import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { colors } from '@repo/shared/config';
import { X } from 'react-feather';

import { useAppDispatch } from '@hooks';
import { notification } from '@utils';
import { generalActions, generalSelectors } from '@store';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';

import Modal from '@components/shared/ant/Modal/Modal';

const DeleteSchedulesModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [processing, setProcessing] = useState(false);

  const modalState = useSelector(schedulesSelectors.getConfirmDeleteModalState);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const { data: schedulePlan } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );

  let deleteCandidateIds: string[] = [];
  if (modalState !== null) {
    deleteCandidateIds = modalState.multiple
      ? modalState.data
      : [modalState.data.id];
  }
  const itemCount = deleteCandidateIds.length;

  const closeModal = useCallback(() => {
    dispatch(schedulesActions.toggleDeleteModal(null));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'DeleteSchedules' }, { itemCount })}
      open={modalState !== null}
      onOk={async () => {
        setProcessing(true);

        const resultAction = await dispatch(
          schedulesActions.deleteSchedules({
            ids: deleteCandidateIds,
            schedulePlanId: schedulePlan?.id,
          })
        );

        if (schedulesActions.deleteSchedules.fulfilled.match(resultAction)) {
          dispatch(schedulesActions.getSchedules());

          closeModal();

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingSchedules' },
              { itemCount }
            ),
          });
        }

        setProcessing(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={processing}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveSchedulesFromSystem' },
        { itemCount: deleteCandidateIds.length }
      ).replace(
        '%replaceValue%',
        modalState && !modalState.multiple
          ? modalState.data.name
          : deleteCandidateIds.length.toString()
      )}
    </Modal>
  );
};

export default DeleteSchedulesModal;
