import { BaseError } from './BaseError';

export class ServerError extends BaseError {
  public statusCode: number;

  constructor(message: string) {
    super(message);

    this.statusCode = 500;
  }
}
