import { createReducer, EntityState, Reducer } from '@reduxjs/toolkit';

import {
  IssueType,
  IssueTypeDetails,
} from '@domain/IssueTypes/models/IssueType';
import { IssueTypeField } from '@domain/IssueTypes/models/IssueTypeField';
import { IConcise, IPagingMeta, ITableFilters } from '@repo/shared/types';
import { issueTypesActions as actions } from '@application/IssueTypes/store/issueTypesActions';
import {
  issuesTypesEntityAdapter,
  issueTypeFieldsEntityAdapter,
  conciseIssueTypesEntityAdapter,
} from '@application/IssueTypes/store/issueTypesEntityAdapters';
import { getInitialFilters } from '@utils';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';

interface IIssueTypesState {
  issueTypes: {
    data: EntityState<IssueType, string>;
    meta: IPagingMeta | null;
    loading: boolean;
    error: string | null;
    filters: ITableFilters;
    confirmDeleteModal: DeleteModalState<IssueType>;
  };
  conciseIssueTypes: {
    data: EntityState<IConcise, string>;
    loading: boolean;
    error: null;
  };
  issueType: {
    details: {
      loading: boolean;
      error: string | null;
      data: IssueTypeDetails | null;
    };
    fields: {
      data: EntityState<IssueTypeField, string>;
      meta: IPagingMeta | null;
      loading: boolean;
      error: string | null;
      filters: ITableFilters;
      addEditModal: {
        show: boolean;
        issueTypeFieldId: string | null;
      };
      confirmDeleteModal: DeleteModalState<IssueTypeField>;
    };
  };
}

const issueTypesInitialState = {
  data: issuesTypesEntityAdapter.getInitialState(),
  meta: null,
  loading: false,
  error: null,
  addEditModal: {
    show: false,
    issueTypeId: null,
  },
  confirmDeleteModal: null,
  filters: getInitialFilters(),
};

const issueTypeInitialState = {
  details: {
    loading: false,
    data: null,
    error: null,
  },
  fields: {
    data: issueTypeFieldsEntityAdapter.getInitialState(),
    meta: null,
    loading: false,
    error: null,
    filters: {
      ...getInitialFilters(),
    },
    addEditModal: {
      show: false,
      issueTypeFieldId: null,
    },
    confirmDeleteModal: null,
  },
};

const issueTypesReducer: Reducer<IIssueTypesState> =
  createReducer<IIssueTypesState>(
    {
      issueTypes: {
        ...issueTypesInitialState,
      },
      conciseIssueTypes: {
        loading: false,
        data: conciseIssueTypesEntityAdapter.getInitialState(),
        error: null,
      },
      issueType: {
        ...issueTypeInitialState,
      },
    },
    (builder) =>
      builder
        .addCase(actions.getIssueTypes.pending, (state, { meta: { arg } }) => {
          state.issueTypes.loading = true;
          state.issueTypes.error = null;

          if (arg === null) {
            state.issueTypes.filters = {
              ...getInitialFilters(),
            };
          } else {
            state.issueTypes.filters = {
              ...state.issueTypes.filters,
              ...arg,
            };
          }
        })
        .addCase(
          actions.getIssueTypes.fulfilled,
          (state, { payload: { meta, data } }) => {
            state.issueTypes.loading = false;
            state.issueTypes.meta = meta;
            issuesTypesEntityAdapter.setAll(state.issueTypes.data, data);
          }
        )
        .addCase(actions.getIssueTypes.rejected, (state, { payload }) => {
          state.issueTypes.loading = false;
          state.issueTypes.error = payload || null;
        })
        .addCase(actions.resetIssueTypes, (state) => {
          state.issueTypes = {
            ...state.issueTypes,
            data: issuesTypesEntityAdapter.getInitialState(),
            meta: null,
            loading: false,
            error: null,
            confirmDeleteModal: null,
          };
        })
        .addCase(actions.getConciseIssueTypes.pending, (state) => {
          state.conciseIssueTypes.loading = true;
          state.conciseIssueTypes.error = null;
        })
        .addCase(
          actions.getConciseIssueTypes.fulfilled,
          (state, { payload }) => {
            state.conciseIssueTypes.loading = false;
            conciseIssueTypesEntityAdapter.setAll(
              state.conciseIssueTypes.data,
              payload
            );
          }
        )
        .addCase(
          actions.getConciseIssueTypes.rejected,
          (state, { payload }) => {
            state.conciseIssueTypes.loading = false;
            state.issueTypes.error = payload || null;
          }
        )
        .addCase(
          actions.getIssueTypeFields.pending,
          (state, { meta: { arg } }) => {
            state.issueType.fields.loading = true;
            state.issueType.fields.error = null;
            state.issueType.fields.filters = {
              ...state.issueType.fields.filters,
              ...arg,
            };
          }
        )
        .addCase(
          actions.getIssueTypeFields.fulfilled,
          (state, { payload: { meta, data } }) => {
            state.issueType.fields.loading = false;
            state.issueType.fields.meta = meta;
            issueTypeFieldsEntityAdapter.setAll(
              state.issueType.fields.data,
              data
            );
          }
        )
        .addCase(actions.getIssueTypeFields.rejected, (state, { payload }) => {
          state.issueType.fields.loading = false;
          state.issueType.fields.error = payload || null;
        })
        .addCase(actions.resetIssueType, (state) => {
          state.issueType = {
            ...issueTypeInitialState,
          };
        })
        .addCase(
          actions.toggleAddEditIssueTypeFieldsModal,
          (state, { payload: { show, issueTypeFieldId } }) => {
            state.issueType.fields.addEditModal = show
              ? {
                  show: true,
                  issueTypeFieldId: issueTypeFieldId || null,
                }
              : { show: false, issueTypeFieldId: null };
          }
        )
        .addCase(actions.getIssueTypeDetails.pending, (state) => {
          state.issueType.details.loading = true;
          state.issueType.details.error = null;
        })
        .addCase(
          actions.getIssueTypeDetails.fulfilled,
          (state, { payload }) => {
            state.issueType.details.loading = false;
            state.issueType.details.data = payload;
          }
        )
        .addCase(actions.getIssueTypeDetails.rejected, (state, { payload }) => {
          state.issueType.details.loading = false;
          state.issueType.details.error = payload || null;
        })
        .addCase(
          actions.toggleConfirmDeleteIssueTypesModal,
          (state, { payload }) => {
            state.issueTypes.confirmDeleteModal = payload;
          }
        )
        .addCase(
          actions.toggleConfirmDeleteIssueTypeFieldsModal,
          (state, { payload }) => {
            state.issueType.fields.confirmDeleteModal = payload;
          }
        )
  );

export { issueTypesReducer };
