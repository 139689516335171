import React from 'react';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';
import { FormInstance } from 'antd/es/form';

import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { getDefaultFormRepeatValues } from '@application/Schedules/utils/getDefaultFormRepeatValues';
import Form from '@components/shared/ant/Form';
import { TutorialCircle } from '@icons';
import {
  ScheduleFormValues,
  ScheduleRepeatFormValues,
} from '@application/Schedules/models/ScheduleFormValues';
import { Info } from './styled';
import { date } from '@utils';
import { DayOfWeek } from '@repo/shared/enums';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';

import OneTime from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/OneTime';
import Weekly from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/Weekly/Weekly';
import MultipleWeeks from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/MultipleWeeks';
import Monthly from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/Monthly';
import Daily from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/Daily';
import Select from '@components/shared/ant/Select/Select';
import Expiration from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/Expiration/Expiration';

const { Option } = AntSelect;

const dayLangIds = {
  [DayOfWeek.Monday]: 'Monday',
  [DayOfWeek.Tuesday]: 'Tuesday',
  [DayOfWeek.Wednesday]: 'Wednesday',
  [DayOfWeek.Thursday]: 'Thursday',
  [DayOfWeek.Friday]: 'Friday',
  [DayOfWeek.Saturday]: 'Saturday',
  [DayOfWeek.Sunday]: 'Sunday',
};

interface IProps {
  disabled?: boolean;
  form: FormInstance;
  isSchedulePlanSchedule: boolean;
}

const RepeatPatterns: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  form,
  isSchedulePlanSchedule,
}) => {
  const { formatMessage } = useIntl();

  const firstAuditStartsTodayLangId = isSchedulePlanSchedule
    ? 'TheFirstAuditStartsTodayAndExpiresInXDays'
    : 'TheFirstAuditStartsTodayAndWillBeDueInXDays';
  const firstAuditStartsOnDateLangId = isSchedulePlanSchedule
    ? 'TheFirstAuditStartsOnDateAndExpiresInXDays'
    : 'TheFirstAuditStartsOnDateAndWillBeDueInXDays';

  return (
    <>
      <Form.Item
        name={['repeat', 'repeatPattern']}
        label={formatMessage({ id: 'Recurrence' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseChooseRecurrence' }),
          },
        ]}
      >
        <Select
          disabled={disabled}
          placeholder={formatMessage({ id: 'Frequency' })}
          e2eDataAttr={
            e2eTestElements.schedules.addEditSchedule.repeatTypeSelect
          }
          onChange={(pattern) => {
            form.setFieldValue('repeat', getDefaultFormRepeatValues(pattern));
          }}
        >
          {!isSchedulePlanSchedule && (
            <Option value={AuditRepeatPattern.OneTime}>
              {formatMessage({ id: 'OneTime' })}
            </Option>
          )}
          <Option value={AuditRepeatPattern.Daily}>
            {formatMessage({ id: 'Daily' })}
          </Option>
          <Option value={AuditRepeatPattern.Weekly}>
            {formatMessage({ id: 'Weekly' })}
          </Option>
          <Option value={AuditRepeatPattern.MultipleWeeks}>
            {formatMessage({ id: 'MultipleWeeks' })}
          </Option>
          <Option value={AuditRepeatPattern.Monthly}>
            {formatMessage({ id: 'Monthly' })}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item<ScheduleFormValues>
        style={{ marginBottom: '5px' }}
        shouldUpdate={(prevValues, nextValues) =>
          prevValues.repeat.repeatPattern !== nextValues.repeat.repeatPattern
        }
      >
        {({ getFieldValue }) => {
          const repeatPattern = getFieldValue(['repeat', 'repeatPattern']);

          switch (repeatPattern) {
            case AuditRepeatPattern.OneTime:
              return <OneTime disabled={disabled} />;
            case AuditRepeatPattern.Daily:
              return <Daily disabled={disabled} />;
            case AuditRepeatPattern.Weekly:
              return <Weekly disabled={disabled} />;
            case AuditRepeatPattern.MultipleWeeks:
              return <MultipleWeeks disabled={disabled} form={form} />;
            case AuditRepeatPattern.Monthly:
              return <Monthly disabled={disabled} form={form} />;
            case undefined:
            default:
              return null;
          }
        }}
      </Form.Item>
      <Form.Item<ScheduleFormValues>
        noStyle
        shouldUpdate={(prevValues, nextValues) =>
          JSON.stringify(prevValues.repeat) !==
          JSON.stringify(nextValues.repeat)
        }
      >
        {({ getFieldValue }) => {
          const repeat: ScheduleRepeatFormValues = getFieldValue('repeat');

          let text = '';

          switch (repeat.repeatPattern) {
            case AuditRepeatPattern.OneTime:
              text = formatMessage(
                {
                  id: repeat.range[0].isSame(date(), 'day')
                    ? firstAuditStartsTodayLangId
                    : firstAuditStartsOnDateLangId,
                },
                {
                  startDate: repeat.range[0].format(config.dateFormat),
                  inDays: repeat.range[1].diff(repeat.range[0], 'days'),
                }
              );
              break;
            case AuditRepeatPattern.Daily:
              text = formatMessage(
                {
                  id: firstAuditStartsTodayLangId,
                },
                {
                  inDays: parseInt(repeat.repeatEvery),
                }
              );
              break;
            case AuditRepeatPattern.Weekly: {
              const { daysOfWeek } = repeat;

              if (daysOfWeek.length === 0) {
                return null;
              }

              daysOfWeek.sort((a, b) => a - b);

              const todayWeekDay = date().weekday();

              const firstSelectedDayAfterTodayIndex = daysOfWeek.findIndex(
                (weekDayIndex) => weekDayIndex >= todayWeekDay
              );

              const firstSelectedDayAfterToday =
                firstSelectedDayAfterTodayIndex !== -1
                  ? daysOfWeek[firstSelectedDayAfterTodayIndex]
                  : daysOfWeek[0];

              const nextSelectedDayAfterFirstDayIndex =
                firstSelectedDayAfterTodayIndex + 1 < daysOfWeek.length
                  ? firstSelectedDayAfterTodayIndex + 1
                  : 0;

              const nextSelectedDayAfterFirstDay =
                daysOfWeek[nextSelectedDayAfterFirstDayIndex];

              let inDays;

              if (nextSelectedDayAfterFirstDay === firstSelectedDayAfterToday) {
                inDays = 7;
              } else if (
                nextSelectedDayAfterFirstDay > firstSelectedDayAfterToday
              ) {
                inDays =
                  nextSelectedDayAfterFirstDay - firstSelectedDayAfterToday;
              } else {
                inDays =
                  7 -
                  (firstSelectedDayAfterToday - nextSelectedDayAfterFirstDay);
              }

              text = formatMessage(
                {
                  id:
                    firstSelectedDayAfterToday === todayWeekDay
                      ? firstAuditStartsTodayLangId
                      : firstAuditStartsOnDateLangId,
                },
                {
                  startDate: formatMessage({
                    id: dayLangIds[firstSelectedDayAfterToday as DayOfWeek],
                  }),
                  inDays,
                }
              );

              break;
            }
            case AuditRepeatPattern.MultipleWeeks: {
              const currentDay = date().day();

              text = formatMessage(
                {
                  id: firstAuditStartsOnDateLangId,
                },
                {
                  startDate: date()
                    .add(currentDay > repeat.startDay ? 1 : 0, 'week')
                    .day(repeat.startDay)
                    .format(config.dateFormat),
                  inDays: repeat.duration,
                }
              );

              break;
            }
            case AuditRepeatPattern.Monthly: {
              const currentDay = date().date();
              const startDay = parseInt(repeat.startDay);

              text = formatMessage(
                {
                  id: firstAuditStartsOnDateLangId,
                },
                {
                  startDate: date()
                    .add(currentDay > startDay ? 1 : 0, 'month')
                    .date(parseInt(repeat.startDay))
                    .format(config.dateFormat),
                  inDays: repeat.duration,
                }
              );

              break;
            }
          }

          return (
            <Info>
              <TutorialCircle />
              {text}
            </Info>
          );
        }}
      </Form.Item>
      {!isSchedulePlanSchedule && <Expiration disabled={disabled} />}
    </>
  );
};

export default RepeatPatterns;
