import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  padding-top: 2px;
  font-size: 12px;
  color: ${colors.gray8};
  word-break: break-word;
`;
