import React from 'react';
import { useSelector } from 'react-redux';

import { accountSelectors } from '@store';

import Logo, { LogoProps } from '@repo/shared/components/Logo/Logo';

const AppLogo: React.FC<
  React.PropsWithChildren<Omit<LogoProps, 'appLogoUrl'>>
> = (props) => {
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  return <Logo {...props} appLogoUrl={appConfiguration?.appLogoUrl} />;
};

export default AppLogo;
