import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';

import { AssigneesList, AssigneesListLabel, Container } from './styled';
import { date, dateInTimeZone, notification } from '@utils';
import { useAppDispatch, usePermission } from '@hooks';
import { Dayjs } from 'dayjs';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { Action } from '@domain/Actions/models/Action';
import { ActionStatus, Permission } from '@repo/shared/enums';
import { actionsActions } from '@application/Actions/store/actionsActions';

import Form from '@components/shared/ant/Form';
import PrioritySelect from '@components/shared/PrioritySelect';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import ActionAssignees from '@src/presentation/Actions/ActionDetails/ActionAssignees/ActionAssignees';

interface IProps {}

const ActionDetailsForm: React.FC<React.PropsWithChildren<IProps>> = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);

  const loading = useSelector(actionsSelectors.getActionDetailsLoading);
  const error = useSelector(actionsSelectors.getActionDetailsError);
  const action = useSelector(actionsSelectors.getActionDetailsAction);

  const [canManageActions, canAssignActions] = usePermission([
    Permission.CanManageCorrectiveActions,
    Permission.CanAssignCorrectiveActions,
  ]);

  const updateAction = useCallback(
    (update: Partial<Action>) => {
      async function _updateAction(update: Partial<Action>) {
        if (!action) {
          return;
        }

        setProcessing(true);

        const resultAction = await dispatch(
          actionsActions.updateAction({
            ...action,
            ...update,
          })
        );

        if (actionsActions.updateAction.rejected.match(resultAction)) {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileEditingAction' }),
          });
        } else {
          notification.success({
            message: formatMessage({ id: 'ActionHasBeenUpdated' }),
          });
        }

        setProcessing(false);
      }

      _updateAction(update);
    },
    [JSON.stringify(action || {})]
  );

  useEffect(() => {
    if (!loading && !error) {
      form.setFieldsValue({
        priority: action?.priority,
        dueDate: date(action?.dueDateInformation?.localTime),
      });
    }
  }, [loading, error, JSON.stringify(action || {})]);

  return (
    <Form form={form} layout="vertical">
      {loading ? (
        <Container>
          <Skeleton active />
        </Container>
      ) : (
        <>
          {error ? (
            <Container>{error}</Container>
          ) : (
            <Container>
              {!!action && (
                <AssigneesList>
                  <AssigneesListLabel>
                    {formatMessage({ id: 'AssignTo' })}
                  </AssigneesListLabel>
                  <ActionAssignees
                    action={action}
                    disabled={
                      !canAssignActions ||
                      action.status === ActionStatus.Expired
                    }
                    e2eDataAttr={
                      e2eTestElements.actions.detailsModal.assignedTo
                    }
                  />
                </AssigneesList>
              )}
              <Spin spinning={processing}>
                <Form.Item
                  name="priority"
                  label={formatMessage({ id: 'Priority' })}
                >
                  <PrioritySelect
                    disabled={
                      !canManageActions ||
                      action?.status === ActionStatus.Approved ||
                      action?.status === ActionStatus.Expired
                    }
                    e2eDataAttr={
                      e2eTestElements.actions.detailsModal.prioritySelect
                    }
                    onChange={(priority) => {
                      updateAction({
                        priority,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="dueDate"
                  label={formatMessage({ id: 'DueDate' })}
                >
                  <DatePicker
                    disabled={
                      !canManageActions ||
                      action?.status === ActionStatus.Approved ||
                      action?.status === ActionStatus.Expired
                    }
                    e2eDataAttr={
                      e2eTestElements.actions.detailsModal.dueDateInput
                    }
                    disabledDate={(currentDate: Dayjs) =>
                      action
                        ? currentDate.isBefore(
                            dateInTimeZone(
                              action.createdAtInformation?.localTime,
                              action.audit?.ianaTimeZone
                            )
                          )
                        : false
                    }
                    onChange={(date: Dayjs) => {
                      if (!action?.dueDateInformation) {
                        return;
                      }

                      updateAction({
                        dueDateInformation: {
                          ...action.dueDateInformation,
                          localTime: date.format(config.apiDateFormat),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Spin>
            </Container>
          )}
        </>
      )}
    </Form>
  );
};

export default ActionDetailsForm;
