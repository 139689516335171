import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IAuditItemStreamUpdate,
  IConductAuditFilters,
  IFileUpload,
  IScore,
  ISignature,
  ITagConcise,
} from '@repo/shared/types';
import { IAppDispatch, IRootState } from '@src/core/frameworks/redux';
import { accountSelectors, generalActions, generalSelectors } from '@store';
import { apiUrls } from '@config';
import { config } from '@repo/shared/config';
import { performAuditSelectors } from '@application/PerformAudit/store/performAuditSelectors';
import {
  date,
  dateUTC,
  extractPerformAuditErrorCode,
  findRootItemId,
  getAncestorsSections,
  getAuditDurationLSItemName,
  getChildrenIds,
  normalizeAuditItemForApi,
  normalizeAuditItems,
  notification,
  resetAnswerDataAndSetNA,
} from '@utils';
import { delay } from '@repo/shared/utils';
import { getErrorMessage } from '@repo/shared/utils';
import { scoresAdapter } from '@store/entityAdapters';
import { Logger } from '@repo/shared/services';
import * as UserPreferencesService from '@services/userPreferences.service';
import {
  ItemType,
  PerformAuditErrorCode,
  SignatureBy,
  UploadStatus,
} from '@repo/shared/enums';
import { InternalApiService } from '@repo/shared/api';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import AuditsApiClient from '@infrastructure/Audits/api/AuditsApiClient';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { PerformAuditItem } from '@domain/PerformAudit/PerformAuditItem';
import { NotUploadedFile } from '@domain/PerformAudit/NotUploadedFile';
import PerformAuditApiClient from '@infrastructure/PerformAudit/api/PerformAuditApiClient';
import { DeleteNotUploadedFileModalState } from '@application/PerformAudit/models/DeleteNotUploadedFileModalState';

const auditsApiClient = new AuditsApiClient();
const apiService = InternalApiService.getInstance();
const performAuditApiClient = new PerformAuditApiClient();

const setAuditErrorCode = createAction<PerformAuditErrorCode | null>(
  'audits/setAuditErrorCode'
);
const updateAuditItemsInStore = createAction<PerformAuditItem[]>(
  'audits/updateAuditItemsInStore'
);
const setPubnubAuthToken = createAction<string | null>(
  'audits/performAudit/setPubnubAuthToken'
);
const setIsUpdatedItemsFetchRequired = createAction<boolean>(
  'audits/performAudit/setIsUpdatedItemsFetchRequired'
);
const completeAudit = createAsyncThunk<
  void,
  void,
  { rejectValue: string; state: IRootState }
>(
  'audits/performAudit/completeAudit',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const state = getState();

    if (performAuditSelectors.itemSyncIsInProgress(state)) {
      dispatch(setAuditErrorCode(PerformAuditErrorCode.itemsNotSynced));
      return rejectWithValue(PerformAuditErrorCode.itemsNotSynced);
    }

    const items = performAuditSelectors.getPerformAuditItems(state);
    const audit = performAuditSelectors.getAudit(state);
    const auditId = performAuditSelectors.getAuditId(state);

    const {
      signAudit: { auditorSignature, auditeeSignature },
    } = state.performAudit;

    try {
      if (!audit || !items || !auditId) {
        throw new Error(`Audit with id: ${auditId} has not been found`);
      }

      await apiService.post({
        url: `${apiUrls.audit}/${auditId}/complete/from-stream`,
        body: {
          startedAt: audit.startDateInformation.localTime,
          items: Object.values<PerformAuditItem>(items).map(
            ({ id, streamRecordId }: PerformAuditItem) => ({
              templateItemId: id,
              streamRecordId,
            })
          ),
          auditorSignature: auditorSignature
            ? {
                photoId: auditorSignature.photoId,
                createdById: auditorSignature.createdBy.id,
                updatedAt: auditorSignature.updatedAt,
              }
            : null,
          auditeeSignature: auditeeSignature
            ? {
                photoId: auditeeSignature.photoId,
                createdByName: auditeeSignature.createdBy.name,
                updatedAt: auditeeSignature.updatedAt,
              }
            : null,
          tagsIds: (audit?.tags || []).map((tag: ITagConcise) => tag.id),
        },
      });

      const lsDurationItemName = getAuditDurationLSItemName(auditId);
      const durations =
        await UserPreferencesService.getItem<Record<string, number>>(
          lsDurationItemName
        );

      if (durations) {
        await apiService.post({
          url: `${apiUrls.audit}/${auditId}/duration`,
          body: {
            sections: Object.entries(durations).map(([sectionId, seconds]) => ({
              templateSectionId: sectionId,
              seconds,
            })),
          },
        });

        await UserPreferencesService.deleteItem(lsDurationItemName);
      }
    } catch (e) {
      const auditErrorCode = extractPerformAuditErrorCode(e);

      if (auditErrorCode !== null) {
        switch (auditErrorCode) {
          case PerformAuditErrorCode.itemsNotValid:
            dispatch(setAuditErrorCode(auditErrorCode));
            break;
          case PerformAuditErrorCode.itemsNotSynced:
          case PerformAuditErrorCode.missingFiles:
            dispatch(getUpdatedAuditItems());
            dispatch(setAuditErrorCode(auditErrorCode));
            break;
          case PerformAuditErrorCode.auditorSignatureRequired:
          case PerformAuditErrorCode.auditeeSignatureRequired: {
            dispatch(setAuditErrorCode(auditErrorCode));
            break;
          }
          default:
            dispatch(setAuditErrorCode(auditErrorCode));
            break;
        }

        return rejectWithValue(auditErrorCode);
      }

      const error = getErrorMessage(e);

      notification.error({
        message: intl.formatMessage({ id: 'ErrorWhileCompletingAudit' }),
        description: error,
      });

      Logger.captureException(e);
      return rejectWithValue(error);
    }
  }
);
const getUpdatedAuditItems = createAsyncThunk<
  PerformAuditItem[],
  void,
  { rejectValue: string; state: IRootState }
>(
  'audits/performAudit/getUpdatedAuditItems',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const auditId = performAuditSelectors.getAuditId(state);
      const items = performAuditSelectors.getPerformAuditItems(state);

      if (!items) {
        const error = new Error(`Items are not available, auditId: ${auditId}`);

        Logger.captureException(error);

        return rejectWithValue(getErrorMessage(error));
      }

      const updatedItems = await apiService.post<any>({
        url: `audit/${auditId}/stream/items`,
        body: Object.values(items).reduce<IAuditItemStreamUpdate[]>(
          (acc, { id, streamRecordId, itemType }) => {
            if (itemType !== ItemType.Root) {
              acc.push({
                templateItemId: id,
                streamRecordId: streamRecordId || null,
              });
            }

            return acc;
          },
          []
        ),
      });

      dispatch(setIsUpdatedItemsFetchRequired(false));

      return updatedItems;
    } catch (e) {
      const auditErrorCode = extractPerformAuditErrorCode(e);

      if (auditErrorCode !== null) {
        dispatch(setAuditErrorCode(auditErrorCode));
        return;
      }

      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const updateAuditItem = createAsyncThunk<
  PerformAuditItem[],
  {
    itemId: string;
    update: Partial<PerformAuditItem>;
  },
  { rejectValue: { error: string; itemId: string }; state: IRootState }
>(
  'audits/performAudit/updateAuditItem',
  async ({ itemId, update }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const auditId = performAuditSelectors.getAuditId(state);
      const items = performAuditSelectors.getPerformAuditItems(state);
      const rootItemId = performAuditSelectors.getRootItemId(state);
      const currentUser = accountSelectors.getCurrentUser(state);

      if (items === null || rootItemId === null) {
        throw new Error(
          `Audit has not been setup correctly in the store, auditId: ${auditId}`
        );
      }

      const nowUtc = dateUTC().toISOString();

      const updatedBy = {
        id: currentUser.id,
        name: currentUser.name,
      };

      let updatedItem: PerformAuditItem = {
        ...items[itemId],
        ...update,
        updatedBy,
        updatedAtUtc: nowUtc,
      };

      const { section, subSection } = getAncestorsSections(
        items,
        updatedItem.id,
        rootItemId
      );

      let affectedItems: PerformAuditItem[] = [];

      if (update.notApplicable !== undefined) {
        // reset item data
        updatedItem = resetAnswerDataAndSetNA(
          updatedItem,
          update.notApplicable
        );

        // reset children data
        if (updatedItem.childrenIds?.length > 0) {
          const allTreeChildrenIds = getChildrenIds(
            items,
            updatedItem.childrenIds
          );

          affectedItems = allTreeChildrenIds.map((id) => ({
            ...resetAnswerDataAndSetNA(
              items[id],
              update.notApplicable as boolean
            ),
            updatedBy,
            updatedAtUtc: nowUtc,
          }));
        }

        let notApplicableChangedSubSectionId: string | null = null;

        // if all siblings are N/A, mark subsection as N/A as well
        if (
          subSection &&
          subSection.id != updatedItem.id &&
          subSection.childrenIds
            .filter((id) => id !== updatedItem.id)
            .every((id) => items[id]?.notApplicable === true)
        ) {
          affectedItems.push({
            ...subSection,
            notApplicable: update.notApplicable,
          });

          notApplicableChangedSubSectionId = subSection.id;
        }

        // if all siblings are N/A, mark section as N/A as well
        if (
          section &&
          section.id !== updatedItem.id &&
          section.childrenIds
            .filter((id) => id !== updatedItem.id)
            .every(
              (id) =>
                items[id]?.notApplicable ||
                id === notApplicableChangedSubSectionId
            )
        ) {
          affectedItems.push({
            ...section,
            notApplicable: update.notApplicable,
          });
        }
      } else {
        updatedItem.notApplicable =
          update.note !== undefined ? updatedItem.notApplicable : false;

        if (updatedItem.childrenIds?.length > 0) {
          const allTreeChildrenIds = getChildrenIds(
            items,
            updatedItem.childrenIds
          );

          affectedItems = [
            ...affectedItems,
            ...allTreeChildrenIds.map((id) => ({
              ...items[id],
              updatedBy,
              updatedAtUtc: nowUtc,
            })),
          ];
        }

        if (
          subSection &&
          subSection.notApplicable &&
          subSection.id !== updatedItem.id &&
          update.note === undefined
        ) {
          affectedItems.push({
            ...subSection,
            notApplicable: false,
          });
        }

        if (
          section &&
          section.notApplicable &&
          section.id !== updatedItem.id &&
          update.note === undefined
        ) {
          affectedItems.push({
            ...section,
            notApplicable: false,
          });
        }
      }

      const allUpdates = [updatedItem, ...affectedItems];

      if (section) {
        const index = allUpdates.findIndex(
          (update) => update.id === section.id
        );

        if (index !== -1) {
          allUpdates[index] = {
            ...allUpdates[index],
            updatedBy,
            updatedAtUtc: nowUtc,
          };
        } else {
          allUpdates.push({
            ...section,
            updatedBy,
            updatedAtUtc: nowUtc,
          });
        }
      }

      if (subSection) {
        const index = allUpdates.findIndex(
          (update) => update.id === subSection.id
        );

        if (index !== -1) {
          allUpdates[index] = {
            ...allUpdates[index],
            updatedBy,
            updatedAtUtc: nowUtc,
          };
        } else {
          allUpdates.push({
            ...subSection,
            updatedBy,
            updatedAtUtc: nowUtc,
          });
        }
      }

      return allUpdates;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue({ itemId, error: getErrorMessage(e) });
    }
  }
);

export const performAuditActions = {
  setPubnubAuthToken,
  openModal: createAsyncThunk<
    {
      rootItemId: string;
      items: Record<string, PerformAuditItem>;
      auditDetails: AuditDetails;
      auditScoreSystem: IScore | undefined;
    },
    string,
    { rejectValue: string; state: IRootState }
  >(
    'audits/performAudit/openModal',
    async (auditId, { rejectWithValue, getState, dispatch }) => {
      try {
        const [auditDetails] = await Promise.all([
          auditsApiClient.getAuditDetails(auditId),
          dispatch(generalActions.getAnswerTypes()),
        ]);

        if (!auditDetails.startedAtInformation) {
          await apiService.post({
            url: `${apiUrls.audit}/${auditId}/start`,
          });

          dispatch(
            auditsActions.updateAuditLocally({
              id: auditId,
              changes: {
                startedAtInformation: {
                  localTime: date().format(config.apiDateFormat),
                  timeZoneAbbreviation: '',
                },
              },
            })
          );
        }

        const scoresSystems = scoresAdapter
          .getSelectors()
          .selectEntities(getState().score.entities);
        const auditScoreSystem =
          auditDetails?.template?.scoreSystemId &&
          scoresSystems[auditDetails.template.scoreSystemId]
            ? scoresSystems[auditDetails.template.scoreSystemId]
            : undefined;

        const browserTabId = accountSelectors.getBrowserTabId(getState());

        const [apiItems, pubnubAuthToken] = await Promise.all([
          apiService.get<any>({
            url: `audit/${auditId}/stream`,
          }),
          apiService.post<string>({
            url: `audit/${auditId}/stream/register`,
            body: {
              deviceId: browserTabId,
            },
          }),
        ]);

        dispatch(setPubnubAuthToken(pubnubAuthToken));

        const rootItemId = findRootItemId(apiItems);
        let items = normalizeAuditItems(apiItems, rootItemId);

        return {
          rootItemId,
          items,
          auditScoreSystem,
          auditDetails,
        };
      } catch (e: any) {
        if (e.message === '') {
          return rejectWithValue('');
        }

        const auditErrorCode = extractPerformAuditErrorCode(e);

        if (auditErrorCode !== null) {
          dispatch(setAuditErrorCode(auditErrorCode));
          return rejectWithValue('');
        }

        const error = getErrorMessage(e);

        notification.error({
          message: error,
        });

        Logger.captureException(e);
        return rejectWithValue(error);
      }
    }
  ),
  getAllItems: createAsyncThunk<
    {
      rootItemId: string;
      items: Record<string, PerformAuditItem>;
    },
    void,
    { rejectValue: string; state: IRootState }
  >(
    'audits/performAudit/getAllItems',
    async (_, { getState, rejectWithValue }) => {
      const auditId = performAuditSelectors.getAuditId(getState());

      try {
        const apiItems = await apiService.get<any>({
          url: `audit/${auditId}/stream`,
        });

        const rootItemId = findRootItemId(apiItems);

        return {
          items: normalizeAuditItems(apiItems, rootItemId),
          rootItemId,
        };
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  setAuditErrorCode,
  setDuration: createAsyncThunk<
    void,
    {
      sectionId: string;
      seconds: number;
    },
    { rejectValue: string; state: IRootState }
  >(
    'audits/performAudit/setDuration',
    async ({ sectionId, seconds }, { getState }) => {
      const auditId = performAuditSelectors.getAuditId(getState());

      if (!auditId) {
        return;
      }

      const lsItemName = getAuditDurationLSItemName(auditId);
      const durations =
        (await UserPreferencesService.getItem<Record<string, number>>(
          lsItemName
        )) || {};

      if (durations[sectionId] === undefined) {
        durations[sectionId] = 0;
      }

      durations[sectionId] += seconds;

      await UserPreferencesService.setItem(lsItemName, durations);
    }
  ),
  closeModal: createAsyncThunk<void, void, { dispatch: IAppDispatch }>(
    'audits/performAudit/closeModal',
    (_, { dispatch }) => {
      dispatch(generalActions.resetFileUploads());
    }
  ),
  openSection: createAction<string>('audits/performAudit/openSection'),
  openSectionList: createAction('audits/performAudit/openSectionList'),
  updateAuditItemsInStore,
  updateAuditItem,
  uploadUpdatedItems: createAsyncThunk<
    { streamRecordId: string; id: string }[],
    string[],
    { rejectValue: string[]; state: IRootState }
  >(
    'audits/performAudit/uploadUpdatedItems',
    async (uploadQueue, { rejectWithValue, getState, dispatch }) => {
      try {
        const uploadingFiles = () => {
          const uploads = generalSelectors.getFilesUploads(getState());

          return Object.values<IFileUpload>(uploads).some(
            ({ status, file }) =>
              uploadQueue.includes(file.id) &&
              (status === UploadStatus.Pending ||
                status === UploadStatus.Uploading)
          );
        };

        while (uploadingFiles()) {
          await delay(50);
        }

        const state = getState();
        const browserTabId = accountSelectors.getBrowserTabId(state);
        const auditId = performAuditSelectors.getAuditId(state);
        const items = performAuditSelectors.getPerformAuditItems(state);

        if (!items) {
          throw new Error(
            `Audit with id: ${auditId} does not setup properly in store`
          );
        }

        return apiService.post({
          url: `audit/${auditId}/stream`,
          body: {
            items: uploadQueue.map((item) =>
              normalizeAuditItemForApi(items[item])
            ),
            deviceId: browserTabId,
          },
        });
      } catch (e) {
        const auditErrorCode = extractPerformAuditErrorCode(e);

        if (auditErrorCode !== null) {
          dispatch(setAuditErrorCode(auditErrorCode));
          return rejectWithValue(uploadQueue);
        }

        Logger.captureException(e);
        return rejectWithValue(uploadQueue);
      }
    }
  ),
  setSectionMarkedAsNABy: createAction<string | null>(
    'audits/setSectionMarkedAsNABy'
  ),
  getUpdatedAuditItems,
  setIsUpdatedItemsFetchRequired,
  showNoteModal: createAction<{ itemId: string; note?: string | null }>(
    'audits/performAudit/showNoteModal'
  ),
  hideNoteModal: createAction('audits/performAudit/hideNoteModal'),
  showFlagsModal: createAction<{ itemId: string }>(
    'audits/performAudit/showFlagsModal'
  ),
  hideFlagsModal: createAction('audits/performAudit/hideFlagsModal'),
  showActionModal: createAction<{
    itemId: string;
    actionId?: string | null;
    actionTemplateId?: string;
  }>('audits/performAudit/showActionModal'),
  hideActionModal: createAction('audits/performAudit/hideActionModal'),
  showSignatureModal: createAction<{
    itemId: string | null;
    signer: SignatureBy;
  }>('audits/performAudit/showSignatureModal'),
  hideSignatureModal: createAction('audits/performAudit/hideSignatureModal'),
  toggleFiltersModal: createAction<boolean>(
    'audits/performAudit/toggleFiltersModal'
  ),
  updateFilters: createAction<Partial<IConductAuditFilters>>(
    'audits/performAudit/updateFilters'
  ),
  resetFilters: createAction('audits/performAudit/resetFilters'),
  addAuditSignature: createAction<{
    signatureBy: SignatureBy;
    signature: ISignature;
  }>('audits/performAudit/addAuditSignature'),
  deleteAuditSignature: createAction<SignatureBy>(
    'audits/performAudit/deleteAuditSignature'
  ),
  completeAudit,
  toggleLoadingPhotosModal: createAction<boolean>(
    'audits/performAudit/toggleLoadingPhotosModal'
  ),
  showNACheckConfirmModal: createAction<string>(
    'audits/performAudit/toggleNACheckConfirmModal'
  ),
  hideNACheckConfirmModal: createAction(
    'audits/performAudit/hideNACheckConfirmModal'
  ),
  toggleSelectActionTemplateModal: createAction<string | null>(
    'audits/performAudit/toggleSelectActionTemplateModal'
  ),
  updateAuditInStore: createAction<Partial<AuditDetails>>(
    'performAudit/updateAuditInStore'
  ),
  getNotUploadedFiles: createAsyncThunk<
    NotUploadedFile[],
    void,
    { rejectValue: string; state: IRootState }
  >(
    'audits/performAudit/getNotUploadedFiles',
    async (_, { getState, rejectWithValue }) => {
      const auditId = performAuditSelectors.getAuditId(getState());

      if (!auditId) {
        throw new Error('auditId is not set');
      }

      try {
        return await performAuditApiClient.getNotUploadedFiles(auditId);
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  toggleSyncModal: createAction<boolean>('audits/performAudit/toggleSyncModal'),
  toggleDeleteNotUploadedFileModal:
    createAction<DeleteNotUploadedFileModalState>(
      'audits/performAudit/toggleDeleteNotUploadedFileModal'
    ),
  deleteNotUploadedFile: createAsyncThunk<
    void,
    NotUploadedFile,
    { rejectValue: string; state: IRootState }
  >(
    'audits/performAudit/deleteNotUploadedFile',
    async (
      { id, itemId, actionId },
      { getState, rejectWithValue, dispatch }
    ) => {
      try {
        const items = performAuditSelectors.getPerformAuditItems(getState());

        const item = items?.[itemId];

        if (!item) {
          throw new Error('item is not found');
        }

        const update: Partial<PerformAuditItem> = {};

        if (actionId) {
          const actionIndex = item.actions.findIndex((a) => a.id === actionId);

          if (actionIndex === -1) {
            throw new Error('action is not found');
          }

          update.actions = [...item.actions];
          update.actions[actionIndex] = {
            ...item.actions[actionIndex],
            files: item.actions[actionIndex].files.filter((f) => f.id !== id),
          };
        } else {
          update.files = item.files.filter((f) => f.id !== id);
        }

        await dispatch(updateAuditItem({ itemId, update }));
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
};
