import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { generalActions, generalSelectors } from '@store';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { Action } from '@components/shared/ActionsModal/styled';
import { Modal } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { AuditsPage } from '@application/Audits/models/AuditsPage';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';

interface IProps {
  page: AuditsPage;
}

const AuditsActionsModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const viewAuditModalId = useSelector(auditsSelectors.getViewAuditModalState);

  const audits = useSelector(auditsSelectors.getAuditsDictionary);
  const audit =
    selectedTableRowKeys.length === 1 ? audits[selectedTableRowKeys[0]] : null;

  useEffect(() => {
    if (selectedTableRowKeys.length > 0 && viewAuditModalId) {
      dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
    }
  }, [selectedTableRowKeys.length, dispatch, JSON.stringify(viewAuditModalId)]);

  return (
    <ActionsModal>
      {() => (
        <>
          <Action
            onClick={(e: MouseEvent) => {
              e.stopPropagation();

              dispatch(
                auditsActions.toggleConfirmDeleteModal(
                  selectedTableRowKeys.length > 1 || !audit
                    ? {
                        multiple: true,
                        data: selectedTableRowKeys,
                      }
                    : {
                        multiple: false,
                        data: audit,
                      }
                )
              );
            }}
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
          {page === AuditsPage.Pending && (
            <>
              <Action
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();

                  dispatch(
                    generalActions.showModal({
                      name: Modal.ConfirmExpire,
                      data: selectedTableRowKeys,
                    })
                  );
                }}
              >
                {formatMessage({ id: 'Expire' })}
                {selectedTableRowKeys.length > 1
                  ? ` (${selectedTableRowKeys.length})`
                  : ''}
              </Action>
            </>
          )}
        </>
      )}
    </ActionsModal>
  );
};

export default AuditsActionsModal;
