import { ITemplateItem } from '@repo/shared/types';
import { v4 as uuid } from 'uuid';

import { CreateTemplateItemDto } from '@application/AuditTemplateBuilder/models/CreateTemplateItemDto';

export function getPristineAuditTemplateItem({
  text,
  parentId,
  answerType,
  index,
  itemType,
  data,
}: CreateTemplateItemDto): ITemplateItem {
  return {
    id: uuid(),
    text,
    parentId,
    itemType,
    answerType:
      answerType === undefined || answerType === null ? null : answerType,
    data: data || null,
    index,
    childrenIds: [],
    isCritical: false,
    condition: null,
    hasInformation: false,
    information: null,
    sectionAuditObjectsTags: [],
    itemTags: [],
    required: false,
    isSignatureRequired: false,
    disableNotApplicable: false,
    actionTemplates: [],
  };
}
