import styled from 'styled-components';
import AntTabs from 'antd/es/tabs';

import { colors } from '@repo/shared/config';

const Tabs = styled(AntTabs)`
  .ant-tabs-nav {
    margin-bottom: 32px;

    &:before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    width: 100%;
    border-radius: 5px;
    background: ${colors.gray3};
    padding: 3px;

    .ant-tabs-tab {
      flex-grow: 1;
      justify-content: center;
      margin-right: 0;
      border-radius: 3px;
      padding: 2px 0;

      .ant-tabs-tab-btn {
        font-size: 14px;
        color: ${colors.gray8};
      }

      &.ant-tabs-tab-active {
        background: ${colors.white};

        .ant-tabs-tab-btn {
          color: ${colors.gray10};
          font-weight: 400;
        }
      }

      & + .ant-tabs-tab {
        margin: 0;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
`;

export const SelectTabs = styled(AntTabs)`
  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav {
    padding: 2px;
    margin-bottom: 4px;

    &:before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    width: 100%;

    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
      padding: 0 0 6px;

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${colors.gray10};
      }
    }

    .ant-tabs-ink-bar {
      background: ${colors.blue2};
    }
  }
`;

export default Tabs;
