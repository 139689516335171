import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Action } from '../../../shared/ActionsModal/styled';
import {
  auditObjectsSelectors,
  generalSelectors,
  auditObjectsActions,
  accountSelectors,
} from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import ActionsModal from '../../../shared/ActionsModal/ActionsModal';

const AttributesActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const attributesMap = useSelector(auditObjectsSelectors.attributes.getMap);
  let attribute =
    selectedTableRowKeys.length === 1
      ? attributesMap[selectedTableRowKeys[0]]
      : null;
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <>
              <Action
                onClick={() => {
                  if (attribute) {
                    navigate(
                      routes.auditObjects.attributeDetails.replace(
                        ':id',
                        attribute.id
                      )
                    );
                  }

                  closeModal();
                }}
              >
                {formatMessage({ id: 'View' })}
              </Action>
              {!isLocationsReadonly && (
                <Action
                  onClick={() => {
                    if (attribute) {
                      dispatch(
                        auditObjectsActions.attributes.toggleAddEditModal({
                          show: true,
                          attributeId: attribute.id,
                        })
                      );
                    }

                    closeModal();
                  }}
                >
                  {formatMessage({ id: 'Edit' })}
                </Action>
              )}
            </>
          )}
          {!isLocationsReadonly && (
            <Action
              onClick={() => {
                dispatch(
                  auditObjectsActions.attributes.toggleConfirmDeleteModal(
                    selectedTableRowKeys
                  )
                );
              }}
            >
              {formatMessage({ id: 'Delete' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
        </>
      )}
    </ActionsModal>
  );
};

export default AttributesActionsModal;
