import React from 'react';
import { useIntl } from 'react-intl';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';

import { Modal } from '@repo/shared/enums';
import { Description } from './styled';
import { generalActions, scoresActions, scoresSelectors } from '@store';
import { usePermission, useAppDispatch } from '@hooks';
import { Permission } from '@repo/shared/enums';

import PageHeader from '../../shared/ant/PageHeader';
import Button from '../../shared/ant/Button';
import SearchInput from '../../shared/SeachInput/SearchInput';

const ScoresHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canManageScoreSystems = usePermission(Permission.CanManageScoreSystems);

  const filters = useSelector(scoresSelectors.getFilters);

  return (
    <>
      <PageHeader
        title={
          <>
            {formatMessage({
              id: 'ScoringSystems',
            })}
            <Description>
              {formatMessage({
                id: 'CreateCategoriesBasedOnAuditScore',
              })}
            </Description>
          </>
        }
        extra={
          <>
            <SearchInput
              value={filters.search}
              onEnter={(search: string) =>
                dispatch(scoresActions.setFilters({ search, pageNumber: 1 }))
              }
            />
            {canManageScoreSystems && (
              <Button
                type="primary"
                icon={<Plus />}
                onClick={() =>
                  dispatch(generalActions.showModal({ name: Modal.AddEdit }))
                }
              >
                {formatMessage({
                  id: 'NewScoreSystem',
                })}
              </Button>
            )}
          </>
        }
      />
    </>
  );
};

export default ScoresHeader;
