import { createReducer } from '@reduxjs/toolkit';

import { schedulesActions as actions } from '@application/Schedules/store/schedulesActions';
import {
  schedulesAdapter,
  conciseSchedulesAdapter,
} from '@application/Schedules/store/entityAdapters';
import { getInitialSchedulesFilters } from '@application/Schedules/utils/getInitialSchedulesFilters';
import { SchedulesState } from '@application/Schedules/models/SchedulesState';

const initialTableState = {
  data: schedulesAdapter.getInitialState(),
  loading: false,
  meta: null,
  error: null,
  filters: {
    ...getInitialSchedulesFilters(),
  },
};

const detailsInitialState = {
  data: null,
  loading: false,
  error: null,
};

const initialState = {
  table: {
    ...initialTableState,
  },
  details: {
    ...detailsInitialState,
  },
  showFiltersModal: false,
  confirmDeleteModal: null,
  showPauseModal: false,
  addEditScheduleModal: {
    opened: false,
  },
  concise: {
    loading: false,
    data: conciseSchedulesAdapter.getInitialState(),
    error: null,
  },
};

export const schedulesReducer = createReducer<SchedulesState>(
  initialState,
  (builder) =>
    builder
      .addCase(actions.getSchedules.pending, (state, { meta: { arg } }) => {
        state.table.loading = true;
        state.table.error = null;
        state.table.filters =
          arg === null
            ? {
                ...getInitialSchedulesFilters(),
              }
            : {
                ...state.table.filters,
                ...arg,
              };
      })
      .addCase(actions.getSchedules.fulfilled, (state, { payload }) => {
        state.table.loading = false;
        state.table.meta = payload.meta;
        schedulesAdapter.setAll(state.table.data, payload.data);
      })
      .addCase(actions.getSchedules.rejected, (state, action) => {
        state.table.loading = false;
        state.table.error = action.payload || null;
      })
      .addCase(actions.toggleFiltersModal, (state, { payload }) => {
        state.showFiltersModal = payload;
      })
      .addCase(actions.resetTableData, (state) => {
        state.table = {
          ...state.table,
          data: schedulesAdapter.getInitialState(),
          loading: false,
          meta: null,
          error: null,
        };
      })
      .addCase(actions.toggleAddEditModal, (state, { payload }) => {
        state.addEditScheduleModal = {
          ...payload,
        };
      })
      .addCase(
        actions.changeStatus.fulfilled,
        (state, { payload: { id, ...changes } }) => {
          if (
            schedulesAdapter.getSelectors().selectById(state.table.data, id)
          ) {
            schedulesAdapter.updateOne(state.table.data, {
              id,
              changes,
            });
          }
        }
      )
      .addCase(actions.toggleDeleteModal, (state, { payload }) => {
        state.confirmDeleteModal = payload;
      })
      .addCase(actions.getConciseSchedules.pending, (state) => {
        state.concise.loading = true;
      })
      .addCase(actions.getConciseSchedules.fulfilled, (state, { payload }) => {
        state.concise.loading = false;
        conciseSchedulesAdapter.setAll(state.concise.data, payload);
      })
      .addCase(actions.getConciseSchedules.rejected, (state, { payload }) => {
        state.concise.loading = false;
        state.concise.error = payload || null;
      })
      .addCase(actions.getScheduleDetails.pending, (state) => {
        state.details.loading = true;
      })
      .addCase(actions.getScheduleDetails.fulfilled, (state, { payload }) => {
        state.details.loading = false;
        state.details.data = payload;
      })
      .addCase(actions.getScheduleDetails.rejected, (state, { payload }) => {
        state.details.loading = false;
        state.details.error = payload || null;
      })
      .addCase(actions.resetScheduleDetails, (state) => {
        state.details = {
          ...detailsInitialState,
        };
      })
);
