import dayjs, { Dayjs, ConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { ZonedDateTime } from '@repo/shared/types';
import { config } from '@repo/shared/config';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'a moment ago',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

export function date(
  d?: ConfigType,
  format?: string | undefined,
  strict?: boolean
): Dayjs {
  return dayjs(d, format, strict);
}

export function dateInTimeZone(
  date?: string | Dayjs,
  ianaTimeZone?: string | null,
  format?: string | undefined
): Dayjs {
  if (!ianaTimeZone) {
    return dayjs(date, format);
  }

  if (format) {
    return dayjs.tz(date, format, ianaTimeZone);
  }

  return dayjs.tz(date, ianaTimeZone);
}

export function dateUTC(
  date?: ConfigType,
  format?: string | undefined,
  strict?: boolean
): Dayjs {
  return dayjs.utc(date, format, strict);
}

export function getSystemTimeZone() {
  return dayjs.tz.guess();
}

export function formatDatetimeInformation(
  datetime: ZonedDateTime,
  format: string = config.dateFormat
): string {
  return `${dayjs(datetime.localTime).format(format)} ${
    datetime.timeZoneAbbreviation ? ` (${datetime.timeZoneAbbreviation})` : ''
  }`;
}

export default dayjs;
