import { createReducer, Reducer } from '@reduxjs/toolkit';

import { generalActions } from '../general/general.actions';
import { itemAnalysisReportActions as actions } from './itemAnalysis-report.actions';
import { IAuditItemsFlagsStats, IOrderByDirection } from '@repo/shared/types';
import { IItemAnalysisReportState } from '@types';
import {
  detailsModalAuditsAdapter,
  failedCriticalItemsAdapter,
  mostFailedItemsAdapter,
  templateSectionsAdapter,
} from '@store/entityAdapters';

const detailsModalInitialState = {
  loading: false,
  audits: detailsModalAuditsAdapter.getInitialState(),
  itemsByAudit: {},
  currentRequestId: null,
  meta: null,
  error: null,
  type: null,
  title: null,
  count: null,
  flag: null,
  filters: null,
  endpoint: null,
};

const initialState = {
  filters: {
    templateIds: [],
  },
  sectionAnalysis: {
    loading: false,
    data: null,
    error: null,
    page: 0,
    currentRequestId: null,
  },
  itemStats: {
    loading: false,
    data: null,
    error: null,
    currentRequestId: null,
  },
  auditItemsFlagsStats: {
    loading: false,
    data: null,
    error: null,
  },
  mostFailedItems: {
    loading: false,
    entities: mostFailedItemsAdapter.getInitialState(),
    filters: {
      pageSize: 15,
      pageNumber: 1,
      orderBy: 'failedCount',
      orderByDirection: 'desc' as IOrderByDirection,
      subSectionId: null,
      sectionId: null,
    },
    meta: null,
    error: null,
    currentRequestId: null,
  },
  failedCriticalItems: {
    loading: false,
    entities: failedCriticalItemsAdapter.getInitialState(),
    filters: {
      pageSize: 15,
      pageNumber: 1,
      orderBy: 'auditCompletedDate',
      orderByDirection: 'desc' as IOrderByDirection,
      subSectionId: null,
      sectionId: null,
    },
    meta: null,
    error: null,
    currentRequestId: null,
  },
  templateSections: {
    loading: false,
    data: templateSectionsAdapter.getInitialState(),
    error: null,
    currentRequestId: null,
  },
  detailsModal: {
    ...detailsModalInitialState,
  },
};

export const itemAnalysisReportReducer: Reducer<IItemAnalysisReportState> =
  createReducer<IItemAnalysisReportState>(initialState, (builder) =>
    builder
      .addCase(
        actions.getItemAnalysisReport.pending,
        (state, { meta: { arg } }) => {
          if (!!arg?.templateIds) {
            state.filters.templateIds = arg.templateIds;

            if (arg.templateIds.length === 1) {
              state.failedCriticalItems.filters.sectionId = null;
              state.failedCriticalItems.filters.subSectionId = null;
              state.mostFailedItems.filters.sectionId = null;
              state.mostFailedItems.filters.subSectionId = null;
            }
          }
        }
      )
      .addCase(actions.getSectionAnalysis.pending, (state, { meta }) => {
        state.sectionAnalysis.error = null;
        state.sectionAnalysis.data = null;
        state.sectionAnalysis.loading = true;
        state.sectionAnalysis.currentRequestId = meta.requestId;
      })
      .addCase(
        actions.getSectionAnalysis.fulfilled,
        (state, { payload, meta }) => {
          if (
            state.sectionAnalysis.loading &&
            state.sectionAnalysis.currentRequestId === meta.requestId
          ) {
            state.sectionAnalysis.data = payload;
            state.sectionAnalysis.loading = false;
            state.sectionAnalysis.currentRequestId = null;
          }
        }
      )
      .addCase(
        actions.getSectionAnalysis.rejected,
        (state, { payload, meta }) => {
          if (
            state.sectionAnalysis.loading &&
            state.sectionAnalysis.currentRequestId === meta.requestId
          ) {
            state.sectionAnalysis.loading = false;
            state.sectionAnalysis.error = payload || null;
            state.sectionAnalysis.currentRequestId = null;
          }
        }
      )
      .addCase(actions.getItemStats.pending, (state, { meta }) => {
        state.itemStats.error = null;
        state.itemStats.data = null;
        state.itemStats.loading = true;
        state.itemStats.currentRequestId = meta.requestId;
      })
      .addCase(actions.getItemStats.fulfilled, (state, { payload, meta }) => {
        if (
          state.itemStats.loading &&
          meta.requestId === state.itemStats.currentRequestId
        ) {
          state.itemStats.data = payload;
          state.itemStats.loading = false;
          state.itemStats.currentRequestId = null;
        }
      })
      .addCase(actions.getItemStats.rejected, (state, { payload, meta }) => {
        if (
          state.itemStats.loading &&
          state.itemStats.currentRequestId === meta.requestId
        ) {
          state.itemStats.loading = false;
          state.itemStats.error = payload || null;
          state.itemStats.currentRequestId = null;
        }
      })
      .addCase(
        actions.getMostFailedItems.pending,
        (state, { meta: { arg, requestId } }) => {
          state.mostFailedItems.loading = true;
          state.mostFailedItems.filters = {
            ...state.mostFailedItems.filters,
            ...(arg || {}),
          };
          state.mostFailedItems.currentRequestId = requestId;
        }
      )
      .addCase(
        actions.getMostFailedItems.fulfilled,
        (state, { payload, meta }) => {
          if (
            state.mostFailedItems.loading &&
            meta.requestId === state.mostFailedItems.currentRequestId
          ) {
            mostFailedItemsAdapter.setAll(
              state.mostFailedItems.entities,
              payload.data
            );
            state.mostFailedItems.meta = payload.meta;
            state.mostFailedItems.loading = false;
            state.mostFailedItems.currentRequestId = null;
          }
        }
      )
      .addCase(actions.getAuditItemsFlagsStats.pending, (state) => {
        state.auditItemsFlagsStats.error = null;
        state.auditItemsFlagsStats.data = null;
        state.auditItemsFlagsStats.loading = true;
      })
      .addCase(
        actions.getAuditItemsFlagsStats.fulfilled,
        (state, { payload }) => {
          state.auditItemsFlagsStats.data = payload.reduce(
            (acc: IAuditItemsFlagsStats, { count, itemFlag }) => {
              const a = acc;
              a[itemFlag] = count;
              return a;
            },
            {}
          );
          state.auditItemsFlagsStats.loading = false;
        }
      )
      .addCase(
        actions.getAuditItemsFlagsStats.rejected,
        (state, { payload }) => {
          state.auditItemsFlagsStats.loading = false;
          state.auditItemsFlagsStats.error = payload || null;
        }
      )
      .addCase(
        actions.getFailedCriticalItems.pending,
        (state, { meta: { arg, requestId } }) => {
          state.failedCriticalItems.loading = true;
          state.failedCriticalItems.filters = {
            ...state.failedCriticalItems.filters,
            ...(arg || {}),
          };
          state.failedCriticalItems.currentRequestId = requestId;
        }
      )
      .addCase(
        actions.getFailedCriticalItems.fulfilled,
        (state, { payload, meta }) => {
          if (
            state.failedCriticalItems.loading &&
            meta.requestId === state.failedCriticalItems.currentRequestId
          ) {
            failedCriticalItemsAdapter.setAll(
              state.failedCriticalItems.entities,
              payload.data
            );
            state.failedCriticalItems.meta = payload.meta;
            state.failedCriticalItems.loading = false;
            state.failedCriticalItems.currentRequestId = null;
          }
        }
      )
      .addCase(actions.getFailedCriticalItems.rejected, (state, { meta }) => {
        if (state.failedCriticalItems.loading && meta.requestId) {
          state.failedCriticalItems.loading = false;
          state.failedCriticalItems.currentRequestId = null;
        }
      })
      .addCase(actions.getTemplateSections.pending, (state, { meta }) => {
        state.templateSections.loading = true;
        state.templateSections.error = null;
        state.templateSections.currentRequestId = meta.requestId;
      })
      .addCase(
        actions.getTemplateSections.fulfilled,
        (state, { payload, meta }) => {
          if (
            state.templateSections.loading &&
            state.templateSections.currentRequestId === meta.requestId
          ) {
            state.templateSections.loading = false;
            templateSectionsAdapter.setAll(
              state.templateSections.data,
              payload
            );
            state.templateSections.error = null;
            state.templateSections.currentRequestId = null;
          }
        }
      )
      .addCase(
        actions.getTemplateSections.rejected,
        (state, { payload, meta }) => {
          if (
            state.templateSections.loading &&
            state.templateSections.currentRequestId === meta.requestId
          ) {
            state.templateSections.loading = false;
            state.templateSections.error = payload || null;
            state.templateSections.currentRequestId = null;
          }
        }
      )
      .addCase(actions.setSectionAnalysisPage.pending, (state, { meta }) => {
        state.sectionAnalysis.loading = true;
        state.sectionAnalysis.currentRequestId = meta.requestId;
      })
      .addCase(
        actions.setSectionAnalysisPage.fulfilled,
        (state, { payload, meta }) => {
          if (
            state.sectionAnalysis.loading &&
            state.sectionAnalysis.currentRequestId === meta.requestId
          ) {
            state.sectionAnalysis.page = payload;
            state.sectionAnalysis.loading = false;
            state.sectionAnalysis.currentRequestId = null;
          }
        }
      )
      .addCase(generalActions.resetAccountData, () => ({ ...initialState }))
      .addCase(
        actions.detailsModal.show,
        (state, { payload: { title, count, filters, type, endpoint } }) => {
          state.detailsModal.title = title;
          state.detailsModal.count = count !== undefined ? count : null;
          state.detailsModal.type = type;
          state.detailsModal.endpoint = endpoint || null;
          state.detailsModal.filters = {
            ...(filters || {}),
          };
        }
      )
      .addCase(actions.detailsModal.hide, (state) => {
        state.detailsModal = {
          ...detailsModalInitialState,
        };
      })
      .addCase(
        actions.detailsModal.getData.pending,
        (state, { meta: { requestId } }) => {
          state.detailsModal.loading = true;
          state.detailsModal.currentRequestId = requestId;
        }
      )
      .addCase(
        actions.detailsModal.getData.fulfilled,
        (
          state,
          { payload: { meta, itemsByAudit, audits }, meta: { requestId } }
        ) => {
          if (requestId !== state.detailsModal.currentRequestId) {
            return;
          }

          state.detailsModal.loading = false;
          state.detailsModal.meta = meta;
          state.detailsModal.currentRequestId = null;

          detailsModalAuditsAdapter.setMany(state.detailsModal.audits, audits);

          for (let auditId in itemsByAudit) {
            state.detailsModal.itemsByAudit[auditId] = [
              ...(state.detailsModal.itemsByAudit[auditId] || []),
              ...itemsByAudit[auditId],
            ];
          }
        }
      )
      .addCase(actions.detailsModal.getData.rejected, (state, { payload }) => {
        state.detailsModal.loading = false;
        state.detailsModal.currentRequestId = null;
        state.detailsModal.error = payload || null;
      })
  );
