import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { BillingPeriod } from '@repo/shared/enums';

export default function useBillingPeriodsNames() {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      [BillingPeriod.Monthly]: formatMessage({ id: 'Monthly' }),
      [BillingPeriod.Annually]: formatMessage({ id: 'Annually' }),
    }),
    []
  );
}
