import React from 'react';

import { Container } from './styled';

interface IProps {
  value: number;
  color: string;
}

const FailedInRowCount: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  color,
}) => {
  return (
    <Container color={color} fontSizePx={value > 9 ? 7 : 9}>
      {value > 9 ? '9+' : value}
    </Container>
  );
};

export default FailedInRowCount;
