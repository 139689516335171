import React from 'react';

import { AssigneesModalContext } from '@repo/shared/enums';
import { e2eTestElements } from '@config';
import { IConcise } from '@repo/shared/types';

import Assignees from '@components/shared/Assignees/Assignees';

interface IProps {
  auditObjectId?: string;
  value?: IConcise[];
  onChange?: (update: IConcise[]) => void;
  disabled?: boolean;
  context: AssigneesModalContext;
  e2eDataAttr?: string;
}

const AssigneesFormItem: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  value,
  onChange,
  disabled,
  context,
  e2eDataAttr,
}) => {
  return (
    <Assignees
      auditObjectId={auditObjectId}
      context={context}
      e2eDataAttr={
        e2eDataAttr ||
        e2eTestElements.audits.performAudit.addEditActionModal.assignedTo
      }
      disabled={disabled}
      users={value}
      onChange={async (users) => {
        if (onChange) {
          onChange(users);
        }
      }}
      showJobTitles
    />
  );
};

export default AssigneesFormItem;
