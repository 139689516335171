import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@hooks';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';

import AddEditComment from '@src/presentation/Actions/ActionDetails/AddEditComment/AddEditComment';

interface IProps {}

const AddEditActionComment: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  const editingComment = useSelector(actionsSelectors.getEditingComment);
  const action = useSelector(actionsSelectors.getActionDetailsAction);

  return (
    <AddEditComment
      comment={editingComment}
      onSend={async (newComment) => {
        if (!action) {
          return false;
        }

        const actionCreator = editingComment
          ? actionsActions.editActionComment
          : actionsActions.createActionComment;

        const resultAction = await dispatch(
          actionCreator({
            actionId: action.id,
            comment: newComment,
          })
        );

        const success = actionCreator.fulfilled.match(resultAction);

        if (success) {
          dispatch(actionsActions.setEditCommentId(null));
        }

        return success;
      }}
      ianaTimeZone={action?.audit?.ianaTimeZone}
    />
  );
};

export default AddEditActionComment;
