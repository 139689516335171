import styled from 'styled-components';

import LibraryButton from '../../shared/ant/Button';

export const Container = styled.div`
  width: 320px;
`;

export const Description = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 7px;
`;

export const Section = styled.div`
  margin-bottom: 32px;

  h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

export const Button = styled(LibraryButton)`
  margin-top: 15px;
`;
