export const dashboard = {
  filters: {
    templateSelect: 'dashboard-filters-template-select',
  },
  totals: {
    upcomingAudits: 'dashboard-upcomingAudits',
    readyToStart: 'dashboard-readyToStart',
    dueSoon: 'dashboard-dueSoon',
    inProgress: 'dashboard-inProgress',
    pastDue: 'dashboard-pastDue',
    allAudits: 'dashboard-allAudits',
  },
};
