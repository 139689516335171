import React from 'react';

import { Button, Description, Text } from './styled';

interface IProps {
  onClick: () => void;
  desc: string;
  text: string;
  icon: React.ReactNode;
  e2eDataAttr?: string;
}

const BlueIconButton: React.FC<React.PropsWithChildren<IProps>> = ({
  onClick,
  desc,
  icon,
  text,
  e2eDataAttr,
  ...otherProps
}) => {
  return (
    <Button
      {...otherProps}
      type="button"
      data-e2e={e2eDataAttr}
      onClick={onClick}
    >
      {icon}
      <div>
        <Description>{desc}</Description>
        <Text>{text}</Text>
      </div>
    </Button>
  );
};

export default BlueIconButton;
