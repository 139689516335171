import React from 'react';
import { TagProps } from 'antd/es/tag';
import { X } from 'react-feather';

import { StyledTag } from './styled';

interface IProps extends TagProps {
  tagcolor?: string;
}

const MAX_TAG_LENGTH = 20;

const Tag: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  if (!props.children) {
    return null;
  }

  const tagName = props.children.toString();

  return (
    <StyledTag {...props} closeIcon={<X />}>
      {tagName.length > MAX_TAG_LENGTH
        ? `${tagName.substring(0, MAX_TAG_LENGTH)}...`
        : tagName}
    </StyledTag>
  );
};

export default Tag;
