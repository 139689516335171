import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AuditsGenerationMode } from '@domain/Schedules/models/Schedule';
import { enumToSelectOptions } from '@utils';
import { accountSelectors } from '@store';

import Form from '@components/shared/ant/Form';
import RadioGroup from '@components/shared/ant/RadioGroup';

const selectOptions =
  enumToSelectOptions<AuditsGenerationMode>(AuditsGenerationMode);

const langIds = {
  [AuditsGenerationMode.AuditPerAuditor]: 'CreateAuditPerAuditor',
  [AuditsGenerationMode.AuditPerLocation]: 'CreateAuditPerLocation',
};

interface IProps {
  disabled?: boolean;
}

const AuditGenerationModeFormItem: React.FC<
  React.PropsWithChildren<IProps>
> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const objectName = useSelector(accountSelectors.getObjectName);

  const options = useMemo(() => {
    return selectOptions.map(({ value }) => ({
      label: formatMessage(
        { id: langIds[value] },
        { objectName: objectName.single.toLowerCase() }
      ),
      value,
    }));
  }, []);

  return (
    <Form.Item
      name="auditsGenerationMode"
      label={formatMessage({ id: 'AuditsGenerationMode' })}
      rules={[
        {
          required: true,
          message: formatMessage({ id: 'RequiredField' }),
        },
      ]}
    >
      <RadioGroup<AuditsGenerationMode> disabled={disabled} options={options} />
    </Form.Item>
  );
};

export default AuditGenerationModeFormItem;
