export const users = {
  createDropdown: 'users-header-create-dropdown',
  createUser: 'users-header-create-users',
  createUserGroup: 'users-header-create-user-group',
  table: 'users-table',
  groupsTable: 'users-groups-table',
  addEditUserForm: {
    closeModal: 'users-add-edit-close-modal',
    fullNameInput: 'users-add-edit-full-name-input',
    emailInput: 'users-add-edit-email-input',
    phoneInput: 'users-add-edit-phone-input',
    roleSelect: 'users-add-edit-roleSelect',
    addEditButton: 'users-add-edit-button',
    cancelButton: 'users-add-edit-cancel-button',
  },
  groupsAssignments: {
    table: 'users-groupsAssignments-table',
    openAddToGroupModal: 'users-groupsAssignments-openAddToGroupModal',
    closeAddToGroupModal: 'users-groupsAssignments-closeAddToGroupModal',
    selectGroupDropdown: 'users-groupsAssignments-selectGroupDropdown',
    selectUsersDropdown: 'users-groupsAssignments-selectUsersDropdown',
    addToGroupBtn: 'users-groupsAssignments-addToGroupBtn',
    cancelBtn: 'users-groupsAssignments-cancelBtn',
  },
  access: {
    table: 'users-access-table',
    openAssignModal: 'users-access-openAssignModal',
    closeAssignModal: 'users-access-closeAssignModal',
    selectUsersDropdown: 'users-access-selectUsersDropdown',
    selectJobTitleDropdown: 'users-access-selectJobTitleDropdown',
    selectObjectsDropdown: 'users-access-selectObjectsDropdown',
    assignBtn: 'users-access-assignBtn',
    cancelBtn: 'users-access-cancelBtn',
  },
  jobTitles: {
    table: 'users-jobTitles-table',
    openAddEditModal: 'users-jobTitles-openAddEditModal',
    closeAddEditModal: 'users-jobTitles-closeAddEditModal',
    jobTitleNameInput: 'users-jobTitles-jobTitleNameInput',
    jobTitleDescInput: 'users-jobTitles-jobTitleDescInput',
    addBtn: 'users-jobTitles-addBtn',
    cancelBtn: 'users-jobTitles-cancelBtn',
  },
  pages: {
    profile: 'users-pages-profile',
    groups: 'users-pages-groups',
    access: 'users-pages-access',
  },
};
