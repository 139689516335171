import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import AntModal from 'antd/es/modal';
import Skeleton from 'antd/es/skeleton';
import { Copy, X, AlertCircle } from 'react-feather';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router';
import { issuesActions } from '@application/Issues/store';

import {
  Container,
  QrCodeContainer,
  QrCodeImg,
  LinkHeader,
  LinkContainer,
  Link,
  CopyBtn,
  LinkDesc,
  SkeletonContainer,
  ErrorContainer,
} from './styled';
import { useAppDispatch } from '@hooks';
import { accountSelectors } from '@store';
import { e2eTestElements, routes } from '@config';
import { notification } from '@utils';
import { getQueryString } from '@repo/shared/utils';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';
import { Logger } from '@repo/shared/services';

import Button from '@components/shared/ant/Button';

interface IProps {}

function getQRCodeUrl({
  issueTypeQrCode,
  companyId,
}: {
  issueTypeQrCode: IssueTypeQrCode;
  companyId: string;
}) {
  return `${window.location.origin}${routes.createIssue}?${getQueryString({
    issueTypeQrCodeId: issueTypeQrCode.id,
    companyId,
  })}`;
}

function getFileName({ issueType, description }: IssueTypeQrCode) {
  return `${issueType.name}${description ? `-${description}` : ''}-qr-code.png`;
}

const IssueTypeQrCodeModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [qrCodeDataUrl, setQrCodeDataUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasNotLinkedError, setHasNotLinkedError] = useState(false);

  const companyId = useSelector(accountSelectors.getCurrentCompanyId);
  const isAdmin = useSelector(accountSelectors.isAdmin);
  const objectName = useSelector(accountSelectors.getObjectName);
  const modalState = useSelector(issueTypeQrCodesSelectors.getQrCodeModalState);

  const qrCodeUrl = useMemo(() => {
    if (!modalState) {
      return null;
    }

    return getQRCodeUrl({
      issueTypeQrCode: modalState,
      companyId,
    });
  }, [modalState]);

  useEffect(() => {
    async function getIssueContext(issueTypeQrCodeId: string) {
      setLoading(true);

      const resultAction = await dispatch(
        issuesActions.getIssueContext({
          companyId,
          issueTypeQrCodeId,
        })
      );

      if (
        issuesActions.getIssueContext.rejected.match(resultAction) &&
        resultAction.payload
      ) {
        if (resultAction.payload === 'issue-type-public-link/not-found') {
          setHasNotLinkedError(true);
        } else {
          notification.error({
            message: 'ErrorWhileLoadingIssueDetails',
          });

          closeModal();
        }
      }

      setLoading(false);
    }

    if (modalState) {
      getIssueContext(modalState.id);
    }
  }, [modalState]);

  useEffect(() => {
    if (qrCodeUrl) {
      QRCode.toDataURL(qrCodeUrl, { width: 256, margin: 1 }, (err, url) => {
        if (err) {
          Logger.captureException(err);

          notification.error({
            message: formatMessage({
              id: 'ErrorWhileGeneratingQRCode',
            }),
          });

          return;
        }

        setQrCodeDataUrl(url);
      });
    }
  }, [qrCodeUrl]);

  const closeModal = useCallback(() => {
    dispatch(issueTypeQrCodesActions.toggleQrCodeModal(null));
    setQrCodeDataUrl(null);
    setHasNotLinkedError(false);
  }, []);

  return (
    <AntModal
      open={modalState !== null}
      title={formatMessage({ id: 'QrCodeForIssueReporting' })}
      width={550}
      closeIcon={<X />}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      styles={{
        body: {
          marginBottom: 0,
        },
      }}
    >
      <Container style={{ alignItems: loading ? 'flex-start' : 'center' }}>
        {loading ? (
          <SkeletonContainer>
            <Skeleton active />
          </SkeletonContainer>
        ) : (
          <>
            {hasNotLinkedError ? (
              <ErrorContainer>
                <AlertCircle />
                <h3>
                  {formatMessage(
                    { id: 'QRCodeIsNotLinkedToAnyAuditObjectErrorTitle' },
                    {
                      objectNamePlural: objectName.plural.toLowerCase(),
                      objectNameSingle: objectName.single.toLowerCase(),
                    }
                  )}
                </h3>
                <p>
                  {formatMessage(
                    { id: 'QRCodeIsNotLinkedToAnyAuditObjectErrorDesc' },
                    {
                      objectNamePlural: objectName.plural.toLowerCase(),
                      objectNameSingle: objectName.single.toLowerCase(),
                    }
                  )}
                </p>
                {isAdmin && (
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate(routes.auditObjects.groupsList);
                    }}
                  >
                    {formatMessage(
                      { id: 'GoToAuditObjectGroups' },
                      { objectName: objectName.plural.toLowerCase() }
                    )}
                  </Button>
                )}
              </ErrorContainer>
            ) : (
              <>
                <QrCodeContainer>
                  {!!qrCodeDataUrl && (
                    <QrCodeImg
                      src={qrCodeDataUrl}
                      data-e2e={
                        e2eTestElements.issues.issueTypes.qrCodes.qrCodeModal
                          .qrCode
                      }
                    />
                  )}
                </QrCodeContainer>
                <Button
                  type="primary"
                  data-e2e={
                    e2eTestElements.issues.issueTypes.qrCodes.qrCodeModal
                      .saveQrCodeBtn
                  }
                  onClick={() => {
                    if (!qrCodeDataUrl || !modalState) {
                      return;
                    }

                    fetch(qrCodeDataUrl)
                      .then((res) => res.blob())
                      .then((blob) => {
                        saveAs(blob, getFileName(modalState));

                        notification.success({
                          message: formatMessage({
                            id: 'QRCodeHasBeenSuccessfullyDownloaded',
                          }),
                        });
                      });
                  }}
                >
                  {formatMessage({ id: 'SaveQRCode' })}
                </Button>
                {!!qrCodeUrl && (
                  <>
                    <LinkHeader>
                      {formatMessage({ id: 'PublicAccessLink' })}:
                    </LinkHeader>
                    <LinkDesc>
                      {formatMessage({ id: 'QrCodeLinkDesc' })}
                    </LinkDesc>
                    <LinkContainer>
                      <Link
                        href={qrCodeUrl}
                        target="_blank"
                        data-e2e={
                          e2eTestElements.issues.issueTypes.qrCodes.qrCodeModal
                            .link
                        }
                      >
                        {qrCodeUrl}
                      </Link>
                      <CopyBtn
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(qrCodeUrl);

                          notification.success({
                            message: formatMessage({
                              id: 'PublicAccessLinkHasBeenCopiedToClipboard',
                            }),
                          });
                        }}
                      >
                        <Copy />
                      </CopyBtn>
                    </LinkContainer>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </AntModal>
  );
};

export default IssueTypeQrCodeModal;
