import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';

import { Buttons, ClearBtn, FilterGroupHeader, FilterQty } from './styled';
import { accountSelectors } from '@store';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { getInitialActionsFilters } from '@application/Actions/store/actionsReducer';
import { useAppDispatch } from '@hooks';
import { SchedulesFilters } from '@application/Schedules/models/SchedulesFilters';

import SlideModal from '@components/shared/SideModal/SideModal';
import AuditObjectsAndAuditObjectGroupsSelect from '@components/shared/EntitySelects/AuditObjectsAndAuditObjectGroupsSelect';
import Form from '@components/shared/ant/Form';
import Select from '@components/shared/ant/Select/Select';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';

const { Option } = AntSelect;

interface IProps {}

const SchedulesFiltersModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const isVisible = useSelector(schedulesSelectors.isFiltersModalOpened);
  const { orderBy, orderByDirection, ...filters } = useSelector(
    schedulesSelectors.getFilters,
    shallowEqual
  );
  const selectedFiltersQty = useSelector(
    schedulesSelectors.getNumberOfSelectedFilters
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  useEffect(() => {
    if (isVisible) {
      form.resetFields();
    }
  }, [form, isVisible]);

  const getSchedules = useCallback(
    (update: Partial<SchedulesFilters>) =>
      dispatch(schedulesActions.getSchedules(update)),
    [dispatch]
  );

  const closeModal = useCallback(
    () => dispatch(schedulesActions.toggleFiltersModal(false)),
    [dispatch]
  );

  return (
    <SlideModal
      opened={isVisible}
      onModalClose={() => {
        closeModal();
      }}
    >
      <Form
        form={form}
        name="schedules-filters-form"
        initialValues={{
          auditObject: {
            auditObjectIds: filters.auditObjectIds,
            auditObjectGroupIds: filters.auditObjectGroupIds,
          },
          auditRepeatPattern: filters.auditRepeatPattern,
        }}
      >
        <FilterGroupHeader>{`${auditObjectName.plural} ${formatMessage({
          id: 'or',
        })} ${formatMessage({ id: 'Groups' })}`}</FilterGroupHeader>
        <Form.Item name="auditObject">
          <AuditObjectsAndAuditObjectGroupsSelect
            allowClear
            onChange={({ auditObjectGroupIds, auditObjectIds }) =>
              getSchedules({
                auditObjectGroupIds,
                auditObjectIds,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'Recurrence' })}
        </FilterGroupHeader>
        <Form.Item name="auditRepeatPattern">
          <Select
            onChange={(auditRepeatPattern: AuditRepeatPattern | -1) => {
              getSchedules({
                auditRepeatPattern:
                  auditRepeatPattern === -1 ? null : auditRepeatPattern,
                pageNumber: 1,
              });
            }}
            placeholder={formatMessage({ id: 'Frequency' })}
          >
            <Option value={-1}>{formatMessage({ id: 'All' })}</Option>
            <Option value={AuditRepeatPattern.OneTime}>
              {formatMessage({ id: 'OneTime' })}
            </Option>
            <Option value={AuditRepeatPattern.Daily}>
              {formatMessage({ id: 'Daily' })}
            </Option>
            <Option value={AuditRepeatPattern.Weekly}>
              {formatMessage({ id: 'Weekly' })}
            </Option>
            <Option value={AuditRepeatPattern.MultipleWeeks}>
              {formatMessage({ id: 'MultipleWeeks' })}
            </Option>
            <Option value={AuditRepeatPattern.Monthly}>
              {formatMessage({ id: 'Monthly' })}
            </Option>
          </Select>
        </Form.Item>
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                const { search, ...initialModalFilters } =
                  getInitialActionsFilters();
                getSchedules(initialModalFilters);
                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default SchedulesFiltersModal;
