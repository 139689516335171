export const issues = {
  issues: {
    table: 'issues-table',
    createIssue: 'issues-header-create-issue',
    createModal: {
      nameInput: 'issue-add-edit-modal-name-input',
      typeSelect: 'issue-add-edit-modal-type-select',
      objectSelect: 'issue-add-edit-modal-object-select',
      nextBtn: 'issue-add-edit-modal-next-btn',
      cancelBtn: 'issue-add-edit-modal-cancel-btn',
      questionAnswer: 'issue-add-edit-modal-question-answer',
      addQuestionAnswerFiles: 'issue-add-edit-modal-add-question-answer-files',
    },
    details: {
      closeModalBtn: 'issue-details-close-btn',
      closeIssueBtn: 'issue-details-close-issue-btn',
      reopenIssueBtn: 'issue-details-reopen-issue-btn',
      deleteBtn: 'issue-details-delete-btn',
      title: 'issue-details-title',
      subTitle: 'issue-details-sub-title',
      number: 'issue-details-number',
      object: 'issue-details-object',
      status: 'issue-details-status',
      createdBy: 'issue-details-created-by',
      assignTo: 'issue-details-assignTo',
      prioritySelect: 'issue-details-priority-select',
      dueDate: 'issue-details-due-date',
      actionsLinkedList: 'issue-details-actions-linked-list',
      actionsLinkedListAddBtn: 'issue-details-actions-linked-list-add-btn',
      auditsLinkedList: 'issue-details-audits-linked-list',
      auditsLinkedListAddBtn: 'issue-details-audits-linked-list-add-btn',
      event: 'issue-details-event',
      eventUser: 'issue-details-event-user',
      eventText: 'issue-details-event-text',
    },
    closeIssue: {
      reasonSelect: 'close-issue-reason-select',
      reasonComment: 'close-issue-reason-comment',
      saveBtn: 'close-issue-save-btn',
      cancelBtn: 'close-issue-cancel-btn',
    },
    reopenIssue: {
      comment: 'reopen-issue-reason-comment',
      saveBtn: 'reopen-issue-save-btn',
      cancelBtn: 'reopen-issue-cancel-btn',
    },
  },
  issueTypes: {
    createIssueType: 'issues-header-create-issue-type',
    table: 'issue-types-table',
    details: {
      saveBtn: 'issue-type-details-save-btn',
      backBtn: 'issue-type-details-back-btn',
      detailsTab: 'issue-type-details-details-tab',
      qrCodesTab: 'issue-type-details-qr-codes-tab',
      deleteBtn: 'issue-type-details-delete-btn',
      nameInput: 'issue-type-details-form-name-input',
      dueDateSelect: 'issue-types-details-due-date-select',
      prioritySelect: 'issue-types-details-priority-select',
      autoAssignSelect: 'issue-type-details-auto-assign-select',
      description: 'issue-type-details-description',
    },
    qrCodes: {
      qrCodesTable: 'issue-type-qrCodes-table',
      createBtn: 'issue-type-qrCodes-create-btn',
      addEditModal: {
        saveBtn: 'issue-type-qrCodes-add-edit-modal-save-btn',
        cancelBtn: 'issue-type-qrCodes-add-edit-modal-cancel-btn',
        issueTypeSelect: 'issue-type-qrCodes-add-edit-modal-issue-type-select',
        allObjectsSwitch:
          'issue-type-qrCodes-add-edit-modal-all-objects-switch',
        objectsGroupsSelect:
          'issue-type-qrCodes-add-edit-modal-objects-groups-select',
        description: 'issue-type-qrCodes-add-edit-modal-description',
      },
      qrCodeModal: {
        qrCode: 'issue-type-qrCodes-qr-code-modal-qr-code',
        saveQrCodeBtn: 'issue-type-qrCodes-qr-code-modal-save-btn',
        link: 'issue-type-qrCodes-qr-code-modal-link',
      },
    },
  },
  issueTypeFields: {
    createIssueTypeField: 'issues-header-create-issue-type-field',
    table: 'issue-type-fields-table',
    addEditField: {
      saveBtn: 'issue-type-fields-add-edit-field-save-btn',
      closeBtn: 'issue-type-fields-close-add-edit-field',
      nameInput: 'issue-type-fields-add-edit-field-name-input',
      typeSelect: 'issue-type-fields-add-edit-field-type-select',
      requiredSwitch: 'issue-type-fields-add-edit-field-required-switch',
      allowAttachmentsSwitch:
        'issue-type-fields-add-edit-field-allow-attachments-switch',
      requiredPhotoSwitch:
        'issue-type-fields-add-edit-field-required-photos-switch',
      addFieldBtn: 'issue-type-fields-add-field-btn',
      addContactFieldBtn: 'issue-type-fields-add-contat-field-btn',
    },
    addEditContactField: {
      requiredSwitch:
        'issue-type-fields-add-edit-contact-field-required-switch',
      typeSelect: 'issue-type-fields-add-edit-contact-field-type-select',
    },
  },
  deleteModal: {
    confirmBtn: 'issue-types-delete-modal-confirm-btn',
    cancelBtn: 'issue-types-delete-modal-cancel-btn',
  },
};
