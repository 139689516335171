import { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { actionsActions } from '@application/Actions/store/actionsActions';

export function useSetActionsPage(page: ActionsPage) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionsActions.setPage(page));
  }, []);
}
