import { createReducer, Reducer } from '@reduxjs/toolkit';

import { customReportActions as actions } from './custom-report.actions';
import { generalActions } from '../general/general.actions';
import { ITableColumn } from '@repo/shared/types';
import { ICustomReportState } from '@types';
import { AuditColumns, DataCategoryType } from '@repo/shared/enums';
import { config } from '@repo/shared/config';

const initialState = {
  filters: {
    dataCategory: DataCategoryType.Audits,
    selectedColumns: [],
    orderBy: null,
    orderByDirection: 'desc',
    pageNumber: 1,
    pageSize: config.pageSize,
  },
  columns: {
    ids: [],
    entities: {} as Record<AuditColumns, ITableColumn>,
  },
  totalCount: 0,
  rows: null,
  loading: false,
  isDataCategoryFetch: false,
  error: null,
  exportModalType: null,
};

export const customReportReducer: Reducer<ICustomReportState> =
  createReducer<ICustomReportState>(initialState, (builder) =>
    builder
      .addCase(actions.getCustomReport.pending, (state, { meta: { arg } }) => {
        if (!arg || arg.dataCategory !== undefined) {
          state.isDataCategoryFetch = true;
        }

        state.filters = {
          ...state.filters,
          ...(arg || {}),
        };

        if (!arg?.pageNumber) {
          state.filters.pageNumber = 1;
        }

        state.loading = true;
        state.rows = null;
        state.error = null;
      })
      .addCase(actions.getCustomReport.fulfilled, (state, { payload }) => {
        state.isDataCategoryFetch = false;
        state.loading = false;
        state.filters.selectedColumns = payload.data.selectedColumns;
        state.totalCount = payload.meta.totalCount;
        state.columns = payload.columns.reduce(
          (acc: any, col: ITableColumn) => {
            const a = acc;
            a.ids.push(col.type);
            a.entities[col.type] = col;
            return a;
          },
          {
            entities: {},
            ids: [],
          }
        );
        state.rows = payload.data.rows;
      })
      .addCase(actions.getCustomReport.rejected, (state, { payload }) => {
        state.isDataCategoryFetch = false;
        state.loading = false;
        state.error = payload || null;
      })
      .addCase(actions.toggleExportModal, (state, { payload }) => {
        state.exportModalType = payload;
      })
      .addCase(generalActions.resetAccountData, () => ({ ...initialState }))
  );
