import React from 'react';
import { useIntl } from 'react-intl';

import { IssueCloseReason } from '@domain/Issues/models/IssueEvent';

interface IProps {
  reason: IssueCloseReason;
}

const langIds = {
  [IssueCloseReason.NotAnIssue]: 'NotAnIssue',
  [IssueCloseReason.Other]: 'Other',
  [IssueCloseReason.Fixed]: 'Fixed',
  [IssueCloseReason.Duplicate]: 'Duplicate',
  [IssueCloseReason.CantReproduce]: 'CantReproduce',
  [IssueCloseReason.ImpossibleToFix]: 'ImpossibleToFix',
};

const IssueCloseReasonName: React.FC<React.PropsWithChildren<IProps>> = ({
  reason,
}) => {
  const { formatMessage } = useIntl();

  return <>{formatMessage({ id: langIds[reason] })}</>;
};

export default IssueCloseReasonName;
