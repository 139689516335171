import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import Spin from 'antd/es/spin';

import { IApiService } from '@repo/shared/types';
import { InternalApiService } from '@repo/shared/api';
import { FileStatus } from '@repo/shared/enums';
import { Logger } from '@repo/shared/services';
import { useFileStatus } from './useFileStatus';
import { useIsVisible } from '@repo/shared/hooks';
import { Container } from './styled';
import { AuthenticationError } from '@repo/shared/errors';

export interface ImageContainerProps {
  fileId: string;
  getFilePath?: (fileId: string) => string;
  getMetadataPath?: (fileId: string) => string;
  children: ({
    loading,
    hasError,
    isOffline,
    isWaitingUpload,
    imageUrl,
    onLoadImage,
    onLoadImageError,
  }: {
    loading: boolean;
    isOffline: boolean;
    isWaitingUpload: boolean;
    hasError: boolean;
    imageUrl: string | undefined;
    onLoadImage: () => void;
    onLoadImageError: (e: SyntheticEvent<HTMLImageElement>) => void;
  }) => React.ReactNode;
  spinSize?: 'small' | 'default' | 'large';
  skipCompanyId?: boolean;
  companyId?: string;
  blurBackgroundOnLoad?: boolean;
  apiService?: IApiService;
  skipStatusCheck?: boolean;
  onClick?: () => void;
  extra?: React.ReactNode;
}

const ImageContainer: React.FC<ImageContainerProps> = ({
  fileId,
  getFilePath = (fileId) => `files/${fileId}`,
  getMetadataPath,
  children,
  spinSize,
  skipCompanyId,
  companyId,
  blurBackgroundOnLoad = true,
  skipStatusCheck,
  apiService = InternalApiService.getInstance(),
  onClick,
  extra,
}) => {
  const status = useFileStatus({
    fileId,
    apiService,
    getMetadataPath,
    skipCompanyId,
    skipStatusCheck,
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible(ref);
  const [loading, setLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [hasError, setHasError] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [isWaitingUpload, setIsWaitingUpload] = useState(false);

  useEffect(() => {
    async function loadFile(fileId: string) {
      setLoading(true);

      try {
        const blob = await apiService.getFile<Blob>({
          id: fileId,
          path: getFilePath(fileId),
          responseType: 'blob',
          skipCompanyId,
          companyId,
          cacheResponse: true,
        });

        setImageUrl(URL.createObjectURL(blob));
      } catch (e: unknown) {
        if (e instanceof AuthenticationError) {
          console.error('auth/invalid-token');
        } else {
          Logger.captureException(e);
        }

        setHasError(true);
      }

      setLoading(false);
    }

    if (
      isVisible &&
      (status === FileStatus.Processed || status === FileStatus.PreviewReady)
    ) {
      if (!imageUrl) {
        loadFile(fileId);
      }

      if (isOffline) {
        setIsOffline(false);
      }

      setIsWaitingUpload(false);
    } else if (status === FileStatus.WaitingUpload) {
      setLoading(false);
      setIsWaitingUpload(true);
    } else if (status === FileStatus.Error) {
      setIsWaitingUpload(false);
      setHasError(true);
      setLoading(false);
    } else if (status === FileStatus.Offline && !imageUrl) {
      setIsWaitingUpload(false);
      setIsOffline(true);
      setLoading(false);
    }
  }, [status, isVisible]);

  return (
    <Container
      ref={ref}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <Spin
        size={spinSize}
        spinning={loading}
        wrapperClassName={blurBackgroundOnLoad ? undefined : 'spin-no-blur'}
      >
        {children({
          loading,
          hasError,
          imageUrl,
          isOffline,
          isWaitingUpload,
          onLoadImage: () => {
            setLoading(false);
          },
          onLoadImageError: async () => {
            if (hasError) {
              return;
            }

            setHasError(true);
          },
        })}
      </Spin>
      {extra}
    </Container>
  );
};

export default ImageContainer;
