import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '@domain/Actions/models/Action';
import { useAppDispatch, usePermission } from '@hooks';
import {
  accountSelectors,
  actionsActions,
  actionsSelectors,
  generalActions,
} from '@store';
import { ActionStatus, Modal, Permission, RoleType } from '@repo/shared/enums';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  action: Action;
  context: 'modal' | 'table';
}

const ActionsThreeDotMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
  context,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentPage = useSelector(actionsSelectors.getPage);
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const currentUserRoleType = currentUser?.role.roleType;
  const isAdminOrAuditor =
    currentUserRoleType === RoleType.Auditor ||
    currentUserRoleType === RoleType.Admin;

  const [canApproveCorrectiveActions, canDoCorrectiveActions] = usePermission([
    Permission.CanApproveCorrectiveActions,
    Permission.CanDoCorrectiveActions,
  ]);
  const isMarkedAsDone = action.status === ActionStatus.Submitted;

  let menuItems = [];

  if (context === 'table') {
    menuItems.push({
      name: formatMessage({ id: 'Details' }),
      onClick: () => {
        dispatch(generalActions.selectTableRows([]));
        dispatch(actionsActions.toggleActionDetailsModal(action.id));
      },
    });
  }

  if (context === 'modal') {
    if (
      action.canMarkAsDone &&
      !isMarkedAsDone &&
      canDoCorrectiveActions &&
      (action.status === ActionStatus.Opened || !isAdminOrAuditor)
    ) {
      menuItems = [
        ...menuItems,
        {
          name: formatMessage({
            id: 'MarkAsDone',
          }),
          onClick: () => {
            dispatch(
              actionsActions.toggleChangeStatusModal({
                status: ActionStatus.Submitted,
                ids: [action.id],
              })
            );
          },
        },
      ];
    } else if (action.canApprove && canApproveCorrectiveActions) {
      menuItems = [
        ...menuItems,
        ...(action.status !== ActionStatus.Approved
          ? [
              {
                name: formatMessage({ id: 'Approve' }),
                onClick: () => {
                  dispatch(
                    actionsActions.toggleChangeStatusModal({
                      status: ActionStatus.Approved,
                      ids: [action.id],
                    })
                  );
                },
              },
            ]
          : []),
        ...(action.status !== ActionStatus.Rejected
          ? [
              {
                name: formatMessage({ id: 'Reject' }),
                onClick: () => {
                  dispatch(
                    actionsActions.toggleChangeStatusModal({
                      status: ActionStatus.Rejected,
                      ids: [action.id],
                    })
                  );
                },
              },
            ]
          : []),
      ];
    }
  }

  menuItems.push({
    name: formatMessage({ id: 'Delete' }),
    onClick: () =>
      dispatch(
        generalActions.showModal({
          name: Modal.ConfirmDelete,
          data: action,
        })
      ),
  });

  if (currentPage === ActionsPage.Pending) {
    menuItems.push({
      name: formatMessage({ id: 'Expire' }),
      onClick: () => {
        dispatch(
          generalActions.showModal({
            name: Modal.ConfirmExpire,
            data: [action.id],
          })
        );
      },
    });

    if (context === 'table') {
      menuItems.push({
        name: formatMessage({ id: 'AssignUsers' }),
        onClick: () => {
          dispatch(
            actionsActions.toggleAssignUsersToActionsModal({
              actionsIds: [action.id],
              auditObjectId: action.auditObject?.id!,
            })
          );
        },
      });
    }
  }

  return <TableRowActionsMenu menuItems={menuItems} />;
};

export default ActionsThreeDotMenu;
