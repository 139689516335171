import React from 'react';

import { Container, Description, Title } from './styled';
import { colors } from '@repo/shared/config';

import Switch from '../ant/Switch';
import Popover from '@components/shared/ant/Popover';

interface IProps {
  title: string | React.ReactNode;
  description?: string;
  onChange?: (checked: boolean) => void;
  value?: boolean;
  switchPosition?: 'start' | 'end';
  disabled?: boolean;
  containerStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  e2eDataAttr?: string;
  disabledPopoverContent?: React.ReactNode | string;
}

const LabeledSwitch: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  description,
  onChange,
  value,
  switchPosition = 'end',
  disabled,
  containerStyle,
  titleStyle,
  e2eDataAttr,
  disabledPopoverContent,
}) => {
  return (
    <Container
      switchPosition={switchPosition}
      style={containerStyle}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div>
        <Title
          style={{
            color: disabled ? colors.gray8 : 'inherit',
            ...titleStyle,
          }}
        >
          {title}
        </Title>
        {description && <Description>{description}</Description>}
      </div>
      <Popover content={disabled ? disabledPopoverContent : null}>
        <Switch
          onChange={onChange}
          checked={value}
          disabled={disabled}
          data-e2e={e2eDataAttr}
        />
      </Popover>
    </Container>
  );
};

export default LabeledSwitch;
