import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  generalSelectors,
  generalActions,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '../../../../shared/ant/Modal/Modal';

const ConfirmDeleteGroupAssignmentModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const assignmentIds = useSelector(
    auditObjectsSelectors.groupAssignments.getDeleteCandidatesIds
  );
  const assignmentsMap = useSelector(
    auditObjectsSelectors.groupAssignments.getMap
  );
  const groupsMap = useSelector(
    generalSelectors.getConciseAuditObjectGroupsMap
  );
  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );

  const assignment =
    assignmentIds.length === 1 ? assignmentsMap[assignmentIds[0]] : null;

  const closeModal = useCallback(
    () =>
      dispatch(
        auditObjectsActions.groupAssignments.toggleConfirmDeleteModal([])
      ),
    []
  );

  return (
    <Modal
      title={formatMessage({ id: 'RemoveFromGroup' })}
      open={assignmentIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          auditObjectsActions.groupAssignments.deleteAssignments(assignmentIds)
        );

        if (
          auditObjectsActions.groupAssignments.deleteAssignments.fulfilled.match(
            resultAction
          )
        ) {
          dispatch(auditObjectsActions.groupAssignments.getData());

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeletingAssignments' }),
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {assignment
        ? formatMessage(
            { id: 'YouAreAboutToRemoveGroupAssignmentFromSystem' },
            {
              name: auditObjectsMap[assignment.auditObjectId]?.name,
              groupName: groupsMap[assignment.groupId]?.name,
            }
          )
        : formatMessage(
            { id: 'YouAreAboutToRemoveGroupAssignmentsFromSystem' },
            { count: assignmentIds.length }
          )}
    </Modal>
  );
};

export default ConfirmDeleteGroupAssignmentModal;
