import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Button = styled.button`
  position: relative;
  background: none;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 22px;
  cursor: pointer;
  //max-width: 400px;
  white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow: hidden;

  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    background: ${colors.blue1};
    left: 0;
    bottom: 1px;
    height: 2px;
    width: 100%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  ${(props: { active: boolean }) =>
    props.active &&
    `
      &:after { opacity: 1; }
  `}

  &:focus {
    outline: none;
  }
`;
