import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  accountSelectors,
  generalSelectors,
} from '@store';
import { Footer } from './styled';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import SlideModal from '../../../../../shared/SideModal/SideModal';
import Form from '../../../../../shared/ant/Form';
import Button from '../../../../../shared/ant/Button';
import AuditObjectsSelect from '../../../../../shared/EntitySelects/AuditObjectsSelect';
import AuditObjectsGroupsSelect from '@components/shared/EntitySelects/AuditObjectsGroupsSelect';

interface IProps {
  auditObjectId?: string;
  auditObjectGroupId?: string;
}

const AddToGroupModal: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  auditObjectGroupId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const visible = useSelector(
    auditObjectsSelectors.groupAssignments.isAddToGroupModalVisible
  );
  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );
  const auditObjectsGroupsMap = useSelector(
    generalSelectors.getConciseAuditObjectGroupsMap
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const closeModal = useCallback(
    () =>
      dispatch(
        auditObjectsActions.groupAssignments.toggleAddToGroupModal(false)
      ),
    []
  );

  useEffect(() => {
    if (selectedTableRows.length > 0) {
      closeModal();
    }
  }, [selectedTableRows.length]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <SlideModal
      opened={visible}
      onModalClose={() => {
        closeModal();
      }}
      title={formatMessage({
        id: 'AddToGroups',
      })}
      closeBtnE2eDataAttr={
        e2eTestElements.objects.groupsAssignments.closeAddToGroupModal
      }
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
              data-e2e={e2eTestElements.objects.groupsAssignments.addToGroupBtn}
            >
              {formatMessage({ id: 'Add' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={e2eTestElements.objects.groupsAssignments.cancelBtn}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form
        form={form}
        onFinish={async (values: any) => {
          setLoading(true);

          const auditObjectsIds = !!auditObjectId
            ? [auditObjectId]
            : values.auditObjectsIds;
          const groupsIds = !!auditObjectGroupId
            ? [auditObjectGroupId]
            : values.groupsIds;

          const resultAction = await dispatch(
            auditObjectsActions.groupAssignments.createAssignment({
              auditObjectsIds,
              groupsIds,
            })
          );

          const translationValues = {
            objectName:
              auditObjectsIds.length === 1 &&
              auditObjectsMap[auditObjectsIds[0]]
                ? auditObjectsMap[auditObjectsIds[0]]?.name
                : `${
                    auditObjectsIds.length
                  } ${auditObjectName.plural.toLowerCase()}`,
            groupName:
              groupsIds.length === 1 && auditObjectsGroupsMap[groupsIds[0]]
                ? `${auditObjectsGroupsMap[groupsIds[0]]?.name} ${formatMessage(
                    {
                      id: 'Group',
                    }
                  ).toLocaleLowerCase()}`
                : `${groupsIds.length} ${formatMessage({
                    id: 'Groups',
                  }).toLocaleLowerCase()}`,
          };

          if (
            auditObjectsActions.groupAssignments.createAssignment.fulfilled.match(
              resultAction
            )
          ) {
            dispatch(auditObjectsActions.groupAssignments.getData());
            closeModal();

            notification.success({
              message: formatMessage(
                { id: 'YouHaveSuccessfullyAddedToGroup' },
                translationValues
              ),
            });
          } else {
            notification.error({
              message: formatMessage(
                { id: 'ErrorWhileAddingObjectsToGroup' },
                translationValues
              ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        layout="vertical"
        initialValues={{
          groupsIds: [],
          auditObjectsIds: [],
        }}
      >
        {!auditObjectGroupId && (
          <Form.Item
            name="groupsIds"
            label={formatMessage(
              { id: 'AuditObjectGroups' },
              { objectName: auditObjectName.plural }
            )}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <AuditObjectsGroupsSelect
              disabled={loading}
              placeholder={formatMessage(
                { id: 'SelectObjectGroups' },
                { objectName: auditObjectName.plural.toLowerCase() }
              )}
              e2eDataAttr={
                e2eTestElements.objects.groupsAssignments.selectGroupDropdown
              }
              mode="multiple"
            />
          </Form.Item>
        )}
        {!auditObjectId && (
          <Form.Item
            name="auditObjectsIds"
            label={auditObjectName.plural}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <AuditObjectsSelect
              disabled={loading}
              mode="multiple"
              placeholder={formatMessage(
                { id: 'SelectAuditObjects' },
                { objectName: auditObjectName.plural.toLowerCase() }
              )}
              e2eDataAttr={
                e2eTestElements.objects.groupsAssignments.selectObjectsDropdown
              }
            />
          </Form.Item>
        )}
      </Form>
    </SlideModal>
  );
};

export default AddToGroupModal;
