import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QrCodeContainer = styled.div`
  width: 256px;
  height: 256px;
  margin: 20px auto 20px;
`;

export const QrCodeImg = styled.img``;

export const LinkHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin: 30px 0 5px;
`;

export const LinkDesc = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const LinkContainer = styled.div`
  position: relative;
  padding-right: 30px;
  width: 100%;
`;

export const Link = styled.a`
  display: block;
  text-align: center;
  background: ${colors.gray3};
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${colors.blue2};
`;

export const CopyBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  right: 0;

  svg {
    width: 19px;
    height: 19px;
    color: ${colors.blue2};
  }
`;

export const SkeletonContainer = styled.div`
  margin: 40px 0 30px;
  width: 300px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 30px;
  width: 400px;
  text-align: center;
  font-size: 14px;

  h3 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 30px;
  }

  svg {
    width: 50px;
    height: 50px;
    color: ${colors.orange};
    margin-bottom: 20px;
  }
`;
