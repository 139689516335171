import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AxiosCacheInstance, setupCache } from 'axios-cache-interceptor';
import {
  ApiConflictError,
  AuthenticationError,
  ForbiddenError,
  NetworkError,
  ServerError,
  UnexpectedError,
} from '../errors';
import { Param, RequestApiParams, AnyObject } from '../types';

export function isObject(value: any): value is AnyObject {
  return typeof value === 'object' && value !== null;
}

const axios = setupCache(Axios);

export class BaseApiService {
  private readonly _axios: AxiosCacheInstance;

  constructor() {
    this._axios = axios;
  }

  protected async _makeRequest({
    method,
    headers,
    body,
    cancelToken,
    onUploadProgress,
    responseType,
    withHeaders,
    url,
    query,
    token,
    cacheResponse,
    requestId,
    deviceId,
  }: RequestApiParams) {
    try {
      const config: AxiosRequestConfig = {
        method,
        headers,
        data: body || {},
        cancelToken,
        onUploadProgress,
      };

      if (responseType) {
        config.responseType = responseType;
      }

      if (!config.headers) {
        config.headers = {
          'Content-Type': 'application/json',
        };
      }

      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }

      if (deviceId) {
        config.headers['X-DEVICE-ID'] = deviceId;
      }

      const response = await this._axios(
        this._appendQueryString({ url, query }),
        {
          ...config,
          cache: cacheResponse ? {} : false,
          id: requestId,
        }
      );

      if (!cacheResponse && requestId) {
        await this._axios.storage.remove(requestId);
      }

      if (withHeaders) {
        return { data: response.data, headers: response.headers };
      }

      return response.data;
    } catch (e: unknown) {
      if (Axios.isAxiosError(e)) {
        if (e.response) {
          const errorMessage = this._extractErrorMessage(e);

          switch (e.response.status) {
            case 401:
              throw new AuthenticationError(errorMessage);
            case 403:
              throw new ForbiddenError();
            case 409:
              throw new ApiConflictError(errorMessage);
            default:
              throw new ServerError(errorMessage);
          }
        } else if (e.request) {
          throw new NetworkError();
        }
      } else {
        let errorMessage;

        if (e instanceof Error) {
          errorMessage = e.message;
        } else if (typeof e === 'string') {
          errorMessage = e;
        } else if (typeof e === 'number') {
          errorMessage = e.toString();
        } else if (e instanceof Object) {
          errorMessage = JSON.stringify(e);
        }

        throw new UnexpectedError(errorMessage);
      }
    }
  }

  public async removeFileCache(requestId: string) {
    await this._axios.storage.remove(requestId);
  }

  protected _constructUrl(params: {
    host: string;
    path: string;
    companyId?: string;
    skipCompanyId?: boolean;
  }) {
    if (!params.skipCompanyId && params.companyId) {
      return `${params.host}/${params.companyId}/${params.path}`;
    }

    return `${params.host}/${params.path}`;
  }

  private _appendQueryString = ({
    url,
    query,
  }: {
    url: string;
    query?: AnyObject;
  }) => {
    let result = url;

    if (query && Object.keys(query).length) {
      result += `?${this._constructQueryString(query)}`;
    }

    return result;
  };

  private _constructQueryString = (params: {
    [name: string]: Param;
  }): string => {
    return Object.keys(params)
      .reduce((acc: string[], key: string) => {
        let param;

        if (Array.isArray(params[key])) {
          const arrayParams = params[key] as (string | number)[];
          param = arrayParams
            .map((value) => `${key}[]=${encodeURIComponent(value)}`)
            .join('&');
        } else {
          param = `${key}=${encodeURIComponent(
            params[key] as string | number
          )}`;
        }

        acc.push(param);

        return acc;
      }, [])
      .join('&');
  };

  private _extractErrorMessage(e: AxiosError): string {
    return (
      (typeof e.response?.data === 'string' && e.response?.data) ||
      (isObject(e.response?.data) &&
        (e.response?.data?.errorCode ||
          e.response?.data?.title ||
          e.response?.data?.error ||
          e.response?.data.message)) ||
      e.message
    );
  }

  getCancelTokenSource() {
    return Axios.CancelToken.source();
  }

  isCancelError(e: unknown) {
    return Axios.isCancel(e);
  }
}
