import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from '@repo/shared/config';

const { Text } = Typography;

export const ResultContainer = styled.div`
  text-align: center;
  padding: 15px 45px;
`;

export const ResultIcon = styled.div`
  display: inline-block;
  margin-bottom: 16px;

  svg {
    display: block;
    width: 36px;
    height: 36px;
  }
`;

export const ResultTitle = styled(Text)`
  display: block;
  font-size: 16px;
  color: ${colors.gray10};
  margin-bottom: 15px;
`;

export const ResultText = styled(Text)`
  display: inline-block;
  margin-bottom: 25px;
  color: ${colors.gray8};
`;

export const ResultButtons = styled.div``;
