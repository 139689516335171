import React from 'react';
import { useIntl } from 'react-intl';

import { IScore } from '@repo/shared/types';

import AddEditModal from '../../shared/AddEditModal/AddEditModal';
import AddEditScoreModalForm from './AddEditScoreModalForm';

const AddEditScoreModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <AddEditModal
      titles={{
        add: formatMessage({ id: 'AddNewScoringSystem' }),
        edit: formatMessage({ id: 'EditScoringSystem' }),
      }}
    >
      {({ closeModal, data, opened }) => (
        <AddEditScoreModalForm
          data={data as IScore | undefined}
          closeModal={closeModal}
          opened={opened}
        />
      )}
    </AddEditModal>
  );
};

export default AddEditScoreModal;
