import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import {
  IAuditCompletionFilters,
  IAuditsCompletionChartItem,
  IAuditsTotals,
  IDashboardFilters,
  IGetUnresolvedActionsResponse,
  IUnresolvedActionsFilters,
} from '@repo/shared/types';
import { removeUndefinedAndNullProps } from '@utils';
import { getErrorMessage } from '@repo/shared/utils';
import { ShowUnresolvedActionsBy } from '@repo/shared/enums';
import { Logger } from '@repo/shared/services';
import { apiUrls } from '@src/config/apiUrls';
import { Permission } from '@repo/shared/enums';
import { accountSelectors, dashboardSelectors, generalActions } from '@store';
import { InternalApiService } from '@repo/shared/api';
import { Audit } from '@domain/Audits/models/Audit';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import AuditsApiClient from '@infrastructure/Audits/api/AuditsApiClient';

const apiService = InternalApiService.getInstance();
const auditsApiClient = new AuditsApiClient();

const getTotals = createAsyncThunk<
  IAuditsTotals,
  undefined,
  { rejectValue: string; state: IRootState }
>('dashboard/audits/getTotals', async (_, { rejectWithValue, getState }) => {
  try {
    return apiService.post({
      url: `${apiUrls.dashboard}/audits/totals`,
      body: removeUndefinedAndNullProps(
        dashboardSelectors.getFilters(getState())
      ),
    });
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getAuditsCompletion = createAsyncThunk<
  { data: IAuditsCompletionChartItem[]; averageScore: number },
  IAuditCompletionFilters | undefined,
  { rejectValue: string; state: IRootState }
>(
  'dashboard/audits/getAuditsCompletion',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      return apiService.post({
        url: `${apiUrls.dashboard}/audits/completion`,
        body: removeUndefinedAndNullProps({
          ...dashboardSelectors.getFilters(getState()),
          ...state.dashboard.auditsCompletion.filters,
        }),
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getUnresolvedActions = createAsyncThunk<
  IGetUnresolvedActionsResponse,
  IUnresolvedActionsFilters | undefined,
  { rejectValue: string; state: IRootState }
>(
  'dashboard/audits/getUnresolvedActionsByStatus',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      return apiService.post({
        url: `${apiUrls.dashboard}/actions/${
          state.dashboard.unresolvedActions.filters.showBy ===
          ShowUnresolvedActionsBy.ByStatus
            ? 'statuses'
            : 'priorities'
        }/totals`,
        body: removeUndefinedAndNullProps({
          ...dashboardSelectors.getFilters(getState()),
          ...state.dashboard.unresolvedActions.filters,
        }),
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getAudits = createAsyncThunk<
  Audit[],
  Partial<AuditsFilters & IDashboardFilters> | undefined,
  { state: IRootState; rejectValue: string }
>('dashboard/audits/get', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const {
      filters: { dueDateStatus },
    } = dashboardSelectors.getAudits(state);
    const { templateIds, auditObjectIds, auditObjectGroupIds } =
      dashboardSelectors.getFilters(state);

    const audits = await auditsApiClient.getPendingAudits({
      pageSize: 1000,
      pageNumber: 1,
      dueDateStatus,
      templateId: templateIds[0],
      auditObjectIds,
      auditObjectGroupIds,
    });

    return audits.data;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

export const dashboardActions = {
  getAudits,
  getDashboard: createAsyncThunk<
    void,
    Partial<IDashboardFilters> | undefined,
    { state: IRootState }
  >(
    'dashboard/getDashboard',
    async (filters, { rejectWithValue, dispatch, getState }) => {
      if (filters) {
        dispatch(generalActions.setSharedFilters(filters));
      }

      const canDoAudits = accountSelectors.hasPermission(
        getState(),
        Permission.CanDoAudits
      );

      try {
        dispatch(getTotals());
        dispatch(getAuditsCompletion());
        dispatch(getUnresolvedActions());

        if (canDoAudits) {
          dispatch(getAudits());
        }
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  getUnresolvedActions,
  getAuditsCompletion,
  getTotals,
  setDashboardLoadingStatus: createAction<boolean>(
    'dashboard/setDashboardLoadingStatus'
  ),
};
