import { IScoreLabel } from '@repo/shared/types';

export function findScoreSystemLabelByScore(
  labels: IScoreLabel[],
  score: number
): IScoreLabel | undefined {
  return labels.find(({ min, max }, i) =>
    i < labels.length - 1
      ? score >= min && score < max
      : score >= min && score <= max
  );
}
