import styled from 'styled-components';
import AntPopover from 'antd/es/popover';

import { colors } from '@repo/shared/config';
import Button from '@components/shared/ant/Button';

export const Spotlight = styled.div`
  position: absolute;
  scroll-margin: 20px;
`;

export const Content = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${({ src }: { src: string }) => src}) no-repeat center center;
  background-size: 24px 24px;
  margin-right: 12px;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 0;
`;

export const Text = styled.div`
  p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  ul {
    margin: 0 0 10px 0;
    padding: 0 0 0 15px;

    li {
      margin: 0 0 6px 0;
    }
  }

  img {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const CancelBtn = styled.button`
  background: none;
  border: 0;
  padding: 0;
  color: ${colors.gray8};
  font-size: 12px;
`;

export const NextBtn = styled(Button)``;

export const Popover = styled(AntPopover)``;
