import React from 'react';
import { useAppDispatch } from '@hooks';
import { useSelector } from 'react-redux';
import { LiveChatWidget } from '@livechat/widget-react';
import { useMatch } from 'react-router';

import { accountActions, accountSelectors } from '@store';
import { routes } from '@config';
import { config } from '@repo/shared/config';
import { LiveChatVisibility } from '@repo/shared/enums';

interface IProps {}

function useChatWidgetRouteVisibility() {
  const matches = [routes.createIssue, routes.issueDetails].map((route) =>
    useMatch(route)
  );
  return matches.some((match) => match !== null);
}

const ChatWidget: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  const isHiddenRoute = useChatWidgetRouteVisibility();

  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const liveChatVisibility = useSelector(
    accountSelectors.getLiveChatVisibility
  );

  if (!appConfiguration.showLiveChat || isHiddenRoute) {
    return null;
  }

  return (
    <LiveChatWidget
      license={config.liveChatLicense}
      visibility={liveChatVisibility}
      onVisibilityChanged={({ visibility }) => {
        dispatch(
          accountActions.setLiveChatVisibility(visibility as LiveChatVisibility)
        );
      }}
      customerName={currentUser?.name}
      customerEmail={currentUser?.email}
    />
  );
};

export default ChatWidget;
