import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.gray4};
`;
