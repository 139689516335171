import uniqBy from 'lodash/uniqBy';

import {
  ActionStatus,
  AuditStatus,
  AuditStatusReports,
  CompareType,
  DetailsModalType,
  HttpMethods,
  AuditDueDateStatus,
} from '@repo/shared/enums';
import {
  IActionAnalyticsChartItem,
  IActionAuditsAnalytics,
  IActionsAnalytics,
  IAnalyticsChartItem,
  IAnyObject,
  IAuditPerformanceChartItem,
  IAuditsCompletionChartItem,
  IAuditsTimeStats,
  ChartItem,
  IColorChartData,
  IColorChartDataItem,
  IColorChartLine,
  IComparables,
  IConcise,
  IFailedCriticalItem,
  IFailedItem,
  IFailedItemsFilters,
  IGetAuditCompletionChartItemResponse,
  IGetAuditorPerfBarChartResponse,
  IGetAuditPerformanceChartItemResponse,
  IGetReportPDFRequest,
  IGetSectionPerformanceChartItemResponse,
  IPagedResponse,
  IParticipationRateChartItem,
  IPerformanceReportBarChartItem,
  IReportTableFilters,
  ISharedFilters,
  IStatuses,
  IUpdateComparablesRequest,
} from '@repo/shared/types';
import { actionStatuses, apiUrls } from '@config';
import { config, colors } from '@repo/shared/config';
import { IRootState } from '../frameworks/redux';
import { generalSelectors, itemAnalysisReportActions } from '@store';
import { createGetEntitiesThunk, date, round } from '@utils';
import { intl } from '@repo/shared/components/IntlGlobalProvider';

export function calcChartTotal(chartData?: ChartItem[]) {
  if (!Array.isArray(chartData)) {
    return 0;
  }

  return chartData.reduce((acc: number, el: ChartItem) => (acc += el.count), 0);
}

export function convertSecondsToHMS(seconds: number) {
  seconds = round(Number(seconds), 0);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + 'd ' : '';
  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = d === 0 && h === 0 && m === 0 && s >= 0 ? s + 's ' : '';

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function sortChartItemsByDate(
  a:
    | IGetAuditCompletionChartItemResponse
    | IGetAuditPerformanceChartItemResponse,
  b:
    | IGetAuditCompletionChartItemResponse
    | IGetAuditPerformanceChartItemResponse
) {
  return date(a.date).isAfter(date(b.date)) ? 1 : -1;
}

export function processAuditPerformanceChartItems(
  items: IGetAuditPerformanceChartItemResponse[],
  comparableId?: string,
  comparableColor?: string
) {
  let totalCount = 0;
  let totalScoreSum = 0;

  const chartItems: IAuditPerformanceChartItem[] = [...items]
    .sort(sortChartItemsByDate)
    .map(({ date: itemDate, averageScore, count }) => {
      totalCount += count;
      totalScoreSum += count * averageScore;

      return {
        averageScore: round(averageScore, 0),
        count,
        color: comparableColor ? comparableColor : colors.blue2,
        dateTimestamp: date(itemDate, config.apiDateFormat).valueOf(),
      };
    });

  const totalAverageScore = round(totalScoreSum / totalCount, 0);

  for (let i = 0; i < items.length; i++) {
    chartItems[i][
      `totalAverageScore${comparableId ? `-${comparableId}` : '-total'}`
    ] = totalAverageScore;
    chartItems[i].totalAverageScore = totalAverageScore;
  }

  return chartItems;
}

export function processAuditCompletionChartItems(
  items: IGetAuditCompletionChartItemResponse[],
  comparableId?: string
) {
  let totalCount = 0;

  const chartItems: any[] = [...items]
    .sort(sortChartItemsByDate)
    .map(({ date: itemDate, count }) => {
      totalCount += count;

      return {
        [comparableId ? `count-${comparableId}` : 'count']: count,
        dateTimestamp: date(itemDate, config.apiDateFormat).valueOf(),
      };
    });

  const averageCount = round(totalCount / chartItems.length, 0);

  for (let i = 0; i < chartItems.length; i++) {
    chartItems[i][
      comparableId ? `averageCount-${comparableId}` : 'averageCount'
    ] = averageCount;
  }

  return chartItems;
}

export function prepareReportPDFRequestBody<
  T extends ISharedFilters = ISharedFilters,
>({
  sectionPerformanceCompare,
  auditPerformanceCompare,
  auditCompletionCompare,
  reportFilters,
  state,
  failedCriticalItemsFilters,
  mostFailedItemsFilters,
}: {
  sectionPerformanceCompare?: IComparables;
  auditPerformanceCompare?: IComparables;
  auditCompletionCompare?: IComparables;
  failedCriticalItemsFilters?: IReportTableFilters;
  mostFailedItemsFilters?: IReportTableFilters;
  reportFilters: T;
  state: IRootState;
}): IGetReportPDFRequest {
  const { company, user } = state.account;

  const usersMap = generalSelectors.getConciseUsersMap(state);
  const userGroupsMap = generalSelectors.getConciseUserGroupsMap(state);
  const templatesMap = generalSelectors.getConciseTemplatesMap(state);
  const auditObjectsMap = generalSelectors.getConciseAuditObjectsMap(state);
  const auditObjectGroupsMap =
    generalSelectors.getConciseAuditObjectGroupsMap(state);
  const tagsMap = generalSelectors.getConciseTagsMap(state);

  const {
    userIds,
    userGroupIds,
    auditObjectIds,
    auditObjectGroupIds,
    tagsIds,
    templateIds,
    ...filters
  } = reportFilters;

  const body: IGetReportPDFRequest = {
    companyId: company.id,
    createdBy: {
      id: user.id,
      name: user.name,
      email: user.email,
    },
    createdAt: date().format(config.apiDateFormat),
    filters: {
      ...filters,
      auditObjects: (auditObjectIds || []).map((id: string) => ({
        id,
        name: auditObjectsMap[id]?.name || '',
      })),
      auditObjectGroups: (auditObjectGroupIds || []).map((id: string) => ({
        id,
        name: auditObjectGroupsMap[id]?.name || '',
      })),
      userGroups: (userGroupIds || []).map((id: string) => ({
        id,
        name: userGroupsMap[id]?.name || '',
      })),
      users: (userIds || []).map((id: string) => ({
        id,
        name: usersMap[id]?.name || '',
      })),
      tags: (tagsIds || []).map((id: string) => ({
        id,
        name: tagsMap[id]?.name || '',
      })),
    },
  };

  if (sectionPerformanceCompare) {
    body.sectionPerformanceCompare = sectionPerformanceCompare;
  }

  if (auditPerformanceCompare) {
    body.auditPerformanceCompare = auditPerformanceCompare;
  }

  if (failedCriticalItemsFilters) {
    body.failedCriticalItemsFilters = failedCriticalItemsFilters;
  }

  if (auditCompletionCompare) {
    body.auditCompletionCompare = auditCompletionCompare;
  }

  if (mostFailedItemsFilters) {
    body.mostFailedItemsFilters = mostFailedItemsFilters;
  }

  body.filters.templates = templateIds.reduce<IConcise[]>((acc, templateId) => {
    if (templatesMap[templateId]) {
      acc.push({
        id: templateId,
        name: templatesMap[templateId]?.name || '',
      });
    }

    return acc;
  }, []);

  return body;
}

export function prepareComparablesForAPI(
  comparables: IComparables
): IUpdateComparablesRequest {
  return Object.values(comparables).reduce(
    (acc: IUpdateComparablesRequest, { compareType, id }) => {
      switch (compareType) {
        case CompareType.User:
          acc.userIds.push(id);
          break;
        case CompareType.UserGroups:
          acc.userGroupIds.push(id);
          break;
        case CompareType.AuditObject:
          acc.auditObjectIds.push(id);
          break;
        case CompareType.AuditObjectGroups:
          acc.auditObjectGroupIds.push(id);
          break;
        default:
          break;
      }

      return acc;
    },
    {
      userIds: [],
      userGroupIds: [],
      auditObjectIds: [],
      auditObjectGroupIds: [],
    }
  );
}

export const createGetSectionPerformanceThunk = (filtersSelector: any): any =>
  createGetEntitiesThunk<
    void,
    ISharedFilters,
    {
      items: IGetSectionPerformanceChartItemResponse[];
      compare: {
        id: string;
        compareType: CompareType;
        items: IGetSectionPerformanceChartItemResponse[];
      }[];
    }
  >({
    apiUrl: `${apiUrls.reports.summary}/sections-performance`,
    entityName: 'reports/summary/getSectionPerformance',
    filtersSelector,
    method: HttpMethods.post,
  });

export const createGetAuditCompletionThunk = (filtersSelector: any): any =>
  createGetEntitiesThunk<
    void,
    ISharedFilters,
    {
      items: IGetAuditCompletionChartItemResponse[];
      compare: {
        id: string;
        compareType: CompareType;
        items: IGetAuditCompletionChartItemResponse[];
      }[];
    }
  >({
    apiUrl: `${apiUrls.reports.summary}/audit-completion`,
    entityName: 'reports/summary/getAuditCompletion',
    filtersSelector,
    method: HttpMethods.post,
  });

export const createGetAuditPerformanceThunk = (filtersSelector: any): any =>
  createGetEntitiesThunk<
    void,
    ISharedFilters,
    {
      items: IGetAuditPerformanceChartItemResponse[];
      compare: {
        id: string;
        compareType: CompareType;
        items: IGetAuditPerformanceChartItemResponse[];
      }[];
    }
  >({
    apiUrl: `${apiUrls.reports.summary}/audit-performance`,
    entityName: 'reports/summary/getAuditPerformance',
    filtersSelector,
    method: HttpMethods.post,
  });

export const createGetAuditCompletionTime = (filtersSelector: any): any =>
  createGetEntitiesThunk<void, ISharedFilters, number[]>({
    apiUrl: `${apiUrls.reports.summary}/audit-completion-time`,
    entityName: 'reports/summary/getAuditCompletionTime',
    filtersSelector,
    method: HttpMethods.post,
  });

export const createGetScoreBreakdownThunk = (filtersSelector: any): any =>
  createGetEntitiesThunk<void, ISharedFilters, IAuditsCompletionChartItem[]>({
    apiUrl: `${apiUrls.reports.summary}/score-breakdown`,
    entityName: 'reports/summary/getScoreBreakdown',
    filtersSelector,
    method: HttpMethods.post,
  });

export const createGetFailedCriticalItemsThunk = (filtersSelector: any) =>
  createGetEntitiesThunk<
    IFailedItem,
    IFailedItemsFilters,
    IPagedResponse<IFailedCriticalItem>
  >({
    apiUrl: `${apiUrls.reports.itemAnalysis}/failed-critical-items`,
    entityName: 'reports/getFailedCriticalItems',
    filtersSelector,
    method: HttpMethods.post,
  });

export const selectAuditsTimeStats = ({
  loading,
  data,
}: {
  loading: boolean;
  data: IAuditsTimeStats | null;
}) => {
  const completionStatsChartData: ChartItem[] | null =
    data?.completed || data?.pending ? [] : null;

  if (data?.completed) {
    completionStatsChartData?.push({
      count: data.completed,
      color: colors.green,
      label: intl?.formatMessage({ id: 'Completed' }),
      onClickAction: itemAnalysisReportActions.detailsModal.show({
        title: intl.formatMessage({ id: 'CompletedAudits' }),
        count: data.completed,
        type: DetailsModalType.Audits,
        filters: {
          auditStatus: AuditStatusReports.Completed,
        },
      }),
    });
  }

  if (data?.pending) {
    completionStatsChartData?.push({
      count: data.pending,
      color: colors.yellow,
      label: intl?.formatMessage({ id: 'Pending' }),
      onClickAction: itemAnalysisReportActions.detailsModal.show({
        title: intl.formatMessage({ id: 'PendingAudits' }),
        count: data.pending,
        type: DetailsModalType.Audits,
        filters: {
          auditStatus: AuditStatusReports.Pending,
        },
      }),
    });
  }

  if (data?.expired) {
    completionStatsChartData?.push({
      count: data.expired,
      color: colors.red,
      label: intl?.formatMessage({ id: 'Expired' }),
      onClickAction: itemAnalysisReportActions.detailsModal.show({
        title: intl.formatMessage({ id: 'ExpiredAudits' }),
        count: data.expired,
        type: DetailsModalType.Audits,
        filters: {
          auditStatus: AuditStatusReports.Expired,
        },
      }),
    });
  }

  const onTimeStatsChartData: ChartItem[] | null =
    data?.late || data?.onTime ? [] : null;

  if (data?.onTime) {
    onTimeStatsChartData?.push({
      count: data.onTime,
      color: colors.green,
      label: intl?.formatMessage({ id: 'OnHyphenTime' }),
      onClickAction: itemAnalysisReportActions.detailsModal.show({
        title: `${intl.formatMessage({
          id: 'OnHyphenTime',
        })} ${intl.formatMessage({ id: 'Audits' })}`,
        count: data.onTime,
        type: DetailsModalType.Audits,
        filters: {
          dueDateStatus: AuditDueDateStatus.OnTime,
        },
      }),
    });
  }

  if (data?.late) {
    onTimeStatsChartData?.push({
      count: data.late,
      color: colors.orange,
      label: intl?.formatMessage({ id: 'Late' }),
      onClickAction: itemAnalysisReportActions.detailsModal.show({
        title: `${intl.formatMessage({
          id: 'Late',
        })} ${intl.formatMessage({ id: 'Audits' })}`,
        count: data.late,
        type: DetailsModalType.Audits,
        filters: {
          dueDateStatus: AuditDueDateStatus.PastDue,
        },
      }),
    });
  }

  return {
    loading,
    completionStatsChart: {
      data: completionStatsChartData,
      labels: uniqBy(completionStatsChartData || [], 'label'),
    },
    onTimeStatsChart: {
      data: onTimeStatsChartData,
      labels: uniqBy(onTimeStatsChartData || [], 'label'),
    },
    completionTime: {
      average: data?.averageCompletionTimeInSeconds,
      fast: data?.fastestCompletionTimeInSeconds,
      slow: data?.longestCompletionTimeInSeconds,
    },
  };
};

export const selectActionsAnalytics = ({
  data,
  loading,
}: {
  data: IActionsAnalytics | null;
  loading: boolean;
}) => {
  const addOnClickRouteToChartItems =
    (showLateActions?: boolean) => (action: IActionAnalyticsChartItem) => ({
      ...action,
      label: intl.formatMessage({ id: actionStatuses[action.status] }),
      onClickAction: itemAnalysisReportActions.detailsModal.show({
        title: intl.formatMessage({ id: 'CorrectiveActions' }),
        count: action.count,
        type: DetailsModalType.Items,
        filters: {
          hasCorrectiveActions: true,
          actionStatuses: [action.status],
          hasLateActions: showLateActions,
        },
      }),
    });

  const emptyChart = [{ label: '', count: 100, color: colors.gray5 }];
  const totalActionsCount = calcChartTotal(data?.total);
  const lateActionsCount = calcChartTotal(data?.late);
  const totalActions = data?.total.map(addOnClickRouteToChartItems()) || [];
  const lateActions = data?.late.map(addOnClickRouteToChartItems(true)) || [];
  const labels = uniqBy([...totalActions, ...lateActions], 'label');

  return {
    loading,
    data: {
      total: totalActionsCount > 0 ? totalActions : emptyChart,
      late: lateActionsCount > 0 ? lateActions : emptyChart,
    },
    totalActionsCount,
    lateActionsCount,
    labels,
  };
};

export function selectAuditorPerfReportAnalyticsChartsData<
  T extends ActionStatus | AuditStatus,
>({
  data,
  loading,
  statuses,
}: {
  data: IActionAuditsAnalytics<T> | null;
  loading: boolean;
  statuses: Record<T, string>;
}) {
  const emptyChart = [{ label: '', count: 100, color: colors.gray5 }];
  const totalCount = calcChartTotal(data?.total);
  const lateCount = calcChartTotal(data?.late);
  const total = data?.total || [];
  const late = data?.late || [];
  const labels = uniqBy([...total, ...late], 'label').map(
    (item: IAnalyticsChartItem<T>) => ({
      ...item,
      label: intl?.formatMessage({
        id: statuses[item.status],
      }),
    })
  );

  return {
    loading,
    data: {
      total: totalCount > 0 ? total : emptyChart,
      late: lateCount > 0 ? late : emptyChart,
    },
    totalCount,
    lateCount,
    labels,
  };
}

export function prepareColorLineChartData<T extends IColorChartDataItem>(
  data: IColorChartData<T>[],
  linesColors: Record<string, string>,
  formatter?: (dataObj: T) => T & { countStr: string }
): {
  lines: IColorChartLine[];
  data: {
    name: string;
    color: string;
    dateTimestamp: number;
    data: { [id: string]: T & { countStr?: string } };
  }[];
} {
  const result: IAnyObject = {};
  const lines: IColorChartLine[] = [];

  for (let i = 0; i < data.length; i++) {
    const { points, id, name } = data[i];

    const color = linesColors[id];

    lines.push({ id, color, label: name });

    for (let j = 0; j < points.length; j++) {
      const dateTimestamp = date(points[j].x, config.apiDateFormat).valueOf();

      if (!result[dateTimestamp]) {
        result[dateTimestamp] = {
          dateTimestamp,
          data: {},
        };
      }

      const dataObj = {
        color,
        name,
        ...points[j],
      };

      result[dateTimestamp].data[id] = formatter ? formatter(dataObj) : dataObj;

      if (
        result[dateTimestamp].maxCount === undefined ||
        result[dateTimestamp].maxCount < points[j].y
      ) {
        result[dateTimestamp].maxCount = points[j].y;
      }
    }
  }

  return {
    lines,
    data: Object.values(result).sort(
      (a, b) => a.dateTimestamp - b.dateTimestamp
    ),
  };
}

export function getDotStyles(fillColor: string) {
  return {
    fill: fillColor,
    stroke: colors.white,
    strokeWidth: 2,
    r: 5,
  };
}

/**
 * Data formatter for tooltip
 * @param dataObj
 */
export function formatParticipationRateDataObj(
  dataObj: IParticipationRateChartItem
) {
  return {
    ...dataObj,
    countStr: `${dataObj.y}% (${dataObj.due}/${dataObj.completed}, ${
      dataObj.late
    } ${intl?.formatMessage({ id: 'late' })})`,
  };
}

export function prepareAuditorPerfBarChartData<T extends string | number>(
  response: IGetAuditorPerfBarChartResponse<T>
): {
  data: IPerformanceReportBarChartItem<T>[];
  statuses: IStatuses<T>;
} {
  const statuses = {} as IStatuses<T>;

  const result = response.reduce<IPerformanceReportBarChartItem<T>[]>(
    (acc, { data, name, id }) => {
      const item = {
        tagId: id,
        name,
        statuses: {},
      } as IPerformanceReportBarChartItem<T>;

      for (let i = 0; i < data.length; i++) {
        const { status, count, color } = data[i];

        if (statuses[status] === undefined) {
          statuses[status] = { color, status };
        }

        item.statuses[status] = { color, status, count };
      }

      acc.push(item);

      return acc;
    },
    []
  );

  return {
    data: result,
    statuses,
  };
}

export function prepareAuditorPerfChartItemNames<T extends string | number>(
  data: IPerformanceReportBarChartItem<T>[],
  page: number
) {
  return (data || [])
    .slice(page * 10, page * 10 + 10)
    .map((item: IPerformanceReportBarChartItem<T>) => ({
      ...item,
      shortName:
        item.name && item.name?.length > 13
          ? `${item.name.substring(0, 13)}...`
          : item.name,
    }));
}
