import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Templates = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M12 12.5C11.7239 12.5 11.5 12.7239 11.5 13C11.5 13.2761 11.7239 13.5 12 13.5V12.5ZM16 13.5C16.2761 13.5 16.5 13.2761 16.5 13C16.5 12.7239 16.2761 12.5 16 12.5V13.5ZM14.5 11C14.5 10.7239 14.2761 10.5 14 10.5C13.7239 10.5 13.5 10.7239 13.5 11H14.5ZM13.5 15C13.5 15.2761 13.7239 15.5 14 15.5C14.2761 15.5 14.5 15.2761 14.5 15H13.5ZM9 11.5C9.27614 11.5 9.5 11.2761 9.5 11C9.5 10.7239 9.27614 10.5 9 10.5V11.5ZM9 15.5C9.27614 15.5 9.5 15.2761 9.5 15C9.5 14.7239 9.27614 14.5 9 14.5V15.5ZM12 13.5H16V12.5H12V13.5ZM13.5 11V15H14.5V11H13.5ZM2.75 3.5H15.25V2.5H2.75V3.5ZM15.5 3.75V6.25H16.5V3.75H15.5ZM15.25 6.5H2.75V7.5H15.25V6.5ZM2.5 6.25V3.75H1.5V6.25H2.5ZM2.75 6.5C2.61193 6.5 2.5 6.38807 2.5 6.25H1.5C1.5 6.94036 2.05964 7.5 2.75 7.5V6.5ZM15.5 6.25C15.5 6.38807 15.3881 6.5 15.25 6.5V7.5C15.9404 7.5 16.5 6.94036 16.5 6.25H15.5ZM15.25 3.5C15.3881 3.5 15.5 3.61193 15.5 3.75H16.5C16.5 3.05965 15.9404 2.5 15.25 2.5V3.5ZM2.75 2.5C2.05964 2.5 1.5 3.05965 1.5 3.75H2.5C2.5 3.61193 2.61193 3.5 2.75 3.5V2.5ZM2.75 11.5H9V10.5H2.75V11.5ZM9 14.5H2.75V15.5H9V14.5ZM2.5 14.25V11.75H1.5V14.25H2.5ZM2.75 14.5C2.61193 14.5 2.5 14.3881 2.5 14.25H1.5C1.5 14.9404 2.05964 15.5 2.75 15.5V14.5ZM2.75 10.5C2.05964 10.5 1.5 11.0596 1.5 11.75H2.5C2.5 11.6119 2.61193 11.5 2.75 11.5V10.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default Templates;
