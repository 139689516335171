import React from 'react';
import { TrendingUp, TrendingDown, Clock } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Metrics,
  Metric,
  MetricLabel,
  MetricValue,
  MetricChange,
  Badge,
  Skeleton,
} from './styled';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { round, convertSecondsToHMS } from '@utils';
import { colors } from '@repo/shared/config';

import { AuditDetails } from '@domain/Audits/models/AuditDetails';

interface IProps {
  audit: AuditDetails;
}

const AuditBadges: React.FC<React.PropsWithChildren<IProps>> = ({ audit }) => {
  const { formatMessage } = useIntl();
  const { loading } = useSelector(auditsSelectors.getAuditDetails);

  if (
    !audit ||
    (!audit.completedAtInformation && !audit.template.estimatedTimeMins)
  ) {
    return null;
  }

  const { scoreChange, score, durationInSeconds } = audit;

  const isFailed =
    audit.template.passedThreshold !== undefined && audit.score !== null
      ? audit.score < audit.template?.passedThreshold
      : false;

  return (
    <Metrics>
      {audit.completedAtInformation && (
        <>
          <Metric>
            <Badge badgeColor={isFailed ? colors.red : colors.green}>
              {scoreChange && scoreChange < 0 ? (
                <TrendingDown />
              ) : (
                <TrendingUp />
              )}
            </Badge>
            <div>
              <MetricLabel>{formatMessage({ id: 'Score' })}</MetricLabel>
              <MetricValue>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    <span>{score}%</span>
                    {!!scoreChange && (
                      <MetricChange value={scoreChange}>
                        {scoreChange > 0 ? '+' : '-'}
                        {Math.abs(round(scoreChange))}%
                      </MetricChange>
                    )}
                  </>
                )}
              </MetricValue>
            </div>
          </Metric>
          <Metric>
            <Badge>
              <Clock />
            </Badge>
            <div>
              <MetricLabel>{formatMessage({ id: 'SpentTime' })}</MetricLabel>
              <MetricValue>
                {loading ? (
                  <Skeleton />
                ) : (
                  <span>{convertSecondsToHMS(durationInSeconds)}</span>
                )}
              </MetricValue>
            </div>
          </Metric>
        </>
      )}
      {!!audit.template.estimatedTimeMins && !audit.completedAtInformation && (
        <Metric>
          <Badge badgeColor={colors.orange}>
            <Clock />
          </Badge>
          <div>
            <MetricLabel>{formatMessage({ id: 'EstimatedTime' })}</MetricLabel>
            <MetricValue>
              {loading ? (
                <Skeleton />
              ) : (
                <span>
                  {audit.template.estimatedTimeMins === null
                    ? 0
                    : convertSecondsToHMS(
                        audit.template.estimatedTimeMins * 60
                      )}
                </span>
              )}
            </MetricValue>
          </div>
        </Metric>
      )}
    </Metrics>
  );
};

export default AuditBadges;
