import styled from 'styled-components';
import AntCheckbox from 'antd/es/checkbox';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  width: 740px;
  margin: 20px auto 43px;
`;

export const FormsContainer = styled.div`
  flex-grow: 1;
`;

export const ChargifyForm = styled.form`
  display: block;
`;

export const Note = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px 0 15px;

  & > * {
    &:first-child {
      flex-grow: 1;
    }

    .agreement-checkbox {
      margin: -2px 0 0 30px;
    }
  }
`;

export const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: ${colors.blue2};
      border-color: ${colors.blue2};
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;
