import { createEntityAdapter } from '@reduxjs/toolkit';

import { SchedulePlan } from '@domain/SchedulePlans/models/SchedulePlan';
import {
  SchedulePlanPeriod,
  SchedulePlanPeriodAuditor,
} from '@domain/SchedulePlans/models/SchedulePlanPeriod';
import { SchedulePlanPeriodAudit } from '@domain/SchedulePlans/models/SchedulePlanPeriodAudit';
import { IConcise } from '@repo/shared/types';

export const schedulePlansAdapter = createEntityAdapter<SchedulePlan>();

export const conciseSchedulePlansAdapter = createEntityAdapter<IConcise>();

export const schedulePlanPeriodAdapter =
  createEntityAdapter<SchedulePlanPeriod>();

export const schedulePlanPeriodAuditorAdapter =
  createEntityAdapter<SchedulePlanPeriodAuditor>();

export const schedulePlanPeriodAuditAdapter =
  createEntityAdapter<SchedulePlanPeriodAudit>();
