import React, { useEffect } from 'react';
import { config, useTransition } from 'react-spring';

import { Container, Header, Content, Footer } from './styled';
import { useScrollBlock } from '@repo/shared/hooks';

interface IProps {
  opened: boolean;
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  noOpeningAnimation?: boolean;
}

const FullScreenModal: React.FC<React.PropsWithChildren<IProps>> = ({
  opened,
  children,
  header,
  footer,
  noOpeningAnimation,
}) => {
  const transition = useTransition(opened, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    if (opened) {
      blockScroll();
    } else {
      allowScroll();
    }

    return () => {
      allowScroll();
    };
  }, [opened]);

  if (noOpeningAnimation) {
    return (
      <Container style={{ display: opened ? 'flex' : 'none' }}>
        {header && <Header>{header}</Header>}
        <Content>{children}</Content>
        {footer && <Footer>{footer}</Footer>}
      </Container>
    );
  }

  return (
    <>
      {transition.map(
        ({ item, props, key }) =>
          item && (
            <Container style={props} key={key}>
              {header && <Header>{header}</Header>}
              <Content>{children}</Content>
              {footer && <Footer>{footer}</Footer>}
            </Container>
          )
      )}
    </>
  );
};

export default FullScreenModal;
