import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useIntl } from 'react-intl';

import { CloseModalButton, Title } from './styled';
import { ArrowLeft } from '@icons';
import { auditObjectsActions, auditObjectsSelectors } from '@store';
import { routes } from '@config';
import { useAppDispatch, usePermission } from '@hooks';

import PageHeader from '@components/shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';
import { useSelector } from 'react-redux';
import { Permission } from '@repo/shared/enums';

export enum AuditObjectPage {
  Details,
  Groups,
  Attributes,
  Access,
  QRCodes,
}

interface IProps {
  page: AuditObjectPage;
  extra?: React.ReactNode;
}

const AuditObjectContentWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  children,
  extra,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const canManageIssueTypes = usePermission(Permission.CanManageIssueTypes);

  const { loading, data, error } = useSelector(
    auditObjectsSelectors.getObjectDetails
  );

  useEffect(() => {
    if (!id) {
      navigate(routes.auditObjects.list);
      return;
    }

    if (!data || data.id !== id) {
      dispatch(auditObjectsActions.objects.getObjectDetails(id));
    }
  }, [id, data]);

  useEffect(() => {
    if (error) {
      navigate(routes.auditObjects.list);
    }
  }, [error]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            <CloseModalButton
              type="button"
              onClick={() => {
                navigate(routes.auditObjects.list);
              }}
            >
              <ArrowLeft />
            </CloseModalButton>
            <Title>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                data?.name
              )}
            </Title>
          </Space>
        }
        extra={extra}
        footer={
          <PageTabbedSwitcher<AuditObjectPage>
            pages={[
              {
                onClick() {
                  if (data) {
                    navigate(
                      routes.auditObjects.auditObjectDetails.replace(
                        ':id',
                        data.id
                      )
                    );
                  }
                },
                name: formatMessage({ id: 'Details' }),
                id: AuditObjectPage.Details,
              },
              {
                onClick() {
                  if (data) {
                    navigate(
                      routes.auditObjects.auditObjectGroups.replace(
                        ':id',
                        data.id
                      )
                    );
                  }
                },
                name: formatMessage({ id: 'Groups' }),
                id: AuditObjectPage.Groups,
              },
              {
                onClick() {
                  if (data) {
                    navigate(
                      routes.auditObjects.auditObjectAttributes.replace(
                        ':id',
                        data.id
                      )
                    );
                  }
                },
                name: formatMessage({ id: 'Attributes' }),
                id: AuditObjectPage.Attributes,
              },
              {
                onClick() {
                  if (data) {
                    navigate(
                      routes.auditObjects.auditObjectAccess.replace(
                        ':id',
                        data.id
                      )
                    );
                  }
                },
                name: formatMessage({ id: 'Access' }),
                id: AuditObjectPage.Access,
              },
              ...(canManageIssueTypes
                ? [
                    {
                      onClick() {
                        if (data) {
                          navigate(
                            routes.auditObjects.auditObjectQRCodes.replace(
                              ':id',
                              data.id
                            )
                          );
                        }
                      },
                      name: formatMessage({ id: 'QrCodes' }),
                      id: AuditObjectPage.QRCodes,
                    },
                  ]
                : []),
            ]}
            activePage={page}
          />
        }
      />
      {children}
    </>
  );
};

export default AuditObjectContentWrapper;
