import React from 'react';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/es/select';

import { apiUrls } from '@config';

import EntityAndGroupSelectAsync from './EntityAndGroupSelectAsync';

interface IProps extends Omit<SelectProps<any>, 'value' | 'onChange'> {
  value?: {
    userIds: string[];
    userGroupIds: string[];
  };
  onChange?: (
    update: {
      userIds: string[];
      userGroupIds: string[];
    },
    option?: any
  ) => void;
  e2eDataAttr?: string;
}

const UsersAndUserGroupsSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  onChange,
  e2eDataAttr,
  ...props
}) => {
  const { formatMessage } = useIntl();

  return (
    <EntityAndGroupSelectAsync
      {...props}
      e2eDataAttr={e2eDataAttr}
      value={{
        entityIds: value?.userIds || [],
        groupIds: value?.userGroupIds || [],
      }}
      onChange={({ entityIds, groupIds }) => {
        if (onChange) {
          onChange({
            userIds: entityIds,
            userGroupIds: groupIds,
          });
        }
      }}
      width="100%"
      entity={{
        api: `${apiUrls.users}/concise`,
        label: formatMessage({ id: 'Users' }),
      }}
      group={{
        api: `${apiUrls.userGroups}/concise`,
        label: formatMessage({ id: 'UserGroups' }),
      }}
      placeholder={formatMessage({ id: 'SelectUsersOrGroups' })}
    />
  );
};

export default UsersAndUserGroupsSelect;
