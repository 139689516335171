import React from 'react';
import { X, AlertTriangle } from 'react-feather';

import {
  Container,
  Placeholder,
  ImageHolder,
  Image,
  RemoveFile,
  SyncIcon,
} from './styled';
import { FileMetadata, IApiService, IFile } from '@repo/shared/types';
import { colors } from '@repo/shared/config';

import OfflineIcon from '@repo/shared/components/Previews/OfflineIcon';
import ImageContainer from '@repo/shared/components/Previews/ImageContainer';

interface IProps {
  file: IFile;
  getFilePath?: (file: FileMetadata, size?: number) => string;
  getMetadataPath?: (fileId: string) => string;
  onRemove?: () => void;
  onClick?: (id: string) => void;
  forceFetch?: boolean;
  imagePreviewStyle?: React.CSSProperties;
  imagePreviewContainerStyle?: React.CSSProperties;
  apiService?: IApiService;
  skipCompanyId?: boolean;
  size?: number;
}

const ImagePreview: React.FC<React.PropsWithChildren<IProps>> = ({
  file,
  getFilePath,
  getMetadataPath,
  onRemove,
  onClick,
  imagePreviewStyle,
  imagePreviewContainerStyle,
  apiService,
  skipCompanyId,
  size,
}) => {
  return (
    <ImageContainer
      fileId={file.id}
      getFilePath={
        getFilePath
          ? () => getFilePath(file, size)
          : () =>
              file.contentType?.includes('video')
                ? `files/${file.id}/video-preview`
                : `files/${file.id}/resized?size=${size}`
      }
      getMetadataPath={getMetadataPath}
      apiService={apiService}
      skipCompanyId={skipCompanyId}
      onClick={() => {
        if (navigator.onLine && onClick) {
          onClick(file.id);
        }
      }}
      extra={
        onRemove && (
          <RemoveFile
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            type="button"
          >
            <X />
          </RemoveFile>
        )
      }
    >
      {({
        hasError,
        imageUrl,
        onLoadImage,
        onLoadImageError,
        isOffline,
        isWaitingUpload,
      }) => {
        const containerStyle = {
          width: imagePreviewStyle?.width,
          height: imagePreviewStyle?.height,
          ...(imagePreviewContainerStyle || {}),
        };

        if (isWaitingUpload) {
          return (
            <Container style={containerStyle}>
              <Placeholder>
                <SyncIcon />
              </Placeholder>
            </Container>
          );
        }

        if (hasError) {
          return (
            <Container style={containerStyle}>
              <Placeholder>
                <AlertTriangle color={colors.red} />
              </Placeholder>
            </Container>
          );
        }

        if (isOffline) {
          return (
            <Container style={containerStyle}>
              <Placeholder>
                <OfflineIcon />
              </Placeholder>
            </Container>
          );
        }

        return (
          <Container style={containerStyle}>
            <ImageHolder isVideoPreview={file.contentType.startsWith('video')}>
              <Image
                src={imageUrl}
                style={imagePreviewStyle}
                onLoad={onLoadImage}
                onError={onLoadImageError}
              />
            </ImageHolder>
          </Container>
        );
      }}
    </ImageContainer>
  );
};

export default ImagePreview;
