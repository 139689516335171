import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '../../../shared/ActionsModal/styled';
import { generalSelectors, usersActions } from '@store';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../../../shared/ActionsModal/ActionsModal';

const UsersAccessActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  return (
    <ActionsModal>
      {() => (
        <Action
          onClick={() =>
            dispatch(
              usersActions.access.toggleConfirmDeleteModal(selectedTableRowKeys)
            )
          }
        >
          {formatMessage({ id: 'Revoke' })}
          {selectedTableRowKeys.length > 1
            ? ` (${selectedTableRowKeys.length})`
            : ''}
        </Action>
      )}
    </ActionsModal>
  );
};

export default UsersAccessActionsModal;
