import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '@components/shared/ActionsModal/styled';
import { generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';

const SchedulePlansActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const schedulePlansDictionary = useSelector(
    schedulePlansSelectors.getSchedulePlansDictionary
  );
  let schedulePlan =
    selectedTableRowKeys.length === 1
      ? schedulePlansDictionary[selectedTableRowKeys[0]]
      : null;

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                if (schedulePlan) {
                  dispatch(
                    schedulePlansActions.toggleAddEditModal({
                      show: true,
                      schedulePlanId: schedulePlan.id,
                    })
                  );
                }

                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          <Action
            onClick={() => {
              dispatch(
                schedulePlansActions.toggleConfirmDeleteModal(
                  selectedTableRowKeys.length > 1 || !schedulePlan
                    ? {
                        multiple: true,
                        data: selectedTableRowKeys,
                      }
                    : {
                        multiple: false,
                        data: schedulePlan,
                      }
                )
              );
            }}
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default SchedulePlansActionsModal;
