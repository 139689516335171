import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@hooks';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { routes } from '@config';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { notification } from '@utils';
import { SchedulePlanDetailsPage } from '@application/SchedulePlans/models/SchedulePlanDetailsPage';

import SchedulePlanDetailsHeader from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetailsHeader/SchedulePlanDetailsHeader';
import AddEditSchedulePlanModal from '@presentation/SchedulePlans/AddEditSchedulePlanModal';
import SchedulePlanDetailsSchedules from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetailsSchedules';
import SchedulePlanDetailsInfo from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetailsInfo/SchedulePlanDetailsInfo';
import DeleteSchedulePlansModal from '@presentation/SchedulePlans/DeleteSchedulePlansModal';
import SchedulePlanDetailsPages from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetailsPages/SchedulePlanDetailsPages';
import SchedulePlanPeriodsTable from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanPeriodsTable/SchedulePlanPeriodsTable';
import StartNewPeriodModal from '@presentation/SchedulePlans/SchedulePlanDetails/StartNewPeriodModal/StartNewPeriodModal';

interface IProps {}

const SchedulePlanDetails: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const page = useSelector(
    schedulePlansSelectors.getSchedulePlanDetailsCurrentPage
  );

  const { id: schedulePlanId } = useParams();

  useEffect(() => {
    async function getSchedulePlanDetails(id: string) {
      const resultAction = await dispatch(
        schedulePlansActions.getSchedulePlanDetails(id)
      );

      if (
        schedulePlansActions.getSchedulePlanDetails.fulfilled.match(
          resultAction
        )
      ) {
        if (page === SchedulePlanDetailsPage.SchedulingRules) {
          dispatch(schedulesActions.getSchedules());
        } else {
          dispatch(schedulePlansActions.getSchedulePlanPeriods());
        }
      } else {
        notification.error({
          message: formatMessage({
            id: 'ErrorWhileLoadingAdvancedScheduleDetails',
          }),
        });

        navigate(routes.advancedSchedules);
      }
    }

    if (schedulePlanId) {
      getSchedulePlanDetails(schedulePlanId);
    } else {
      navigate(routes.advancedSchedules);
    }
  }, [schedulePlanId]);

  useEffect(() => {
    return () => {
      dispatch(schedulePlansActions.resetDetailsData());
    };
  }, []);

  return (
    <>
      <SchedulePlanDetailsHeader />
      <SchedulePlanDetailsInfo />
      <SchedulePlanDetailsPages />
      {page === SchedulePlanDetailsPage.SchedulingRules ? (
        <SchedulePlanDetailsSchedules />
      ) : (
        <SchedulePlanPeriodsTable />
      )}
      <AddEditSchedulePlanModal />
      <DeleteSchedulePlansModal />
      <StartNewPeriodModal />
    </>
  );
};

export default SchedulePlanDetails;
