import styled from 'styled-components';
import Space from 'antd/es/space';
import AntAlert from '@repo/shared/components/Alert';

import { colors } from '@repo/shared/config';

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const Dates = styled.div`
  margin-bottom: 20px;
`;

export const FormGroupRow = styled.div`
  display: flex;
  align-items: flex-start;

  & > * {
    &:first-child {
      display: block;
      height: 32px;
      width: 40%;
      padding-top: 6px;
    }

    &:last-child {
      width: 60%;
    }
  }
`;

export const Alert = styled(AntAlert)`
  margin-top: 25px;
  align-items: flex-start;

  .ant-alert-icon {
    margin-top: 2px;
  }

  .ant-alert-message {
    font-size: 16px;
    line-height: 20px;
  }

  &.ant-alert-warning {
    color: ${colors.gray10};
  }
`;
