import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -3px;

  & > * {
    margin: 0 3px;
  }
`;
