import React from 'react';

import { Container, Label, Content } from './styled';

interface IProps {
  label?: string;
  content: React.ReactNode;
}

const ContentBlock: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  content,
}) => {
  return (
    <Container>
      <Label>{label || ''}</Label>
      <Content>{content}</Content>
    </Container>
  );
};

export default ContentBlock;
