import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Score = styled.div`
  display: inline-flex;
  align-items: center;

  ${({ circleColor }: { circleColor?: string }) =>
    circleColor !== undefined
      ? `
    &:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-right: 5px;
    background: ${circleColor};
  }
  `
      : ''}
`;

export const Change = styled.span`
  display: inline-block;
  margin-left: 3px;
  margin-top: 2.72px;
  font-size: 10px;
  line-height: 11px;
  color: ${({ isPositive }: { isPositive: boolean }) =>
    isPositive ? colors.green : colors.red};
`;
