import React from 'react';
import { ChevronRight } from 'react-feather';

import {
  Link,
  DisabledLink,
  Button,
  Name,
  Icon,
  Extra,
  ExtraIcon,
} from './styled';

interface IProps {
  disabled?: boolean;
  route?: string;
  onClick?: () => void;
  extra?: React.ReactNode;
  icon: React.ReactNode;
  name: string;
  e2eDataAttr?: string;
}

const LinkedItem: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  route,
  onClick,
  extra,
  icon,
  name,
  e2eDataAttr,
}) => {
  const content = (
    <>
      <Icon>{icon}</Icon>
      <Name>{name}</Name>
      <Extra>
        {extra}
        {!disabled && (
          <ExtraIcon>
            <ChevronRight />
          </ExtraIcon>
        )}
      </Extra>
    </>
  );

  if (route) {
    if (disabled) {
      return <DisabledLink>{content}</DisabledLink>;
    }

    return (
      <Link to={route} target="_blank" data-e2e={e2eDataAttr}>
        {content}
      </Link>
    );
  }

  return (
    <Button
      disabled={disabled}
      type="button"
      onClick={onClick}
      data-e2e={e2eDataAttr}
    >
      {content}
    </Button>
  );
};

export default LinkedItem;
