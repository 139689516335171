import { BaseApiClient } from '@repo/shared/api';
import { IIssueTypesApiClient } from '@infrastructure/IssueTypes/models/IIssueTypesApiClient';
import { ITableFilters, IPagedResponse, IConcise } from '@repo/shared/types';
import {
  IssueType,
  IssueTypeDetails,
} from '@domain/IssueTypes/models/IssueType';
import { transformIssueTypeToDto } from '@infrastructure/IssueTypes/transformers/transformIssueTypeToDto';

class IssueTypesApiClient
  extends BaseApiClient
  implements IIssueTypesApiClient
{
  public async getIssueTypes(filters: ITableFilters) {
    return this._apiService.get<IPagedResponse<IssueType>>({
      url: `issues/types`,
      query: filters,
    });
  }

  public async getConciseIssueTypes() {
    return this._apiService.get<IConcise[]>({
      url: 'issues/types/concise',
    });
  }

  public async getIssueTypeDetails(issueTypeId: string) {
    return this._apiService.get<IssueTypeDetails>({
      url: `issues/types/${issueTypeId}`,
    });
  }

  public async getIssueTypeDetailsPublic(
    companyId: string,
    issueTypeId: string
  ) {
    return this._apiService.get<IssueTypeDetails>({
      url: `issues/public/types/${issueTypeId}`,
      companyId,
    });
  }

  public async createIssueType(issueType: Omit<IssueType, 'id' | 'fields'>) {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `issues/types`,
      body: transformIssueTypeToDto(issueType),
    });

    return id;
  }

  public async editIssueType(issueType: IssueType) {
    await this._apiService.put({
      url: `issues/types/${issueType.id}`,
      body: transformIssueTypeToDto(issueType),
    });
  }

  public async deleteIssueTypes(ids: string[]) {
    await this._apiService.delete({
      url: `issues/types`,
      body: {
        ids,
      },
    });
  }
}

export default IssueTypesApiClient;
