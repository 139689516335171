import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPagedResponse } from '@repo/shared/types';
import { Logger } from '@repo/shared/services';
import { getErrorMessage } from '@repo/shared/utils';
import IssueTypeQrCodesApiClient from '@infrastructure/IssueTypes/api/IssueTypeQrCodesApiClient';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';
import { IssueTypeQrCodesFilters } from '@application/IssueTypes/models/IssueTypeQrCodesFilters';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';
import { CreateIssueTypeQrCodeDto } from '@infrastructure/IssueTypes/dto/CreateIssueTypeQrCodeDto';
import { UpdateIssueTypeQrCodeDto } from '@infrastructure/IssueTypes/dto/UpdateIssueTypeQrCodeDto';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { IssueTypeQrCodeModalState } from '@application/IssueTypes/models/IssueTypeQrCodeModalState';

const apiClient = new IssueTypeQrCodesApiClient();

const getQrCodes = createAsyncThunk<
  IPagedResponse<IssueTypeQrCode>,
  Partial<IssueTypeQrCodesFilters> | null | undefined,
  { rejectValue: string }
>('issueTypeQrCodes/getQrCodes', async (_, { rejectWithValue, getState }) => {
  try {
    return await apiClient.getQrCodes(
      issueTypeQrCodesSelectors.getFilters(getState())
    );
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleFiltersModal = createAction<boolean>(
  'issueTypeQrCodes/toggleFiltersModal'
);

const toggleAddEditModal = createAction<{
  show: boolean;
  issueTypeQrCodeId?: string;
}>('issueTypeQrCodes/toggleAddEditModal');

const createQrCode = createAsyncThunk<{ id: string }, CreateIssueTypeQrCodeDto>(
  'issueTypeQrCodes/createQrCode',
  async (dto, { rejectWithValue }) => {
    try {
      return await apiClient.createQrCode(dto);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const editQrCode = createAsyncThunk<void, UpdateIssueTypeQrCodeDto>(
  'issueTypeQrCodes/editQrCode',
  async (dto, { rejectWithValue }) => {
    try {
      await apiClient.updateQrCode(dto);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteIssueQrCodes = createAsyncThunk<void, string[]>(
  'issueTypeQrCodes/deleteQrCodes',
  async (ids, { rejectWithValue }) => {
    try {
      await apiClient.deleteQrCodes(ids);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleConfirmDeleteModal = createAction<
  DeleteModalState<IssueTypeQrCode>
>('issueTypeQrCodes/toggleConfirmDeleteModal');

const resetQrCodesData = createAction('issueTypeQrCodes/resetIssueQrCodesData');

const toggleQrCodeModal = createAction<IssueTypeQrCodeModalState | null>(
  'issueTypeQrCodes/toggleQrCodeModal'
);

export const issueTypeQrCodesActions = {
  getQrCodes,
  toggleFiltersModal,
  toggleAddEditModal,
  createQrCode,
  editQrCode,
  deleteIssueQrCodes,
  toggleConfirmDeleteModal,
  resetQrCodesData,
  toggleQrCodeModal,
};
