import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { getColumnSortProperties } from '@utils';
import { useAppDispatch } from '@hooks';
import {
  issueTypesActions,
  issueTypesSelectors,
} from '@application/IssueTypes/store';
import { e2eTestElements, routes } from '@config';
import { config } from '@repo/shared/config';
import { IssueType } from '@domain/IssueTypes/models/IssueType';

import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import IssueTypesActionsMenu from './IssueTypesActionsMenu';

const IssueTypesTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { loading, error, meta, data } = useSelector(
    issueTypesSelectors.getIssueTypes
  );
  const filters = useSelector(issueTypesSelectors.getIssueTypesFilters);

  return (
    <Table
      error={error}
      loading={loading}
      meta={meta}
      e2eDataAttr={e2eTestElements.issues.issueTypes.table}
      onRow={(issueType) => {
        return {
          onClick: () => {
            navigate(routes.issueTypeDetails.replace(':id', issueType.id));
          },
        };
      }}
      onUnmount={() => dispatch(issueTypesActions.resetIssueTypes())}
      dataSource={data.map((entity: IssueType) => ({
        key: entity.id,
        ...entity,
      }))}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          className: 'cell-text-link',
        },
        {
          title: formatMessage({ id: 'Description' }),
          dataIndex: 'description',
          key: 'description',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'description'
          ),
        },
        {
          title: formatMessage({ id: 'Action' }),
          key: 'action',
          align: 'center' as 'center',
          width: 70,
          render: (issueType: IssueType) => (
            <IssueTypesActionsMenu issueType={issueType} />
          ),
        },
      ]}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage(
                  {
                    id:
                      filters.search !== ''
                        ? 'NoIssueTypesSearchResults'
                        : 'NoIssueTypes',
                  },
                  { searchStr: filters.search }
                )}
                <br />
                {formatMessage({ id: 'YouCanAddIssueTypeRightNow' })}
              </>
            }
            button={{
              text: `+ ${formatMessage({ id: 'CreateIssueType' })}`,
              type: 'link',
              onClick: () => {
                navigate(routes.createIssueType);
              },
            }}
            howItWorksUrl={config.urls.issuesSupport}
          />
        ),
      }}
      onPageChange={(update) => {
        dispatch(issueTypesActions.getIssueTypes(update));
      }}
      onSort={(orderBy, orderByDirection) => {
        dispatch(
          issueTypesActions.getIssueTypes({ orderBy, orderByDirection })
        );
      }}
    />
  );
};

export default IssueTypesTable;
