import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Desc = styled.div`
  font-size: 12px;
  line-height: 1.3;
  color: ${colors.gray8};
  margin: 2px 0 6px;
`;
