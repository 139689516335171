import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import Button from '@components/shared/ant/Button';

export const Content = styled.div`
  text-align: center;
  margin-bottom: 28px;
`;

export const Icon = styled.div`
  background: url(${({ src }: { src: string }) => src}) no-repeat center center;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  margin: 0 auto 18px;
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 12px;
`;

export const Text = styled.div`
  p {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -4px;

  & > * {
    width: 50%;
    margin: 0 4px;
  }
`;

export const CancelButton = styled(Button)`
  background: ${colors.gray3};
  color: ${colors.gray8};
  height: 36px;

  &:hover {
    background: ${colors.gray3};
    color: ${colors.gray8};
  }
`;

export const BlueButton = styled(Button)`
  height: 36px;
`;

export const Tip = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray8};
`;

export const BlueButtonsWrapper = styled.div`
  width: 280px;
  margin: 30px auto;

  & > * {
    width: 100%;
    margin-bottom: 12px;
  }
`;
