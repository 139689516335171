import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '@repo/shared/enums';
import { IAnyObject } from '@repo/shared/types';
import { generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';

import SlideModal from '../SideModal/SideModal';

interface IProps {
  children: ({
    data,
    closeModal,
  }: {
    data?: IAnyObject;
    closeModal: () => void;
    opened: boolean;
  }) => React.ReactNode;
  titles: {
    add: string;
    edit: string;
  };
  onClose?: () => void;
  closeBtnE2eDataAttr?: string;
}

const AddEditModal: React.FC<IProps> = ({
  children,
  titles,
  onClose,
  closeBtnE2eDataAttr,
}) => {
  const dispatch = useAppDispatch();
  const modalState = useSelector(generalSelectors.getModal(Modal.AddEdit));

  const closeModal = useCallback(() => {
    dispatch(generalActions.hideModal(Modal.AddEdit));

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [dispatch, onClose]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, [closeModal]);

  const { data, opened } = modalState;

  return (
    <SlideModal
      opened={opened}
      onModalClose={() => closeModal()}
      title={!data ? titles.add : titles.edit}
      closeBtnE2eDataAttr={closeBtnE2eDataAttr}
    >
      {children({
        data,
        closeModal,
        opened,
      })}
    </SlideModal>
  );
};

export default AddEditModal;
