import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';

import { Container, Content, CheckIcon, Title } from './styled';
import { accountSelectors } from '@store';
import { BillingPeriod } from '@repo/shared/enums';
import useBillingPeriodsNames from '../PaymentForm/OrderSummary/useBillingPeriodsNames';
import { config } from '@repo/shared/config';
import { date } from '@utils';

import SubscriptionSummary from '../../../SubscriptionSummary/SubscriptionSummary';

const PaymentResult: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { data, loading } = useSelector(accountSelectors.getSubscription);
  const periodNames = useBillingPeriodsNames();
  const {
    usersCost,
    observersCost,
    observersQuantity,
    usersQuantity,
    totalCost,
  } = useSelector(accountSelectors.getTotalCost);

  let details: any[] = [];

  if (!loading && data.automatedSubscriptionData) {
    const subscription = data.automatedSubscriptionData;

    details = [
      {
        label: formatMessage({
          id: 'PurchasedDate',
        }),
        value: date().format(config.dateFormat),
      },
      {
        label: formatMessage({
          id: 'PaymentMethod',
        }),
        value: `•••• •••• •••• ${subscription.creditCard?.lastFourDigits}`,
      },
      {
        label: (
          <>
            {formatMessage({
              id: 'Professional',
            })}
            {observersQuantity === 0 ? (
              <>
                ,{' '}
                {formatMessage(
                  { id: 'XActiveUsers' },
                  { count: usersQuantity }
                )}
              </>
            ) : (
              <div style={{ fontStyle: 'italic' }}>
                {formatMessage({ id: 'XUsers' }, { count: usersQuantity })} ($
                {usersCost}) +{' '}
                {formatMessage(
                  { id: 'XObservers' },
                  { count: observersQuantity }
                )}{' '}
                ($
                {observersCost})
              </div>
            )}
          </>
        ),
        value: `$${totalCost}`,
      },
      {
        label: formatMessage({
          id: 'BillingPeriod',
        }),
        value: periodNames[subscription.billingPeriod as BillingPeriod],
      },
    ];
  }

  return (
    <Container>
      {loading ? (
        <Skeleton active />
      ) : (
        <Content>
          <CheckIcon />
          <Title>{formatMessage({ id: 'YourPurchaseWasSuccessful' })}</Title>
          <SubscriptionSummary details={details} readOnly />
        </Content>
      )}
    </Container>
  );
};

export default PaymentResult;
