import { MimeType } from '@repo/shared/enums';

export const allowedLogoMimeTypes = [MimeType.png, MimeType.jpeg];

export const config = {
  pageSize: 50,
  googleApiKey: import.meta.env.VITE_GOOGLE_API_KEY || '',
  dateFormat: 'MMM D, YYYY',
  dateTimeFormat: 'MMM D, YYYY, hh:mm a',
  apiDateFormat: 'YYYY-MM-DDTHH:mm:ss',
  defaultPassedThreshold: 90,
  pubnub: {
    subKey: import.meta.env.VITE_PUBNUB_SUB_KEY || '',
    pubKey: import.meta.env.VITE_PUBNUB_PUB_KEY || '',
  },
  gtmId: 'GTM-KF3HRGV',
  urls: {
    privacyPolicy: 'https://www.monitorqa.com/privacy-policy/',
    contact: 'https://www.monitorqa.com/contact/',
    support: 'https://support.monitorqa.com/',
    dev: 'https://developer.monitorqa.com/',
    termsOfUse: 'https://www.monitorqa.com/terms-of-use/',
    salesEmail: 'sales@monitorqa.com',
    supportEmail: 'support@monitorqa.com',
    supportPhone: '+1.858.256.5559',
    interactiveDemo: 'https://www.monitorqa.com/book-a-demo/',
    multipleAccounts: 'https://www.monitorqa.com/support/multiple-accounts/',
    capterra: 'https://reviews.capterra.com/new/215426',
    submitTicket: 'https://support.monitorqa.com/hc/en-us/requests/new',
    auditsSupport:
      'https://support.monitorqa.com/hc/en-us/articles/1500001652782-Assigning-and-scheduling-audits',
    actionsSupport:
      'https://support.monitorqa.com/hc/en-us/articles/360061941253-Creating-and-assigning-corrective-actions',
    schedulesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/1500001652782-Assigning-and-scheduling-audits',
    templatesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/21902808588183-Creating-an-Audit-Template',
    actionTemplatesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/19534822377111-Templated-corrective-actions',
    issuesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/21472283459351-Issue-Reporting-Module',
    qrCodesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/21472283459351-Issue-Reporting-Module#:~:text=the%20integration%20of-,QR%20Codes,-.%20Issuers%20can%20now',
    auditObjectsSupport:
      'https://support.monitorqa.com/hc/en-us/articles/360061941053-Create-audit-objects',
    auditObjectGroupsSupport:
      'https://support.monitorqa.com/hc/en-us/articles/21902486838935-Object-Groups',
    attributesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/19534881872151-Attributes',
    userGroupsSupport:
      'https://support.monitorqa.com/hc/en-us/articles/21901990343575-User-Groups',
    jobTitlesSupport:
      'https://support.monitorqa.com/hc/en-us/articles/19535046701079-Job-titles',
  },
  chargify: {
    publicKey: import.meta.env.VITE_CHARGIFY_PUBLIC_KEY || '',
    host: import.meta.env.VITE_CHARGIFY_HOST || '',
  },
  freeTrialInDays: 14,
  maxUploadFileSizeMb: 400,
  maxConcurrentUploadsQty: 5,
  liveChatLicense: import.meta.env.VITE_LIVECHAT_LICENCE!,
};
