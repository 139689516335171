import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRootState } from '../../../core/frameworks/redux';
import { IConcise, IPagedResponse, ITableFilters } from '@repo/shared/types';
import { getErrorMessage } from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import {
  SchedulePlan,
  SchedulePlanDetails,
} from '@domain/SchedulePlans/models/SchedulePlan';
import SchedulePlansApiClient from '@infrastructure/SchedulePlans/api/SchedulePlansApiClient';
import SchedulePlanPeriodsApiClient from '@infrastructure/SchedulePlans/api/SchedulePlanPeriodsApiClient';
import { CreateSchedulePlanDto } from '@infrastructure/SchedulePlans/models/CreateSchedulePlanDto';
import { UpdateSchedulePlanDto } from '@infrastructure/SchedulePlans/models/UpdateSchedulePlanDto';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { ChangeStatusDto } from '@application/SchedulePlans/models/ChangeStatusDto';
import {
  CurrentSchedulePlanPeriod,
  SchedulePlanPeriod,
  SchedulePlanPeriodAuditor,
} from '@domain/SchedulePlans/models/SchedulePlanPeriod';
import { UnexpectedError } from '@repo/shared/errors';
import { SchedulePlanDetailsPage } from '@application/SchedulePlans/models/SchedulePlanDetailsPage';
import { SchedulePlanPeriodAuditorType } from '@application/SchedulePlans/models/SchedulePlanPeriodAuditorType';
import { SchedulePlanPeriodDetailsPage } from '@application/SchedulePlans/models/SchedulePlanPeriodDetailsPage';
import { SchedulePlanPeriodAudit } from '@domain/SchedulePlans/models/SchedulePlanPeriodAudit';

const schedulePlansApiClient = new SchedulePlansApiClient();
const schedulePlansPeriodsApiClient = new SchedulePlanPeriodsApiClient();

const getSchedulePlans = createAsyncThunk<
  IPagedResponse<SchedulePlan>,
  Partial<ITableFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>(
  'schedulePlans/getSchedulePlans',
  async (_, { rejectWithValue, getState }) => {
    try {
      return await schedulePlansApiClient.getSchedulePlans(
        schedulePlansSelectors.getFilters(getState())
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getConciseSchedulePlans = createAsyncThunk<
  IConcise[],
  void,
  { state: IRootState; rejectValue: string }
>('schedules/getConciseSchedulePlans', async (_, { rejectWithValue }) => {
  try {
    return await schedulePlansApiClient.getConciseSchedulePlans();
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getSchedulePlanDetails = createAsyncThunk<
  SchedulePlanDetails,
  string,
  { state: IRootState; rejectValue: string }
>('schedulePlans/getSchedulePlanDetails', async (id, { rejectWithValue }) => {
  try {
    return await schedulePlansApiClient.getSchedulePlanDetails(id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const createSchedulePlan = createAsyncThunk<
  string,
  CreateSchedulePlanDto,
  { state: IRootState; rejectValue: string }
>('schedulePlans/createSchedulePlan', async (dto, { rejectWithValue }) => {
  try {
    return await schedulePlansApiClient.createSchedulePlan(dto);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const updateSchedulePlan = createAsyncThunk<
  SchedulePlanDetails,
  UpdateSchedulePlanDto,
  { state: IRootState; rejectValue: string }
>('schedulePlans/updateSchedulePlan', async (dto, { rejectWithValue }) => {
  try {
    await schedulePlansApiClient.updateSchedulePlan(dto);
    return await schedulePlansApiClient.getSchedulePlanDetails(dto.id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const deleteSchedulePlans = createAsyncThunk<
  void,
  string[],
  { state: IRootState; rejectValue: string }
>('schedulePlans/deleteSchedulePlans', async (ids, { rejectWithValue }) => {
  try {
    await schedulePlansApiClient.deleteSchedulePlans(ids);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleAddEditModal = createAction<{
  show: boolean;
  schedulePlanId?: string;
}>('schedulePlans/toggleAddEditModal');

const toggleConfirmDeleteModal = createAction<DeleteModalState<SchedulePlan>>(
  'schedulePlans/toggleConfirmDeleteModal'
);

const resetTableData = createAction('schedulePlans/resetTableData');

const resetDetailsData = createAction('schedulePlans/resetDetailsData');

const changeStatus = createAsyncThunk<
  { dto: ChangeStatusDto; currentPeriod: CurrentSchedulePlanPeriod },
  ChangeStatusDto,
  { state: IRootState; rejectValue: string }
>('schedulePlans/changeStatus', async (dto, { rejectWithValue }) => {
  try {
    await schedulePlansApiClient.changeSchedulePlanStatus(dto);

    const { currentPeriod } =
      await schedulePlansApiClient.getSchedulePlanDetails(dto.id);

    return { dto, currentPeriod };
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getSchedulePlanPeriods = createAsyncThunk<
  IPagedResponse<SchedulePlanPeriod>,
  Partial<ITableFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>(
  'schedulePlans/getSchedulePlanPeriods',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const { data } = schedulePlansSelectors.getSchedulePlanDetails(state);
      const filters =
        schedulePlansSelectors.getSchedulePlanPeriodsFilters(state);

      if (data === null) {
        throw new UnexpectedError('Schedule plan details are not set');
      }

      return await schedulePlansPeriodsApiClient.getPeriods(data.id, filters);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const setSchedulePlanDetailsPage = createAction<SchedulePlanDetailsPage>(
  'schedulePlans/setSchedulePlanDetailsPage'
);

const resetPeriodsListData = createAction('schedulePlans/resetPeriodsListData');

const getSchedulePlanPeriod = createAsyncThunk<
  SchedulePlanPeriod,
  { schedulePlanId: string; schedulePlanPeriodId: string },
  { state: IRootState; rejectValue: string }
>(
  'schedulePlans/getSchedulePlanPeriod',
  async ({ schedulePlanId, schedulePlanPeriodId }, { rejectWithValue }) => {
    try {
      return await schedulePlansPeriodsApiClient.getPeriod(
        schedulePlanId,
        schedulePlanPeriodId
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const resetPeriodData = createAction('schedulePlans/resetPeriodData');

const getSchedulePlanPeriodAuditors = createAsyncThunk<
  IPagedResponse<SchedulePlanPeriodAuditor>,
  Partial<ITableFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>(
  'schedulePlans/getSchedulePlanPeriodAuditors',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const { page, schedulePlanId, data } =
        schedulePlansSelectors.getSchedulePlanPeriod(state);
      const { filters } =
        schedulePlansSelectors.getSchedulePlanPeriodAuditors(state);

      if (!schedulePlanId || !data) {
        throw new UnexpectedError('Schedule plan period is not set');
      }

      return await schedulePlansPeriodsApiClient.getPeriodAuditors({
        schedulePlanId,
        schedulePlanPeriodId: data.id,
        type:
          page === SchedulePlanPeriodDetailsPage.BusyAuditors
            ? SchedulePlanPeriodAuditorType.Busy
            : SchedulePlanPeriodAuditorType.Waiting,
        filters,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getSchedulePlanPeriodAudits = createAsyncThunk<
  IPagedResponse<SchedulePlanPeriodAudit>,
  Partial<ITableFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>(
  'schedulePlans/getSchedulePlanPeriodAudits',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const { filters } =
        schedulePlansSelectors.getSchedulePlanPeriodAudits(getState());
      const { schedulePlanId, data } =
        schedulePlansSelectors.getSchedulePlanPeriod(state);

      if (!schedulePlanId || !data) {
        throw new UnexpectedError('Schedule plan period is not set');
      }

      return await schedulePlansPeriodsApiClient.getPeriodAudits({
        schedulePlanId,
        schedulePlanPeriodId: data.id,
        filters,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleStartNewPeriodModal = createAction<boolean>(
  'schedulePlans/toggleStartNewPeriodModal'
);

const setSchedulePlanPeriodDetailsPage =
  createAction<SchedulePlanPeriodDetailsPage>(
    'schedulePlans/setSchedulePlanPeriodDetailsPage'
  );

const regenerateSchedulePlanPeriodAudits = createAsyncThunk<
  void,
  void,
  { state: IRootState; rejectValue: string }
>(
  'schedulePlans/regenerateSchedulePlanPeriodAudits',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      const { data } = schedulePlansSelectors.getSchedulePlanDetails(state);

      if (!data) {
        throw new UnexpectedError(
          'Advanced schedule details are not set in the configureStore'
        );
      }

      await schedulePlansPeriodsApiClient.regenerate(data.id);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const setSchedulePlanSchedulesCount = createAction<number>(
  'schedulePlans/setSchedulePlanSchedulesCount'
);

export const schedulePlansActions = {
  resetListData: resetTableData,
  getSchedulePlans,
  getConciseSchedulePlans,
  deleteSchedulePlans,
  getSchedulePlanDetails,
  toggleAddEditModal,
  createSchedulePlan,
  updateSchedulePlan,
  toggleConfirmDeleteModal,
  resetDetailsData,
  changeStatus,
  getSchedulePlanPeriods,
  setSchedulePlanDetailsPage,
  resetPeriodsListData,
  getSchedulePlanPeriod,
  resetPeriodData,
  getSchedulePlanPeriodAuditors,
  getSchedulePlanPeriodAudits,
  toggleStartNewPeriodModal,
  setSchedulePlanPeriodDetailsPage,
  regenerateSchedulePlanPeriodAudits,
  setSchedulePlanSchedulesCount,
};
