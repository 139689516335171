import styled from 'styled-components';
import { colors } from '@repo/shared/config';
import { slideDimensions } from '@repo/shared/components/Previews/FileGallery/styled';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.gray3};

  ${slideDimensions};
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  color: ${colors.gray10};
  font-size: 14px;
  text-align: center;

  svg, div {
    width: 55px;
    height: 55px;
    margin-bottom: 15px;
  }
`;

export const LoadingPlaceholder = styled(Placeholder)`
  svg {
    color: ${colors.blue2};
  }
`;

export const ErrorPlaceholder = styled(Placeholder)`
  svg {
    color: ${colors.red};
  }
`;
