import styled from 'styled-components';
import Space from 'antd/es/space';

import { colors } from '@repo/shared/config';

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const LocationLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
`;

export const LabelTitle = styled.span`
  color: rgba(0, 0, 0, 0.85);
`;

export const LabelTagline = styled.span`
  display: block;
  font-size: 12px;
  line-height: 22px;
  color: ${colors.gray8};
`;
