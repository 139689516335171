import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import {
  generalSelectors,
  generalActions,
  usersSelectors,
  usersActions,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '../../shared/ant/Modal/Modal';
const ConfirmDeleteJobTitlesModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const deleteCandidatesIds = useSelector(
    usersSelectors.jobTitles.getDeleteCandidatesIds
  );
  const jobTitlesMap = useSelector(usersSelectors.jobTitles.getMap);

  const attribute =
    deleteCandidatesIds.length === 1
      ? jobTitlesMap[deleteCandidatesIds[0]]
      : null;

  const closeModal = useCallback(
    () => dispatch(usersActions.jobTitles.toggleConfirmDeleteModal([])),
    []
  );

  return (
    <Modal
      title={formatMessage(
        { id: 'RemoveJobTitles' },
        { count: deleteCandidatesIds.length }
      )}
      open={deleteCandidatesIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          usersActions.jobTitles.deleteJobTitles(deleteCandidatesIds)
        );

        if (
          usersActions.jobTitles.deleteJobTitles.fulfilled.match(resultAction)
        ) {
          dispatch(usersActions.jobTitles.getData());
          dispatch(generalActions.getConciseJobTitles());

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeletingJobTitles' }),
          });
        }

        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveJobTitles' },
        { itemCount: 1 }
      ).replace('%replaceValue%', attribute?.name || '')}
    </Modal>
  );
};

export default ConfirmDeleteJobTitlesModal;
