import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.footer`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 12px;
  line-height: 1.6;
  text-align: center;
  padding: 26px 20px;
  background: ${({ hasBackground }: { hasBackground?: boolean }) =>
    hasBackground ? colors.blue6 : 'transparent'};

  & > * {
    margin: 0 10px;
    color: ${colors.white};
  }
`;
