import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowUp } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Spin from 'antd/es/spin';

import {
  Content,
  Form,
  SendCommentBtn,
  TextField,
  UploadFileIcon,
} from './styled';
import { useAppDispatch } from '@hooks';
import { FileSourceType, ThumbSize, UploadStatus } from '@repo/shared/enums';
import { accountSelectors, generalActions, generalSelectors } from '@store';
import { date, dateInTimeZone, notification } from '@utils';
import { IFile } from '@repo/shared/types';
import { Comment } from '@domain/common/Comment';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';

import UploadFilesTrigger from '@components/shared/UploadFiles/UploadFilesTrigger';
import Previews from '@components/shared/Previews/Previews';

interface IProps {
  comment?: Comment | null;
  onSend: (comment: Comment) => Promise<boolean>;
  ianaTimeZone?: string | null;
  disabled?: boolean;
}

const AddEditComment: React.FC<React.PropsWithChildren<IProps>> = ({
  comment,
  onSend,
  ianaTimeZone,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [commentBody, setCommentBody] = useState('');
  const [sending, setSending] = useState(false);
  const [previews, setPreviews] = useState<IFile[]>([]);

  const user = useSelector(accountSelectors.getCurrentUser);
  const uploads = useSelector(generalSelectors.getFilesUploads);

  useEffect(() => {
    if (comment) {
      setCommentBody(comment.body);

      if (comment.files?.length > 0) {
        setPreviews(comment.files);
      }
    }
  }, [JSON.stringify(comment || {})]);

  const sendComment = useCallback(async () => {
    if (!user) {
      return;
    }

    setSending(true);

    const success = await onSend({
      id: comment?.id || uuid(),
      files: previews.map(({ file, ...preview }) => ({ ...preview })),
      body: commentBody,
      createdBy: {
        id: user.id,
        name: user.name,
      },
      createdAt: dateInTimeZone(date(), ianaTimeZone).format(
        config.apiDateFormat
      ),
    });

    setPreviews([]);
    setCommentBody('');

    if (success) {
      dispatch(generalActions.resetFileUploads());
    } else {
      notification.error({
        message: formatMessage({ id: 'ErrorWhileAddingComment' }),
      });
    }

    setSending(false);
  }, [commentBody, JSON.stringify(previews)]);

  useEffect(() => {
    let updatedPreviews: IFile[] | null = null;

    for (let fileId in uploads) {
      const upload = uploads[fileId];

      if (upload.status === UploadStatus.Done) {
        const previewIndex = previews.findIndex(({ id }) => fileId === id);

        if (
          previewIndex >= 0 &&
          previews[previewIndex].updatedAt !== upload.file.updatedAt
        ) {
          if (updatedPreviews === null) {
            updatedPreviews = [...previews];
          }

          updatedPreviews[previewIndex] = {
            ...updatedPreviews[previewIndex],
            updatedAt: upload.file.updatedAt,
          };
        }
      }
    }

    if (updatedPreviews) {
      setPreviews([...updatedPreviews]);
    }
  }, [JSON.stringify(uploads)]);

  const previewsRef = useRef(previews);
  previewsRef.current = previews;

  return (
    <Spin spinning={sending}>
      <Form>
        <UploadFilesTrigger
          source={FileSourceType.CorrectiveActionCommentFile}
          onAddFiles={(files) => {
            setPreviews([...(previewsRef.current || []), ...files]);
          }}
          onRemoveFile={(fileId) => {
            setPreviews([
              ...previews.filter((preview) => preview.id !== fileId),
            ]);
          }}
          disabled={disabled}
        >
          <UploadFileIcon />
        </UploadFilesTrigger>
        <Content>
          <TextField
            onChange={(e) => setCommentBody(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && commentBody.trim() !== '' && !sending) {
                e.preventDefault();
                sendComment();
              }
            }}
            disabled={disabled}
            data-e2e={e2eTestElements.actions.detailsModal.sendComment.input}
            value={commentBody}
            placeholder={`${formatMessage({ id: 'AddComment' })}...`}
          />
          <Previews
            files={previews.map(({ file, ...other }) => ({ ...other }))}
            onRemove={(id) => {
              setPreviews(previews.filter((file) => file.id !== id));
            }}
            thumbSize={ThumbSize.xs}
            onUpdate={(updatedFile, index) => {
              setPreviews(
                previews.map((file, i) => (i === index ? updatedFile : file))
              );
            }}
            fileSourceType={FileSourceType.CorrectiveActionCommentFile}
          />
        </Content>
        <SendCommentBtn
          type="button"
          onClick={sendComment}
          disabled={commentBody === ''}
          data-e2e={e2eTestElements.actions.detailsModal.sendComment.sendBtn}
        >
          <ArrowUp />
        </SendCommentBtn>
      </Form>
    </Spin>
  );
};

export default AddEditComment;
