import React, { useState } from 'react';
import { useSpring, config } from 'react-spring';
import Measure from 'react-measure';
import { useIntl } from 'react-intl';

import { Container, Content, Text } from './styled';
import { colors } from '@repo/shared/config';
import { getFlagColorAndLangId } from '@repo/shared/utils';
import { ItemFlag } from '@repo/shared/enums';

import FailedInRowCount from '@repo/shared/components/FailedInRowCount/FailedInRowCount';

const initialHeight = 8;
const initialWidth = 30;
const initialDimensions = { height: initialHeight, width: initialWidth };

interface IProps {
  expanded: boolean;
  expandable?: boolean;
  onClick?: () => void;
  flag: ItemFlag;
  failedInRowCount: number;
}

const AnimatedFlag: React.FC<React.PropsWithChildren<IProps>> = ({
  expanded,
  expandable,
  onClick,
  flag,
  failedInRowCount,
}) => {
  const { formatMessage } = useIntl();

  const [dimensions, setDimensions] = useState(initialDimensions);

  const props = useSpring({
    from: initialDimensions,
    to: expanded ? dimensions : initialDimensions,
    config: config.slow,
    immediate: !expandable,
  });

  const { color, langId } = getFlagColorAndLangId(flag);

  return (
    <Container
      type="button"
      onClick={onClick}
      style={{
        ...props,
        background: color,
        cursor: expandable ? 'pointer' : 'default',
      }}
    >
      <Measure
        bounds
        onResize={(contentRect) => {
          setDimensions({
            width: contentRect.bounds?.width || initialHeight,
            height: contentRect.bounds?.height || initialWidth,
          });
        }}
      >
        {({ measureRef }) => (
          <Content
            ref={measureRef}
            expanded={expanded}
            single={failedInRowCount <= 1}
          >
            <Text>{formatMessage({ id: langId })}</Text>
            {failedInRowCount > 1 && (
              <FailedInRowCount value={failedInRowCount} color={colors.white} />
            )}
          </Content>
        )}
      </Measure>
    </Container>
  );
};

export default AnimatedFlag;
