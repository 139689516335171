import { FLAGS } from '@repo/shared/config';
import { ItemFlag } from '@repo/shared/enums';

export function getFlagsList() {
  return Object.entries(FLAGS)
    .map(([flag, flagData]) => ({
      flag: parseInt(flag) as ItemFlag,
      ...flagData,
    }))
    .sort((a, b) => a.flag - b.flag);
}

export function getFlagColorAndLangId(flag: ItemFlag) {
  return FLAGS[flag];
}
