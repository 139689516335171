import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

export const Container = styled(animated.div)`
  height: 56px;
  overflow: hidden;
  margin-right: -30px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: ${colors.gray3};
  border-color: ${colors.gray3};
  padding: 4px 12px;
  height: 32px;
  margin-right: 30px;
`;

export const Text = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
`;

export const ChangeVisibilityBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  padding: 0;
  width: 20px;
  height: 20px;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.gray8};
  }
`;

export const CopyKeyBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  padding: 0;
  position: absolute;
  right: -30px;

  svg {
    width: 19px;
    height: 19px;
    color: ${colors.blue2};
  }
`;
