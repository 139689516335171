import React from 'react';
import { useIntl } from 'react-intl';
import { AlertCircle } from 'react-feather';

import { ActionDetails } from '@domain/Actions/models/Action';
import { routes, e2eTestElements } from '@config';

import LinkedItem from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/LinkedItem/LinkedItem';
import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';
import {usePermission} from '@hooks';
import {Permission} from '@repo/shared/enums';

interface IProps {
  action: ActionDetails;
}

const ActionDetailsLinkedIssue: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  const canViewIssues = usePermission(Permission.CanViewIssues);

  if (!action.issue) {
    return null;
  }

  return (
    <ActionDetailsInfoItem
      label={formatMessage({ id: 'Issue' })}
      content={
        <LinkedItem
          name={action.issue.name}
          disabled={action.issue.isDeleted || !canViewIssues}
          route={routes.issueDetails.replace(':id', action.issue!.id)}
          icon={<AlertCircle />}
          e2eDataAttr={e2eTestElements.actions.detailsModal.linkedIssue}
        />
      }
    />
  );
};

export default ActionDetailsLinkedIssue;
