import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'antd/es/modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Calendar, Flag, X } from 'react-feather';
import YouTube, { YouTubePlayer, YouTubeEvent } from 'react-youtube';

import { accountActions, accountSelectors } from '@store';
import { auditsActions } from '@application/Audits/store/auditsActions';
import {
  ButtonsRow,
  Close,
  Container,
  Content,
  Footer,
  Header,
  StartSampleAuditBtn,
  SubTitle,
  Text,
  Title,
} from './styled';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { AnalyticsEvent } from '@repo/shared/enums';

import BlueIconButton from './BlueIconButton/BlueIconButton';

const WelcomeModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { formatMessage } = useIntl();

  const [videoPlayer, setVideoPlayer] = useState<YouTubePlayer | null>(null);
  const fromCompanySetup = location.state?.fromCompanySetup || false;
  const visible = useSelector(accountSelectors.isWelcomeModalVisible);
  const company = useSelector(accountSelectors.getCompany);

  useEffect(() => {
    if (!visible && videoPlayer) {
      videoPlayer.stopVideo();
    }
  }, [visible, videoPlayer]);

  useEffect(() => {
    if (fromCompanySetup) {
      dispatch(accountActions.toggleWelcomeModal(true));
    }
  }, [fromCompanySetup]);

  function closeModal() {
    dispatch(accountActions.toggleWelcomeModal(false));
    navigate(location.pathname, { replace: true });
  }

  return (
    <Modal
      open={visible}
      width={1100}
      style={{ maxWidth: 'none' }}
      className="modal-no-padding"
      closeIcon={
        <Close data-e2e={e2eTestElements.account.welcomeModal.closeButton}>
          <X />
        </Close>
      }
      onCancel={() => {
        closeModal();

        if (fromCompanySetup) {
          dispatch(
            accountActions.sendAnalyticsEvent(AnalyticsEvent.WelcomePopupClosed)
          );
        }
      }}
      footer={null}
    >
      <Container>
        <Content>
          <Header>
            <div>
              <Title>{formatMessage({ id: 'Welcome' })}!</Title>
              <SubTitle>
                {formatMessage({ id: 'GetStartedWithYourFirstAudit' })}!
              </SubTitle>
            </div>
            {company.hasSampleData && (
              <StartSampleAuditBtn
                type="button"
                data-e2e={
                  e2eTestElements.account.welcomeModal.startSampleAuditButton
                }
                onClick={() => {
                  dispatch(accountActions.toggleWelcomeModal(false));
                  dispatch(auditsActions.toggleStartSampleAuditModal(true));
                }}
              >
                {formatMessage({ id: 'StartSampleAudit' })}
              </StartSampleAuditBtn>
            )}
          </Header>
          <Title>{formatMessage({ id: 'LearnTheBasics' })}!</Title>
          <SubTitle>
            {formatMessage({
              id: 'GetStartedByWatchingQuickOverviewOfThePlatform',
            })}
            !
          </SubTitle>
          <YouTube
            videoId="WmwN592TAlQ"
            style={{ margin: '26px 0 32px' }}
            opts={{ width: 560, height: 315 }}
            onReady={(event: YouTubeEvent) => {
              setVideoPlayer(event.target);
            }}
            onPlay={() => {
              dispatch(
                accountActions.sendAnalyticsEvent(
                  AnalyticsEvent.LearnTheBasicsVideoClicked
                )
              );
            }}
          />
          <Text>{formatMessage({ id: 'ThenTryOurConciergeOnboarding' })}</Text>
          <ButtonsRow>
            <BlueIconButton
              onClick={() => {
                dispatch(
                  accountActions.sendAnalyticsEvent(
                    AnalyticsEvent.LiveConsultationClicked
                  )
                );

                window.open(config.urls.interactiveDemo, '_blank');
              }}
              icon={<Calendar />}
              desc="Calendly"
              e2eDataAttr={
                e2eTestElements.account.welcomeModal.scheduleLiveConsultButton
              }
              text={formatMessage({ id: 'ScheduleLiveConsultation' })}
            />
            <BlueIconButton
              onClick={() => {
                dispatch(accountActions.toggleWelcomeModal(false));
                dispatch(accountActions.selfGuidedTour.start());
              }}
              icon={<Flag />}
              desc={formatMessage({ id: '3Minutes' })}
              text={formatMessage({ id: 'DoSelfGuidedTour' })}
              e2eDataAttr={
                e2eTestElements.account.welcomeModal.doSelfGuidedTourButton
              }
            />
          </ButtonsRow>
          <Footer>
            {formatMessage({
              id: 'TipYouCanReturnHereAtAnyTimeFromTheSideMenu',
            })}
          </Footer>
        </Content>
      </Container>
    </Modal>
  );
};

export default WelcomeModal;
