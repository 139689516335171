import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';

import { Logger } from '@repo/shared/services';
import { Locale } from '@repo/shared/enums';

export function useTranslations(
  locale: Locale = Locale.en
): [boolean, Record<string, string>, Locale] {
  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    async function loadTranslations() {
      const lcl = locale.toLowerCase();
      const locales = [import(`../translations/${lcl}.json`)];

      if (locale !== Locale.en) {
        locales.push(import(`../translations/en.json`));
      }

      dayjs.locale(lcl);

      try {
        const result = await Promise.all(locales);
        const translations = result[0].default;
        const fallbackTranslations = result[1]?.default;

        if (fallbackTranslations) {
          Object.keys(fallbackTranslations).forEach((key) => {
            if (!(key in translations)) {
              translations[key] = fallbackTranslations[key];
            }
          });
        }

        setTranslations(translations);
        setLoading(false);
      } catch (e) {
        Logger.captureException(e);
        setLoading(false);
      }
    }

    loadTranslations();
  }, [locale]);

  return [loading, translations, locale];
}
