import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import Input from 'antd/es/input';

import { notification } from '@utils';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useAppDispatch } from '@hooks';
import { generalActions, generalSelectors } from '@store';
import { IssueCloseReason } from '@domain/Issues/models/IssueEvent';
import { e2eTestElements } from '@config';

import Modal from '@components/shared/ant/Modal/Modal';
import Form from '@components/shared/ant/Form';
import IssueCloseReasonSelect from '@src/presentation/Issues/IssueDetails/IssueCloseReasonSelect';

interface IProps {}

interface FormValues {
  reason: IssueCloseReason;
  comment: string;
}

const CloseIssuesModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<FormValues>();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);

  const issuesIds = useSelector(issuesSelectors.getCloseIssuesModalState);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const detailsIssue = useSelector(issuesSelectors.getDetailsIssue);

  const opened = Array.isArray(issuesIds);

  const closeModal = useCallback(() => {
    form.resetFields();
    dispatch(issuesActions.toggleCloseIssuesModal(null));
  }, []);

  return (
    <Modal
      title={
        opened
          ? formatMessage(
              {
                id: 'CloseIssues',
              },
              {
                count: issuesIds.length,
              }
            )
          : null
      }
      destroyOnClose
      open={opened}
      onOk={async () => {
        if (!opened) {
          return;
        }

        try {
          const { reason, comment } = await form.validateFields();

          setProcessing(true);

          const resultAction = await dispatch(
            issuesActions.closeIssues({
              issuesIds,
              reason,
              comment,
            })
          );

          if (issuesActions.closeIssues.fulfilled.match(resultAction)) {
            notification.success({
              message: formatMessage(
                {
                  id: 'IssuesClosed',
                },
                { count: issuesIds.length }
              ),
            });

            closeModal();

            if (selectedTableRowKeys.length > 0) {
              dispatch(generalActions.selectTableRows([]));
            }

            if (detailsIssue) {
              dispatch(issuesActions.getIssueEvents(detailsIssue.id));
            }

            dispatch(issuesActions.getIssues());
          } else {
            notification.error({
              message: formatMessage(
                { id: 'ErrorWhileClosingIssues' },
                { count: issuesIds.length }
              ),
            });
          }

          setProcessing(false);
        } catch (_) {}
      }}
      okText={formatMessage({
        id: 'Close',
      })}
      confirmLoading={processing}
      width={450}
      onCancel={() => {
        closeModal();
      }}
      e2eDataConfirmAttr={e2eTestElements.issues.issues.closeIssue.saveBtn}
      e2eDataCancelAttr={e2eTestElements.issues.issues.closeIssue.cancelBtn}
      closeIcon={<X />}
      styles={{
        body: {
          marginTop: '15px',
          marginBottom: '20px',
        },
      }}
    >
      <Form<FormValues>
        form={form}
        layout="vertical"
        initialValues={{ comment: '', reason: null }}
      >
        <Form.Item
          name="reason"
          label={formatMessage({ id: 'Reason' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <IssueCloseReasonSelect
            e2eDataAttr={e2eTestElements.issues.issues.closeIssue.reasonSelect}
            disabled={processing}
            placeholder={formatMessage({ id: 'PleaseSelectReasonToClose' })}
          />
        </Form.Item>
        <Form.Item
          name="comment"
          label={formatMessage({ id: 'CommentOptional' })}
        >
          <Input.TextArea
            rows={5}
            data-e2e={e2eTestElements.issues.issues.closeIssue.reasonComment}
            disabled={processing}
            placeholder={formatMessage({ id: 'TypeSomethingHere' })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CloseIssuesModal;
