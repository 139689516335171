import React, { useLayoutEffect } from 'react';
import { X } from 'react-feather';
import { useTransition, config } from 'react-spring';
import { useSelector } from 'react-redux';

import Scrollbar from '../Scrollbar/Scrollbar';
import {
  Container,
  CloseModalButton,
  Title,
  Content,
  TopBar,
  SubTitle,
  Header,
  Footer,
} from './styled';
import { useScrollBlock } from '@repo/shared/hooks';
import { accountSelectors } from '@store';

interface IProps {
  onModalClose: () => void;
  children: React.ReactNode | (() => React.ReactNode);
  opened: boolean;
  title?: React.ReactNode | string | null;
  subTitle?: React.ReactNode | string | null;
  headerAlign?: 'left' | 'center';
  topBarLeadingContent?: React.ReactNode;
  footer?: React.ReactNode;
  footerHeight?: number;
  footerPadding?: string | number;
  noScroll?: boolean;
  closeBtnE2eDataAttr?: string;
  zIndex?: number;
}

const SlideModal: React.FC<React.PropsWithChildren<IProps>> = ({
  onModalClose,
  children,
  opened,
  title,
  subTitle,
  topBarLeadingContent,
  headerAlign = 'left',
  footer,
  footerHeight = 80,
  footerPadding = '0 25px',
  noScroll,
  closeBtnE2eDataAttr,
  zIndex,
}) => {
  const { step: selfGuidedTourStep } = useSelector(
    accountSelectors.getSelfGuidedTour
  );
  const [blockScroll, allowScroll] = useScrollBlock('auto hidden');

  useLayoutEffect(() => {
    let intervalId: any;

    if (selfGuidedTourStep === null) {
      return;
    }

    if (opened) {
      intervalId = setInterval(() => {
        blockScroll();
      }, 200);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        allowScroll();
      }
    };
  }, [opened, selfGuidedTourStep]);

  const transition = useTransition(opened, null, {
    from: { right: -400 },
    enter: { right: -30 },
    leave: { right: -400 },
    config: config.stiff,
  });

  return (
    <>
      {transition.map(
        ({ item, props, key }) =>
          item && (
            <Container
              key={key}
              style={{
                ...props,
                paddingBottom: footer && footerHeight ? footerHeight : 0,
                zIndex: zIndex || 2000,
              }}
            >
              <Scrollbar autoHide={false} noScrollY={noScroll}>
                <Content>
                  <TopBar>
                    <div>{topBarLeadingContent}</div>
                    <CloseModalButton
                      type="button"
                      onClick={onModalClose}
                      data-e2e={closeBtnE2eDataAttr}
                    >
                      <X />
                    </CloseModalButton>
                  </TopBar>
                  <Header style={{ textAlign: headerAlign }}>
                    {title && <Title level={4}>{title}</Title>}
                    {subTitle && <SubTitle>{subTitle}</SubTitle>}
                  </Header>
                  {children}
                </Content>
              </Scrollbar>
              {footer && (
                <Footer
                  style={{ height: footerHeight, padding: footerPadding }}
                >
                  {footer}
                </Footer>
              )}
            </Container>
          )
      )}
    </>
  );
};

export default SlideModal;
