import React, { useState } from 'react';
import { FormInstance } from 'antd/es/form';
import Dropdown from 'antd/es/dropdown';

import { Container, Trigger, Overlay, Buttons } from './styled';
import { ColorButton } from '../../../tags/AddEditTagModal/styled';
import { tagColors } from '@repo/shared/config';

import Form from '../../../shared/ant/Form';

interface IProps {
  children: React.ReactNode;
  form: FormInstance;
  index: number;
  field: any;
}

const ColorPalette: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  form,
  index,
  field,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode as HTMLDivElement}
        trigger={['click']}
        open={open}
        onOpenChange={(update) => {
          setOpen(update);
        }}
        overlay={
          <Overlay>
            <Form.Item
              {...field}
              name={[field.name, 'color']}
              fieldKey={[field.fieldKey, 'color']}
            >
              <Buttons>
                {Object.values(tagColors).map((color) => (
                  <ColorButton
                    key={color}
                    value={color}
                    onClick={() => {
                      form.setFieldsValue({
                        labels: form
                          .getFieldValue('labels')
                          .map((label: any, i: number) => ({
                            ...label,
                            color: index === i ? color : label.color,
                          })),
                      });
                      setOpen(false);
                    }}
                  />
                ))}
              </Buttons>
            </Form.Item>
          </Overlay>
        }
      >
        <Trigger
          type="button"
          triggerColor={form.getFieldValue(['labels', index, 'color'])}
        />
      </Dropdown>
      {children}
    </Container>
  );
};

export default ColorPalette;
