import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Content = styled.div`
  max-width: ${({ maxWidth }: { maxWidth?: number }) => `${maxWidth || 200}px`};

  p {
    font-size: 12px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface ITriggerProps {
  circleBg: string;
  hoverCircleBg: string;
}

export const Trigger = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 21px;
  margin-left: 3px;
  cursor: pointer;
  margin-top: -1px;

  svg {
    width: 21px;
    height: 21px;
    transition: all 0.2s ease-in-out;
    fill: ${({ circleBg }: ITriggerProps) => circleBg};
    stroke: ${colors.white};
  }

  &:hover {
    svg {
      fill: ${({ hoverCircleBg }: ITriggerProps) => hoverCircleBg};
    }
  }
`;
