import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';
import AntSelect from 'antd/es/select';
import { v4 as uuid } from 'uuid';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { Footer, Divider } from './styled';
import { notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { AttributeFieldType } from '@repo/shared/enums';
import { IConcise } from '@repo/shared/types';

import SlideModal from '../../../../shared/SideModal/SideModal';
import Form from '../../../../shared/ant/Form';
import Button from '../../../../shared/ant/Button';
import { Input } from '@components/shared/ant/Input';
import Select from '@components/shared/ant/Select/Select';
import DropdownAttributeOptionList from '@components/auditObjects/attributes/list/AddEditAttributeModal/DropdownAttributeOptionList';
import { e2eTestElements } from '@config';

const { Option } = AntSelect;

const AddEditAttributeModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );
  const { show, attributeId } = useSelector(
    auditObjectsSelectors.attributes.getAddEditModalData
  );

  const isEdit = !!attributeId;
  const attribute = isEdit ? attributesMap[attributeId] : null;
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const closeModal = useCallback(
    () =>
      dispatch(
        auditObjectsActions.attributes.toggleAddEditModal({ show: false })
      ),
    []
  );

  useEffect(() => {
    if (selectedTableRows.length > 0) {
      closeModal();
    }
  }, [selectedTableRows.length]);

  useEffect(() => {
    if (show) {
      form.resetFields();
    }
  }, [show]);

  const itemName = formatMessage({ id: 'Attribute' }).toLowerCase();

  return (
    <SlideModal
      opened={show}
      onModalClose={() => closeModal()}
      closeBtnE2eDataAttr={e2eTestElements.objects.attributes.closeBtn}
      title={formatMessage({
        id: isEdit ? 'EditAttribute' : 'AddNewAttribute',
      })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
              data-e2e={e2eTestElements.objects.attributes.createBtn}
            >
              {formatMessage({ id: isEdit ? 'Save' : 'Add' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={e2eTestElements.objects.attributes.cancelBtn}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form
        form={form}
        onFinish={async ({
          name,
          fieldType,
          defaultOptionId,
          options,
        }: any) => {
          setLoading(true);

          const resultAction = await dispatch(
            auditObjectsActions.attributes.addEditAttribute({
              id: attribute?.id,
              name,
              fieldType,
              defaultOptionId,
              options,
            })
          );

          if (
            auditObjectsActions.attributes.addEditAttribute.fulfilled.match(
              resultAction
            )
          ) {
            dispatch(auditObjectsActions.attributes.getData());
            dispatch(generalActions.getConciseAuditObjectAttributes());
            closeModal();

            notification.success({
              message: isEdit
                ? formatMessage(
                    { id: 'YouHaveSuccessfullyEditedItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'YouHaveSuccessfullyCreatedItem' },
                    { item: itemName }
                  ),
            });
          } else {
            notification.error({
              message: isEdit
                ? formatMessage(
                    { id: 'ErrorWhileEditingItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'ErrorWhileCreatingItem' },
                    { item: itemName }
                  ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        layout="vertical"
        initialValues={{
          name: attribute?.name || '',
          fieldType: attribute?.fieldType || AttributeFieldType.Dropdown,
          defaultOptionId: attribute?.defaultOptionId || null,
          options: attribute?.options || [{ id: uuid(), name: '' }],
        }}
      >
        <Form.Item
          name="name"
          label={formatMessage({ id: 'Name' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <Input
            disabled={loading}
            data-e2e={e2eTestElements.objects.attributes.nameInput}
            placeholder={formatMessage({ id: 'AttributeName' })}
          />
        </Form.Item>
        <Form.Item
          name="fieldType"
          label={formatMessage({ id: 'FieldType' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <Select
            disabled={loading}
            e2eDataAttr={e2eTestElements.objects.attributes.fieldTypeSelect}
          >
            <Option value={AttributeFieldType.Dropdown}>
              {formatMessage({ id: 'Dropdown' })}
            </Option>
            {/*<Option value={AttributeFieldType.Number}>*/}
            {/*  {formatMessage({ id: 'Number' })}*/}
            {/*</Option>*/}
            {/*<Option value={AttributeFieldType.Text}>*/}
            {/*  {formatMessage({ id: 'Text' })}*/}
            {/*</Option>*/}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('fieldType') === AttributeFieldType.Dropdown ? (
              <>
                <DropdownAttributeOptionList loading={loading} />
                <Divider />
                <Form.Item
                  name="defaultOptionId"
                  label={formatMessage({ id: 'DefaultValueOptional' })}
                >
                  <Select
                    disabled={
                      loading ||
                      getFieldValue('options').every(
                        (option: IConcise) => option.name === ''
                      )
                    }
                    e2eDataAttr={
                      e2eTestElements.objects.attributes.defaultOptionSelect
                    }
                    allowClear
                    placeholder={formatMessage({ id: 'SelectValue' })}
                    options={getFieldValue('options').reduce(
                      (
                        acc: { value: string; label: string }[],
                        option: IConcise
                      ) => {
                        const a = acc;

                        if (option.name !== '') {
                          a.push({
                            value: option.id,
                            label: option.name,
                          });
                        }

                        return a;
                      },
                      []
                    )}
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                name="defaultValue"
                label={formatMessage({ id: 'DefaultValueOptional' })}
              >
                <Input
                  disabled={loading}
                  placeholder={formatMessage({ id: 'EnterValue' })}
                />
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
    </SlideModal>
  );
};

export default AddEditAttributeModal;
