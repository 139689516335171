import { createReducer, Reducer } from '@reduxjs/toolkit';

import { scoresActions as actions } from './scores.actions';
import { getInitialFilters } from '../../../utils/misc';
import { IScoreState } from '@types';
import { scoresAdapter } from '@store/entityAdapters';

export const scoresReducer: Reducer<IScoreState> = createReducer<IScoreState>(
  {
    entities: scoresAdapter.getInitialState(),
    loading: false,
    meta: null,
    error: null,
    filters: getInitialFilters(),
    concise: [],
  },
  (builder) =>
    builder
      .addCase(actions.setFilters, (state, { payload }) => {
        state.filters = {
          ...state.filters,
          ...payload,
        };
      })
      .addCase(actions.getScores.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.getScores.fulfilled, (state, { payload }) => {
        scoresAdapter.setAll(state.entities, payload.data);
        state.meta = payload.meta;
        state.loading = false;
      })
      .addCase(actions.getScores.rejected, (state) => {
        state.loading = false;
      })
      .addCase(actions.getScoresConcise.fulfilled, (state, { payload }) => {
        state.concise = payload;
      })
);
