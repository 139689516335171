import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypesActions } from '@application/IssueTypes/store';

import IssueTypeQrCodesHeader from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodesHeader/IssueTypeQrCodesHeader';
import DeleteIssueTypeQrCodesModal from '@src/presentation/IssueTypes/IssueTypeQrCodes/DeleteIssueTypeQrCodesModal';
import IssueTypeQrCodesActionsModal from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodesActionsModal';
import IssueTypeQrCodesTable from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodesTable/IssueTypeQrCodesTable';
import IssueTypeQrCodeModal from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodeModal/IssueTypeQrCodeModal';
import IssueTypeQrCodesFiltersModal from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodesFiltersModal/IssueTypeQrCodesFiltersModal';
import AddEditIssueTypeQrCodeModal from '@src/presentation/IssueTypes/IssueTypeQrCodes/AddEditIssueTypeQrCodeModal/AddEditIssueTypeQrCodeModal';

interface IProps {
  auditObjectId?: string;
  auditObjectGroupId?: string;
  issueTypeId?: string;
}

const IssueTypeQrCodes: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  auditObjectGroupId,
  issueTypeId,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!auditObjectId && !auditObjectGroupId && !issueTypeId) {
      dispatch(issueTypeQrCodesActions.getQrCodes());
    } else {
      dispatch(
        issueTypeQrCodesActions.getQrCodes({
          auditObjectsIds: auditObjectId ? [auditObjectId] : [],
          auditObjectGroupsIds: auditObjectGroupId ? [auditObjectGroupId] : [],
          issueTypeIds: issueTypeId ? [issueTypeId] : [],
        })
      );
    }

    dispatch(issueTypesActions.getConciseIssueTypes());
  }, [auditObjectId, issueTypeId]);

  return (
    <>
      {!auditObjectId && !auditObjectGroupId && !issueTypeId && (
        <IssueTypeQrCodesHeader />
      )}
      <IssueTypeQrCodesTable
        auditObjectId={auditObjectId}
        auditObjectGroupId={auditObjectGroupId}
        issueTypeId={issueTypeId}
      />
      <DeleteIssueTypeQrCodesModal />
      <IssueTypeQrCodesActionsModal />
      <IssueTypeQrCodeModal />
      <IssueTypeQrCodesFiltersModal
        auditObjectId={auditObjectId}
        auditObjectGroupId={auditObjectGroupId}
        issueTypeId={issueTypeId}
      />
      <AddEditIssueTypeQrCodeModal
        auditObjectId={auditObjectId}
        auditObjectGroupId={auditObjectGroupId}
        issueTypeId={issueTypeId}
      />
    </>
  );
};

export default IssueTypeQrCodes;
