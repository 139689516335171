import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  generalActions,
  generalSelectors,
  usersActions,
  usersSelectors,
} from '@store';
import { Footer } from './styled';
import { disallowURL, notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import SlideModal from '../../../shared/SideModal/SideModal';
import Form from '../../../shared/ant/Form';
import Button from '../../../shared/ant/Button';
import { Input } from '@components/shared/ant/Input';

const AddEditJobTitleModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const jobTitlesMap = useSelector(usersSelectors.jobTitles.getMap);
  const { show, jobTitleId } = useSelector(
    usersSelectors.jobTitles.getAddEditModalData
  );
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const isEdit = !!jobTitleId;
  const jobTitle = isEdit ? jobTitlesMap[jobTitleId] : null;

  const closeModal = useCallback(
    () => dispatch(usersActions.jobTitles.toggleAddEditModal({ show: false })),
    []
  );

  useEffect(() => {
    if (selectedTableRows.length > 0) {
      closeModal();
    }
  }, [selectedTableRows.length]);

  useEffect(() => {
    if (show) {
      form.resetFields();
    }
  }, [show]);

  const itemName = formatMessage({ id: 'JobTitle' }).toLowerCase();

  return (
    <SlideModal
      opened={show}
      onModalClose={() => closeModal()}
      title={formatMessage({
        id: isEdit ? 'EditJobTitle' : 'AddNewJobTitle',
      })}
      closeBtnE2eDataAttr={e2eTestElements.users.jobTitles.closeAddEditModal}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
              data-e2e={e2eTestElements.users.jobTitles.addBtn}
            >
              {formatMessage({ id: isEdit ? 'Save' : 'Add' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={e2eTestElements.users.jobTitles.cancelBtn}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form
        form={form}
        onFinish={async ({ name, description }: any) => {
          setLoading(true);

          const resultAction = await dispatch(
            usersActions.jobTitles.addEditJobTitle({
              id: jobTitle?.id,
              name,
              description,
            })
          );

          if (
            usersActions.jobTitles.addEditJobTitle.fulfilled.match(resultAction)
          ) {
            dispatch(usersActions.jobTitles.getData());
            dispatch(generalActions.getConciseJobTitles());
            closeModal();

            notification.success({
              message: isEdit
                ? formatMessage(
                    { id: 'YouHaveSuccessfullyEditedItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'YouHaveSuccessfullyCreatedItem' },
                    { item: itemName }
                  ),
            });
          } else {
            notification.error({
              message: isEdit
                ? formatMessage(
                    { id: 'ErrorWhileEditingItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'ErrorWhileCreatingItem' },
                    { item: itemName }
                  ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        layout="vertical"
        initialValues={{
          name: jobTitle?.name || '',
          description: jobTitle?.description || '',
        }}
      >
        <Form.Item
          name="name"
          label={formatMessage({ id: 'Name' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
            {
              max: 500,
              message: formatMessage(
                {
                  id: 'NameMustNotExceedXCharacters',
                },
                { count: 500 }
              ),
            },
            disallowURL(),
          ]}
        >
          <Input
            disabled={loading}
            maxLength={500}
            data-e2e={e2eTestElements.users.jobTitles.jobTitleNameInput}
            placeholder={formatMessage({ id: 'EnterName' })}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={formatMessage({ id: 'Description' })}
          rules={[
            {
              max: 800,
              message: formatMessage(
                {
                  id: 'NameMustNotExceedXCharacters',
                },
                { count: 800 }
              ),
            },
            disallowURL(),
          ]}
        >
          <Input.TextArea
            rows={3}
            data-e2e={e2eTestElements.users.jobTitles.jobTitleDescInput}
            disabled={loading}
            placeholder={formatMessage({ id: 'EnterDescription' })}
          />
        </Form.Item>
      </Form>
    </SlideModal>
  );
};

export default AddEditJobTitleModal;
