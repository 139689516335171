export const account = {
  welcomeModal: {
    closeButton: 'account-modal-close-button',
    startSampleAuditButton: 'account-modal-start-sample-audit-button',
    scheduleLiveConsultButton:
      'account-modal-schedule-live-consultation-button',
    doSelfGuidedTourButton: 'account-modal-do-self-guided-tour-button',
  },
  signInForm: {
    emailInput: 'sign-in-email-input',
    passwordInput: 'sign-in-password-input',
    submitBtn: 'sign-in-submit-btn',
  },
  signUpForm: {
    emailInput: 'sign-up-email-input',
    passwordInput: 'sign-up-password-input',
    submitButton: 'sign-in-submit-btn',
  },
  companyForm: {
    adminFullName: 'company-form-admin-full-name',
    adminPhone: 'company-form-admin-phone',
    nameInput: 'company-form-name-input',
    companySizeSelect: 'company-form-company-size-select',
    tzSelect: 'company-form-tz-select',
    industrySelect: 'company-form-industry-select',
    usagePurposeSelect: 'company-form-usage-purpose-select',
    usagePurposeInput: 'company-form-usage-purpose-input',
    submitBtn: 'company-form-submit-button',
  },
  changePassword: {
    passwordInput: 'change-password-input',
    passwordConfirmInput: 'change-password-confirm-input',
    saveBtn: 'change-password-save-btn',
    cancelBtn: 'change-password-cancel-btn',
  },
  selfGuidedTour: {
    beginTourBtn: 'self-guided-tour-begin-tour-btn',
    cancelTourBtn: 'self-guided-tour-cancel-tour-btn',
    nextStepBtn: 'self-guided-tour-next-step-btn',
    stepTitle: 'self-guided-tour-step-title',
    stepDesc: 'self-guided-tour-step-desc',
    endTourBtn: 'self-guided-tour-end-tour-btn',
    scheduleLiveConsultation: 'self-guided-tour-schedule-live-consultation',
    contactSupport: 'self-guided-tour-contact-support',
  },
  selectAccount: {
    expandable: 'select-account-expandable',
    account: 'select-account-account',
    manageAccountsBtn: 'select-account-manage-accounts-btn',
  },
};
