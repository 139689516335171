import React, { lazy, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Modal from 'antd/es/modal';

import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { useAppDispatch } from '@hooks';
import { Content } from './styled';
import { TrendsType } from '@repo/shared/enums';
import { routes } from '@config';

import Loader from '@repo/shared/components/Loader';

const Trends = lazy(() => import('@repo/trends'));

const TrendsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const modalState = useSelector(auditsSelectors.getTrendsModalState);

  const title = useMemo(() => {
    switch (modalState?.type) {
      case TrendsType.Audit:
        return formatMessage({ id: 'AuditAnalysis' });
      case TrendsType.Section:
        return formatMessage({ id: 'SectionAnalysis' });
      case TrendsType.Item:
        return formatMessage({ id: 'ItemAnalysis' });
      default:
        return '';
    }
  }, [!!modalState]);

  return (
    <Modal
      title={title}
      open={modalState !== null}
      onCancel={() => {
        dispatch(auditsActions.toggleTrendsModal(null));
      }}
      width={800}
      destroyOnClose
      className="trends-modal"
      footer={null}
      styles={{
        content: {
          padding: 0,
        },
        header: {
          padding: '15px 25px 8px',
          margin: 0,
        },
        body: {
          margin: 0,
        },
      }}
    >
      <Content>
        {modalState && (
          <Suspense fallback={<Loader />}>
            {modalState.type === TrendsType.Audit ? (
              <Trends
                {...modalState}
                onReportBtnClick={{
                  callback: (auditId) => {
                    if (window) {
                      window
                        .open(
                          routes.auditReport.replace(':id', auditId),
                          '_blank'
                        )
                        ?.focus();
                    }
                  },
                  externalLink: true,
                }}
              />
            ) : (
              <Trends {...modalState} />
            )}
          </Suspense>
        )}
      </Content>
    </Modal>
  );
};

export default TrendsModal;
