import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import { usersActions, usersSelectors, generalSelectors } from '@store';
import { Footer } from './styled';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import SlideModal from '../../../../../shared/SideModal/SideModal';
import Form from '../../../../../shared/ant/Form';
import Button from '../../../../../shared/ant/Button';
import UsersSelect from '@components/shared/EntitySelects/UsersSelect';
import UserGroupsSelect from '@components/shared/EntitySelects/UserGroupsSelect';

interface IProps {
  userId?: string;
  userGroupId?: string;
}

const AddToGroupModal: React.FC<React.PropsWithChildren<IProps>> = ({
  userId,
  userGroupId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const visible = useSelector(
    usersSelectors.groupAssignments.isAddToGroupModalVisible
  );
  const usersMap = useSelector(generalSelectors.getConciseUsersMap);
  const userGroupsMap = useSelector(generalSelectors.getConciseUserGroupsMap);
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const closeModal = useCallback(
    () => dispatch(usersActions.groupAssignments.toggleAddToGroupModal(false)),
    []
  );

  useEffect(() => {
    if (selectedTableRows.length > 0) {
      closeModal();
    }
  }, [selectedTableRows.length]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  return (
    <SlideModal
      opened={visible}
      onModalClose={() => {
        closeModal();
      }}
      title={formatMessage({
        id: 'AddToGroups',
      })}
      closeBtnE2eDataAttr={
        e2eTestElements.users.groupsAssignments.closeAddToGroupModal
      }
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
              data-e2e={e2eTestElements.users.groupsAssignments.addToGroupBtn}
            >
              {formatMessage({ id: 'Add' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={e2eTestElements.users.groupsAssignments.cancelBtn}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form
        form={form}
        onFinish={async (values: any) => {
          setLoading(true);

          const usersIds = userId ? [userId] : values.usersIds;
          const groupsIds = userGroupId ? [userGroupId] : values.groupsIds;

          const resultAction = await dispatch(
            usersActions.groupAssignments.createAssignment({
              usersIds,
              groupsIds,
            })
          );

          const translationValues = {
            objectName:
              usersIds.length === 1 && usersMap[usersIds[0]]
                ? usersMap[usersIds[0]]?.name
                : formatMessage({ id: 'XUsers' }, { count: usersIds.length }),
            groupName:
              groupsIds.length === 1 && userGroupsMap[groupsIds[0]]
                ? `${userGroupsMap[groupsIds[0]]?.name} ${formatMessage({
                    id: 'Group',
                  }).toLocaleLowerCase()}`
                : `${groupsIds.length} ${formatMessage({
                    id: 'Groups',
                  }).toLocaleLowerCase()}`,
          };

          if (
            usersActions.groupAssignments.createAssignment.fulfilled.match(
              resultAction
            )
          ) {
            dispatch(usersActions.groupAssignments.getData());
            closeModal();

            notification.success({
              message: formatMessage(
                { id: 'YouHaveSuccessfullyAddedToGroup' },
                translationValues
              ),
            });
          } else {
            notification.error({
              message: formatMessage(
                { id: 'ErrorWhileAddingObjectsToGroup' },
                translationValues
              ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        layout="vertical"
        initialValues={{
          groupsIds: [],
          usersIds: [],
        }}
      >
        {!userGroupId && (
          <Form.Item
            name="groupsIds"
            label={formatMessage({ id: 'UserGroups' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <UserGroupsSelect
              disabled={loading}
              placeholder={formatMessage({ id: 'SelectUserGroups' })}
              e2eDataAttr={
                e2eTestElements.users.groupsAssignments.selectGroupDropdown
              }
              mode="multiple"
            />
          </Form.Item>
        )}
        {!userId && (
          <Form.Item
            name="usersIds"
            label={formatMessage({ id: 'Users' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <UsersSelect
              disabled={loading}
              mode="multiple"
              placeholder={formatMessage({ id: 'SelectUsers' })}
              e2eDataAttr={
                e2eTestElements.users.groupsAssignments.selectUsersDropdown
              }
            />
          </Form.Item>
        )}
      </Form>
    </SlideModal>
  );
};

export default AddToGroupModal;
