import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Modal } from '@repo/shared/enums';
import { generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';

import SlideModal from '../SideModal/SideModal';

interface IProps {
  children: ({
    isGroupActions,
    closeModal,
  }: {
    isGroupActions: boolean;
    closeModal: () => void;
  }) => React.ReactNode;
}

const ActionsModal: React.FC<IProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const addEditModalData = useSelector(
    generalSelectors.getModal(Modal.AddEdit)
  );

  useEffect(() => {
    if (selectedTableRowKeys.length > 0 && addEditModalData.opened) {
      dispatch(generalActions.hideModal(Modal.AddEdit));
    }
  }, [selectedTableRowKeys.length, addEditModalData.opened, dispatch]);

  function closeModal() {
    dispatch(generalActions.selectTableRows([]));
  }

  const isGroupActions = selectedTableRowKeys.length > 1;

  return (
    <SlideModal
      opened={selectedTableRowKeys.length > 0}
      onModalClose={() => closeModal()}
      title={
        isGroupActions
          ? formatMessage({ id: 'GroupActions' })
          : formatMessage({ id: 'Actions' })
      }
    >
      {children({ isGroupActions, closeModal })}
    </SlideModal>
  );
};

export default ActionsModal;
