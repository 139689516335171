import React, { useEffect } from 'react';

import { tagsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { TagsPage } from '@types';

import TagsHeader from './TagsHeader/TagsHeader';
import TagsTable from './TagsTable/TagsTable';
import TagActionsModal from './TagActionsModal';
import AddEditTagModal from './AddEditTagModal/AddEditTagModal';
import DeleteTagModal from './DeleteTagModal/DeleteTagModal';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';

interface IProps {
  page: TagsPage;
}

const Tags: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tagsActions.setPage(page));
    dispatch(tagsActions.getTags({ tagType: page }));
  }, [dispatch, page]);

  return (
    <>
      <SampleDataAlert />
      <TagsHeader tagType={page} />
      <TagsTable />
      <TagActionsModal />
      <AddEditTagModal />
      <DeleteTagModal />
    </>
  );
};

export default Tags;
