import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import externalIcon from './external.svg';

export const Container = styled.button`
  display: flex;
  width: 100%;
  background: none;
  border: none;
  padding: 0 20px;
  align-items: center;
  height: 55px;
  text-align: left;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #fafafa;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 24px;
  height: 24px;
`;

export const Text = styled.div`
  flex-grow: 1;
  line-height: 1.4;
`;

export const Name = styled.div`
  font-size: 15px;
`;

export const Description = styled.div`
  font-size: 13px;
  color: ${colors.gray8};
`;

export const ExternalIcon = styled.div`
  margin-left: 20px;
  width: 14px;
  height: 14px;
  background: url(${externalIcon}) no-repeat center center;
`;
