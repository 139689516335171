import {
  IAuditObject,
  IConcise,
  IGroup,
  ZonedDateTime,
} from '@repo/shared/types';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';
import { ExpirationType } from '@domain/Schedules/enums/ExpirationType';
import { DayOfWeek } from '@repo/shared/enums';
import { AuditorAssignmentType } from '@domain/Schedules/enums/AuditorAssignmentType';

interface Expiration {
  auditsExpirationType: ExpirationType;
  auditsExpireAfterDays: number | null;
  actionsExpirationType: ExpirationType;
  actionsExpireAfterDays: number | null;
}

export type ScheduleRepeat =
  | ({
      repeatPattern: AuditRepeatPattern.OneTime;
      startDate: string;
      endDate: string;
    } & Expiration)
  | ({
      repeatPattern: AuditRepeatPattern.Daily;
      repeatEvery: number;
    } & Expiration)
  | ({
      repeatPattern: AuditRepeatPattern.Weekly;
      daysOfWeek: number[];
    } & Expiration)
  | ({
      repeatPattern: AuditRepeatPattern.MultipleWeeks;
      repeatEvery: number;
      startDay: DayOfWeek;
      duration: number;
    } & Expiration)
  | ({
      repeatPattern: AuditRepeatPattern.Monthly;
      repeatEvery: number;
      startDay: number;
      duration: number;
    } & Expiration);

export enum AuditsGenerationMode {
  AuditPerLocation,
  AuditPerAuditor,
}

export interface Schedule {
  id: string;
  name: string;
  auditObjects: IAuditObject[];
  auditObjectGroups: IGroup[];
  template: IConcise;
  auditRepeatPattern: AuditRepeatPattern;
  repeat: ScheduleRepeat;
  dueDate: ZonedDateTime;
  isSample: boolean;
  auditorHint: string | null;
  auditsGenerationMode: AuditsGenerationMode | null;
  auditorAssignmentType: AuditorAssignmentType;
  assignedUserGroups: IConcise[];
  assignedUsers: IConcise[];
  maxAuditsQty: string | null;
  active: boolean;
  stopByDate: string | null;
  startFromDate: string | null;
  nextDueDate: ZonedDateTime | null;
  nextStartDate: ZonedDateTime | null;
}
