import styled from 'styled-components';
import { Paperclip } from 'react-feather';

import AutoTextArea from '@components/shared/AutoTextArea';
import { colors } from '@repo/shared/config';

export const Form = styled.form`
  display: flex;
  align-items: flex-end;
  border-top: 1px solid ${colors.gray5};
  padding: 10px 24px 10px 15px;
`;

export const Content = styled.div`
  flex-grow: 1;
  max-height: 350px;
  overflow-y: auto;
`;

export const TextField = styled(AutoTextArea)`
  padding: 6px 12px 6px 0;
  background: none;
  width: 100%;
  resize: none;
  border: 0;

  &:focus {
    outline: none;
  }
`;

export const SendCommentBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: ${colors.blue2};
  width: 44px;
  height: 44px;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.white};
  }

  &:disabled {
    background: ${colors.gray7};
  }
`;

export const Previews = styled.div`
  display: flex;
  align-items: center;
  margin: 7px -4px 0 7px;
`;

export const UploadFileIcon = styled(Paperclip)`
  display: block;
  margin: 0 13px 8px 0;
  width: 28px;
  height: 28px;
  color: ${colors.blue2};
`;
