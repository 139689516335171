import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { scoresAdapter } from '@store/entityAdapters';

export const scoresSelectors = {
  getScores: createSelector(
    [
      (state: IRootState) => state.score.loading,
      (state: IRootState) => state.score.meta,
      (state: IRootState) => state.score.error,
      (state: IRootState) => state.score.entities,
    ],
    (loading, meta, error, entities) => ({
      loading,
      error,
      meta,
      score: scoresAdapter.getSelectors().selectAll(entities),
    })
  ),
  getScoresLookup: (state: IRootState) =>
    scoresAdapter.getSelectors().selectEntities(state.score.entities),
  getFilters: (state: IRootState) => state.score.filters,
  getScoresConcise: (state: IRootState) => state.score.concise,
};
