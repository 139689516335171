import { createReducer } from '@reduxjs/toolkit';
import { PerformAuditState } from '@application/PerformAudit/models/PerformAuditState';
import { performAuditActions as actions } from '@application/PerformAudit/store/performAuditActions';
import { dateUTC, getAncestorsSections } from '@utils';
import { ItemType, SignatureBy } from '@repo/shared/enums';

const initialFilters = {
  onlyFailedItems: false,
  onlyCriticalItems: false,
  onlyRepeats: false,
  onlyNotApplicable: false,
  onlyItemsWithNotes: false,
  onlyItemsWithPhotos: false,
  onlySignedItems: false,
  onlyActionsWithStatus: [],
  onlyItemsWithFlags: [],
  onlyUnansweredItems: false,
};

const initialState: PerformAuditState = {
  loading: false,
  error: null,
  visible: false,
  audit: null,
  items: null,
  rootItemId: null,
  openedSectionId: null,
  showFiltersModal: false,
  filters: {
    search: '',
    ...initialFilters,
  },
  noteModal: {
    show: false,
    note: null,
    itemId: null,
  },
  flagsModal: {
    show: false,
    itemId: null,
  },
  actionModal: {
    show: false,
    actionId: null,
    itemId: null,
    actionTemplateId: null,
  },
  signatureModal: {
    show: false,
    ref: null,
    itemId: null,
    signer: null,
  },
  signAudit: {
    auditorSignature: null,
    auditeeSignature: null,
  },
  showLoadingPhotosModal: false,
  naCheckConfirmModal: {
    show: false,
    itemId: null,
  },
  auditScoreSystem: undefined,
  auditErrorCode: null,
  sectionMarkedAsNABy: null,
  pubnubAuthToken: null,
  itemsSavingToStreamIds: [],
  receivingUpdatedItems: false,
  lastItemSyncTimestamp: null,
  uploadQueue: [],
  uploadInProgress: false,
  isUpdatedItemsFetchRequired: false,
  selectActionTemplateModalItemId: null,
  notUploadedFiles: [],
  showSyncModal: false,
  deleteNotUploadedFileModal: { opened: false },
};

export const performAuditReducer = createReducer<PerformAuditState>(
  initialState,
  (builder) =>
    builder
      .addCase(actions.openModal.pending, (state) => {
        state.loading = true;
        state.visible = true;
      })
      .addCase(actions.openModal.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.rootItemId = payload.rootItemId;
        state.audit = payload.auditDetails;
        state.auditScoreSystem = payload.auditScoreSystem
          ? {
              ...payload.auditScoreSystem,
              labels: [...payload.auditScoreSystem.labels].sort(
                (a, b) => a.max - b.max
              ),
            }
          : undefined;
        state.items = payload.items;
      })
      .addCase(actions.openModal.rejected, (state, { payload }) => {
        state.error = payload || null;
        state.loading = false;
        state.visible = false;
      })
      .addCase(actions.getAllItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        actions.getAllItems.fulfilled,
        (state, { payload: { rootItemId, items } }) => {
          state.loading = false;
          state.rootItemId = rootItemId;
          state.items = items;
        }
      )
      .addCase(actions.getAllItems.rejected, (state, { payload }) => {
        state.error = payload || null;
        state.loading = false;
      })
      .addCase(actions.openSection, (state, { payload }) => {
        state.openedSectionId = payload;
      })
      .addCase(actions.openSectionList, (state) => {
        state.openedSectionId = null;
      })
      .addCase(actions.closeModal.fulfilled, () => {
        return {
          ...initialState,
        };
      })
      .addCase(
        actions.updateAuditItem.fulfilled,
        (state, { payload: items }) => {
          for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (state.items?.[item.id]) {
              state.items[item.id] = {
                ...state.items[item.id],
                ...item,
              };

              if (!state.uploadQueue.includes(item.id)) {
                state.uploadQueue.push(item.id);
              }
            }
          }
        }
      )
      .addCase(actions.uploadUpdatedItems.pending, (state) => {
        state.uploadQueue = [];
        state.uploadInProgress = true;
      })
      .addCase(actions.uploadUpdatedItems.fulfilled, (state, { payload }) => {
        for (let i = 0; i < payload.length; i++) {
          const { id, streamRecordId } = payload[i];

          if (state.items?.[id]) {
            state.items[id].streamRecordId = streamRecordId;
          }
        }

        state.uploadInProgress = false;
      })
      .addCase(actions.uploadUpdatedItems.rejected, (state, { payload }) => {
        if (Array.isArray(payload)) {
          for (let i = 0; i < payload.length; i++) {
            if (!state.uploadQueue.includes(payload[i])) {
              state.uploadQueue.push(payload[i]);
            }
          }
        }

        state.uploadInProgress = false;
      })
      .addCase(
        actions.updateAuditItemsInStore,
        (state, { payload: updates }) => {
          for (let i = 0; i < updates.length; i++) {
            if (state.items?.[updates[i].id]) {
              state.items[updates[i].id] = {
                ...state.items?.[updates[i].id],
                ...updates[i],
              };
            }
          }
        }
      )
      .addCase(actions.setIsUpdatedItemsFetchRequired, (state, { payload }) => {
        state.isUpdatedItemsFetchRequired = payload;
      })
      .addCase(actions.getUpdatedAuditItems.pending, (state) => {
        state.receivingUpdatedItems = true;
      })
      .addCase(
        actions.getUpdatedAuditItems.fulfilled,
        (state, { payload: updatedItems }) => {
          const updatesSortedByTime = updatedItems.sort((a, b) =>
            dateUTC(a.updatedAtUtc).isAfter(b.updatedAtUtc) ? -1 : 1
          );

          for (let i = 0; i < updatesSortedByTime.length; i++) {
            const item = updatesSortedByTime[i];

            if (
              item.itemType === ItemType.Section &&
              item.notApplicable &&
              item.id === state.openedSectionId &&
              item.updatedBy?.name
            ) {
              state.sectionMarkedAsNABy = item.updatedBy.name;
            }

            if (i === 0 && state.rootItemId && state.items) {
              const { section } = getAncestorsSections(
                state.items,
                item.id,
                state.rootItemId
              );

              if (section) {
                state.items[section.id].updatedBy = item.updatedBy;
                state.items[section.id].updatedAtUtc = item.updatedAtUtc;
              }
            }

            if (state.items?.[item.id]) {
              state.items[item.id] = {
                ...state.items[item.id],
                ...item,
              };
            }
          }

          state.receivingUpdatedItems = false;
        }
      )
      .addCase(actions.getUpdatedAuditItems.rejected, (state) => {
        state.receivingUpdatedItems = false;
      })
      .addCase(
        actions.showNoteModal,
        (state, { payload: { itemId, note } }) => {
          state.noteModal = {
            show: true,
            itemId,
            note: note || null,
          };
        }
      )
      .addCase(actions.hideNoteModal, (state) => {
        state.noteModal = {
          show: false,
          itemId: null,
          note: null,
        };
      })
      .addCase(actions.showFlagsModal, (state, { payload: { itemId } }) => {
        state.flagsModal = {
          show: true,
          itemId,
        };
      })
      .addCase(actions.hideFlagsModal, (state) => {
        state.flagsModal = {
          show: false,
          itemId: null,
        };
      })
      .addCase(
        actions.showActionModal,
        (state, { payload: { itemId, actionId, actionTemplateId } }) => {
          state.actionModal = {
            show: true,
            itemId,
            actionId: actionId || null,
            actionTemplateId: actionTemplateId || null,
          };
        }
      )
      .addCase(actions.hideActionModal, (state) => {
        state.actionModal = {
          show: false,
          itemId: null,
          actionId: null,
          actionTemplateId: null,
        };
      })
      .addCase(
        actions.showSignatureModal,
        (state, { payload: { itemId, signer } }) => {
          state.signatureModal = {
            show: true,
            itemId,
            signer,
          };
        }
      )
      .addCase(actions.hideSignatureModal, (state) => {
        state.signatureModal = {
          show: false,
          itemId: null,
          signer: null,
        };
      })
      .addCase(actions.toggleFiltersModal, (state, { payload }) => {
        state.showFiltersModal = payload;
      })
      .addCase(actions.updateFilters, (state, { payload }) => {
        state.filters = {
          ...state.filters,
          ...payload,
        };
      })
      .addCase(actions.resetFilters, (state) => {
        state.filters = {
          ...state.filters,
          ...initialFilters,
        };
      })
      .addCase(
        actions.addAuditSignature,
        (state, { payload: { signatureBy, signature } }) => {
          state.signAudit[
            signatureBy === SignatureBy.Auditee
              ? 'auditeeSignature'
              : 'auditorSignature'
          ] = signature;
        }
      )
      .addCase(actions.deleteAuditSignature, (state, { payload }) => {
        state.signAudit[
          payload === SignatureBy.Auditee
            ? 'auditeeSignature'
            : 'auditorSignature'
        ] = null;
      })
      .addCase(actions.toggleLoadingPhotosModal, (state, { payload }) => {
        state.showLoadingPhotosModal = payload;
      })
      .addCase(actions.showNACheckConfirmModal, (state, { payload }) => {
        state.naCheckConfirmModal = {
          show: true,
          itemId: payload,
        };
      })
      .addCase(actions.hideNACheckConfirmModal, (state) => {
        state.naCheckConfirmModal = {
          show: false,
          itemId: null,
        };
      })
      .addCase(actions.setAuditErrorCode, (state, { payload }) => {
        state.auditErrorCode = payload;
      })
      .addCase(actions.setSectionMarkedAsNABy, (state, { payload }) => {
        state.sectionMarkedAsNABy = payload;
      })
      .addCase(actions.setPubnubAuthToken, (state, { payload }) => {
        state.pubnubAuthToken = payload;
      })
      .addCase(
        actions.toggleSelectActionTemplateModal,
        (state, { payload }) => {
          state.selectActionTemplateModalItemId = payload;
        }
      )
      .addCase(actions.updateAuditInStore, (state, { payload }) => {
        if (state.audit) {
          state.audit = {
            ...state.audit,
            ...payload,
          };
        }
      })
      .addCase(actions.getNotUploadedFiles.fulfilled, (state, { payload }) => {
        state.notUploadedFiles = payload;
      })
      .addCase(actions.toggleSyncModal, (state, { payload }) => {
        state.showSyncModal = payload;
      })
      .addCase(
        actions.toggleDeleteNotUploadedFileModal,
        (state, { payload }) => {
          state.deleteNotUploadedFileModal = payload;
        }
      )
);
