import React from 'react';

import {
  ItemContainer,
  Content,
  Label,
} from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/styled';

interface IProps {
  label: string;
  content: React.ReactNode;
}

const ActionDetailsInfoItem: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  content,
}) => {
  return (
    <ItemContainer>
      <Label>{label}</Label>
      <Content>{content}</Content>
    </ItemContainer>
  );
};

export default ActionDetailsInfoItem;
