import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { useSelector } from 'react-redux';

import { Container } from './styled';
import { dashboardActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';

import Welcome from './Welcome/Welcome';
import AuditTemplate from '../../reports/components/AuditTemplate';
import AuditObject from '../../reports/components/AuditObject';

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const filters = useSelector(generalSelectors.getSharedFilters);

  return (
    <Container>
      <Row align="middle" gutter={[14, 0]}>
        <Col span="16">
          <Welcome />
        </Col>
        <Col span="8">
          <Row align="bottom" gutter={[14, 0]}>
            <Col span="12">
              <AuditTemplate
                selectedValue={filters.templateIds[0]}
                onChange={(templateId) => {
                  dispatch(
                    dashboardActions.getDashboard({
                      templateIds: templateId ? [templateId] : [],
                    })
                  );
                }}
              />
            </Col>
            <Col span="12">
              <AuditObject
                selectedValue={{
                  auditObjectIds: filters.auditObjectIds,
                  auditObjectGroupIds: filters.auditObjectGroupIds,
                }}
                onChange={({ auditObjectIds, auditObjectGroupIds }) => {
                  dispatch(
                    dashboardActions.getDashboard({
                      auditObjectIds,
                      auditObjectGroupIds,
                    })
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
