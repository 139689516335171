import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountSelectors, dashboardActions, dashboardSelectors } from '@store';
import { ButtonLink, Mark } from '@presentation/Audits/AuditsTable/styled';
import {
  Container,
  Header,
  ChangeRangeBtn,
  Buttons,
  ShowAllAuditsLink,
  TableContainer,
  Placeholder,
} from './styled';
import placeholderImage from './placeholder.svg';
import { routes } from '@config';
import { colors } from '@repo/shared/config';
import { AuditDueDateStatus } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { Audit } from '@domain/Audits/models/Audit';

import Table from '../../shared/ant/Table/Table';
import AuditStatusName from '@presentation/Audits/AuditStatusName';
import ConciseListStr from '@components/shared/ConciseListStr/ConciseListStr';
import TableButtonLink from '@components/shared/ant/Table/TableButtonLink';
import ZonedDateTime from '@components/shared/ZonedDateTime';

const AuditsList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, data, error, filters } = useSelector(
    dashboardSelectors.getAudits,
    shallowEqual
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  return (
    <Container>
      <Header>
        <Buttons>
          <ChangeRangeBtn
            active={filters.dueDateStatus === AuditDueDateStatus.DueSoon}
            activeBorderColor={colors.yellow}
            type="button"
            onClick={() =>
              dispatch(
                dashboardActions.getAudits({
                  dueDateStatus: AuditDueDateStatus.DueSoon,
                })
              )
            }
          >
            {formatMessage({ id: 'AuditsDueIn7Days' })}
          </ChangeRangeBtn>
          <ChangeRangeBtn
            active={filters.dueDateStatus === AuditDueDateStatus.PastDue}
            activeBorderColor={colors.red}
            type="button"
            onClick={() =>
              dispatch(
                dashboardActions.getAudits({
                  dueDateStatus: AuditDueDateStatus.PastDue,
                })
              )
            }
          >
            {formatMessage({ id: 'PastDue' })}
          </ChangeRangeBtn>
        </Buttons>
        <ShowAllAuditsLink to={routes.pendingAudits}>
          {formatMessage({ id: 'ShowAll' })}
        </ShowAllAuditsLink>
      </Header>
      <TableContainer>
        <Table<Audit>
          canSelectRow={false}
          scroll={{ y: 412 }}
          loading={loading}
          meta={{ pageSize: 1000, pageNumber: 1, totalCount: 1000 }}
          error={error}
          columns={[
            {
              title: formatMessage({ id: 'Name' }),
              dataIndex: 'auditSchedule',
              key: 'auditSchedule',
              render(_, audit) {
                return (
                  <ButtonLink>
                    <TableButtonLink
                      text={audit.name}
                      onClick={() => {
                        let url = window.origin;

                        if (audit.expiredAtInformation) {
                          url += routes.expiredAudit;
                        } else if (audit.completedAtInformation) {
                          url += routes.completedAudit;
                        } else {
                          url += routes.pendingAudit;
                        }

                        window.open(url.replace(':id', audit.id), '_blank');
                      }}
                    />
                    {audit.isRecurring && (
                      <Mark>{formatMessage({ id: 'RecurringAudit' })}</Mark>
                    )}
                  </ButtonLink>
                );
              },
            },
            {
              title: auditObjectName.single,
              dataIndex: 'auditObjectName',
              key: 'auditObjectName',
              render(_, audit) {
                return audit.auditObject.name;
              },
            },
            {
              title: formatMessage({ id: 'Status' }),
              dataIndex: 'status',
              key: 'status',
              render(_, audit) {
                return <AuditStatusName audit={audit} />;
              },
            },
            {
              title: formatMessage({ id: 'DueDate' }),
              dataIndex: 'endDate',
              key: 'endDate',
              render(_, audit) {
                return <ZonedDateTime dateTime={audit.endDateInformation} />;
              },
            },
            {
              title: formatMessage({ id: 'AssignedTo' }),
              dataIndex: 'assignees',
              key: 'assignees',
              render(_, audit) {
                return <ConciseListStr items={audit.assignees} />;
              },
            },
          ]}
          dataSource={data.map((audit: Audit) => ({
            ...audit,
            key: audit.id,
          }))}
          locale={{
            emptyText: <Placeholder src={placeholderImage} />,
          }}
        />
      </TableContainer>
    </Container>
  );
};

export default AuditsList;
