import { ITagConcise, ITemplateItem } from '@repo/shared/types';
import { UpdateAuditTemplateItemDto } from '@infrastructure/AuditTemplateBuilder/models/UpdateAuditTemplateItemDto';
import { ActionType } from '@repo/shared/enums';

export function transformTemplateItemsToDto(
  items: Record<string, ITemplateItem>
) {
  return Object.values(items).reduce<
    Record<string, UpdateAuditTemplateItemDto>
  >((acc, { actionTemplates, sectionAuditObjectsTags, itemTags, ...item }) => {
    acc[item.id] = {
      ...item,
      actionTemplatesIds: actionTemplates.map((tag) => tag.id),
      sectionAuditObjectsTagsIds: (sectionAuditObjectsTags || []).map(
        (tag) => tag.id
      ),
      itemTagsIds: (itemTags || []).map((tag: ITagConcise) => tag.id),
      conditions: (item.conditions || []).filter(
        ({ actionType }) => actionType !== ActionType.AddItems
      ),
    };
    return acc;
  }, {});
}
