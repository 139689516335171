import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  generalSelectors,
  generalActions,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '../../../shared/ant/Modal/Modal';
import { routes } from '@config';
const ConfirmDeleteAttributesModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const attributesIds = useSelector(
    auditObjectsSelectors.attributes.getAttributesIdsDeleteCandidates
  );
  const attributesMap = useSelector(auditObjectsSelectors.attributes.getMap);

  const attribute =
    attributesIds.length === 1 ? attributesMap[attributesIds[0]] : null;

  const closeModal = useCallback(
    () => dispatch(auditObjectsActions.attributes.toggleConfirmDeleteModal([])),
    []
  );

  return (
    <Modal
      title={formatMessage(
        { id: 'RemoveAttributes' },
        { itemCount: attributesIds.length }
      )}
      open={attributesIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          auditObjectsActions.attributes.deleteAttributes(attributesIds)
        );

        if (
          auditObjectsActions.attributes.deleteAttributes.fulfilled.match(
            resultAction
          )
        ) {
          dispatch(auditObjectsActions.attributes.getData());

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();

          navigate(routes.auditObjects.attributesList);
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeletingAttributes' }),
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveAttributes' },
        { itemCount: 1 }
      ).replace('%replaceValue%', attribute?.name || '')}
    </Modal>
  );
};

export default ConfirmDeleteAttributesModal;
