import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Check } from 'react-feather';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';

import {
  ActionContainer,
  Title,
  Content,
  FooterItem,
  Footer,
  Text,
  FooterItemDivider,
} from './styled';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { ActionStatus } from '@repo/shared/enums';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { useAppDispatch } from '@hooks';

import ActionStatusName from '@presentation/Actions/ActionStatusName';
import PriorityLabel from '@components/shared/PriorityLabel/PriorityLabel';
import User from '@components/shared/User/User';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import ZonedDateTime from '@components/shared/ZonedDateTime';

interface IProps {
  audit: AuditDetails | null;
}

const AllAuditActions: React.FC<React.PropsWithChildren<IProps>> = ({
  audit,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { data, loading } = useSelector(actionsSelectors.getAllAuditsActions);

  useEffect(() => {
    if (audit) {
      dispatch(actionsActions.getAllAuditActions(audit.id));
    }
  }, [audit?.id]);

  if (loading) {
    return <Skeleton active />;
  }

  if (data.length === 0) {
    return (
      <EmptyTable
        text={formatMessage(
          { id: 'NoCorrectiveActions' },
          { linebreak: <br /> }
        )}
        showDemoBtn={false}
      />
    );
  }

  return (
    <>
      {data.map((action) => (
        <ActionContainer
          key={action.id}
          type="button"
          onClick={() => {
            dispatch(actionsActions.toggleActionDetailsModal(action.id));
          }}
        >
          <Content>
            <Title>
              {action.status === ActionStatus.Approved && <Check />}
              {action.number ? `${action.number} - ` : ''}
              {action.name}
            </Title>
            <Text>{action.description}</Text>
          </Content>
          <Footer>
            <FooterItem>
              <label>{formatMessage({ id: 'Status' })}</label>
              <ActionStatusName status={action.status} />
            </FooterItem>
            <FooterItemDivider />
            <FooterItem>
              <label>{formatMessage({ id: 'Priority' })}</label>
              <PriorityLabel priority={action.priority} />
            </FooterItem>
            <FooterItemDivider />
            {!!action.dueDateInformation && (
              <FooterItem>
                <label>{formatMessage({ id: 'Date' })}</label>
                <ZonedDateTime dateTime={action.dueDateInformation} />
              </FooterItem>
            )}
            <FooterItemDivider />
            <FooterItem>
              <User>
                {action.assignedUsers.length > 0
                  ? action.assignedUsers.map(({ name }) => name).join(', ')
                  : formatMessage({ id: 'Unassigned' })}
              </User>
            </FooterItem>
          </Footer>
        </ActionContainer>
      ))}
    </>
  );
};

export default AllAuditActions;
