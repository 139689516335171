import React, { useEffect } from 'react';

import { Permission } from '@repo/shared/enums';
import { convertQueryParamToAssignment } from '@utils';
import { getQueryStringParams } from '@repo/shared/utils';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { useAppDispatch, usePermission } from '@hooks';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';

import InternalAuditReportModal from '@presentation/Audits/InternalAuditReport/InternalAuditReportModal';
import Audits from './Audits';

const CompletedAudits: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  const [canViewAuditResults] = usePermission([Permission.CanViewAuditResults]);

  useEffect(() => {
    dispatch(auditsActions.setAuditsPage(AuditsPage.Completed));

    const { assignment, ...query } = getQueryStringParams([
      'scoreMin',
      'scoreMax',
      'status',
      'auditObjectIds',
      'auditObjectGroupIds',
      'templateId',
      'assignment',
      'startDate',
      'endDate',
    ]);

    const defaultFilters: Partial<AuditsFilters> = {
      ...query,
    };

    if (assignment !== undefined) {
      defaultFilters.assignment = convertQueryParamToAssignment(assignment);
    }

    dispatch(auditsActions.getAudits(defaultFilters));

    return () => {
      dispatch(auditsActions.setAuditsPage(null));
    };
  }, [dispatch, canViewAuditResults]);

  return (
    <>
      <Audits page={AuditsPage.Completed} />
      <InternalAuditReportModal />
    </>
  );
};

export default CompletedAudits;
