import styled from 'styled-components';
import Layout from 'antd/es/layout';

import { colors } from '@repo/shared/config';

export const Container = styled(Layout.Header)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${colors.blue6};
  padding: 0 24px;
`;

export const DeleteSampleDataBtn = styled.button`
  background: ${colors.yellow};
  border: 0;
  font-size: 11px;
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 14px;
  line-height: 1;
  margin-right: 20px;
  margin-top: 2px;
`;
