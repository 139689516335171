import React from 'react';
import { X } from 'react-feather';

import { Container, CloseButton, LeftContent } from './styled';
import { issuesActions } from '@application/Issues/store';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import ActionMenu from '@src/presentation/Issues/IssueDetails/IssueDetailsHeader/ActionMenu/ActionMenu';
import IssueStatusButtons from '@src/presentation/Issues/IssueDetails/IssueDetailsHeader/IssueDetailsStatusButtons/IssueDetailsStatusButtons';

interface IProps {}

const IssueDetailsHeader: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <LeftContent>
        <ActionMenu />
      </LeftContent>
      <IssueStatusButtons />
      <div>
        <CloseButton
          type="button"
          data-e2e={e2eTestElements.issues.issues.details.closeModalBtn}
          onClick={() => {
            dispatch(issuesActions.toggleIssueDetailsModal(null));
          }}
        >
          <X />
        </CloseButton>
      </div>
    </Container>
  );
};

export default IssueDetailsHeader;
