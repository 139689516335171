import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { ActionsFilters } from '@application/Actions/models/ActionsFilters';
import { useAppDispatch } from '@hooks';
import { actionsActions, actionsSelectors } from '@store';
import { ActionStatus } from '@repo/shared/enums';
import { Priority } from '@repo/shared/enums';
import { ActionDueDateStatus } from '@application/Actions/enums/ActionDueDateStatus';
import { convertQueryParamToAssignment } from '@utils';
import { getQueryStringParams } from '@repo/shared/utils';

export function useQueryParamsAndGetActions(
  page: ActionsPage | null,
  params?: Partial<ActionsFilters>
) {
  const dispatch = useAppDispatch();

  const currentPage = useSelector(actionsSelectors.getPage);

  useEffect(() => {
    if (page === null || currentPage !== page) {
      return;
    }

    const paramToStatusMap = {
      '0': ActionStatus.Opened,
      '1': ActionStatus.Approved,
      '2': ActionStatus.Rejected,
      '3': ActionStatus.Submitted,
    };

    const paramToPriorityMap = {
      '0': Priority.Low,
      '1': Priority.Medium,
      '2': Priority.High,
    };

    const paramToDueStatusMap = {
      '0': ActionDueDateStatus.All,
      '1': ActionDueDateStatus.DueIn7Days,
      '2': ActionDueDateStatus.PastDue,
    };

    const queryStringParams = getQueryStringParams([
      'dueDate',
      'status',
      'dueDateStatus',
      'priority',
      'assignment',
      'templateId',
      'auditObjectIds',
      'auditObjectGroupIds',
      'startDate',
      'endDate',
      'auditId',
    ]);

    if (
      Object.values(queryStringParams).every((param) => param === undefined)
    ) {
      dispatch(actionsActions.getActions(params));
      return;
    }

    const { assignment, status, priority, dueDateStatus, ...query } =
      queryStringParams;

    dispatch(
      actionsActions.getActions({
        ...query,
        status: /^[0-3]$/.test(status)
          ? paramToStatusMap[status as '0' | '1' | '2' | '3']
          : null,
        priority: /^[0-2]$/.test(priority)
          ? paramToPriorityMap[priority as '0' | '1' | '2']
          : null,
        dueDateStatus: /^[0-2]$/.test(dueDateStatus)
          ? paramToDueStatusMap[dueDateStatus as '0' | '1' | '2']
          : ActionDueDateStatus.All,
        assignment: convertQueryParamToAssignment(assignment),
        ...(params || {}),
      })
    );
  }, [page, currentPage]);
}
