import { createAction, createAsyncThunk, Update } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { Logger } from '@repo/shared/services';
import { date } from '@utils';
import {
  getErrorMessage,
  getExportFileMetaData,
  saveFile,
} from '@repo/shared/utils';
import { IPagedResponse } from '@repo/shared/types';
import { config } from '@repo/shared/config';
import { ExportType } from '@repo/shared/enums';
import AuditsApiClient from '@infrastructure/Audits/api/AuditsApiClient';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import { ReopenAuditDto } from '@infrastructure/Audits/models/ReopenAuditDto';
import { CreateOneTimeAuditDto } from '@infrastructure/Audits/models/CreateOneTimeAuditDto';
import { ReopenAuditModalState } from '@application/Audits/models/ReopenAuditModalState';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { UpdateAuditPayload } from '@application/Audits/models/UpdateAuditPayload';
import { Audit } from '@domain/Audits/models/Audit';
import { ReportAuditModalState } from '@application/Audits/models/ReportAuditModalState';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { AssignUsersToAuditsModalState } from '@application/Audits/models/AssignUsersToAuditsModalState';
import { TrendsModalState } from '@application/Audits/models/TrendsModalState';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { AuditStatus } from '@domain/Audits/enums/AuditStatus';
import { ViewAuditModalState } from '@application/Audits/models/ViewAuditModalState';

const auditsApiClient = new AuditsApiClient();

const getAudits = createAsyncThunk<
  IPagedResponse<Audit>,
  Partial<AuditsFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>('audits/getAudits', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const page = auditsSelectors.getPage(state);
    const filters = auditsSelectors.getFilters(state);

    switch (page) {
      case AuditsPage.My:
        return await auditsApiClient.getMyAudits(filters);
      case AuditsPage.Pending:
      case null:
        return await auditsApiClient.getPendingAudits(filters);
      case AuditsPage.Completed:
        return await auditsApiClient.getCompletedAudits(filters);
      case AuditsPage.Expired:
        return await auditsApiClient.getExpiredAudits(filters);
    }
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getAuditDetails = createAsyncThunk<
  AuditDetails,
  { auditId: string; status: AuditStatus },
  { rejectValue: string }
>(
  'audits/getAuditDetails',
  async ({ auditId, status }, { rejectWithValue }) => {
    try {
      switch (status) {
        case AuditStatus.Pending:
        case AuditStatus.Reopened:
        case AuditStatus.InProgress:
          return await auditsApiClient.getPendingAuditDetails(auditId);
        case AuditStatus.Completed:
          return await auditsApiClient.getCompletedAuditDetails(auditId);
        case AuditStatus.Expired:
          return await auditsApiClient.getExpiredAuditDetails(auditId);
      }
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getSampleAuditId = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>('audits/getSampleAuditId', async (_, { rejectWithValue }) => {
  try {
    return await auditsApiClient.getSampleAuditId();
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const updatePendingAudit = createAsyncThunk<
  UpdateAuditPayload,
  UpdateAuditPayload,
  { rejectValue: string }
>('audits/updatePendingAudit', async (payload, { rejectWithValue }) => {
  try {
    await auditsApiClient.updatePendingAudit(payload);

    return payload;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const updateAuditLocally = createAction<Update<Audit, string>>(
  'audits/updateAuditLocally'
);

const reopenAudit = createAsyncThunk<
  void,
  ReopenAuditDto,
  { rejectValue: string }
>('audits/reopenAudit', async (dto, { rejectWithValue }) => {
  try {
    await auditsApiClient.reopenAudit(dto);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const expirePendingAudits = createAsyncThunk<
  void,
  string[],
  { rejectValue: string }
>('audits/expirePendingAudits', async (ids, { rejectWithValue }) => {
  try {
    await auditsApiClient.expirePendingAudits(ids);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const deleteAudits = createAsyncThunk<
  string[],
  string[],
  { rejectValue: string }
>('audits/deleteAudits', async (ids, { rejectWithValue, getState }) => {
  try {
    await auditsApiClient.deleteAudits(ids);
    return ids;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const setAuditsPage = createAction<AuditsPage | null>('audits/setAuditsPage');

const resetListData = createAction('audits/resetListData');

const resetDetailsData = createAction('audits/resetDetailsData');

const toggleViewAuditModal = createAction<ViewAuditModalState>(
  'audits/toggleViewAuditModal'
);

const toggleFiltersModal = createAction<boolean>('audits/toggleFiltersModal');

const toggleReopenAuditModal = createAction<ReopenAuditModalState>(
  'audits/toggleReopenAuditModal'
);

const toggleAuditReportModal = createAction<ReportAuditModalState>(
  'audits/toggleAuditReportModal'
);

const toggleCreateOneTimeAuditModal = createAction<boolean>(
  'audits/toggleCreateOneTimeAuditModal'
);

const createOneTimeAudit = createAsyncThunk<
  string,
  CreateOneTimeAuditDto,
  { rejectValue: string }
>('audits/createOneTimeAudit', async (dto, { rejectWithValue }) => {
  try {
    return await auditsApiClient.createOneTimeAudit(dto);
  } catch (e) {
    Logger.captureException(e);

    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleStartSampleAuditModal = createAction<boolean>(
  'audits/toggleStartSampleAuditModal'
);

const exportAudits = createAsyncThunk<void, void, { state: IRootState }>(
  'audits/exportAudits',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();

    const page = auditsSelectors.getPage(state);
    const filters = auditsSelectors.getFilters(state);

    try {
      const { mimeType, extension } = getExportFileMetaData(ExportType.Xls);

      saveFile({
        data: await auditsApiClient.exportAudits(page, filters),
        mimeType,
        fileName: `All Audits — ${date().format(
          config.dateFormat
        )}.${extension}`,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleConfirmDeleteModal = createAction<DeleteModalState<Audit>>(
  'audits/toggleConfirmDeleteModal'
);

const toggleAssignUsersToAuditsModal =
  createAction<AssignUsersToAuditsModalState>(
    'audits/toggleAssignUsersToAuditsModal'
  );

const assignUsersToAudits = createAsyncThunk<
  void,
  {
    usersIds: string[];
    auditsIds: string[];
  },
  { state: IRootState }
>(
  'actions/assignUsersToAudits',
  async ({ usersIds, auditsIds }, { rejectWithValue }) => {
    try {
      await auditsApiClient.assignUsersToAudits({
        usersIds,
        auditsIds,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteAuditLocally = createAction<string>('audits/deleteAuditLocally');

const toggleTrendsModal = createAction<TrendsModalState>(
  'audits/toggleTrendsModal'
);

export const auditsActions = {
  getAudits,
  getAuditDetails,
  getSampleAuditId,
  reopenAudit,
  updateAuditLocally,
  updatePendingAudit,
  deleteAudits,
  setAuditsPage,
  resetListData,
  resetDetailsData,
  toggleViewAuditModal,
  toggleFiltersModal,
  toggleReopenAuditModal,
  toggleAuditReportModal,
  toggleCreateOneTimeAuditModal,
  createOneTimeAudit,
  toggleStartSampleAuditModal,
  exportAudits,
  toggleConfirmDeleteModal,
  expirePendingAudits,
  toggleAssignUsersToAuditsModal,
  assignUsersToAudits,
  deleteAuditLocally,
  toggleTrendsModal,
};
