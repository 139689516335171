import React, { useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { notification } from '@utils/notifications';
import {
  ITag,
  IGroup,
  IUser,
  IAuditObject,
  IRole,
  IScore,
} from '@repo/shared/types';
import { Modal } from '@repo/shared/enums';
import { accountActions, generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { DeleteSampleDataCheckbox, Desc, Header } from './styled';
import { colors } from '@repo/shared/config';
import { Action } from '@domain/Actions/models/Action';
import { Schedule } from '@domain/Schedules/models/Schedule';
import { IssueType } from '@domain/IssueTypes/models/IssueType';

import LibraryModal from '../ant/Modal/Modal';
import { Logger } from '@repo/shared/services';

interface IProps {
  text: {
    title: string;
    error: string;
    desc: string;
  };
  onSubmit: (ids: string[]) => Promise<void> | void;
  onClose?: () => void;
  onSuccess: (ids: string[]) => Promise<void> | void;
  data: Record<
    string,
    | ITag
    | IUser
    | IAuditObject
    | Schedule
    | Action
    | IRole
    | IScore
    | IGroup
    | IssueType
  > | null;
}

const ConfirmDeleteModal: React.FC<React.PropsWithChildren<IProps>> = ({
  onClose,
  onSuccess,
  text,
  onSubmit,
  data,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [deleteSampleData, setDeleteSampleData] = useState(false);

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const modalData = useSelector(generalSelectors.getModal(Modal.ConfirmDelete));
  const [loading, setLoading] = useState(false);

  function closeModal() {
    dispatch(generalActions.hideModal(Modal.ConfirmDelete));
    setDeleteSampleData(false);

    if (typeof onClose === 'function') {
      onClose();
    }
  }

  const ids =
    selectedTableRowKeys.length > 0
      ? selectedTableRowKeys
      : [modalData.data?.id];
  const isMassDeletion = ids.length > 1;

  return (
    <LibraryModal
      title={text.title}
      open={modalData.opened}
      width={400}
      onOk={async () => {
        setLoading(true);

        try {
          if (deleteSampleData) {
            await dispatch(accountActions.deleteSampleData());
            dispatch(accountActions.toggleFullScreenLoader(true));
            await dispatch(accountActions.getAppData());
            dispatch(accountActions.toggleFullScreenLoader(false));
          } else {
            await onSubmit(ids);
          }

          if (selectedTableRowKeys.length > 0) {
            setTimeout(() => {
              dispatch(generalActions.selectTableRows([]));
            }, 200);
          }

          await onSuccess(ids);
        } catch (e: any) {
          Logger.captureException(e);

          notification.error({
            message: text.error,
            description: e.message,
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({
        id: deleteSampleData ? 'DeleteAllSampleData' : 'Delete',
      })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => closeModal()}
      closeIcon={<X />}
    >
      {text.desc.replace(
        '%replaceValue%',
        isMassDeletion ? ids.length.toString() : data?.[ids[0]]?.name || ''
      )}
      {!!modalData?.data?.isSample && (
        <DeleteSampleDataCheckbox
          checked={deleteSampleData}
          onChange={(e) => {
            setDeleteSampleData(e.target.checked);
          }}
        >
          <Header>{formatMessage({ id: 'DeleteAllSampleData' })}?</Header>
          <Desc>
            {formatMessage({ id: 'YouCanReCreateItInGettingStartedMenu' })}
          </Desc>
        </DeleteSampleDataCheckbox>
      )}
    </LibraryModal>
  );
};

export default ConfirmDeleteModal;
