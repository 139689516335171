import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import Button from '../../shared/ant/Button';

export const SocButton = styled(Button)`
  background: ${colors.gray3};
  margin-bottom: 10px;
  border: 0 !important;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    background: ${colors.gray4};
  }

  span {
    color: ${colors.gray10};
  }
`;

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 14px;
  color: ${colors.gray8};
  line-height: 22px;
`;
