import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';
import Button from '../../../../shared/ant/Button';
import { hexToRGB } from '../../../../../../core/utils/misc';

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'state';
  //margin-top: 12px;
  text-align: left;

  & > * {
    grid-area: state;
  }
`;

export const ApplyPromoCodeBtn = styled(animated.button)`
  height: 15px;
  background: none;
  line-height: 15px;
  color: ${colors.blue2};
  font-size: 12px;
  border: 0;
  padding: 0;
  text-align: left;
`;

export const AddPromoCode = styled(animated.div)`
  width: 100%;
`;

export const FormWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  display: block;
  border: 0;
  border-radius: 5px;
  background: ${colors.white};
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 40px 0 12px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const ConfirmApplyBtn = styled(Button)`
  position: absolute !important;
  right: 4px;
  top: 4px;
  height: 24px;
  padding: 2px 10px;

  &:disabled {
    background: ${colors.blue2}!important;
    border-color: ${colors.blue2}!important;
    color: ${colors.white}!important;
    opacity: 0.4;
  }
`;

export const Error = styled.div`
  text-align: center;
  font-size: 12px;
  color: ${colors.red};
  margin-top: 8px;
`;

export const PromoCode = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  color: ${colors.gray8};
  margin-bottom: 4px;
`;

export const Details = styled(animated.div)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Code = styled.div`
  font-weight: 600;
  text-transform: uppercase;
`;

export const RemoveBtnWrapper = styled.div`
  flex-grow: 1;
`;

export const RemoveBtn = styled.button`
  background: none;
  padding: 0 6px;
  border: 0;
  color: ${colors.blue2};
`;

export const Value = styled.div`
  color: ${colors.gray10};
`;

export const Description = styled.div`
  border: 1px solid ${colors.green};
  border-radius: 3px;
  background: ${hexToRGB(colors.green, 0.1)};
  color: ${colors.green};
  padding: 10px;
  margin-top: 10px;
`;
