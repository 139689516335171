import styled from 'styled-components';

import { colors, BREAKPOINTS } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 20px;

  & > div {
    width: 100%;
    margin: 0 20px;
  }

  @media (min-width: 420px) {
    & > div {
      width: 370px;
    }
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    & > div {
      width: 390px;
    }
  }
`;

export const LogoWrapper = styled.div`
  & > div {
    width: 200px;
    height: 51px;
    margin: 0 auto 32px;

    @media (min-width: ${BREAKPOINTS.MD}px) {
      margin: 0 auto 50px;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 30px 25px 35px;
  background: ${colors.white};
  border-radius: 10px;
  overflow: hidden;
  text-align: center;

  svg {
    color: ${colors.red};
    width: 34px;
    height: 34px;
    margin-bottom: 10px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 15px;
`;

export const Text = styled.div`
  font-size: 15px;
  padding: 0 20px;
  line-height: 19px;
  margin-bottom: 20px;
`;
