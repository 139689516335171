import React from 'react';
import { useIntl } from 'react-intl';

import { date } from '@utils';
import { config } from '@repo/shared/config';
import { Audit } from '@domain/Audits/models/Audit';
import { SchedulePlanPeriodAudit } from '@domain/SchedulePlans/models/SchedulePlanPeriodAudit';

interface IProps {
  audit?: Audit | SchedulePlanPeriodAudit | null;
}

const AuditStatusName: React.FC<React.PropsWithChildren<IProps>> = ({
  audit,
}) => {
  const { formatMessage } = useIntl();

  if (!audit) {
    return null;
  }

  if (audit.expiredAtInformation) {
    return <>{formatMessage({ id: 'Expired' })}</>;
  }

  if (
    !audit.startedAtInformation &&
    date(audit.startDateInformation.localTime).isAfter(date())
  ) {
    return <>{formatMessage({ id: 'Upcoming' })}</>;
  }

  if (!audit.completedAtInformation && !audit.startedAtInformation) {
    if (!date(audit.startDateInformation.localTime).isSame(date(), 'day')) {
      return (
        <>
          {formatMessage(
            { id: 'StartsOnDate' },
            {
              date: date(audit.startDateInformation.localTime).format(
                config.dateFormat
              ),
            }
          )}
        </>
      );
    }

    return <>{formatMessage({ id: 'ReadyToStart' })}</>;
  }

  if (audit.startedAtInformation && !audit.completedAtInformation) {
    return <>{formatMessage({ id: 'InProgress' })}</>;
  }

  if (audit.completedAtInformation) {
    return <>{formatMessage({ id: 'Completed' })}</>;
  }

  return null;
};

export default AuditStatusName;
