import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';

import { accountSelectors, accountActions } from '@store';
import {
  Container,
  ApplyPromoCodeBtn,
  FormWrapper,
  ConfirmApplyBtn,
  Input,
  PromoCode,
  Code,
  Label,
  Description,
  Value,
  Details,
  RemoveBtnWrapper,
  RemoveBtn,
  Error,
  AddPromoCode,
} from './styled';
import { useAppDispatch } from '@hooks';
import { round } from '@utils';

interface IProps {
  loading?: boolean;
  readOnly?: boolean;
}

const Coupon: React.FC<React.PropsWithChildren<IProps>> = ({
  loading = false,
  readOnly,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const coupon = useSelector(accountSelectors.getCoupon);
  const { couponDiscount } = useSelector(accountSelectors.getTotalCost);

  const [edit, setEdit] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [codeNotExists, setCodeNotExists] = useState(false);
  const [codeDraft, setCodeDraft] = useState('');

  if (readOnly && !coupon) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Container>
        {coupon ? (
          <PromoCode>
            <Label>{formatMessage({ id: 'PromoCode' })}</Label>
            <Details>
              <Code>{coupon.code}</Code>
              <RemoveBtnWrapper>
                {!readOnly && (
                  <RemoveBtn
                    type="button"
                    onClick={() => {
                      dispatch(accountActions.removeCouponLocally());
                    }}
                  >
                    {formatMessage({ id: 'Remove' })}
                  </RemoveBtn>
                )}
              </RemoveBtnWrapper>
              <Value>-${round(couponDiscount, 2)}</Value>
            </Details>
            {!readOnly && coupon.description && (
              <Description>{coupon.description}</Description>
            )}
          </PromoCode>
        ) : (
          <>
            {!readOnly && edit ? (
              <AddPromoCode>
                <FormWrapper>
                  <Input
                    value={codeDraft}
                    disabled={processing}
                    onChange={(e) => {
                      setCodeDraft(e.target.value);
                      setCodeNotExists(false);
                    }}
                  />
                  <ConfirmApplyBtn
                    type="primary"
                    loading={processing}
                    disabled={codeDraft === '' || codeNotExists}
                    onClick={async () => {
                      setCodeNotExists(false);
                      setProcessing(true);

                      const validateCodeResultAction = await dispatch(
                        accountActions.validateCouponCode(codeDraft)
                      );

                      if (
                        accountActions.validateCouponCode.fulfilled.match(
                          validateCodeResultAction
                        )
                      ) {
                        setCodeDraft('');
                        setEdit(false);
                      } else {
                        setCodeNotExists(true);
                      }

                      setProcessing(false);
                    }}
                  >
                    {formatMessage({ id: 'Apply' })}
                  </ConfirmApplyBtn>
                </FormWrapper>
                {codeNotExists && (
                  <Error>
                    {formatMessage({ id: 'ThisPromoCodeDoesNotExist' })}
                  </Error>
                )}
              </AddPromoCode>
            ) : (
              <ApplyPromoCodeBtn
                type="button"
                onClick={() => {
                  setEdit(true);
                }}
              >
                {formatMessage({ id: 'ApplyPromoCode' })}
              </ApplyPromoCodeBtn>
            )}
          </>
        )}
      </Container>
    </Spin>
  );
};

export default Coupon;
