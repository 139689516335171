import styled from 'styled-components';

export const DefaultMqaLogo = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
`;

export const LogoImg = styled.img`
  object-fit: contain;
`;
