import React from 'react';
import { useSelector } from 'react-redux';

import { Link, Icon, Text } from './styled';
import { accountSelectors } from '@store';

interface IProps {}

const Announcement: React.FC<React.PropsWithChildren<IProps>> = () => {
  const configuration = useSelector(accountSelectors.getAppConfiguration);

  if (configuration.news?.length) {
    const latest = configuration.news[configuration.news.length - 1];

    return (
      <Link href={latest.url} target="_blank" rel="noopener noreferrer">
        <Icon />
        <Text>{latest.text}</Text>
      </Link>
    );
  }

  return null;
};

export default Announcement;
