import React, { Component } from 'react';
import { IntlShape, WrappedComponentProps, injectIntl } from 'react-intl';

export let intl:
  | IntlShape
  | { formatMessage: (payload: { id: string }) => string; messages: any } = {
  formatMessage(payload: { id: string }) {
    return payload.id;
  },
  messages: {},
};

class IntlGlobalProvider extends Component<
  React.PropsWithChildren<WrappedComponentProps>
> {
  constructor(props: any) {
    super(props);
    intl = this.props.intl;
  }

  componentDidUpdate(prevProps: WrappedComponentProps) {
    if (prevProps.intl.messages.locale !== this.props.intl.messages.locale) {
      intl = this.props.intl;
    }
  }

  public render() {
    return this.props.children;
  }
}

export default injectIntl(IntlGlobalProvider);
