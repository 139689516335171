import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { colors } from '@repo/shared/config';

const containerStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${colors.gray4};
  border: 0;
  padding: 10px 10px 10px 14px;
  border-radius: 5px;
  font-size: 14px;
  color: ${colors.gray10};
  margin-bottom: 8px;
  min-height: 44px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    display: block;
    width: 17px;
    height: 17px;
    color: ${colors.gray8};
  }
`;

export const Link = styled(RouterLink)`
  ${containerStyles}
`;

export const DisabledLink = styled.div`
  ${containerStyles};
`;

export const Button = styled.button`
  ${containerStyles}
`;

export const Icon = styled.div`
  width: 17px;
`;

export const Name = styled.div`
  flex-grow: 1;
  text-align: left;
  padding: 0 13px 0 10px;
  line-height: 1.35;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Extra = styled.div`
  display: flex;
  align-items: center;
`;

export const ExtraIcon = styled.div`
  margin-left: 3px;
`;
