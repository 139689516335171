import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Headline = styled.h1`
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 3px 0;
  font-weight: 600;
`;

export const Tagline = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${colors.gray8};
  margin: 0;
`;
