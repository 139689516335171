import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPagedResponse } from '@repo/shared/types';
import { IRootState } from '@src/core/frameworks/redux';
import { Logger } from '@repo/shared/services';
import { date } from '@utils';
import { getErrorMessage, saveFile } from '@repo/shared/utils';
import { AuditTemplatesFilters } from '@application/AuditTemplates/models/AuditTemplatesFilters';
import AuditTemplatesApiClient from '@infrastructure/AuditTemplates/api/AuditTemplatesApiClient';
import { auditTemplatesSelectors } from '@application/AuditTemplates/store/auditTemplatesSelectors';
import { accountSelectors } from '@store';
import { ApiConflictError } from '@repo/shared/errors';
import { InternalApiService } from '@repo/shared/api';
import { ExportAuditTemplatePdfParams } from '@application/AuditTemplates/models/ExportAuditTemplatePdfParams';
import { config } from '@repo/shared/config';
import { AuditTemplateGroup } from '@domain/AuditTemplates/models/AuditTemplateGroup';
import { AuditTemplate } from '@domain/AuditTemplates/models/AuditTemplate';
import { AuditTemplateDetails } from '@repo/shared/types';
import { DeleteTemplateModalState } from '@application/AuditTemplates/models/DeleteTemplateModalState';
import { AuditTemplateSuggestion } from '@domain/AuditTemplates/models/AuditTemplateSuggestion';

const apiService = InternalApiService.getInstance();
const apiClient = new AuditTemplatesApiClient();

const getTemplateGroups = createAsyncThunk<
  IPagedResponse<AuditTemplateGroup>,
  Partial<AuditTemplatesFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>(
  'auditTemplates/getAuditTemplates',
  async (_, { rejectWithValue, getState }) => {
    try {
      return await apiClient.getAuditTemplateGroups(
        auditTemplatesSelectors.getFilters(getState())
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteTemplate = createAsyncThunk<
  void,
  AuditTemplate | AuditTemplateDetails,
  { state: IRootState; rejectValue: string }
>(
  'auditTemplates/deleteTemplate',
  async ({ id, isDraft }, { rejectWithValue }) => {
    try {
      await apiClient.deleteTemplate(id, isDraft);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteTemplateFromGrid = createAction<string>(
  'auditTemplates/deleteTemplateFromGrid'
);

const setDefaultTemplate = createAsyncThunk<
  void,
  string,
  { state: IRootState }
>(
  'auditTemplates/setDefaultTemplate',
  async (templateId, { rejectWithValue }) => {
    try {
      await apiClient.setDefaultTemplate(templateId);
    } catch (e) {
      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const copyTemplate = createAsyncThunk<
  void,
  { templateId: string; companyId: string }
>(
  'auditTemplates/copyTemplate',
  async ({ templateId, companyId }, { rejectWithValue }) => {
    try {
      await apiClient.copyTemplate(templateId, companyId);
    } catch (e) {
      if (e instanceof ApiConflictError) {
        return rejectWithValue(e.message);
      }

      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const exportTemplatePdf = createAsyncThunk<
  void,
  ExportAuditTemplatePdfParams,
  { rejectValue: string }
>(
  'auditTemplates/exportTemplatePdf',
  async (params, { rejectWithValue, signal }) => {
    try {
      const source = apiService.getCancelTokenSource();

      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const data = await apiClient.exportTemplatePdf({
        ...params,
        cancelToken: source.token,
      });

      saveFile({
        data,
        mimeType: 'application/pdf;charset=utf-8',
        fileName: `All Audit Templates - ${date().format(
          config.dateFormat
        )}.pdf`,
      });
    } catch (e) {
      if (!apiService.isCancelError(e)) {
        Logger.captureException(e);
      }

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleSetDefaultTemplateModal = createAction<AuditTemplate | null>(
  'auditTemplates/toggleSetDefaultTemplateModal'
);

const toggleCopyTemplateModal = createAction<AuditTemplate | null>(
  'auditTemplates/toggleCopyTemplateModal'
);

const toggleExportTemplatePdfModal = createAction<
  AuditTemplate | AuditTemplateDetails | null
>('auditTemplates/toggleExportTemplatePdfModal');

const toggleDeleteModal = createAction<DeleteTemplateModalState>(
  'auditTemplates/toggleDeleteModal'
);

const toggleSuggestionsModal = createAction<boolean>(
  'auditTemplates/toggleSuggestionsModal'
);

const getPrompts = createAsyncThunk<string[], void, { rejectValue: string }>(
  'auditTemplates/getPrompts',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      const locale = accountSelectors.getLocale(state);
      const company = accountSelectors.getCompany(state);

      return await apiClient.getPrompts(company.industry, locale);
    } catch (e) {
      if (e instanceof ApiConflictError) {
        return rejectWithValue(e.message);
      }

      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getSuggestion = createAsyncThunk<
  AuditTemplateSuggestion,
  string,
  { rejectValue: string }
>(
  'auditTemplates/getSuggestion',
  async (prompt, { rejectWithValue, getState }) => {
    try {
      return await apiClient.getSuggestion(prompt);
    } catch (e) {
      if (e instanceof ApiConflictError) {
        return rejectWithValue(e.message);
      }

      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

export const auditTemplatesActions = {
  getTemplateGroups,
  deleteTemplate,
  deleteTemplateFromGrid,
  setDefaultTemplate,
  copyTemplate,
  exportTemplatePdf,
  toggleSetDefaultTemplateModal,
  toggleCopyTemplateModal,
  toggleExportTemplatePdfModal,
  toggleDeleteModal,
  toggleSuggestionsModal,
  getPrompts,
  getSuggestion,
};
