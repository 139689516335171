import { BaseApiClient } from '@repo/shared/api';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import { IPagedResponse } from '@repo/shared/types';
import {
  GetCompletedAuditDetailsDto,
  GetCompletedAuditDto,
} from '@infrastructure/Audits/models/GetCompletedAuditDto';
import { ReopenAuditDto } from '@infrastructure/Audits/models/ReopenAuditDto';
import { CreateOneTimeAuditDto } from '@infrastructure/Audits/models/CreateOneTimeAuditDto';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import {
  GetPendingAuditDetailsDto,
  GetPendingAuditDto,
} from '@infrastructure/Audits/models/GetPendingAuditDto';
import {
  GetExpiredAuditDetailsDto,
  GetExpiredAuditDto,
} from '@infrastructure/Audits/models/GetExpiredAuditDto';
import { UpdateAuditPayload } from '@application/Audits/models/UpdateAuditPayload';
import { transformUpdateAuditDto } from '@infrastructure/Audits/transformers/transformUpdateAuditDto';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { transformPendingAuditDtoToAudit } from '@infrastructure/Audits/transformers/transformPendingAuditDtoToAudit';
import { transformCompletedAuditDtoToAudit } from '@infrastructure/Audits/transformers/transformCompletedAuditDtoToAudit';
import { transformExpiredAuditDtoToAudit } from '@infrastructure/Audits/transformers/transformExpiredAuditDtoToAudit';
import { transformPendingAuditDetailsDtoToAudit } from '@infrastructure/Audits/transformers/transformPendingAuditDetailsDtoToAudit';
import { transformCompletedAuditDetailsDtoToAudit } from '@infrastructure/Audits/transformers/transformCompletedAuditDetailsDtoToAudit';
import { transformExpiredAuditDetailsDtoToAudit } from '@infrastructure/Audits/transformers/transformExpiredAuditDetailsDtoToAudit';

class AuditsApiClient extends BaseApiClient {
  public async getMyAudits(filters: Partial<AuditsFilters>) {
    const { data, meta } = await this._apiService.post<
      IPagedResponse<GetPendingAuditDto>
    >({
      url: 'audit/all/pending/my',
      body: {
        ...filters,
      },
    });

    return {
      data: data.map(transformPendingAuditDtoToAudit),
      meta,
    };
  }

  public async getPendingAudits(filters: Partial<AuditsFilters>) {
    const { data, meta } = await this._apiService.post<
      IPagedResponse<GetPendingAuditDto>
    >({
      url: 'audit/all/pending',
      body: {
        ...filters,
      },
    });

    return {
      data: data.map(transformPendingAuditDtoToAudit),
      meta,
    };
  }

  public async getCompletedAudits(filters: Partial<AuditsFilters>) {
    const { data, meta } = await this._apiService.post<
      IPagedResponse<GetCompletedAuditDto>
    >({
      url: 'audit/all/complete',
      body: {
        ...filters,
      },
    });

    return {
      data: data.map(transformCompletedAuditDtoToAudit),
      meta,
    };
  }

  public async getExpiredAudits(filters: Partial<AuditsFilters>) {
    const { data, meta } = await this._apiService.post<
      IPagedResponse<GetExpiredAuditDto>
    >({
      url: 'audit/all/expired',
      body: {
        ...filters,
      },
    });

    return {
      data: data.map(transformExpiredAuditDtoToAudit),
      meta,
    };
  }

  public async getAuditDetails(auditId: string) {
    return this._apiService.get<AuditDetails>({
      url: `audit/all/${auditId}`,
    });
  }

  public async getPendingAuditDetails(auditId: string) {
    const dto = await this._apiService.get<GetPendingAuditDetailsDto>({
      url: `audit/all/pending/${auditId}`,
    });

    return transformPendingAuditDetailsDtoToAudit(dto);
  }

  public async getCompletedAuditDetails(auditId: string) {
    const dto = await this._apiService.get<GetCompletedAuditDetailsDto>({
      url: `audit/all/complete/${auditId}`,
    });

    return transformCompletedAuditDetailsDtoToAudit(dto);
  }

  public async getExpiredAuditDetails(auditId: string) {
    const dto = await this._apiService.get<GetExpiredAuditDetailsDto>({
      url: `audit/all/expired/${auditId}`,
    });

    return transformExpiredAuditDetailsDtoToAudit(dto);
  }

  public async getSampleAuditId() {
    const { id } = await this._apiService.post<{
      id: string;
    }>({
      url: 'sample-data/audit',
    });

    return id;
  }

  public async updatePendingAudit(dto: UpdateAuditPayload) {
    await this._apiService.put({
      url: `audit/all/${dto.id}`,
      body: {
        ...transformUpdateAuditDto(dto),
      },
    });
  }

  public async reopenAudit(dto: ReopenAuditDto) {
    await this._apiService.post({
      url: `audit/all/${dto.id}/reopen`,
      body: {
        ...dto,
      },
    });
  }

  public async deleteAudits(ids: string[]) {
    await this._apiService.delete({
      url: 'audit/all',
      body: {
        ids,
      },
    });
  }

  public async createOneTimeAudit(dto: CreateOneTimeAuditDto) {
    const { id } = await this._apiService.post<{
      id: string;
    }>({
      url: 'audit/all',
      body: {
        ...dto,
      },
    });

    return id;
  }

  public async exportAudits(page: AuditsPage | null, filters: AuditsFilters) {
    const urls = {
      [AuditsPage.My]: 'audit/all/pending/my',
      [AuditsPage.Pending]: 'audit/all/pending',
      [AuditsPage.Completed]: 'audit/all/complete',
      [AuditsPage.Expired]: 'audit/all/expired',
    };

    const { data } = await this._apiService.post<{ data: ArrayBuffer }>({
      responseType: 'arraybuffer',
      url: `${urls[page ?? AuditsPage.Pending]}/export-xls`,
      body: {
        ...filters,
      },
      withHeaders: true,
    });

    return data;
  }

  public async expirePendingAudits(ids: string[]) {
    await this._apiService.post({
      url: 'audit/all/expire',
      body: {
        ids,
      },
    });
  }

  public async assignUsersToAudits({
    usersIds,
    auditsIds,
  }: {
    usersIds: string[];
    auditsIds: string[];
  }) {
    await this._apiService.post({
      url: `audit/all/assign`,
      body: {
        usersIds,
        auditsIds,
      },
    });
  }
}

export default AuditsApiClient;
