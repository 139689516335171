import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Title, CloseModalButton } from './styled';
import { ArrowLeft } from '@icons';
import { generalSelectors } from '@store';
import { routes } from '@config';

import PageHeader from '@components/shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

export enum UserGroupPage {
  Details,
  Assignments,
}

interface IProps {
  page: UserGroupPage;
  extra?: React.ReactNode;
}

const UserGroupContentWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  children,
  extra,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const { loading } = useSelector(generalSelectors.getConciseUserGroups);
  const userGroupsMap = useSelector(generalSelectors.getConciseUserGroupsMap);

  useEffect(() => {
    if (!id || (!loading && !userGroupsMap[id])) {
      navigate(routes.users.groupsList);
      return;
    }
  }, [id, loading, userGroupsMap]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            <CloseModalButton
              type="button"
              onClick={() => {
                navigate(routes.users.groupsList);
              }}
            >
              <ArrowLeft />
            </CloseModalButton>
            <Title>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                <>{id ? userGroupsMap[id]?.name : '-'}</>
              )}
            </Title>
          </Space>
        }
        extra={extra}
        footer={
          <PageTabbedSwitcher<UserGroupPage>
            pages={[
              {
                onClick() {
                  if (id) {
                    navigate(routes.users.groupDetails.replace(':id', id));
                  }
                },
                name: formatMessage({ id: 'Details' }),
                id: UserGroupPage.Details,
              },
              {
                onClick() {
                  if (id) {
                    navigate(routes.users.groupAssignments.replace(':id', id));
                  }
                },
                name: formatMessage({ id: 'Assignments' }),
                id: UserGroupPage.Assignments,
              },
            ]}
            activePage={page}
          />
        }
      />
      {children}
    </>
  );
};

export default UserGroupContentWrapper;
