import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Modal } from 'antd';

import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useAppDispatch } from '@hooks';
import {
  Container,
  TwoColsContainer,
  LeftCol,
  RightCol,
  LeftColContent,
} from './styled';
import { routes } from '@config';
import { IssuesApiErrorCodes } from '@infrastructure/Issues/api/IssuesApiErrorCodes';

import IssueDetailsHeader from '@src/presentation/Issues/IssueDetails/IssueDetailsHeader/IssueDetailsHeader';
import IssueDetailsTagline from '@src/presentation/Issues/IssueDetails/IssueDetailsTagline/IssueDetailsTagline';
import IssueDetailsForm from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/IssueDetailsForm';
import IssueDetailsEvents from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvents';
import Scrollbar from '@components/shared/Scrollbar/Scrollbar';
import { delay } from '@repo/shared/utils';

interface IProps {}

const IssueDetails: React.FC<React.PropsWithChildren<IProps>> = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const id = useSelector(issuesSelectors.getIssueDetailsId);

  const closeModal = useCallback(() => {
    dispatch(issuesActions.toggleIssueDetailsModal(null));
  }, []);

  const getIssueDetails = useCallback(
    async (issueId: string) => {
      const resultAction = await dispatch(
        issuesActions.getIssueDetails({ issueId, showLoader: true })
      );

      if (
        issuesActions.getIssueDetails.rejected.match(resultAction) &&
        resultAction.payload === IssuesApiErrorCodes.noAccessOrNotFound
      ) {
        navigate(routes.notFound);

        await delay(300);

        closeModal();
      }
    },
    [id]
  );

  useEffect(() => {
    if (id) {
      getIssueDetails(id);
    }
  }, [id]);

  return (
    <Modal
      open={!!id}
      width={1190}
      forceRender
      className="modal-no-padding modal-large"
      footer={null}
      closeIcon={null}
      styles={{
        body: {
          marginBottom: 0,
        },
      }}
      onCancel={closeModal}
    >
      <Container>
        <IssueDetailsHeader />
        <IssueDetailsTagline />
        <TwoColsContainer>
          <LeftCol>
            <Scrollbar>
              <LeftColContent>
                <IssueDetailsForm />
              </LeftColContent>
            </Scrollbar>
          </LeftCol>
          <RightCol>
            <IssueDetailsEvents />
          </RightCol>
        </TwoColsContainer>
      </Container>
    </Modal>
  );
};

export default IssueDetails;
