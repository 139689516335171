import { BaseApiClient } from '@repo/shared/api';
import { Issue, IssueDetails } from '@domain/Issues/models/Issue';
import { IssuesFilters } from '@application/Issues/models/IssuesFilters';
import {
  CreateIssueDto,
  CreateIssueDtoPublic,
} from '@infrastructure/Issues/models/CreateIssueDto';
import {
  IssueEvent,
  IssueEventComment,
} from '@domain/Issues/models/IssueEvent';
import { transformIssueDetailsToEditIssueDto } from '@infrastructure/Issues/transformers/transformIssueDetailsToEditIssueDto';
import { IPagedResponse } from '@repo/shared/types';
import { IssueContext } from '@domain/Issues/models/IssueContext';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import {
  CloseIssuesDto,
  ReopenIssuesDto,
} from '@infrastructure/Issues/models/ChangeIssuesStatusDto';
import { AssignUsersToIssuesDto } from '@infrastructure/Issues/models/AssignUsersToIssuesDto';
import { ExportType } from '@repo/shared/enums';
import { removeUndefinedAndNullProps } from '@utils';

class IssuesApiClient extends BaseApiClient {
  public async getIssues(page: IssuesPage | null, filters: IssuesFilters) {
    const pages = {
      [IssuesPage.My]: 'pending/my',
      [IssuesPage.Pending]: 'pending',
      [IssuesPage.Archive]: 'resolved',
    };

    return this._apiService.post<IPagedResponse<Issue>>({
      url: `issues/${pages[page ?? IssuesPage.Pending]}`,
      body: filters,
    });
  }

  public async createIssue(issue: CreateIssueDto) {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `issues/create`,
      body: issue,
    });

    return id;
  }

  public async createIssuePublic(
    companyId: string,
    issue: CreateIssueDtoPublic
  ) {
    const { id, number } = await this._apiService.post<{
      id: string;
      number: string;
    }>({
      url: `issues/public`,
      body: issue,
      companyId,
    });

    return {
      id,
      number: parseInt(number),
    };
  }

  public async editIssue(issueDetails: IssueDetails) {
    await this._apiService.put({
      url: `issues/${issueDetails.id}`,
      body: transformIssueDetailsToEditIssueDto(issueDetails),
    });
  }

  public async deleteIssues(ids: string[]) {
    await this._apiService.delete({
      url: `issues`,
      body: {
        ids,
      },
    });
  }

  public async getIssueDetails(id: string) {
    return this._apiService.get<IssueDetails>({
      url: `issues/${id}/details`,
    });
  }

  public async getIssueEvents(id: string) {
    return this._apiService.get<IssueEvent[]>({
      url: `issues/${id}/events`,
    });
  }

  public async getNewIssueEvents(
    issueId: string,
    lastUpdateDateTimeUtc: string
  ) {
    return this._apiService.post<IssueEvent[]>({
      url: `issues/${issueId}/events/new`,
      body: {
        updatedAtUtc: lastUpdateDateTimeUtc,
      },
    });
  }

  public async openIssues(payload: ReopenIssuesDto) {
    return this._apiService.post({
      url: `issues/open`,
      body: {
        ...payload,
      },
    });
  }

  public async closeIssues(payload: CloseIssuesDto) {
    return this._apiService.post({
      url: `issues/close`,
      body: {
        ...payload,
      },
    });
  }

  public async createIssueCommentEvent(
    issueId: string,
    event: IssueEventComment
  ) {
    return this._apiService.post({
      url: `issues/${issueId}/events/comment`,
      body: {
        ...event,
      },
    });
  }

  public async getEventsUpdatesToken(deviceId: string, issueId: string) {
    return this._apiService.post<string>({
      url: `issues/${issueId}/events/register`,
      body: {
        deviceId,
      },
    });
  }

  public async getIssueContextPublic(
    companyId: string,
    issueTypeQrCodeId: string
  ) {
    return this._apiService.get<IssueContext>({
      url: `issues/public/context/${issueTypeQrCodeId}`,
      companyId,
    });
  }

  public async assignUsersToIssues(payload: AssignUsersToIssuesDto) {
    return this._apiService.post({
      url: `issues/assign`,
      body: {
        ...payload,
      },
    });
  }

  public async exportIssues(
    page: IssuesPage | null,
    exportType: ExportType,
    filters: IssuesFilters
  ): Promise<ArrayBuffer> {
    const pagePaths: Record<IssuesPage, string> = {
      [IssuesPage.My]: 'pending/my',
      [IssuesPage.Pending]: 'pending',
      [IssuesPage.Archive]: 'resolved',
    };

    const exportTypePaths: Record<ExportType, string> = {
      [ExportType.Csv]: 'export-csv',
      [ExportType.Pdf]: 'export-pdf',
      [ExportType.Xls]: 'export-xls',
    };

    const { data } = await this._apiService.post<{ data: ArrayBuffer }>({
      responseType: 'arraybuffer',
      url: `issues/${pagePaths[page ?? IssuesPage.Pending]}/${exportTypePaths[exportType]}`,
      body: removeUndefinedAndNullProps(filters),
      withHeaders: true,
    });

    return data;
  }
}

export default IssuesApiClient;
