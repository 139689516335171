import { createAction, createAsyncThunk, Update } from '@reduxjs/toolkit';

import { IPagedResponse } from '@repo/shared/types';
import IssuesApiClient from '@infrastructure/Issues/api/IssuesApiClient';
import { Logger } from '@repo/shared/services';
import { date, dateUTC } from '@utils';
import {
  getExportFileMetaData,
  saveFile,
  getErrorMessage,
} from '@repo/shared/utils';
import { Issue, IssueDetails } from '@domain/Issues/models/Issue';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { IssuesFilters } from '@application/Issues/models/IssuesFilters';
import { issuesSelectors } from '@application/Issues/store/issuesSelectors';
import {
  CreateIssueDto,
  CreateIssueDtoPublic,
} from '@infrastructure/Issues/models/CreateIssueDto';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import {
  IssueEvent,
  IssueEventComment,
} from '@domain/Issues/models/IssueEvent';
import { IssueContext } from '@domain/Issues/models/IssueContext';
import {
  CloseIssuesDto,
  ReopenIssuesDto,
} from '@infrastructure/Issues/models/ChangeIssuesStatusDto';
import { AssignUsersToIssuesModalState } from '@application/Issues/models/AssignUsersToIssuesModalState';
import { IRootState } from '@src/core/frameworks/redux';
import { ApiConflictError } from '@repo/shared/errors';
import { IssuesApiErrorCodes } from '@infrastructure/Issues/api/IssuesApiErrorCodes';
import { ExportType } from '@repo/shared/enums';
import { config } from '@repo/shared/config';
import { accountSelectors } from '@store';

const issuesApiClient = new IssuesApiClient();

const setPage = createAction<IssuesPage>('issues/setPage');

const getIssues = createAsyncThunk<
  IPagedResponse<Issue>,
  Partial<IssuesFilters> | null | undefined,
  { rejectValue: string }
>('issues/getIssues', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const page = issuesSelectors.getPage(state);
    const filters = {
      ...issuesSelectors.getFilters(state),
    };

    return await issuesApiClient.getIssues(page, filters);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleFiltersModal = createAction<boolean>('issues/toggleFiltersModal');

const toggleCreateIssueModal = createAction<boolean>(
  'issues/toggleCreateIssueModal'
);

const createIssue = createAsyncThunk<
  string,
  { createIssueDto: CreateIssueDto }
>('issues/createIssue', async ({ createIssueDto }, { rejectWithValue }) => {
  try {
    return await issuesApiClient.createIssue(createIssueDto);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const createIssuePublic = createAsyncThunk<
  { id: string; number: number },
  { createIssueDto: CreateIssueDtoPublic; companyId: string }
>(
  'issues/createIssue',
  async ({ createIssueDto, companyId }, { rejectWithValue }) => {
    try {
      return await issuesApiClient.createIssuePublic(companyId, createIssueDto);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const editIssue = createAsyncThunk<IssueDetails, IssueDetails>(
  'issues/editIssue',
  async (issueDetails, { rejectWithValue }) => {
    try {
      await issuesApiClient.editIssue(issueDetails);
      return issueDetails;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteIssues = createAsyncThunk<void, string[]>(
  'issues/deleteIssues',
  async (ids, { rejectWithValue }) => {
    try {
      await issuesApiClient.deleteIssues(ids);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleConfirmDeleteIssuesModal = createAction<DeleteModalState<Issue>>(
  'issues/confirmDeleteIssuesModal'
);

const toggleIssueDetailsModal = createAction<string | null>(
  'issues/toggleIssueDetailsModal'
);

const getIssueDetails = createAsyncThunk<
  IssueDetails,
  { issueId: string; showLoader?: boolean },
  { rejectValue: string }
>('issues/getIssueDetails', async ({ issueId }, { rejectWithValue }) => {
  try {
    return await issuesApiClient.getIssueDetails(issueId);
  } catch (e) {
    Logger.captureException(e);

    if (
      e instanceof ApiConflictError &&
      e.message === IssuesApiErrorCodes.noAccessOrNotFound
    ) {
      return rejectWithValue(IssuesApiErrorCodes.noAccessOrNotFound);
    }

    return rejectWithValue(getErrorMessage(e));
  }
});

const getIssueEvents = createAsyncThunk<
  IssueEvent[],
  string,
  { rejectValue: string }
>('issues/getIssueEvents', async (id, { rejectWithValue }) => {
  try {
    return await issuesApiClient.getIssueEvents(id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getNewIssueEvents = createAsyncThunk<
  IssueEvent[],
  string,
  { rejectValue: string }
>('issues/getNewIssueEvents', async (id, { rejectWithValue, getState }) => {
  try {
    const lastUpdateDateTimeUtc =
      issuesSelectors.getEventsLastUpdateDateTime(getState());

    return await issuesApiClient.getNewIssueEvents(
      id,
      lastUpdateDateTimeUtc || dateUTC().toISOString()
    );
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleReopenIssuesModal = createAction<null | string[]>(
  'issues/toggleReopenIssuesModal'
);

const toggleCloseIssuesModal = createAction<null | string[]>(
  'issues/toggleCloseIssuesModal'
);

const reopenIssues = createAsyncThunk<
  ReopenIssuesDto,
  ReopenIssuesDto,
  { rejectValue: string }
>('issues/reopenIssues', async (payload, { rejectWithValue }) => {
  try {
    await issuesApiClient.openIssues(payload);
    return payload;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const closeIssues = createAsyncThunk<
  CloseIssuesDto,
  CloseIssuesDto,
  { rejectValue: string }
>('issues/closeIssues', async (payload, { rejectWithValue }) => {
  try {
    await issuesApiClient.closeIssues(payload);
    return payload;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const createIssueCommentEvent = createAsyncThunk<
  void,
  { issueId: string; event: IssueEventComment },
  { rejectValue: string }
>(
  'issues/createIssueComment',
  async ({ issueId, event }, { rejectWithValue }) => {
    try {
      await issuesApiClient.createIssueCommentEvent(issueId, event);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getIssueContext = createAsyncThunk<
  IssueContext,
  { companyId: string; issueTypeQrCodeId: string },
  { rejectValue: string }
>(
  'issues/getIssueContext',
  async ({ companyId, issueTypeQrCodeId }, { rejectWithValue }) => {
    try {
      return await issuesApiClient.getIssueContextPublic(
        companyId,
        issueTypeQrCodeId
      );
    } catch (e) {
      if (
        e instanceof ApiConflictError &&
        e.message === 'issue-type-public-link/not-found'
      ) {
        return rejectWithValue('issue-type-public-link/not-found');
      }

      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const resetIssueDetailsData = createAction('issues/resetIssueDetailsData');

const resetListData = createAction('issues/resetIssuesData');

const toggleAssignUsersToIssuesModal =
  createAction<AssignUsersToIssuesModalState>(
    'actions/toggleAssignUsersToActionsModal'
  );

const assignUsersToIssues = createAsyncThunk<
  void,
  {
    usersIds: string[];
    issuesIds: string[];
  },
  { state: IRootState }
>(
  'actions/assignUsersToIssues',
  async ({ usersIds, issuesIds }, { rejectWithValue }) => {
    try {
      await issuesApiClient.assignUsersToIssues({
        usersIds,
        issuesIds,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const exportIssues = createAsyncThunk<void, ExportType, { state: IRootState }>(
  'issues/exportIssues',
  async (exportType, { getState, rejectWithValue }) => {
    try {
      const state = getState();

      const data = await issuesApiClient.exportIssues(
        issuesSelectors.getPage(state),
        exportType,
        issuesSelectors.getFilters(state)
      );

      const { extension, mimeType } = getExportFileMetaData(exportType);

      saveFile({
        data,
        mimeType,
        fileName: `All Issues - ${date().format(
          config.dateFormat
        )}.${extension}`,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteIssueLocally = createAction<string>('issues/deleteIssueLocally');

const updateIssueLocally = createAction<Update<Issue, string>>(
  'issues/updateIssueLocally'
);

export const issuesActions = {
  setPage,
  toggleIssueDetailsModal,
  getIssues,
  createIssue,
  createIssuePublic,
  editIssue,
  deleteIssues,
  toggleFiltersModal,
  toggleCreateIssueModal,
  toggleConfirmDeleteIssuesModal,
  getIssueDetails,
  getNewIssueEvents,
  getIssueEvents,
  reopenIssues,
  closeIssues,
  toggleReopenIssuesModal,
  toggleCloseIssuesModal,
  createIssueCommentEvent,
  getIssueContext,
  resetIssueDetailsData,
  toggleAssignUsersToIssuesModal,
  assignUsersToIssues,
  resetListData,
  exportIssues,
  deleteIssueLocally,
  updateIssueLocally,
};
