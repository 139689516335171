import { BaseApiClient } from '@repo/shared/api';

import { HistoryAudit } from '../types/HistoryAudit';
import { HistoryItem, HistoryItemDetails } from '../types/HistoryItem';
import { AuditDetails } from '../types/AuditDetails';

export class TrendsApiClient extends BaseApiClient {
  public async getHistoryAudits(auditId: string) {
    return this._apiService.get<HistoryAudit[]>({
      url: `audit/all/${auditId}/history`,
    });
  }

  public async getHistoryItems(auditId: string, itemId: string) {
    return this._apiService.get<HistoryItem[]>({
      url: `audit/all/${auditId}/history/items/${itemId}/list`,
    });
  }

  public async getHistoryItemDetails(auditId: string, templateItemId: string) {
    return this._apiService.get<HistoryItemDetails>({
      url: `audit/all/${auditId}/history/items/${templateItemId}/detailed`,
    });
  }

  public async getAuditDetails(auditId: string) {
    return this._apiService.get<AuditDetails>({
      url: `audit/all/pending/${auditId}`,
    });
  }
}
