import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Action } from '../../../shared/ActionsModal/styled';
import { generalSelectors, usersSelectors, usersActions } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import ActionsModal from '../../../shared/ActionsModal/ActionsModal';

const UserGroupsActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const groups = useSelector(usersSelectors.groups.getMap);
  let group =
    selectedTableRowKeys.length === 1 ? groups[selectedTableRowKeys[0]] : null;

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <>
              <Action
                onClick={() => {
                  if (group) {
                    navigate(
                      routes.users.groupDetails.replace(':id', group.id)
                    );
                  }

                  closeModal();
                }}
              >
                {formatMessage({ id: 'View' })}
              </Action>
              <Action
                onClick={() => {
                  if (group) {
                    dispatch(
                      usersActions.groups.toggleAddEditGroupModal({
                        visible: true,
                        groupId: group.id,
                      })
                    );
                  }

                  closeModal();
                }}
              >
                {formatMessage({ id: 'Edit' })}
              </Action>
            </>
          )}
          <Action
            onClick={() =>
              dispatch(
                usersActions.groups.toggleConfirmDeleteGroupsModal(
                  selectedTableRowKeys
                )
              )
            }
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default UserGroupsActionsModal;
