import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: ${({ inline }: { inline?: boolean }) =>
    inline ? 'inline-flex' : 'flex'};
  align-items: center;
`;

interface IProps {
  size?: number;
}

export const Initials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }: IProps) => size || 22}px;
  width: ${({ size }: IProps) => size || 22}px;
  font-size: ${({ size }: IProps) => (size ? size * 0.45 : 10)}px;
  border-radius: 100%;
  text-align: center;
  color: ${colors.white};
  margin-right: 5px;
  background: ${colors.blue1};
  flex-shrink: 0;
`;

export const Name = styled.div`
  text-align: left;
  line-height: 1.2;
  margin-left: 5px;
`;
