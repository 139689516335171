import React from 'react';

import { TagType } from '@repo/shared/enums';

import Tags from './Tags';

const AuditsTags: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <Tags page={TagType.Audit} />;
};

export default AuditsTags;
