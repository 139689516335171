import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Container, Main, Content, Title, Buttons } from './styled';
import { accountSelectors, accountActions } from '@store';
import { useAppDispatch } from '@hooks';

import FullScreenModal from '@repo/shared/components/FullScreenModal/FullScreenModal';
import Scrollbar from '../../shared/Scrollbar/Scrollbar';
import Footer from '../../shared/Footer/Footer';
import SetupCompanyForm from '@components/account/AuthForms/SignUp/SetupCompanyForm';
import Button from '@components/shared/ant/Button';

const Setup: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const isVisible = useSelector(accountSelectors.isSetupCompanyModalVisible);
  const currentUser = useSelector(accountSelectors.getCurrentUser);

  return (
    <FullScreenModal
      opened={isVisible}
      noOpeningAnimation={currentUser === null}
    >
      <Container>
        <Content>
          <Scrollbar>
            <Main>
              <Title level={4}>{formatMessage({ id: 'SetupCompany' })}</Title>
              <SetupCompanyForm>
                {({ form, loading }) => (
                  <Buttons>
                    <Button
                      type="default"
                      disabled={loading}
                      onClick={() => {
                        form.resetFields();
                        dispatch(accountActions.toggleSetupCompanyModal(false));
                      }}
                    >
                      {formatMessage({ id: 'CancelSetup' })}
                    </Button>
                    <Button
                      type="primary"
                      disabled={loading}
                      loading={loading}
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      {formatMessage({ id: 'Create' })}
                    </Button>
                  </Buttons>
                )}
              </SetupCompanyForm>
            </Main>
          </Scrollbar>
        </Content>
        <Footer hasBackground />
      </Container>
    </FullScreenModal>
  );
};

export default Setup;
