import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { TooltipPlacement } from 'antd/es/tooltip';

import { SelfGuidedTourStep } from '@repo/shared/enums';
import {
  Spotlight,
  Content,
  Footer,
  Header,
  Icon,
  Title,
  NextBtn,
  CancelBtn,
  Text,
  Popover,
} from './styled';
import { useSelfGuidedTourStepData } from '../useSelfGuidedTourData';
import { e2eTestElements } from '@config';
import { delay } from '@repo/shared/utils';
import { ISpotlightBounds } from '@repo/shared/types';

import TourPopoverMeasure from '@components/account/SelfGuidedTour/TourPopover/TourPopoverMeasure';

interface IProps {
  step: SelfGuidedTourStep;
  visible: boolean;
  width?: number;
  children: React.ReactNode;
  placement?: TooltipPlacement;
  overlayStyle?: {
    marginTop?: number;
    marginLeft?: number;
    height?: number;
    width?: number;
  };
  scrollToSpotlight?: boolean;
  scrollTo?: number;
  disabledNextBtn?: boolean;
  align?: any;
  measureDelay?: number;
  loading: boolean;
  setSpotlightBoundsInStore: (bounds: ISpotlightBounds | null) => void;
  onCancel: () => void;
  onNextStep: () => void;
}

const TourPopoverInner: React.ComponentType<
  React.PropsWithChildren<IProps>
> = ({
  step,
  children,
  visible,
  placement,
  width = 284,
  overlayStyle,
  scrollToSpotlight,
  disabledNextBtn,
  scrollTo,
  align,
  measureDelay = 0,
  setSpotlightBoundsInStore,
  onCancel,
  onNextStep,
}) => {
  const { formatMessage } = useIntl();

  const stepData = useSelfGuidedTourStepData(step);
  const spotlightRef = useRef<HTMLDivElement | null>(null);
  const [measureAllowed, setMeasureAllowed] = useState(measureDelay === 0);
  const [popupVisible, setPopupVisible] = useState(false);
  const [spotlightBounds, setSpotlightBounds] =
    useState<ISpotlightBounds | null>(null);

  useEffect(() => {
    if (visible && measureDelay) {
      setTimeout(() => {
        setMeasureAllowed(true);
      }, measureDelay);
    }
  }, [visible]);

  useEffect(() => {
    async function initPopup() {
      if (spotlightBounds) {
        setSpotlightBoundsInStore(spotlightBounds);

        await delay(100);

        setPopupVisible(true);

        if (scrollTo !== undefined) {
          window.scrollTo({
            top: scrollTo,
            behavior: 'smooth',
          });
        } else if (scrollToSpotlight && spotlightRef.current) {
          spotlightRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    }

    initPopup();
  }, [JSON.stringify(spotlightBounds || {})]);

  const { icon, title, text } = stepData;

  return (
    <>
      {visible &&
        !!spotlightBounds &&
        ReactDOM.createPortal(
          <Popover
            align={align}
            open={popupVisible}
            placement={placement}
            destroyTooltipOnHide
            title={
              <Header>
                <Icon src={icon} />
                <Title
                  data-e2e={e2eTestElements.account.selfGuidedTour.stepTitle}
                >
                  {title}
                </Title>
              </Header>
            }
            overlayClassName="self-guided-tour-popover"
            overlayStyle={{
              width,
              zIndex: 2100,
            }}
            content={
              <Content>
                <Text
                  data-e2e={e2eTestElements.account.selfGuidedTour.stepDesc}
                >
                  {text}
                </Text>
                <Footer>
                  <CancelBtn
                    type="button"
                    data-e2e={
                      e2eTestElements.account.selfGuidedTour.cancelTourBtn
                    }
                    onClick={() => {
                      onCancel();
                    }}
                  >
                    {formatMessage({ id: 'CancelTour' })}
                  </CancelBtn>
                  <NextBtn
                    type="primary"
                    size="small"
                    data-e2e={
                      e2eTestElements.account.selfGuidedTour.nextStepBtn
                    }
                    disabled={disabledNextBtn}
                    onClick={() => {
                      onNextStep();
                    }}
                  >
                    {formatMessage({ id: 'Next' })}
                  </NextBtn>
                </Footer>
              </Content>
            }
          >
            <Spotlight ref={spotlightRef} style={spotlightBounds} />
          </Popover>,
          document.getElementById('self-guided-tour-overlay') || document.body
        )}
      {visible && (measureDelay === 0 || measureAllowed) ? (
        <TourPopoverMeasure
          onMeasure={(bounds) => {
            setSpotlightBounds(bounds);
          }}
          overlayStyle={overlayStyle}
        >
          {children}
        </TourPopoverMeasure>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default TourPopoverInner;
