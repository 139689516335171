import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  padding: 50px 30px 40px 40px;
  width: 490px;
`;

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 28px;
`;

export const Step = styled.div`
  position: relative;
  margin-bottom: 24px;
  padding-left: 25px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 9px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${colors.blue2};
  }
`;

export const StepTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const StepDescription = styled.div`
  font-size: 12px;
`;

export const Divider = styled.div`
  width: 124px;
  height: 1px;
  background: ${colors.gray6};
  margin: 50px 0 30px;
`;

export const Disclaimer = styled.div`
  font-size: 11px;
  color: ${colors.gray8};

  p {
    margin-bottom: 8px;
  }
`;
