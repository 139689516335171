import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { scoresActions, scoresSelectors, generalSelectors } from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';

import ConfirmDeleteModal from '../shared/ConfirmDeleteModal/ConfirmDeleteModal';
import { getTablePageNumberAfterDeletion } from '@utils';

const DeleteScoresModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const scores = useSelector(scoresSelectors.getScoresLookup);
  const { meta } = useSelector(scoresSelectors.getScores);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  return (
    <ConfirmDeleteModal
      text={{
        title: formatMessage(
          { id: 'DeleteScore' },
          { itemCount: selectedTableRowKeys.length }
        ),
        error: formatMessage(
          { id: 'ErrorWhileDeletingScore' },
          { itemCount: selectedTableRowKeys.length }
        ),
        desc: formatMessage(
          { id: 'YouAreAboutToRemoveScoreFromSystem' },
          { itemCount: selectedTableRowKeys.length }
        ),
      }}
      onSuccess={() => {
        dispatch(
          scoresActions.getScores({
            pageNumber: getTablePageNumberAfterDeletion(meta),
          })
        );
      }}
      onSubmit={async (ids) => {
        const resultAction = await dispatch(scoresActions.deleteScore(ids));

        if (scoresActions.deleteScore.rejected.match(resultAction)) {
          const hasTemplatesErrorCode = 'score-system/has-templates';

          if (
            typeof resultAction.payload === 'string' &&
            resultAction.payload.includes(hasTemplatesErrorCode)
          ) {
            const idsStr = resultAction.payload.split(
              `${hasTemplatesErrorCode}:`
            )?.[1];
            const idsArr = idsStr ? idsStr.split(',') : [];

            notification.error({
              message: formatMessage({
                id: 'AnErrorOccurredWhileDeletingScore',
              }),
              description:
                idsArr.length > 0
                  ? formatMessage(
                      {
                        id: 'ScoreSystemIsUsedInTemplates',
                      },
                      {
                        scoresNames: idsArr
                          .map((id) => `"${scores[id]?.name}"`)
                          .join(', '),
                        count: idsArr.length,
                      }
                    )
                  : null,
            });
          }
        }
      }}
      data={scores}
    />
  );
};

export default DeleteScoresModal;
