import React from 'react';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';

import { ScheduleFormValues } from '@application/Schedules/models/ScheduleFormValues';
import { ExpirationType } from '@domain/Schedules/enums/ExpirationType';

import Form from '@components/shared/ant/Form';
import Select from '@components/shared/ant/Select/Select';
import ExpiredAfterDaysInput from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/Expiration/ExpiredAfterDaysInput';

const { Option } = AntSelect;

interface IProps {
  disabled?: boolean;
}

const Expiration: React.FC<IProps> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Form.Item
        name={['repeat', 'auditsExpirationType']}
        label={formatMessage({ id: 'AuditsExpiration' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseChooseAuditsExpiration' }),
          },
        ]}
      >
        <Select disabled={disabled}>
          <Option value={ExpirationType.NeverAutoExpire}>
            {formatMessage({ id: 'AuditNeverAutoExpiresByDefault' })}
          </Option>
          <Option value={ExpirationType.ExpireImmediatelyAfterDueDate}>
            {formatMessage({ id: 'AuditExpiresImmediatelyAfterDueDate' })}
          </Option>
          <Option value={ExpirationType.ExpireXDaysAfterDueDate}>
            {formatMessage({ id: 'AuditExpiresXDaysAfterDueDate' })}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item<ScheduleFormValues>
        shouldUpdate={(prevValues, nextValues) =>
          prevValues.repeat.auditsExpirationType !==
          nextValues.repeat.auditsExpirationType
        }
        noStyle
      >
        {({ getFieldValue }) => {
          if (
            getFieldValue(['repeat', 'auditsExpirationType']) ===
            ExpirationType.ExpireXDaysAfterDueDate
          ) {
            return (
              <ExpiredAfterDaysInput
                disabled={disabled}
                fieldName="auditsExpireAfterDays"
              />
            );
          }

          return null;
        }}
      </Form.Item>
      <Form.Item
        name={['repeat', 'actionsExpirationType']}
        label={formatMessage({ id: 'ActionsExpiration' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseChooseActionsExpiration' }),
          },
        ]}
      >
        <Select disabled={disabled}>
          <Option value={ExpirationType.NeverAutoExpire}>
            {formatMessage({ id: 'ActionsNeverAutoExpireByDefault' })}
          </Option>
          <Option value={ExpirationType.ExpireImmediatelyAfterDueDate}>
            {formatMessage({ id: 'ActionExpiresImmediatelyAfterDueDate' })}
          </Option>
          <Option value={ExpirationType.ExpireXDaysAfterDueDate}>
            {formatMessage({ id: 'ActionsExpireXDaysAfterDueDate' })}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item<ScheduleFormValues>
        shouldUpdate={(prevValues, nextValues) =>
          prevValues.repeat.actionsExpirationType !==
          nextValues.repeat.actionsExpirationType
        }
        noStyle
      >
        {({ getFieldValue }) => {
          if (
            getFieldValue(['repeat', 'actionsExpirationType']) ===
            ExpirationType.ExpireXDaysAfterDueDate
          ) {
            return (
              <ExpiredAfterDaysInput
                disabled={disabled}
                fieldName="actionsExpireAfterDays"
              />
            );
          }

          return null;
        }}
      </Form.Item>
    </>
  );
};

export default Expiration;
