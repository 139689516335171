import React from 'react';
import Spin from 'antd/es/spin';

import { ErrorIcon, PendingIcon, SuccessIcon } from './styled';
import { UploadStatus } from '@repo/shared/enums';

interface IProps {
  status: UploadStatus;
}

const UploadStatusIcon: React.FC<React.PropsWithChildren<IProps>> = ({
  status,
}) => {
  if (status === UploadStatus.Done) {
    return <SuccessIcon />;
  }

  if (status === UploadStatus.Error) {
    return <ErrorIcon />;
  }

  if (status === UploadStatus.Pending) {
    return <PendingIcon />;
  }

  return <Spin size="small" spinning />;
};

export default UploadStatusIcon;
