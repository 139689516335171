import { createReducer } from '@reduxjs/toolkit';

import {
  AuditTemplateBuilderMode,
  AuditTemplateBuilderState,
  SetupFormValidationStatus,
} from '@application/AuditTemplateBuilder/models/AuditTemplateBuilderState';
import { auditTemplateBuilderActions as actions } from '@application/AuditTemplateBuilder/store/auditTemplateBuilderActions';
import { UnexpectedError } from '@repo/shared/errors';
import { ItemType } from '@repo/shared/enums';
import { convertArrayToHashMap, getChildrenIds } from '@utils';
import { ZERO_UUID } from '@config';

const initialState: AuditTemplateBuilderState = {
  mode: AuditTemplateBuilderMode.Setup,
  template: null,
  rootItemId: null,
  loading: false,
  error: null,
  dragItem: null,
  validationStatus: SetupFormValidationStatus.Idle,
  expanded: {
    sections: {},
    answers: {},
  },
  confirmDeleteItem: {
    entityId: null,
    itemType: null,
  },
  openedSectionId: null,
  sectionVisibilityModalId: null,
  saveChangesModal: {
    show: false,
  },
  showPublishChangesModal: false,
  showConfirmTemplateDeleteModal: false,
  showEditAsDraftModal: false,
  showCreateDraftCopyModal: false,
  isInformationPhotosUploading: false,
  answerValidation: {
    errors: {} as Record<string, Record<string, string>>,
  },
  editIntervalsModal: null,
  itemsByActionTemplateModalTemplateId: null,
  scrollItemId: null,
};

export const auditTemplateBuilderReducer =
  createReducer<AuditTemplateBuilderState>(initialState, (builder) =>
    builder
      .addCase(actions.loadBuilder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        actions.loadBuilder.fulfilled,
        (state, { payload: { template, rootItemId } }) => {
          state.loading = false;
          state.template = template;
          state.rootItemId = rootItemId;
        }
      )
      .addCase(actions.loadBuilder.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload || new UnexpectedError().message;
      })
      .addCase(actions.saveTemplate.fulfilled, (state, { payload }) => {
        if (state.template) {
          state.template.hasChanges = false;

          if (state.template.id === ZERO_UUID && payload) {
            state.template.id = payload;
          }
        }
      })
      .addCase(actions.publishDraft.fulfilled, (state) => {
        if (state.template) {
          state.template.hasChanges = false;
        }
      })
      .addCase(actions.startDrag, (state, { payload }) => {
        state.dragItem = payload;
      })
      .addCase(actions.stopDrag, (state) => {
        state.dragItem = null;
      })
      .addCase(actions.addTemplateItem, (state, { payload }) => {
        if (!state.template) {
          return;
        }

        const { index, parentId, id, itemType } = payload;

        state.template.hasChanges = true;

        if (itemType !== ItemType.Root && parentId !== null) {
          state.template.data[parentId].childrenIds.splice(index, 0, id);
        }

        if (itemType === ItemType.Root) {
          state.rootItemId = id;
        }

        state.template.data[id] = {
          ...payload,
          childrenIds: [],
        };

        if (parentId !== null) {
          for (
            let i = 0;
            i < state.template.data[parentId].childrenIds.length;
            i++
          ) {
            const id = state.template.data[parentId].childrenIds[i];
            state.template.data[id].index = i;
          }
        }
      })
      .addCase(actions.deleteTemplateItem, (state, { payload: itemId }) => {
        if (!state.template) {
          return;
        }

        const item = state.template.data[itemId];

        if (!item || item.parentId === null) {
          return;
        }

        const parent = state.template.data[item.parentId];
        const index = parent.childrenIds.indexOf(itemId);
        const { parentId, childrenIds, itemType } = item;

        state.template.hasChanges = true;

        if (itemType === ItemType.Section) {
          if (state.expanded.sections[itemId]) {
            delete state.expanded.sections[itemId];
          }
        } else {
          if (state.expanded.answers[itemId]) {
            delete state.expanded.answers[itemId];
          }
        }

        state.template.data[parentId].childrenIds.splice(index, 1);

        for (
          let i = 0;
          i < state.template.data[parentId].childrenIds.length;
          i++
        ) {
          const id = state.template.data[parentId].childrenIds[i];
          state.template.data[id].index = i;
        }

        let idsToDelete = [itemId, ...childrenIds];

        let currentLevelIds = [...childrenIds];

        while (currentLevelIds.length > 0) {
          let nextLevelIds: string[] = [];

          for (let i = 0; i < currentLevelIds.length; i++) {
            const childChildrenIds =
              state.template.data[currentLevelIds[i]].childrenIds;
            idsToDelete = idsToDelete.concat(childChildrenIds);
            nextLevelIds = nextLevelIds.concat(childChildrenIds);
          }

          currentLevelIds = nextLevelIds;
        }

        for (let i = 0; i < idsToDelete.length; i++) {
          delete state.template.data[idsToDelete[i]];
        }
      })
      .addCase(
        actions.updateTemplateItem,
        (state, { payload: { entityId, update } }) => {
          if (!state.template) {
            return;
          }

          state.template.hasChanges = true;

          if (
            (state.template.data[entityId].itemType ===
              ItemType.ConditionalItem &&
              update.answerType !== undefined) ||
            (state.template.data[entityId].itemType ===
              ItemType.ConditionalItem &&
              update.itemType !== undefined &&
              update.itemType !== ItemType.ConditionalItem)
          ) {
            const childrenIds = getChildrenIds(
              state.template.data,
              state.template.data[entityId].childrenIds
            );

            for (let i = 0; i < childrenIds.length; i++) {
              const id = childrenIds[i];

              if (state.template.data[id]) {
                delete state.template.data[id];
              }
            }

            update.childrenIds = [];
          }

          state.template.data[entityId] = {
            ...state.template.data[entityId],
            ...update,
          };
        }
      )
      .addCase(actions.dragTemplateItem, (state, { payload }) => {
        const { nextParentId, nextIndex } = payload;

        if (state.dragItem === null || state.template === null) {
          return;
        }

        const { index: prevIndex, parentId: prevParentId } = state.dragItem;

        if (nextParentId === prevParentId && nextIndex === prevIndex) {
          return;
        }

        const [id] = state.template.data[prevParentId].childrenIds.splice(
          prevIndex,
          1
        );

        state.template.data[nextParentId].childrenIds.splice(nextIndex, 0, id);

        for (
          let i = 0;
          i < state.template.data[nextParentId].childrenIds.length;
          i++
        ) {
          const id = state.template.data[nextParentId].childrenIds[i];
          state.template.data[id].index = i;
        }

        if (prevParentId !== nextParentId) {
          state.template.data[id].parentId = nextParentId;

          for (
            let i = 0;
            i < state.template.data[prevParentId].childrenIds.length;
            i++
          ) {
            const id = state.template.data[prevParentId].childrenIds[i];
            state.template.data[id].index = i;
          }
        }

        state.dragItem.parentId = nextParentId;
        state.dragItem.index = nextIndex;

        state.template.hasChanges = true;
      })
      .addCase(actions.moveTemplateSection, (state, { payload }) => {
        if (state.template === null) {
          return;
        }

        const rootItemId = state.rootItemId;
        const { nextIndex, sectionId } = payload;
        const { parentId } = state.template.data[sectionId];

        if (parentId === null) {
          return;
        }

        const prevIndex =
          state.template.data[parentId].childrenIds.indexOf(sectionId);
        const targetId = parentId || rootItemId;

        if (targetId) {
          state.template.data[targetId].childrenIds.splice(
            nextIndex,
            0,
            ...state.template.data[targetId].childrenIds.splice(prevIndex, 1)
          );

          for (
            let i = 0;
            i < state.template.data[targetId].childrenIds.length;
            i++
          ) {
            const id = state.template.data[targetId].childrenIds[i];
            state.template.data[id].index = i;
          }

          state.template.hasChanges = true;
        }
      })
      .addCase(actions.changeBuilderMode, (state, { payload }) => {
        state.mode = payload;
      })
      .addCase(actions.updateTemplate, (state, { payload }) => {
        if (state.template) {
          state.template = {
            ...state.template,
            ...payload,
            hasChanges: true,
          };
        }
      })
      .addCase(actions.showDeleteEntityConfirmModal, (state, { payload }) => {
        state.confirmDeleteItem = payload;
      })
      .addCase(actions.toggleSections, (state, { payload: { ids, show } }) => {
        if (show) {
          state.expanded.sections = {
            ...state.expanded.sections,
            ...convertArrayToHashMap(ids),
          };
        } else {
          for (let i = 0; i < ids.length; i++) {
            delete state.expanded.sections[ids[i]];
          }
        }
      })
      .addCase(actions.toggleAnswerSettings, (state, { payload: { ids } }) => {
        state.expanded.answers = convertArrayToHashMap(ids);
      })
      .addCase(
        actions.openSection.fulfilled,
        (state, { payload: sectionId }) => {
          state.openedSectionId = sectionId;
        }
      )
      .addCase(actions.openSectionsList.fulfilled, (state) => {
        state.openedSectionId = null;
      })
      .addCase(actions.toggleSectionSettingsModal, (state, { payload }) => {
        state.sectionVisibilityModalId = payload;
      })
      .addCase(actions.toggleSaveChangesModal, (state, { payload }) => {
        state.saveChangesModal = payload;
      })
      .addCase(actions.togglePublishChangesModal, (state, { payload }) => {
        state.showPublishChangesModal = payload;
      })
      .addCase(actions.setInformationPhotosUploading, (state, { payload }) => {
        state.isInformationPhotosUploading = payload;
      })
      .addCase(
        actions.answerValidation.validateOpenedAnswer.fulfilled,
        (state) => {
          if (Object.keys(state.answerValidation.errors).length > 0) {
            state.answerValidation.errors = {};
          }
        }
      )
      .addCase(
        actions.answerValidation.validateOpenedAnswer.rejected,
        (state, { payload }) => {
          if (payload) {
            state.answerValidation.errors = payload;
          }
        }
      )
      .addCase(
        actions.answerValidation.setErrors,
        (state, { payload: { answerId, errors } }) => {
          state.answerValidation.errors[answerId] = {
            ...(state.answerValidation.errors[answerId] || {}),
            ...errors,
          };
        }
      )
      .addCase(
        actions.answerValidation.unsetError,
        (state, { payload: { field, answerId } }) => {
          if (state.answerValidation.errors[answerId]?.[field]) {
            delete state.answerValidation.errors[answerId][field];

            if (
              Object.keys(state.answerValidation.errors[answerId]).length === 0
            ) {
              delete state.answerValidation.errors[answerId];
            }
          }
        }
      )
      .addCase(actions.toggleEditIntervalsModal, (state, { payload }) => {
        state.editIntervalsModal = payload;
      })
      .addCase(actions.setSetupFormValidationStatus, (state, { payload }) => {
        state.validationStatus = payload;
      })
      .addCase(
        actions.toggleItemsByActionTemplateModal,
        (state, { payload }) => {
          state.itemsByActionTemplateModalTemplateId = payload;
        }
      )
      .addCase(actions.goToItem.fulfilled, (state, { payload }) => {
        state.scrollItemId = payload || null;
      })
      .addCase(actions.resetScrollItem, (state) => {
        state.scrollItemId = null;
      })
      .addCase(actions.resetData, () => {
        return {
          ...initialState,
        };
      })
      .addCase(actions.toggleCreateDraftCopyModal, (state, { payload }) => {
        state.showCreateDraftCopyModal = payload;
      })
  );
