import React, { useEffect, useMemo, useState } from 'react';
import AntModal from 'antd/es/modal';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { Check } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Footer, SearchWrapper, UserListWrapper } from './styled';
import { RowButton } from './AssigneeOptionGroup/styled';
import { IParticipant, IConcise, IAssignee } from '@repo/shared/types';
import { e2eTestElements } from '@config';
import { generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { hasOwnProperty } from '@repo/shared/utils';

import SearchInput from '../../SeachInput/SearchInput';
import Scrollbar from '../../Scrollbar/Scrollbar';
import Button from '../../ant/Button';
import AssigneeOptionGroup from './AssigneeOptionGroup/AssigneeOptionGroup';

function filterList<T extends IConcise>(
  search: string,
  showJobTitles?: boolean
) {
  return (user: T) =>
    !search ||
    (search &&
      user.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) ||
    (showJobTitles &&
      hasOwnProperty(user, 'jobTitles') &&
      Array.isArray(user.jobTitles) &&
      user.jobTitles.find(({ name }) =>
        name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      ));
}

interface IProps {
  onSave?: (selected: IConcise[]) => Promise<any>;
  initial: IAssignee[];
  showJobTitles?: boolean;
}

const SelectAssigneeModal: React.FC<React.PropsWithChildren<IProps>> = ({
  onSave,
  initial,
  showJobTitles,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { loading, participants, users } = useSelector(
    generalSelectors.assigneesModal.getData
  );
  const show = useSelector(generalSelectors.assigneesModal.isVisible);

  const [search, setSearch] = useState('');
  const [selectedAssignees, setSelectedAssignees] = useState<IAssignee[]>([]);
  const [processing, setProcessing] = useState(false);

  function closeModal() {
    dispatch(generalActions.assigneesModal.toggle(false));

    setTimeout(() => {
      setSelectedAssignees([]);
      setSearch('');
    }, 300);
  }

  useEffect(() => {
    if (show) {
      setSelectedAssignees(initial);
    }
  }, [show]);

  const filteredParticipants = useMemo(
    () => participants.filter(filterList<IParticipant>(search, showJobTitles)),
    [search, participants]
  );
  const filteredUsers = useMemo(
    () => users.filter(filterList<IConcise>(search, showJobTitles)),
    [search, users]
  );

  return (
    <AntModal
      className="select-user-modal"
      wrapClassName="select-user-modal"
      open={show}
      closable={false}
      onCancel={() => closeModal()}
      destroyOnClose
      footer={
        <Footer>
          <Button
            type="link"
            data-e2e={e2eTestElements.common.assigneesModal.resetToDefaultBtn}
            onClick={() => {
              setSelectedAssignees(initial);
            }}
          >
            {formatMessage({ id: 'ResetToDefault' })}
          </Button>
          <Space>
            <Button
              type="default"
              data-e2e={e2eTestElements.common.assigneesModal.cancelBtn}
              onClick={() => {
                closeModal();
              }}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
            <Button
              type="primary"
              loading={processing}
              data-e2e={e2eTestElements.common.assigneesModal.saveBtn}
              onClick={async () => {
                if (onSave) {
                  setProcessing(true);

                  try {
                    await onSave(
                      selectedAssignees.map(({ id, name }) => ({ id, name }))
                    );

                    setProcessing(false);

                    closeModal();
                  } catch (e) {
                    setProcessing(false);
                  }
                }
              }}
            >
              {formatMessage({ id: 'Save' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <SearchWrapper>
        <SearchInput
          onChange={(search) => setSearch(search)}
          e2eDataAttr={e2eTestElements.common.assigneesModal.searchInput}
          width="100%"
          disabled={processing}
          placeholder={
            showJobTitles
              ? formatMessage({ id: 'SearchByNameOrJobTitle' })
              : undefined
          }
        />
      </SearchWrapper>
      <UserListWrapper
        data-e2e={e2eTestElements.common.assigneesModal.userListWrapper}
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Scrollbar>
            <RowButton
              type="button"
              key="unselected-button"
              disabled={processing}
              onClick={() => {
                setSelectedAssignees([]);
              }}
            >
              {formatMessage({ id: 'Unassigned' })}
              {selectedAssignees.length === 0 && <Check />}
            </RowButton>
            <AssigneeOptionGroup
              label={formatMessage({ id: 'Participants' })}
              options={filteredParticipants}
              selected={selectedAssignees}
              onSelect={(assignees) => setSelectedAssignees(assignees)}
              showJobTitles={showJobTitles}
            />
            <AssigneeOptionGroup
              label={formatMessage({ id: 'AllUsers' })}
              options={filteredUsers}
              selected={selectedAssignees}
              onSelect={(assignees) => setSelectedAssignees(assignees)}
              showJobTitles={false}
            />
          </Scrollbar>
        )}
      </UserListWrapper>
    </AntModal>
  );
};

export default SelectAssigneeModal;
