import styled from 'styled-components';

export const ListContainer = styled.div`
  margin-bottom: 15px;
`;

export const List = styled.ul`
  padding: 0 0 0 15px;
  margin: 0;
`;

export const Description = styled.div`
  margin: 15px 0 13px;
`;
