import React from 'react';
import { useIntl } from 'react-intl';
import { ChevronRight } from 'react-feather';

import {
  IssueEventAction,
  IssueEventActionType,
} from '@domain/Issues/models/IssueEvent';
import { Text, ExternalLink, DisabledLink, Icon, Name } from './styled';
import { usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements, routes } from '@config';

import ActionIcon from './ActionIcon';

const langIds = {
  [IssueEventActionType.Created]: 'CorrectiveActionHasBeenCreated',
  [IssueEventActionType.Approved]: 'CorrectiveActionHasBeenApproved',
  [IssueEventActionType.Deleted]: 'CorrectiveActionHasBeenDeleted',
  [IssueEventActionType.Expired]: 'CorrectiveActionHasBeenExpired',
  [IssueEventActionType.Rejected]: 'CorrectiveActionHasBeenRejected',
  [IssueEventActionType.MarkedAsDone]: 'CorrectiveActionHasBeenMarkedAsDone',
};

interface IProps {
  event: IssueEventAction;
}

const IssueDetailsEventAction: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  const { formatMessage } = useIntl();

  const [canViewActions] = usePermission([Permission.CanViewCorrectiveActions]);

  const linkContent = (
    <>
      <Icon>
        <ActionIcon />
      </Icon>
      <Name>{event.data.action.name}</Name>
    </>
  );

  return (
    <>
      {!!langIds[event.data.event] && (
        <Text data-e2e={e2eTestElements.issues.issues.details.eventText}>
          {formatMessage({ id: langIds[event.data.event] })}:
        </Text>
      )}
      {!canViewActions ? (
        <DisabledLink>{linkContent}</DisabledLink>
      ) : (
        <ExternalLink
          to={routes.pendingAction.replace(':id', event.data.action.id)}
          target="_blank"
        >
          {linkContent}
          <ChevronRight />
        </ExternalLink>
      )}
    </>
  );
};

export default IssueDetailsEventAction;
