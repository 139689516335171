import { createReducer, Reducer } from '@reduxjs/toolkit';

import { auditPerformanceReportActions as actions } from './auditPerformance-report.actions';
import { generalActions } from '../general/general.actions';
import { IAuditPerformanceReportState } from '@types';
import { completedAuditDetailsAdapter } from '@store/entityAdapters';

const initialState = {
  auditsTimeStats: {
    loading: false,
    data: null,
    error: null,
  },
  completedAuditsStats: {
    loading: false,
    data: null,
    error: null,
  },
  completedAuditDetails: {
    loading: false,
    entities: completedAuditDetailsAdapter.getInitialState(),
    filters: {
      pageSize: 15,
      pageNumber: 1,
      orderBy: 'completeDate',
      orderByDirection: 'desc',
    },
    meta: null,
    error: null,
  },
};

export const auditPerformanceReportReducer: Reducer<IAuditPerformanceReportState> =
  createReducer<IAuditPerformanceReportState>(initialState, (builder) =>
    builder
      .addCase(actions.getAuditsTimeStats.pending, (state) => {
        state.auditsTimeStats.error = null;
        state.auditsTimeStats.data = null;
        state.auditsTimeStats.loading = true;
      })
      .addCase(actions.getAuditsTimeStats.fulfilled, (state, { payload }) => {
        state.auditsTimeStats.data = payload;
        state.auditsTimeStats.loading = false;
      })
      .addCase(actions.getAuditsTimeStats.rejected, (state, { payload }) => {
        state.auditsTimeStats.loading = false;
        state.auditsTimeStats.error = payload || null;
      })
      .addCase(actions.getAuditStats.pending, (state) => {
        state.completedAuditsStats.error = null;
        state.completedAuditsStats.data = null;
        state.completedAuditsStats.loading = true;
      })
      .addCase(actions.getAuditStats.fulfilled, (state, { payload }) => {
        state.completedAuditsStats.data = payload;
        state.completedAuditsStats.loading = false;
      })
      .addCase(actions.getAuditStats.rejected, (state, { payload }) => {
        state.completedAuditsStats.loading = false;
        state.completedAuditsStats.error = payload || null;
      })
      .addCase(
        actions.getCompletedAuditDetails.pending,
        (state, { meta: { arg } }) => {
          state.completedAuditDetails.loading = true;
          state.completedAuditDetails.filters = {
            ...state.completedAuditDetails.filters,
            ...(arg || {}),
          };
        }
      )
      .addCase(
        actions.getCompletedAuditDetails.fulfilled,
        (state, { payload }) => {
          completedAuditDetailsAdapter.setAll(
            state.completedAuditDetails.entities,
            payload.data.map(({ auditId, ...audit }) => ({
              id: auditId,
              ...audit,
            }))
          );
          state.completedAuditDetails.meta = payload.meta;
          state.completedAuditDetails.loading = false;
        }
      )
      .addCase(actions.getCompletedAuditDetails.rejected, (state) => {
        state.completedAuditDetails.loading = false;
      })
      .addCase(generalActions.resetAccountData, () => ({ ...initialState }))
  );
