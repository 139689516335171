import React from 'react';

import { Container, Header, Title, DropDownTrigger } from './styled';
import { ChevronDown } from 'react-feather';
import DropDownMenu from '../../shared/DropDownMenu/DropDownMenu';

interface IProps<T> {
  title: string;
  children: React.ReactNode;
  filters: {
    value: T;
    items: { value: T; label: string }[];
    onChange: (newValue: T) => void;
  };
}

function WidgetWrapper<T>({
  title,
  children,
  filters: { value, items, onChange },
}: IProps<T>) {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <DropDownMenu
          menuItems={items.map((item) => ({
            name: item.label,
            onClick: () => onChange(item.value),
          }))}
        >
          <DropDownTrigger onClick={(e) => e.stopPropagation()}>
            {items.find((item) => item.value === value)?.label}
            <ChevronDown />
          </DropDownTrigger>
        </DropDownMenu>
      </Header>
      {children}
    </Container>
  );
}

export default WidgetWrapper;
