import React from 'react';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { issueTypesActions } from '@application/IssueTypes/store/issueTypesActions';
import { issueTypesSelectors } from '@application/IssueTypes/store';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { routes } from '@config';

import PageHeader from '@components/shared/ant/PageHeader';
import SearchInput from '@components/shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';

const IssueTypesHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const filters = useSelector(issueTypesSelectors.getIssueTypesFilters);

  return (
    <>
      <PageHeader
        title={formatMessage({ id: 'IssueTypes' })}
        extra={
          <Space>
            <SearchInput
              value={filters.search}
              onEnter={(search) =>
                dispatch(
                  issueTypesActions.getIssueTypes({ search, pageNumber: 1 })
                )
              }
            />
            <Button
              type="primary"
              onClick={() => {
                navigate(routes.createIssueType);
              }}
              data-e2e={e2eTestElements.issues.issueTypes.createIssueType}
            >
              {formatMessage({
                id: 'CreateIssueType',
              })}
            </Button>
          </Space>
        }
      />
    </>
  );
};

export default IssueTypesHeader;
