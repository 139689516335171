import styled from 'styled-components';
import AntSkeleton from 'antd/es/skeleton';

export const Container = styled.div`
  width: 650px;
  margin-bottom: 30px;
  padding: 25px;
`;

export const Skeleton = styled(AntSkeleton)`
  width: 650px;
`;
