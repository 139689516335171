import React, { lazy, Suspense, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { AuditReportType } from '@repo/shared/enums';
import { Locale } from '@repo/shared/enums';

import PublicAuditReportError from '@presentation/Audits/PublicAuditReport/PublicAuditReportError/PublicAuditReportError';
import Loader from '@repo/shared/components/Loader';
const AuditReport = lazy(() => import('@repo/audit-report/full'));

interface IProps {}

function getParamValue(searchParams: URLSearchParams, param: string) {
  return searchParams.get(param)
    ? searchParams.get(param) === 'true'
    : undefined;
}

const PublicAuditReport: React.FC<React.PropsWithChildren<IProps>> = () => {
  const params = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<string | undefined>(undefined);

  if (!params.id || !searchParams.get('token') || error) {
    return <PublicAuditReportError error={error} />;
  }

  const searchParamsLocale = searchParams.get('locale');
  const locale =
    searchParamsLocale &&
    Object.values(Locale).includes(
      searchParamsLocale!.toLocaleUpperCase() as Locale
    )
      ? (searchParamsLocale as Locale)
      : undefined;

  return (
    <Suspense fallback={<Loader />}>
      <AuditReport
        auditId={params.id}
        type={AuditReportType.Public}
        locale={locale}
        token={searchParams.get('token')!}
        onError={(error) => {
          setError(error);
        }}
        showPages
        showExportButton
        showHeaderTitle
        templateSettings={{
          includeSummary: getParamValue(searchParams, 'includeSummary'),
          includeDetails: getParamValue(searchParams, 'includeDetails'),
          includeNAAnswers: getParamValue(searchParams, 'includeNAAnswers'),
          includePhotos: getParamValue(searchParams, 'includePhotos'),
        }}
      />
    </Suspense>
  );
};

export default PublicAuditReport;
