export enum RoleType {
  Admin,
  Auditor,
  Auditee,
  Observer,
}

export enum Permission {
  None = 0,
  CanDoAudits = 1,
  CanAssignAudits = 2,
  CanManageAudits = 3,
  CanScheduleAudits = 4,
  CanCreateInstantAudits = 5,
  CanViewAuditResults = 6,
  CanManageCorrectiveActions = 7,
  CanCreateOrDeleteCorrectiveActions = 8,
  CanApproveCorrectiveActions = 9,
  CanAssignCorrectiveActions = 10,
  CanDoCorrectiveActions = 11,
  CanViewCorrectiveActions = 12,
  CanViewAnalytics = 13,
  CanManageNotifications = 14,
  CanManageAuditObjects = 15,
  CanManageAuditTemplates = 16,
  CanManageUsers = 17,
  CanManageTags = 18,
  CanManageRoles = 19,
  CanManageScoreSystems = 20,
  CanManageBilling = 21,
  CanManageSecurity = 22,
  CanManageGeneralSetup = 23,
  CanAccessNonParticipantAuditObjects = 24,
  CanDeleteIssues = 25,
  CanEditIssues = 26,
  CanChangeIssueStatus = 27,
  CanViewIssues = 28,
  CanManageIssueTypes = 29,
}

export enum PermissionGroup {
  General = 'General',
  Audits = 'Audits',
  Actions = 'CorrectiveActions',
  Analytics = 'Analytics',
  Notifications = 'Notifications',
  AuditObjects = 'AuditObjects',
  AuditTemplates = 'AuditTemplates',
  Users = 'Users',
  Tags = 'Tags',
  Roles = 'Roles',
  ScoreSystems = 'ScoreSystems',
  Billing = 'Billing',
  Security = 'Security',
  Access = 'Access',
  Issues = 'Issues',
}
