import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { generalActions, generalSelectors } from '@store';
import { notification } from '@utils';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { Audit } from '@domain/Audits/models/Audit';
import { AssigneesModalContext } from '@repo/shared/enums';

import BulkUsersAssignmentModal from '@components/shared/BulkUsersAssignmentModal/BulkUsersAssignmentModal';

interface IProps {}

const AssignUsersToAuditsModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const modalState = useSelector(
    auditsSelectors.getAssignUsersToAuditsModalState
  );
  const auditsDictionary = useSelector(auditsSelectors.getAuditsDictionary);

  const closeModal = useCallback(
    () =>
      dispatch(auditsActions.toggleAssignUsersToAuditsModal({ opened: false })),
    []
  );

  return (
    <BulkUsersAssignmentModal<Audit>
      opened={modalState.opened}
      title={formatMessage({
        id: 'AuditAssignment',
      })}
      description={formatMessage(
        {
          id: 'PleaseSelectUsersFromDropdownToAssignTheFollowingAudits',
        },
        { count: modalState.opened ? modalState.auditsIds.length : 1 }
      )}
      assigneesContext={AssigneesModalContext.Audit}
      entitiesIds={modalState.opened ? modalState.auditsIds : []}
      entitiesMap={auditsDictionary}
      formatEntityName={(audit) => {
        const number = audit.number ? `${audit.number} - ` : '';
        return number + audit.name;
      }}
      onAssignClick={async (usersIds) => {
        if (!modalState.opened) {
          return;
        }

        const resultAction = await dispatch(
          auditsActions.assignUsersToAudits({
            usersIds,
            auditsIds: modalState.auditsIds,
          })
        );

        if (auditsActions.assignUsersToAudits.fulfilled.match(resultAction)) {
          dispatch(auditsActions.getAudits());

          notification.success({
            message: formatMessage({ id: 'AuditsHaveBeenAssigned' }),
          });

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileAssigningAudits' }),
          });
        }
      }}
      onCancel={() => {
        closeModal();
      }}
    />
  );
};

export default AssignUsersToAuditsModal;
