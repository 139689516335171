import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Sync = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M18.8005 9.334L18.3148 9.45259C18.3536 9.61156 18.4678 9.74149 18.6204 9.80044L18.8005 9.334ZM5.00111 11.1257L5.1256 11.61C5.34979 11.5524 5.50511 11.3484 5.50103 11.1169L5.00111 11.1257ZM3.74964 18.8977C3.98867 19.0359 4.29453 18.9543 4.4328 18.7152C4.57107 18.4762 4.48939 18.1703 4.25036 18.0321L3.74964 18.8977ZM19.6997 17.6006C19.4789 17.7664 19.4344 18.0799 19.6002 18.3007C19.7661 18.5214 20.0795 18.566 20.3003 18.4001L19.6997 17.6006ZM12 15L12.3536 14.6464C12.1583 14.4512 11.8417 14.4512 11.6464 14.6464L12 15ZM11.5 22.5C11.5 22.7761 11.7239 23 12 23C12.2761 23 12.5 22.7761 12.5 22.5H11.5ZM8.64645 17.6464C8.45118 17.8417 8.45118 18.1583 8.64645 18.3536C8.84171 18.5488 9.15829 18.5488 9.35355 18.3536L8.64645 17.6464ZM14.6464 18.3536C14.8417 18.5488 15.1583 18.5488 15.3536 18.3536C15.5488 18.1583 15.5488 17.8417 15.3536 17.6464L14.6464 18.3536ZM5.5 11C5.5 7.41015 8.41015 4.5 12 4.5V3.5C7.85786 3.5 4.5 6.85786 4.5 11H5.5ZM18.6204 9.80044C20.3058 10.4512 21.5 12.0868 21.5 14H22.5C22.5 11.6599 21.0387 9.66228 18.9806 8.86757L18.6204 9.80044ZM12 4.5C15.0561 4.5 17.6207 6.60957 18.3148 9.45259L19.2863 9.21542C18.4854 5.93507 15.5277 3.5 12 3.5V4.5ZM2.5 15C2.5 13.3695 3.61543 11.9982 5.1256 11.61L4.87662 10.6415C2.93521 11.1406 1.5 12.902 1.5 15H2.5ZM5.50103 11.1169C5.50034 11.078 5.5 11.0391 5.5 11H4.5C4.5 11.0449 4.5004 11.0898 4.50118 11.1345L5.50103 11.1169ZM4.25036 18.0321C3.20291 17.4261 2.5 16.2949 2.5 15H1.5C1.5 16.6663 2.40589 18.1204 3.74964 18.8977L4.25036 18.0321ZM21.5 14C21.5 15.4719 20.7938 16.7787 19.6997 17.6006L20.3003 18.4001C21.6351 17.3976 22.5 15.7997 22.5 14H21.5ZM11.5 15V22.5H12.5V15H11.5ZM9.35355 18.3536L12.3536 15.3536L11.6464 14.6464L8.64645 17.6464L9.35355 18.3536ZM11.6464 15.3536L14.6464 18.3536L15.3536 17.6464L12.3536 14.6464L11.6464 15.3536Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default Sync;
