import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IAccount } from '@repo/shared/types';
import { accountSelectors } from '@store';

export const useAccounts = () => {
  const { data } = useSelector(accountSelectors.getAccounts);
  const currentCompanyId = useSelector(accountSelectors.getCurrentCompanyId);

  const { selectedAccount, otherAccounts } = useMemo(
    () =>
      data.reduce<{
        selectedAccount: IAccount | null;
        otherAccounts: IAccount[];
      }>(
        (acc, account) => {
          if (account.company.id === currentCompanyId) {
            acc.selectedAccount = account;
          } else {
            acc.otherAccounts.push(account);
          }

          return acc;
        },
        {
          selectedAccount: null,
          otherAccounts: [],
        }
      ),
    [JSON.stringify(data)]
  );

  return { selectedAccount, otherAccounts };
};
