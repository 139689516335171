import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  accountSelectors,
  generalSelectors,
} from '@store';
import { Footer } from './styled';
import { notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { IConcise } from '@repo/shared/types';
import { e2eTestElements } from '@config';

import SlideModal from '../../../../../shared/SideModal/SideModal';
import Form from '../../../../../shared/ant/Form';
import Button from '../../../../../shared/ant/Button';
import Select from '@components/shared/ant/Select/Select';
import AuditObjectsSelect from '../../../../../shared/EntitySelects/AuditObjectsSelect';
import AttributeValue from '@components/auditObjects/attributes/single/AttributesAssignments/AddEditAttributeAssignmentModal/AttributeValue';

interface IProps {
  auditObjectId?: string;
  attributeId?: string;
}

const AddAttributeAssignmentModal: React.FC<React.PropsWithChildren<IProps>> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const visible = useSelector(
    auditObjectsSelectors.attributesAssignments.isAddModalVisible
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  const closeModal = useCallback(
    () =>
      dispatch(auditObjectsActions.attributesAssignments.toggleAddModal(false)),
    []
  );
  const { data: attributes } = useSelector(
    generalSelectors.getConciseAuditObjectAttributes
  );
  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  useEffect(() => {
    if (selectedTableRows.length > 0) {
      closeModal();
    }
  }, [selectedTableRows.length]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  const itemName = formatMessage({ id: 'Attribute' }).toLowerCase();
  return (
    <SlideModal
      opened={visible}
      onModalClose={() => closeModal()}
      title={formatMessage({
        id: 'AssignAttribute',
      })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
              data-e2e={e2eTestElements.objects.attributesAssignments.assignBtn}
            >
              {formatMessage({ id: 'Assign' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={e2eTestElements.objects.attributesAssignments.cancelBtn}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form
        form={form}
        onFinish={async (values: any) => {
          setLoading(true);

          const attributeId = props.attributeId || values.attributeId;

          const resultAction = await dispatch(
            auditObjectsActions.attributesAssignments.createAssignment({
              auditObjectsIds: !!props.auditObjectId
                ? [props.auditObjectId]
                : values.auditObjectsIds,
              attributeId,
              optionId: values.optionId,
            })
          );

          if (
            auditObjectsActions.attributesAssignments.createAssignment.fulfilled.match(
              resultAction
            )
          ) {
            dispatch(auditObjectsActions.attributesAssignments.getData());

            closeModal();

            notification.success({
              message: formatMessage(
                { id: 'YouHaveSuccessfullyCreatedItem' },
                { item: itemName }
              ),
            });
          } else {
            notification.error({
              message: formatMessage(
                { id: 'ErrorWhileCreatingItem' },
                { item: itemName }
              ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        layout="vertical"
        initialValues={{
          attributeId: props.attributeId || null,
          optionId: props.attributeId
            ? attributesMap[props.attributeId]?.defaultOptionId
            : null,
          auditObjectsIds: [],
        }}
      >
        <Form.Item
          name="attributeId"
          label={formatMessage({ id: 'Attribute' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <Select
            disabled={loading || !!props.attributeId}
            options={attributes.map(({ id, name }: IConcise) => ({
              value: id,
              label: name,
            }))}
            e2eDataAttr={
              e2eTestElements.objects.attributesAssignments
                .selectAttributeDropdown
            }
            onChange={(newAttributeId) => {
              const attribute = attributesMap[newAttributeId];

              if (attribute) {
                form.setFieldValue('optionId', attribute.defaultOptionId);
              }
            }}
            placeholder={formatMessage({ id: 'SelectAttribute' })}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('attributeId') !== null ? (
              <AttributeValue
                loading={loading}
                attributeId={getFieldValue('attributeId')}
                label={formatMessage({ id: 'Value' })}
                attributesMap={attributesMap}
                required
              />
            ) : null
          }
        </Form.Item>
        {!props.auditObjectId && (
          <Form.Item
            name="auditObjectsIds"
            label={auditObjectName.plural}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <AuditObjectsSelect
              disabled={loading}
              mode="multiple"
              e2eDataAttr={
                e2eTestElements.objects.attributesAssignments
                  .selectObjectDropdown
              }
              placeholder={formatMessage(
                { id: 'SelectAuditObjects' },
                { objectName: auditObjectName.plural.toLowerCase() }
              )}
            />
          </Form.Item>
        )}
      </Form>
    </SlideModal>
  );
};

export default AddAttributeAssignmentModal;
