import React, { useState } from 'react';

import { Container } from './styled';
import { ItemFlag } from '@repo/shared/enums';

import AnimatedFlag from './AnimatedFlag/AnimatedFlag';

interface IProps {
  flags?: ItemFlag[];
  flagsInRow?: { flag: ItemFlag; count: number }[];
  onClick?: () => void;
  expandable?: boolean;
}

const Flags: React.FC<React.PropsWithChildren<IProps>> = ({
  flags,
  flagsInRow,
  onClick,
  expandable,
}) => {
  const [expanded, setExpanded] = useState(false);

  if (!flags || flags.length === 0) {
    return null;
  }

  return (
    <Container
      onClick={() => {
        if (expandable && onClick) {
          onClick();
        }
      }}
    >
      {flags.map((flag) => {
        const failedInRowCount =
          flagsInRow?.find((i) => i.flag === flag)?.count || 0;

        return (
          <AnimatedFlag
            key={flag}
            expanded={expandable ? expanded : true}
            expandable={expandable}
            onClick={() => {
              if (expandable && !onClick) {
                setExpanded(!expanded);
              }
            }}
            flag={flag}
            failedInRowCount={failedInRowCount}
          />
        );
      })}
    </Container>
  );
};

export default Flags;
