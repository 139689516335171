import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertCircle, X } from 'react-feather';
import AntModal from 'antd/es/modal';

import { ActionDetails } from '@domain/Actions/models/Action';
import { colors } from '@repo/shared/config';
import { FilesContainer } from './styled';

import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';
import LinkedItem from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/LinkedItem/LinkedItem';
import Previews from '@components/shared/Previews/Previews';

interface IProps {
  action: ActionDetails;
}

const ActionDetailsBestPractice: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  const [modalOpened, setModalOpened] = useState(false);

  if (!action.information) {
    return null;
  }

  return (
    <>
      <ActionDetailsInfoItem
        label={formatMessage({ id: 'GuideBestPractice' })}
        content={
          <LinkedItem
            onClick={() => {
              setModalOpened(true);
            }}
            icon={<AlertCircle color={colors.blue2} />}
            name={action.information.text}
          />
        }
      />
      <AntModal
        open={modalOpened}
        title={formatMessage({ id: 'GuideBestPractice' })}
        width={372}
        closeIcon={<X />}
        onCancel={() => {
          setModalOpened(false);
        }}
        footer={null}
        styles={{
          body: {
            marginBottom: 0,
          },
        }}
      >
        {action.information.text}
        {action.information.files.length > 0 && (
          <FilesContainer>
            <Previews files={action.information.files} />
          </FilesContainer>
        )}
      </AntModal>
    </>
  );
};

export default ActionDetailsBestPractice;
