import React from 'react';

import Form from '@components/shared/ant/Form';
import RepeatEvery from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/RepeatEvery/RepeatEvery';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';

interface IProps {
  disabled?: boolean;
}

const Daily: React.FC<React.PropsWithChildren<IProps>> = ({ disabled }) => {
  return (
    <Form.Item name={['repeat', 'repeatEvery']}>
      <RepeatEvery
        disabled={disabled}
        repeatPattern={AuditRepeatPattern.Daily}
      />
    </Form.Item>
  );
};

export default Daily;
