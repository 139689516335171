import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Space from 'antd/es/space';

import { useAppDispatch } from '@hooks';
import { accountSelectors, generalActions, generalSelectors } from '@store';
import { Footer, ApplyToAllObjectsLabel } from './styled';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';
import { CreateIssueTypeQrCodeDto } from '@infrastructure/IssueTypes/dto/CreateIssueTypeQrCodeDto';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';
import { notification } from '@utils';
import { e2eTestElements } from '@config';

import SlideModal from '@components/shared/SideModal/SideModal';
import Form from '@components/shared/ant/Form';
import Button from '@components/shared/ant/Button';
import IssueTypesSelect from '@components/shared/EntitySelects/IssueTypesSelect';
import LabeledSwitch from '@components/shared/LabeledSwitch/LabeledSwitch';
import { Input } from '@components/shared/ant/Input';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';
import AuditObjectsAndAuditObjectGroupsSelect from '@components/shared/EntitySelects/AuditObjectsAndAuditObjectGroupsSelect';

interface IProps {
  issueTypeId?: string;
  auditObjectId?: string;
  auditObjectGroupId?: string;
}

interface FormValues
  extends Omit<
    CreateIssueTypeQrCodeDto,
    'auditObjectsIds' | 'auditObjectGroupsIds'
  > {
  auditObject: {
    auditObjectIds: string[];
    auditObjectGroupIds: string[];
  };
}

const AddEditIssueTypeQrCodeModal: React.FC<
  React.PropsWithChildren<IProps>
> = ({ issueTypeId, auditObjectId, auditObjectGroupId }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();

  const [saving, setSaving] = useState(false);
  const [allowAnyAuditObject, setAllowAnyAuditObject] = useState(false);

  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const qrCodesDictionary = useSelector(
    issueTypeQrCodesSelectors.getIssueQrCodesDictionary
  );
  const { show, issueTypeQrCodeId } = useSelector(
    issueTypeQrCodesSelectors.getAddEditModalState
  );
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  useEffect(() => {
    if (show) {
      form.resetFields();

      if (issueTypeQrCodeId && qrCodesDictionary[issueTypeQrCodeId]) {
        const {
          allowAnyAuditObject,
          auditObjects,
          issueType,
          description,
          auditObjectGroups,
        } = qrCodesDictionary[issueTypeQrCodeId] as IssueTypeQrCode;

        form.setFieldsValue({
          allowAnyAuditObject,
          auditObject: {
            auditObjectIds: auditObjects.map(({ id }) => id),
            auditObjectGroupIds: auditObjectGroups.map(({ id }) => id),
          },
          issueTypeId: issueType.id,
          description,
        });

        if (allowAnyAuditObject) {
          setAllowAnyAuditObject(true);
        }
      } else if (auditObjectId || auditObjectGroupId) {
        form.setFieldsValue({
          allowAnyAuditObject,
          auditObject: {
            auditObjectIds: auditObjectId ? [auditObjectId] : [],
            auditObjectGroupIds: auditObjectGroupId ? [auditObjectGroupId] : [],
          },
        });
      }
    }
  }, [show]);

  const closeModal = useCallback(() => {
    dispatch(issueTypeQrCodesActions.toggleAddEditModal({ show: false }));
    setAllowAnyAuditObject(false);
  }, []);

  return (
    <SlideModal
      opened={show}
      onModalClose={closeModal}
      title={formatMessage({
        id: issueTypeQrCodeId ? 'EditQrCode' : 'CreateQrCode',
      })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={saving}
              onClick={() => {
                form?.submit();
              }}
              data-e2e={
                e2eTestElements.issues.issueTypes.qrCodes.addEditModal.saveBtn
              }
            >
              {formatMessage({ id: issueTypeQrCodeId ? 'Save' : 'Create' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                form?.resetFields();

                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
              data-e2e={
                e2eTestElements.issues.issueTypes.qrCodes.addEditModal.cancelBtn
              }
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      <Form<FormValues>
        form={form}
        layout="vertical"
        initialValues={{
          issueTypeId: issueTypeId || null,
          allowAnyAuditObject: false,
          description: '',
          auditObject: {
            auditObjectIds: auditObjectId ? [auditObjectId] : [],
            auditObjectGroupIds: auditObjectGroupId ? [auditObjectGroupId] : [],
          },
        }}
        onFinish={async ({ auditObject, ...values }) => {
          setSaving(true);

          const actionCreator = issueTypeQrCodeId
            ? issueTypeQrCodesActions.editQrCode
            : issueTypeQrCodesActions.createQrCode;

          const resultAction = await (issueTypeQrCodeId
            ? dispatch(
                issueTypeQrCodesActions.editQrCode({
                  id: issueTypeQrCodeId,
                  ...values,
                  auditObjectsIds: auditObject.auditObjectIds,
                  auditObjectGroupsIds: auditObject.auditObjectGroupIds,
                })
              )
            : dispatch(
                issueTypeQrCodesActions.createQrCode({
                  ...values,
                  auditObjectsIds: auditObject.auditObjectIds,
                  auditObjectGroupsIds: auditObject.auditObjectGroupIds,
                })
              ));

          const item = formatMessage({ id: 'QrCode' }).toLowerCase();

          if (actionCreator.fulfilled.match(resultAction)) {
            dispatch(issueTypeQrCodesActions.getQrCodes());

            closeModal();

            if (selectedTableRowKeys.length > 0) {
              dispatch(generalActions.selectTableRows([]));
            }

            notification.success({
              message: formatMessage(
                {
                  id: issueTypeId
                    ? 'YouHaveSuccessfullyEditedItem'
                    : 'YouHaveSuccessfullyCreatedItem',
                },
                {
                  item,
                }
              ),
            });
          } else {
            notification.error({
              message: formatMessage(
                {
                  id: issueTypeId
                    ? 'ErrorWhileEditingItem'
                    : 'ErrorWhileCreatingItem',
                },
                {
                  item,
                }
              ),
            });
          }

          setSaving(false);
        }}
      >
        <Form.Item
          name="issueTypeId"
          label={formatMessage({ id: 'IssueType' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <IssueTypesSelect
            e2eDataAttr={
              e2eTestElements.issues.issueTypes.qrCodes.addEditModal
                .issueTypeSelect
            }
            disabled={saving || !!issueTypeId}
            placeholder={formatMessage({ id: 'SelectIssueType' })}
          />
        </Form.Item>
        {!auditObjectId && !auditObjectGroupId && (
          <Form.Item
            name="allowAnyAuditObject"
            style={{ margin: '15px 0 10px' }}
          >
            <LabeledSwitch
              title={
                <ApplyToAllObjectsLabel>
                  {formatMessage(
                    { id: 'ApplyToAllAuditObjects' },
                    { auditObjectName: auditObjectName.plural.toLowerCase() }
                  )}
                  <CircleTooltip>
                    {formatMessage(
                      {
                        id: 'WhenEnableQrCodeWillBeAppliedToAllAuditObjects',
                      },
                      { auditObjectName: auditObjectName.plural.toLowerCase() }
                    )}
                  </CircleTooltip>
                </ApplyToAllObjectsLabel>
              }
              e2eDataAttr={
                e2eTestElements.issues.issueTypes.qrCodes.addEditModal
                  .allObjectsSwitch
              }
              onChange={(checked) => {
                setAllowAnyAuditObject(checked);

                form.setFieldsValue({
                  auditObject: {
                    auditObjectIds: [],
                    auditObjectGroupIds: [],
                  },
                });
              }}
            />
          </Form.Item>
        )}
        <div style={{ opacity: allowAnyAuditObject ? 0.5 : 1 }}>
          <Form.Item
            name="auditObject"
            label={`${auditObjectName.plural} ${formatMessage({
              id: 'or',
            })} ${formatMessage({ id: 'Groups' })}`}
            rules={
              !allowAnyAuditObject
                ? [
                    {
                      validator(
                        _,
                        value: {
                          auditObjectIds: string[];
                          auditObjectGroupIds: string[];
                        }
                      ) {
                        if (
                          value.auditObjectIds.length === 0 &&
                          value.auditObjectGroupIds.length === 0
                        ) {
                          return Promise.reject(
                            formatMessage(
                              { id: 'PleaseAddAtLeastOneAuditObject' },
                              {
                                auditObjectName:
                                  auditObjectName.single.toLowerCase(),
                              }
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]
                : undefined
            }
            style={{ marginBottom: '20px' }}
          >
            <AuditObjectsAndAuditObjectGroupsSelect
              disabled={
                saving ||
                allowAnyAuditObject ||
                !!auditObjectId ||
                !!auditObjectGroupId
              }
              data-e2e={
                e2eTestElements.issues.issueTypes.qrCodes.addEditModal
                  .objectsGroupsSelect
              }
              allowClear
            />
          </Form.Item>
        </div>
        <Form.Item
          name="description"
          label={formatMessage({ id: 'DescriptionOptional' })}
        >
          <Input.TextArea
            disabled={saving}
            rows={3}
            data-e2e={
              e2eTestElements.issues.issueTypes.qrCodes.addEditModal.description
            }
            placeholder={formatMessage({ id: 'EnterDescription' })}
          />
        </Form.Item>
      </Form>
    </SlideModal>
  );
};

export default AddEditIssueTypeQrCodeModal;
