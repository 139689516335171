import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { dashboardActions, dashboardSelectors } from '@store';
import { DashboardDateRangeType } from '@repo/shared/enums';
import {
  Container,
  ChartWrapper,
  Numbers,
  Total,
  Title,
  Content,
} from './styled';
import { round, useTranslatedOptions, enumToSelectOptions, date } from '@utils';
import { useAppDispatch } from '@hooks';
import { config } from '@repo/shared/config';

import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import ChartLegend from '../ChartLegend/ChartLegend';
import EmptyWidgetPlaceholder from '@repo/shared/components/EmptyWidgetPlaceholder';
import PieChart from '@repo/shared/components/PieChart/PieChart';

const filtersItems = enumToSelectOptions<DashboardDateRangeType>(
  DashboardDateRangeType
);

function getDatesBasedOnRangeType(dateRangeType: DashboardDateRangeType): {
  startDate: string;
  endDate: string;
} {
  const today = date();

  let startDate = today.subtract(1, 'week');

  if (dateRangeType !== DashboardDateRangeType.PriorWeek) {
    const subtract = {
      [DashboardDateRangeType.Prior3Months]: 3,
      [DashboardDateRangeType.Prior6Months]: 6,
      [DashboardDateRangeType.Prior9Months]: 9,
      [DashboardDateRangeType.Prior12Months]: 12,
    };

    startDate = today.subtract(subtract[dateRangeType], 'month');
  }

  return {
    startDate: date(startDate.startOf('day')).format(config.apiDateFormat),
    endDate: date(today.endOf('day')).format(config.apiDateFormat),
  };
}

const AuditsCompletion: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { data, filters, loading } = useSelector(
    dashboardSelectors.getAuditsCompletion,
    shallowEqual
  );
  const translatedFiltersItems =
    useTranslatedOptions<DashboardDateRangeType>(filtersItems);

  const total = data?.averageScore || 0;
  const chartTitle = formatMessage({ id: 'OverallAverageComplianceScore' });

  return (
    <WidgetWrapper<DashboardDateRangeType>
      title={formatMessage({ id: 'AuditsCompletion' })}
      filters={{
        value: filters.dateRangeType,
        items: translatedFiltersItems,
        onChange: (dateRangeType) =>
          dispatch(
            dashboardActions.getAuditsCompletion({
              dateRangeType,
              ...getDatesBasedOnRangeType(dateRangeType),
            })
          ),
      }}
    >
      <Container>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {total === 0 ? (
              <EmptyWidgetPlaceholder />
            ) : (
              <>
                <ChartWrapper>
                  <PieChart
                    data={data?.chart}
                    loading={loading}
                    onAction={(action) => {
                      dispatch(action);
                    }}
                  />
                  <Content>
                    <Numbers>
                      <Total>{round(total, 0)}%</Total>
                    </Numbers>
                    <Title>{chartTitle}</Title>
                  </Content>
                </ChartWrapper>
                <ChartLegend items={data?.chart} loading={loading} />
              </>
            )}
          </>
        )}
      </Container>
    </WidgetWrapper>
  );
};

export default AuditsCompletion;
