import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Container, ButtonContainer } from './styled';
import { useAppDispatch, usePermission } from '@hooks';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { IssueStatus } from '@domain/Issues/models/Issue';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import StatusButton from '@src/presentation/Actions/ActionDetails/ActionDetailsHeader/StatusButton/StatusButton';
import CloseReasonPopover from './CloseReasonPopover/CloseReasonPopover';

interface IProps {}

const IssueDetailsStatusButtons: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const issue = useSelector(issuesSelectors.getDetailsIssue);
  const loading = useSelector(issuesSelectors.getIssueDetailsLoading);

  const [canChangeIssueStatus] = usePermission([
    Permission.CanChangeIssueStatus,
  ]);

  if (!canChangeIssueStatus || !issue || loading) {
    return null;
  }

  if (issue.status === IssueStatus.Opened) {
    return (
      <Container>
        <ButtonContainer>
          <StatusButton
            e2eDataAttr={e2eTestElements.issues.issues.details.closeIssueBtn}
            onClick={() => {
              dispatch(issuesActions.toggleCloseIssuesModal([issue.id]));
            }}
            label={formatMessage({ id: 'Close' })}
            disabled={loading}
          />
        </ButtonContainer>
      </Container>
    );
  }

  if (issue.status === IssueStatus.Closed) {
    return (
      <Container>
        <CloseReasonPopover />
        <ButtonContainer>
          <StatusButton
            e2eDataAttr={e2eTestElements.issues.issues.details.reopenIssueBtn}
            onClick={() => {
              dispatch(issuesActions.toggleReopenIssuesModal([issue.id]));
            }}
            label={formatMessage({ id: 'Reopen' })}
            disabled={loading}
          />
        </ButtonContainer>
      </Container>
    );
  }

  return null;
};

export default IssueDetailsStatusButtons;
