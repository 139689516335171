import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { GetPendingAuditDetailsDto } from '@infrastructure/Audits/models/GetPendingAuditDto';

export function transformPendingAuditDetailsDtoToAudit(
  dto: GetPendingAuditDetailsDto
): AuditDetails {
  return {
    ...dto,
    completedBy: null,
    completedAtInformation: null,
    pendingActionsCount: 0,
    completedActionsCount: 0,
    expiredActionsCount: 0,
    score: null,
    scoreLabel: null,
    scoreColor: null,
    scoreChange: null,
    expiredBy: null,
    expiredAtInformation: null,
  };
}
