import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Container, Image, Desc, Content, Title } from './styled';
import { Logger } from '@repo/shared/services';

const NotFound: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const location = useLocation();

  useEffect(() => {
    Logger.captureException(
      new Error(`Page not found: ${location.state?.referrer}`)
    );
  }, []);

  return (
    <Container>
      <Content>
        <Image />
        <Title>{formatMessage({ id: 'PageNotFound' })}</Title>
        <Desc>
          {formatMessage({
            id: 'TheLinkYouFollowedProbablyBrokenOrThePageHasBeenRemoved',
          })}
        </Desc>
      </Content>
    </Container>
  );
};

export default NotFound;
