import React, { useMemo } from 'react';
import { SelectProps } from 'antd/es/select';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import uniqBy from 'lodash/uniqBy';

import { generalSelectors } from '@store';
import { IConcise, IUserConcise } from '@repo/shared/types';
import { delay } from '@repo/shared/utils';
import { InternalApiService } from '@repo/shared/api';

import EntitySelect from '@components/shared/EntitySelects/EntitySelect';

const apiService = InternalApiService.getInstance();

interface IProps extends SelectProps<any> {
  width?: string;
  apiUrl?: string;
  e2eDataAttr?: string;
  extraOptions?: IConcise[];
  filterOptions?: (user: IUserConcise) => boolean;
}

const UsersSelect: React.FC<React.PropsWithChildren<IProps>> = React.forwardRef(
  ({ apiUrl, extraOptions, filterOptions, ...props }, _) => {
    const { data: conciseUsers, loading } = useSelector(
      generalSelectors.getConciseUsers
    );

    const { result: apiUsers, loading: apiUsersLoading } = useAsync(
      () =>
        apiUrl
          ? apiService.get({
              url: apiUrl,
            })
          : delay(0),
      []
    );

    const options = useMemo(() => {
      let mainOptions = [];

      if (!apiUsersLoading && !loading) {
        mainOptions = apiUrl ? apiUsers : conciseUsers;

        if (filterOptions) {
          mainOptions = mainOptions.filter(filterOptions);
        }
      }

      return uniqBy([...mainOptions, ...(extraOptions || [])], 'id');
    }, [apiUsersLoading, loading, apiUrl]);

    return (
      <EntitySelect
        {...props}
        options={options}
        loading={loading || apiUsersLoading}
      />
    );
  }
);

export default UsersSelect;
