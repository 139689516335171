import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import AntSelect from 'antd/es/select';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { unwrapResult } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

import { date } from '@repo/shared/utils';
import { enumToSelectOptions, limitLength, notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { Footer } from '@presentation/Audits/CreateOneTimeAuditModal/styled';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { generalSelectors } from '@store';
import { routes } from '@config';
import { getAuditorAssignmentTypeLangId } from '@application/SchedulePlans/utils/getAuditorAssignmentTypeLangId';
import { IConcise } from '@repo/shared/types';
import { AuditorAssignmentType } from '@domain/Schedules/enums/AuditorAssignmentType';

import Button from '@components/shared/ant/Button';
import SlideModal from '@components/shared/SideModal/SideModal';
import Form from '@components/shared/ant/Form';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';
import Select from '@components/shared/ant/Select/Select';
import UsersSelect from '@components/shared/EntitySelects/UsersSelect';
import UserGroupsSelect from '@components/shared/EntitySelects/UserGroupsSelect';
import { Input } from '@components/shared/ant/Input';
import Checkbox from '@components/shared/ant/Checkbox';
import CheckboxRow from '@components/shared/CheckboxRow/CheckboxRow';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import { config } from '@repo/shared/config';

interface FormValues {
  name: string;
  planningPeriodWeeks: string;
  startFromDate: Dayjs | null;
  auditorAssignmentType: AuditorAssignmentType;
  assignedUsersIds: string[] | undefined;
  assignedUserGroupsIds: string[] | undefined;
}

const { Option } = AntSelect;

const auditorAssignmentsTypesOptions =
  enumToSelectOptions<AuditorAssignmentType>(AuditorAssignmentType);

interface IProps {}

const AddEditSchedulePlanModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<FormValues>();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const { show, schedulePlanId } = useSelector(
    schedulePlansSelectors.getAddEditModalState
  );

  const { data: schedulePlanDetails } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );

  const conciseUsersDictionary = useSelector(
    generalSelectors.getConciseUsersMap
  );
  const conciseUserGroupsDictionary = useSelector(
    generalSelectors.getConciseUserGroupsMap
  );

  useEffect(() => {
    async function loadSchedulePlanDetails(id: string) {
      let schedulePlan;

      if (!schedulePlanDetails) {
        setLoading(true);

        const resultAction = await dispatch(
          schedulePlansActions.getSchedulePlanDetails(id)
        );

        if (
          schedulePlansActions.getSchedulePlanDetails.fulfilled.match(
            resultAction
          )
        ) {
          schedulePlan = unwrapResult(resultAction);
        } else {
          notification.error({
            message: formatMessage({
              id: 'ErrorWhileLoadingAdvancedScheduleDetails',
            }),
          });

          closeModal();
        }

        setLoading(false);
      } else {
        schedulePlan = schedulePlanDetails;
      }

      if (schedulePlan) {
        form.setFieldsValue({
          name: schedulePlan.name,
          startFromDate: schedulePlan.startFromDate
            ? date(schedulePlan.startFromDate)
            : null,
          auditorAssignmentType: schedulePlan.auditorAssignmentType,
          assignedUserGroupsIds: schedulePlan.assignedUserGroups.map(
            ({ id }) => id
          ),
          planningPeriodWeeks: (
            schedulePlan.planningPeriodWeeks || 2
          ).toString(),
          assignedUsersIds: schedulePlan.assignedUsers.map(({ id }) => id),
        });
      }
    }

    if (show && schedulePlanId) {
      loadSchedulePlanDetails(schedulePlanId);
    }
  }, [show]);

  const closeModal = useCallback(() => {
    dispatch(schedulePlansActions.toggleAddEditModal({ show: false }));
    form.resetFields();
  }, []);

  const itemName = formatMessage({ id: 'AdvancedSchedule' }).toLowerCase();

  return (
    <SlideModal
      opened={show}
      onModalClose={closeModal}
      title={formatMessage({
        id: schedulePlanId ? 'EditAdvancedSchedule' : 'CreateAdvancedSchedule',
      })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              disabled={loading}
              loading={saving}
              onClick={() => {
                form?.submit();
              }}
            >
              {formatMessage({
                id: schedulePlanId ? 'Save' : 'Create',
              })}
            </Button>
            <Button
              type="default"
              disabled={loading}
              onClick={() => {
                closeModal();
              }}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
        </Footer>
      }
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Form<FormValues>
          form={form}
          layout="vertical"
          initialValues={{
            name: '',
            auditorAssignmentType: AuditorAssignmentType.NoAuditorAssigned,
            assignedUsersIds: [],
            startFromDate: null,
            assignedUserGroupsIds: [],
            planningPeriodWeeks: '2',
          }}
          onFinish={async ({
            assignedUsersIds,
            assignedUserGroupsIds,
            ...values
          }) => {
            setSaving(true);

            const actionCreator = schedulePlanId
              ? schedulePlansActions.updateSchedulePlan
              : schedulePlansActions.createSchedulePlan;

            const assignedUsers = (assignedUsersIds || []).reduce<IConcise[]>(
              (acc, userId) => {
                const a = acc;

                if (conciseUsersDictionary[userId]) {
                  a.push(conciseUsersDictionary[userId]!);
                }

                return a;
              },
              []
            );

            const assignedUserGroups = (assignedUserGroupsIds || []).reduce<
              IConcise[]
            >((acc, userId) => {
              const a = acc;

              if (conciseUserGroupsDictionary[userId]) {
                a.push(conciseUserGroupsDictionary[userId]!);
              }

              return a;
            }, []);

            const startFromDate =
              values.startFromDate?.format(config.apiDateFormat) || null;
            const planningPeriodWeeks = parseInt(values.planningPeriodWeeks);

            const resultAction = await (schedulePlanId
              ? dispatch(
                  schedulePlansActions.updateSchedulePlan({
                    id: schedulePlanId,
                    assignedUsers,
                    assignedUserGroups,
                    ...values,
                    startFromDate,
                    planningPeriodWeeks,
                  })
                )
              : dispatch(
                  schedulePlansActions.createSchedulePlan({
                    assignedUsers,
                    assignedUserGroups,
                    ...values,
                    startFromDate,
                    planningPeriodWeeks,
                  })
                ));

            if (actionCreator.fulfilled.match(resultAction)) {
              notification.success({
                message: formatMessage(
                  {
                    id: schedulePlanId
                      ? 'YouHaveSuccessfullyEditedItem'
                      : 'YouHaveSuccessfullyCreatedItem',
                  },
                  { item: itemName }
                ),
              });

              dispatch(schedulePlansActions.getConciseSchedulePlans());

              if (schedulePlanId) {
                dispatch(schedulePlansActions.getSchedulePlans());
              } else {
                const result = unwrapResult(resultAction);

                if (typeof result === 'string') {
                  navigate(
                    routes.advancedScheduleDetails.replace(':id', result)
                  );
                }
              }

              closeModal();
            } else {
              notification.error({
                message: formatMessage(
                  {
                    id: schedulePlanId
                      ? 'ErrorWhileEditingItem'
                      : 'ErrorWhileCreatingItem',
                  },
                  { item: itemName }
                ),
              });
            }

            setSaving(false);
          }}
        >
          <Form.Item
            name="name"
            label={formatMessage({ id: 'Name' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
              limitLength(800),
            ]}
          >
            <Input
              disabled={saving}
              placeholder={formatMessage({ id: 'EnterName' })}
            />
          </Form.Item>
          <Form.Item
            name="planningPeriodWeeks"
            label={formatMessage({ id: 'SchedulePeriodLength' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
              {
                pattern: /(^0?[1-9]$)|(^1[0-2]$)/,
                message: formatMessage({
                  id: 'PeriodMustBeNumberBetween1and12weeks',
                }),
              },
            ]}
          >
            <Input
              disabled={saving}
              placeholder={formatMessage({ id: 'EnterNumberOfWeeks' })}
            />
          </Form.Item>
          <Form.Item
            name="startFromDate"
            label={formatMessage({ id: 'StartDate' })}
          >
            <DatePicker
              disabled={saving}
              disabledDate={(current: Dayjs) =>
                current && current.isBefore(date().add(1, 'day').startOf('day'))
              }
            />
          </Form.Item>
          <Form.Item
            name="auditorAssignmentType"
            label={
              <>
                {formatMessage({ id: 'Auditor' })}
                <CircleTooltip contentMaxWidth={250}>
                  <p>
                    {formatMessage({
                      id: 'AssignThisAuditToAnAuditorGroupOfAuditorsOrLeaveItUnassignedSoAnyAuditorCanStartIt',
                    })}
                  </p>
                </CircleTooltip>
              </>
            }
          >
            <Select
              disabled={saving}
              placeholder={formatMessage({ id: 'SelectTemplate' })}
            >
              {auditorAssignmentsTypesOptions.map(({ value }) => (
                <Option key={value} value={value}>
                  {formatMessage({ id: getAuditorAssignmentTypeLangId(value) })}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, values) =>
              prevValues.auditorAssignmentType !== values.auditorAssignmentType
            }
          >
            {({ getFieldValue }) => {
              const auditorAssignmentType: AuditorAssignmentType =
                getFieldValue('auditorAssignmentType');

              switch (auditorAssignmentType) {
                case AuditorAssignmentType.AssignToSpecificAuditor:
                  return (
                    <Form.Item
                      name="assignedUsersIds"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'PleaseChooseAuditor' }),
                        },
                      ]}
                    >
                      <UsersSelect
                        disabled={saving}
                        placeholder={formatMessage({ id: 'ChooseAuditor' })}
                        filterOptions={(user) => user.canDoAudit}
                        mode="multiple"
                      />
                    </Form.Item>
                  );
                case AuditorAssignmentType.RandomAssignedAuditorFromGroup:
                case AuditorAssignmentType.EvenlyDistributedAuditorFromGroup:
                  return (
                    <Form.Item
                      name="assignedUserGroupsIds"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'ChooseUserGroups' }),
                        },
                      ]}
                    >
                      <UserGroupsSelect
                        disabled={saving}
                        placeholder={formatMessage({ id: 'ChooseUserGroups' })}
                        mode="multiple"
                      />
                    </Form.Item>
                  );
                default:
                  return null;
              }
            }}
          </Form.Item>
        </Form>
      )}
    </SlideModal>
  );
};

export default AddEditSchedulePlanModal;
