import styled from 'styled-components';

import { BREAKPOINTS } from '@repo/shared/config';

export const ActionButtonWrapper = styled.div`
  & > * {
    display: none;

    @media (min-width: ${BREAKPOINTS.MD}px) {
      display: block;
    }
  }
`;
