import { useEffect, useState } from 'react';
import { backOff } from 'exponential-backoff';

import { FileStatus } from '@repo/shared/enums';
import { Logger } from '@repo/shared/services';
import { IApiService } from '@repo/shared/types';
import { NetworkError } from '@repo/shared/errors';

export function useFileStatus({
  fileId,
  getMetadataPath = (fileId) => `files/${fileId}/metadata`,
  skipCompanyId,
  apiService,
  skipStatusCheck,
}: {
  fileId: string;
  getMetadataPath?: (fileId: string) => string;
  skipCompanyId?: boolean;
  apiService: IApiService;
  skipStatusCheck?: boolean;
}) {
  const [status, setStatus] = useState<FileStatus | null>(
    skipStatusCheck ? FileStatus.Processed : null
  );

  useEffect(() => {
    let isMounted = true;

    async function getFileStatus(fileId: string) {
      if (!navigator.onLine) {
        setStatus(FileStatus.Offline);
        return;
      }

      try {
        let isFirstRequest = true;

        const status = await backOff<FileStatus>(
          async () => {
            const { status: newStatus } = await apiService.get<{
              status: FileStatus;
            }>({
              host: apiService.getFileApiHost(),
              url: getMetadataPath(fileId),
              skipCompanyId,
            });

            if (isFirstRequest || newStatus === FileStatus.PreviewReady) {
              setStatus(newStatus);
              isFirstRequest = false;
            }

            if (newStatus === FileStatus.Processed) {
              setStatus(FileStatus.Processed);
            } else if (
              newStatus === FileStatus.WaitingUpload ||
              newStatus === FileStatus.Processing ||
              newStatus === FileStatus.PreviewReady
            ) {
              throw new Error('file-not-processed');
            }

            return newStatus;
          },
          {
            numOfAttempts: 300,
            retry: (e: Error) => {
              const isNetworkError = e instanceof NetworkError;

              if (!navigator.onLine && isNetworkError) {
                return false;
              }

              return (
                navigator.onLine &&
                isMounted &&
                (e.message === 'file-not-processed' || isNetworkError)
              );
            },
          }
        );

        setStatus(status);
      } catch (e: unknown) {
        setStatus(FileStatus.Error);

        if (!(e instanceof NetworkError)) {
          Logger.captureException(e);
        }
      }
    }

    const handleOnlineStatus = () => {
      if (!navigator.onLine) {
        setStatus(FileStatus.Offline);
      } else if (!skipStatusCheck && isMounted) {
        getFileStatus(fileId);
      }
    };

    if (!skipStatusCheck) {
      getFileStatus(fileId);
    }

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      isMounted = false;
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, [fileId, skipCompanyId, skipStatusCheck]);

  return status;
}
