import React from 'react';
import Space from 'antd/es/space';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Button from '../../../shared/ant/Button';
import {
  ResultContainer,
  ResultIcon,
  ResultText,
  ResultTitle,
  ResultButtons,
} from './styled';
import { routes } from '@src/config/routes';

interface IProps {
  loading: boolean;
}

const ResetPasswordSuccess: React.FC<React.PropsWithChildren<IProps>> = ({
  loading,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <ResultContainer>
      <ResultIcon />
      <ResultTitle>{formatMessage({ id: 'SuccessfullySent' })}</ResultTitle>
      <ResultText>
        {formatMessage({
          id: 'CheckYourEmailForInstructionsToResetYourPassword',
        })}
      </ResultText>
      <ResultButtons>
        <Space>
          <Button
            type="primary"
            disabled={loading}
            onClick={() => navigate(routes.signin)}
          >
            {formatMessage({ id: 'Ok' })}
          </Button>
        </Space>
      </ResultButtons>
    </ResultContainer>
  );
};

export default ResetPasswordSuccess;
