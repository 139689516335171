import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { AuditTemplatesState } from '@application/AuditTemplates/models/AuditTemplatesState';
import { auditTemplateGroupsAdapter } from '@application/AuditTemplates/store/entityAdapters';

const getAuditTemplatesState = (state: IRootState): AuditTemplatesState =>
  state.auditTemplates;

const getTemplatesGroups = createSelector(
  getAuditTemplatesState,
  ({ groups: { data, loading, error, meta } }) => ({
    data: auditTemplateGroupsAdapter.getSelectors().selectAll(data),
    loading,
    error,
    meta,
  })
);

const getTemplateGroupsDictionary = createSelector(
  getAuditTemplatesState,
  ({ groups: { data } }) =>
    auditTemplateGroupsAdapter.getSelectors().selectEntities(data)
);

const getFilters = createSelector(
  getAuditTemplatesState,
  ({ groups: { filters } }) => filters
);

const getDefaultTemplateCandidate = createSelector(
  getAuditTemplatesState,
  ({ defaultTemplateCandidate }) => defaultTemplateCandidate
);
const getCopyTemplateCandidate = createSelector(
  getAuditTemplatesState,
  ({ copyTemplateCandidate }) => copyTemplateCandidate
);

const getExportPdfTemplateCandidate = createSelector(
  getAuditTemplatesState,
  ({ exportPdfTemplateCandidate }) => exportPdfTemplateCandidate
);

const getDeleteTemplateModalState = createSelector(
  getAuditTemplatesState,
  ({ deleteTemplateModal }) => deleteTemplateModal
);

const isSuggestionsModalOpened = createSelector(
  getAuditTemplatesState,
  ({ suggestionsModal }) => suggestionsModal.opened
);

const getPrompts = createSelector(
  getAuditTemplatesState,
  ({ suggestionsModal }) => suggestionsModal.prompts
);

const getSuggestion = createSelector(
  getAuditTemplatesState,
  ({ suggestionsModal }) => suggestionsModal.suggestion
);

export const auditTemplatesSelectors = {
  getTemplatesGroups,
  getTemplateGroupsDictionary,
  getFilters,
  getDefaultTemplateCandidate,
  getCopyTemplateCandidate,
  getExportPdfTemplateCandidate,
  getDeleteTemplateModalState,
  isSuggestionsModalOpened,
  getPrompts,
  getSuggestion,
};
