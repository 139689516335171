import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const FeatureList = styled.ul`
  margin: 0 0 25px 16px;
  padding: 0;
  font-size: 13px;
  color: ${colors.gray8};
  line-height: 1.7;

  li {
    list-style: none;

    &:before {
      content: '\\2022';
      color: ${colors.blue2};
      font-weight: bold;
      display: inline-block;
      width: 18px;
      margin-left: -16px;
    }
  }
`;

export const TrialEnds = styled.div`
  font-size: 13px;
  color: ${colors.gray8};
`;

export const TrialEnded = styled.div`
  font-size: 13px;
  color: ${colors.red};
`;

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 36px;
`;
