import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HeaderActionButton } from './styled';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

const SchedulePlanDetailsActionMenu: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { data } = useSelector(schedulePlansSelectors.getSchedulePlanDetails);

  const [canScheduleAudits] = usePermission([Permission.CanScheduleAudits]);

  if (!canScheduleAudits) {
    return null;
  }

  return (
    <DropDownMenu
      menuItems={[
        ...(!data?.active
          ? [
              {
                name: formatMessage({
                  id: 'Edit',
                }),
                onClick: () => {
                  if (!data) {
                    return;
                  }

                  dispatch(
                    schedulePlansActions.toggleAddEditModal({
                      show: true,
                      schedulePlanId: data.id,
                    })
                  );
                },
              },
            ]
          : []),
        {
          name: formatMessage({
            id: 'Delete',
          }),
          onClick: () => {
            if (!data) {
              return;
            }

            dispatch(
              schedulePlansActions.toggleConfirmDeleteModal({
                multiple: false,
                data,
              })
            );
          },
        },
      ]}
    >
      <HeaderActionButton type="button">
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default SchedulePlanDetailsActionMenu;
