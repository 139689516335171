import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin: 0 -8px 30px;

  & > * {
    margin: 0 8px;
  }
`;

interface IStarSvgProps {
  readonly?: boolean;
  selectedColor: string;
}

export const StarSvg = styled.svg`
  cursor: ${({ readonly }: IStarSvgProps) =>
    readonly ? 'default' : 'pointer'};

  ${({ selectedColor, readonly }: IStarSvgProps) => {
    return `
      path {
        transition: all 0.2s ease-in;
        fill: ${selectedColor};
      }
      
      ${
        !readonly
          ? `
        &:hover {
          path {
            fill: ${colors.yellow};
          }
      
          & ~ svg {
            path {
              fill: ${colors.yellow};
            }
          }
        }
      `
          : ''
      }
  `;
  }}
`;
