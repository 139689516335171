import { createReducer, EntityState } from '@reduxjs/toolkit';

import { IPagingMeta } from '@repo/shared/types';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issuesQrCodesEntityAdapter } from '@application/Issues/store/entityAdapters';
import { getInitialFilters } from '@utils';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';
import { IssueTypeQrCodesFilters } from '@application/IssueTypes/models/IssueTypeQrCodesFilters';
import { IssueTypeQrCodeModalState } from '@application/IssueTypes/models/IssueTypeQrCodeModalState';

function getInitialIssuesQrCodesFilters(): IssueTypeQrCodesFilters {
  return {
    ...getInitialFilters(),
    issueTypeIds: [],
    auditObjectsIds: [],
    auditObjectGroupsIds: [],
    allowAnyAuditObject: false,
  };
}

const initialState = {
  list: {
    loading: false,
    data: issuesQrCodesEntityAdapter.getInitialState(),
    error: null,
    meta: null,
  },
  filters: {
    ...getInitialIssuesQrCodesFilters(),
  },
  showFiltersModal: false,
  showCreateModal: false,
  confirmDeleteModal: null,
  qrCodeModal: null,
  addEditModal: {
    show: false,
    issueTypeQrCodeId: null,
  },
};

export const issueTypeQrCodesReducer = createReducer<{
  list: {
    loading: boolean;
    data: EntityState<IssueTypeQrCode, string>;
    meta: IPagingMeta | null;
    error: string | null;
  };
  confirmDeleteModal: DeleteModalState<IssueTypeQrCode>;
  filters: IssueTypeQrCodesFilters;
  qrCodeModal: IssueTypeQrCodeModalState | null;
  showFiltersModal: boolean;
  addEditModal: {
    show: boolean;
    issueTypeQrCodeId: string | null;
  };
}>(initialState, (builder) =>
  builder
    .addCase(
      issueTypeQrCodesActions.getQrCodes.pending,
      (state, { meta: { arg } }) => {
        state.list.loading = true;

        if (arg === null) {
          state.filters = {
            ...getInitialIssuesQrCodesFilters(),
          };
        } else {
          state.filters = {
            ...state.filters,
            ...arg,
          };
        }

        state.list.error = null;
      }
    )
    .addCase(
      issueTypeQrCodesActions.getQrCodes.fulfilled,
      (state, { payload: { data, meta } }) => {
        state.list.loading = false;
        state.list.meta = meta;
        issuesQrCodesEntityAdapter.setAll(state.list.data, data);
      }
    )
    .addCase(
      issueTypeQrCodesActions.getQrCodes.rejected,
      (state, { payload }) => {
        state.list.loading = false;
        state.list.error = payload || null;
      }
    )
    .addCase(issueTypeQrCodesActions.resetQrCodesData, (state) => {
      const filters = state.filters;

      return {
        ...initialState,
        filters,
      };
    })
    .addCase(
      issueTypeQrCodesActions.toggleConfirmDeleteModal,
      (state, { payload }) => {
        state.confirmDeleteModal = payload;
      }
    )
    .addCase(
      issueTypeQrCodesActions.toggleAddEditModal,
      (state, { payload: { show, issueTypeQrCodeId } }) => {
        state.addEditModal = {
          show,
          issueTypeQrCodeId: issueTypeQrCodeId || null,
        };
      }
    )
    .addCase(
      issueTypeQrCodesActions.toggleFiltersModal,
      (state, { payload }) => {
        state.showFiltersModal = payload;
      }
    )
    .addCase(
      issueTypeQrCodesActions.toggleQrCodeModal,
      (state, { payload }) => {
        state.qrCodeModal = payload;
      }
    )
);
