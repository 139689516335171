import styled from 'styled-components';
import { ButtonType } from 'antd/es/button';

import { colors } from '@repo/shared/config';
import LibraryButton from '../ant/Button';

export const Button = styled(LibraryButton)`
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;

  svg {
    color: ${colors.white};
    width: 14px;
    height: 14px;
    margin-left: 5px;
    margin-right: 0 !important;
  }

  ${({ type }: { type: ButtonType }) =>
    type === 'link'
      ? `
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      color: ${colors.blue2};
      height: 24px;
      padding-right: 0;
      
      svg {
        color: ${colors.blue2};
      }
  `
      : ''}
`;

export const ItemLabel = styled.div`
  padding: 6px 8px;
  display: flex;
  width: 100%;
  align-items: center;
  border: 0;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background: ${colors.gray1};
  }

  svg {
    width: 28px;
    height: 28px;
    color: ${colors.blue2};
    margin-right: 10px;
  }
`;
