import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { MQAIcon } from '@components/shared/MQAIcon/MQAIcon';

export const Container = styled.div`
  margin-top: 5px;
`;

export const Header = styled.h3`
  font-size: 18px;
  margin: 30px 0 10px;
`;

export const Event = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-top: 5px;
`;

export const Date = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
`;

export const AvatarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

export const MQAAvatar = styled(MQAIcon)`
  width: 24px;
  height: 24px;
`;
