import React from 'react';
import { useSelector } from 'react-redux';
import { TooltipPlacement } from 'antd/es/tooltip';

import { accountActions, accountSelectors } from '@store';
import { SelfGuidedTourStep } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';

import TourPopoverInner from '@components/account/SelfGuidedTour/TourPopover/TourPopoverInner';

interface IProps {
  step: SelfGuidedTourStep;
  width?: number;
  children: React.ReactNode;
  placement?: TooltipPlacement;
  overlayStyle?: {
    marginTop?: number;
    marginLeft?: number;
    height?: number;
    width?: number;
  };
  scrollToSpotlight?: boolean;
  scrollTo?: number;
  disabledNextBtn?: boolean;
  align?: any;
  measureDelay?: number;
}

const TourPopover: React.ComponentType<React.PropsWithChildren<IProps>> = ({
  children,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const { step: tourStep, loading } = useSelector(
    accountSelectors.getSelfGuidedTour
  );

  return (
    <TourPopoverInner
      {...props}
      visible={!loading && tourStep === props.step}
      loading={loading}
      setSpotlightBoundsInStore={(bounds) => {
        dispatch(accountActions.selfGuidedTour.setSpotlightBounds(bounds));
      }}
      onCancel={() => {
        dispatch(accountActions.selfGuidedTour.stop());
      }}
      onNextStep={() => {
        dispatch(accountActions.selfGuidedTour.nextStep());
      }}
    >
      {children}
    </TourPopoverInner>
  );
};

export default TourPopover;
