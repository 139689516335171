import { forwardRef, SVGAttributes } from 'react';

import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const CsvIcon = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <rect
          x="4"
          y="2"
          width="20"
          height="24"
          rx="4"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.32552 18.152V19.896C8.32552 19.9333 8.34933 19.9573 8.39696 19.968C8.57687 19.9893 8.71974 20 8.82557 20C9.20656 20 9.50024 19.912 9.7066 19.736C9.91826 19.5547 10.0241 19.304 10.0241 18.984C10.0241 18.3493 9.62459 18.032 8.82557 18.032C8.69328 18.032 8.54777 18.0453 8.38902 18.072C8.34669 18.0827 8.32552 18.1093 8.32552 18.152ZM7.45242 22.92C7.33072 22.92 7.22489 22.8747 7.13493 22.784C7.04498 22.6933 7 22.5867 7 22.464V17.576C7 17.448 7.04233 17.3333 7.127 17.232C7.21166 17.1307 7.31749 17.0747 7.44449 17.064C7.89426 17.0213 8.32817 17 8.7462 17C10.4395 17 11.2861 17.6347 11.2861 18.904C11.2861 19.592 11.0877 20.1147 10.6908 20.472C10.2993 20.824 9.72248 21 8.9605 21C8.75943 21 8.57158 20.992 8.39696 20.976C8.34933 20.976 8.32552 20.9973 8.32552 21.04V22.464C8.32552 22.5867 8.28054 22.6933 8.19059 22.784C8.10063 22.8747 7.9948 22.92 7.8731 22.92H7.45242Z"
          fill={color}
        />
        <path
          d="M13.4101 18.184V21.816C13.4101 21.864 13.434 21.8933 13.4816 21.904C13.5874 21.9253 13.7144 21.936 13.8626 21.936C14.397 21.936 14.7912 21.7787 15.0452 21.464C15.3045 21.1493 15.4341 20.6347 15.4341 19.92C15.4341 19.28 15.3045 18.8107 15.0452 18.512C14.7859 18.2133 14.3917 18.064 13.8626 18.064C13.7144 18.064 13.5874 18.0747 13.4816 18.096C13.434 18.1067 13.4101 18.136 13.4101 18.184ZM12.537 22.936C12.41 22.9253 12.3042 22.872 12.2196 22.776C12.1349 22.6747 12.0926 22.5573 12.0926 22.424V17.576C12.0926 17.4427 12.1349 17.328 12.2196 17.232C12.3042 17.1307 12.41 17.0747 12.537 17.064C12.9498 17.0213 13.3572 17 13.7594 17C14.7066 17 15.4368 17.2533 15.9501 17.76C16.4633 18.2667 16.72 18.9867 16.72 19.92C16.72 20.9333 16.4633 21.7013 15.9501 22.224C15.4368 22.7413 14.7066 23 13.7594 23C13.3572 23 12.9498 22.9787 12.537 22.936Z"
          fill={color}
        />
        <path
          d="M18.0251 22.92C17.9034 22.92 17.7976 22.8747 17.7076 22.784C17.6177 22.6933 17.5727 22.5867 17.5727 22.464V17.536C17.5727 17.4133 17.6177 17.3067 17.7076 17.216C17.7976 17.1253 17.9034 17.08 18.0251 17.08H20.7476C20.8693 17.08 20.9751 17.1253 21.0651 17.216C21.155 17.3067 21.2 17.4133 21.2 17.536V17.72C21.2 17.8427 21.155 17.9493 21.0651 18.04C20.9751 18.1307 20.8693 18.176 20.7476 18.176H18.9934C18.9458 18.176 18.922 18.1973 18.922 18.24V19.432C18.922 19.48 18.9458 19.504 18.9934 19.504H20.5888C20.7105 19.504 20.8164 19.5493 20.9063 19.64C20.9963 19.7253 21.0413 19.8293 21.0413 19.952V20.08C21.0413 20.2027 20.9963 20.3093 20.9063 20.4C20.8164 20.4853 20.7105 20.528 20.5888 20.528H18.9934C18.9458 20.528 18.922 20.552 18.922 20.6V22.464C18.922 22.5867 18.877 22.6933 18.7871 22.784C18.7024 22.8747 18.5992 22.92 18.4775 22.92H18.0251Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default CsvIcon;
