import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  min-height: 20px;
  margin: 4px -4px;
  color: ${colors.gray10};

  & > * {
    margin: 0 4px;
  }
`;

export const Name = styled.button`
  background: none;
  padding: 0;
  font-weight: 600;
  border: 0;
`;

export const Size = styled.div`
  font-weight: 600;
  color: ${colors.gray8};
`;

export const RemoveBtn = styled.button`
  display: block;
  padding: 0;
  border: 0;
  width: 14px;
  height: 14px;

  svg {
    position: relative;
    top: 1px;
    width: 14px;
    height: 14px;
    color: ${colors.gray10};
  }
`;
