import { createReducer } from '@reduxjs/toolkit';

import { actionTemplatesAdapter } from '@application/ActionTemplates/store/entityAdapters';
import { getInitialFilters } from '@utils';
import { actionTemplatesActions as actions } from '@application/ActionTemplates/store/actionTemplatesActions';
import { ActionTemplatesState } from '@application/ActionTemplates/models/ActionTemplatesState';
import ConfirmDeleteModal from '@components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { ActionTemplate } from '@domain/ActionTemplates/models/ActionTemplate';

export const actionTemplatesReducer = createReducer<ActionTemplatesState>(
  {
    data: actionTemplatesAdapter.getInitialState(),
    loading: false,
    meta: null,
    error: null,
    filters: {
      ...getInitialFilters(),
    },
    addEditModal: {
      show: false,
    },
    confirmDeleteModal: null,
  },
  (builder) =>
    builder
      .addCase(
        actions.getActionTemplates.pending,
        (state, { meta: { arg } }) => {
          state.loading = true;
          state.error = null;
          state.filters =
            arg === null
              ? { ...getInitialFilters() }
              : {
                  ...state.filters,
                  ...(arg || {}),
                };
        }
      )
      .addCase(actions.getActionTemplates.fulfilled, (state, { payload }) => {
        actionTemplatesAdapter.setAll(state.data, payload.data);
        state.meta = payload.meta;
        state.loading = false;
      })
      .addCase(actions.getActionTemplates.rejected, (state, { payload }) => {
        state.error = payload || null;
        state.loading = false;
      })
      .addCase(actions.toggleAddEditModal, (state, { payload }) => {
        state.addEditModal = payload;
      })
      .addCase(actions.toggleDeleteModal, (state, { payload }) => {
        state.confirmDeleteModal = payload;
      })
);
