import React from 'react';
import ShowMoreText from 'react-show-more-text';
import { useIntl } from 'react-intl';

import { Container } from './styled';

interface IProps {
  text: string;
  e2eDataAttr?: string;
}

const IssueDetailsEventCommentText: React.FC<
  React.PropsWithChildren<IProps>
> = ({ text, e2eDataAttr }) => {
  const { formatMessage } = useIntl();

  return (
    <Container data-e2e={e2eDataAttr}>
      <ShowMoreText
        lines={10}
        more={formatMessage({ id: 'ShowMore' })}
        less={formatMessage({ id: 'ShowLess' })}
        anchorClass="show-more-link"
        keepNewLines
      >
        {text}
      </ShowMoreText>
    </Container>
  );
};

export default IssueDetailsEventCommentText;
