import { forwardRef, SVGAttributes } from 'react';

import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Cloud = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.gray8, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <g clipPath="url(#clip0)">
          <path
            d="M9.67234 4.13635C8.94957 3.44862 8.04839 2.97741 7.07119 2.77627C6.094 2.57512 5.07997 2.65211 4.14435 2.99848C3.20872 3.34484 2.389 3.9467 1.77835 4.73567C1.16769 5.52463 0.790571 6.46907 0.689849 7.46165C0.589127 8.45424 0.768838 9.45517 1.20856 10.3507C1.64828 11.2463 2.33039 12.0005 3.17737 12.5278C4.02434 13.055 5.00223 13.3342 5.99991 13.3334H11.9999C12.884 13.3334 13.7318 12.9822 14.3569 12.3571C14.9821 11.732 15.3332 10.8841 15.3332 10.0001C15.3332 9.11603 14.9821 8.26818 14.3569 7.64306"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.5553 2.66675L8.88867 9.34008L6.88867 7.34008"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

export default Cloud;
