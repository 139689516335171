import { AuditTemplateDetails } from '@repo/shared/types';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { AnswerType, AuditTemplateType, ItemType } from '@repo/shared/enums';
import { getPristineAuditTemplateItem } from '@application/AuditTemplateBuilder/utils/getPristineAuditTemplateItem';
import { IAnswerData, IScore, ITemplateItem } from '@repo/shared/types';
import { getAnswerTypeData } from '@utils';
import { getAuditTemplateBase } from '@application/AuditTemplateBuilder/utils/getAuditTemplateBase';

export function getPristineAuditTemplateDetails({
  templateType,
  answerTypes,
  scoreSystem,
}: {
  templateType: AuditTemplateType;
  answerTypes: { answerType: AnswerType; data: IAnswerData }[];
  scoreSystem: IScore;
}): AuditTemplateDetails {
  const root = getPristineAuditTemplateItem({
    parentId: null,
    itemType: ItemType.Root,
    answerType: null,
    text: 'Root',
    index: 0,
  });

  const section = getPristineAuditTemplateItem({
    parentId: root.id,
    itemType: ItemType.Section,
    answerType: null,
    text: intl.formatMessage({ id: 'Section' }),
    index: 0,
  });

  root.childrenIds.push(section.id);

  const items = [root, section];

  for (let i = 0; i < 2; i++) {
    const answerType =
      templateType === AuditTemplateType.Audit
        ? AnswerType.PassFailButtons
        : AnswerType.Checklist;

    const item = getPristineAuditTemplateItem({
      parentId: section.id,
      itemType: ItemType.Item,
      answerType,
      data: getAnswerTypeData(answerType, answerTypes),
      text: intl.formatMessage({ id: 'Item' }),
      index: i,
    });

    section.childrenIds.push(item.id);

    items.push(item);
  }

  return {
    ...getAuditTemplateBase({ templateType, scoreSystem }),
    data: items.reduce<Record<string, ITemplateItem>>((acc, item) => {
      const a = acc;
      a[item.id] = item;
      return a;
    }, {}),
  };
}
