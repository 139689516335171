import { ThunkDispatch } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { auditTemplatesSelectors } from '@application/AuditTemplates/store/auditTemplatesSelectors';
import { auditTemplatesActions } from '@application/AuditTemplates/store/auditTemplatesActions';

export async function hasTemplatesCreatedByUser(
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => IRootState
) {
  const { data: auditTemplates } =
    auditTemplatesSelectors.getTemplatesGroups(getState());

  if (auditTemplates.length === 0) {
    await dispatch(auditTemplatesActions.getTemplateGroups());
  }

  return auditTemplatesSelectors
    .getTemplatesGroups(getState())
    .data.some(({ isSample }) => !isSample);
}
