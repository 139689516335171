import React from 'react';

import { colors } from '@repo/shared/config';

interface IProps {}

const OfflineIcon: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8005 6.334L17.3148 6.45259C17.3536 6.61156 17.4678 6.74149 17.6204 6.80044L17.8005 6.334ZM4.00111 8.12573L4.1256 8.60999C4.34979 8.55235 4.50511 8.34837 4.50103 8.11693L4.00111 8.12573ZM2.74964 15.8977C2.98867 16.0359 3.29453 15.9543 3.4328 15.7152C3.57107 15.4762 3.48939 15.1703 3.25036 15.0321L2.74964 15.8977ZM18.6997 14.6006C18.4789 14.7664 18.4344 15.0799 18.6002 15.3007C18.7661 15.5214 19.0795 15.566 19.3003 15.4001L18.6997 14.6006ZM15.5202 13.3536C15.7155 13.1583 15.7155 12.8417 15.5202 12.6465C15.325 12.4512 15.0084 12.4512 14.8131 12.6464L15.5202 13.3536ZM10.5 17.6664L10.1464 18.0199C10.3417 18.2152 10.6583 18.2152 10.8535 18.0199L10.5 17.6664ZM7.85355 14.3128C7.65829 14.1175 7.34171 14.1175 7.14645 14.3128C6.95118 14.5081 6.95118 14.8247 7.14645 15.0199L7.85355 14.3128ZM4.5 8C4.5 4.41015 7.41015 1.5 11 1.5V0.5C6.85786 0.5 3.5 3.85786 3.5 8H4.5ZM17.6204 6.80044C19.3058 7.45124 20.5 9.08678 20.5 11H21.5C21.5 8.65987 20.0387 6.66228 17.9806 5.86757L17.6204 6.80044ZM11 1.5C14.0561 1.5 16.6207 3.60957 17.3148 6.45259L18.2863 6.21542C17.4854 2.93507 14.5277 0.5 11 0.5V1.5ZM1.5 12C1.5 10.3695 2.61543 8.99822 4.1256 8.60999L3.87662 7.64148C1.93521 8.14057 0.5 9.90205 0.5 12H1.5ZM4.50103 8.11693C4.50034 8.07804 4.5 8.03906 4.5 8H3.5C3.5 8.04493 3.5004 8.08978 3.50118 8.13454L4.50103 8.11693ZM3.25036 15.0321C2.20291 14.4261 1.5 13.2949 1.5 12H0.5C0.5 13.6663 1.40589 15.1204 2.74964 15.8977L3.25036 15.0321ZM20.5 11C20.5 12.4719 19.7938 13.7787 18.6997 14.6006L19.3003 15.4001C20.6351 14.3976 21.5 12.7997 21.5 11H20.5ZM14.8131 12.6464L10.1465 17.3128L10.8535 18.0199L15.5202 13.3536L14.8131 12.6464ZM10.8536 17.3128L7.85355 14.3128L7.14645 15.0199L10.1464 18.0199L10.8536 17.3128Z"
        fill={colors.blue2}
      />
    </svg>
  );
};

export default OfflineIcon;
