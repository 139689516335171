import styled from 'styled-components';
import AntButton from 'antd/es/button';

import { buttonStyles } from '@repo/shared/styles';

const Button = styled(AntButton)`
  &${buttonStyles}
`;

export default Button;
