import { v4 as uuid } from 'uuid';

import { AuditTemplateGroupDto } from '@infrastructure/AuditTemplates/models/AuditTemplateGroupDto';
import { AuditTemplateGroup } from '@domain/AuditTemplates/models/AuditTemplateGroup';

export function transformAuditTemplateGroupDto(
  dto: AuditTemplateGroupDto
): AuditTemplateGroup {
  return {
    id: uuid(),
    ...dto,
  };
}
