import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { usersActions } from '@store';

import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import JobTitlesActionsModal from '@components/users/jobTitles/JobTitlesActionsModal';
import ConfirmDeleteJobTitlesModal from '@components/users/jobTitles/ConfirmDeleteJobTitlesModal';
import JobTitlesTable from '@components/users/jobTitles/JobTitlesTable/JobTitlesTable';
import AddEditJobTitleModal from '@components/users/jobTitles/AddEditJobTitleModal/AddEditJobTitleModal';
import JobTitlesHeader from '@components/users/jobTitles/JobTitlesHeader';

interface IProps {}

const JobTitles: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(usersActions.jobTitles.getData());

    return () => {
      dispatch(usersActions.jobTitles.toggleAddEditModal({ show: false }));
    };
  }, []);

  return (
    <>
      <SampleDataAlert />
      <JobTitlesHeader />
      <JobTitlesTable />
      <JobTitlesActionsModal />
      <ConfirmDeleteJobTitlesModal />
      <AddEditJobTitleModal />
    </>
  );
};

export default JobTitles;
