import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { accountSelectors, usersActions, usersSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import {
  AvatarContainer,
  Content,
  ProfileSection,
} from '@components/account/EditProfile/styled';

import UserContentWrapper, {
  UserPage,
} from '@components/users/single/UserContentWrapper/UserContentWrapper';
import HeaderActionMenu from '@components/users/single/UserDetails/HeaderActionMenu/HeaderActionMenu';
import Avatar from '@components/shared/ant/Avatar';
import SetUserPasswordModal from '@components/users/list/SetUserPasswordModal';
import AddEditUserModal from '@components/users/list/AddEditUserModal/AddEditUserModal';
import DeleteUserModal from '@components/users/list/DeleteUserModal';

interface IProps {}

const UserDetails: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { loading, data } = useSelector(usersSelectors.list.getUserDetails);

  const loggedInUser = useSelector(accountSelectors.getCurrentUser);
  const isAdmin = useSelector(accountSelectors.isAdmin);
  const isCurrentUser = data?.id === loggedInUser.id;

  return (
    <UserContentWrapper
      page={UserPage.Details}
      extra={<HeaderActionMenu user={data} />}
    >
      {loading || !data ? (
        <Skeleton active />
      ) : (
        <Content>
          <AvatarContainer>
            <Avatar user={data} size={80} />
          </AvatarContainer>
          <ProfileSection>
            <h3>{formatMessage({ id: 'Email' })}</h3>
            <span>{data?.email}</span>
          </ProfileSection>
          {!!data?.phone && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Phone' })}</h3>
              <span>{data?.phone}</span>
            </ProfileSection>
          )}
          {!!data?.role.name && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Role' })}</h3>
              <span>{data?.role.name}</span>
            </ProfileSection>
          )}
          {isAdmin && !isCurrentUser && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Password' })}</h3>
              <button
                type="button"
                onClick={() => {
                  dispatch(
                    usersActions.list.toggleSetUserPasswordModal(data.id)
                  );
                }}
              >
                {formatMessage({ id: 'SetPassword' })}
              </button>
            </ProfileSection>
          )}
        </Content>
      )}
      <SetUserPasswordModal />
      <AddEditUserModal />
      <DeleteUserModal />
    </UserContentWrapper>
  );
};

export default UserDetails;
