import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: inline-flex;
  background: ${colors.gray3};
  align-items: center;
  height: 32px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${colors.gray5};
  }
`;

export const ShowModalButton = styled.button`
  border: 0;
  background: none;
  padding: ${({ showQty }: { showQty: boolean }) =>
    showQty ? '0 7px 0 10px' : '0 10px'};
  display: flex;
  height: 32px;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${colors.gray8};
    width: 15px;
    height: 15px;
  }
`;

export const Info = styled.div`
  margin-right: 10px;

  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    cursor: pointer;
  }
`;

export const SelectedFilters = styled.div`
  background: ${colors.blue2};
  color: ${colors.white};
  font-size: 12px;
  font-weight: 600;
`;

export const ResetFilters = styled.button`
  background: ${colors.red};
  border: 0;
  padding: 0;
  cursor: pointer;

  svg {
    color: ${colors.white};
    width: 14px;
    height: 14px;
  }
`;
