import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getColumnSortProperties } from '@utils';
import { IAuditObjectAttributeAssignment } from '@repo/shared/types';
import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
  generalSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission } from '@hooks/account';
import { useAppDispatch } from '@hooks';

import Table from '../../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../../shared/ant/EmptyTable/EmptyTable';
import AttributesAssignmentsActionsMenu from '@components/auditObjects/attributes/single/AttributesAssignments/AttributesAssignmentsTable/AttributesAssignmentsActionsMenu';

interface IProps {
  auditObjectId?: string;
  attributeId?: string;
}

const AttributesAssignmentsTable: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  attributeId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, error, data, meta } = useSelector(
    auditObjectsSelectors.attributesAssignments.getData
  );
  const filters = useSelector(
    auditObjectsSelectors.attributesAssignments.getFilters
  );

  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );
  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    return () => {
      dispatch(auditObjectsActions.attributesAssignments.resetData());
    };
  }, []);

  return (
    <Table<any>
      onPageChange={(update) =>
        dispatch(auditObjectsActions.attributesAssignments.getData(update))
      }
      loading={loading}
      meta={meta}
      error={error}
      columns={[
        ...(!auditObjectId
          ? [
              {
                title: auditObjectName.single,
                dataIndex: 'auditObjectName',
                key: 'auditObjectName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'auditObjectName'
                ),
              },
            ]
          : []),
        ...(!attributeId
          ? [
              {
                title: formatMessage({ id: 'Attribute' }),
                dataIndex: 'attributeName',
                key: 'attributeName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'attributeName'
                ),
              },
            ]
          : []),
        {
          title: formatMessage({ id: 'Value' }),
          dataIndex: 'attributeValue',
          key: 'attributeValue',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'attributeValue'
          ),
        },
        ...(!isLocationsReadonly && canManageAuditsObjects
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (
                  _: any,
                  assignment: IAuditObjectAttributeAssignment
                ) => (
                  <AttributesAssignmentsActionsMenu
                    assignmentId={assignment.id}
                  />
                ),
              },
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((assignment: IAuditObjectAttributeAssignment) => {
              const attribute = attributesMap[assignment.attributeId];

              return {
                key: assignment.id,
                id: assignment.id,
                auditObjectName:
                  auditObjectsMap[assignment.auditObjectId]?.name,
                attributeName: attribute?.name,
                attributeValue: attribute?.options.find(
                  (option) => option.id === assignment.optionId
                )?.name,
              };
            })
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage({
                  id: 'NoAttributes',
                })}
                {!isLocationsReadonly && canManageAuditsObjects && (
                  <>
                    <br />
                    {formatMessage({ id: 'YouCanAssignAttributeRightNow' })}
                  </>
                )}
              </>
            }
            button={
              !isLocationsReadonly && canManageAuditsObjects
                ? {
                    text: `+ ${formatMessage({ id: 'AssignAttribute' })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        auditObjectsActions.attributesAssignments.toggleAddModal(
                          true
                        )
                      );
                    },
                  }
                : null
            }
          />
        ),
      }}
      canSelectRow={!isLocationsReadonly && canManageAuditsObjects}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          auditObjectsActions.attributesAssignments.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default AttributesAssignmentsTable;
