import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  CancelBtn,
  SubmitButton,
  Title,
  Subtitle,
  LeaveFeedbackContainer,
  BottomBtnsRow,
  HowCanWeImprove,
  TextArea,
} from './styled';
import { useAppDispatch } from '@hooks';
import { accountSelectors, generalActions, generalSelectors } from '@store';
import { FeedbackEvent, FeedbackModalPage } from '@repo/shared/enums';
import { useOpacityTransition } from '@hooks';
import { notification } from '@utils';

import Stars from './Stars/Stars';

interface IProps {
  closeModal: () => void;
}

const LeaveFeedback: React.FC<React.PropsWithChildren<IProps>> = ({
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [text, setText] = useState('');
  const [processing, setProcessing] = useState(false);

  const rating = useSelector(generalSelectors.feedbackModal.getRating);
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const transition = useOpacityTransition(rating !== undefined);

  const displayDontShowBtn =
    !!currentUser?.feedbackModalDisplayDontShowAgainButton;

  return (
    <LeaveFeedbackContainer>
      <div>
        <Title>{formatMessage({ id: 'EnjoyingMonitorQAApp' })}</Title>
        <Subtitle>{formatMessage({ id: 'TapStarToRateIt' })}</Subtitle>
      </div>
      <Stars
        rating={rating}
        onClick={(newRating) => {
          dispatch(generalActions.feedbackModal.setRating(newRating));
        }}
      />
      <div>
        {transition.map(({ item, props, key }) =>
          item ? (
            <HowCanWeImprove key={key} style={props}>
              <h3>{formatMessage({ id: 'HowCanWeImprove?' })}</h3>
              <TextArea
                rows={5}
                disabled={processing}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </HowCanWeImprove>
          ) : null
        )}
        <SubmitButton
          type="primary"
          disabled={rating === undefined || processing}
          loading={processing}
          onClick={async () => {
            if (rating === undefined) {
              return;
            }

            setProcessing(true);

            const resultAction = await dispatch(
              generalActions.feedbackModal.saveFeedback({
                event: FeedbackEvent.Submit,
                rating,
                text,
              })
            );

            if (
              generalActions.feedbackModal.saveFeedback.fulfilled.match(
                resultAction
              )
            ) {
              dispatch(
                generalActions.feedbackModal.setPage(
                  rating > 3
                    ? FeedbackModalPage.Capterra
                    : FeedbackModalPage.ThankYou
                )
              );
            } else {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileSavingFeedback' }),
              });
            }

            setProcessing(false);
          }}
        >
          {formatMessage({ id: 'Submit' })}
        </SubmitButton>
        <BottomBtnsRow displayDontShowBtn={displayDontShowBtn}>
          {displayDontShowBtn && (
            <CancelBtn
              type="button"
              onClick={() => {
                dispatch(
                  generalActions.feedbackModal.saveFeedback({
                    event: FeedbackEvent.Reject,
                  })
                );
                closeModal();
              }}
            >
              {formatMessage({ id: 'DontShowAgain' })}
            </CancelBtn>
          )}
          <CancelBtn type="button" onClick={closeModal}>
            {formatMessage({ id: 'AskMeLater' })}
          </CancelBtn>
        </BottomBtnsRow>
      </div>
    </LeaveFeedbackContainer>
  );
};

export default LeaveFeedback;
