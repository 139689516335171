import React from 'react';

import { useAppDispatch } from '@trends/shared/store';
import { generalActions } from '@store';
import { FileMetadata, IFile } from '@repo/shared/types';
import { IApiService } from '@repo/shared/types';
import { FileSourceType, ThumbSize } from '@repo/shared/enums';

import LibraryPreviews from '@repo/shared/components/Previews/Previews';

interface IProps {
  files: IFile[];
  thumbSize?: ThumbSize;
  onUpdate?: (file: IFile, index: number) => void;
  onRemove?: (id: string) => void;
  mode?: 'grid' | 'row';
  id?: string;
  forceFetch?: boolean;
  fileSourceType?: FileSourceType;
  getFilePath?: (file: FileMetadata, size?: number) => string;
  getUploadPath?: (fileId: string) => string;
  getMetadataPath?: (fileId: string) => string;
  containerStyle?: React.CSSProperties;
  imagePreviewStyle?: React.CSSProperties;
  imagePreviewContainerStyle?: React.CSSProperties;
  apiService?: IApiService;
  skipCompanyId?: boolean;
  onConflictError?: (error: string) => boolean;
}

const Previews: React.FC<React.PropsWithChildren<IProps>> = ({
  onUpdate,
  onConflictError,
  getUploadPath,
  fileSourceType,
  ...props
}) => {
  const dispatch = useAppDispatch();

  return (
    <LibraryPreviews
      onUpdate={
        onUpdate
          ? async (updatedFile, index) => {
              const resultAction = await dispatch(
                generalActions.uploadFile({
                  file: updatedFile,
                  getUploadPath,
                  source: fileSourceType,
                })
              );

              if (generalActions.uploadFile.fulfilled.match(resultAction)) {
                onUpdate(updatedFile, index);

                return true;
              } else {
                if (
                  resultAction.payload?.errorCode === 409 &&
                  resultAction.payload?.error !== undefined &&
                  onConflictError !== undefined
                ) {
                  onConflictError(resultAction.payload?.error);
                }

                return false;
              }
            }
          : undefined
      }
      {...props}
    />
  );
};

export default Previews;
