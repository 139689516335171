import React from 'react';
import { useIntl } from 'react-intl';

import { Container, GirlOnBike, Title, Description, Content } from './styled';

interface IProps {}

const Processing: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Content>
        <GirlOnBike />
        <Title>{formatMessage({ id: 'YourPaymentIsBeingProcessed' })}...</Title>
        <Description>
          {formatMessage({ id: 'PleaseDontCloseThePage' })}
        </Description>
      </Content>
    </Container>
  );
};

export default Processing;
