import React from 'react';
import { useIntl } from 'react-intl';

import { IScore } from '@repo/shared/types';
import { generalActions } from '@store';
import { Modal } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  score: IScore;
}

const ScoresActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  score,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'EditScore' }),
          onClick: () => {
            dispatch(
              generalActions.showModal({ name: Modal.AddEdit, data: score })
            );
          },
        },
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () =>
            dispatch(
              generalActions.showModal({
                name: Modal.ConfirmDelete,
                data: score,
              })
            ),
        },
      ]}
    />
  );
};

export default ScoresActionsMenu;
