import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { auditObjectsActions } from '@store';

import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import AttributesTable from '@components/auditObjects/attributes/list/AttributesTable/AttributesTable';
import AttributesHeader from '@components/auditObjects/attributes/list/AttributesHeader';
import AttributesActionsModal from '@components/auditObjects/attributes/list/AttributesActionsModal';
import ConfirmDeleteAttributesModal from '@components/auditObjects/attributes/list/ConfirmDeleteAttributesModal';
import AddEditAttributeModal from '@components/auditObjects/attributes/list/AddEditAttributeModal/AddEditAttributeModal';

interface IProps {}

const Attributes: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(auditObjectsActions.attributes.getData());

    return () => {
      dispatch(
        auditObjectsActions.attributes.toggleAddEditModal({ show: false })
      );
    };
  }, []);

  return (
    <>
      <SampleDataAlert />
      <AttributesHeader />
      <AttributesTable />
      <AttributesActionsModal />
      <ConfirmDeleteAttributesModal />
      <AddEditAttributeModal />
    </>
  );
};

export default Attributes;
