import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -2px;

  & > * {
    margin: 0 2px;
  }
`;
