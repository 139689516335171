import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { auditPerformanceReportSelectors } from './auditPerformance-report.selectors';
import { createGetEntitiesThunk } from '../../../utils/apiHelpers';
import {
  IAuditStats,
  IAuditsTimeStats,
  ICompletedAuditDetails,
  ICompletedAuditDetailsFilters,
  IGetCompletedAuditDetailsResponse,
  IPagedResponse,
  ISharedFilters,
} from '@repo/shared/types';
import { ExportType, HttpMethods } from '@repo/shared/enums';
import {
  createGetAuditCompletionThunk,
  createGetAuditCompletionTime,
  createGetAuditPerformanceThunk,
  createGetScoreBreakdownThunk,
  createGetSectionPerformanceThunk,
  prepareReportPDFRequestBody,
} from '@utils';
import {
  getErrorMessage,
  getExportFileMetaData,
  parseContentDispositionHeader,
  saveFile,
} from '@repo/shared/utils';
import { apiUrls } from '@config';
import { generalActions, generalSelectors } from '@store';
import { Logger } from '@repo/shared/services';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

const getAuditsTimeStats = createGetEntitiesThunk<
  IAuditsTimeStats,
  ISharedFilters,
  IAuditsTimeStats
>({
  apiUrl: `${apiUrls.reports.auditPerformance}/audits-time-stats`,
  entityName: 'reports/auditPerformance/getAuditsTimeStats',
  filtersSelector: generalSelectors.getSharedFilters,
  method: HttpMethods.post,
});
const getSectionPerformance = createGetSectionPerformanceThunk(
  auditPerformanceReportSelectors.getSectionPerformanceFilters
);
const getAuditCompletion = createGetAuditCompletionThunk(
  auditPerformanceReportSelectors.getAuditCompletionFilters
);
const getAuditPerformance = createGetAuditPerformanceThunk(
  auditPerformanceReportSelectors.getAuditPerformanceFilters
);
const getAuditCompletionTime = createGetAuditCompletionTime(
  generalSelectors.getSharedFilters
);
const getAuditStats = createGetEntitiesThunk<
  IAuditStats,
  ISharedFilters,
  IAuditStats
>({
  apiUrl: `${apiUrls.reports.auditPerformance}/audits-stats`,
  entityName: 'reports/auditPerformance/getAuditStats',
  filtersSelector: generalSelectors.getSharedFilters,
  method: HttpMethods.post,
});
const getCompletedAuditDetails = createGetEntitiesThunk<
  ICompletedAuditDetails,
  ICompletedAuditDetailsFilters,
  IPagedResponse<IGetCompletedAuditDetailsResponse>
>({
  apiUrl: `${apiUrls.reports.auditPerformance}/completed-audits`,
  entityName: 'reports/auditPerformance/getCompletedAuditDetails',
  filtersSelector:
    auditPerformanceReportSelectors.getCompletedAuditDetailsFilters,
  method: HttpMethods.post,
});

export const auditPerformanceReportActions = {
  getAuditPerformanceReport: createAsyncThunk<
    void,
    Partial<ISharedFilters> | undefined,
    { rejectValue: string; state: IRootState }
  >('reports/auditPerformance/get', async (filters, { dispatch }) => {
    if (filters) {
      dispatch(generalActions.setSharedFilters(filters));
    }

    const getScoreBreakDown = createGetScoreBreakdownThunk(
      generalSelectors.getSharedFilters
    );

    dispatch(getScoreBreakDown());
    dispatch(getAuditsTimeStats());
    dispatch(getAuditStats());
    dispatch(getSectionPerformance());
    dispatch(getAuditCompletion());
    dispatch(getAuditPerformance());
    dispatch(getAuditCompletionTime());
    dispatch(getCompletedAuditDetails());
  }),
  getAuditsTimeStats,
  setReportLoadingStatus: createAction<boolean>(
    'reports/auditPerformance/setLoading'
  ),
  getAuditCompletion,
  getAuditStats,
  getCompletedAuditDetails,
  getAuditPerformance,
  getSectionPerformance,
  exportReportAsPDF: createAsyncThunk<void, undefined, { state: IRootState }>(
    'reports/exportReportAsPDF',
    async (_, { getState, rejectWithValue }) => {
      const state = getState();

      const { auditCompletion, sectionPerformance, auditPerformance } =
        state.reports.summary;

      try {
        const { data, headers } = await apiService.post<{
          data: 'arraybuffer';
          headers: any;
        }>({
          responseType: 'arraybuffer',
          url: `${apiUrls.reports.auditPerformance}/export-pdf`,
          body: prepareReportPDFRequestBody<ISharedFilters>({
            state,
            sectionPerformanceCompare: sectionPerformance.comparables,
            auditPerformanceCompare: auditPerformance.comparables,
            auditCompletionCompare: auditCompletion.comparables,
            reportFilters: generalSelectors.getSharedFilters(state),
          }),
          withHeaders: true,
        });

        const { mimeType } = getExportFileMetaData(ExportType.Pdf);

        saveFile({
          data,
          fileName:
            parseContentDispositionHeader(headers['content-disposition']) ||
            'report.pdf',
          mimeType,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  exportCompletedAudits: createAsyncThunk<
    void,
    ExportType,
    { state: IRootState }
  >(
    'reports/exportCompletedAuditDetails',
    async (exportType, { getState, rejectWithValue }) => {
      const state = getState();

      const { mimeType, urlSuffix } = getExportFileMetaData(exportType);

      try {
        const { data, headers } = await apiService.post<{
          data: 'arraybuffer';
          headers: any;
        }>({
          responseType: 'arraybuffer',
          url: `${apiUrls.reports.auditPerformance}/completed-audits/export-${urlSuffix}`,
          body: {
            ...generalSelectors.getSharedFilters(state),
          },
          withHeaders: true,
        });

        saveFile({
          data,
          fileName:
            parseContentDispositionHeader(headers['content-disposition']) ||
            'completed-audits.pdf',
          mimeType,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
};
