import styled from 'styled-components';
import AntSlider from 'antd/es/slider';

import { colors } from '@repo/shared/config';

const StyledSlider = styled(AntSlider)`
  .ant-slider-handle {
    border-color: ${colors.blue2}!important;
  }

  .ant-slider-track,
  .ant-slider-handle {
    background-color: ${colors.blue2};
  }
`;

export default StyledSlider;
