import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountSelectors, usersActions } from '@store';
import { usePermission, useAppDispatch } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import AuditObjectContentWrapper, {
  AuditObjectPage,
} from '@components/auditObjects/single/AuditObjectContentWrapper/AuditObjectContentWrapper';
import Button from '@components/shared/ant/Button';
import UsersAccessTable from '@components/users/single/UserAccess/UsersAccessTable/UsersAccessTable';
import AssignUsersAccessModal from '@components/users/single/UserAccess/AssignUsersAccessModal/AssignUsersAccessModal';
import ConfirmDeleteUsersAccessModal from '@components/users/single/UserAccess/ConfirmDeleteUsersAccessModal';
import UsersAccessActionsModal from '@components/users/single/UserAccess/UsersAccessActionsModal';

interface IProps {}

const AuditObjectAccess: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);
  const { isJobTitlesAndAccessReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    dispatch(usersActions.access.getData({ auditObjectId: id }));

    return () => {
      dispatch(usersActions.access.toggleAssignAccessModal(false));
      dispatch(usersActions.access.toggleFiltersModal(false));
    };
  }, [id]);

  return (
    <AuditObjectContentWrapper
      page={AuditObjectPage.Access}
      extra={
        !isJobTitlesAndAccessReadonly && canManageAuditObjects ? (
          <Button
            type="primary"
            onClick={() => {
              dispatch(usersActions.access.toggleAssignAccessModal(true));
            }}
            data-e2e={e2eTestElements.users.access.openAssignModal}
          >
            {formatMessage({
              id: 'AssignAccess',
            })}
          </Button>
        ) : undefined
      }
    >
      <UsersAccessTable auditObjectId={id} />
      <AssignUsersAccessModal auditObjectId={id} />
      <ConfirmDeleteUsersAccessModal auditObjectId={id} />
      <UsersAccessActionsModal />
    </AuditObjectContentWrapper>
  );
};

export default AuditObjectAccess;
