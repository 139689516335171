import styled from 'styled-components';
import { PageHeader as AntPageHeader } from '@ant-design/pro-components';

const PageHeader = styled(AntPageHeader)`
  &.ant-page-header {
    padding: 0 0 16px;
  }

  .ant-page-header,
  .ant-page-header-heading {
    align-items: center;
  }

  .ant-page-header-heading-left {
    align-items: flex-end;

    .ant-page-header-heading-title {
      margin-right: 20px;
      text-transform: capitalize;
    }

    .ant-page-header-heading-title,
    .ant-page-header-heading-sub-title {
      line-height: 1.3;
    }
  }

  .ant-page-header-footer {
    margin-top: 0;
  }
`;

export default PageHeader;
