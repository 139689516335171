import React from 'react';
import { useIntl } from 'react-intl';

import { Container, DueDateLabelType, Label } from './styled';
import { config } from '@repo/shared/config';
import { date } from '@utils';

interface IProps {
  dueDate: string;
  showDueLabel?: boolean;
  timeZoneAbbreviation?: string;
  dueInDays?: number | null;
}

const DueDateTableCell: React.FC<React.PropsWithChildren<IProps>> = ({
  dueDate,
  showDueLabel = true,
  timeZoneAbbreviation,
  dueInDays,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      {date(dueDate).format(config.dateFormat)}
      {timeZoneAbbreviation ? ` (${timeZoneAbbreviation})` : ''}
      {showDueLabel &&
        dueInDays !== undefined &&
        dueInDays !== null &&
        dueInDays <= 7 && (
          <Label
            type={
              dueInDays < 0 ? DueDateLabelType.Error : DueDateLabelType.Warning
            }
          >
            {dueInDays < 0 && formatMessage({ id: 'PastDue' })}
            {dueInDays === 0 && formatMessage({ id: 'DueToday' })}
            {dueInDays === 1 && formatMessage({ id: 'DueTomorrow' })}
            {dueInDays > 1 &&
              dueInDays <= 7 &&
              formatMessage({ id: 'DueInXDays' }, { count: dueInDays })}
          </Label>
        )}
    </Container>
  );
};

export default DueDateTableCell;
