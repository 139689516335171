import { getInitialFilters } from '@utils';
import { AssignmentType } from '@repo/shared/enums';
import { AuditDueDateStatus } from '@application/Audits/enums/AuditDueDateStatus';
import { AuditStartDateStatus } from '@application/Audits/enums/AuditStartDateStatus';

export const getInitialAuditsFilters = () => ({
  ...getInitialFilters(),
  orderBy: 'endDate',
  auditObjectIds: [],
  auditObjectGroupIds: [],
  templateId: null,
  scoreMin: 0,
  scoreMax: 100,
  assignment: AssignmentType.all,
  assignedTo: null,
  assignedToGroup: null,
  completedBy: null,
  completedByGroup: null,
  startDate: null,
  endDate: null,
  dueDateStatus: AuditDueDateStatus.All,
  startDateStatus: AuditStartDateStatus.All,
  inProgress: null,
  pendingAuditsStatus: null,
  tagsIds: [],
  isAutoExpired: false,
  auditSchedulesIds: [],
  schedulePlansIds: [],
});
