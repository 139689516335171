import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import { useAppDispatch } from '@hooks';
import {
  Container,
  TwoColsContainer,
  LeftCol,
  RightCol,
  LeftColContent,
} from './styled';
import { routes } from '@config';
import { notification } from '@utils';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { SelfGuidedTourStep } from '@repo/shared/enums';

import ActionDetailsHeader from '@src/presentation/Actions/ActionDetails/ActionDetailsHeader/ActionDetailsHeader';
import ActionDetailsTagline from '@src/presentation/Actions/ActionDetails/ActionDetailsTagline/ActionDetailsTagline';
import ActionDetailsForm from '@src/presentation/Actions/ActionDetails/ActionDetailsForm/ActionDetailsForm';
import ActionDetailsComments from '@src/presentation/Actions/ActionDetails/ActionDetailComments/ActionDetailsComments';
import Scrollbar from '@components/shared/Scrollbar/Scrollbar';
import ActionDetailsInfo from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfo';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import { delay } from '@repo/shared/utils';

interface IProps {}

const ActionDetails: React.FC<React.PropsWithChildren<IProps>> = ({}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const action = useSelector(actionsSelectors.getActionDetailsAction);
  const loading = useSelector(actionsSelectors.getActionDetailsLoading);
  const id = useSelector(actionsSelectors.getActionDetailsId);

  const closeModal = useCallback(() => {
    dispatch(actionsActions.toggleActionDetailsModal(null));
  }, []);

  const getActionDetails = useCallback(async (id: string) => {
    const resultAction = await dispatch(actionsActions.getActionDetails(id));

    if (actionsActions.getActionDetails.rejected.match(resultAction)) {
      notification.error({
        message: formatMessage({ id: 'ErrorWhileLoadingActionDetails' }),
        description: resultAction.payload,
      });

      navigate(routes.notFound);

      await delay(300);

      closeModal();
    }
  }, []);

  useEffect(() => {
    if (id) {
      getActionDetails(id);
    }
  }, [id]);

  return (
    <Modal
      open={!!id}
      width={1190}
      forceRender
      className="modal-no-padding modal-large"
      footer={null}
      closeIcon={null}
      styles={{
        body: {
          marginBottom: 0,
        },
      }}
      onCancel={closeModal}
    >
      <Container>
        <TourPopover
          step={SelfGuidedTourStep.ActionDetails}
          placement="bottom"
          overlayStyle={{ height: 800}}
          disabledNextBtn={loading}
          scrollToSpotlight
        >
          <ActionDetailsHeader />
        </TourPopover>
        <ActionDetailsTagline />
        <TwoColsContainer>
          <LeftCol>
            <Scrollbar>
              <LeftColContent>
                <ActionDetailsForm />
                <ActionDetailsInfo action={action} />
              </LeftColContent>
            </Scrollbar>
          </LeftCol>
          <RightCol>
            <ActionDetailsComments />
          </RightCol>
        </TwoColsContainer>
      </Container>
    </Modal>
  );
};

export default ActionDetails;
