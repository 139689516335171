import { GetCompletedAuditDto } from '@infrastructure/Audits/models/GetCompletedAuditDto';
import { Audit } from '@domain/Audits/models/Audit';

export function transformCompletedAuditDtoToAudit(
  dto: GetCompletedAuditDto
): Audit {
  return {
    ...dto,
    expiredBy: null,
    expiredAtInformation: null,
    dueInDays: 0,
    canStartAudit: false,
  };
}
