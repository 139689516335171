import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

import { colors, BREAKPOINTS } from '@repo/shared/config';

export const MAX_IMG_WIDTH = 762;
export const MAX_IMG_HEIGHT = 582;

export const Container = styled.div`
  position: relative;
  background: ${colors.white};
  height: 500px;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    height: 540px;
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    height: 580px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    height: 620px;
  }
`;

export const Content = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 8px;
  color: ${colors.gray10};
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const slideDimensions = css`
  height: 440px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    height: 500px;
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    height: 540px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    height: 580px;
  }
`;

export const ImageWrapper = styled.div`
  background: ${colors.gray2};
  ${slideDimensions};

  .ant-spin {
    max-height: none !important;
  }

  .ant-spin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    ${slideDimensions};
  }
`;
