import React from 'react';

import { Container, Initials, Name } from './styled';

interface IProps {
  children: string;
  size?: number;
  inline?: boolean;
  extra?: React.ReactNode;
  extraName?: React.ReactNode;
}

const User: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  size,
  inline,
  extra,
  extraName,
}) => {
  const parts = children?.match(/\b\w/g) || [];
  const initials = ((parts.shift() || '') + (parts.pop() || '')).toUpperCase();

  return (
    <Container inline={inline}>
      <Initials size={size}>{initials}</Initials>
      <Name>
        {children}
        {extraName}
      </Name>
      {extra}
    </Container>
  );
};

export default User;
