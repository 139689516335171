import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  background: ${colors.gray3};
  border-radius: 5px;
  padding: 18px 15px;
  margin-top: 14px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const ChangeRangeBtn = styled.button`
  display: flex;
  align-items: flex-start;
  background: none;
  font-size: 16px;
  font-weight: 600;
  padding: 0 0 6px 0;
  margin-right: 36px;
  border: 0;
  border-bottom: 4px solid transparent;
  transition: border-bottom-color 0.2s ease-in-out;

  ${({
    active,
    activeBorderColor,
  }: {
    activeBorderColor: string;
    active: boolean;
  }) =>
    active &&
    `
    border-bottom-color: ${activeBorderColor};
  `};
`;

export const AuditsCount = styled.div`
  font-size: 12px;
  color: ${colors.gray6};
`;

export const ShowAllAuditsLink = styled(Link)`
  color: ${colors.gray8};
  font-size: 12px;
  margin-bottom: 8px;
`;

export const TableContainer = styled.div`
  height: 495px;

  .ant-table {
    background: ${colors.gray3};
  }

  .ant-table-thead > tr > th {
    background: ${colors.gray4};
  }

  .ant-table-tbody .ant-table-cell {
    padding: 11.2px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    white-space: nowrap;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: none;
  }
`;

export const Placeholder = styled.img`
  margin: 130px auto 0;
`;
