import React from 'react';
import { useIntl } from 'react-intl';
import AntSelect, { SelectProps } from 'antd/es/select';

import { filterOptionsByChildren } from '../../../../core/utils/misc';

import Select from '../ant/Select/Select';
import { IOption } from '@repo/shared/types';

interface IProps extends Omit<SelectProps<any>, 'value' | 'onChange'> {
  loading: boolean;
  entity: {
    options: IOption[];
    label: string;
  };
  entityGroup: {
    options: IOption[];
    label: string;
  };
  value?: {
    entityId: string | null;
    entityGroupId: string | null;
  };
  onChange: (update: {
    entityId: string | null;
    entityGroupId: string | null;
  }) => void;
  width?: string;
  e2eDataAttr?: string;
}

const { OptGroup, Option } = AntSelect;

const ENTITY_PREFIX = 'entity';
const ENTITY_GROUP_PREFIX = 'entityGroup';

const EntityOrEntityGroupSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  loading,
  entity,
  entityGroup,
  disabled,
  placeholder,
  value,
  onChange,
  e2eDataAttr,
  ...props
}) => {
  const { formatMessage } = useIntl();

  let normalizedValue = null;

  if (value?.entityGroupId) {
    normalizedValue = `${ENTITY_GROUP_PREFIX}-${value.entityGroupId}`;
  } else if (value?.entityId) {
    normalizedValue = `${ENTITY_PREFIX}-${value.entityId}`;
  }

  return (
    <Select
      {...props}
      value={normalizedValue}
      e2eDataAttr={e2eDataAttr}
      onChange={(selected) => {
        if (!onChange) {
          return;
        }

        onChange({
          entityId: selected?.includes(ENTITY_PREFIX)
            ? selected.split(`${ENTITY_PREFIX}-`)[1]
            : null,
          entityGroupId: selected?.includes(ENTITY_GROUP_PREFIX)
            ? selected.split(`${ENTITY_GROUP_PREFIX}-`)[1]
            : null,
        });
      }}
      disabled={loading || disabled}
      placeholder={
        loading ? `${formatMessage({ id: 'loading' })}...` : placeholder
      }
      loading={loading}
      showSearch
      optionFilterProp="children"
      filterOption={filterOptionsByChildren}
    >
      {entity.options.length > 0 && (
        <OptGroup label={entity.label}>
          {entity.options.map((entity: any) => (
            <Option value={`${ENTITY_PREFIX}-${entity.id}`} key={entity.id}>
              {entity.name}
            </Option>
          ))}
        </OptGroup>
      )}
      {entityGroup.options.length > 0 && (
        <OptGroup label={entityGroup.label}>
          {entityGroup.options.map((entity: any) => (
            <Option
              value={`${ENTITY_GROUP_PREFIX}-${entity.id}`}
              key={entity.id}
            >
              {entity.name}
            </Option>
          ))}
        </OptGroup>
      )}
    </Select>
  );
};

export default EntityOrEntityGroupSelect;
