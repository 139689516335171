import { combineReducers } from '@reduxjs/toolkit';

import { accountReducer } from './modules/account/account.reducer';
import { accountActions } from './modules/account/account.actions';
import { accountSelectors } from './modules/account/account.selectors';
import { auditObjectsReducer } from './modules/auditObjects/auditObjects.reducer';
import { auditObjectsActions } from './modules/auditObjects/auditObjects.actions';
import { auditObjectsSelectors } from './modules/auditObjects/auditObjects.selectors';
import { actionsReducer } from '@application/Actions/store/actionsReducer';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { dashboardReducer } from './modules/dashboard/dashboard.reducer';
import { dashboardActions } from './modules/dashboard/dashboard.actions';
import { dashboardSelectors } from './modules/dashboard/dashboard.selectors';
import { usersReducer } from './modules/users/users.reducer';
import { usersActions } from './modules/users/users.actions';
import { usersSelectors } from './modules/users/users.selectors';
import { settingsReducer } from './modules/settings/settings.reducer';
import { settingsActions } from './modules/settings/settings.actions';
import { settingsSelectors } from './modules/settings/settings.selectors';
import { rolesReducer } from './modules/roles/roles.reducer';
import { rolesActions } from './modules/roles/roles.actions';
import { rolesSelectors } from './modules/roles/roles.selectors';
import { schedulesReducer } from '@application/Schedules/store/schedulesReducer';
import { schedulePlansReducer } from '@application/SchedulePlans/store/schedulePlansReducer';
import { scoresReducer } from './modules/scores/scores.reducer';
import { scoresActions } from './modules/scores/scores.actions';
import { scoresSelectors } from './modules/scores/scores.selectors';
import { generalReducer } from './modules/general/general.reducer';
import { generalActions } from './modules/general/general.actions';
import { generalSelectors } from './modules/general/general.selectors';
import { tagsReducer } from './modules/tags/tags.reducer';
import { tagsActions } from './modules/tags/tags.actions';
import { tagsSelectors } from './modules/tags/tags.selectors';
import { summaryReportReducer } from './modules/reports/summary-report.reducer';
import { summaryReportActions } from './modules/reports/summary-report.actions';
import { summaryReportSelectors } from './modules/reports/summary-report.selectors';
import { customReportReducer } from './modules/reports/custom-report.reducer';
import { customReportActions } from './modules/reports/custom-report.actions';
import { customReportSelectors } from './modules/reports/custom-report.selectors';
import { auditPerformanceReportReducer } from './modules/reports/auditPerformance-report.reducer';
import { auditPerformanceReportActions } from './modules/reports/auditPerformance-report.actions';
import { auditPerformanceReportSelectors } from './modules/reports/auditPerformance-report.selectors';
import { itemAnalysisReportReducer } from './modules/reports/itemAnalysis-report.reducer';
import { itemAnalysisReportActions } from './modules/reports/itemAnalysis-report.actions';
import { itemAnalysisReportSelectors } from './modules/reports/itemAnalysis-report.selectors';
import { performanceReportReducer } from './modules/reports/performance-report.reducer';
import { performanceReportActions } from './modules/reports/performance-report.actions';
import { performanceReportSelectors } from './modules/reports/performance-report.selectors';

import { issuesReducer } from '@application/Issues/store/issuesReducer';
import { issueTypesReducer } from '@application/IssueTypes/store/issueTypesReducer';
import { issueTypeQrCodesReducer } from '@application/IssueTypes/store/issueTypeQrCodesReducer';
import { auditsReducer } from '@application/Audits/store/auditsReducer';
import { performAuditReducer } from '@application/PerformAudit/store/performAuditReducer';
import { actionTemplatesReducer } from '@application/ActionTemplates/store/actionTemplatesReducer';
import { auditTemplatesReducer } from '@application/AuditTemplates/store/auditTemplatesReducer';
import { auditTemplateBuilderReducer } from '@application/AuditTemplateBuilder/store/auditTemplateBuilderReducer';

const reportsReducer = combineReducers({
  summary: summaryReportReducer,
  custom: customReportReducer,
  auditPerformance: auditPerformanceReportReducer,
  itemAnalysis: itemAnalysisReportReducer,
  performance: performanceReportReducer,
});

export const combinedReducer = combineReducers({
  shared: generalReducer,
  users: usersReducer,
  tags: tagsReducer,
  auditObjects: auditObjectsReducer,
  roles: rolesReducer,
  schedules: schedulesReducer,
  schedulePlans: schedulePlansReducer,
  auditTemplates: auditTemplatesReducer,
  actionTemplates: actionTemplatesReducer,
  auditTemplateBuilder: auditTemplateBuilderReducer,
  actions: actionsReducer,
  audits: auditsReducer,
  performAudit: performAuditReducer,
  dashboard: dashboardReducer,
  account: accountReducer,
  score: scoresReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  issues: issuesReducer,
  issueTypes: issueTypesReducer,
  issueTypeQrCodes: issueTypeQrCodesReducer,
});

export {
  accountReducer,
  auditObjectsReducer,
  auditsReducer,
  actionsReducer,
  dashboardReducer,
  usersReducer,
  settingsReducer,
  rolesReducer,
  schedulesReducer,
  scoresReducer,
  generalReducer,
  tagsReducer,
};

export {
  accountActions,
  auditObjectsActions,
  actionsActions,
  dashboardActions,
  usersActions,
  settingsActions,
  rolesActions,
  scoresActions,
  generalActions,
  tagsActions,
  summaryReportActions,
  customReportActions,
  auditPerformanceReportActions,
  performanceReportActions,
  itemAnalysisReportActions,
};

export {
  accountSelectors,
  auditObjectsSelectors,
  actionsSelectors,
  dashboardSelectors,
  usersSelectors,
  settingsSelectors,
  rolesSelectors,
  scoresSelectors,
  generalSelectors,
  tagsSelectors,
  summaryReportSelectors,
  customReportSelectors,
  auditPerformanceReportSelectors,
  itemAnalysisReportSelectors,
  performanceReportSelectors,
};
