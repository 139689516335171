import { ExportType } from '@repo/shared/enums';
import { saveAs } from 'file-saver';

export function getExportFileMetaData(exportType: ExportType): {
  extension: string;
  mimeType: string;
  urlSuffix: string;
} {
  const data = {
    [ExportType.Xls]: {
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: 'xlsx',
      urlSuffix: 'xls',
    },
    [ExportType.Pdf]: {
      mimeType: 'application/pdf;charset=utf-8',
      extension: 'pdf',
      urlSuffix: 'pdf',
    },
    [ExportType.Csv]: {
      mimeType: 'text/csv',
      extension: 'csv',
      urlSuffix: 'csv',
    },
  };

  return data[exportType];
}

export function saveFile({
  data,
  fileName,
  mimeType,
}: {
  data: any;
  mimeType: string;
  fileName: string;
}): void {
  const blob = new Blob([data], {
    type: mimeType,
  });

  saveAs(blob, decodeURIComponent(fileName));
}
