import styled from 'styled-components';

export const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 -6px;

  & > * {
    margin: 0 6px;

    &:first-child {
      flex-grow: 1;
    }

    &:last-child {
      width: 120px;
    }
  }
`;
