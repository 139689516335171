import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import {
  conciseSchedulePlansAdapter,
  schedulePlanPeriodAdapter,
  schedulePlanPeriodAuditAdapter,
  schedulePlanPeriodAuditorAdapter,
  schedulePlansAdapter,
} from '@application/SchedulePlans/store/entityAdapters';
import { SchedulePlansState } from '@application/SchedulePlans/models/SchedulePlansState';

const getSchedulePlansState = (state: IRootState): SchedulePlansState =>
  state.schedulePlans;

const getSchedulePlans = createSelector(
  getSchedulePlansState,
  ({ table: { loading, error, meta, data } }) => ({
    loading,
    error,
    meta,
    data: schedulePlansAdapter.getSelectors().selectAll(data),
  })
);

const getConciseSchedulePlans = createSelector(
  getSchedulePlansState,
  ({ concise }) => ({
    ...concise,
    data: conciseSchedulePlansAdapter.getSelectors().selectAll(concise.data),
  })
);

const getSchedulePlansDictionary = createSelector(
  getSchedulePlansState,
  ({ table: { data } }) =>
    schedulePlansAdapter.getSelectors().selectEntities(data)
);

const getFilters = createSelector(
  getSchedulePlansState,
  ({ table: { filters } }) => filters
);

const getConfirmDeleteModalState = createSelector(
  getSchedulePlansState,
  ({ confirmDeleteModal }) => confirmDeleteModal
);

const getAddEditModalState = createSelector(
  getSchedulePlansState,
  ({ addEditModal }) => addEditModal
);

const getSchedulePlanDetails = createSelector(
  getSchedulePlansState,
  ({ details }) => details
);

const getSchedulePlanPeriodsFilters = createSelector(
  getSchedulePlansState,
  ({ periods }) => periods.filters
);

const getSchedulePlanDetailsCurrentPage = createSelector(
  getSchedulePlansState,
  ({ details }) => details.page
);

const getSchedulePlanPeriods = createSelector(
  getSchedulePlansState,
  ({ periods: { loading, error, meta, data } }) => ({
    loading,
    error,
    meta,
    data: schedulePlanPeriodAdapter.getSelectors().selectAll(data),
  })
);

const getSchedulePlanPeriod = createSelector(
  getSchedulePlansState,
  ({ periodDetails }) => ({
    ...periodDetails,
  })
);

const getSchedulePlanPeriodAuditors = createSelector(
  getSchedulePlansState,
  ({ periodDetails: { auditorsTable } }) => ({
    ...auditorsTable,
    data: schedulePlanPeriodAuditorAdapter
      .getSelectors()
      .selectAll(auditorsTable.data),
  })
);

const getSchedulePlanPeriodAudits = createSelector(
  getSchedulePlansState,
  ({ periodDetails: { auditsTable } }) => ({
    ...auditsTable,
    data: schedulePlanPeriodAuditAdapter
      .getSelectors()
      .selectAll(auditsTable.data),
  })
);

const isStartNewPeriodModalOpened = createSelector(
  getSchedulePlansState,
  ({ periodDetails: { showStartNewPeriodModal } }) => showStartNewPeriodModal
);

const getSchedulePlanSchedulesCount = createSelector(
  getSchedulePlansState,
  ({ details: { data } }) => data?.schedulesCount || 0
);

export const schedulePlansSelectors = {
  getSchedulePlans,
  getConciseSchedulePlans,
  getSchedulePlansDictionary,
  getFilters,
  getConfirmDeleteModalState,
  getAddEditModalState,
  getSchedulePlanDetails,
  getSchedulePlanPeriodsFilters,
  getSchedulePlanDetailsCurrentPage,
  getSchedulePlanPeriods,
  getSchedulePlanPeriod,
  getSchedulePlanPeriodAuditors,
  getSchedulePlanPeriodAudits,
  isStartNewPeriodModalOpened,
  getSchedulePlanSchedulesCount,
};
