import React from 'react';

import {
  Container,
  Header,
  CommentDate,
  User,
  Text,
  PreviewsWrapper,
  Extra,
} from './styled';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { Comment } from '@domain/common/Comment';
import { date } from '@utils';

import Avatar from '@components/shared/ant/Avatar';
import IssueDetailsEventCommentText from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsEventCommentText/IssueDetailsEventCommentText';
import Previews from '@components/shared/Previews/Previews';
import ActionDetailsCommentMenu from '@src/presentation/Actions/ActionDetails/ActionDetailComments/ActionDetailsComment/ActionDetailsCommentMenu/ActionDetailsCommentMenu';

interface IProps {
  comment: Comment;
  editable: boolean;
  isEditing?: boolean;
  containerStyles?: React.CSSProperties;
}

const ActionDetailsComment: React.FC<React.PropsWithChildren<IProps>> = ({
  comment,
  editable,
  isEditing,
  containerStyles,
}) => {
  return (
    <Container isEditing={isEditing} style={containerStyles}>
      <Header>
        <User>
          <Avatar user={comment.createdBy} size={26} />
          <span data-e2e={e2eTestElements.actions.detailsModal.comment.sender}>
            {comment.createdBy.name}
          </span>
        </User>
        <Extra>
          <CommentDate>
            {date(comment.createdAt).format(config.dateTimeFormat)}
          </CommentDate>
          {editable && (
            <div>
              <ActionDetailsCommentMenu comment={comment} />
            </div>
          )}
        </Extra>
      </Header>
      <Text>
        <IssueDetailsEventCommentText
          text={comment.body}
          e2eDataAttr={e2eTestElements.actions.detailsModal.comment.text}
        />
      </Text>
      {comment.files.length > 0 && (
        <PreviewsWrapper>
          <Previews files={comment.files} />
        </PreviewsWrapper>
      )}
    </Container>
  );
};

export default ActionDetailsComment;
