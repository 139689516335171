import React from 'react';

import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { useSetIssuesPage } from '@application/Issues/hooks/useSetIssuesPage';

import Issues from '@src/presentation/Issues/Issues';

interface IProps {}

const ResolvedIssues: React.FC<React.PropsWithChildren<IProps>> = () => {
  useSetIssuesPage(IssuesPage.Archive);

  return <Issues page={IssuesPage.Archive} />;
};

export default ResolvedIssues;
