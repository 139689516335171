import React from 'react';
import { useIntl } from 'react-intl';

import { XlsIcon } from '@icons';
import { useAppDispatch } from '@hooks';
import { actionsActions } from '@store';
import { notification } from '@utils';
import { ExportType } from '@repo/shared/enums';

import BigBtnDropdown from '@components/shared/BigBtnDropdown/BigBtnDropdown';

const ExportButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return (
    <BigBtnDropdown
      triggerText={formatMessage({ id: 'Export' })}
      width={250}
      buttons={[
        {
          content: (
            <>
              <XlsIcon size={28} />
              {formatMessage({ id: 'ExportAsXLS' })}
            </>
          ),
          onClick: async () => {
            const resultAction = await dispatch(
              actionsActions.exportActions(ExportType.Xls)
            );

            if (actionsActions.exportActions.rejected.match(resultAction)) {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileExportingActions' }),
              });
            }
          },
        },
      ]}
    />
  );
};

export default ExportButton;
