import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HeaderActionButton } from './styled';
import { useAppDispatch, usePermission } from '@hooks';
import { accountSelectors, generalActions } from '@store';
import { IUser } from '@repo/shared/types';
import { Modal, Permission } from '@repo/shared/enums';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

interface IProps {
  user?: IUser | null;
}

const HeaderActionMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  user,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const loggedInUser = useSelector(accountSelectors.getCurrentUser);
  const isCurrentUser = user?.id === loggedInUser.id;

  const canManageUsers = usePermission(Permission.CanManageUsers);

  if (!canManageUsers) {
    return null;
  }

  const menuItems = [
    {
      name: formatMessage({
        id: 'Edit',
      }),
      onClick: () => {
        dispatch(generalActions.showModal({ name: Modal.AddEdit, data: user }));
      },
    },
  ];

  if (loggedInUser && !isCurrentUser) {
    menuItems.push({
      name: formatMessage({
        id: 'Delete',
      }),
      onClick: () => {
        dispatch(
          generalActions.showModal({
            name: Modal.ConfirmDelete,
            data: user,
          })
        );
      },
    });
  }

  return (
    <DropDownMenu menuItems={menuItems}>
      <HeaderActionButton type="button">
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default HeaderActionMenu;
