import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Locale } from '@repo/shared/enums';
import { LocaleIcon, DropDownTrigger, MenuItem } from './styled';
import { accountActions, accountSelectors } from '@store';
import { notification } from '@utils/notifications';
import en from './icons/en.png';
import es from './icons/es.png';
import fr from './icons/fr.png';
import pt from './icons/pt.png';
import { useAppDispatch } from '@hooks';
import { enumToArray } from '@utils';
import { e2eTestElements } from '@config';

import DropDownMenu from '../DropDownMenu/DropDownMenu';

function getLocaleData(locale: Locale) {
  if (locale === Locale.fr) {
    return {
      name: 'Française',
      icon: fr,
    };
  }

  if (locale === Locale.es) {
    return {
      name: 'Español',
      icon: es,
    };
  }

  if (locale === Locale.pt) {
    return {
      name: 'Português',
      icon: pt,
    };
  }

  return {
    name: 'English',
    icon: en,
  };
}

const ChangeLocale: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);
  const locale = useSelector(accountSelectors.getLocale);

  const menuItems = useMemo(
    () =>
      enumToArray<Locale>(Locale).map(([, locale]) => {
        const loc = locale as Locale;
        const { name, icon } = getLocaleData(loc);

        return {
          name: (
            <MenuItem>
              <LocaleIcon src={icon} alt={name} />
              {name}
            </MenuItem>
          ),
          onClick: async () => {
            dispatch(accountActions.toggleFullScreenLoader(true));

            const resultAction = await dispatch(
              accountActions.changeLocale(loc)
            );

            if (accountActions.changeLocale.rejected.match(resultAction)) {
              notification.error({
                message: formatMessage({ id: 'Error' }),
                description: resultAction.payload,
              });
            }

            dispatch(accountActions.toggleFullScreenLoader(false));
          },
        };
      }),
    [JSON.stringify(appConfiguration || {})]
  );

  const { name, icon } = getLocaleData(locale);

  if (menuItems.length < 2) {
    return null;
  }

  return (
    <DropDownMenu
      menuItems={menuItems}
      e2eDataAttr={e2eTestElements.common.sidebar.languageChange.dropdown}
    >
      <DropDownTrigger
        data-e2e={e2eTestElements.common.sidebar.languageChange.trigger}
      >
        <LocaleIcon src={icon} alt={name} />
        {name}
      </DropDownTrigger>
    </DropDownMenu>
  );
};

export default ChangeLocale;
