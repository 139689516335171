export const actions = {
  table: 'actions-table',
  detailsModal: {
    saveBtn: 'action-details-modal-save-button',
    cancelBtn: 'action-details-modal-cancel-button',
    backBtn: 'action-details-modal-back-button',
    approveBtn: 'action-details-modal-approve-btn',
    rejectBtn: 'action-details-modal-reject-btn',
    markAsDoneBtn: 'action-details-modal-mark-as-done-btn',
    nameInput: 'action-details-modal-name-input',
    descInput: 'action-details-modal-desc-input',
    dueDateInput: 'action-details-modal-due-date-input',
    assignedTo: 'action-details-modal-assigned-to',
    tagSelect: 'action-details-modal-tag-select',
    prioritySelect: 'action-details-modal-priority-select',
    statusSelect: 'action-details-modal-status-select',
    linkedIssue: 'action-details-modal-linked-issue',
    linkedAudit: 'action-details-modal-linked-audit',
    auditObject: 'action-details-modal-audit-object',
    sendComment: {
      input: 'action-send-comment-input',
      sendBtn: 'action-send-comment-btn',
    },
    comment: {
      sender: 'action-comment-sender',
      text: 'action-comment-text',
    },
    setStatusModal: {
      submitButton: 'action-set-status-modal-submit-btn',
    },
    rightTopDropdown: 'action-header-dropdown-trigger',
    expireBtn: 'action-header-dropdown-expire-btn',
    deleteBtn: 'action-header-dropdown-delete-btn',
  },
  actionsTemplates: {
    table: 'actions-templates-table',
    addEditModal: {
      nameInput: 'actions-templates-add-edit-modal-name-input',
      descInput: 'actions-templates-add-edit-modal-desc-input',
      assignSelect: 'actions-templates-add-edit-modal-assign-select',
      prioritySelect: 'actions-templates-add-edit-modal-priority-select',
      dueDaysInput: 'actions-templates-add-edit-modal-due-days-input',
      addBtn: 'actions-templates-add-edit-modal-add-btn',
      cancelBtn: 'actions-templates-add-edit-modal-cancel-btn',
    },
    answerSettingsToggle: 'actions-templates-answer-settings-toggle',
    addActionTemplateBtn: 'actions-templates-add-action-template-btn',
    removeActionTemplateBtn: 'actions-templates-remove-action-template-btn',
    actionTemplateSelect: 'actions-templates-action-template-select',
    createWithoutTemplateBtn: 'actions-templates-create-without-template-btn',
    createNewActionTemplateBtn:
      'actions-templates-create-new-action-template-btn',
  },
};
