const colors = {
  layoutBG: '#F0F2F5',
  surface: '#001529',
  blue1: '#54C0E8',
  blue2: '#31BBE7',
  blue3: '#44ADD3',
  blue4: '#22628C',
  blue5: '#174C70',
  blue6: '#0B4368',
  blue7: '#0B3E60',
  white: '#FFFFFF',
  gray1: '#F8F8F8',
  gray2: '#F6F6F6',
  gray3: '#F3F3F3',
  gray4: '#EBEBEB',
  gray5: '#E2E2E3',
  gray6: '#DEDEE3',
  gray7: '#B5B5BE',
  gray8: '#92929D',
  gray9: '#64646F',
  gray10: '#44444A',
  black: 'rgba(0, 0, 0, 0.85)',
  green: '#00C8A8',
  red: '#FF4D4F',
  yellow: '#FAAD14',
  orange: '#FF7A45',
  wingstop: '#006938',
};

const tagColors = {
  magenta: '#F759AB',
  red: '#FF4D4F',
  calendula: '#874D00',
  darkOrange: '#D46B08',
  orange: '#FFA940',
  gold: '#FFC53D',
  lime: '#BAE637',
  green: '#73D13D',
  cyan: '#36CFC9',
  geekblue: '#597EF7',
  purple: '#9254DE',
  blue: '#40A9FF',
};

export { colors, tagColors };
