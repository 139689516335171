import React from 'react';
import Modal from 'antd/es/modal';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Calendar, MessageCircle } from 'react-feather';

import {
  ButtonRow,
  Content,
  Icon,
  BlueButton,
  Text,
  Title,
  BlueButtonsWrapper,
  Tip,
} from './styled';
import { accountSelectors, accountActions } from '@store';
import { AnalyticsEvent, SelfGuidedTourStep } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import flagIcon from '../icons/flag.png';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';

import BlueIconButton from '../../WelcomeModal/BlueIconButton/BlueIconButton';

const EndTourModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { step: tourStep } = useSelector(accountSelectors.getSelfGuidedTour);

  return (
    <Modal
      open={tourStep === SelfGuidedTourStep.EndTour}
      width={400}
      closable={false}
      onCancel={() => {}}
      footer={null}
      zIndex={2100}
      styles={{
        body: { marginBottom: 0 },
      }}
    >
      <Content>
        <Icon src={flagIcon} />
        <Title>{formatMessage({ id: 'GetStartedToday' })}</Title>
        <Text>
          {formatMessage({
            id: 'StartBuildingYourSystem',
          })}
        </Text>
        <BlueButtonsWrapper>
          <BlueIconButton
            onClick={() => {
              dispatch(
                accountActions.sendAnalyticsEvent(
                  AnalyticsEvent.LiveConsultationClicked
                )
              );

              window.open(config.urls.interactiveDemo, '_blank')?.focus();
            }}
            icon={<Calendar />}
            e2eDataAttr={
              e2eTestElements.account.selfGuidedTour.scheduleLiveConsultation
            }
            desc="Calendly"
            text={formatMessage({ id: 'ScheduleLiveConsultation' })}
          />
          <BlueIconButton
            e2eDataAttr={e2eTestElements.account.selfGuidedTour.contactSupport}
            onClick={() => {
              window.open(config.urls.support, '_blank')?.focus();
            }}
            icon={<MessageCircle />}
            desc={formatMessage({ id: 'Support' })}
            text={formatMessage({ id: 'ProblemsContactUs' })}
          />
        </BlueButtonsWrapper>
        <Tip>
          {formatMessage({ id: 'TipStartTheTourAtAnyTimeFromTheSideMenu' })}
        </Tip>
      </Content>
      <ButtonRow>
        <BlueButton
          type="primary"
          data-e2e={e2eTestElements.account.selfGuidedTour.endTourBtn}
          onClick={() => {
            dispatch(accountActions.selfGuidedTour.stop());
          }}
        >
          {formatMessage({ id: 'EndTour' })}
        </BlueButton>
      </ButtonRow>
    </Modal>
  );
};

export default EndTourModal;
