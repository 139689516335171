import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';

import { Column, Content } from './styled';
import { settingsActions, settingsSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { NotificationsGroupType, NotificationsTab } from '@repo/shared/enums';

import PageHeader from '../../shared/ant/PageHeader';
import NotificationsGroup from '@components/settings/Notifications/NotificationsGroup/NotificationsGroup';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

const Notifications: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const loading = useSelector(settingsSelectors.getNotificationsLoading);
  const currentTab = useSelector(settingsSelectors.getNotificationsTab);
  const counts = useSelector(settingsSelectors.getNotificationsCountByTab);

  useEffect(() => {
    dispatch(settingsActions.getNotifications());

    return () => {
      dispatch(settingsActions.setNotificationsTab(NotificationsTab.Audit));
    };
  }, []);

  return (
    <>
      <PageHeader
        title={formatMessage({ id: 'Notifications' })}
        footer={
          <PageTabbedSwitcher<NotificationsTab>
            activePage={currentTab}
            pages={[
              ...(counts[NotificationsTab.Audit]
                ? [
                    {
                      id: NotificationsTab.Audit,
                      name: formatMessage({ id: 'Audits' }),
                      onClick() {
                        dispatch(
                          settingsActions.setNotificationsTab(
                            NotificationsTab.Audit
                          )
                        );
                      },
                    },
                  ]
                : []),
              ...(counts[NotificationsTab.Action]
                ? [
                    {
                      id: NotificationsTab.Action,
                      name: formatMessage({ id: 'Actions' }),
                      onClick() {
                        dispatch(
                          settingsActions.setNotificationsTab(
                            NotificationsTab.Action
                          )
                        );
                      },
                    },
                  ]
                : []),
              ...(counts[NotificationsTab.Issue]
                ? [
                    {
                      id: NotificationsTab.Issue,
                      name: formatMessage({ id: 'Issues' }),
                      onClick() {
                        dispatch(
                          settingsActions.setNotificationsTab(
                            NotificationsTab.Issue
                          )
                        );
                      },
                    },
                  ]
                : []),
            ]}
          />
        }
      />
      <Content>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Column>
              {currentTab === NotificationsTab.Audit && (
                <>
                  <NotificationsGroup
                    title={formatMessage({ id: 'WhenAuditIsAssignedToYou' })}
                    group={NotificationsGroupType.AuditsAssignedToMe}
                  />
                  <NotificationsGroup
                    title={formatMessage({
                      id: 'WhenAuditIsAssignedToParticipants',
                    })}
                    group={NotificationsGroupType.AuditsAssignedToSomeoneElse}
                  />
                </>
              )}
              {currentTab === NotificationsTab.Action && (
                <>
                  <NotificationsGroup
                    title={formatMessage({
                      id: 'WhenCorrectiveActionIsAssignedToYou',
                    })}
                    group={NotificationsGroupType.ActionsAssignedToMe}
                  />
                  <NotificationsGroup
                    title={formatMessage({
                      id: 'WhenCorrectiveActionIsCreatedByYou',
                    })}
                    group={NotificationsGroupType.ActionsCreatedByMe}
                  />
                  <NotificationsGroup
                    title={formatMessage({
                      id: 'WhenCorrectiveActionIsAssignedToParticipants',
                    })}
                    group={NotificationsGroupType.ActionsAssignedToSomeoneElse}
                  />
                </>
              )}
              {currentTab === NotificationsTab.Issue && (
                <>
                  <NotificationsGroup
                    title={formatMessage({
                      id: 'WhenIssueAssignedToYou',
                    })}
                    group={NotificationsGroupType.IssuesAssignedToMe}
                  />
                  <NotificationsGroup
                    title={formatMessage({
                      id: 'WhenIssueAssignedToParticipants',
                    })}
                    group={NotificationsGroupType.IssuesAssignedToParticipants}
                  />
                </>
              )}
            </Column>
          </>
        )}
      </Content>
    </>
  );
};

export default Notifications;
