import {
  createAction,
  createAsyncThunk,
  createSlice,
  createSelector,
  configureStore,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { HistoryAudit } from '../types/HistoryAudit';
import { HistoryItem, HistoryItemDetails } from '../types/HistoryItem';
import { TrendsApiClient } from '../api/TrendsApiClient';
import { AuditDetails } from '../types/AuditDetails';
import { ResponseHistoryChartItem } from '@trends/shared/types/ResponseHistoryChartItem';
import { colors } from '@repo/shared/config';

interface Data<T> {
  loading: boolean;
  data: T;
  error?: string;
}

export interface TrendsState {
  auditDetails: Data<AuditDetails | null>;
  historyAudits: Data<HistoryAudit[]>;
  historyItems: Data<HistoryItem[]>;
  historyItemDetails: Data<HistoryItemDetails | null>;
}

function getInitialDataState<T>(defaultValue: T) {
  return {
    loading: false,
    data: defaultValue,
  };
}

const initialState: TrendsState = {
  auditDetails: getInitialDataState(null),
  historyAudits: getInitialDataState([]),
  historyItems: getInitialDataState([]),
  historyItemDetails: getInitialDataState(null),
};

const apiClient = new TrendsApiClient();

export const getHistoryAudits = createAsyncThunk<
  HistoryAudit[],
  string,
  { rejectValue: string }
>('trends/getHistoryAudits', async (auditId, { rejectWithValue }) => {
  try {
    return await apiClient.getHistoryAudits(auditId);
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});

export const getHistoryItems = createAsyncThunk<
  HistoryItem[],
  { auditId: string; itemId: string },
  { rejectValue: string }
>(
  'trends/getHistoryItems',
  async ({ auditId, itemId }, { rejectWithValue }) => {
    try {
      return await apiClient.getHistoryItems(auditId, itemId);
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const getHistoryItemDetails = createAsyncThunk<
  HistoryItemDetails,
  { auditId: string; itemId: string },
  { rejectValue: string }
>(
  'trends/getHistoryItemDetails',
  async ({ auditId, itemId }, { rejectWithValue }) => {
    try {
      return await apiClient.getHistoryItemDetails(auditId, itemId);
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const getAuditDetails = createAsyncThunk<
  AuditDetails,
  string,
  { rejectValue: string }
>('trends/getAuditDetails', async (auditId, { rejectWithValue }) => {
  try {
    return await apiClient.getAuditDetails(auditId);
  } catch (error: unknown) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }

    return rejectWithValue('An unknown error occurred');
  }
});

const setAuditDetails = createAction<AuditDetails>('trends/setAuditDetails');

export const index = createSlice({
  name: 'trends',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryAudits.pending, (state) => {
        state.historyAudits.loading = true;
        delete state.historyAudits.error;
      })
      .addCase(getHistoryAudits.fulfilled, (state, { payload }) => {
        state.historyAudits.loading = false;
        state.historyAudits.data = payload;
      })
      .addCase(getHistoryAudits.rejected, (state, { payload }) => {
        state.historyAudits.loading = false;
        state.historyAudits.error = payload;
      })
      .addCase(getHistoryItems.pending, (state) => {
        state.historyItems.loading = true;
        delete state.historyItems.error;
      })
      .addCase(getHistoryItems.fulfilled, (state, { payload }) => {
        state.historyItems.loading = false;
        state.historyItems.data = payload;
      })
      .addCase(getHistoryItems.rejected, (state, { payload }) => {
        state.historyItems.loading = false;
        state.historyItems.error = payload;
      })
      .addCase(getHistoryItemDetails.pending, (state) => {
        state.historyItemDetails.loading = true;
        delete state.historyItemDetails.error;
      })
      .addCase(getHistoryItemDetails.fulfilled, (state, { payload }) => {
        state.historyItemDetails.loading = false;
        state.historyItemDetails.data = payload;
      })
      .addCase(getHistoryItemDetails.rejected, (state, { payload }) => {
        state.historyItemDetails.loading = false;
        state.historyItemDetails.error = payload;
      })
      .addCase(getAuditDetails.pending, (state) => {
        state.auditDetails.loading = true;
        delete state.auditDetails.error;
      })
      .addCase(getAuditDetails.fulfilled, (state, { payload }) => {
        state.auditDetails.loading = false;
        state.auditDetails.data = payload;
      })
      .addCase(getAuditDetails.rejected, (state, { payload }) => {
        state.auditDetails.loading = false;
        state.auditDetails.error = payload;
      })
      .addCase(setAuditDetails, (state, { payload }) => {
        state.auditDetails.data = payload;
      });
  },
});

export const actions = {
  getHistoryAudits,
  getHistoryItems,
  getAuditDetails,
  getHistoryItemDetails,
  setAuditDetails,
};

export const selectors = {
  getHistoryAudits: (state: RootState) => state.historyAudits,
  getHistoryItems: (state: RootState) => state.historyItems,
  getAuditDetails: (state: RootState) => state.auditDetails,
  getHistoryItemDetails: (state: RootState) => state.historyItemDetails,
  getResponseChartItems: createSelector(
    (state: RootState) => state.historyItems,
    ({ data, error, loading }) => {
      if (loading || error) {
        return [];
      }

      const scoresMap: Record<string, ResponseHistoryChartItem> = {};

      for (let i = 0; i < data.length; i++) {
        const { id, scoreLabel, scoreColor, notApplicable } = data[i];

        const label = notApplicable ? 'N/A' : scoreLabel;

        if (!scoresMap[label]) {
          scoresMap[label] = {
            id,
            color: notApplicable ? colors.gray8 : scoreColor,
            label,
            count: 1,
          };
        } else {
          scoresMap[label].count++;
        }
      }

      return Object.values(scoresMap);
    }
  ),
};

export default index.reducer;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const store = configureStore({
  reducer: index.reducer,
});

export type RootState = ReturnType<typeof store.getState>;
