import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getColumnSortProperties } from '@utils';
import { IUserGroupAssignment } from '@repo/shared/types';
import {
  usersActions,
  usersSelectors,
  generalSelectors,
  accountSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';

import Table from '../../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../../shared/ant/EmptyTable/EmptyTable';
import UserGroupsAssignmentsActionsMenu from '@components/users/groups/single/UserGroupAssignments/UserGroupsAssignmentsTable/UserGroupsAssignmentsActionsMenu';

interface IProps {
  userId?: string;
  userGroupId?: string;
}

const UserGroupsAssignmentsTable: React.FC<React.PropsWithChildren<IProps>> = ({
  userId,
  userGroupId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, error, data, meta } = useSelector(
    usersSelectors.groupAssignments.getData
  );
  const filters = useSelector(usersSelectors.groupAssignments.getFilters);

  const usersMap = useSelector(generalSelectors.getConciseUsersMap);
  const userGroupsMap = useSelector(generalSelectors.getConciseUserGroupsMap);
  const canManageUsers = usePermission(Permission.CanManageUsers);
  const { isUsersGroupsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const canEdit = !isUsersGroupsReadonly && canManageUsers;

  useEffect(() => {
    return () => {
      dispatch(usersActions.groupAssignments.resetData());
    };
  }, []);

  return (
    <Table<any>
      onPageChange={(update) =>
        dispatch(usersActions.groupAssignments.getData(update))
      }
      loading={loading}
      meta={meta}
      canSelectRow={canEdit}
      error={error}
      columns={[
        ...(!userGroupId
          ? [
              {
                title: formatMessage({ id: 'Group' }),
                dataIndex: 'groupName',
                key: 'groupName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'groupName'
                ),
              },
            ]
          : []),
        ...(!userId
          ? [
              {
                title: formatMessage({ id: 'User' }),
                dataIndex: 'userName',
                key: 'userName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'userName'
                ),
              },
            ]
          : []),
        ...(canEdit
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (_: any, assignment: IUserGroupAssignment) => (
                  <UserGroupsAssignmentsActionsMenu
                    assignmentId={assignment.id}
                  />
                ),
              },
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((assignment: IUserGroupAssignment) => {
              return {
                key: assignment.id,
                id: assignment.id,
                groupName: userGroupsMap[assignment.groupId]?.name || '',
                userName: usersMap[assignment.userId]?.name || '',
              };
            })
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {filters.search
                  ? formatMessage(
                      {
                        id: 'NoAuditObjectsGroupsAssignmentsSearchResults',
                      },
                      {
                        objectName: formatMessage({
                          id: 'Users',
                        }).toLowerCase(),
                        searchStr: filters.search,
                      }
                    )
                  : formatMessage(
                      {
                        id: 'NoAuditObjectsGroupsAssignments',
                      },
                      {
                        objectName: formatMessage({
                          id: 'Users',
                        }).toLowerCase(),
                      }
                    )}
              </>
            }
            button={
              canEdit
                ? {
                    text: `+ ${formatMessage({ id: 'AddToGroup' })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        usersActions.groupAssignments.toggleAddToGroupModal(
                          true
                        )
                      );
                    },
                  }
                : null
            }
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          usersActions.groupAssignments.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default UserGroupsAssignmentsTable;
