import { MoreHorizontal } from 'react-feather';

import { DropDownTrigger } from './styled';
import { IDropdownMenuItem } from '@repo/shared/types';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

interface IProps {
  menuItems: IDropdownMenuItem[];
}

function TableRowActionsMenu({ menuItems }: IProps) {
  return (
    <DropDownMenu menuItems={menuItems} overlayStyle={{ zIndex: 5 }}>
      <DropDownTrigger onClick={(e) => e.stopPropagation()}>
        <MoreHorizontal />
      </DropDownTrigger>
    </DropDownMenu>
  );
}

export default TableRowActionsMenu;
