import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;

  input {
    padding-left: 35px !important;
  }
`;

export const Trigger = styled.button`
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 8px;
  left: 10px;

  &:before {
    display: block;
    position: absolute;
    content: '';
    background: ${({ triggerColor }: { triggerColor?: string }) =>
      triggerColor ? triggerColor : colors.gray7};
    border: 1px solid ${colors.white};
    border-radius: 100%;
    z-index: 1;
    width: 11px;
    height: 11px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Overlay = styled.div`
  background: ${colors.white};
  padding: 7px;
  width: 250px;
`;

export const Buttons = styled.div`
  display: flex;
  margin: 0 -4px;
  flex-wrap: wrap;

  & > * {
    margin: 0 4px;
  }
`;
