import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { FileSourceType } from '@repo/shared/enums';
import { AttachFilesText, PreviewsContainer } from './styled';
import { FileMetadata, IFile } from '@repo/shared/types';
import { e2eTestElements } from '@config';

import Previews from '@components/shared/Previews/Previews';
import UploadFilesTrigger from '@components/shared/UploadFiles/UploadFilesTrigger';

interface IProps {
  value?: IFile[];
  onChange?: (files: IFile[]) => void;
  disabled?: boolean;
  getFilePath?: (file: FileMetadata, size?: number) => string;
  getFileUploadPath?: (fileId: string) => string;
  getFileMetadataPath?: (fileId: string) => string;
}

const IssueQuestionAttachments: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  onChange,
  disabled,
  getFilePath,
  getFileUploadPath,
  getFileMetadataPath,
}) => {
  const { formatMessage } = useIntl();

  const valueRef = useRef<IFile[] | undefined>([]);
  valueRef.current = value;

  return (
    <>
      {!!value && value.length > 0 && (
        <PreviewsContainer>
          <Previews
            files={value}
            getFilePath={getFilePath}
            getUploadPath={getFileUploadPath}
            getMetadataPath={getFileMetadataPath}
            onRemove={
              !disabled
                ? (fileId) => {
                    if (onChange) {
                      onChange((value || []).filter(({ id }) => id !== fileId));
                    }
                  }
                : undefined
            }
            fileSourceType={FileSourceType.IssueFile}
          />
        </PreviewsContainer>
      )}
      {!disabled && (
        <UploadFilesTrigger
          getUploadPath={getFileUploadPath}
          onAddFiles={(files) => {
            if (onChange) {
              onChange([...(valueRef.current || []), ...files]);
            }
          }}
          source={FileSourceType.IssueFile}
          e2eDataAttr={
            e2eTestElements.issues.issues.createModal.addQuestionAnswerFiles
          }
        >
          <AttachFilesText>
            + {formatMessage({ id: 'AttachFiles' })}
          </AttachFilesText>
        </UploadFilesTrigger>
      )}
    </>
  );
};

export default IssueQuestionAttachments;
