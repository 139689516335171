import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { tagColors } from '@repo/shared/config';
import { ITag } from '@repo/shared/types';
import {
  Buttons,
  ColorsHeader,
  ColorButton,
  Colors,
  Hex,
  Preview,
} from './styled';
import { notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { generalActions, tagsActions, tagsSelectors } from '@store';

import Form from '../../shared/ant/Form';
import { Input } from '../../shared/ant/Input';
import Tag from '../../shared/ant/Tag/Tag';
import Button from '../../shared/ant/Button';

interface IProps {
  data?: ITag;
  closeModal: () => void;
}

const AddEditTagModalForm: React.FC<React.PropsWithChildren<IProps>> = ({
  data: tag,
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const { tagType } = useSelector(tagsSelectors.getFilters);
  const [loading, setLoading] = useState(false);
  const isEditMode = !!tag;

  useEffect(() => form.resetFields(), [tag, form]);

  const itemName = formatMessage({ id: 'Tag' }).toLowerCase();

  return (
    <Form
      form={form}
      onFinish={async (values: any) => {
        setLoading(true);

        const resultAction = await dispatch(
          tagsActions.updateTag({ id: tag?.id, tagType, ...values })
        );

        if (tagsActions.updateTag.fulfilled.match(resultAction)) {
          closeModal();
          dispatch(tagsActions.getTags());
          dispatch(generalActions.getConciseTags());

          notification.success({
            message: isEditMode
              ? formatMessage(
                  { id: 'YouHaveSuccessfullyEditedItem' },
                  { item: itemName }
                )
              : formatMessage(
                  { id: 'YouHaveSuccessfullyCreatedItem' },
                  { item: itemName }
                ),
          });
        } else {
          notification.error({
            message: isEditMode
              ? formatMessage(
                  { id: 'ErrorWhileCreatingItem' },
                  { item: itemName }
                )
              : formatMessage(
                  { id: 'ErrorWhileEditingItem' },
                  { item: itemName }
                ),
            description: resultAction.payload as string,
          });
        }

        setLoading(false);
      }}
      name="add-new-tag-form"
      layout="vertical"
      initialValues={{
        name: tag?.name || '',
        color: tag?.color || tagColors.blue,
        description: tag?.description || '',
      }}
    >
      {
        // @ts-ignore
        () => (
          <>
            <Form.Item
              name="name"
              label={formatMessage({ id: 'TagName' })}
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'PleaseEnterTagName' }),
                },
                {
                  max: 80,
                  message: formatMessage(
                    {
                      id: 'NameMustNotExceedXCharacters',
                    },
                    { count: 80 }
                  ),
                },
              ]}
            >
              <Input
                disabled={loading}
                placeholder={formatMessage({ id: 'EnterName' })}
              />
            </Form.Item>
            <ColorsHeader>{formatMessage({ id: 'Color' })}</ColorsHeader>
            <Form.Item>
              <Form.Item name="color">
                <Colors>
                  {Object.values(tagColors).map((color) => (
                    <ColorButton
                      key={color}
                      value={color}
                      onClick={() => form.setFieldsValue({ color })}
                    />
                  ))}
                </Colors>
              </Form.Item>
              <Hex>{form.getFieldValue('color')}</Hex>
            </Form.Item>
            {form.getFieldValue('name') && (
              <Form.Item label={formatMessage({ id: 'Preview' })}>
                <Preview>
                  <Tag tagcolor={form.getFieldValue('color')}>
                    {form.getFieldValue('name')}
                  </Tag>
                </Preview>
              </Form.Item>
            )}
            <Form.Item
              name="description"
              label={formatMessage({ id: 'CommentOptional' })}
            >
              <Input.TextArea disabled={loading} rows={3} />
            </Form.Item>
            <Buttons>
              <Button type="primary" htmlType="submit" loading={loading}>
                {isEditMode
                  ? formatMessage({ id: 'Save' })
                  : formatMessage({ id: 'AddTag' })}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  form.resetFields();
                  setTimeout(() => {
                    closeModal();
                  }, 50);
                }}
              >
                {formatMessage({ id: 'Cancel' })}
              </Button>
            </Buttons>
          </>
        )
      }
    </Form>
  );
};

export default AddEditTagModalForm;
