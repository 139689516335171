import styled from 'styled-components';

import { Input as StyledInput } from '../ant/Input';
import { colors } from '@repo/shared/config';

export const Input = styled(StyledInput)`
  width: ${({ width }: { width?: string }) => (width ? width : '165px')};
  vertical-align: middle;

  svg {
    height: 16px;
    width: 16px;
    color: ${colors.gray6};
  }
`;

interface CloseBtnProps {
  show: boolean;
}

export const CloseButton = styled.button`
  border: 0;
  background: none;
  cursor: ${({ show }: CloseBtnProps) => (show ? 'pointer' : 'default')};
  opacity: ${({ show }: CloseBtnProps) => (show ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  height: 16px;
  width: 16px;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 8px;

  svg {
    width: 16px;
    height: 16px;
  }
`;
