import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HeaderActionButton } from './styled';
import { useAppDispatch, usePermission } from '@hooks';
import { accountSelectors, auditObjectsActions } from '@store';
import { Permission } from '@repo/shared/enums';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

interface IProps {
  attributeId?: string;
}

const HeaderActionMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  attributeId,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);

  if (isLocationsReadonly || !canManageAuditObjects) {
    return null;
  }

  return (
    <DropDownMenu
      menuItems={[
        {
          name: formatMessage({
            id: 'Edit',
          }),
          onClick: () => {
            dispatch(
              auditObjectsActions.attributes.toggleAddEditModal({
                show: true,
                attributeId,
              })
            );
          },
        },
        {
          name: formatMessage({
            id: 'Delete',
          }),
          onClick: () => {
            if (attributeId) {
              dispatch(
                auditObjectsActions.attributes.toggleConfirmDeleteModal([
                  attributeId,
                ])
              );
            }
          },
        },
      ]}
    >
      <HeaderActionButton type="button">
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default HeaderActionMenu;
