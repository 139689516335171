import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Check, X } from 'react-feather';

import { Container } from './styled';
import { useAppDispatch, usePermission } from '@hooks';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { ActionStatus, Permission, RoleType } from '@repo/shared/enums';
import { accountSelectors } from '@store';
import { e2eTestElements } from '@config';
import { colors } from '@repo/shared/config';

import StatusButton from '@src/presentation/Actions/ActionDetails/ActionDetailsHeader/StatusButton/StatusButton';

interface IProps {}

const ActionDetailsStatusButtons: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const currentUserRoleType = currentUser?.role.roleType;
  const isAdminOrAuditor =
    currentUserRoleType === RoleType.Auditor ||
    currentUserRoleType === RoleType.Admin;

  const action = useSelector(actionsSelectors.getActionDetailsAction);

  const [canApproveCorrectiveActions, canDoCorrectiveActions] = usePermission([
    Permission.CanApproveCorrectiveActions,
    Permission.CanDoCorrectiveActions,
  ]);

  if (!action || action.status === ActionStatus.Expired) {
    return null;
  }

  const isMarkedAsDone = action.status === ActionStatus.Submitted;

  if (
    action.canMarkAsDone &&
    canDoCorrectiveActions &&
    (action.status === ActionStatus.Opened || !isAdminOrAuditor)
  ) {
    return (
      <Container>
        <StatusButton
          selected={isMarkedAsDone}
          icon={<Check />}
          selectedIconColor={colors.green}
          e2eDataAttr={e2eTestElements.actions.detailsModal.markAsDoneBtn}
          onClick={() => {
            if (!isMarkedAsDone) {
              dispatch(
                actionsActions.toggleChangeStatusModal({
                  status: ActionStatus.Submitted,
                  ids: [action.id],
                })
              );
            }
          }}
          label={formatMessage({
            id: isMarkedAsDone ? 'MarkedAsDone' : 'MarkAsDone',
          })}
        />
      </Container>
    );
  }

  if (action.canApprove && canApproveCorrectiveActions) {
    return (
      <Container>
        <StatusButton
          selected={action.status === ActionStatus.Approved}
          icon={<Check />}
          selectedIconColor={colors.green}
          e2eDataAttr={e2eTestElements.actions.detailsModal.approveBtn}
          onClick={() => {
            if (action.status !== ActionStatus.Approved) {
              dispatch(
                actionsActions.toggleChangeStatusModal({
                  status: ActionStatus.Approved,
                  ids: [action.id],
                })
              );
            }
          }}
          label={formatMessage({
            id:
              action.status === ActionStatus.Approved ? 'Approved' : 'Approve',
          })}
        />
        <StatusButton
          selected={action.status === ActionStatus.Rejected}
          selectedIconColor={colors.red}
          icon={<X />}
          e2eDataAttr={e2eTestElements.actions.detailsModal.rejectBtn}
          onClick={() => {
            if (action.status !== ActionStatus.Rejected) {
              dispatch(
                actionsActions.toggleChangeStatusModal({
                  status: ActionStatus.Rejected,
                  ids: [action.id],
                })
              );
            }
          }}
          label={formatMessage({
            id: action.status === ActionStatus.Rejected ? 'Rejected' : 'Reject',
          })}
        />
      </Container>
    );
  }

  return null;
};

export default ActionDetailsStatusButtons;
