import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const RowButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: 0;
  border: 0;
  height: 50px;
  cursor: pointer;
  padding: 0 20px 0 0;

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.green};
  }
`;

export const OptGroupHeader = styled.div`
  color: ${colors.gray8};
  margin: 7px 0 10px;
`;
