import styled from 'styled-components';

export const Container = styled.div`
  height: 220px;

  iframe {
    height: 63px !important;
  }
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -6px;

  & > * {
    margin: 0 6px;
  }
`;
