import React from 'react';

import { Container } from './styled';
import { IAbortRequest, IFileUpload, IFileUploads } from '@repo/shared/types';

import UploadItem from './UploadItem/UploadItem';

interface IProps {
  fileUploads: IFileUploads;
  onRemove: (fileId: string) => void;
  cancelTokens: Record<string, IAbortRequest>;
}

const UploadList: React.FC<React.PropsWithChildren<IProps>> = ({
  fileUploads,
  onRemove,
  cancelTokens,
}) => {
  if (Object.keys(fileUploads).length === 0) {
    return null;
  }

  return (
    <Container>
      {Object.values<IFileUpload>(fileUploads).map((upload) => (
        <UploadItem
          key={upload.file.id}
          upload={upload}
          onRemove={onRemove}
          cancelToken={cancelTokens[upload.file.id]}
        />
      ))}
    </Container>
  );
};

export default UploadList;
