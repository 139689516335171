import React from 'react';
import { useIntl } from 'react-intl';

import { ActionDetails } from '@domain/Actions/models/Action';

import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';

interface IProps {
  action: ActionDetails;
}

const ActionsDetailsQuestion: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  if (!action.question) {
    return null;
  }

  return (
    <ActionDetailsInfoItem
      label={formatMessage({ id: 'Question' })}
      content={action.question}
    />
  );
};

export default ActionsDetailsQuestion;
