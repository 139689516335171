export const templates = {
  table: 'templates-table',
  createDropdown: 'templates-header-create-dropdown',
  createAuditTemplate: 'templates-header-create-audit-template',
  createChecklistTemplate: 'templates-header-create-checklist-template',
  builder: {
    header: {
      modeSwitch: {
        setup: 'template-builder-header-switch-setup',
        pdfSettings: 'template-builder-header-switch-pdf-settings',
        build: 'template-builder-header-switch-build',
        preview: 'template-builder-header-switch-preview',
        actionTemplates: 'template-builder-header-switch-action-templates',
      },
      saveDropdown: {
        publish: 'template-builder-header-publish-button',
        save: 'template-builder-header-save-changes-button',
        dropdown: 'template-builder-header-save-button',
      },
      actions: {
        dropdownTrigger: 'template-builder-header-dropdown-trigger',
        saveAsDraft: 'template-builder-header-actions-save-draft',
        deleteTemplate: 'template-builder-header-actions-delete',
      },
      closeBuilder: 'template-builder-header-close-builder',
      backButton: 'template-builder-header-back-button',
    },
    footer: {
      report: 'template-builder-footer-report',
      createForms: 'template-builder-footer-create-forms',
      templateSettings: 'template-builder-footer-template-settings',
      actionsTemplates: 'template-builder-footer-actions-templates',
      preview: 'template-builder-footer-preview',
      prevSection: 'template-builder-footer-prev-section',
      nextSection: 'template-builder-footer-next-section',
    },
    deleteTemplateModal: {
      confirmButton: 'template-builder-confirm-delete-btn',
      closeModalButton: 'template-builder-close-confirm-delete-modal-button',
    },
    leaveBuilderModal: {
      confirmButton: 'template-builder-confirm-leave-btn',
      closeModalButton: 'template-builder-close-leave-builder-modal-button',
    },
    publishTemplateModal: {
      confirmButton: 'template-builder-confirm-publish-btn',
      closeModalButton: 'template-builder-close-publish-template-modal-button',
    },
    setup: {
      templateNameInput: 'template-builder-setup-name-input',
      templateTypeSelect: 'template-builder-setup-type-select',
      scoreSelect: 'template-builder-setup-score-select',
      descInput: 'template-builder-setup-desc-input',
      trendsSelect: 'template-builder-setup-trends-select',
      tagsSelect: 'template-builder-setup-tags-select',
      auditorSignatureSwitch: 'template-builder-setup-auditor-sign-switch',
      auditeeSignatureSwitch: 'template-builder-setup-auditee-sign-switch',
      disableNASwitch: 'template-builder-setup-disable-na-switch',
      defaultPublicAccessSwitch:
        'template-builder-setup-default-public-access-switch',
      includePdfReport: 'template-builder-setup-include-pdf-report-switch',
      restrictGalleryUsage:
        'template-builder-setup-restrictGalleryUsage-switch',
      acknowledgementInput: 'template-builder-setup-acknowledgement-input',
      disclaimerInput: 'template-builder-setup-disclaimerInput',
    },
    build: {
      chooseSection: 'template-builder-build-section',
      sectionTitleInput: 'template-builder-section-title-input',
      sectionBody: 'template-builder-section-body',
      addSection: 'template-builder-add-section',
      chooseItem: 'template-builder-choose-item',
      section: 'template-builder-section-',
      item: {
        body: 'template-builder-item-body',
        nameInput: 'template-builder-item-name-input',
        responseTypeSelect: 'template-builder-response-type-select',
        buttonsResponse: {
          passedPoints: 'template-builder-passed-failed-passed-points',
          failedPoints: 'template-builder-passed-failed-failed-points',
        },
        criticalItemSwitch: 'template-builder-item-critical-items-switch',
        requiredPhotoSwitch: 'template-builder-item-required-photo-switch',
        signatureSwitch: 'template-builder-item-signature-switch',
        informationSwitch: 'template-builder-item-information-switch',
        informationInput: 'template-builder-item-information-input',
        informationFileInput: 'template-builder-item-information-file-input',
        editIntervalsButton: 'template-builder-edit-intervals-button',
        closeIntervalsModalBtn:
          'template-builder-edit-close-intervals-modal-button',
        intervals: 'template-builder-intervals',
        saveIntervalsButton: 'template-builder-save-intervals-button',
        dropdownResponse: 'template-builder-dropdown-response',
        temperatureUnitsSwitch: 'template-builder-temperature-units-switch',
        sliderResponse: {
          min: 'template-builder-slider-min',
          max: 'template-builder-slider-max',
          interval: 'template-builder-slider-interval',
        },
      },
      addItem: 'template-builder-add-item',
    },
  },
};
