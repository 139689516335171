import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AnswerType, ItemType } from '@repo/shared/enums';
import {
  isButtonsAnswerData,
  isDropdownAnswerData,
  isNumericAnswerData,
  isSliderAnswerData,
  isTemperatureAnswerData,
  isTextAnswerData,
  isChecklistAnswerData,
} from '@utils';
import { DetailsModalItem } from '@domain/common/DetailsModalItem';

interface IProps {
  item: DetailsModalItem;
}

function findSelectedCondition<T extends { selected?: boolean }>(
  conditions: T[]
): T | undefined {
  return conditions.find(({ selected }) => selected);
}

const Response: React.FC<React.PropsWithChildren<IProps>> = ({ item }) => {
  const { formatMessage } = useIntl();

  const { isFailed, text } = useMemo(() => {
    let text: string | number = '';
    let isFailed = false;

    if (item.answerType === AnswerType.Text && isTextAnswerData(item.data)) {
      text = item.data.text;
      isFailed = false;
    }

    if (
      (item.answerType === AnswerType.PassFailButtons ||
        item.answerType === AnswerType.YesNoButtons) &&
      isButtonsAnswerData(item.data)
    ) {
      const selectedCondition = findSelectedCondition(item.data.conditions);

      if (selectedCondition) {
        text = selectedCondition.name;
        isFailed = selectedCondition.markAsFailed;
      }
    }

    if (
      item.answerType === AnswerType.Checklist &&
      isChecklistAnswerData(item.data)
    ) {
      const selectedCondition = findSelectedCondition(item.data.conditions);

      if (selectedCondition) {
        text = selectedCondition.name;
        isFailed = false;
      }
    }

    if (
      item.answerType === AnswerType.Dropdown &&
      isDropdownAnswerData(item.data)
    ) {
      const selectedCondition = findSelectedCondition(item.data.conditions);

      if (selectedCondition) {
        text = selectedCondition.name;
        isFailed = selectedCondition.markAsFailed;
      }
    }

    if (
      (item.answerType === AnswerType.Temperature &&
        isTemperatureAnswerData(item.data)) ||
      (item.answerType === AnswerType.Numeric &&
        isNumericAnswerData(item.data)) ||
      (item.answerType === AnswerType.Slider && isSliderAnswerData(item.data))
    ) {
      const selectedCondition = findSelectedCondition(item.data.conditions);

      if (
        selectedCondition &&
        selectedCondition.number !== null &&
        selectedCondition.number !== undefined
      ) {
        text = selectedCondition.number;
        isFailed = selectedCondition.markAsFailed;
      }

      if (isTemperatureAnswerData(item.data)) {
        text += ` ${item.data.units}`;
      }
    }

    if (item.notApplicable) {
      text = formatMessage({ id: 'N/A' });
    }

    return {
      isFailed,
      text,
    };
  }, []);

  if (
    item.itemType !== ItemType.Item &&
    item.itemType !== ItemType.ConditionalItem
  ) {
    return null;
  }

  return (
    <>
      {text}
      {isFailed
        ? ` (${formatMessage(
            {
              id:
                item.failedInRowCount > 1
                  ? 'ItemHasBeenFailedXTimesInRow'
                  : 'ItemHasBeenFailed',
            },
            { count: item.failedInRowCount }
          )})`
        : null}
    </>
  );
};

export default Response;
