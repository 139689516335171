import React from 'react';
import styled from 'styled-components';

import { colors, BREAKPOINTS } from '../../config';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  horizontal?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;

  & > * {
    &:first-child {
      margin-right: 8px;
    }
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    flex-direction: ${({ horizontal }: ContainerProps) =>
      horizontal ? 'row' : 'column'};

    & > * {
      &:first-child {
        ${({ horizontal }) =>
          horizontal ? 'margin-right: 8px;' : 'margin-bottom: 8px;'}
      }
    }
  }
`;

export const Label = styled.div`
  color: ${colors.gray8};
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;

  a {
    color: #000;
    text-decoration: none;
  }
`;
