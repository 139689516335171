import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Container } from './styled';
import { SelfGuidedTourStep } from '@repo/shared/enums';
import { actionsActions, actionsSelectors } from '@store';
import { ActionsFilters } from '@application/Actions/models/ActionsFilters';
import { getTablePageNumberAfterDeletion } from '@utils';
import { useQueryParamsAndGetActions } from '@application/Actions/hooks/useQueryParamsAndGetActions';
import { useAppDispatch } from '@hooks';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { getRoutesFromActionPage } from '@application/Actions/utils/getRoutesFromActionPage';

import ActionsHeader from '@src/presentation/Actions/ActionsHeader/ActionsHeader';
import ActionsTable from '@src/presentation/Actions/ActionsTable/ActionsTable';
import ActionsSideModal from '@src/presentation/Actions/ActionsSideModal/ActionsSideModal';
import DeleteActionModal from './DeleteActionModal';
import ChangeActionStatusModal from './ChangeActionStatusModal';
import ActionsFiltersModal from '@src/presentation/Actions/ActionsFiltersModal/ActionsFiltersModal';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import AssignUsersToActionsModal from '@src/presentation/Actions/AssignUsersToActionsModal';
import ExpireActionsModal from '@src/presentation/Actions/ExpireActionsModal';
import ActionDetails from '@presentation/Actions/ActionDetails/ActionDetails';
import ViewActionModalRouteHandler from '@presentation/Actions/ActionDetails/ActionDetailsRouteHandler';
import MyActionsList from '@presentation/Actions/MyActionsList/MyActionsList';

interface IProps {
  page: ActionsPage;
  initialFilters?: Partial<ActionsFilters>;
}

const Actions: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  initialFilters,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, meta } = useSelector(actionsSelectors.getActions);

  useQueryParamsAndGetActions(page, initialFilters);

  return (
    <Container>
      <SampleDataAlert />
      <TourPopover
        step={SelfGuidedTourStep.ActionsList}
        placement="bottom"
        overlayStyle={{ height: 284 }}
        disabledNextBtn={loading}
        scrollToSpotlight
      >
        <ActionsHeader page={page} />
      </TourPopover>
      {page === ActionsPage.My ? (
        <MyActionsList />
      ) : (
        <ActionsTable page={page} />
      )}
      <ActionsSideModal page={page} />
      <DeleteActionModal
        onSuccess={async (ids) => {
          if (page === ActionsPage.My) {
            ids.forEach((id) =>
              dispatch(actionsActions.deleteActionLocally(id))
            );
            navigate(getRoutesFromActionPage(page).list);
          } else {
            await dispatch(
              actionsActions.getActions({
                pageNumber: getTablePageNumberAfterDeletion(meta),
              })
            );
          }

          dispatch(actionsActions.toggleActionDetailsModal(null));
        }}
      />
      <ChangeActionStatusModal />
      <ActionsFiltersModal page={page} />
      <ExpireActionsModal />
      <AssignUsersToActionsModal />
      <ActionDetails />
      <ViewActionModalRouteHandler page={page} />
    </Container>
  );
};

export default Actions;
