import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import googleLogo from './google.png';
import { AnalyticsEvent } from '@repo/shared/enums';
import { Icon, SocButton } from './styled';
import { accountActions } from '@store';
import { routes } from '@config';
import { useAppDispatch } from '@hooks';

interface IProps {
  onBtnClick?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

const SocialAuthButton: React.FC<React.PropsWithChildren<IProps>> = ({
  onBtnClick,
  onSuccess,
  onError,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <SocButton
      type="default"
      onClick={async () => {
        if (onBtnClick) {
          onBtnClick();
        }

        const resultAction = await dispatch(accountActions.signInWithGoogle());

        if (accountActions.signInWithGoogle.fulfilled.match(resultAction)) {
          dispatch(accountActions.sendAnalyticsEvent(AnalyticsEvent.SignIn));
          navigate(routes.dashboard);

          if (onSuccess) {
            onSuccess();
          }
        } else {
          if (onError) {
            onError();
          }
        }
      }}
    >
      <Icon src={googleLogo} />
      {formatMessage({ id: 'ContinueWithGoogle' })}
    </SocButton>
  );
};

export default SocialAuthButton;
