import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Action = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M14.75 5.5V3.25M14.75 3.25V1M14.75 3.25L12.5 3.25M14.75 3.25H17M11 8.25L7.50001 11.7498L5.25001 9.49977M9.50001 3.25L7.99961 3.25008C4.27189 3.25027 1.25007 6.2722 1.25 9.99992C1.24993 13.7279 4.27204 16.75 7.99998 16.75C11.7279 16.75 14.75 13.7279 14.75 9.99999V8.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default Action;
