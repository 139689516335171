import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { AlertCircle } from 'react-feather';

import {
  actionsActions,
  actionsSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { Action } from '@components/shared/ActionsModal/styled';
import { useAppDispatch, usePermission } from '@hooks';
import { AssignWrapper, TooltipWrapper } from './styled';
import { colors } from '@repo/shared/config';
import { IConcise } from '@repo/shared/types';
import { ActionStatus, Modal, Permission } from '@repo/shared/enums';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

const SubHeader = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin: 24px 0 8px;
`;

interface IProps {
  page: ActionsPage;
}

const ActionsSideModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [canApproveCorrectiveActions, canManageCorrectiveActions] =
    usePermission([
      Permission.CanApproveCorrectiveActions,
      Permission.CanManageCorrectiveActions,
    ]);

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const actions = useSelector(actionsSelectors.getActionsDictionary);
  const action =
    selectedTableRowKeys.length === 1 ? actions[selectedTableRowKeys[0]] : null;
  const selectedAuditObjectId = useMemo(() => {
    const objects = selectedTableRowKeys.reduce<Record<string, IConcise>>(
      (acc, actionId) => {
        const a = acc;
        const action = actions[actionId];

        if (action?.auditObject?.id) {
          a[action.auditObject.id] = action.auditObject;
        }

        return a;
      },
      {}
    );

    const auditObjectsIds = Object.keys(objects);

    return auditObjectsIds.length === 1 ? auditObjectsIds[0] : null;
  }, [JSON.stringify(selectedTableRowKeys)]);

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions &&
            canManageCorrectiveActions &&
            page !== ActionsPage.Expired && (
              <Action
                onClick={() => {
                  dispatch(
                    actionsActions.toggleActionDetailsModal(
                      selectedTableRowKeys[0]
                    )
                  );

                  closeModal();
                }}
              >
                {formatMessage({ id: 'Edit' })}
              </Action>
            )}
          {canManageCorrectiveActions && (
            <>
              <Action
                onClick={() =>
                  dispatch(
                    generalActions.showModal({
                      name: Modal.ConfirmDelete,
                      data: action,
                    })
                  )
                }
              >
                {formatMessage({ id: 'Delete' })}
                {selectedTableRowKeys.length > 1
                  ? ` (${selectedTableRowKeys.length})`
                  : ''}
              </Action>
              {page === ActionsPage.Pending && (
                <>
                  <Action
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation();

                      dispatch(
                        generalActions.showModal({
                          name: Modal.ConfirmExpire,
                          data: selectedTableRowKeys,
                        })
                      );
                    }}
                  >
                    {formatMessage({ id: 'Expire' })}
                    {selectedTableRowKeys.length > 1
                      ? ` (${selectedTableRowKeys.length})`
                      : ''}
                  </Action>
                  <AssignWrapper>
                    <Action
                      onClick={(e: MouseEvent) => {
                        e.stopPropagation();
                        dispatch(
                          actionsActions.toggleAssignUsersToActionsModal({
                            actionsIds: selectedTableRowKeys,
                            auditObjectId: selectedAuditObjectId!,
                          })
                        );
                      }}
                      disabled={!selectedAuditObjectId}
                    >
                      {formatMessage({ id: 'AssignUsers' })}
                      {!!selectedAuditObjectId &&
                        selectedTableRowKeys.length > 1 &&
                        ` (${selectedTableRowKeys.length})`}
                    </Action>
                    {!selectedAuditObjectId && (
                      <TooltipWrapper>
                        <CircleTooltip
                          circleBg={colors.red}
                          hoverCircleBg={colors.red}
                          icon={<AlertCircle />}
                          contentMaxWidth={270}
                        >
                          <p>
                            {formatMessage({
                              id: 'ActionsFromDifferentAuditObjectsHaveBeenSelected',
                            })}
                          </p>
                        </CircleTooltip>
                      </TooltipWrapper>
                    )}
                  </AssignWrapper>
                </>
              )}
            </>
          )}
          {canManageCorrectiveActions &&
            canApproveCorrectiveActions &&
            page === ActionsPage.Pending && (
              <>
                <SubHeader>{formatMessage({ id: 'SetStatus' })}</SubHeader>
                <Action
                  onClick={() =>
                    dispatch(
                      actionsActions.toggleChangeStatusModal({
                        status: ActionStatus.Approved,
                        ids: selectedTableRowKeys,
                      })
                    )
                  }
                >
                  {formatMessage({ id: 'Approve' })}
                  {selectedTableRowKeys.length > 1
                    ? ` (${selectedTableRowKeys.length})`
                    : ''}
                </Action>
                <Action
                  onClick={() =>
                    dispatch(
                      actionsActions.toggleChangeStatusModal({
                        status: ActionStatus.Rejected,
                        ids: selectedTableRowKeys,
                      })
                    )
                  }
                >
                  {formatMessage({ id: 'Reject' })}
                  {selectedTableRowKeys.length > 1
                    ? ` (${selectedTableRowKeys.length})`
                    : ''}
                </Action>
              </>
            )}
        </>
      )}
    </ActionsModal>
  );
};

export default ActionsSideModal;
