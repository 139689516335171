import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  width: 100%;
  border-radius: 5px;
  background: ${colors.gray3};
  overflow: hidden;
`;

export const Account = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 9px 16px;
  background: ${({ current }: { current: boolean }) =>
    current ? colors.gray4 : 'transparent'};
  border: 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${colors.gray4};
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.gray10};
  }
`;

export const Name = styled.div`
  flex-grow: 1;
  font-size: 14px;
`;
