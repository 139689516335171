import React, { useCallback } from 'react';
import Modal from 'antd/es/modal';
import { useSelector } from 'react-redux';
import { X } from 'react-feather';

import { accountActions, accountSelectors, generalActions } from '@store';
import { Container } from './styled';
import { useAppDispatch } from '@hooks';

import TextColumn from './TextColumn/TextColumn';
import FormColumn from './FormColumn/FormColumn';

const FormsDigitizationModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();

  const visible = useSelector(accountSelectors.isFormsDigitizationModalVisible);

  const closeModal = useCallback(() => {
    dispatch(accountActions.toggleFormsDigitizationModal(false));
    dispatch(generalActions.resetFileUploads());
  }, []);

  return (
    <Modal
      open={visible}
      closable
      closeIcon={<X />}
      width={940}
      styles={{
        body: { marginBottom: 0 },
      }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
      className="modal-no-padding"
    >
      <Container>
        <TextColumn />
        <FormColumn />
      </Container>
    </Modal>
  );
};

export default FormsDigitizationModal;
