import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useIntl } from 'react-intl';
import { Plus } from 'react-feather';

import { ArrowLeft } from '@icons';
import { Container, LeftArrowButton, LeftContent, Title } from './styled';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { routes } from '@config';
import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { SchedulePlanDetailsPage } from '@application/SchedulePlans/models/SchedulePlanDetailsPage';

import SchedulePlanDetailsActionMenu from '@presentation/SchedulePlans/SchedulePlanDetails/SchedulePlanDetailsHeader/SchedulePlanDetailsActionMenu/SchedulePlanDetailsActionMenu';
import Button from '@components/shared/ant/Button';
import SchedulePlanChangeState from '@presentation/SchedulePlans/SchedulePlanChangeState/SchedulePlanChangeState';

interface IProps {}

const SchedulePlanDetailsHeader: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const schedulesCount = useSelector(
    schedulePlansSelectors.getSchedulePlanSchedulesCount
  );
  const page = useSelector(
    schedulePlansSelectors.getSchedulePlanDetailsCurrentPage
  );
  const { data, loading } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );

  return (
    <Container>
      <LeftContent>
        <LeftArrowButton
          type="button"
          onClick={() => {
            navigate(routes.advancedSchedules);
          }}
        >
          <ArrowLeft />
        </LeftArrowButton>
        <Title>
          {loading ? (
            <Skeleton.Input
              active
              size="small"
              style={{ height: '28px', width: '200px', borderRadius: 5 }}
            />
          ) : (
            data?.name
          )}
        </Title>
      </LeftContent>
      <Space size={14}>
        {!loading && !!data && (
          <SchedulePlanChangeState
            schedulePlan={data}
            disabled={!schedulesCount}
          />
        )}
        {page === SchedulePlanDetailsPage.SchedulingRules && (
          <Button
            type="primary"
            disabled={!data || loading || data.active}
            icon={<Plus />}
            onClick={() => {
              dispatch(schedulesActions.toggleAddEditModal({ opened: true }));
            }}
          >
            {formatMessage({ id: 'AddSchedulingRule' })}
          </Button>
        )}
        {page === SchedulePlanDetailsPage.PlanningPeriods && !!data?.active && (
          <Button
            type="primary"
            style={{ background: colors.red }}
            onClick={() => {
              dispatch(schedulePlansActions.toggleStartNewPeriodModal(true));
            }}
          >
            {formatMessage({
              id: 'RegenerateThePeriod',
            })}
          </Button>
        )}
        <SchedulePlanDetailsActionMenu />
      </Space>
    </Container>
  );
};

export default SchedulePlanDetailsHeader;
