import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BillingPage, SubscriptionType } from '@repo/shared/enums';
import { PageSwitcherWrapper } from './styled';
import { accountSelectors } from '@store';
import { routes, DEFAULT_APP_NAME } from '@config';

import PageHeader from '../../../../shared/ant/PageHeader';
import PageTabbedSwitcher from '../../../../shared/PageTabbedSwitcher/PageTabbedSwitcher';
import HaveQuestions from '../../HaveQuestions/HaveQuestions';

interface IProps {
  page: BillingPage;
}

const BillingHeader: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { loading, data: subscription } = useSelector(
    accountSelectors.getSubscription
  );
  const subscriptionType = subscription?.subscriptionType;

  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const menuItems = useMemo(() => {
    const items = [
      {
        id: BillingPage.Subscription,
        name: formatMessage({ id: 'Subscription' }),
        onClick: () => navigate(routes.subscription),
      },
    ];

    if (!loading && subscriptionType === SubscriptionType.Automated) {
      items.push({
        id: BillingPage.Invoices,
        name: formatMessage({ id: 'Invoices' }),
        onClick: () => navigate(routes.invoices),
      });
    }

    return items;
  }, [subscriptionType, loading]);

  return (
    <PageHeader
      title={formatMessage({ id: 'Billing' })}
      extra={appConfiguration.appName === DEFAULT_APP_NAME && <HaveQuestions />}
      footer={
        <PageSwitcherWrapper>
          <PageTabbedSwitcher<BillingPage>
            activePage={page}
            pages={menuItems}
          />
        </PageSwitcherWrapper>
      }
    />
  );
};

export default BillingHeader;
