import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Action } from '../../../shared/ActionsModal/styled';
import {
  auditObjectsSelectors,
  generalSelectors,
  auditObjectsActions,
  accountSelectors,
} from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import ActionsModal from '../../../shared/ActionsModal/ActionsModal';

const AuditObjectsActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const groups = useSelector(auditObjectsSelectors.groups.getGroupsMap);
  let group =
    selectedTableRowKeys.length === 1 ? groups[selectedTableRowKeys[0]] : null;

  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <>
              <Action
                onClick={() => {
                  if (group) {
                    navigate(
                      routes.auditObjects.groupDetails.replace(':id', group.id)
                    );
                  }
                  closeModal();
                }}
              >
                {formatMessage({ id: 'View' })}
              </Action>
              {!isLocationsReadonly && (
                <Action
                  onClick={() => {
                    if (group) {
                      dispatch(
                        auditObjectsActions.groups.toggleAddEditModal({
                          visible: true,
                          groupId: group.id,
                        })
                      );
                    }

                    closeModal();
                  }}
                >
                  {formatMessage({ id: 'Edit' })}
                </Action>
              )}
            </>
          )}
          {!isLocationsReadonly && (
            <Action
              onClick={() =>
                dispatch(
                  auditObjectsActions.groups.toggleConfirmDeleteModal(
                    selectedTableRowKeys
                  )
                )
              }
            >
              {formatMessage({ id: 'Delete' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
        </>
      )}
    </ActionsModal>
  );
};

export default AuditObjectsActionsModal;
