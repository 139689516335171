import React from 'react';
import { useIntl } from 'react-intl';

import { usersActions } from '@store';
import { useAppDispatch } from '@hooks';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  jobTitleId: string;
}

const JobTitlesActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  jobTitleId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'Edit' }),
          onClick: () =>
            dispatch(
              usersActions.jobTitles.toggleAddEditModal({
                show: true,
                jobTitleId: jobTitleId,
              })
            ),
        },
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () => {
            dispatch(
              usersActions.jobTitles.toggleConfirmDeleteModal([jobTitleId])
            );
          },
        },
      ]}
    />
  );
};

export default JobTitlesActionsMenu;
