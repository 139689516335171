import React from 'react';
import { useIntl } from 'react-intl';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';

import SearchInput from '../../shared/SeachInput/SearchInput';
import { rolesActions, rolesSelectors } from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';

import PageHeader from '../../shared/ant/PageHeader';
import Button from '../../shared/ant/Button';

const RolesHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canManageRoles = usePermission(Permission.CanManageRoles);

  const filters = useSelector(rolesSelectors.getFilters);

  return (
    <>
      <PageHeader
        title={formatMessage({
          id: 'AccessRolesAndPermissions',
        })}
        extra={
          <>
            <SearchInput
              value={filters.search}
              onEnter={(search: string) =>
                dispatch(rolesActions.setFilters({ search, pageNumber: 1 }))
              }
            />
            {canManageRoles && (
              <Button
                type="primary"
                icon={<Plus />}
                onClick={() => dispatch(rolesActions.showAddEditRoleModal())}
              >
                {formatMessage({
                  id: 'AddNewRole',
                })}
              </Button>
            )}
          </>
        }
      />
    </>
  );
};

export default RolesHeader;
