import styled from 'styled-components';

import icon from './icon.svg';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  font-size: 14px;
  color: ${colors.gray8};
  text-align: center;
`;

export const Icon = styled.div`
  width: 71px;
  height: 65px;
  background: url(${icon}) center center no-repeat;
  background-size: contain;
  margin: 0 auto 9px;
`;
