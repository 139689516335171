import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { useAppDispatch } from '@hooks';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';

import SchedulesTable from '@presentation/Schedules/SchedulesTable/SchedulesTable';

interface IProps {}

const SchedulePlanDetailsSchedules: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();

  const { loading, meta } = useSelector(schedulesSelectors.getSchedules);
  const { data } = useSelector(schedulePlansSelectors.getSchedulePlanDetails);

  useEffect(() => {
    if (!loading && meta) {
      dispatch(
        schedulePlansActions.setSchedulePlanSchedulesCount(meta.totalCount)
      );
    }
  }, [loading, meta]);

  return <SchedulesTable disabled={data?.active} />;
};

export default SchedulePlanDetailsSchedules;
