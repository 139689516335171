import React from 'react';
import { useIntl } from 'react-intl';
import { ChevronRight } from 'react-feather';

import {
  IssueEventAudit,
  IssueEventAuditType,
} from '@domain/Issues/models/IssueEvent';
import { ExternalLink, DisabledLink, Icon, Name, Text } from './styled';
import { usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import AuditIcon from './AuditIcon';
import { getRoutesFromAuditStatus } from '@application/Audits/utils/getRoutesFromAuditStatus';

const langIds = {
  [IssueEventAuditType.Created]: 'AuditHasBeenCreated',
  [IssueEventAuditType.Deleted]: 'AuditHasBeenDeleted',
  [IssueEventAuditType.Expired]: 'AuditHasBeenExpired',
  [IssueEventAuditType.Completed]: 'AuditHasBeenCompleted',
};

interface IProps {
  event: IssueEventAudit;
}

const IssueDetailsEventAudit: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  const { formatMessage } = useIntl();

  const [canDoAudits, canViewAuditResults] = usePermission([
    Permission.CanDoAudits,
    Permission.CanViewAuditResults,
  ]);

  const disabled = !canDoAudits && !canViewAuditResults;

  const linkContent = (
    <>
      <Icon>
        <AuditIcon />
      </Icon>
      <Name>{event.data.audit.name}</Name>
    </>
  );

  return (
    <>
      {!!langIds[event.data.event] && (
        <Text data-e2e={e2eTestElements.issues.issues.details.eventText}>
          {formatMessage({ id: langIds[event.data.event] })}:
        </Text>
      )}
      {disabled ? (
        <DisabledLink>{linkContent}</DisabledLink>
      ) : (
        <ExternalLink
          to={getRoutesFromAuditStatus(event.data.audit.status).single.replace(
            ':id',
            event.data.audit.id
          )}
          target="_blank"
        >
          <Icon>
            <AuditIcon />
          </Icon>
          <Name>{event.data.audit.name}</Name>
          <ChevronRight />
        </ExternalLink>
      )}
    </>
  );
};

export default IssueDetailsEventAudit;
