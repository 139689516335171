import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { getColumnSortProperties } from '@utils';
import { IAuditObject, IConcise } from '@repo/shared/types';
import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission, RoleType, SelfGuidedTourStep } from '@repo/shared/enums';
import { e2eTestElements, routes } from '@config';
import { config } from '@repo/shared/config';
import { useAppDispatch, usePermission } from '@hooks';

import Table from '../../../shared/ant/Table/Table';
import EmptyTable from '../../../shared/ant/EmptyTable/EmptyTable';
import AuditObjectsActionsMenu from './AuditObjectsActionsMenu';
import SampleBadgedName from '../../../shared/SampleBadgedName';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import ConciseListStr from '@components/shared/ConciseListStr/ConciseListStr';

const AuditObjectsTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { loading, error, auditObjects, meta } = useSelector(
    auditObjectsSelectors.getAuditObjects
  );
  const filters = useSelector(auditObjectsSelectors.getFilters);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const isCurrentUserAdmin = currentUser?.role.roleType === RoleType.Admin;

  return (
    <TourPopover
      step={SelfGuidedTourStep.AddObject}
      overlayStyle={{ marginTop: -55, height: 334 }}
      placement="bottom"
      scrollToSpotlight
    >
      <Table
        onPageChange={(update) => {
          dispatch(auditObjectsActions.objects.getData(update));
        }}
        onSelectRow={() => {
          dispatch(auditObjectsActions.objects.hideAddEditModal());
        }}
        onRow={(auditObject) => {
          return {
            onClick: () => {
              dispatch(auditObjectsActions.objects.updateObjectDetails(null));
              navigate(
                routes.auditObjects.auditObjectDetails.replace(
                  ':id',
                  auditObject.id
                )
              );
            },
          };
        }}
        loading={loading}
        meta={meta}
        canSelectRow={!isLocationsReadonly && canManageAuditsObjects}
        error={error}
        e2eDataAttr={e2eTestElements.objects.table}
        columns={[
          {
            title: formatMessage({ id: 'Name' }),
            dataIndex: 'name',
            key: 'name',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'name'
            ),
            render: (_, { name, isSample }) => (
              <SampleBadgedName text={name} isSample={isSample} />
            ),
            className: 'cell-text-link',
          },
          {
            title: formatMessage(
              { id: 'AuditObjectGroups' },
              { objectName: auditObjectName.plural }
            ),
            dataIndex: 'auditObjectGroups',
            key: 'auditObjectGroups',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'auditObjectGroups'
            ),
            render(auditObjectGroups: IConcise[]) {
              return (
                <ConciseListStr
                  items={auditObjectGroups}
                  emptyPlaceholder={formatMessage({ id: 'Ungrouped' })}
                />
              );
            },
          },
          ...(canManageAuditsObjects
            ? [
                {
                  title: formatMessage({ id: 'Action' }),
                  key: 'action',
                  align: 'center' as 'center',
                  width: 70,
                  render: (auditObject: IAuditObject) => (
                    <AuditObjectsActionsMenu auditObject={auditObject} />
                  ),
                },
              ]
            : []),
        ]}
        dataSource={
          auditObjects
            ? auditObjects.map((auditObject: IAuditObject) => ({
                key: auditObject.id,
                id: auditObject.id,
                tags: auditObject.tags || [],
                name: auditObject.name,
                isSample: auditObject.isSample || false,
                geoAddress: auditObject.geoAddress || null,
                participantUsers: auditObject.participantUsers || [],
                participantUserGroups: auditObject.participantUserGroups || [],
                auditObjectGroups: auditObject.auditObjectGroups || [],
              }))
            : []
        }
        locale={{
          emptyText: (
            <EmptyTable
              text={
                <>
                  {formatMessage(
                    {
                      id:
                        filters.search !== ''
                          ? 'NoAuditObjectsSearchResults'
                          : 'NoAuditObjects',
                    },
                    {
                      searchStr: filters.search,
                      objectName: auditObjectName.plural.toLowerCase(),
                    }
                  )}
                  {!isLocationsReadonly && isCurrentUserAdmin && (
                    <>
                      <br />
                      {formatMessage(
                        { id: 'YouCanAddAuditObjectRightNow' },
                        {
                          objectName: auditObjectName.single.toLowerCase(),
                        }
                      )}
                    </>
                  )}
                </>
              }
              button={
                !isLocationsReadonly &&
                isCurrentUserAdmin &&
                canManageAuditsObjects
                  ? {
                      text: `+ ${formatMessage(
                        { id: 'CreateNewAuditObject' },
                        { objectName: auditObjectName.single.toLowerCase() }
                      )}`,
                      type: 'link',
                      onClick: () => {
                        dispatch(
                          auditObjectsActions.objects.showAddEditModal()
                        );
                      },
                    }
                  : null
              }
              howItWorksUrl={config.urls.auditObjectsSupport}
            />
          ),
        }}
        onSort={(orderBy, orderByDirection) =>
          dispatch(
            auditObjectsActions.objects.getData({ orderBy, orderByDirection })
          )
        }
      />
    </TourPopover>
  );
};

export default AuditObjectsTable;
