import React from 'react';
import { useIntl } from 'react-intl';
import { AlertTriangle } from 'react-feather';
import Spin from 'antd/es/spin';

import { IApiService } from '@repo/shared/types';
import { Container, ErrorPlaceholder, LoadingPlaceholder } from './styled';
import { useFileStatus } from '@repo/shared/components/Previews/useFileStatus';
import { FileStatus } from '@repo/shared/enums';

import VideoPlayer from '@repo/shared/components/VideoPlayer';

interface IProps {
  fileId: string;
  getFilePath?: (fileId: string) => string;
  getMetadataPath?: (fileId: string) => string;
  apiService: IApiService;
  skipCompanyId?: boolean;
}

const VideoContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  fileId,
  getFilePath = (fileId: string) => `files/${fileId}`,
  apiService,
  skipCompanyId,
}) => {
  const { formatMessage } = useIntl();

  const status = useFileStatus({
    fileId,
    apiService,
    skipCompanyId,
  });

  if (
    status === null ||
    status === FileStatus.WaitingUpload ||
    status === FileStatus.Processing ||
    status === FileStatus.PreviewReady
  ) {
    return (
      <Container>
        <LoadingPlaceholder>
          <div>
            <Spin size="large" />
          </div>
          {(status === FileStatus.Processing ||
            status === FileStatus.PreviewReady) &&
            formatMessage({ id: 'YourVideoIsCurrentlyProcessing' })}
        </LoadingPlaceholder>
      </Container>
    );
  }

  if (status === FileStatus.Error) {
    return (
      <Container>
        <ErrorPlaceholder>
          <AlertTriangle />
          {formatMessage({ id: 'ErrorWhileLoadingVideo' })}
        </ErrorPlaceholder>
      </Container>
    );
  }

  return (
    <Container>
      <VideoPlayer
        containerProps={{ maxWidth: '95%' }}
        fileId={fileId}
        getFilePath={getFilePath}
        apiService={apiService}
      />
    </Container>
  );
};

export default VideoContainer;
