import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { unwrapResult } from '@reduxjs/toolkit';
import { createPortal } from 'react-dom';

import { useAppDispatch } from '@hooks';
import { actionsActions } from '@store';
import { date, notification } from '@utils';
import { IExposedFormRef } from '@application/Issues/hooks/useExposedForm';
import {
  FileSourceType,
  AssigneesModalContext,
  ActionStatus,
} from '@repo/shared/enums';
import { IssueDetailsAction } from '@domain/Issues/models/Issue';

import AddEditActionModal, {
  ActionFormValues,
} from '@presentation/PerformAudit/PerformSection/PerformAuditItemTree/ActionModal/AddEditActionModal';

interface IProps {
  opened: boolean;
  onCancel: () => void;
  onSave: (item: IssueDetailsAction) => Promise<void>;
  onDelete: (itemId: string) => void;
  auditObjectId: string;
  issueId: string;
}

const IssueDetailsCreateActionModal: React.FC<
  React.PropsWithChildren<IProps>
> = ({ opened, onDelete, onCancel, onSave, auditObjectId, issueId }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const formRef = useRef<IExposedFormRef<ActionFormValues>>(null);

  return (
    <>
      {createPortal(
        <AddEditActionModal
          ref={formRef}
          opened={opened}
          onClose={onCancel}
          onSave={async ({
            name,
            description,
            assignedUsers,
            tags,
            files,
            priority,
            dueDate,
          }) => {
            const resultAction = await dispatch(
              actionsActions.createAction({
                name,
                description,
                assignedUsersIds: assignedUsers.map(({ id }) => id),
                tagsIds: tags.map(({ id }) => id),
                files,
                priority,
                dueDate,
                auditObjectId,
                issueId,
              })
            );

            if (actionsActions.createAction.fulfilled.match(resultAction)) {
              await onSave({
                id: unwrapResult(resultAction),
                name,
                number: null,
                status: ActionStatus.Opened,
                dueInDays: date().diff(dueDate, 'day'),
              });
            } else {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileCreatingAction' }),
              });
            }
          }}
          auditObjectId={auditObjectId}
          onDelete={onDelete}
          fileSourceType={FileSourceType.IssueFile}
          assigneesContext={AssigneesModalContext.Issue}
        />,
        document.body
      )}
    </>
  );
};

export default IssueDetailsCreateActionModal;
