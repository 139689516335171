import styled, { css } from 'styled-components';
import AntUpload from 'antd/es/upload';

import { colors } from '@repo/shared/config';
import { RemoveBtn } from '../UploadIcon/UploadCircle/styled';

const { Dragger } = AntUpload;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 100px;
  border-radius: 5px;
  background: ${colors.gray3};
  padding: 20px;
`;

const dimensions = css`
  width: 204px;
  height: 68px;
`;
export const Logo = styled.img`
  object-fit: contain;
  ${dimensions};
`;

export const Title = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  line-height: 20px;

  span {
    text-decoration: underline;
  }
`;

export const Desc = styled.div`
  font-size: 10px;
  color: ${colors.gray7};
`;

export const Upload = styled(Dragger)`
  .ant-upload.ant-upload-drag {
    width: 320px;
    height: 100px;
    border: 0;
    border-radius: 5px;
    background: ${colors.gray3};

    & + .ant-upload-list {
      display: none;
    }
  }
`;

export const RemoveLogoBtn = styled(RemoveBtn)`
  right: -7px;
  top: -7px;
`;
