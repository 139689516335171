import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import {
  attributesAdapter,
  attributesAssignmentsAdapter,
  auditObjectGroupAssignmentAdapter,
  auditObjectsAdapter,
  groupAdapter,
} from '@store/entityAdapters';
import { IAuditObjectsFilters } from '@repo/shared/types';

export const auditObjectsSelectors = {
  getAuditObjects: createSelector(
    (state: IRootState) => state.auditObjects.objects,
    (auditObjects) => ({
      loading: auditObjects.loading,
      error: auditObjects.error,
      meta: auditObjects.meta,
      auditObjects: auditObjectsAdapter
        .getSelectors()
        .selectAll(auditObjects.data),
    })
  ),
  getObjectDetails: (state: IRootState) => state.auditObjects.object,
  getAuditObjectsLookUp: (state: IRootState) =>
    auditObjectsAdapter
      .getSelectors()
      .selectEntities(state.auditObjects.objects.data),
  getAddEditAuditObjectModalState: (state: IRootState) =>
    state.auditObjects.objects.addEditAuditObjectModal,
  getFilters: (state: IRootState): IAuditObjectsFilters =>
    state.auditObjects.objects.filters,
  isFiltersModalVisible: (state: IRootState) =>
    state.auditObjects.objects.showFiltersModal,
  getSelectedFiltersQty: createSelector(
    (state: IRootState): IAuditObjectsFilters =>
      state.auditObjects.objects.filters,
    (filters) => {
      let selected = 0;

      if (filters.ungrouped) {
        selected += 1;
      }

      if (filters.auditObjectGroupIds.length > 0) {
        selected += 1;
      }

      if (filters.auditObjectAttributeId !== null) {
        selected += 1;
      }

      if (filters.auditObjectAttributeOptionId !== null) {
        selected += 1;
      }

      return selected;
    }
  ),
  groups: {
    getGroups: createSelector(
      (state: IRootState) => state.auditObjects.groups,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: groupAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getGroupsMap: (state: IRootState) =>
      groupAdapter
        .getSelectors()
        .selectEntities(state.auditObjects.groups.data),
    getFilters: (state: IRootState) => state.auditObjects.groups.filters,
    getAddEditGroupModalState: (state: IRootState) =>
      state.auditObjects.groups.addEditGroupModal,
    getDeleteCandidatesIds: (state: IRootState) =>
      state.auditObjects.groups.deleteCandidatesIds,
  },
  groupAssignments: {
    getData: createSelector(
      (state: IRootState) => state.auditObjects.groupAssignments,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: auditObjectGroupAssignmentAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getMap: (state: IRootState) =>
      auditObjectGroupAssignmentAdapter
        .getSelectors()
        .selectEntities(state.auditObjects.groupAssignments.data),
    getFilters: (state: IRootState) =>
      state.auditObjects.groupAssignments.filters,
    isAddToGroupModalVisible: (state: IRootState) =>
      state.auditObjects.groupAssignments.showAddToGroupModal,
    getDeleteCandidatesIds: (state: IRootState) =>
      state.auditObjects.groupAssignments.deleteCandidatesIds,
    isFiltersModalVisible: (state: IRootState) =>
      state.auditObjects.groupAssignments.showFiltersModal,
    getSelectedFiltersQty: createSelector(
      (state: IRootState) => state.auditObjects.groupAssignments.filters,
      (filters) => {
        let selected = 0;

        if (filters.auditObjectIds.length > 0) {
          selected += 1;
        }

        if (filters.auditObjectGroupIds.length > 0) {
          selected += 1;
        }

        return selected;
      }
    ),
  },
  attributesAssignments: {
    getData: createSelector(
      (state: IRootState) => state.auditObjects.attributesAssignments,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: attributesAssignmentsAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getMap: (state: IRootState) =>
      attributesAssignmentsAdapter
        .getSelectors()
        .selectEntities(state.auditObjects.attributesAssignments.data),
    getFilters: (state: IRootState) =>
      state.auditObjects.attributesAssignments.filters,
    isAddModalVisible: (state: IRootState) =>
      state.auditObjects.attributesAssignments.showAddAttributeAssignmentModal,
    getDeleteCandidatesIds: (state: IRootState) =>
      state.auditObjects.attributesAssignments.deleteCandidatesIds,
    isFiltersModalVisible: (state: IRootState) =>
      state.auditObjects.attributesAssignments.showFiltersModal,
    getSelectedFiltersQty: createSelector(
      (state: IRootState) => state.auditObjects.attributesAssignments.filters,
      (filters) => {
        let selected = 0;

        if (filters.auditObjectId !== null) {
          selected += 1;
        }

        if (filters.attributeId !== null) {
          selected += 1;
        }

        if (filters.optionId !== null) {
          selected += 1;
        }

        return selected;
      }
    ),
  },
  attributes: {
    getData: createSelector(
      (state: IRootState) => state.auditObjects.attributes,
      ({ loading, error, data, meta }) => ({
        loading,
        error,
        data: attributesAdapter.getSelectors().selectAll(data),
        meta,
      })
    ),
    getMap: (state: IRootState) =>
      attributesAdapter
        .getSelectors()
        .selectEntities(state.auditObjects.attributes.data),
    getFilters: (state: IRootState) => state.auditObjects.attributes.filters,
    getAddEditModalData: (state: IRootState) =>
      state.auditObjects.attributes.addEditAttributeModal,
    getAttributesIdsDeleteCandidates: (state: IRootState) =>
      state.auditObjects.attributes.deleteCandidatesIds,
  },
};
