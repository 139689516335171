import React from 'react';
import { Minus, Plus } from 'react-feather';

import { Actions, ActionBtn, Container } from './styled';

interface IProps {
  onAdd(): void;
  onDelete(): void;
  disabledAddBtn?: boolean;
}

const IssueTypeDetailsFormFieldContainer: React.FC<
  React.PropsWithChildren<IProps>
> = ({ onAdd, onDelete, children, disabledAddBtn }) => {
  return (
    <Container>
      {children}
      <Actions>
        <ActionBtn type="button" onClick={onDelete}>
          <Minus />
        </ActionBtn>
        <ActionBtn type="button" onClick={onAdd} disabled={disabledAddBtn}>
          <Plus />
        </ActionBtn>
      </Actions>
    </Container>
  );
};

export default IssueTypeDetailsFormFieldContainer;
