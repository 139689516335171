import React from 'react';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';
import { useSelector } from 'react-redux';

import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { Permission } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { getInitialSchedulesFilters } from '@application/Schedules/utils/getInitialSchedulesFilters';
import { e2eTestElements } from '@config';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import PageHeader from '@components/shared/ant/PageHeader';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';
import Button from '@components/shared/ant/Button';

const SchedulesHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);
  const selectedFiltersQty = useSelector(
    schedulesSelectors.getNumberOfSelectedFilters
  );
  const filters = useSelector(schedulesSelectors.getFilters);

  return (
    <PageHeader
      title={formatMessage({ id: 'Schedules' })}
      extra={
        <Space>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(schedulesActions.getSchedules({ search, pageNumber: 1 }))
            }
          />
          <FiltersButton
            showModal={() =>
              dispatch(schedulesActions.toggleFiltersModal(true))
            }
            resetFilters={() => {
              dispatch(
                schedulesActions.getSchedules({
                  ...getInitialSchedulesFilters(),
                  search: filters.search,
                })
              );
            }}
            selectedFilters={selectedFiltersQty}
          />
          {canScheduleAudits && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(schedulesActions.toggleAddEditModal({ opened: true }));
              }}
              data-e2e={e2eTestElements.schedules.addScheduleButton}
            >
              {formatMessage({
                id: 'Create',
              })}
            </Button>
          )}
        </Space>
      }
    />
  );
};

export default SchedulesHeader;
