import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Buttons, FilterGroupHeader } from './styled';
import {
  auditObjectsActions,
  auditObjectsSelectors,
  accountSelectors,
  generalSelectors,
} from '@store';
import { IAuditObjectsFilters, IConcise } from '@repo/shared/types';
import { useAppDispatch } from '@hooks';
import {
  ClearBtn,
  FilterQty,
} from '@src/presentation/Actions/ActionsFiltersModal/styled';
import { getInitialAuditObjectsFilters } from '@store/modules/auditObjects/auditObjects.reducer';

import SlideModal from '@components/shared/SideModal/SideModal';
import Form from '@components/shared/ant/Form';
import GroupingStatusButtons from '@components/users/list/UsersFiltersModal/GroupingStatusButtons/GroupingStatusButtons';
import AuditObjectsGroupsSelect from '@components/shared/EntitySelects/AuditObjectsGroupsSelect';
import AttributeValue from '@components/auditObjects/attributes/single/AttributesAssignments/AddEditAttributeAssignmentModal/AttributeValue';
import Select from '@components/shared/ant/Select/Select';

interface IProps {}

const AuditObjectsFiltersModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const visible = useSelector(auditObjectsSelectors.isFiltersModalVisible);
  const filters = useSelector(auditObjectsSelectors.getFilters);
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  const getAuditObjects = useCallback(
    (update: Partial<IAuditObjectsFilters>) =>
      dispatch(auditObjectsActions.objects.getData(update)),
    [dispatch]
  );
  const selectedFiltersQty = useSelector(
    auditObjectsSelectors.getSelectedFiltersQty
  );
  const { data: attributesList } = useSelector(
    generalSelectors.getConciseAuditObjectAttributes
  );
  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );

  const closeModal = useCallback(
    () => dispatch(auditObjectsActions.objects.toggleFiltersModal(false)),
    [dispatch]
  );

  return (
    <SlideModal opened={visible} onModalClose={() => closeModal()}>
      <Form
        layout="vertical"
        form={form}
        name="audit-objects-filters-form"
        onFinish={({
          auditObjectGroupsIds,
          auditObjectAttributeId,
          auditObjectAttributeOptionId,
          ungrouped,
        }: any) => {
          getAuditObjects({
            ungrouped,
            auditObjectGroupIds: auditObjectGroupsIds,
            auditObjectAttributeId,
            auditObjectAttributeOptionId,
          });

          closeModal();
        }}
        initialValues={{
          ungrouped: filters.ungrouped,
          auditObjectGroupsIds: filters.auditObjectGroupIds,
          auditObjectAttributeId: filters.auditObjectAttributeId,
          auditObjectAttributeOptionId: filters.auditObjectAttributeOptionId,
        }}
      >
        <Form.Item name="ungrouped">
          <GroupingStatusButtons
            onChange={(ungrouped) => {
              form.setFieldsValue({ auditObjectGroupsIds: [] });

              getAuditObjects({
                ungrouped,
                auditObjectGroupIds: [],
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'OrInGroups' })}
        </FilterGroupHeader>
        <Form.Item name="auditObjectGroupsIds">
          <AuditObjectsGroupsSelect
            allowClear
            placeholder={formatMessage(
              { id: 'SelectObjectGroups' },
              { objectName: auditObjectName.plural }
            )}
            mode="multiple"
            onChange={(auditObjectGroupsIds) => {
              form.setFieldsValue({ ungrouped: false });

              getAuditObjects({
                auditObjectGroupIds: auditObjectGroupsIds,
                ungrouped: false,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'Attribute' })}
        </FilterGroupHeader>
        <Form.Item name="auditObjectAttributeId">
          <Select
            options={attributesList.map(({ id, name }: IConcise) => ({
              value: id,
              label: name,
            }))}
            allowClear
            onChange={(newAttributeId) => {
              form.setFieldsValue({
                auditObjectAttributeOptionId: null,
              });

              getAuditObjects({
                auditObjectAttributeId: newAttributeId || null,
                auditObjectAttributeOptionId: null,
                pageNumber: 1,
              });
            }}
            onClear={() => {
              form.setFieldsValue({
                auditObjectAttributeOptionId: null,
                auditObjectAttributeId: null,
              });

              getAuditObjects({
                auditObjectAttributeId: null,
                auditObjectAttributeOptionId: null,
                pageNumber: 1,
              });
            }}
            placeholder={formatMessage({ id: 'SelectAttribute' })}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return getFieldValue('auditObjectAttributeId') ? (
              <>
                <FilterGroupHeader>
                  {formatMessage({ id: 'HasValue' })}
                </FilterGroupHeader>
                <AttributeValue
                  formItemName="auditObjectAttributeOptionId"
                  attributeId={getFieldValue('auditObjectAttributeId')}
                  placeholder={formatMessage({ id: 'SelectAttributeValue' })}
                  attributesMap={attributesMap}
                  onChange={(auditObjectAttributeOptionId) => {
                    getAuditObjects({
                      auditObjectAttributeOptionId,
                      pageNumber: 1,
                    });
                  }}
                />
              </>
            ) : null;
          }}
        </Form.Item>
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                const { search, ...initialModalFilters } =
                  getInitialAuditObjectsFilters();

                getAuditObjects(initialModalFilters);

                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default AuditObjectsFiltersModal;
