import React from 'react';

import { StarSvg } from './styled';

interface IProps {
  color: string;
  onClick?: () => void;
}

const Star: React.FC<React.PropsWithChildren<IProps>> = ({
  color,
  onClick,
}) => {
  return (
    <StarSvg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      selectedColor={color}
      readonly={!onClick}
    >
      <path d="M16.2798 5.516C17.6419 2.84699 18.323 1.51248 19.2399 1.08131C20.038 0.70597 20.962 0.70597 21.7601 1.08131C22.677 1.51248 23.3581 2.84699 24.7202 5.516L26.7278 9.44995C27.1292 10.2366 27.33 10.6299 27.6181 10.9366C27.8733 11.2082 28.1775 11.4293 28.5147 11.588C28.8955 11.7673 29.3316 11.8367 30.2038 11.9754L34.5656 12.6691C37.5249 13.1398 39.0045 13.3751 39.6979 14.1139C40.3015 14.757 40.587 15.6357 40.4767 16.5108C40.35 17.5161 39.2913 18.5761 37.1738 20.6963L34.0528 23.8214C33.4287 24.4462 33.1166 24.7587 32.914 25.1275C32.7345 25.4542 32.6183 25.8118 32.5715 26.1816C32.5187 26.5991 32.5875 27.0353 32.7251 27.9076L33.4132 32.2703C33.88 35.2302 34.1134 36.7102 33.6251 37.5979C33.2 38.3707 32.4525 38.9138 31.5862 39.0793C30.5909 39.2694 29.2556 38.5901 26.5848 37.2314L22.6483 35.2288C21.8611 34.8284 21.4676 34.6282 21.0541 34.5494C20.688 34.4797 20.312 34.4797 19.9459 34.5494C19.5324 34.6282 19.1389 34.8284 18.3517 35.2288L14.4152 37.2314C11.7444 38.5901 10.4091 39.2694 9.41384 39.0793C8.54749 38.9138 7.80001 38.3707 7.3749 37.5979C6.88655 36.7102 7.11998 35.2302 7.58683 32.2703L8.27495 27.9076C8.41254 27.0353 8.48134 26.5991 8.42848 26.1816C8.38168 25.8118 8.26548 25.4542 8.08601 25.1275C7.88335 24.7587 7.57132 24.4462 6.94724 23.8214L3.82622 20.6963C1.70874 18.5761 0.650007 17.5161 0.523275 16.5108C0.412954 15.6357 0.698467 14.757 1.30208 14.1139C1.99549 13.3751 3.47513 13.1398 6.43442 12.6691L10.7962 11.9754C11.6684 11.8367 12.1045 11.7673 12.4853 11.588C12.8225 11.4293 13.1267 11.2082 13.3819 10.9366C13.67 10.6299 13.8708 10.2366 14.2722 9.44995L16.2798 5.516Z" />
    </StarSvg>
  );
};

export default Star;
