import React from 'react';
import { useIntl } from 'react-intl';
import {
  Activity as ChartIcon,
  Calendar,
  Clock,
  HelpCircle,
  Info as InfoIcon,
  MapPin,
  User,
} from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  AssigneesModalContext,
  HistoricalTrendDepth,
  Permission,
  SignatureBy,
  TrendsType,
} from '@repo/shared/enums';
import {
  Detail,
  Icon,
  Info,
  Link,
  Signatures,
  SubHeader,
  Text,
} from './styled';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { IConcise } from '@repo/shared/types';
import { notification } from '@utils';
import { routes } from '@config';
import { useAppDispatch, usePermission } from '@hooks';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { Templates } from '@icons';

import Assignees from '@components/shared/Assignees/Assignees';
import AuditBadges from '@presentation/Audits/ViewAuditModal/Details/AuditBadges/AuditBadges';
import Activity from './Activity/Activity';
import Signature from './Signature/Signature';
import TagList from '@components/shared/TagList/TagList';
import ExpiredDateWithBadge from '@presentation/Audits/ExpiredDateWithBadge/ExpiredDateWithBadge';
import ZonedDateTime from '@components/shared/ZonedDateTime';
import { colors } from '@repo/shared/config';

interface IProps {
  audit?: AuditDetails | null;
}

const Details: React.FC<React.PropsWithChildren<IProps>> = ({ audit }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [canScheduleAudits, canAssignAudits] = usePermission([
    Permission.CanScheduleAudits,
    Permission.CanAssignAudits,
  ]);

  if (!audit) {
    return null;
  }

  return (
    <>
      <Info>
        <Detail>
          <Icon title={formatMessage({ id: 'Location' })}>
            <MapPin />
          </Icon>
          <Text>{audit?.auditObject?.name}</Text>
        </Detail>
        <Detail>
          <Icon title={formatMessage({ id: 'Template' })}>
            <Templates size={18} color={colors.gray7} />
          </Icon>
          <Text>{audit.template.name}</Text>
        </Detail>
        {!audit.completedAtInformation ? (
          <>
            {audit.endDateInformation && (
              <Detail>
                <Icon title={formatMessage({ id: 'DueDate' })}>
                  <Clock />
                </Icon>
                <Text>
                  <ExpiredDateWithBadge
                    date={<ZonedDateTime dateTime={audit.endDateInformation} />}
                    isAutoExpired={audit.expiredBy === null}
                    showBadge={!!audit.expiredAtInformation}
                  />
                </Text>
              </Detail>
            )}
            {!!audit.auditObject.notes && (
              <Detail>
                <Icon title={formatMessage({ id: 'Notes' })}>
                  <InfoIcon />
                </Icon>
                <Text>{audit.auditObject.notes}</Text>
              </Detail>
            )}
            {!!audit.auditorHint && (
              <Detail>
                <Icon title={formatMessage({ id: 'AuditorHint' })}>
                  <HelpCircle />
                </Icon>
                <Text>{audit.auditorHint}</Text>
              </Detail>
            )}
          </>
        ) : (
          <>
            {!!audit.completedAtInformation && (
              <Detail>
                <Icon title={formatMessage({ id: 'CompletedDate' })}>
                  <Clock />
                </Icon>
                <Text>
                  <ZonedDateTime dateTime={audit.completedAtInformation} />
                </Text>
              </Detail>
            )}
          </>
        )}
        {canScheduleAudits &&
        (audit.schedulePlanSchedule || audit.auditSchedule) ? (
          <Detail title={formatMessage({ id: 'Schedule' })}>
            <Icon>
              <Calendar />
            </Icon>
            <Link
              type="button"
              disabled={
                audit.schedulePlanSchedule?.isDeleted ||
                audit.auditSchedule?.isDeleted
              }
              onClick={() => {
                if (audit.schedulePlanSchedule) {
                  navigate(
                    routes.advancedScheduleDetails.replace(
                      ':id',
                      audit.schedulePlanSchedule.schedulePlanId
                    )
                  );
                } else if (audit.auditSchedule) {
                  navigate(
                    routes.scheduleDetails.replace(
                      ':id',
                      audit.auditSchedule.id
                    )
                  );
                }
              }}
            >
              {audit.schedulePlanSchedule?.name ||
                audit.auditSchedule?.name ||
                '-'}
            </Link>
          </Detail>
        ) : null}
        {audit.previousAudit &&
          audit.template.historicalTrendDept !== HistoricalTrendDepth.None && (
            <Detail>
              <Icon title={formatMessage({ id: 'Trends' })}>
                <ChartIcon />
              </Icon>
              <Link
                type="button"
                onClick={() => {
                  dispatch(
                    auditsActions.toggleTrendsModal({
                      type: TrendsType.Audit,
                      audit,
                    })
                  );
                }}
              >
                {formatMessage({ id: 'Trends' })}
              </Link>
            </Detail>
          )}
        {!audit.completedAtInformation ? (
          <>
            {canAssignAudits ? (
              <Detail style={{ marginTop: '7px' }}>
                <Icon title={formatMessage({ id: 'AssignedTo' })}>
                  <User />
                </Icon>
                <Text style={{ marginTop: '-7px' }}>
                  <Assignees
                    onChange={async (selected: IConcise[]) => {
                      if (!audit) {
                        return;
                      }

                      const {
                        id,
                        name,
                        endDateInformation,
                        template,
                        auditorHint,
                        auditObject,
                      } = audit;

                      const resultAction = await dispatch(
                        auditsActions.updatePendingAudit({
                          id,
                          name,
                          endDate: endDateInformation.localTime,
                          templateId: template.id,
                          auditorHint,
                          auditObjectId: auditObject.id,
                          assignees: selected,
                        })
                      );

                      if (
                        auditsActions.updatePendingAudit.rejected.match(
                          resultAction
                        )
                      ) {
                        notification.error({
                          message: formatMessage({
                            id: 'ErrorWhileUpdatingAudit',
                          }),
                          description: resultAction.payload,
                        });
                      }
                    }}
                    auditObjectId={audit?.auditObject.id}
                    users={audit?.assignees || []}
                    context={AssigneesModalContext.Audit}
                    disabled={!!audit.expiredAtInformation}
                  />
                </Text>
              </Detail>
            ) : null}
          </>
        ) : (
          <Detail title={formatMessage({ id: 'AssignedTo' })}>
            <Icon>
              <User />
            </Icon>
            <Text>{audit.completedBy?.name || '-'}</Text>
          </Detail>
        )}
      </Info>
      {audit.tags?.length > 0 && <TagList tags={audit.tags} />}
      <AuditBadges audit={audit} />
      {(audit?.auditorSignature || audit?.auditeeSignature) && (
        <>
          <SubHeader>{formatMessage({ id: 'Signature' })}</SubHeader>
          <Signatures>
            {audit?.auditorSignature && (
              <Signature
                signature={audit.auditorSignature}
                signatureBy={SignatureBy.Auditor}
              />
            )}
            {audit?.auditeeSignature && (
              <Signature
                signature={audit.auditeeSignature}
                signatureBy={SignatureBy.Auditee}
              />
            )}
          </Signatures>
        </>
      )}
      <Activity audit={audit} />
    </>
  );
};

export default Details;
