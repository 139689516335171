import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/es/select';

import { generalSelectors } from '@store';
import { filterOptionsByChildren } from '../../../../core/utils/misc';

import EntityOrEntityGroupSelect from './EntityOrEntityGroupSelect';

interface IProps extends Omit<SelectProps<any>, 'value' | 'onChange'> {
  value?: {
    userId: string | null;
    userGroupId: string | null;
  };
  onChange?: (update: {
    userId: string | null;
    userGroupId: string | null;
  }) => void;
  e2eDataAttr?: string;
}

const UserOrUserGroupSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  placeholder,
  value,
  onChange,
  e2eDataAttr,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const { loading: conciseUsersLoading, data: conciseUsers } = useSelector(
    generalSelectors.getConciseUsers
  );
  const { loading: conciseUserGroupsLoading, data: conciseUserGroups } =
    useSelector(generalSelectors.getConciseUserGroups);

  const loading = conciseUsersLoading || conciseUserGroupsLoading;

  return (
    <EntityOrEntityGroupSelect
      {...props}
      entity={{
        options: conciseUsers,
        label: formatMessage({ id: 'Users' }),
      }}
      entityGroup={{
        options: conciseUserGroups,
        label: formatMessage({ id: 'UserGroups' }),
      }}
      value={{
        entityId: value?.userId || null,
        entityGroupId: value?.userGroupId || null,
      }}
      onChange={({ entityGroupId, entityId }) => {
        if (!onChange) {
          return;
        }

        onChange({
          userId: entityId,
          userGroupId: entityGroupId,
        });
      }}
      disabled={loading || disabled}
      placeholder={formatMessage({ id: 'SelectUserOrUsersGroup' })}
      loading={loading}
      showSearch
      optionFilterProp="children"
      filterOption={filterOptionsByChildren}
      e2eDataAttr={e2eDataAttr}
    />
  );
};

export default UserOrUserGroupSelect;
