import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { Schedule } from '@domain/Schedules/models/Schedule';
import { SchedulesFilters } from '@application/Schedules/models/SchedulesFilters';
import { IConcise, IPagedResponse } from '@repo/shared/types';
import { getErrorMessage } from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import SchedulesApiClient from '@infrastructure/Schedules/api/SchedulesApiClient';
import SchedulePlansApiClient from '@infrastructure/SchedulePlans/api/SchedulePlansApiClient';
import { ScheduleDto } from '@infrastructure/Schedules/models/ScheduleDto';
import { ChangeStatusDto } from '@application/SchedulePlans/models/ChangeStatusDto';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { AddEditScheduleModalState } from '@application/Schedules/models/AddEditScheduleModalState';

const apiClient = new SchedulesApiClient();
const schedulePlansApiClient = new SchedulePlansApiClient();

const getSchedules = createAsyncThunk<
  IPagedResponse<Schedule>,
  Partial<SchedulesFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>('schedules/getSchedules', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const { data: schedulePlan } =
      schedulePlansSelectors.getSchedulePlanDetails(state);
    const filters = schedulesSelectors.getFilters(state);

    return await apiClient.getSchedules(filters, schedulePlan?.id);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getScheduleDetails = createAsyncThunk<
  Schedule,
  { scheduleId: string; schedulePlanId?: string },
  { state: IRootState; rejectValue: string }
>(
  'schedules/getScheduleDetails',
  async ({ scheduleId, schedulePlanId }, { rejectWithValue }) => {
    try {
      return await (schedulePlanId
        ? schedulePlansApiClient.getSchedulePlanSchedule(
            schedulePlanId,
            scheduleId
          )
        : apiClient.getScheduleDetails(scheduleId));
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getConciseSchedules = createAsyncThunk<
  IConcise[],
  void,
  { state: IRootState; rejectValue: string }
>('schedules/getConciseSchedules', async (_, { rejectWithValue }) => {
  try {
    return await apiClient.getConciseSchedules();
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const resetTableData = createAction('schedules/resetTableData');

const createSchedule = createAsyncThunk<
  void,
  { schedule: ScheduleDto; schedulePlanId?: string },
  { state: IRootState; rejectValue: string }
>(
  'schedules/createSchedule',
  async ({ schedule }, { rejectWithValue, getState }) => {
    try {
      const { data: schedulePlan } =
        schedulePlansSelectors.getSchedulePlanDetails(getState());

      await apiClient.createSchedule(schedule, schedulePlan?.id);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const updateSchedule = createAsyncThunk<
  void,
  { id: string; schedule: ScheduleDto; schedulePlanId?: string },
  { state: IRootState; rejectValue: string }
>(
  'schedules/updateSchedule',
  async ({ id, schedule }, { rejectWithValue, getState }) => {
    try {
      const { data: schedulePlan } =
        schedulePlansSelectors.getSchedulePlanDetails(getState());

      await apiClient.updateSchedule(id, schedule, schedulePlan?.id);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteSchedules = createAsyncThunk<
  void,
  { ids: string[]; schedulePlanId?: string },
  { state: IRootState; rejectValue: string }
>(
  'schedules/deleteSchedules',
  async ({ ids, schedulePlanId }, { rejectWithValue }) => {
    try {
      await apiClient.deleteSchedules(ids, schedulePlanId);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const toggleFiltersModal = createAction<boolean>(
  'schedules/toggleFiltersModal'
);

const toggleAddEditModal = createAction<AddEditScheduleModalState>(
  'schedules/toggleAddEditModal'
);

const toggleStartModal = createAction<string | null>(
  'schedules/toggleStartModal'
);

const togglePauseModal = createAction<string | null>(
  'schedules/togglePauseModal'
);

const changeStatus = createAsyncThunk<
  ChangeStatusDto,
  ChangeStatusDto,
  { state: IRootState; rejectValue: string }
>('schedules/changeStatus', async (dto, { rejectWithValue }) => {
  try {
    await apiClient.changeStatus(dto);
    return dto;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleDeleteModal = createAction<DeleteModalState<Schedule>>(
  'schedules/toggleDeleteModal'
);

const resetScheduleDetails = createAction('schedules/resetScheduleDetails');

export const schedulesActions = {
  resetTableData,
  getSchedules,
  getScheduleDetails,
  getConciseSchedules,
  createSchedule,
  updateSchedule,
  deleteSchedules,
  toggleFiltersModal,
  toggleAddEditModal,
  toggleStartModal,
  togglePauseModal,
  changeStatus,
  toggleDeleteModal,
  resetScheduleDetails,
};
