import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useAppDispatch, usePermission } from '@hooks';
import { accountSelectors, auditObjectsActions } from '@store';
import { e2eTestElements } from '@config';
import { Permission } from '@repo/shared/enums';

import AddAttributeAssignmentModal from '@components/auditObjects/attributes/single/AttributesAssignments/AddEditAttributeAssignmentModal/AddAttributeAssignmentModal';
import ConfirmDeleteAssignmentModal from '@components/auditObjects/attributes/single/AttributesAssignments/ConfirmDeleteAssignmentModal';
import AttributesAssignmentsTable from '@components/auditObjects/attributes/single/AttributesAssignments/AttributesAssignmentsTable/AttributesAssignmentsTable';
import AttributesAssignmentsActionsModal from '@components/auditObjects/attributes/single/AttributesAssignments/AttributeAssignmentsActionsModal';
import AttributeContentWrapper, {
  AttributePage,
} from '@components/auditObjects/attributes/single/AttributeContentWrapper/AttributeContentWrapper';
import SearchInput from '@components/shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';

interface IProps {}

const AttributeAssignments: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    dispatch(
      auditObjectsActions.attributesAssignments.getData({
        attributeId: id,
      })
    );

    return () => {
      dispatch(
        auditObjectsActions.attributesAssignments.toggleFiltersModal(false)
      );
      dispatch(auditObjectsActions.attributesAssignments.toggleAddModal(false));
    };
  }, []);

  return (
    <AttributeContentWrapper
      page={AttributePage.Assignments}
      extra={
        <>
          <SearchInput
            onEnter={(search: string) =>
              dispatch(
                auditObjectsActions.attributesAssignments.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isLocationsReadonly && canManageAuditObjects && (
            <Button
              type="primary"
              data-e2e={
                e2eTestElements.objects.attributesAssignments
                  .openAssignAttributeModal
              }
              onClick={() => {
                dispatch(
                  auditObjectsActions.attributesAssignments.toggleAddModal(true)
                );
              }}
            >
              {formatMessage({
                id: 'AssignAttribute',
              })}
            </Button>
          )}
        </>
      }
    >
      <AttributesAssignmentsTable attributeId={id} />
      <AddAttributeAssignmentModal attributeId={id} />
      <ConfirmDeleteAssignmentModal />
      <AttributesAssignmentsActionsModal />
    </AttributeContentWrapper>
  );
};

export default AttributeAssignments;
