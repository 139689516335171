import React from 'react';

import { TagType } from '@repo/shared/enums';

import Tags from './Tags';

const ItemsTags: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <Tags page={TagType.Item} />;
};

export default ItemsTags;
