import React from 'react';
import { useIntl } from 'react-intl';

import { isNumeric } from '@utils';

import { Input } from '@components/shared/ant/Input';
import Form from '@components/shared/ant/Form';

interface IProps {
  fieldName: string;
  disabled?: boolean;
}

const ExpiredAfterDaysInput: React.FC<IProps> = ({ fieldName, disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Item
      name={['repeat', fieldName]}
      rules={[
        () => ({
          validator(_, value) {
            if (value === '') {
              return Promise.reject(formatMessage({ id: 'RequiredField' }));
            }

            if (!isNumeric(value) || parseInt(value, 10) <= 0) {
              return Promise.reject(
                formatMessage({ id: 'EnterNumberGreaterThan0' })
              );
            }

            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input
        disabled={disabled}
        placeholder={formatMessage({ id: 'EnterAmountOfDays' })}
      />
    </Form.Item>
  );
};

export default ExpiredAfterDaysInput;
