import React from 'react';
import { useAsync } from 'react-async-hook';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';

import Select from '../ant/Select/Select';
import { filterOptionsByChildren } from '@utils';
import { IConcise } from '@repo/shared/types';
import { InternalApiService } from '@repo/shared/api';

const { OptGroup, Option } = AntSelect;

interface IProps extends Omit<SelectProps<any>, 'value'> {
  entity: {
    api: string;
    label: string;
  };
  group: {
    api: string;
    label: string;
  };
  value?: {
    entityIds: string[];
    groupIds: string[];
  };
  onChange?: (
    update: {
      entityIds: string[];
      groupIds: string[];
    },
    option: any
  ) => void;
  width?: string;
  e2eDataAttr?: string;
}

export const ENTITY_ID = 'entity';
export const GROUP_ID = 'group';

const apiService = InternalApiService.getInstance();

function EntityAndGroupSelectAsync<T extends { id: string; name: string }>({
  entity,
  group,
  onChange,
  value,
  ...props
}: IProps) {
  const { formatMessage } = useIntl();

  const { result, loading } = useAsync(
    () =>
      Promise.all([
        apiService.get<T[]>({
          url: entity.api,
        }),
        apiService.get<IConcise[]>({
          url: group.api,
        }),
      ]),
    []
  );

  const { disabled, placeholder } = props;

  let normalizedValue = loading
    ? []
    : [
        ...(value?.entityIds || []).map((id) => `${ENTITY_ID}-${id}`),
        ...(value?.groupIds || []).map((id) => `${GROUP_ID}-${id}`),
      ];

  return (
    <Select
      {...props}
      value={normalizedValue}
      onChange={(selected, option) => {
        if (onChange) {
          onChange(
            {
              entityIds: selected
                .filter((id: string) => id.includes(`${ENTITY_ID}-`))
                .map((id: string) => id.split(`${ENTITY_ID}-`)[1]),
              groupIds: selected
                .filter((id: string) => id.includes(`${GROUP_ID}-`))
                .map((id: string) => id.split(`${GROUP_ID}-`)[1]),
            },
            option
          );
        }
      }}
      disabled={loading || disabled}
      placeholder={loading ? formatMessage({ id: 'loading' }) : placeholder}
      loading={loading}
      mode="multiple"
      showSearch
      optionFilterProp="children"
      filterOption={filterOptionsByChildren}
    >
      {result?.[0] && Array.isArray(result?.[0]) && (
        <OptGroup label={entity.label}>
          {result[0].length > 0 ? (
            result[0].map((entityItem, i) => (
              <Option
                value={`${ENTITY_ID}-${entityItem.id}`}
                key={`${entityItem.id}-${i}`}
              >
                {entityItem.name}
              </Option>
            ))
          ) : (
            <Option value="no-items" key="no-items" disabled>
              {formatMessage({ id: 'NoItems' })}
            </Option>
          )}
        </OptGroup>
      )}
      {result?.[1] && Array.isArray(result?.[1]) && (
        <OptGroup label={group.label}>
          {result[1].length > 0 ? (
            result[1].map((groupItem, i) => (
              <Option
                value={`${GROUP_ID}-${groupItem.id}`}
                key={`${groupItem.id}-${i}`}
              >
                {groupItem.name}
              </Option>
            ))
          ) : (
            <Option value="no-group-items" key="no-group-items" disabled>
              {formatMessage({ id: 'NoItems' })}
            </Option>
          )}
        </OptGroup>
      )}
    </Select>
  );
}

export default React.memo(EntityAndGroupSelectAsync);
