import React from 'react';
import styled from 'styled-components';
import AntInput, { InputProps } from 'antd/es/input';
import { Eye, EyeOff } from 'react-feather';

import { colors } from '@repo/shared/config';

const Input = styled(AntInput)`
  border-radius: 5px;
  background: ${colors.gray3};
  border-color: ${colors.gray3};
  padding: 4px 12px;

  &.ant-input-affix-wrapper {
    padding: 4px 12px 4px 8px;

    input {
      background: ${colors.gray3};
    }
  }

  &.has-error {
    border-color: ${colors.red};
  }

  &::placeholder,
  & > input::placeholder {
    color: ${colors.gray8};
  }

  &:hover {
    background: ${colors.white};
    border-color: ${colors.white};
    box-shadow: 0 0 4px ${colors.blue2};

    &.ant-input-affix-wrapper-disabled {
      box-shadow: 0 0 4px ${colors.gray3};
      background: ${colors.gray3};
      border-color: ${colors.gray3};

      input {
        background: ${colors.gray3}!important;
      }
    }

    &.ant-input-affix-wrapper input {
      background: ${colors.white};
    }
  }

  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: ${colors.blue2};
    box-shadow: 0 0 4px ${colors.blue2};
    background: ${colors.white};

    input {
      background: ${colors.white};
    }
  }

  .ant-input-suffix,
  .ant-input-prefix {
    svg {
      color: ${colors.gray8};
      width: 16px;
    }
  }
`;

const StyledInputPassword = styled(AntInput.Password)`
  .ant-input-suffix {
    &:hover {
      cursor: pointer;
    }

    svg {
      color: ${colors.gray7};
    }
  }
`;

const InputPassword: React.FC<React.PropsWithChildren<InputProps>> =
  React.forwardRef((props, _) => {
    return (
      <StyledInputPassword
        {...props}
        iconRender={(visible: boolean) => (visible ? <Eye /> : <EyeOff />)}
      />
    );
  });

export { Input, InputPassword };
