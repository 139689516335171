import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 24px 24px 20px;
  line-height: 1;
  transition: all 0.4s ease-in-out;

  ${({ hasScroll }: { hasScroll: boolean }) =>
    hasScroll
      ? `
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  `
      : ''}
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-right: 6px;
`;

export const Count = styled.div`
  position: relative;
  top: -1px;
  color: ${colors.gray7};
  font-size: 16px;
`;

export const Container = styled.div`
  border-top: 1px solid ${colors.gray3};
  padding: 0 6px 12px 24px;
`;

export const Content = styled.div`
  padding-top: 14px;
  margin-right: 10px;
`;

export const LoaderContainer = styled.div`
  position: relative;
  height: 50px;

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Flag = styled.div`
  position: relative;
  top: 3px;
`;
