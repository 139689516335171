import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

interface IProps {
  to: string;
}

const NavigateWithId: React.FC<React.PropsWithChildren<IProps>> = ({ to }) => {
  const { id } = useParams();

  return <Navigate to={id ? to.replace(':id', id) : to} replace />;
};

export default NavigateWithId;
