import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const OptionsRows = styled.div`
  margin: 0 0 10px 10px;
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > * {
    &:first-child {
      flex-grow: 1;
    }
  }
`;

export const Divider = styled.div`
  margin-bottom: 7px;
`;

export const ActionBtn = styled.button`
  margin-left: 5px;
  background: none;
  border: 0;
  margin-bottom: 6px;
  padding: 0;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.gray7};
    stroke-width: 3px;
    transition: color 0.2s ease-in-out;
  }

  &:disabled {
    svg {
      color: ${colors.gray3};
    }
  }
`;
