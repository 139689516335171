import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FeatureList, TrialEnds, TrialEnded, Description } from './styled';
import { routes } from '@config';
import { colors } from '@repo/shared/config';
import { date } from '@utils';
import { accountSelectors } from '@store';
import { DEFAULT_APP_NAME } from '@config';

import Button from '../../../../shared/ant/Button';
import TwoColsWithImage from '../TwoColsWithImage/TwoColsWithImage';

const TrialSubscriptionInfo: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage, formatDate } = useIntl();

  const { data: subscription } = useSelector(accountSelectors.getSubscription);
  const { data: prices } = useSelector(accountSelectors.getPrices);
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const navigate = useNavigate();

  if (!subscription || !prices) {
    return null;
  }

  const trialEndDaysDiff = date(subscription.trialPeriodEndDate).diff(
    date(),
    'days'
  );

  return (
    <>
      <TwoColsWithImage
        title={formatMessage({ id: 'The14dayProfessionalFreeTrial' })}
        imageColor={colors.blue2}
        leftColContent={
          <>
            <FeatureList>
              <li>{formatMessage({ id: 'CancelAtAnyTime' })}</li>
              <li>{formatMessage({ id: 'LiveCustomerService' })}</li>
              <li>{formatMessage({ id: 'EnhancedDataPortability' })}</li>
              <li>
                {formatMessage({ id: 'EnterpriseGradeCloudInfrastructure' })}
              </li>
              <li>{formatMessage({ id: '60DayMoneyBackGuarantee' })}</li>
              <li>
                {formatMessage({
                  id: 'IntuitiveMobileAppThatAnyoneCanUseInstantly',
                })}
              </li>
            </FeatureList>
            {trialEndDaysDiff > 0 ? (
              <TrialEnds>
                {formatMessage(
                  { id: 'TheFreeTrialWillEndOnDateInXDays' },
                  {
                    count: trialEndDaysDiff,
                    date: formatDate(
                      date(subscription.trialPeriodEndDate).toDate(),
                      {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }
                    ),
                  }
                )}
              </TrialEnds>
            ) : (
              <TrialEnded>
                {formatMessage({ id: 'ItsTimeToUpgradeToOurProPlan' })}
              </TrialEnded>
            )}
          </>
        }
      />
      {appConfiguration.appName === DEFAULT_APP_NAME && (
        <Description>
          {formatMessage({ id: 'BillingPageTrialDescription' })}
        </Description>
      )}
      <Button
        type="primary"
        htmlType="button"
        onClick={() => navigate(routes.payment)}
      >
        {formatMessage({ id: 'UpgradeToProfessional' })}
      </Button>
    </>
  );
};

export default TrialSubscriptionInfo;
