import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { auditObjectsActions, generalSelectors } from '@store';
import { ProfileSection } from '@components/account/EditProfile/styled';
import { useAppDispatch } from '@hooks';

import HeaderActionMenu from './HeaderActionMenu/HeaderActionMenu';
import AttributeContentWrapper, {
  AttributePage,
} from '@components/auditObjects/attributes/single/AttributeContentWrapper/AttributeContentWrapper';
import AddEditAttributeModal from '@components/auditObjects/attributes/list/AddEditAttributeModal/AddEditAttributeModal';
import ConfirmDeleteAttributesModal from '@components/auditObjects/attributes/list/ConfirmDeleteAttributesModal';

const AttributeDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { id } = useParams();

  useEffect(() => {
    dispatch(auditObjectsActions.attributes.getData());
  }, []);

  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );
  const { loading } = useSelector(
    generalSelectors.getConciseAuditObjectAttributes
  );

  return (
    <AttributeContentWrapper
      page={AttributePage.Details}
      extra={<HeaderActionMenu attributeId={id} />}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {id && attributesMap[id] && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Name' })}</h3>
              <span>{attributesMap[id]?.name}</span>
            </ProfileSection>
          )}
        </>
      )}
      <AddEditAttributeModal />
      <ConfirmDeleteAttributesModal />
    </AttributeContentWrapper>
  );
};

export default AttributeDetails;
