import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { auditsAdapter } from '@application/Audits/store/entityAdapters';
import { Audit } from '@domain/Audits/models/Audit';
import { AuditDetailsState } from '@application/Audits/models/AuditDetailsState';
import { AuditsListState } from '@application/Audits/models/AuditsListState';
import {
  AssignmentType,
  AuditDueDateStatus,
  AuditStartDateStatus,
  DayOfWeek,
} from '@repo/shared/enums';
import { ItemsGroup } from '@application/Audits/enums/ItemsGroup';
import { date } from '@utils';
import { routes } from '@config';
import { ReopenAuditModalState } from '@application/Audits/models/ReopenAuditModalState';
import { ReportAuditModalState } from '@application/Audits/models/ReportAuditModalState';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { AssignUsersToAuditsModalState } from '@application/Audits/models/AssignUsersToAuditsModalState';
import { TrendsModalState } from '@application/Audits/models/TrendsModalState';
import { ViewAuditModalState } from '@application/Audits/models/ViewAuditModalState';
import { getInitialAuditsFilters } from '@application/Audits/utils/getInitialAuditsFilters';

const getPage = (state: IRootState): AuditsPage | null => state.audits.page;

const getFilters = createSelector(
  [
    getPage,
    (state: IRootState): Record<AuditsPage, AuditsFilters> | null =>
      state.audits.list.filters,
  ],
  (page, filters) =>
    page !== null && filters !== null
      ? filters[page]
      : getInitialAuditsFilters()
);

const getAuditsDictionary = (state: IRootState): Record<string, Audit> =>
  auditsAdapter.getSelectors().selectEntities(state.audits.list.data);

const getAuditDetails = (state: IRootState): AuditDetailsState =>
  state.audits.details;

const getAuditsState = (state: IRootState): AuditsListState =>
  state.audits.list;

const getAudits = createSelector(
  getAuditsState,
  ({ loading, meta, error, data }) => ({
    loading,
    error,
    meta,
    data: auditsAdapter.getSelectors().selectAll(data),
  })
);

const getMyAudits = createSelector(
  getAudits,
  ({ loading, data, meta, error }) => {
    return {
      loading,
      audits: data.reduce<{
        list: ItemsGroup[];
        dictionary: Record<ItemsGroup, Audit[]>;
      }>(
        (acc, audit) => {
          const a = acc;

          let group;

          const today = date().startOf('day');

          if (today.isAfter(audit.endDateInformation.localTime, 'day')) {
            group = ItemsGroup.Past;
          } else if (
            today.isSame(audit.startDateInformation.localTime, 'day')
          ) {
            group = ItemsGroup.Today;
          } else if (
            today.isSame(audit.startDateInformation.localTime, 'week')
          ) {
            switch (date(audit.startDateInformation.localTime).weekday()) {
              case DayOfWeek.Monday:
                group = ItemsGroup.Monday;
                break;
              case DayOfWeek.Tuesday:
                group = ItemsGroup.Tuesday;
                break;
              case DayOfWeek.Wednesday:
                group = ItemsGroup.Wednesday;
                break;
              case DayOfWeek.Thursday:
                group = ItemsGroup.Thursday;
                break;
              case DayOfWeek.Friday:
                group = ItemsGroup.Friday;
                break;
              case DayOfWeek.Saturday:
                group = ItemsGroup.Saturday;
                break;
              case DayOfWeek.Sunday:
                group = ItemsGroup.Sunday;
                break;
            }
          } else {
            group = ItemsGroup.Upcoming;
          }

          if (group !== undefined) {
            if (!a.dictionary[group]) {
              a.dictionary[group] = [];
              a.list.push(group);
            }

            a.dictionary[group].push(audit);
          }

          return a;
        },
        {
          list: [],
          dictionary: {} as Record<ItemsGroup, Audit[]>,
        }
      ),
      meta,
      error,
    };
  }
);

const getSelectedFiltersQty = createSelector(getFilters, (filters) => {
  let selected = 0;

  if (window.location.pathname === routes.completedAudits) {
    if (Array.isArray(filters.tagsIds) && filters.tagsIds.length > 0) {
      selected += 1;
    }

    if (filters.completedBy || filters.completedByGroup) {
      selected += 1;
    }
  }

  if (
    filters.auditObjectIds.length > 0 ||
    filters.auditObjectGroupIds.length > 0
  ) {
    selected += 1;
  }

  if (filters.templateId !== null) {
    selected += 1;
  }

  if (filters.startDate !== null && filters.endDate !== null) {
    selected += 1;
  }

  if (filters.scoreMin !== 0 || filters.scoreMax !== 100) {
    selected += 1;
  }

  if (
    filters.assignment !== AssignmentType.all &&
    filters.assignment !== null
  ) {
    selected += 1;
  }

  if (filters.assignedTo || filters.assignedToGroup) {
    selected += 1;
  }

  if (filters.dueDateStatus !== AuditDueDateStatus.All) {
    selected += 1;
  }

  if (filters.startDateStatus !== AuditStartDateStatus.All) {
    selected += 1;
  }

  if (filters.inProgress) {
    selected += 1;
  }

  if (filters.isAutoExpired) {
    selected += 1;
  }

  if (filters.schedulePlansIds.length || filters.auditSchedulesIds.length) {
    selected += 1;
  }

  return selected;
});

const getViewAuditModalState = (state: IRootState): ViewAuditModalState =>
  state.audits.viewAuditModal;

const getReopenModalState = (state: IRootState): ReopenAuditModalState =>
  state.audits.reopenAuditModal;

const isFiltersModalOpened = (state: IRootState): boolean =>
  state.audits.openFiltersModal;

const isCreateOneTimeAuditModalOpened = (state: IRootState): boolean =>
  state.audits.openCreateOneTimeAuditModal;

const isStartSampleAuditModalOpened = (state: IRootState): boolean =>
  state.audits.openStartSampleAuditModal;

const getAuditReportModalAuditState = (
  state: IRootState
): ReportAuditModalState => state.audits.reportModalAudit;

const getConfirmDeleteModalState = (
  state: IRootState
): DeleteModalState<Audit> => state.audits.confirmDeleteModal;

const getAssignUsersToAuditsModalState = (
  state: IRootState
): AssignUsersToAuditsModalState => state.audits.assignUsersToAuditsModal;

const getTrendsModalState = (state: IRootState): TrendsModalState =>
  state.audits.trendsModal;

export const auditsSelectors = {
  getPage,
  getFilters,
  getAuditsDictionary,
  getAuditDetails,
  getAudits,
  getMyAudits,
  getSelectedFiltersQty,
  getViewAuditModalState,
  getReopenModalState,
  isFiltersModalOpened,
  isCreateOneTimeAuditModalOpened,
  isStartSampleAuditModalOpened,
  getAuditReportModalAuditState,
  getConfirmDeleteModalState,
  getAssignUsersToAuditsModalState,
  getTrendsModalState,
};
