import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'antd/es/table';

import { getColumnSortProperties } from '@utils';
import { IAuditObjectAttribute } from '@repo/shared/types';
import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { routes } from '@config';
import { config } from '@repo/shared/config';

import Table from '../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../shared/ant/EmptyTable/EmptyTable';
import AttributesActionsMenu from '@components/auditObjects/attributes/list/AttributesTable/AttributesActionsMenu';

const AttributesTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { loading, error, data, meta } = useSelector(
    auditObjectsSelectors.attributes.getData
  );
  const filters = useSelector(auditObjectsSelectors.attributes.getFilters);

  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const canManage = !isLocationsReadonly && canManageAuditsObjects;

  return (
    <Table<IAuditObjectAttribute>
      onPageChange={(update) =>
        dispatch(auditObjectsActions.attributes.getData(update))
      }
      canSelectRow={canManage}
      loading={loading}
      meta={meta}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          className: 'cell-text-link',
        },
        ...(canManage
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center',
                width: 70,
                render: (_, attribute) => (
                  <AttributesActionsMenu attributeId={attribute.id} />
                ),
              } as ColumnType<IAuditObjectAttribute>,
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((attribute) => ({
              key: attribute.id,
              ...attribute,
            }))
          : []
      }
      onRow={(attribute) => {
        return {
          onClick: () => {
            navigate(
              routes.auditObjects.attributeDetails.replace(':id', attribute.id)
            );
          },
        };
      }}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage({
                  id: 'NoAttributes',
                })}
                {canManage && (
                  <>
                    <br />
                    {formatMessage({ id: 'YouCanCreateAttributeRightNow' })}
                  </>
                )}
              </>
            }
            button={
              canManage
                ? {
                    text: `+ ${formatMessage({ id: 'NewAttribute' })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        auditObjectsActions.attributes.toggleAddEditModal({
                          show: true,
                        })
                      );
                    },
                  }
                : null
            }
            howItWorksUrl={config.urls.attributesSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          auditObjectsActions.attributes.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default AttributesTable;
