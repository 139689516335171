import React from 'react';
import { useIntl } from 'react-intl';

import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { Schedule } from '@domain/Schedules/models/Schedule';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  schedule: Schedule;
}

const SchedulesActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  schedule,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);

  if (!canScheduleAudits) {
    return null;
  }

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'EditSchedule' }),
          onClick: () => {
            dispatch(
              schedulesActions.toggleAddEditModal({
                opened: true,
                scheduleId: schedule.id,
              })
            );
          },
        },
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () => {
            dispatch(
              schedulesActions.toggleDeleteModal({
                multiple: false,
                data: schedule,
              })
            );
          },
        },
      ]}
    />
  );
};

export default SchedulesActionsMenu;
