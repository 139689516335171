import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HeaderActionButton } from './styled';
import { useAppDispatch, usePermission } from '@hooks';
import { accountSelectors, usersActions } from '@store';
import { Permission } from '@repo/shared/enums';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

interface IProps {
  userGroupId?: string;
}

const HeaderActionMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  userGroupId,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { isUsersGroupsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const canManageUsers = usePermission(Permission.CanManageUsers);

  if (isUsersGroupsReadonly || !canManageUsers) {
    return null;
  }

  return (
    <DropDownMenu
      menuItems={[
        {
          name: formatMessage({
            id: 'Edit',
          }),
          onClick: () => {
            dispatch(
              usersActions.groups.toggleAddEditGroupModal({
                visible: true,
                groupId: userGroupId,
              })
            );
          },
        },
        {
          name: formatMessage({
            id: 'Delete',
          }),
          onClick: () => {
            if (userGroupId) {
              dispatch(
                usersActions.groups.toggleConfirmDeleteGroupsModal([
                  userGroupId,
                ])
              );
            }
          },
        },
      ]}
    >
      <HeaderActionButton type="button">
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default HeaderActionMenu;
