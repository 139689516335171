import styled from 'styled-components';
import AntButton from 'antd/es/button';

import { colors } from '@repo/shared/config';

const Button = styled(AntButton)`
  &.ant-btn {
    border-radius: 5px;
    border: none;

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }

    & > span {
      vertical-align: middle;
      line-height: 1;
    }

    &.ant-btn-primary {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
    }

    &.ant-btn-primary {
      background: ${colors.blue2};
    }

    &.ant-btn-default {
      color: ${colors.gray8};
      background: ${colors.gray3};
      font-size: 14px;
    }

    &.ant-btn-link {
      color: ${colors.blue2};
      padding: 0;

      &:hover {
        color: ${colors.blue2};
      }
    }

    &.ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      color: ${colors.gray7};
      background: ${colors.gray2};
      border-color: ${colors.gray2};
      text-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &.ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active {
      color: ${colors.gray7};
    }

    &.ant-btn-text:hover:not([disabled]) {
      color: inherit !important;
      background-color: transparent !important;
    }
  }
`;

export default Button;
