import React from 'react';
import { XCircle } from 'react-feather';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';

import {
  Container,
  SignContainer,
  Sign,
  SignedBy,
  RemoveSignatureBtn,
} from './styled';
import { SignatureBy } from '@repo/shared/enums';
import { ISignature } from '@repo/shared/types';
import { IApiService } from '@repo/shared/types';

import ImageContainer from '@repo/shared/components/Previews/ImageContainer';

const SignatureByLangIds = {
  [SignatureBy.Auditee]: 'Auditee',
  [SignatureBy.Auditor]: 'Auditor',
};

interface IProps {
  signature: ISignature | null;
  signatureBy?: SignatureBy;
  onDelete?: () => void;
  apiService?: IApiService;
}

const Signature: React.FC<React.PropsWithChildren<IProps>> = ({
  signature,
  signatureBy,
  onDelete,
  apiService,
}) => {
  const { formatMessage } = useIntl();

  if (!signature) {
    return null;
  }

  const signedBy = formatMessage({
    id:
      signatureBy !== undefined ? SignatureByLangIds[signatureBy] : 'SignedBy',
  });

  return (
    <Container>
      <ImageContainer fileId={signature.photoId} apiService={apiService}>
        {({ loading, imageUrl, onLoadImage, onLoadImageError }) => (
          <Spin spinning={loading}>
            <SignContainer>
              <Sign
                src={imageUrl}
                onLoad={onLoadImage}
                onError={onLoadImageError}
              />
              {onDelete && (
                <RemoveSignatureBtn type="button" onClick={onDelete}>
                  <XCircle />
                </RemoveSignatureBtn>
              )}
            </SignContainer>
          </Spin>
        )}
      </ImageContainer>
      <SignedBy>
        {signedBy}{' '}
        <span>{signature.createdBy?.name || signature.createdByName}</span>
      </SignedBy>
    </Container>
  );
};

export default Signature;
