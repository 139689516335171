export enum AuditTemplateType {
  Audit,
  Checklist,
}

export enum AuditReportTitlePageTemplate {
  Full,
  Simplified,
}

export enum HistoricalTrendDepth {
  Days30,
  Days60,
  Days90,
  Last1,
  Last2,
  Last3,
  Last4,
  None,
}
