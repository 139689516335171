import styled from 'styled-components';

import cardIcon from './card.svg';
import LibraryButton from '../../../../shared/ant/Button';
import { colors } from '@repo/shared/config';

export const Detail = styled.div`
  display: flex;
  font-size: 13px;
  color: ${colors.gray8};
  margin: 0 -7px 9px;

  & > * {
    margin: 0 7px;
  }

  strong {
    font-weight: normal;
    color: ${colors.gray10};
  }
`;

export const Status = styled.span``;

export const Subtitle = styled.h3`
  margin: 22px 0 14px;
`;

export const CompanyDetails = styled.div`
  font-size: 13px;
  margin-bottom: 15px;

  & > * {
    margin-bottom: 8px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;

  &:before {
    display: block;
    content: '';
    width: 22px;
    height: 16px;
    background: url(${cardIcon}) no-repeat center center;
    margin-right: 10px;
  }
`;

export const Button = styled(LibraryButton)`
  font-size: 12px;
  padding: 0;
`;
