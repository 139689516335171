import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';
import { notification } from '@utils';
import { generalActions, generalSelectors } from '@store';

import Modal from '@components/shared/ant/Modal/Modal';

interface IProps {}

const DeleteIssueTypeQrCodesModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [processing, setProcessing] = useState(false);

  const modalState = useSelector(
    issueTypeQrCodesSelectors.getConfirmDeleteModalState
  );
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  let deleteCandidateIds: string[] = [];
  if (modalState !== null) {
    deleteCandidateIds = modalState.multiple
      ? modalState.data
      : [modalState.data.id];
  }
  const itemCount = deleteCandidateIds.length;

  const closeModal = useCallback(() => {
    dispatch(issueTypeQrCodesActions.toggleConfirmDeleteModal(null));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'DeleteIssueQrCodes' }, { itemCount })}
      open={modalState !== null}
      onOk={async () => {
        setProcessing(true);

        const resultAction = await dispatch(
          issueTypeQrCodesActions.deleteIssueQrCodes(deleteCandidateIds)
        );

        if (
          issueTypeQrCodesActions.deleteIssueQrCodes.fulfilled.match(
            resultAction
          )
        ) {
          dispatch(issueTypeQrCodesActions.getQrCodes());

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingIssueQrCodes' },
              { itemCount }
            ),
          });
        }

        setProcessing(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={processing}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveIssueQrCodesFromSystem' },
        { itemCount }
      )}
    </Modal>
  );
};

export default DeleteIssueTypeQrCodesModal;
