import React from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';

import IssueTypeQrCodes from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodes';
import SearchInput from '@components/shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';
import AuditObjectGroupContentWrapper, {
  AuditObjectGroupPage,
} from '@components/auditObjects/groups/single/AuditObjectGroupContentWrapper/AuditObjectGroupContentWrapper';

interface IProps {}

const AuditObjectGroupQrCodes: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const filters = useSelector(issueTypeQrCodesSelectors.getFilters);

  const { id: auditObjectGroupId } = useParams();

  return (
    <AuditObjectGroupContentWrapper
      page={AuditObjectGroupPage.QRCodes}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                issueTypeQrCodesActions.getQrCodes({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          <Button
            type="primary"
            onClick={() => {
              dispatch(
                issueTypeQrCodesActions.toggleAddEditModal({
                  show: true,
                })
              );
            }}
          >
            {formatMessage({
              id: 'CreateQrCode',
            })}
          </Button>
        </>
      }
    >
      <IssueTypeQrCodes auditObjectGroupId={auditObjectGroupId} />
    </AuditObjectGroupContentWrapper>
  );
};

export default AuditObjectGroupQrCodes;
