import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.gray4};
  margin-bottom: 7px;
  margin-top: 4px;
`;

export const TabButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  border-bottom: 3px solid transparent;
  transition: border-bottom-color 0.2s ease-in-out;
  padding: 10px 12px;
  font-size: 14px;
  line-height: 22px;
  margin: 0 8px;

  &:first-child {
    margin-left: 0;
  }

  ${({ active }: { active: boolean }) =>
    active &&
    `
    border-bottom-color: ${colors.blue2};
  `}
`;

export const Count = styled.div`
  background: ${colors.gray4};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 7px;
  border-radius: 10px;
  margin-left: 5px;
`;
