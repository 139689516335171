import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { routes } from '@config';
import { config } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { SchedulePlanPeriod } from '@domain/SchedulePlans/models/SchedulePlanPeriod';
import { date } from '@utils';
import { Container, CurrentBadge } from './styled';

import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';

const SchedulePlanPeriodsTable: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const keepDataRef = useRef<boolean>(false);

  const { data: schedulePlan } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );

  const {
    data: schedulePlanPeriods,
    loading,
    error,
    meta,
  } = useSelector(schedulePlansSelectors.getSchedulePlanPeriods);

  return (
    <Table<SchedulePlanPeriod>
      onPageChange={(update) => {
        dispatch(schedulePlansActions.getSchedulePlanPeriods(update));
      }}
      onUnmount={() => {
        if (!keepDataRef.current) {
          dispatch(schedulePlansActions.resetPeriodsListData());
        }
      }}
      loading={loading}
      meta={meta}
      canSelectRow={false}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'Period' }),
          dataIndex: 'name',
          key: 'name',
          onCell: ({ id }) => ({
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();

              if (keepDataRef) {
                keepDataRef.current = true;
              }

              if (!schedulePlan) {
                return;
              }

              navigate(
                routes.advancedSchedulePeriodDetails
                  .replace(':schedulePlanId', schedulePlan.id)
                  .replace(':schedulePlanPeriodId', id)
              );
            },
          }),
          render(_, schedulePlanPeriod) {
            return (
              <Container>
                {date(schedulePlanPeriod.startDate).format(config.dateFormat)} -{' '}
                {date(schedulePlanPeriod.endDate).format(config.dateFormat)}
                {schedulePlanPeriod.isCurrent && (
                  <CurrentBadge>
                    {formatMessage({ id: 'Current' })}
                  </CurrentBadge>
                )}
              </Container>
            );
          },
          className: 'cell-text-link',
        },
        {
          title: formatMessage({ id: 'NumberOfAudits' }),
          dataIndex: 'auditsCount',
          key: 'auditsCount',
          render(_, schedulePlanPeriod) {
            return schedulePlanPeriod.auditsCount;
          },
        },
        {
          title: formatMessage({ id: 'NumberOfAuditors' }),
          dataIndex: 'auditsCount',
          key: 'auditsCount',
          render(_, schedulePlanPeriod) {
            return schedulePlanPeriod.auditorsCount;
          },
        },
        {
          title: formatMessage({ id: 'BusyAuditors' }),
          dataIndex: 'busyAuditorsCount',
          key: 'busyAuditorsCount',
          render(_, schedulePlanPeriod) {
            return schedulePlanPeriod.busyAuditorsCount;
          },
        },
        {
          title: formatMessage({ id: 'WaitingAuditors' }),
          dataIndex: 'waitingAuditorsCount',
          key: 'waitingAuditorsCount',
          render(_, schedulePlanPeriod) {
            return schedulePlanPeriod.waitingAuditorsCount;
          },
        },
      ]}
      dataSource={schedulePlanPeriods.map(
        (schedulePlanPeriod: SchedulePlanPeriod) => ({
          ...schedulePlanPeriod,
          key: schedulePlanPeriod.id,
        })
      )}
      locale={{
        emptyText: (
          <EmptyTable text={formatMessage({ id: 'NoPlanningPeriods' })} />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          schedulePlansActions.getSchedulePlanPeriods({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default SchedulePlanPeriodsTable;
