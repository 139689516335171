import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '../shared/ActionsModal/styled';
import { Modal } from '@repo/shared/enums';
import { scoresSelectors, generalSelectors, generalActions } from '@store';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../shared/ActionsModal/ActionsModal';

const ScoresActionsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const score = useSelector(scoresSelectors.getScoresLookup);

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                dispatch(
                  generalActions.showModal({
                    name: Modal.AddEdit,
                    data: score[selectedTableRowKeys[0]],
                  })
                );

                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          <Action
            onClick={() =>
              dispatch(generalActions.showModal({ name: Modal.ConfirmDelete }))
            }
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default ScoresActionsModal;
