import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

const darkBlue = '#05385A';

export const Container = styled.div`
  background: ${darkBlue};
  border-radius: 5px;
  margin-left: 12px;
  margin-right: -9px;
  overflow: hidden;
`;

export const Expandable = styled(animated.div)``;

export const AccountBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 8px 8px 9px;
  background: ${darkBlue};
  border: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${colors.blue7};
  }
`;

export const SelectedAccountBtn = styled(AccountBtn)`
  padding-top: 10px;
  padding-bottom: 10px;
  background: ${({ expanded }: { expanded: boolean }) =>
    expanded ? darkBlue : colors.blue7};

  &:hover {
    background: ${darkBlue};
  }
`;

export const Name = styled.div`
  font-size: 12px;
  color: ${colors.white};
  flex-grow: 1;
  text-align: left;
`;

export const ToggleSwitch = styled.div`
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 100%;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background: ${darkBlue};
  }

  svg {
    width: 12px;
    height: 12px;
    color: ${colors.white};
    transition: all 0.3s ease-in-out;
    transform: rotate(
      ${({ expanded }: { expanded: boolean }) => (expanded ? '90deg' : '0')}
    );
  }
`;

export const ManageAccountsBtn = styled.button`
  display: block;
  width: 100%;
  background: none;
  border: 0;
  font-size: 12px;
  color: ${colors.blue2};
  text-align: center;
  padding: 8px 0 12px;
`;
