import { forwardRef, SVGAttributes } from 'react';

import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  color?: string;
}

const Template = forwardRef<SVGSVGElement, IProps>(
  ({ color = colors.gray7, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width="6"
        height="4"
        viewBox="0 0 6 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path d="M0 3.5L3 0.5L6 3.5" fill={color} />
      </svg>
    );
  }
);

export default Template;
