import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountSelectors, usersActions, usersSelectors } from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import PageHeader from '../../../shared/ant/PageHeader';
import SearchInput from '../../../shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';

const UserGroupsHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canManageUsers = usePermission(Permission.CanManageUsers);
  const { isUsersGroupsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const filters = useSelector(usersSelectors.groups.getFilters);

  return (
    <PageHeader
      title={formatMessage({ id: 'UserGroups' })}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                usersActions.groups.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isUsersGroupsReadonly && canManageUsers && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  usersActions.groups.toggleAddEditGroupModal({
                    visible: true,
                  })
                );
              }}
              data-e2e={e2eTestElements.users.createUserGroup}
            >
              {formatMessage({
                id: 'NewUserGroup',
              })}
            </Button>
          )}
        </>
      }
    />
  );
};

export default UserGroupsHeader;
