import styled from 'styled-components';

import { colors, BREAKPOINTS } from '@repo/shared/config';
import { slideDimensions } from '@repo/shared/components/Previews/FileGallery/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .slick-slider {
    width: 100%;
    max-width: 762px;
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: none;
  }
`;

export const VideoHolder = styled.div`
  background: ${colors.black};
`;

export const ImageHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${slideDimensions};
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    max-height: 500px;
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    max-height: 540px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    max-height: 580px;
  }
`;

const ButtonControl = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 0;
  cursor: pointer;
  background: ${colors.blue2};
  transition: background 0.2s ease-in-out;

  &:disabled {
    background: ${colors.gray8};

    &:hover {
      background: ${colors.gray8};
    }
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.white};
  }
`;

export const PrevButton = styled(ButtonControl)`
  left: 0;
  border-radius: 0px 4px 4px 0px;
`;

export const NextButton = styled(ButtonControl)`
  right: 0;
  border-radius: 4px 0px 0px 4px;
`;

export const FooterButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 4px;
  color: ${colors.gray10};
  margin-left: 5px;

  svg {
    color: ${colors.gray10};
    width: 13px;
    height: 13px;
    margin-right: 7px;
  }
`;
