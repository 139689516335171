import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Space from 'antd/es/space';

import { Permission } from '@repo/shared/enums';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { useAppDispatch, usePermission } from '@hooks';
import { e2eTestElements, routes } from '@config';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import PageHeader from '@components/shared/ant/PageHeader';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';
import Button from '@components/shared/ant/Button';
import ExportButton from './ExportButton';

interface IProps {
  type: AuditsPage;
}

const AuditsHeader: React.FC<React.PropsWithChildren<IProps>> = ({ type }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const filters = useSelector(auditsSelectors.getFilters);

  const selectedFiltersQty = useSelector(auditsSelectors.getSelectedFiltersQty);
  const [canDoAudits, canCreateInstantAudits, canViewAuditsResults] =
    usePermission([
      Permission.CanDoAudits,
      Permission.CanCreateInstantAudits,
      Permission.CanViewAuditResults,
    ]);

  const closeViewAuditModal = useCallback(() => {
    dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
  }, [dispatch]);

  const menuItems = useMemo(() => {
    const items = [];

    if (canDoAudits) {
      items.push({
        id: AuditsPage.My,
        name: formatMessage({ id: 'MyAudits' }),
        onClick: () => {
          navigate(routes.myAudits);
          closeViewAuditModal();
        },
      });

      items.push({
        id: AuditsPage.Pending,
        name: formatMessage({ id: 'Pending' }),
        onClick: () => {
          navigate(routes.pendingAudits);
          closeViewAuditModal();
        },
      });
    }

    if (canViewAuditsResults) {
      items.push({
        id: AuditsPage.Completed,
        name: formatMessage({ id: 'Archive' }),
        onClick: () => {
          navigate(routes.completedAudits);
          closeViewAuditModal();
        },
      });
    }

    if (canDoAudits || canViewAuditsResults) {
      items.push({
        id: AuditsPage.Expired,
        name: formatMessage({ id: 'Expired' }),
        onClick: () => {
          navigate(routes.expiredAudits);
          closeViewAuditModal();
        },
      });
    }

    return items;
  }, [canDoAudits, canViewAuditsResults]);

  return (
    <PageHeader
      title={formatMessage({ id: 'Audits' })}
      extra={
        <Space>
          {type !== AuditsPage.My && (
            <>
              <SearchInput
                value={filters.search}
                onEnter={(search: string) =>
                  dispatch(auditsActions.getAudits({ search, pageNumber: 1 }))
                }
              />
              <FiltersButton
                showModal={() => {
                  dispatch(auditsActions.toggleFiltersModal(true));
                }}
                resetFilters={() => {
                  dispatch(auditsActions.getAudits(null));
                }}
                selectedFilters={selectedFiltersQty}
              />
            </>
          )}
          {canDoAudits &&
            canCreateInstantAudits &&
            (type === AuditsPage.My || type === AuditsPage.Pending) && (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(auditsActions.toggleCreateOneTimeAuditModal(true));
                }}
                data-e2e={e2eTestElements.audits.header.createAdhocAudit}
              >
                {formatMessage({
                  id: 'Create',
                })}
              </Button>
            )}
          <ExportButton />
        </Space>
      }
      footer={
        <PageTabbedSwitcher<AuditsPage> activePage={type} pages={menuItems} />
      }
    />
  );
};

export default AuditsHeader;
