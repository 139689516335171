import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Eye, EyeOff, Copy } from 'react-feather';
import { useSpring } from 'react-spring';

import {
  Container,
  Content,
  Text,
  ChangeVisibilityBtn,
  CopyKeyBtn,
} from './styled';
import { notification } from '@utils';

interface IProps {
  apiKey: string | null;
}

const ApiKeyInput: React.FC<IProps> = ({ apiKey }) => {
  const { formatMessage } = useIntl();

  const [visible, setVisible] = useState(false);

  const props = useSpring({
    from: { height: 0, opacity: 1 },
    to: {
      height: apiKey !== null ? 56 : 0,
      opacity: apiKey !== null ? 1 : 0,
    },
  });

  return (
    <Container style={props}>
      <Content>
        <Text>
          {visible && !!apiKey ? apiKey : '••••••••••••••••••••••••••••'}
        </Text>
        <ChangeVisibilityBtn
          type="button"
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {visible ? <EyeOff /> : <Eye />}
        </ChangeVisibilityBtn>
        <CopyKeyBtn
          type="button"
          title={formatMessage({ id: 'Copy' })}
          onClick={() => {
            if (!apiKey) {
              return;
            }

            navigator.clipboard.writeText(apiKey);

            notification.success({
              message: formatMessage({
                id: 'ApiKeyHasBeenSuccessfullyCopiedToClipboard',
              }),
            });
          }}
        >
          <Copy />
        </CopyKeyBtn>
      </Content>
    </Container>
  );
};

export default ApiKeyInput;
