import styled from 'styled-components';

import logoIcon from './logo-icon.svg';
import { colors } from '@repo/shared/config';

export const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${colors.white};
  padding: 0 12px;
  border-radius: 5px;
  height: 34px;
  line-height: 34px;
  background: linear-gradient(83.16deg, #238dc1, #54c0e8);

  &:hover {
    color: ${colors.white};
  }
`;

export const Icon = styled.span`
  display: block;
  width: 21px;
  height: 21px;
  background: ${colors.gray2};
  margin-right: 10px;
  border-radius: 4px;

  &:after {
    display: block;
    content: '';
    width: 21px;
    height: 21px;
    background: url(${logoIcon}) no-repeat center center;
  }
`;

export const Text = styled.span`
  display: block;
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 3px;
`;
