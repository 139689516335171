import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { prepareComparablesForAPI, selectAuditsTimeStats } from '@utils';
import { generalSelectors } from '@store';
import { completedAuditDetailsAdapter } from '@store/entityAdapters';

export const auditPerformanceReportSelectors = {
  getSectionPerformanceFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) =>
        state.reports.summary.sectionPerformance.comparables,
    ],
    (filters, comparables) => ({
      ...filters,
      compare: prepareComparablesForAPI(comparables),
    })
  ),
  getCompletedAuditDetailsFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) =>
        state.reports.auditPerformance.completedAuditDetails.filters,
    ],
    (sharedFilters, filters) => ({
      ...sharedFilters,
      ...filters,
    })
  ),
  getAuditCompletionFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) => state.reports.summary.auditCompletion.comparables,
    ],
    (filters, comparables) => ({
      ...filters,
      compare: prepareComparablesForAPI(comparables),
    })
  ),
  getAuditPerformanceFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) => state.reports.summary.auditPerformance.comparables,
    ],
    (filters, comparables) => ({
      ...filters,
      compare: prepareComparablesForAPI(comparables),
    })
  ),
  getAuditsTimeStats: createSelector(
    (state: IRootState) => state.reports.auditPerformance.auditsTimeStats,
    selectAuditsTimeStats
  ),
  getCompletedAuditStats: createSelector(
    (state: IRootState) => state.reports.auditPerformance.completedAuditsStats,
    (auditStats) => auditStats
  ),
  getCompletedAuditsDetails: createSelector(
    [
      (state: IRootState) =>
        state.reports.auditPerformance.completedAuditDetails.loading,
      (state: IRootState) =>
        state.reports.auditPerformance.completedAuditDetails.meta,
      (state: IRootState) =>
        state.reports.auditPerformance.completedAuditDetails.error,
      (state: IRootState) =>
        state.reports.auditPerformance.completedAuditDetails.entities,
    ],
    (loading, meta, error, entities) => ({
      loading,
      error,
      meta,
      audits: completedAuditDetailsAdapter.getSelectors().selectAll(entities),
    })
  ),
};
