import React, { useEffect } from 'react';

import { getFirstCharacter } from '@utils';
import { IAccount } from '@repo/shared/types';
import { Icon, LogoIcon } from './styled';

interface IProps {
  onLoad?: () => void;
  imageUrl?: string | undefined;
  account: IAccount;
}
const AccountIcon: React.FC<React.PropsWithChildren<IProps>> = ({
  onLoad,
  account,
  imageUrl,
}) => {
  useEffect(() => {
    if (onLoad && imageUrl) {
      onLoad();
    }
  }, [imageUrl]);

  if (imageUrl) {
    return <LogoIcon src={imageUrl} onLoad={onLoad} />;
  }

  return (
    <Icon size="small" color={account.color}>
      {getFirstCharacter(account.company.name)}
    </Icon>
  );
};

export default AccountIcon;
