import React from 'react';
import Radio, { RadioGroupProps } from 'antd/es/radio';
import Space from 'antd/es/space';

interface IProps<T> extends Omit<RadioGroupProps, 'options' | 'onChange'> {
  options: { value: T; label: string | React.ReactNode }[];
  onChange?: (value: T) => void;
  spaceSize?: number;
  reverse?: boolean;
}

function RadioGroup<T>({
  options,
  onChange,
  spaceSize,
  reverse,
  ...props
}: React.PropsWithChildren<IProps<T>>) {
  return (
    <Radio.Group
      {...props}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      style={{ width: '100%' }}
      className={reverse ? 'reverse-radio-group' : undefined}
    >
      <Space direction="vertical" size={spaceSize} style={{ width: '100%' }}>
        {options.map(({ label, value }, i) => (
          <Radio key={`${i}`} value={value}>
            {label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
}

export default RadioGroup;
