import dayjs from 'dayjs';

import { dateInTimeZone } from '@repo/shared/utils';

import { NotUploadedFileDto } from '@infrastructure/PerformAudit/dto/NotUploadedFileDto';
import { NotUploadedFile } from '@domain/PerformAudit/NotUploadedFile';
import { config } from '@repo/shared/config';

export function transformNotUploadedFileDto({
  updatedAtUtc,
  ...dto
}: NotUploadedFileDto): NotUploadedFile {
  return {
    ...dto,
    updatedAt: dateInTimeZone(updatedAtUtc, dayjs.tz.guess()).format(
      config.apiDateFormat
    ),
  };
}
