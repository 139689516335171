import styled from 'styled-components';
import { Inbox } from 'react-feather';

import { colors } from '@repo/shared/config';

export const Container = styled.div``;

export const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Label = styled.label``;

export const Zone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  background: ${colors.gray2};
  border: 1px dashed
    ${({
      isDragActive,
      hasError,
    }: {
      isDragActive: boolean;
      hasError?: boolean;
    }) => {
      if (isDragActive) {
        return colors.blue2;
      }

      return hasError ? colors.red : colors.gray6;
    }};
  transition: all 0.2s ease-in-out;
  border-radius: 3px;
`;

export const Icon = styled(Inbox)`
  color: ${colors.blue2};
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  font-size: 16px;
  color: ${colors.gray10};
  margin-bottom: 5px;
  text-align: center;
`;

export const Desc = styled.div`
  font-size: 13px;
  line-height: 1.5;
  padding-top: 5px;
  color: ${colors.gray8};
  text-align: center;
`;
