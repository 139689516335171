import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SampleBadge = styled.div`
  margin-left: 7px;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: #0b4368;
  background: #8fb8d2;
`;

interface IProps {
  text: string;
  isSample: boolean;
}

const SampleBadgedName: React.FC<React.PropsWithChildren<IProps>> = ({
  text,
  isSample,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      {text}
      {isSample && <SampleBadge>{formatMessage({ id: 'Sample' })}</SampleBadge>}
    </Container>
  );
};

export default SampleBadgedName;
