import { useEffect, useCallback, useRef } from 'react';
import { BroadcastChannel } from 'broadcast-channel';

export function useBroadcastChannel<T = string>(
  channelName: string,
  handleMessage: (message: T) => void
): (data: T) => void {
  const channelRef = useRef(new BroadcastChannel<T>(channelName));

  useEffect(() => {
    const channel = channelRef.current;

    if (channel) {
      channel.addEventListener('message', handleMessage);

      return () => channel.removeEventListener('message', handleMessage);
    }
  }, [channelRef, handleMessage]);

  return useCallback(
    (data: T) => {
      channelRef?.current?.postMessage(data);
    },
    [channelRef]
  );
}
