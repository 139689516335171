import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Skeleton from 'antd/es/skeleton';

import { PermissionGroupHeader, SubHeader } from '../AddEditRoleModal/styled';
import { IPermission, IRoleDetails } from '@repo/shared/types';
import { rolesActions, generalActions, generalSelectors } from '@store';
import { Checked } from './styled';
import { PERMISSION_TYPES } from '@config';
import { PermissionGroup, Modal } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';

import ViewModal from '../../shared/ViewModal/ViewModal';
import CheckboxRow from '@components/shared/CheckboxRow/CheckboxRow';

type IGroupedPermissions = Record<
  PermissionGroup,
  (IPermission & { langId: string; tooltipLangId?: string })[]
>;

const ViewRoleModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { opened, data } = useSelector(generalSelectors.getModal(Modal.View));
  const [details, setDetails] = useState<IRoleDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const id = data?.id;

  useEffect(() => {
    if (id && opened) {
      const fetchRoleDetails = async () => {
        setLoading(true);

        const resultAction = await dispatch(rolesActions.getRoleDetails(id));

        if (rolesActions.getRoleDetails.fulfilled.match(resultAction)) {
          const roleDetails: IRoleDetails = unwrapResult(resultAction);

          setDetails(roleDetails);
        }

        setLoading(false);
      };

      fetchRoleDetails();
    }
  }, [opened, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(generalActions.hideModal(Modal.View));
    };
  }, [dispatch]);

  const groupedByPermissionGroup = (
    details?.permissions || []
  ).reduce<IGroupedPermissions>((grouped, permission) => {
    if (PERMISSION_TYPES[permission.rolePermissionType] === undefined) {
      return grouped;
    }

    const { group, langId, tooltipLangId } =
      PERMISSION_TYPES[permission.rolePermissionType];

    if (!grouped[group]) {
      grouped[group] = [];
    }

    grouped[group].push({
      ...permission,
      langId,
      tooltipLangId,
    });

    return grouped;
  }, {} as IGroupedPermissions);

  return (
    <ViewModal>
      {() =>
        loading ? (
          <Skeleton active />
        ) : (
          <>
            <SubHeader>{formatMessage({ id: 'Permissions' })}</SubHeader>
            {Object.entries(groupedByPermissionGroup).map(
              ([group, permissions]) => {
                return (
                  <React.Fragment key={group}>
                    <PermissionGroupHeader>
                      {formatMessage({ id: group })}
                    </PermissionGroupHeader>
                    {permissions.map(
                      ({
                        rolePermissionType,
                        langId,
                        tooltipLangId,
                        isEnabled,
                      }) => (
                        <CheckboxRow
                          key={rolePermissionType}
                          label={formatMessage({
                            id: langId,
                          })}
                          tooltip={
                            tooltipLangId
                              ? formatMessage({
                                  id: tooltipLangId,
                                })
                              : undefined
                          }
                        >
                          {isEnabled && <Checked />}
                        </CheckboxRow>
                      )
                    )}
                  </React.Fragment>
                );
              }
            )}
          </>
        )
      }
    </ViewModal>
  );
};

export default ViewRoleModal;
