import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ArrowLeft } from 'react-feather';
import { config, useTransition } from 'react-spring';
import { useNavigate } from 'react-router-dom';

import { accountActions, accountSelectors } from '@store';
import { Title, BackButton, Content } from './styled';
import { PaymentStep } from '@repo/shared/enums';
import { StepWrapper } from './styled';
import { IAnyObject } from '@repo/shared/types';
import { routes } from '@src/config/routes';
import { useAppDispatch } from '@hooks';

import PageHeader from '../../../shared/ant/PageHeader';
import HaveQuestions from '../HaveQuestions/HaveQuestions';
import PaymentForm from './steps/PaymentForm/PaymentForm';
import Processing from './steps/Processing/Processing';
import PaymentResult from './steps/PaymentResult/PaymentResult';

const steps = {
  [PaymentStep.FillUp]: <PaymentForm />,
  [PaymentStep.Processing]: <Processing />,
  [PaymentStep.Result]: <PaymentResult />,
};

interface IProps {}

const Payment: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const step = useSelector(accountSelectors.getPaymentStep);
  const dispatch = useAppDispatch();

  const transitions = useTransition<PaymentStep, IAnyObject>(step, (p) => p, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: {
      opacity: 0,
    },
    config: config.stiff,
  });

  useEffect(() => {
    dispatch(accountActions.getSubscription());
    dispatch(accountActions.getPrices());

    return () => {
      dispatch(accountActions.setPaymentStep(PaymentStep.FillUp));
    };
  }, []);

  return (
    <div>
      <PageHeader
        title={
          <Title>
            <BackButton
              type="button"
              onClick={() => navigate(routes.subscription)}
            >
              <ArrowLeft />
            </BackButton>
            {formatMessage({ id: 'Billing' })}
          </Title>
        }
        extra={<HaveQuestions />}
      />
      <Content>
        {transitions.map(({ item, props, key }) => (
          <StepWrapper key={key} style={props}>
            {steps[item]}
          </StepWrapper>
        ))}
      </Content>
    </div>
  );
};

export default Payment;
