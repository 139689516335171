import React from 'react';
import { useIntl } from 'react-intl';

import { IRole } from '@repo/shared/types';
import { generalActions, rolesActions } from '@store';
import { Modal } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  role: IRole;
}

const RolesActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  role,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const menuItems = [
    {
      name: formatMessage({ id: 'EditRole' }),
      onClick: () => {
        dispatch(rolesActions.showAddEditRoleModal(role));
      },
    },
  ];

  if (!role.isDefault) {
    menuItems.push({
      name: formatMessage({ id: 'Delete' }),
      onClick: () =>
        dispatch(
          generalActions.showModal({
            name: Modal.ConfirmDelete,
            data: role,
          })
        ),
    });
  }

  return <TableRowActionsMenu menuItems={menuItems} />;
};

export default RolesActionsMenu;
