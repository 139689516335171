import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import { useIntl } from 'react-intl';

import { BillingPage, SubscriptionType } from '@repo/shared/enums';
import { accountActions, accountSelectors, rolesActions } from '@store';
import { useAppDispatch } from '@hooks';
import { Container } from './styled';

import BillingLayout from '../BillingLayout/BillingLayout';
import UpgradeToProfessional from './TrialSubscriptionInfo/TrialSubscriptionInfo';
import AutoSubscriptionInfo from './AutoSubscriptionInfo/AutoSubscriptionInfo';
import CustomSubscriptionInfo from './CustomSubscriptionInfo/CustomSubscriptionInfo';

const components = {
  [SubscriptionType.Automated]: <AutoSubscriptionInfo />,
  [SubscriptionType.Custom]: <CustomSubscriptionInfo />,
};

const Subscription: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(rolesActions.getRoles());
  }, []);

  const {
    loading: subscriptionLoading,
    data: subscription,
    error: subscriptionError,
  } = useSelector(accountSelectors.getSubscription);
  const { loading: pricesLoading, error: pricesError } = useSelector(
    accountSelectors.getPrices
  );

  useEffect(() => {
    dispatch(accountActions.getSubscription());
    dispatch(accountActions.getPrices());
  }, []);

  return (
    <BillingLayout page={BillingPage.Subscription}>
      <Container>
        {subscriptionLoading || pricesLoading ? (
          <Skeleton active />
        ) : (
          <>
            {pricesError || subscriptionError ? (
              <>
                {formatMessage({ id: 'ErrorWhileLoadingSubscription' })}.<br />
                {formatMessage({ id: 'PleaseTryAgainOrContactSupport' })}
              </>
            ) : (
              <>
                {subscription === null ||
                subscription.subscriptionType === SubscriptionType.Trial ? (
                  <UpgradeToProfessional />
                ) : (
                  components[
                    subscription.subscriptionType as
                      | SubscriptionType.Automated
                      | SubscriptionType.Custom
                  ]
                )}
              </>
            )}
          </>
        )}
      </Container>
    </BillingLayout>
  );
};

export default Subscription;
