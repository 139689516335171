import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Body,
  Container,
  DetailsButton,
  Header,
  Title,
  ValueContainer,
} from './styled';
import { accountSelectors, actionsActions, generalActions } from '@store';
import { useAppDispatch } from '@hooks';
import { Action } from '@domain/Actions/models/Action';

import ZonedDateTime from '@components/shared/ZonedDateTime';
import ActionStatusName from '@repo/shared/components/ActionStatusName';
import Definition from '@repo/shared/components/Definition/Definition';

const containerStyle: React.CSSProperties = {
  fontSize: '12px',
};

const valueStyle: React.CSSProperties = {
  minHeight: '18px',
};

interface IProps {
  item: Action;
}

const MyAction: React.FC<React.PropsWithChildren<IProps>> = ({ item }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const objectName = useSelector(accountSelectors.getObjectName);

  return (
    <Container>
      <Header>
        <Title>{item.name}</Title>
      </Header>
      <Body>
        <div>
          {!!item.dueDateInformation && (
            <Definition
              horizontal
              valueStyle={valueStyle}
              containerStyle={containerStyle}
              label={formatMessage({ id: 'DueDate' })}
              value={<ZonedDateTime dateTime={item.dueDateInformation} />}
            />
          )}
          <Definition
            horizontal
            valueStyle={valueStyle}
            containerStyle={containerStyle}
            label={formatMessage({ id: 'Status' })}
            value={<ActionStatusName status={item.status} />}
          />
        </div>
        <div>
          {!!item.auditObject && (
            <Definition
              horizontal
              valueStyle={valueStyle}
              containerStyle={containerStyle}
              label={objectName.single}
              value={<ValueContainer>{item.auditObject.name}</ValueContainer>}
            />
          )}
          {!!item.audit && (
            <Definition
              horizontal
              valueStyle={valueStyle}
              containerStyle={containerStyle}
              label={formatMessage({ id: 'Audit' })}
              value={<ValueContainer>{item.audit.name}</ValueContainer>}
            />
          )}
        </div>
        <DetailsButton
          onClick={() => {
            dispatch(actionsActions.toggleFiltersModal(false));
            dispatch(generalActions.selectTableRows([]));
            dispatch(actionsActions.toggleActionDetailsModal(item.id));
          }}
        >
          {formatMessage({ id: 'Details' })}
        </DetailsButton>
      </Body>
    </Container>
  );
};

export default MyAction;
