import { Rule } from 'antd/es/form';
import { intl } from '@repo/shared/components/IntlGlobalProvider';

export const validations = {
  phone: /^\+?\d{10,12}$/,
  password: /^.{8,}$/,
  integer: /^-?\d+$/,
  float: /^-?\d+(\.\d{0,18})?$/,
  decimal: /^-?\d+(\.\d{1,2})?$/,
  natural: /^(0|([1-9]\d*))$/,
  naturalPositive: /^([1-9]\d*)$/,
  decimal4: /^-?\d+(\.\d{0,4})?$/,
  from0to100: /^[0-9]$|^[1-9][0-9]$|^(100)$/,
  email: /\S+@\S+\.\S+/,
  uuid: /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
  url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  html: /(<([^>]+)>)/gi,
  hexColor: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
};

export function disallowURL(error?: string): Rule {
  return () => ({
    validator(_, value) {
      if (!value || !validations.url.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          error ||
            intl?.formatMessage({ id: 'URLIsNotAllowed' }) ||
            'URL is not allowed'
        )
      );
    },
  });
}

export function disallowHTML(error?: string): Rule {
  return () => ({
    validator(_, value) {
      if (!value || !validations.html.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          error ||
            intl?.formatMessage({ id: 'HTMLIsNotAllowed' }) ||
            'HTML is not allowed'
        )
      );
    },
  });
}

export function isUUID(str: string | undefined | null) {
  return validations.uuid.test(str || '');
}

export function disallowEmail(error?: string): Rule {
  return () => ({
    validator(_, value) {
      if (!value || !validations.email.test(value)) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          error ||
            intl?.formatMessage({ id: 'EmailIsNotAllowed' }) ||
            'Email is not allowed'
        )
      );
    },
  });
}

export function limitLength(limit: number) {
  return {
    max: limit,
    message: intl.formatMessage(
      {
        id: 'NameMustNotExceedXCharacters',
      },
      { count: limit }
    ),
  };
}

export function requireNotEmptyArray(error?: string): Rule {
  return () => ({
    validator(_, value) {
      if (!Array.isArray(value) || value.length === 0) {
        return Promise.reject(
          new Error(
            error ||
              intl?.formatMessage({
                id: 'PleaseAttachAtLeastOneFileToContinue',
              }) ||
              'Files are required'
          )
        );
      }

      return Promise.resolve();
    },
  });
}

export default validations;
