import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  height: 74px;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled.button`
  display: block;
  border: 0;
  width: 20px;
  height: 20px;
  background: none;
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;
