import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/es/select';

import { filterOptionsByChildren } from '@utils';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';

import EntityAndGroupSelect from './EntityAndGroupSelect';

interface IProps extends Omit<SelectProps<any>, 'value' | 'onChange'> {
  value?: {
    schedulesIds: string[];
    schedulePlansIds: string[];
  };
  onChange?: (update: {
    schedulesIds: string[];
    schedulePlansIds: string[];
  }) => void;
  width?: string;
}

const SchedulesAndSchedulePlansSelect: React.FC<
  React.PropsWithChildren<IProps>
> = ({ disabled, placeholder, value, onChange, width, ...props }) => {
  const { formatMessage } = useIntl();

  const { data: schedulesConcise, loading: schedulesConciseLoading } =
    useSelector(schedulesSelectors.getConciseSchedules);
  const { data: schedulePlansConcise, loading: schedulePlansConciseLoading } =
    useSelector(schedulePlansSelectors.getConciseSchedulePlans);

  return (
    <EntityAndGroupSelect
      {...props}
      loading={schedulesConciseLoading || schedulePlansConciseLoading}
      entity={{
        options: schedulesConcise,
        label: formatMessage({ id: 'Schedules' }),
      }}
      group={{
        options: schedulePlansConcise,
        label: formatMessage({ id: 'AdvancedSchedules' }),
      }}
      value={{
        entityIds: value?.schedulesIds || [],
        groupIds: value?.schedulePlansIds || [],
      }}
      onChange={({ groupIds, entityIds }) => {
        if (!onChange) {
          return;
        }

        onChange({
          schedulesIds: entityIds,
          schedulePlansIds: groupIds,
        });
      }}
      disabled={disabled}
      placeholder={
        placeholder ||
        formatMessage({ id: 'SelectSchedulesOrAdvancedSchedules' })
      }
      width={width}
      showSearch
      optionFilterProp="children"
      filterOption={filterOptionsByChildren}
    />
  );
};

export default SchedulesAndSchedulePlansSelect;
