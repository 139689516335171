import React from 'react';

import { Button } from './styled';

interface IProps {
  onClick: () => void;
  text: string | number;
}

const TableButtonLink: React.FC<React.PropsWithChildren<IProps>> = ({
  onClick,
  text,
}) => {
  return (
    <Button type="button" onClick={onClick}>
      {text}
    </Button>
  );
};

export default TableButtonLink;
