import React, { useMemo } from 'react';

import mqaLogoDark from './mqa-logo-dark.svg';
import mqaLogoLight from './mqa-logo-light.svg';
import mqaLogoIcon from './mqa-logo-icon.svg';
import { DefaultMqaLogo, LogoImg } from './styled';
import { IApiService } from '@repo/shared/types';

import ImageContainer from '@repo/shared/components/Previews/ImageContainer';

type LogoVersion = 'light' | 'dark' | 'icon';

export interface LogoProps {
  version?: LogoVersion;
  width?: number;
  height?: number;
  align?: string;
  logoId?: string | null;
  size?: number;
  apiService?: IApiService;
  appLogoUrl?: string | null;
}

function useMqaLogoSrc(version: LogoVersion, appLogoUrl?: string | null) {
  if (appLogoUrl) {
    return appLogoUrl;
  }

  switch (version) {
    case 'light':
      return mqaLogoLight;
    case 'dark':
      return mqaLogoDark;
    case 'icon':
      return mqaLogoIcon;
  }
}

const Logo: React.FC<React.PropsWithChildren<LogoProps>> = React.memo(
  ({
    version = 'light',
    width,
    height,
    align = 'left',
    logoId,
    apiService,
    appLogoUrl,
  }) => {
    const dimensions = useMemo(() => {
      const baseWidth = version === 'icon' ? '35px' : '132px';
      const baseHeight = version === 'icon' ? '51px' : '34px';

      return {
        width: width ? `${width}px` : baseWidth,
        height: height ? `${height}px` : baseHeight,
      };
    }, [version]);

    const mqaLogoSrc = useMqaLogoSrc(version, appLogoUrl);

    if (logoId) {
      return (
        <ImageContainer
          fileId={logoId}
          blurBackgroundOnLoad={false}
          apiService={apiService}
        >
          {({ imageUrl, onLoadImage, onLoadImageError }) => {
            return (
              <LogoImg
                src={imageUrl}
                onLoad={onLoadImage}
                onError={onLoadImageError}
                style={{
                  ...dimensions,
                  objectPosition: align,
                }}
              />
            );
          }}
        </ImageContainer>
      );
    }

    return (
      <DefaultMqaLogo
        style={{
          ...dimensions,
          backgroundPosition: align,
          backgroundImage: `url(${mqaLogoSrc})`,
        }}
      />
    );
  }
);

export default Logo;
