import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Headline, Tagline } from './styled';
import { accountSelectors } from '@store';
import { date } from '@utils';

const Welcome: React.FC<React.PropsWithChildren<unknown>> = () => {
  const user = useSelector(accountSelectors.getCurrentUser);
  const { formatMessage } = useIntl();

  return (
    <>
      <Headline>
        {formatMessage({ id: 'WelcomeUsername' }, { userName: user?.name })}
      </Headline>
      <Tagline>{date().format('dddd, MMM D, YYYY')}</Tagline>
    </>
  );
};

export default Welcome;
