import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import ReactScrollbarsCustom from 'react-scrollbars-custom';

import {
  Container,
  Content,
  CommentsList,
  CommentContainer,
  SkeletonContainer,
} from './styled';
import { accountSelectors } from '@store';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';

import Scrollbar from '@components/shared/Scrollbar/Scrollbar';
import AddEditActionComment from '@src/presentation/Actions/ActionDetails/ActionDetailComments/AddEditActionComment';
import ActionDetailsComment from '@src/presentation/Actions/ActionDetails/ActionDetailComments/ActionDetailsComment/ActionDetailsComment';
import DeleteActionCommentModal from '@src/presentation/Actions/ActionDetails/ActionDetailComments/DeleteActionCommentModal';
import NoCommentsPlaceholder from '@src/presentation/Actions/ActionDetails/ActionDetailComments/NoCommentsPlaceholder/NoCommentsPlaceholder';

interface IProps {}

const ActionDetailsComments: React.FC<React.PropsWithChildren<IProps>> = () => {
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const loading = useSelector(actionsSelectors.getActionDetailsLoading);
  const comments = useSelector(actionsSelectors.getActionDetailsComments);
  const scrollbarRef = useRef<ReactScrollbarsCustom>(null);
  const editingComment = useSelector(actionsSelectors.getEditingComment);

  useEffect(() => {
    scrollbarRef.current?.scrollToBottom();
  }, [comments.length]);

  return (
    <Container>
      <Content>
        {loading ? (
          <SkeletonContainer>
            <Skeleton active />
          </SkeletonContainer>
        ) : (
          <>
            {comments.length > 0 ? (
              <Scrollbar ref={scrollbarRef}>
                <CommentsList>
                  {comments.map((comment, i) => (
                    <CommentContainer
                      key={comment.id}
                      style={{
                        justifyContent:
                          currentUser?.id === comment.createdBy?.id
                            ? 'flex-end'
                            : 'flex-start',
                      }}
                    >
                      <ActionDetailsComment
                        comment={comment}
                        editable={
                          comment.createdBy.id === currentUser.id &&
                          comments.length - 1 === i
                        }
                        isEditing={editingComment?.id === comment.id}
                      />
                    </CommentContainer>
                  ))}
                </CommentsList>
              </Scrollbar>
            ) : (
              <NoCommentsPlaceholder />
            )}
          </>
        )}
      </Content>
      <AddEditActionComment />
      <DeleteActionCommentModal />
    </Container>
  );
};

export default ActionDetailsComments;
