import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ChartLineColorGenerator } from '@utils';
import {
  getErrorMessage,
  getExportFileMetaData,
  saveFile,
} from '@repo/shared/utils';
import {
  IActionAuditsAnalytics,
  IPerformanceReportFilters,
  IColorChartData,
  IColorChartDataItem,
  IGetAuditorPerfBarChartResponse,
  IPagedResponse,
  IGetOpenActionResponse,
  IOpenActionsFilters,
} from '@repo/shared/types';
import { ActionStatus } from '@repo/shared/enums';
import {
  AuditStatus,
  AuditorPerformanceReportChartExport,
  ExportType,
  FailedItemStatus,
  PerformanceReportPage,
  PerformanceReportType,
} from '@repo/shared/enums';
import { apiUrls } from '@config';
import { performanceReportSelectors } from './performance-report.selectors';
import { generalActions } from '../general/general.actions';
import { IRootState } from '@src/core/frameworks/redux';
import { Logger } from '@repo/shared/services';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();
const colorLineGenerator = ChartLineColorGenerator.use();

function getApiUrlPrefix(type: PerformanceReportType): string {
  return type === PerformanceReportType.Auditor
    ? apiUrls.reports.auditorPerformance
    : apiUrls.reports.auditObjectPerformance;
}

function createGetDataAsyncThunk<T>({
  actionType,
  apiUrl,
}: {
  actionType: string;
  apiUrl: string;
}) {
  return createAsyncThunk<T, void, { rejectValue: string; state: IRootState }>(
    actionType,
    async (_, { getState }) => {
      const state = getState();
      const type = performanceReportSelectors.getType(state);
      const { tagsIds, ...filters } =
        performanceReportSelectors.getFilters(state);

      return apiService.post<T>({
        url: `${getApiUrlPrefix(type)}${apiUrl}`,
        body: {
          ...filters,
          ...(type === PerformanceReportType.Object ? { tagsIds } : {}),
        },
      });
    }
  );
}

const getAuditsAnalytics = createGetDataAsyncThunk<
  IActionAuditsAnalytics<AuditStatus>
>({
  actionType: 'reports/performance/getAuditsAnalytics',
  apiUrl: '/real-time/audits/analysis',
});

const getActionsAnalytics = createGetDataAsyncThunk<
  IActionAuditsAnalytics<ActionStatus>
>({
  actionType: 'reports/performance/getActionsAnalytics',
  apiUrl: '/real-time/corrective-actions/analysis',
});

const getActionsOverTime = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getActionsOverTime',
  apiUrl: '/historical/corrective-actions/created',
});

const getResolvedActionsOverTime = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getResolvedActionsOverTime',
  apiUrl: '/historical/corrective-actions/resolved',
});

const getFailedItemsOverTimePeriod = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getFailedItemsOverTimePeriod',
  apiUrl: '/historical/failed-items',
});

const getFailedItemsByTags = createGetDataAsyncThunk<
  IGetAuditorPerfBarChartResponse<FailedItemStatus>
>({
  actionType: 'reports/performance/getFailedItemsByTags',
  apiUrl: '/real-time/failed-items/analysis',
});

const getAuditsBurndown = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getAuditsBurndown',
  apiUrl: '/historical/audits/burndown',
});

const getActionsByTags = createGetDataAsyncThunk<
  IGetAuditorPerfBarChartResponse<ActionStatus>
>({
  actionType: 'reports/performance/getActionsByTags',
  apiUrl: '/real-time/corrective-actions/analysis/by-tags',
});

const getActionsBurndown = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getActionsBurndown',
  apiUrl: '/historical/corrective-actions/burndown',
});

const getAuditParticipationRate = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getAuditParticipationRate',
  apiUrl: '/historical/audits/participation-rate',
});

const getActionParticipationRate = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getActionParticipationRate',
  apiUrl: '/historical/corrective-actions/participation-rate',
});

const getCompletedAuditsOverTime = createGetDataAsyncThunk<
  IColorChartData<IColorChartDataItem>[]
>({
  actionType: 'reports/performance/getCompletedAuditsOverTime',
  apiUrl: '/historical/audits/completed',
});

const getOpenActions = createAsyncThunk<
  IPagedResponse<IGetOpenActionResponse>,
  Partial<IOpenActionsFilters> | undefined,
  { rejectValue: string; state: IRootState }
>('reports/performance/getOpenActions', async (_, { getState }) => {
  const state = getState();
  const type = performanceReportSelectors.getType(state);
  const { tagsIds, ...filters } = performanceReportSelectors.getFilters(state);
  const { filters: tableFilters } =
    performanceReportSelectors.getOpenActions(state);

  return apiService.post({
    url: `${getApiUrlPrefix(type)}/real-time/corrective-actions/pending`,
    body: {
      ...filters,
      ...(type === PerformanceReportType.Object ? { tagsIds } : {}),
      ...tableFilters,
    },
  });
});

export const performanceReportActions = {
  getAuditsAnalytics,
  getActionsAnalytics,
  getActionsOverTime,
  getResolvedActionsOverTime,
  getFailedItemsOverTimePeriod,
  getFailedItemsByTags,
  getAuditsBurndown,
  getActionsBurndown,
  getCompletedAuditsOverTime,
  getActionsByTags,
  getAuditParticipationRate,
  getActionParticipationRate,
  getOpenActions,
  updateFilters: createAction<Partial<IPerformanceReportFilters>>(
    'reports/performance/updateFilters'
  ),
  getAuditorPerformanceReport: createAsyncThunk<
    void,
    Partial<IPerformanceReportFilters> | undefined,
    { rejectValue: string; state: IRootState }
  >('reports/performance/get', async (filters, { dispatch, getState }) => {
    const page = performanceReportSelectors.getPage(getState());

    if (filters) {
      const {
        userIds,
        userGroupIds,
        auditObjectGroupIds,
        auditObjectIds,
        templateIds,
        tagsIds,
        dateRangeType,
        startDate,
        endDate,
        ...newSharedFilters
      } = filters;

      if (Object.keys(newSharedFilters).length > 0) {
        dispatch(generalActions.setSharedFilters(newSharedFilters));
      }
    }

    switch (page) {
      case PerformanceReportPage.RealTime:
        dispatch(getActionsAnalytics());
        dispatch(getAuditsAnalytics());
        dispatch(getActionsByTags());
        dispatch(getFailedItemsByTags());
        dispatch(getOpenActions());
        break;
      case PerformanceReportPage.Historical:
        dispatch(getActionsOverTime());
        dispatch(getResolvedActionsOverTime());
        dispatch(getFailedItemsOverTimePeriod());
        dispatch(getCompletedAuditsOverTime());
        dispatch(getAuditsBurndown());
        dispatch(getActionsBurndown());
        dispatch(getAuditParticipationRate());
        dispatch(getActionParticipationRate());
        break;
      default:
        break;
    }
  }),
  setup: createAction<{
    page: PerformanceReportPage;
    type: PerformanceReportType;
  }>('reports/performance/setup'),
  setReportLoadingStatus: createAction<boolean>(
    'reports/performance/setReportLoadingStatus'
  ),
  setFailedItemsByTagsPage: createAction<number>(
    'reports/performance/setFailedItemsByTagsPage'
  ),
  setActionsByTagsPage: createAction<number>(
    'reports/performance/setActionsByTagsPage'
  ),
  reset: createAsyncThunk<void, void>('reports/performance/reset', async () => {
    colorLineGenerator.reset();
  }),
  exportChart: createAsyncThunk<
    void,
    {
      exportType: ExportType;
      chart: AuditorPerformanceReportChartExport;
    },
    { state: IRootState }
  >(
    'reports/performance/exportChart',
    async ({ exportType, chart }, { getState, rejectWithValue }) => {
      const state = getState();
      const filters = performanceReportSelectors.getFilters(state);
      const type = performanceReportSelectors.getType(state);
      const urlPrefix = getApiUrlPrefix(type);

      const { mimeType, urlSuffix, extension } =
        getExportFileMetaData(exportType);

      const meta = {
        [AuditorPerformanceReportChartExport.AuditBurndown]: {
          url: `${urlPrefix}/historical/audits/burndown/export-${urlSuffix}`,
          fileName: `audits-burndown.${extension}`,
        },
        [AuditorPerformanceReportChartExport.AuditParticipationRate]: {
          url: `${urlPrefix}/historical/audits/participation-rate/export-${urlSuffix}`,
          fileName: `audits-participation-rate.${extension}`,
        },
        [AuditorPerformanceReportChartExport.ActionBurndown]: {
          url: `${urlPrefix}/historical/corrective-actions/burndown/export-${urlSuffix}`,
          fileName: `corrective-actions-burndown.${extension}`,
        },
        [AuditorPerformanceReportChartExport.ActionParticipationRate]: {
          url: `${urlPrefix}/historical/corrective-actions/participation-rate/export-${urlSuffix}`,
          fileName: `corrective-actions-participation-rate.${extension}`,
        },
        [AuditorPerformanceReportChartExport.ActionsByTags]: {
          url: `${urlPrefix}/real-time/corrective-actions/analysis/by-tags/export-${urlSuffix}`,
          fileName: `corrective-actions-by-tags.${extension}`,
        },
        [AuditorPerformanceReportChartExport.FailedItemsByTags]: {
          url: `${urlPrefix}/real-time/failed-items/analysis/export-${urlSuffix}`,
          fileName: `failed-items-by-tags.${extension}`,
        },
        [AuditorPerformanceReportChartExport.CompletedAuditsOverTime]: {
          url: `${urlPrefix}/historical/audits/completed/export-${urlSuffix}`,
          fileName: `completed-audits-overtime.${extension}`,
        },
        [AuditorPerformanceReportChartExport.FailedItemsOverTime]: {
          url: `${urlPrefix}/historical/failed-items/export-${urlSuffix}`,
          fileName: `failed-items-overtime.${extension}`,
        },
        [AuditorPerformanceReportChartExport.ResolvedActionsOverTime]: {
          url: `${urlPrefix}/historical/corrective-actions/resolved/export-${urlSuffix}`,
          fileName: `resolved-actions-overtime.${extension}`,
        },
        [AuditorPerformanceReportChartExport.ActionsOverTime]: {
          url: `${urlPrefix}/historical/corrective-actions/created/export-${urlSuffix}`,
          fileName: `corrective-actions-overtime.${extension}`,
        },
        [AuditorPerformanceReportChartExport.OpenActions]: {
          url: `${urlPrefix}/real-time/corrective-actions/pending/export-${urlSuffix}`,
          fileName: `open-actions.${extension}`,
        },
      };

      try {
        const { data } = await apiService.post<{ data: 'arraybuffer' }>({
          responseType: 'arraybuffer',
          url: meta[chart].url,
          body: {
            ...filters,
          },
          withHeaders: true,
        });

        saveFile({
          data,
          fileName: meta[chart].fileName,
          mimeType,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
};
