import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getColumnSortProperties } from '@utils';
import { IAuditObjectGroup } from '@repo/shared/types';
import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission, RoleType } from '@repo/shared/enums';
import { usePermission } from '@hooks/account';
import { e2eTestElements, routes } from '@config';
import { config, colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';

import Table from '../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../shared/ant/EmptyTable/EmptyTable';
import AuditObjectsGroupsActionsMenu from '@components/auditObjects/groups/list/AuditObjectsGroupsTable/AuditObjectsGroupsActionsMenu';

const AuditObjectsGroupsTable: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { loading, error, data, meta } = useSelector(
    auditObjectsSelectors.groups.getGroups
  );
  const filters = useSelector(auditObjectsSelectors.groups.getFilters);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const isCurrentUserAdmin = currentUser?.role.roleType === RoleType.Admin;

  return (
    <Table
      onPageChange={(update) =>
        dispatch(auditObjectsActions.groups.getData(update))
      }
      onSelectRow={() => {
        dispatch(
          auditObjectsActions.groups.toggleAddEditModal({ visible: false })
        );
      }}
      loading={loading}
      meta={meta}
      canSelectRow={!isLocationsReadonly && canManageAuditsObjects}
      onUnmount={() => {
        dispatch(
          auditObjectsActions.groups.toggleAddEditModal({ visible: false })
        );
      }}
      error={error}
      e2eDataAttr={e2eTestElements.objects.groupsTable}
      onRow={(auditObjectGroup) => {
        return {
          onClick: () => {
            navigate(
              routes.auditObjects.groupDetails.replace(
                ':id',
                auditObjectGroup.id
              )
            );
          },
        };
      }}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          render: (_, group: IAuditObjectGroup) => (
            <div>
              <span style={{ color: colors.blue2 }}>{group.name}</span>
              {!!group.auditObjectsCount && (
                <span
                  style={{ color: colors.gray7 }}
                >{` (${group.auditObjectsCount})`}</span>
              )}
            </div>
          ),
        },
        ...(canManageAuditsObjects
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (group: IAuditObjectGroup) => (
                  <AuditObjectsGroupsActionsMenu group={group} />
                ),
              },
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((group: IAuditObjectGroup) => ({
              key: group.id,
              ...group,
            }))
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage(
                  {
                    id:
                      filters.search !== ''
                        ? 'NoAuditObjectsGroupsSearchResults'
                        : 'NoAuditObjectsGroups',
                  },
                  {
                    searchStr: filters.search,
                    objectName: auditObjectName.plural.toLowerCase(),
                  }
                )}
              </>
            }
            button={
              !isLocationsReadonly && isCurrentUserAdmin
                ? {
                    text: `+ ${formatMessage(
                      { id: 'CreateNewAuditObjectGroup' },
                      { objectName: auditObjectName.single.toLowerCase() }
                    )}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        auditObjectsActions.groups.toggleAddEditModal({
                          visible: true,
                        })
                      );
                    },
                  }
                : null
            }
            howItWorksUrl={config.urls.auditObjectGroupsSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          auditObjectsActions.groups.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default AuditObjectsGroupsTable;
