import React, { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import Spin from 'antd/es/spin';

import { apiUrls } from '@config';
import { Container, FolderIcon, TemplateName } from './styled';

import { InternalApiService } from '@repo/shared/api';

interface IProps {
  templateId: string;
  onError: () => void;
}

const apiService = InternalApiService.getInstance();

const PreSelectedTemplate: React.FC<React.PropsWithChildren<IProps>> = ({
  templateId,
  onError,
}) => {
  const { result, loading, error } = useAsync<{ id: string; name: string }>(
    () =>
      apiService.get({
        url: `${apiUrls.libraryTemplates}/${templateId}`,
      }),
    []
  );

  useEffect(() => {
    if (error) {
      onError();
    }
  }, [error]);

  if (error) {
    return null;
  }

  return (
    <Spin spinning={loading} size="small">
      <Container>
        {!loading && !!result?.name && (
          <>
            <FolderIcon />
            <TemplateName>{result.name}</TemplateName>
          </>
        )}
      </Container>
    </Spin>
  );
};

export default PreSelectedTemplate;
