import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import ReactScrollbarsCustom from 'react-scrollbars-custom';
import uniqBy from 'lodash/uniqBy';

import { issuesSelectors, issuesActions } from '@application/Issues/store';
import {
  Container,
  EventsListWrapper,
  EventsList,
  EventContainer,
  SkeletonContainer,
} from './styled';
import { useAppDispatch, useRealtimeSync } from '@hooks';
import { accountSelectors } from '@store';
import IssuesApiClient from '@infrastructure/Issues/api/IssuesApiClient';
import { date } from '@utils';

import Scrollbar from '@components/shared/Scrollbar/Scrollbar';
import AddEditIssueComment from './AddEditIssueComment';
import IssueDetailsEvent from './IssueDetailsEvent/IssueDetailsEvent';

interface IProps {}

const issuesApiClient = new IssuesApiClient();

enum IssueRealtimeEventType {
  Events,
  Details,
}

interface IssuesRealtimeEvent {
  companyId: string;
  type: IssueRealtimeEventType;
  issueId: string;
}

const IssueDetailsEvents: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const issue = useSelector(issuesSelectors.getDetailsIssue);
  const issueId = issue?.id;
  const currentCompanyId = useSelector(accountSelectors.getCurrentCompanyId);
  const browserTabId = useSelector(accountSelectors.getBrowserTabId);
  const scrollbarRef = useRef<ReactScrollbarsCustom>(null);
  const events = useSelector(issuesSelectors.getIssueDetailsEvents);

  useRealtimeSync<IssuesRealtimeEvent>({
    channelId: `issues_${browserTabId}`,
    active: !!issueId,
    registerRealtimeClient: async () =>
      issuesApiClient.getEventsUpdatesToken(browserTabId, issueId!),
    hasChangesForInboundSync: (e) =>
      e.message.companyId === currentCompanyId && e.message.issueId === issueId,
    syncInbound: async (_events) => {
      const events = uniqBy(_events, 'type');

      if (events.find(({ type }) => type === IssueRealtimeEventType.Events)) {
        dispatch(issuesActions.getNewIssueEvents(issueId!));
      }

      if (events.find(({ type }) => type === IssueRealtimeEventType.Details)) {
        await dispatch(
          issuesActions.getIssueDetails({
            issueId: issueId!,
            showLoader: false,
          })
        );
      }
    },
  });

  useEffect(() => {
    async function getEventsAndScroll(id: string) {
      await dispatch(issuesActions.getIssueEvents(id));
      scrollbarRef.current?.scrollToBottom();
    }

    if (issueId) {
      getEventsAndScroll(issueId);
    }
  }, [issueId]);

  useEffect(() => {
    scrollbarRef.current?.scrollToBottom();
  }, [events.data.length]);

  return (
    <Container>
      <EventsListWrapper>
        <Scrollbar ref={scrollbarRef}>
          <EventsList>
            {events.loading ? (
              <SkeletonContainer>
                <Skeleton active />
              </SkeletonContainer>
            ) : (
              <>
                {events.data
                  .sort((a, b) =>
                    date(a.createDate.localTime).isAfter(b.createDate.localTime)
                      ? 1
                      : -1
                  )
                  .map((event) => (
                    <EventContainer
                      key={event.id}
                      style={{
                        justifyContent:
                          currentUser?.id === event.createdBy?.id
                            ? 'flex-end'
                            : 'flex-start',
                      }}
                    >
                      <IssueDetailsEvent event={event} />
                    </EventContainer>
                  ))}
              </>
            )}
          </EventsList>
        </Scrollbar>
      </EventsListWrapper>
      <AddEditIssueComment />
    </Container>
  );
};

export default IssueDetailsEvents;
