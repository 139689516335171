import {
  AuditStatus,
  FailedItemStatus,
  Permission,
  PermissionGroup,
  SelfGuidedTourStep,
} from '@repo/shared/enums';
import { routes } from '@config/routes';
import { SelfGuidedTourSteps } from '@repo/shared/types';
import { ActionStatus } from '@repo/shared/enums';

export const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

export const NO_ACCOUNTS_ERROR = 'no-accounts-error';

export const CURRENT_PASS_NOT_CORRECT_ERR = 'current-password-is-not-correct';

export const REQUIRE_RECENT_LOGIN_ERR = 'recent-login-is-required';

export const PERMISSION_TYPES: {
  [key in Permission]: {
    langId: string;
    group: PermissionGroup;
    tooltipLangId?: string;
  };
} = {
  [Permission.None]: {
    langId: 'None',
    group: PermissionGroup.General,
  },
  [Permission.CanDoAudits]: {
    langId: 'CanDoAudits',
    group: PermissionGroup.Audits,
  },
  [Permission.CanAssignAudits]: {
    langId: 'CanAssignAudits',
    group: PermissionGroup.Audits,
  },
  [Permission.CanManageAudits]: {
    langId: 'CanManageAudits',
    group: PermissionGroup.Audits,
  },
  [Permission.CanScheduleAudits]: {
    langId: 'CanScheduleAudits',
    group: PermissionGroup.Audits,
  },
  [Permission.CanCreateInstantAudits]: {
    langId: 'CanCreateInstantAudits',
    group: PermissionGroup.Audits,
  },
  [Permission.CanViewAuditResults]: {
    langId: 'CanViewAuditResults',
    group: PermissionGroup.Audits,
  },
  [Permission.CanManageCorrectiveActions]: {
    langId: 'CanManageCorrectiveActions',
    group: PermissionGroup.Actions,
  },
  [Permission.CanCreateOrDeleteCorrectiveActions]: {
    langId: 'CanCreateOrDeleteCorrectiveActions',
    group: PermissionGroup.Actions,
  },
  [Permission.CanApproveCorrectiveActions]: {
    langId: 'CanApproveCorrectiveActions',
    group: PermissionGroup.Actions,
  },
  [Permission.CanAssignCorrectiveActions]: {
    langId: 'CanAssignCorrectiveActions',
    group: PermissionGroup.Actions,
  },
  [Permission.CanDoCorrectiveActions]: {
    langId: 'CanDoCorrectiveActions',
    group: PermissionGroup.Actions,
  },
  [Permission.CanViewCorrectiveActions]: {
    langId: 'CanViewCorrectiveActions',
    group: PermissionGroup.Actions,
  },
  [Permission.CanViewAnalytics]: {
    langId: 'CanViewAnalytics',
    group: PermissionGroup.Analytics,
  },
  [Permission.CanManageNotifications]: {
    langId: 'CanManageNotifications',
    group: PermissionGroup.Notifications,
  },
  [Permission.CanManageAuditObjects]: {
    langId: 'CanManageAuditObjects',
    group: PermissionGroup.AuditObjects,
  },
  [Permission.CanManageAuditTemplates]: {
    langId: 'CanManageAuditTemplates',
    group: PermissionGroup.AuditTemplates,
  },
  [Permission.CanManageUsers]: {
    langId: 'CanManageUsers',
    group: PermissionGroup.Users,
  },
  [Permission.CanManageTags]: {
    langId: 'CanManageTags',
    group: PermissionGroup.Tags,
  },
  [Permission.CanManageRoles]: {
    langId: 'CanManageRoles',
    group: PermissionGroup.Roles,
  },
  [Permission.CanManageScoreSystems]: {
    langId: 'CanManageScoreSystems',
    group: PermissionGroup.ScoreSystems,
  },
  [Permission.CanManageBilling]: {
    langId: 'CanManageBilling',
    group: PermissionGroup.Billing,
  },
  [Permission.CanManageSecurity]: {
    langId: 'CanManageSecurity',
    group: PermissionGroup.Security,
  },
  [Permission.CanManageGeneralSetup]: {
    langId: 'CanManageGeneralSetup',
    group: PermissionGroup.General,
  },
  [Permission.CanAccessNonParticipantAuditObjects]: {
    langId: 'CanAccessNonParticipantAuditObjects',
    group: PermissionGroup.Access,
    tooltipLangId:
      'GrantsAccessToAuditObjectsAndTheirAssociatedAuditsRegardlessOfWhetherTheUserIsListedAsParticipant',
  },
  [Permission.CanDeleteIssues]: {
    langId: 'CanDeleteIssues',
    group: PermissionGroup.Issues,
  },
  [Permission.CanEditIssues]: {
    langId: 'CanEditIssues',
    group: PermissionGroup.Issues,
  },
  [Permission.CanChangeIssueStatus]: {
    langId: 'CanChangeIssueStatus',
    group: PermissionGroup.Issues,
  },
  [Permission.CanViewIssues]: {
    langId: 'CanViewIssues',
    group: PermissionGroup.Issues,
  },
  [Permission.CanManageIssueTypes]: {
    langId: 'CanManageIssueTypes',
    group: PermissionGroup.Issues,
  },
};

export const HAS_RESET_PASSWORD_TEMP = 'hasResetPasswordTemp';

const canViewAuditsPagesPermissions = [
  Permission.CanDoAudits,
  Permission.CanManageAudits,
  Permission.CanAssignAudits,
  Permission.CanScheduleAudits,
];

// @ts-ignore
export const selfGuidedTourSteps: SelfGuidedTourSteps = {
  [SelfGuidedTourStep.StartTour]: {
    route: null,
    nextStep: SelfGuidedTourStep.SummaryReportWidgets,
    requiredPermissions: null,
  },
  [SelfGuidedTourStep.SummaryReportWidgets]: {
    route: routes.summaryReport,
    nextStep: SelfGuidedTourStep.Settings,
    requiredPermissions: [Permission.CanViewAnalytics],
  },
  [SelfGuidedTourStep.Settings]: {
    route: routes.summaryReport,
    nextStep: SelfGuidedTourStep.AddUser,
    requiredPermissions: [Permission.CanViewAnalytics],
  },
  [SelfGuidedTourStep.AddUser]: {
    route: routes.users.list,
    nextStep: SelfGuidedTourStep.AddUserFormNameEmail,
    requiredPermissions: null,
  },
  [SelfGuidedTourStep.AddUserFormNameEmail]: {
    route: routes.users.list,
    nextStep: SelfGuidedTourStep.AddUserFormRoles,
    requiredPermissions: null,
  },
  [SelfGuidedTourStep.AddUserFormRoles]: {
    route: routes.users.list,
    nextStep: SelfGuidedTourStep.AddObject,
    requiredPermissions: null,
  },
  [SelfGuidedTourStep.AddObject]: {
    route: routes.auditObjects.list,
    nextStep: SelfGuidedTourStep.AddObjectForm,
    requiredPermissions: null,
  },
  [SelfGuidedTourStep.AddObjectForm]: {
    route: routes.auditObjects.list,
    nextStep: SelfGuidedTourStep.CreateTemplate,
    requiredPermissions: null,
  },
  [SelfGuidedTourStep.CreateTemplate]: {
    route: routes.auditTemplates,
    nextStep: SelfGuidedTourStep.BuilderTemplateSetup,
    requiredPermissions: [Permission.CanManageAuditTemplates],
  },
  [SelfGuidedTourStep.BuilderTemplateSetup]: {
    route: routes.auditTemplates,
    nextStep: SelfGuidedTourStep.BuilderTemplatePreview,
    requiredPermissions: [Permission.CanManageAuditTemplates],
  },
  [SelfGuidedTourStep.BuilderTemplatePreview]: {
    route: routes.auditTemplates,
    nextStep: SelfGuidedTourStep.AddSchedule,
    requiredPermissions: [Permission.CanManageAuditTemplates],
  },
  [SelfGuidedTourStep.AddSchedule]: {
    route: routes.schedules,
    nextStep: SelfGuidedTourStep.AddScheduleForm,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.AddScheduleForm]: {
    route: routes.schedules,
    nextStep: SelfGuidedTourStep.ViewAudits,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.ViewAudits]: {
    route: routes.completedAudits,
    nextStep: SelfGuidedTourStep.ViewAuditsReports,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.ViewAuditsReports]: {
    route: routes.completedAudits,
    nextStep: SelfGuidedTourStep.AuditReport,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.AuditReport]: {
    route: routes.completedAudits,
    nextStep: SelfGuidedTourStep.CustomizeAuditReport,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.CustomizeAuditReport]: {
    route: routes.completedAudits,
    nextStep: SelfGuidedTourStep.ExportAuditReportPDF,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.ExportAuditReportPDF]: {
    route: routes.completedAudits,
    nextStep: SelfGuidedTourStep.SummaryDashboard,
    requiredPermissions: canViewAuditsPagesPermissions,
  },
  [SelfGuidedTourStep.SummaryDashboard]: {
    route: routes.summaryReport,
    nextStep: SelfGuidedTourStep.TrackActionsWidget,
    requiredPermissions: [Permission.CanViewAnalytics],
  },
  [SelfGuidedTourStep.TrackActionsWidget]: {
    route: routes.summaryReport,
    nextStep: SelfGuidedTourStep.ActionsList,
    requiredPermissions: [Permission.CanViewAnalytics],
  },
  [SelfGuidedTourStep.ActionsList]: {
    route: routes.correctiveActions,
    nextStep: SelfGuidedTourStep.ActionDetails,
    requiredPermissions: [Permission.CanViewCorrectiveActions],
  },
  [SelfGuidedTourStep.ActionDetails]: {
    route: routes.pendingActions,
    nextStep: SelfGuidedTourStep.AuditPerformanceWidget,
    requiredPermissions: [Permission.CanViewCorrectiveActions],
  },
  [SelfGuidedTourStep.AuditPerformanceWidget]: {
    route: routes.summaryReport,
    nextStep: SelfGuidedTourStep.CustomReport,
    requiredPermissions: [Permission.CanViewAnalytics],
  },
  [SelfGuidedTourStep.CustomReport]: {
    route: routes.customReport,
    nextStep: SelfGuidedTourStep.EndTour,
    requiredPermissions: [Permission.CanViewAnalytics],
  },
  [SelfGuidedTourStep.EndTour]: {
    route: routes.dashboard,
    nextStep: null,
    requiredPermissions: null,
  },
};

export const failedItemStatuses: Record<FailedItemStatus, string> = {
  [FailedItemStatus.Total]: 'Total',
  [FailedItemStatus.Critical]: 'Critical',
};

export const actionStatuses: Record<ActionStatus, string> = {
  [ActionStatus.Opened]: 'Opened',
  [ActionStatus.Approved]: 'Approved',
  [ActionStatus.Rejected]: 'Rejected',
  [ActionStatus.Submitted]: 'Submitted',
  [ActionStatus.Expired]: 'Expired',
};

export const auditStatuses: Record<AuditStatus, string> = {
  [AuditStatus.Pending]: 'Pending',
  [AuditStatus.Reopened]: 'Reopened',
  [AuditStatus.Completed]: 'Completed',
  [AuditStatus.InProgress]: 'InProgress',
  [AuditStatus.Expired]: 'Expired',
};

export const DEFAULT_APP_NAME = 'monitorQA';
