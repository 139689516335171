import { createEntityAdapter, createReducer, Reducer } from '@reduxjs/toolkit';

import { tagsActions as actions } from './tags.actions';
import { getInitialFilters } from '@utils';
import { ITag } from '@repo/shared/types';
import { ITagsState } from '@types';
import { TagType } from '@repo/shared/enums';

export const tagsAdapter = createEntityAdapter<ITag>();

export const tagsReducer: Reducer<ITagsState> = createReducer<ITagsState>(
  {
    entities: tagsAdapter.getInitialState(),
    loading: false,
    meta: null,
    error: null,
    page: TagType.Audit,
    filters: {
      [TagType.Audit]: {
        ...getInitialFilters(),
        tagType: TagType.Audit,
      },
      [TagType.Item]: {
        ...getInitialFilters(),
        tagType: TagType.Item,
      },
      [TagType.Action]: {
        ...getInitialFilters(),
        tagType: TagType.Action,
      },
    },
  },
  (builder) =>
    builder
      .addCase(actions.setPage, (state, { payload }) => {
        state.page = payload;
      })
      .addCase(actions.getTags.pending, (state, { meta: { arg } }) => {
        state.loading = true;
        state.error = null;
        state.filters[state.page] = {
          ...state.filters[state.page],
          ...(arg || {}),
        };
      })
      .addCase(actions.getTags.fulfilled, (state, action) => {
        state.loading = false;
        state.meta = action.payload.meta;
        tagsAdapter.setAll(state.entities, action.payload.data);
      })
      .addCase(actions.getTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || null;
      })
);
