import React from 'react';
import { useIntl } from 'react-intl';

import { IUser } from '@repo/shared/types';
import { e2eTestElements } from '@config';

import AddEditModal from '../../../shared/AddEditModal/AddEditModal';
import AddEditUserModalForm from './AddEditUserModalForm';

const AddEditUserModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <AddEditModal
      titles={{
        add: formatMessage({ id: 'AddNewUser' }),
        edit: formatMessage({ id: 'EditUser' }),
      }}
      closeBtnE2eDataAttr={e2eTestElements.users.addEditUserForm.closeModal}
    >
      {({ closeModal, data }) => (
        <AddEditUserModalForm
          data={data as IUser | undefined}
          closeModal={closeModal}
        />
      )}
    </AddEditModal>
  );
};

export default AddEditUserModal;
