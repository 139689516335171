import React, { useEffect } from 'react';

import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { useAppDispatch } from '@hooks';

import SchedulesHeader from '@presentation/Schedules/SchedulesHeader';
import SchedulesTable from '@presentation/Schedules/SchedulesTable/SchedulesTable';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import SchedulesFiltersModal from '@presentation/Schedules/SchedulesFiltersModal/SchedulesFiltersModal';
import AddEditScheduleModalRouteHandler from '@presentation/Schedules/AddEditScheduleModal/AddEditScheduleModalRouteHandler';

const Schedules: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(schedulesActions.getSchedules());
  }, [dispatch]);

  return (
    <>
      <SampleDataAlert />
      <SchedulesHeader />
      <SchedulesTable />
      <SchedulesFiltersModal />
      <AddEditScheduleModalRouteHandler />
    </>
  );
};

export default Schedules;
