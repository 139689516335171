import { ScheduleRepeatFormValues } from '@application/Schedules/models/ScheduleFormValues';
import { date, getWeekDaysArray } from '@utils';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';
import { ExpirationType } from '@domain/Schedules/enums/ExpirationType';
import { DayOfWeek } from '@repo/shared/enums';

const weekDaysArray = getWeekDaysArray();

export function getDefaultFormRepeatValues(
  pattern?: AuditRepeatPattern
): ScheduleRepeatFormValues {
  const expiration = {
    auditsExpirationType: ExpirationType.NeverAutoExpire,
    auditsExpireAfterDays: '',
    actionsExpirationType: ExpirationType.NeverAutoExpire,
    actionsExpireAfterDays: '',
  };

  switch (pattern) {
    case AuditRepeatPattern.OneTime:
    case undefined:
      return {
        repeatPattern: AuditRepeatPattern.OneTime,
        range: [date(), date().add(7, 'days')],
        ...expiration,
      };
    case AuditRepeatPattern.Daily:
      return {
        repeatPattern: AuditRepeatPattern.Daily,
        repeatEvery: '1',
        ...expiration,
      };
    case AuditRepeatPattern.Weekly:
      return {
        repeatPattern: AuditRepeatPattern.Weekly,
        daysOfWeek: weekDaysArray,
        ...expiration,
      };
    case AuditRepeatPattern.MultipleWeeks:
      return {
        repeatPattern: AuditRepeatPattern.MultipleWeeks,
        repeatEvery: '1',
        startDay: DayOfWeek.Monday,
        duration: '1',
        ...expiration,
      };
    case AuditRepeatPattern.Monthly:
      return {
        repeatPattern: AuditRepeatPattern.Monthly,
        repeatEvery: '1',
        startDay: '1',
        duration: '1',
        ...expiration,
      };
  }
}
