import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Photo = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M14.75 5.5V3.25M14.75 3.25V1M14.75 3.25L12.5 3.25M14.75 3.25H17M9.5 3.25H2.75C1.92157 3.25 1.25 3.92157 1.25 4.75V15.25C1.25 16.0784 1.92157 16.75 2.75 16.75H13.25C14.0784 16.75 14.75 16.0784 14.75 15.25V8.5M8 13C9.65685 13 11 11.6569 11 10C11 8.34315 9.65685 7 8 7C6.34315 7 5 8.34315 5 10C5 11.6569 6.34315 13 8 13Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default Photo;
