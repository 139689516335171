import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  createAddOrUpdateEntityThunk,
  createDeleteEntityThunk,
  removeUndefinedAndNullProps,
} from '@utils';
import { getErrorMessage } from '@repo/shared/utils';
import { IRootState } from '../../../frameworks/redux';
import { Logger } from '@repo/shared/services';

import {
  IUsersAccessFilters,
  IUserAccess,
  IJobTitle,
  IPagedResponse,
  ITableFilters,
  IUpdateUserGroupRequest,
  IUpdateUserRequest,
  IUser,
  IUserGroup,
  IUserGroupAssignment,
  IUsersFilters,
  IUserGroupAssignmentsFilters,
} from '@repo/shared/types';
import { IConfirmResponse } from '@repo/shared/types';
import { apiUrls } from '@src/config/apiUrls';
import { usersSelectors } from './users.selectors';
import { InternalApiService } from '@repo/shared/api';
import { ApiConflictError, UnexpectedError } from '@repo/shared/errors';

const apiService = InternalApiService.getInstance();

export const usersActions = {
  list: {
    getData: createAsyncThunk<
      IPagedResponse<IUser>,
      Partial<IUsersFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'users/list/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = usersSelectors.list.getFilters(state);

        try {
          return apiService.post({
            url: `${apiUrls.users}/detailed`,
            body: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    resetData: createAction('users/list/resetData'),
    updateUser: createAddOrUpdateEntityThunk<IUpdateUserRequest>({
      apiUrl: apiUrls.users,
      entityName: 'users',
    }),
    deleteUsers: createDeleteEntityThunk({
      apiUrl: apiUrls.users,
      entityName: 'users',
    }),
    toggleSetUserPasswordModal: createAction<string | null>(
      'users/toggleSetUserPasswordModal'
    ),
    setUserPassword: createAsyncThunk<
      void,
      { userId: string; password: string },
      { rejectValue: string }
    >(
      'users/setUserPassword',
      async ({ userId, password }, { rejectWithValue }) => {
        try {
          await apiService.post({
            url: `${apiUrls.users}/${userId}/password`,
            body: {
              password,
            },
          });
        } catch (e: unknown) {
          Logger.captureException(e);

          if (e instanceof ApiConflictError) {
            return rejectWithValue(e.message);
          }

          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    getUserDetails: createAsyncThunk<IUser, string, { rejectValue: string }>(
      'users/getUserDetails',
      async (id, { rejectWithValue }) => {
        try {
          return apiService.get({
            url: `${apiUrls.users}/detailed/${id}`,
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    updateUserDetails: createAction<IUser | null>('users/updateUserDetails'),
    toggleFiltersModal: createAction<boolean>('users/list/toggleFiltersModal'),
  },
  groups: {
    getData: createAsyncThunk<
      IPagedResponse<IUserGroup>,
      Partial<ITableFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'users/groups/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = usersSelectors.groups.getFilters(state);

        try {
          return apiService.get({
            url: `${apiUrls.userGroups}/detailed`,
            query: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleAddEditGroupModal: createAction<{
      visible: boolean;
      groupId?: string;
    }>('users/groups/toggleAddEditModal'),
    toggleConfirmDeleteGroupsModal: createAction<string[]>(
      'users/groups/toggleConfirmDeleteGroupsModal'
    ),
    addEditUserGroup: createAsyncThunk<
      IConfirmResponse,
      IUpdateUserGroupRequest,
      { rejectValue: string }
    >('userGroups/createOrUpdate', async (payload, { rejectWithValue }) => {
      const createNew = !payload.id;

      try {
        const response = await apiService[createNew ? 'post' : 'put']({
          url: apiUrls.userGroups,
          body: payload,
        });

        return response as IConfirmResponse;
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
    deleteUsersGroups: createAsyncThunk<
      IConfirmResponse,
      string[],
      { rejectValue: string }
    >('userGroups/deleteUsersGroups', async (ids, { rejectWithValue }) => {
      try {
        return apiService.delete({
          url: apiUrls.userGroups,
          body: {
            ids,
          },
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
  },
  jobTitles: {
    getData: createAsyncThunk<
      IPagedResponse<IJobTitle>,
      Partial<ITableFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'users/jobTitles/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = usersSelectors.jobTitles.getFilters(state);

        try {
          return apiService.get({
            url: `${apiUrls.jobTitles}/detailed`,
            query: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleAddEditModal: createAction<{ show: boolean; jobTitleId?: string }>(
      'users/jobTitles/toggleAddEditModal'
    ),
    addEditJobTitle: createAsyncThunk<
      void,
      Omit<IJobTitle, 'id'> & { id?: string },
      { state: IRootState; rejectValue: string }
    >(
      'users/jobTitles/addEditJobTitle',
      async (jobTitle, { rejectWithValue }) => {
        try {
          const isEdit = jobTitle.id !== undefined;

          return apiService[isEdit ? 'put' : 'post']({
            url: apiUrls.jobTitles,
            body: jobTitle,
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    deleteJobTitles: createAsyncThunk<
      void,
      string[],
      { state: IRootState; rejectValue: string }
    >(
      'users/jobTitles/deleteJobTitles',
      async (jobTitlesIds, { rejectWithValue }) => {
        try {
          return apiService.delete({
            url: apiUrls.jobTitles,
            body: {
              ids: jobTitlesIds,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmDeleteModal: createAction<string[]>(
      'users/jobTitles/toggleConfirmDeleteModal'
    ),
  },
  groupAssignments: {
    getData: createAsyncThunk<
      IPagedResponse<IUserGroupAssignment>,
      Partial<IUserGroupAssignmentsFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >(
      'users/groupAssignments/getData',
      async (update = {}, { rejectWithValue, getState }) => {
        const state = getState();
        const filters = usersSelectors.groupAssignments.getFilters(state);

        try {
          return apiService.post({
            url: `${apiUrls.users}/groups/assignments/detailed`,
            body: removeUndefinedAndNullProps({
              ...filters,
              ...update,
            }),
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    resetData: createAction('users/groupAssignments/resetData'),
    toggleFiltersModal: createAction<boolean>(
      'users/groupAssignments/toggleFiltersModal'
    ),
    toggleAddToGroupModal: createAction<boolean>(
      'users/groupAssignments/toggleAddToGroupModal'
    ),
    createAssignment: createAsyncThunk<
      void,
      {
        usersIds: string[];
        groupsIds: string[];
      },
      { state: IRootState; rejectValue: string }
    >(
      'users/groupAssignments/create',
      async ({ usersIds, groupsIds }, { rejectWithValue }) => {
        try {
          return apiService.post({
            url: `${apiUrls.users}/groups/assignments`,
            body: { usersIds, userGroupsIds: groupsIds },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmDeleteModal: createAction<string[]>(
      'users/groupAssignments/toggleConfirmDeleteModal'
    ),
    deleteAssignments: createAsyncThunk<
      void,
      string[],
      { state: IRootState; rejectValue: string }
    >(
      'users/groupAssignments/delete',
      async (assignmentsIds, { rejectWithValue }) => {
        try {
          return apiService.delete({
            url: `${apiUrls.users}/groups/assignments`,
            body: {
              ids: assignmentsIds,
            },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
  },
  access: {
    getData: createAsyncThunk<
      IPagedResponse<IUserAccess>,
      Partial<IUsersAccessFilters> | undefined,
      { state: IRootState; rejectValue: string }
    >('users/access/getData', async (_ = {}, { rejectWithValue, getState }) => {
      const state = getState();

      const { userId, auditObjectId, ...filters } =
        usersSelectors.access.getFilters(state);

      try {
        if (!userId && !auditObjectId) {
          throw new UnexpectedError('userId and auditObjectId are not set');
        }

        return await apiService.get({
          url: userId
            ? `${apiUrls.users}/${userId}/access`
            : `${apiUrls.auditObjects}/${auditObjectId}/access`,
          query: removeUndefinedAndNullProps({
            ...filters,
          }),
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
    resetData: createAction('users/access/resetData'),
    toggleAssignAccessModal: createAction<boolean>(
      'users/access/toggleAssignAccessModal'
    ),
    createAccess: createAsyncThunk<
      void,
      {
        auditObjectsIds: string[];
        jobTitlesIds: string[];
        usersIds?: string[];
        userGroupsIds?: string[];
      },
      { state: IRootState; rejectValue: string }
    >(
      'users/access/create',
      async (
        { jobTitlesIds, auditObjectsIds, usersIds, userGroupsIds },
        { rejectWithValue }
      ) => {
        try {
          return apiService.post({
            url: `${apiUrls.auditObjects}/access`,
            body: { jobTitlesIds, auditObjectsIds, usersIds, userGroupsIds },
          });
        } catch (e) {
          Logger.captureException(e);
          return rejectWithValue(getErrorMessage(e));
        }
      }
    ),
    toggleConfirmDeleteModal: createAction<string[]>(
      'users/access/toggleConfirmDeleteModal'
    ),
    deleteAccess: createAsyncThunk<
      void,
      string[],
      { state: IRootState; rejectValue: string }
    >('users/access/delete', async (accessIds, { rejectWithValue }) => {
      try {
        return apiService.delete({
          url: `${apiUrls.auditObjects}/access`,
          body: {
            ids: accessIds,
          },
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }),
    toggleFiltersModal: createAction<boolean>(
      'users/access/toggleFiltersModal'
    ),
  },
};
