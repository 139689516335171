import React from 'react';
import { useIntl } from 'react-intl';

import { ActionDetails } from '@domain/Actions/models/Action';
import { getRoutesFromAuditStatus } from '@application/Audits/utils/getRoutesFromAuditStatus';
import { e2eTestElements } from '@config';
import { usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';

import ExternalLink from '@components/shared/ExternalLink/ExternalLink';
import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';

interface IProps {
  action: ActionDetails;
}

const ActionsDetailsAudit: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  const canDoAudits = usePermission(Permission.CanDoAudits);

  if (!action.audit) {
    return null;
  }

  return (
    <ActionDetailsInfoItem
      label={formatMessage({ id: 'Audit' })}
      content={
        <ExternalLink
          disabled={!canDoAudits}
          data-e2e={e2eTestElements.actions.detailsModal.linkedAudit}
          to={getRoutesFromAuditStatus(action.audit.status).single.replace(
            ':id',
            action.audit!.id
          )}
        >
          {action.audit.name}
        </ExternalLink>
      }
    />
  );
};

export default ActionsDetailsAudit;
