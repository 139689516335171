import { createEntityAdapter } from '@reduxjs/toolkit';

import { IssueType } from '@domain/IssueTypes/models/IssueType';
import { IssueTypeField } from '@domain/IssueTypes/models/IssueTypeField';
import { IConcise } from '@repo/shared/types';

export const issuesTypesEntityAdapter = createEntityAdapter<IssueType>();

export const issueTypeFieldsEntityAdapter =
  createEntityAdapter<IssueTypeField>();

export const conciseIssueTypesEntityAdapter = createEntityAdapter<IConcise>();
