import React from 'react';

import AccountsHeader from './AccountsHeader';
import AccountsTable from './AccountsTable';
import Setup from '../Setup/Setup';
import DeleteAccountModal from './DeleteAccountModal';

const Accounts: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <AccountsHeader />
      <AccountsTable />
      <Setup />
      <DeleteAccountModal />
    </>
  );
};

export default Accounts;
