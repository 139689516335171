import React from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { Schedule } from '@domain/Schedules/models/Schedule';
import { notification } from '@utils';

import ChangeScheduleStateModalAndButton from '@presentation/SchedulePlans/ChangeScheduleStateModalAndButton/ChangeScheduleStateModalAndButton';

interface IProps {
  schedule: Schedule;
}

const SchedulesTableChangeStatusCell: React.FC<
  React.PropsWithChildren<IProps>
> = ({ schedule: { id, active, startFromDate, stopByDate } }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return (
    <ChangeScheduleStateModalAndButton
      active={active}
      startFromDate={startFromDate}
      stopByDate={stopByDate}
      onChange={async (params) => {
        const resultAction = await dispatch(
          schedulesActions.changeStatus({
            id,
            ...params,
          })
        );

        if (schedulesActions.changeStatus.fulfilled.match(resultAction)) {
          notification.success({
            message: formatMessage({
              id: !active
                ? 'ScheduleHasBeenStartedSuccessfully'
                : 'ScheduleHasBeenStoppedSuccessfully',
            }),
          });

          return true;
        } else {
          notification.error({
            message: formatMessage({
              id: !active
                ? 'ErrorWhileStartingSchedule'
                : 'ErrorWhilePausingSchedule',
            }),
          });

          return false;
        }
      }}
    />
  );
};

export default SchedulesTableChangeStatusCell;
