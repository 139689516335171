import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 212px;
`;

export const ChartWrapper = styled.div`
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  left: 25%;
  top: 32%;
  width: 122px;
  height: 62px;
`;

export const Numbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Total = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-right: 6px;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;
