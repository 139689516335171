import { account } from './account';
import { common } from './common';
import { dashboard } from './dashboard';
import { users } from './users';
import { objects } from './objects';
import { templates } from './templates';
import { schedules } from './schedules';
import { actions } from './actions';
import { audits } from './audits';
import { issues } from './issues';

export const e2eTestElements = {
  common,
  account,
  dashboard,
  users,
  objects,
  templates,
  schedules,
  actions,
  audits,
  issues,
};
