import styled from 'styled-components';
import Avatar from 'antd/es/avatar';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 8px;
`;

export const LogoIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
`;

export const Icon = styled(Avatar)`
  background: ${({ color }: { color?: string }) => color || colors.blue2};
  font-weight: normal;
  margin-right: 8px;
  flex-shrink: 0;
`;
