import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import { issueTypesActions } from '@application/IssueTypes/store';
import { IssueType } from '@domain/IssueTypes/models/IssueType';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  issueType: IssueType;
}

const IssueTypesActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  issueType,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'Edit' }),
          onClick: () => {
            navigate(routes.issueTypeDetails.replace(':id', issueType.id));
          },
        },
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () => {
            dispatch(
              issueTypesActions.toggleConfirmDeleteIssueTypesModal({
                multiple: false,
                data: issueType,
              })
            );
          },
        },
      ]}
    />
  );
};

export default IssueTypesActionsMenu;
