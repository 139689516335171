import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { IDetailsModalAudit } from '@repo/shared/types';
import { date, dateUTC } from '@utils';
import { routes } from '@config';
import { config } from '@repo/shared/config';
import {
  AuditObjectName,
  Container,
  ExpiredStatus,
  LateStatus,
  Name,
  NameContainer,
} from './styled';
import { itemAnalysisReportSelectors } from '@store';
import {
  AuditStatusReports,
  DetailsModalType,
  Permission,
} from '@repo/shared/enums';
import { usePermission } from '@hooks';

import ContentBlock from './ContentBlock/ContentBlock';
import DetailsModalItem from '@components/reports/itemAnalysis/DetailsModal/DetailsModalAudit/DetailsModalItem/DetailsModalItem';
import ScoreChange from '@components/shared/ScoreChange/ScoreChange';

interface IProps {
  audit: IDetailsModalAudit;
}

const DetailsModalAudit: React.FC<React.PropsWithChildren<IProps>> = ({
  audit,
}) => {
  const { formatMessage } = useIntl();

  const itemsByAudit = useSelector(
    itemAnalysisReportSelectors.detailsModal.getItemsByAudit
  );
  const type = useSelector(itemAnalysisReportSelectors.detailsModal.getType);
  const [canDoAudits, canAssignAudits, canManageAudits, canScheduleAudits] =
    usePermission([
      Permission.CanDoAudits,
      Permission.CanAssignAudits,
      Permission.CanManageAudits,
      Permission.CanScheduleAudits,
      Permission.CanViewAuditResults,
      Permission.CanManageAuditTemplates,
      Permission.CanManageBilling,
      Permission.CanViewCorrectiveActions,
      Permission.CanViewAnalytics,
      Permission.CanManageGeneralSetup,
      Permission.CanDoCorrectiveActions,
    ]);
  const canViewAuditsPages =
    canDoAudits || canAssignAudits || canManageAudits || canScheduleAudits;

  const isLate = useMemo(() => {
    if (!audit.dueDateUtc) {
      return null;
    }

    if (audit.completedAtInformation && audit.dueDateInformation) {
      return date(audit.completedAtInformation.localTime).isAfter(
        audit.dueDateInformation.localTime
      );
    }

    return dateUTC().isAfter(audit.dueDateUtc);
  }, [audit.dueDateUtc]);

  const auditItems = itemsByAudit[audit.id];

  const auditDate = audit.completedAtInformation || audit.dueDateInformation;

  return (
    <Container>
      <ContentBlock
        label={
          auditDate
            ? `${date(auditDate.localTime).format(config.dateFormat)} (${
                auditDate.timeZoneAbbreviation
              })`
            : ''
        }
        content={
          <>
            <NameContainer>
              <Name
                disabled={!canViewAuditsPages}
                onClick={() => {
                  let url = window.origin;
                  let auditId = audit.id;

                  if (audit.auditStatus === AuditStatusReports.Expired) {
                    url += routes.expiredAudit;

                    if (audit.auditId) {
                      auditId = audit.auditId;
                    }
                  } else if (audit.completedAtInformation !== null) {
                    url += routes.completedAudit;

                    if (audit.auditId) {
                      auditId = audit.auditId;
                    }
                  } else {
                    url += routes.pendingAudit;
                  }

                  window.open(url.replace(':id', auditId), '_blank');
                }}
              >
                {audit.number ? `#${audit.number} ` : ''}
                {audit.name}
              </Name>
              {audit.auditStatus === AuditStatusReports.Completed && (
                <ScoreChange color={audit.scoreColor} score={audit.score} />
              )}
              {audit.auditStatus === AuditStatusReports.Expired ? (
                <ExpiredStatus>
                  {formatMessage({ id: 'Expired' })}
                </ExpiredStatus>
              ) : (
                <>
                  {isLate !== null && (
                    <LateStatus isLate={isLate}>
                      {formatMessage({ id: isLate ? 'Late' : 'OnTime' })}
                    </LateStatus>
                  )}
                </>
              )}
            </NameContainer>
            <AuditObjectName>{audit.auditObject.name}</AuditObjectName>
          </>
        }
      />
      {type !== DetailsModalType.Audits && auditItems && (
        <>
          {auditItems.map((item) => (
            <DetailsModalItem key={item.id} item={item} />
          ))}
        </>
      )}
    </Container>
  );
};

export default DetailsModalAudit;
