import styled from 'styled-components';

import { colors } from '@repo/shared/config';

import Space from 'antd/es/space';

export const FilterGroupHeader = styled.div`
  color: ${colors.gray8};
  margin: 25px 0 12px;
`;

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const CheckboxRowWrapper = styled.div`
  margin-top: 22px;
`;
