import { BaseApiClient } from '@repo/shared/api';
import { IIssueTypeQrCodesApiClient } from '@infrastructure/IssueTypes/models/IIssueTypeQrCodesApiClient';
import { CreateIssueTypeQrCodeDto } from '@infrastructure/IssueTypes/dto/CreateIssueTypeQrCodeDto';
import { UpdateIssueTypeQrCodeDto } from '@infrastructure/IssueTypes/dto/UpdateIssueTypeQrCodeDto';
import { IssueTypeQrCodesFilters } from '@application/IssueTypes/models/IssueTypeQrCodesFilters';
import { IPagedResponse } from '@repo/shared/types';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';

class IssueTypeQrCodesApiClient
  extends BaseApiClient
  implements IIssueTypeQrCodesApiClient
{
  public async getQrCodes(
    filters: IssueTypeQrCodesFilters
  ): Promise<IPagedResponse<IssueTypeQrCode>> {
    return this._apiService.post({
      url: 'issue-types-links',
      body: filters,
    });
  }

  public async createQrCode(
    dto: CreateIssueTypeQrCodeDto
  ): Promise<{ id: string }> {
    const { id } = await this._apiService.post<{ id: string }>({
      url: 'issue-types-links/create',
      body: {
        ...dto,
      },
    });

    return {
      id,
    };
  }

  public async updateQrCode({
    id,
    ...payload
  }: UpdateIssueTypeQrCodeDto): Promise<void> {
    await this._apiService.put({
      url: `issue-types-links/${id}`,
      body: {
        ...payload,
      },
    });
  }

  public async deleteQrCodes(ids: string[]): Promise<void> {
    await this._apiService.delete({
      url: 'issue-types-links',
      body: {
        ids,
      },
    });
  }
}

export default IssueTypeQrCodesApiClient;
