import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';

import { Buttons, ClearBtn, FilterGroupHeader, FilterQty } from './styled';
import { accountSelectors } from '@store';
import { IOrderByDirection } from '@repo/shared/types';
import { date, enumToSelectOptions, useTranslatedOptions } from '@utils';
import { config } from '@repo/shared/config';
import { AssignmentType, Priority } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import {
  CompletedIssuesLateStatus,
  IssuesFilters,
  PendingIssuesDueDateStatus,
} from '@application/Issues/models/IssuesFilters';
import { IssueCloseReason } from '@domain/Issues/models/IssueEvent';

import SlideModal from '@components/shared/SideModal/SideModal';
import UserOrUserGroupSelect from '@components/shared/EntitySelects/UserOrUserGroupSelect';
import Form from '@components/shared/ant/Form';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import AssignmentButtonGroup from '@presentation/Audits/AuditsFiltersModal/AssignmentButtonGroup/AssignmentButtonGroup';
import AuditObjectsSelect from '@components/shared/EntitySelects/AuditObjectsSelect';
import PrioritySelect from '@components/shared/PrioritySelect';
import IssueCloseReasonSelect from '@src/presentation/Issues/IssueDetails/IssueCloseReasonSelect';
import IssueTypesSelect from '@components/shared/EntitySelects/IssueTypesSelect';
import Select from '@components/shared/ant/Select/Select';

const _dueDateStatusOptions = enumToSelectOptions(PendingIssuesDueDateStatus);
const _lateStatusOptions = enumToSelectOptions(CompletedIssuesLateStatus);

interface IProps {
  page: IssuesPage;
}

const IssuesFiltersModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const opened = useSelector(issuesSelectors.isFiltersModalOpened);
  const filters = useSelector(issuesSelectors.getFilters);
  const selectedFiltersQty = useSelector(issuesSelectors.getSelectedFiltersQty);
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  useEffect(() => {
    if (opened) {
      form.resetFields();
    }
  }, [opened]);

  const getIssues = useCallback(
    (update: Partial<IssuesFilters> | null) =>
      dispatch(issuesActions.getIssues(update)),
    [dispatch]
  );

  const closeModal = useCallback(
    () => dispatch(issuesActions.toggleFiltersModal(false)),
    [dispatch]
  );

  const dueDateStatusOptions = useTranslatedOptions(_dueDateStatusOptions);
  const lateStatusOptions = useTranslatedOptions(_lateStatusOptions);

  return (
    <SlideModal
      opened={opened}
      onModalClose={() => {
        closeModal();
      }}
    >
      <Form<{
        priority: Priority | -1;
        auditObject: {
          auditObjectIds: string[];
          auditObjectGroupIds: string[];
        };
        order: {
          orderBy: string;
          orderByDirection: IOrderByDirection;
        };
        createdBy: {
          userId: string | null;
          userGroupId: string | null;
        };
        range: [Dayjs, Dayjs];
        assignment: AssignmentType;
        reason: IssueCloseReason | null;
        dueDateStatus: PendingIssuesDueDateStatus | null;
        lateStatus: CompletedIssuesLateStatus | null;
      }>
        form={form}
        name="issues-filters-form"
        onFinish={() => {
          closeModal();
        }}
        initialValues={{
          auditObjectIds: filters.auditObjectIds,
          range:
            filters.startDate && filters.endDate
              ? [date(filters.startDate), date(filters.endDate)]
              : null,
          priority: filters.priority === null ? -1 : filters.priority,
          order: {
            orderBy: filters.orderBy,
            orderByDirection: filters.orderByDirection,
          },
          assignment: filters.assignment,
          assignedTo: {
            userId: filters.assignedTo,
            userGroupId: filters.assignedToGroup,
          },
          issueTypeIds: filters.issueTypeIds,
          reason: filters.reason === null ? -1 : filters.reason,
          dueDateStatus: filters.dueDateStatus,
          lateStatus: filters.lateStatus,
        }}
      >
        <Form.Item name="assignment">
          <AssignmentButtonGroup
            onChange={(assignment) => {
              getIssues({
                assignment,
                pageNumber: 1,
                assignedTo: null,
                assignedToGroup: null,
              });

              form.setFieldsValue({ assignedTo: null, assignedToGroup: null });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'OrAssignedTo' })}
        </FilterGroupHeader>
        <Form.Item name="assignedTo">
          <UserOrUserGroupSelect
            allowClear
            onChange={({ userGroupId, userId }) => {
              getIssues({
                assignedTo: userId,
                assignedToGroup: userGroupId,
                assignment: null,
                pageNumber: 1,
              });

              form.setFieldsValue({ assignment: null });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>{auditObjectName.plural}</FilterGroupHeader>
        <Form.Item name="auditObjectIds">
          <AuditObjectsSelect
            allowClear
            mode="multiple"
            onChange={(auditObjectIds: string[]) =>
              getIssues({
                auditObjectIds,
                pageNumber: 1,
              })
            }
            placeholder={formatMessage(
              { id: 'SelectAuditObject' },
              { objectName: auditObjectName.single.toLowerCase() }
            )}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'IssueType' })}
        </FilterGroupHeader>
        <Form.Item name="issueTypeIds">
          <IssueTypesSelect
            allowClear
            mode="multiple"
            onChange={(issueTypeIds: string[]) =>
              getIssues({
                issueTypeIds,
                pageNumber: 1,
              })
            }
            placeholder={formatMessage({ id: 'SelectIssueType' })}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'DateRange' })}
        </FilterGroupHeader>
        <Form.Item name="range">
          <DatePicker
            picker="range"
            onChange={(range: [Dayjs, Dayjs]) => {
              getIssues({
                startDate: range?.[0]
                  ? date(range[0]).startOf('day').format(config.apiDateFormat)
                  : null,
                endDate: range?.[1]
                  ? date(range[1]).startOf('day').format(config.apiDateFormat)
                  : null,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        {page === IssuesPage.Pending && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'Late' })}
            </FilterGroupHeader>
            <Form.Item name="dueDateStatus">
              <Select
                options={dueDateStatusOptions}
                onChange={(dueDateStatus) =>
                  getIssues({ dueDateStatus, pageNumber: 1 })
                }
              />
            </Form.Item>
          </>
        )}
        {page === IssuesPage.Archive && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'IssueClosingReason' })}
            </FilterGroupHeader>
            <Form.Item name="reason">
              <IssueCloseReasonSelect
                allowAll
                placeholder={formatMessage({ id: 'SelectIssueClosingReason' })}
                onChange={(reason) => {
                  getIssues({
                    reason: reason === -1 ? null : reason,
                  });
                }}
              />
            </Form.Item>
            <FilterGroupHeader>
              {formatMessage({ id: 'Late' })}
            </FilterGroupHeader>
            <Form.Item name="lateStatus">
              <Select
                options={lateStatusOptions}
                onChange={(lateStatus) =>
                  getIssues({ lateStatus, pageNumber: 1 })
                }
              />
            </Form.Item>
          </>
        )}
        <FilterGroupHeader>
          {formatMessage({ id: 'Priority' })}
        </FilterGroupHeader>
        <Form.Item name="priority">
          <PrioritySelect
            allowAll
            onChange={(priority) =>
              getIssues({
                priority: priority === -1 ? null : priority,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                getIssues(null);
                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default IssuesFiltersModal;
