import styled from 'styled-components';

import { Priority } from '@repo/shared/enums';
import { getActionPriorityColor } from '@domain/Actions/utils/getActionPriorityColor';

export const Container = styled.div`
  display: inline-block;
  white-space: nowrap;

  &:after {
    display: inline-block;
    content: '';
    width: 9px;
    height: 9px;
    margin-left: 6px;
    border-radius: 100%;
    background: ${({ priority }: { priority: Priority }) =>
      getActionPriorityColor(priority)};
  }
`;
