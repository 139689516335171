import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  &:before {
    position: absolute;
    display: block;
    content: '';
    background: ${colors.gray3};
    left: 0;
    top: 0;
    bottom: 0;
    width: 490px;
  }
`;
