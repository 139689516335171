import { forwardRef, SVGAttributes } from 'react';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
}

const AI = forwardRef<SVGSVGElement, IProps>(
  ({ size = 16, title, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M3.85526 1.54805L0.5 5.84935C1.59006 7.24675 3.35731 7.24675 4.44737 5.84935L7.80263 1.54805C6.71258 0.150649 4.94532 0.150649 3.85526 1.54805Z"
          fill="url(#paint0_linear_76_157)"
        />
        <path
          d="M11.5526 9.89763L8.19737 14.1989C9.28742 15.5963 11.0547 15.5963 12.1447 14.1989L15.5 9.89763C14.4099 8.50023 12.6427 8.50023 11.5526 9.89763Z"
          fill="url(#paint1_linear_76_157)"
        />
        <path
          d="M3.26316 14.4519L10.9605 4.58426C12.0506 3.18686 13.8178 3.18686 14.9079 4.58426L7.21053 14.4519C6.12047 15.8494 4.35321 15.8494 3.26316 14.4519Z"
          fill="url(#paint2_linear_76_157)"
        />
        <path
          d="M8.19737 2.05409L0.5 11.9218C1.59006 13.3192 3.35731 13.3192 4.44737 11.9218L12.1447 2.05409C11.0547 0.656684 9.28742 0.656684 8.19737 2.05409Z"
          fill="url(#paint3_linear_76_157)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_76_157"
            x1="9.5"
            y1="4.5"
            x2="-10.5731"
            y2="25.0284"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#31BBE7" />
            <stop offset="1" stopColor="#0B4368" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_76_157"
            x1="9.5"
            y1="4.5"
            x2="-10.5731"
            y2="25.0284"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#31BBE7" />
            <stop offset="1" stopColor="#0B4368" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_76_157"
            x1="9.5"
            y1="4.5"
            x2="-10.5731"
            y2="25.0284"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#31BBE7" />
            <stop offset="1" stopColor="#0B4368" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_76_157"
            x1="9.5"
            y1="4.5"
            x2="-10.5731"
            y2="25.0284"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#31BBE7" />
            <stop offset="1" stopColor="#0B4368" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
);

export default AI;
