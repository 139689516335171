import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';
import { XCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  AuditNameRow,
  Buttons,
  ClearBtn,
  FilterGroupHeader,
  FilterQty,
} from './styled';
import { accountSelectors, actionsActions, actionsSelectors } from '@store';
import { ActionsFilters } from '@application/Actions/models/ActionsFilters';
import {
  date,
  enumToSelectOptions,
  removeUndefinedAndNullProps,
  useTranslatedOptions,
} from '@utils';
import { getQueryStringParams } from '@repo/shared/utils';
import { getInitialActionsFilters } from '@application/Actions/store/actionsReducer';
import { routes } from '@config';
import { config } from '@repo/shared/config';
import { Priority, TagType } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { ActionDueDateStatus } from '@application/Actions/enums/ActionDueDateStatus';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';

import SlideModal from '@components/shared/SideModal/SideModal';
import AuditObjectsAndAuditObjectGroupsSelect from '@components/shared/EntitySelects/AuditObjectsAndAuditObjectGroupsSelect';
import UserOrUserGroupSelect from '@components/shared/EntitySelects/UserOrUserGroupSelect';
import Form from '@components/shared/ant/Form';
import AuditTemplatesSelect from '@components/shared/EntitySelects/AuditTemplatesSelect';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import AssignmentButtonGroup from '@presentation/Audits/AuditsFiltersModal/AssignmentButtonGroup/AssignmentButtonGroup';
import Select from '@components/shared/ant/Select/Select';
import CompletedAuditsSelect from '@components/shared/EntitySelects/CompletedAuditsSelect';
import UsersSelect from '@components/shared/EntitySelects/UsersSelect';
import TagSelect from '@components/shared/EntitySelects/TagSelect';

const dueDateStatusOptions = enumToSelectOptions(ActionDueDateStatus);
const priorityOptions = enumToSelectOptions(Priority);

interface IProps {
  page: ActionsPage;
}

const ActionsFiltersModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const showFiltersModal = useSelector(actionsSelectors.isFiltersModalVisible);
  const { orderBy, orderByDirection, ...filters } = useSelector(
    actionsSelectors.getFilters,
    shallowEqual
  );
  const [oneAuditMode, setOneAuditMode] = useState(false);
  const currentPage = useSelector(actionsSelectors.getPage);
  const selectedFiltersQty = useSelector(
    actionsSelectors.getSelectedFiltersQty
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const { auditId, auditName } = getQueryStringParams(['auditId', 'auditName']);

  useEffect(() => {
    if (showFiltersModal) {
      form.resetFields();
    }
  }, [form, showFiltersModal]);

  useEffect(() => {
    if (auditId && auditName) {
      setOneAuditMode(true);
    }
  }, [auditId, auditName]);

  const getActions = useCallback(
    (update: Partial<ActionsFilters> | null) =>
      dispatch(actionsActions.getActions(update)),
    [dispatch]
  );

  const closeModal = useCallback(() => {
    dispatch(actionsActions.toggleFiltersModal(false));
  }, [dispatch]);

  const translatedDueDateStatusOptions =
    useTranslatedOptions(dueDateStatusOptions);

  return (
    <SlideModal
      opened={showFiltersModal}
      onModalClose={() => {
        closeModal();
      }}
    >
      <Form
        form={form}
        name="actions-filters-form"
        onFinish={({
          order,
          score,
          range,
          assignedTo,
          createdBy,
          approvedBy,
          auditObject,
          ...values
        }: any) => {
          getActions({
            ...removeUndefinedAndNullProps({
              ...values,
              orderBy: order.orderBy,
              orderByDirection: order.orderByDirection,
            }),
            createdBy: createdBy.userId,
            approvedBy: approvedBy.id,
            createdByGroup: createdBy.userGroupId,
            assignedTo: assignedTo.userId,
            assignedToGroup: assignedTo.userGroupId,
            pageNumber: 1,
            priority: values.priority === -1 ? null : values.priority,
            auditObjectIds: auditObject.auditObjectIds,
            auditObjectGroupIds: auditObject.auditObjectGroupIds,
            templateId: values.templateId === -1 ? null : values.templateId,
            auditId: values.auditId === -1 ? null : values.auditId,
            startDate: range?.[0]
              ? date(range[0]).startOf('day').format(config.apiDateFormat)
              : null,
            endDate: range?.[1]
              ? date(range[1]).startOf('day').format(config.apiDateFormat)
              : null,
            completeAuditDate: values.completeAuditDate
              ? date(values.completeAuditDate)
                  .startOf('day')
                  .format(config.apiDateFormat)
              : null,
          });
          closeModal();
        }}
        initialValues={{
          ...filters,
          templateId: filters.templateId === null ? -1 : filters.templateId,
          auditObject: {
            auditObjectIds: filters.auditObjectIds,
            auditObjectGroupIds: filters.auditObjectGroupIds,
          },
          priority: filters.priority === null ? -1 : filters.priority,
          order: { orderBy, orderByDirection },
          createdBy: {
            userId: filters.createdBy,
            userGroupId: filters.createdByGroup,
          },
          assignedTo: {
            userId: filters.assignedTo,
            userGroupId: filters.assignedToGroup,
          },
          auditId: filters.auditId === null ? -1 : filters.auditId,
          completeAuditDate: filters.completeAuditDate
            ? date(filters.completeAuditDate)
            : null,
          range:
            filters.startDate && filters.endDate
              ? [date(filters.startDate), date(filters.endDate)]
              : null,
        }}
      >
        <Form.Item name="assignment">
          <AssignmentButtonGroup
            onChange={(assignment) => {
              getActions({
                assignment,
                pageNumber: 1,
                assignedTo: null,
                assignedToGroup: null,
              });
              form.setFieldsValue({ assignedTo: null, assignedToGroup: null });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'OrAssignedTo' })}
        </FilterGroupHeader>
        <Form.Item name="assignedTo">
          <UserOrUserGroupSelect
            allowClear
            onChange={({ userGroupId, userId }) => {
              getActions({
                assignedTo: userId,
                assignedToGroup: userGroupId,
                assignment: null,
                pageNumber: 1,
              });
              form.setFieldsValue({ assignment: null });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'CreatedBy' })}
        </FilterGroupHeader>
        <Form.Item name="createdBy">
          <UserOrUserGroupSelect
            allowClear
            onChange={({ userGroupId, userId }) =>
              getActions({
                createdBy: userId,
                createdByGroup: userGroupId,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <FilterGroupHeader>{formatMessage({ id: 'Tags' })}</FilterGroupHeader>
        <Form.Item name="tagsIds">
          <TagSelect
            allowClear
            onChange={(tagsIds: string[]) =>
              getActions({
                tagsIds,
              })
            }
            placeholder={formatMessage({ id: 'SelectTags' })}
            tagType={TagType.Action}
          />
        </Form.Item>
        {page === ActionsPage.Completed && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'ApprovedBy' })}
            </FilterGroupHeader>
            <Form.Item name="approvedBy">
              <UsersSelect
                allowClear
                placeholder={formatMessage({ id: 'PleaseSelectUser' })}
                onChange={(userId: string | null) =>
                  getActions({
                    approvedBy: userId,
                    pageNumber: 1,
                  })
                }
              />
            </Form.Item>
          </>
        )}
        <FilterGroupHeader>
          {formatMessage({ id: 'AuditTemplate' })}
        </FilterGroupHeader>
        <Form.Item name="templateId">
          <AuditTemplatesSelect
            placeholder={formatMessage({ id: 'AuditTemplates' })}
            extraOptions={[
              {
                id: -1,
                name: formatMessage({ id: 'All' }),
              },
            ]}
            onChange={(templateId) =>
              getActions({
                templateId: templateId === -1 ? null : templateId,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <FilterGroupHeader>{`${auditObjectName.plural} ${formatMessage({
          id: 'or',
        })} ${formatMessage({ id: 'Groups' })}`}</FilterGroupHeader>
        <Form.Item name="auditObject">
          <AuditObjectsAndAuditObjectGroupsSelect
            allowClear
            onChange={({ auditObjectGroupIds, auditObjectIds }) =>
              getActions({
                auditObjectIds,
                auditObjectGroupIds,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'DateRange' })}
        </FilterGroupHeader>
        <Form.Item name="range">
          <DatePicker
            picker="range"
            onChange={(range: [Dayjs, Dayjs]) => {
              getActions({
                startDate: range?.[0]
                  ? date(range[0]).startOf('day').format(config.apiDateFormat)
                  : null,
                endDate: range?.[1]
                  ? date(range[1]).startOf('day').format(config.apiDateFormat)
                  : null,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>{formatMessage({ id: 'Late' })}</FilterGroupHeader>
        <Form.Item name="dueDateStatus">
          <Select
            options={translatedDueDateStatusOptions}
            onChange={(dueDateStatus) =>
              getActions({ dueDateStatus, pageNumber: 1 })
            }
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'Priority' })}
        </FilterGroupHeader>
        <Form.Item name="priority">
          <Select
            options={[
              {
                value: -1,
                label: formatMessage({ id: 'All' }),
              },
              ...priorityOptions,
            ]}
            onChange={(priority) =>
              getActions({
                priority: priority === -1 ? null : priority,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <FilterGroupHeader>{formatMessage({ id: 'Audit' })}</FilterGroupHeader>
        {oneAuditMode ? (
          <AuditNameRow>
            <span>{auditName}</span>
            <button
              type="button"
              onClick={() => {
                setOneAuditMode(false);
                getActions({ auditId: null });
                form.setFieldsValue({ auditId: -1 });
                navigate(
                  currentPage === ActionsPage.Completed
                    ? routes.completedActions
                    : routes.pendingActions
                );
              }}
            >
              <XCircle />
            </button>
          </AuditNameRow>
        ) : (
          <Form.Item name="auditId">
            <CompletedAuditsSelect
              allowClear
              extraOptions={[
                {
                  id: -1,
                  name: formatMessage({ id: 'All' }),
                },
              ]}
              onChange={(auditId) =>
                getActions({
                  auditId: auditId === -1 ? null : auditId,
                  pageNumber: 1,
                })
              }
            />
          </Form.Item>
        )}
        <FilterGroupHeader>
          {formatMessage({ id: 'AuditCompletionDate' })}
        </FilterGroupHeader>
        <Form.Item name="completeAuditDate">
          <DatePicker
            onChange={(completeAuditDate: Dayjs) =>
              getActions({
                completeAuditDate: completeAuditDate
                  ? date(completeAuditDate)
                      .startOf('day')
                      .format(config.apiDateFormat)
                  : null,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                const { status, search, ...filters } =
                  getInitialActionsFilters();
                getActions(filters);
                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default ActionsFiltersModal;
