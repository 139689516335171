import React from 'react';
import { SelectProps } from 'antd/es/select';

import EntitySelectAsync from './EntitySelectAsync';
import { apiUrls } from '@src/config/apiUrls';

interface IProps extends SelectProps<any> {
  e2eDataAttr?: string;
}

const AuditObjectsGroupsSelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef((props, _) => {
    return (
      <EntitySelectAsync
        {...props}
        apiUrl={`${apiUrls.auditObjectsGroups}/concise`}
      />
    );
  });

export default AuditObjectsGroupsSelect;
