import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import {
  actionsActions,
  actionsSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { notification } from '@utils';
import { Action } from '@domain/Actions/models/Action';
import { AssigneesModalContext } from '@repo/shared/enums';

import BulkUsersAssignmentModal from '@components/shared/BulkUsersAssignmentModal/BulkUsersAssignmentModal';

interface IProps {}

const AssignUsersToActionsModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const modalState = useSelector(
    actionsSelectors.getAssignUsersToActionsModalState
  );
  const actionsMap = useSelector(actionsSelectors.getActionsDictionary);

  const closeModal = useCallback(
    () => dispatch(actionsActions.toggleAssignUsersToActionsModal(null)),
    []
  );

  const actionCount = modalState.opened ? modalState.actionsIds.length : 1;

  return (
    <BulkUsersAssignmentModal<Action>
      opened={modalState.opened}
      title={formatMessage(
        {
          id: 'UsersToActionsAssignment',
        },
        { count: actionCount }
      )}
      auditObjectId={modalState.opened ? modalState.auditObjectId : undefined}
      description={formatMessage(
        {
          id: 'PleaseSelectUsersFromDropdownToAssignTheFollowingActions',
        },
        { count: actionCount }
      )}
      assigneesContext={AssigneesModalContext.Action}
      entitiesIds={modalState.opened ? modalState.actionsIds : []}
      entitiesMap={actionsMap}
      formatEntityName={(action) =>
        action.number ? `${action.number} - ${action.name}` : action.name
      }
      onAssignClick={async (usersIds) => {
        if (!modalState.opened) {
          return;
        }

        const resultAction = await dispatch(
          actionsActions.assignUsersToActions({
            usersIds,
            actionsIds: modalState.actionsIds,
          })
        );

        if (actionsActions.assignUsersToActions.fulfilled.match(resultAction)) {
          dispatch(actionsActions.getActions());

          notification.success({
            message: formatMessage({ id: 'ActionsHaveBeenAssigned' }),
          });

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileAssigningActions' }),
          });
        }
      }}
      onCancel={() => {
        closeModal();
      }}
    />
  );
};

export default AssignUsersToActionsModal;
