export enum BillingPage {
  Subscription,
  Invoices,
  Settings,
  PaymentMethods,
}

export enum PaymentStep {
  FillUp,
  Processing,
  Result,
}

export enum BillingPeriod {
  Monthly,
  Annually,
}

export enum SubscriptionType {
  Trial,
  Custom,
  Automated,
}

export enum SubscriptionStatus {
  Active,
  Processing,
  Blocked,
}

export enum InvoiceStatus {
  Draft,
  Pending,
  Open,
  Paid,
  Voided,
  Canceled,
}
