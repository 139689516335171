import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

export const Container = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1150;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray5};
  height: 64px;
`;

export const Controls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.gray5};
  height: 64px;
`;
