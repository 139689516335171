import styled from 'styled-components';
import { animated } from 'react-spring';
import { ISpotlightBounds } from '@repo/shared/types';

import { colors } from '@repo/shared/config';

export const Overlay = styled(animated.div)`
  cursor: pointer;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  overflow: hidden;
  position: absolute;
  z-index: 2000;

  ${({ bounds }: { bounds: ISpotlightBounds | null }) => {
    return `
      &:after {
        display: block;
        position: absolute;
        content: '';
        top: ${bounds?.top || 0}px;
        left: ${bounds?.left || 0}px;
        width: ${`${bounds?.width}px` || '100%'};
        height: ${`${bounds?.height}px` || '100%'};
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.45);
        border-radius: 5px;
        border: 4px solid ${bounds !== null ? colors.blue2 : 'transparent'};
      }
    `;
  }}
`;
