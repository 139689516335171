import styled from 'styled-components';
import Space from 'antd/es/space';

import { colors } from '@repo/shared/config';

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const SubHeader = styled.h3`
  padding: 16px 0 13px;
`;

export const LabeledInput = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -4px 6px;
  font-size: 14px;

  & > * {
    margin: 0 4px;
  }

  .ant-form-item {
    margin-bottom: 0;
    flex-grow: 1;
  }

  input {
    max-width: 200px !important;
  }
`;

export const Label = styled.span`
  white-space: nowrap;
`;

export const ScoreOption = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    &:first-child {
      margin-right: 3px;
    }
  }
`;

export const PercentInput = styled.div`
  position: relative;

  &:after {
    position: absolute;
    display: block;
    content: '%';
    color: ${colors.gray7};
    right: 7px;
    top: 4px;
    font-size: 15px;
  }

  input {
    padding-right: 23px !important;
  }
`;

export const ActionBtn = styled.button`
  margin-left: 5px;
  background: none;
  border: 0;
  margin-bottom: 6px;
  padding: 0;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.gray7};
    stroke-width: 3px;
    transition: color 0.2s ease-in-out;
  }

  &:disabled {
    svg {
      color: transparent;
    }
  }
`;
