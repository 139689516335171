import { getInitialFilters } from '@utils';

export function getInitialSchedulesFilters() {
  return {
    ...getInitialFilters(),
    orderBy: 'auditDueDate',
    auditObjectGroupIds: [],
    auditObjectIds: [],
    schedulePlanId: null,
    auditRepeatPattern: null,
  };
}
