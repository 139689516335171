import React from 'react';

import { Container } from './styled';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { Priority } from '@repo/shared/enums';

interface IProps {
  priority: Priority;
}

export const getTranslatedPriorityLabel = (
  priority?: Priority | null | string
) => {
  if (priority === Priority.Low) {
    return intl.formatMessage({ id: 'Low' });
  }

  if (priority === Priority.Medium) {
    return intl.formatMessage({ id: 'Medium' });
  }

  if (priority === Priority.High) {
    return intl.formatMessage({ id: 'High' });
  }

  return '';
};

const PriorityLabel: React.FC<React.PropsWithChildren<IProps>> = ({
  priority,
}) => {
  return (
    <Container priority={priority}>
      {getTranslatedPriorityLabel(priority)}
    </Container>
  );
};

export default PriorityLabel;
