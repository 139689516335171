import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';
import { useSelector } from 'react-redux';

import { Container } from './styled';
import { notification, validations } from '@utils';
import { useAppDispatch } from '@hooks';
import { accountActions, accountSelectors, generalActions } from '@store';
import { IFile } from '@repo/shared/types';

import Form from '@components/shared/ant/Form';
import { Input } from '@components/shared/ant/Input';
import UploadFilesFormItem from '@components/shared/DigitizeFormsModal/FormColumn/UploadFilesFormItem';
import Button from '@components/shared/ant/Button';

interface IProps {}

const FormColumn: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const visible = useSelector(accountSelectors.isFormsDigitizationModalVisible);
  const currentUser = useSelector(accountSelectors.getCurrentUser);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      dispatch(generalActions.resetFileUploads());
    }
  }, [visible]);

  return (
    <Container>
      <Spin spinning={loading} style={{ maxHeight: '600px' }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            files: [],
            email: currentUser?.email || '',
            fullName: currentUser?.name || '',
            notes: '',
          }}
          onFinish={async (values: any) => {
            setLoading(true);

            const resultAction = await dispatch(
              accountActions.sendDigitizeFormsRequest({
                filesIds: values.files.map((file: IFile) => file.id),
                email: values.email,
                fullName: values.fullName,
                notes: values.notes,
              })
            );

            if (
              accountActions.sendDigitizeFormsRequest.fulfilled.match(
                resultAction
              )
            ) {
              notification.success({
                message: formatMessage({
                  id: 'RequestHasBeenSuccessfullySent',
                }),
              });

              dispatch(accountActions.toggleFormsDigitizationModal(false));
            } else {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileSendingRequest' }),
              });
            }

            setLoading(false);
          }}
        >
          <Form.Item
            name="files"
            rules={[
              () => ({
                validator(_, value) {
                  if (value.length === 0) {
                    return Promise.reject(
                      formatMessage({ id: 'PleaseUploadFilesToProceed' })
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            noStyle
            shouldUpdate
          >
            <UploadFilesFormItem form={form} />
          </Form.Item>
          <Form.Item
            name="email"
            label={formatMessage({ id: 'Email' })}
            rules={[
              {
                pattern: validations.email,
                message: formatMessage({
                  id: 'ValidEmailAddressIsRequired',
                }),
              },
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label={formatMessage({ id: 'FirstAndLastName' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="notes" label={formatMessage({ id: 'Notes' })}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%', height: '36px', marginTop: '5px' }}
          >
            {formatMessage({ id: 'Send' })}
          </Button>
        </Form>
      </Spin>
    </Container>
  );
};

export default FormColumn;
