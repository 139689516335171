import React, { useRef } from 'react';
import Carousel from 'antd/es/carousel';
import { ArrowLeft, ArrowRight, Crop, Edit2 } from 'react-feather';
import { useIntl } from 'react-intl';

import { FileMetadata } from '@repo/shared/types';
import {
  Container,
  FooterButton,
  ImageHolder,
  VideoHolder,
  Image,
  NextButton,
  PrevButton,
} from './styled';
import { Footer, FooterButtons } from '../styled';
import { ImageGalleryMode } from '@repo/shared/enums';
import { IApiService } from '@repo/shared/types';

import ImageContainer from '../../ImageContainer';
import VideoContainer from './VideoContainer/VideoContainer';
import VideoPlaybackDisabledNotice from './VideoPlaybackDisabledNotice/VideoPlaybackDisabledNotice';

interface IProps {
  files: FileMetadata[];
  getFilePath?: (file: FileMetadata) => string;
  getMetadataPath?: (fileId: string) => string;
  apiService: IApiService;
  readonly?: boolean;
  currentSlideIndex: number;
  onSlideChange: (newIndex: number) => void;
  onModeChange: (newMode: ImageGalleryMode) => void;
  skipCompanyId?: boolean;
  disableVideoPlayback?: boolean;
}

const FileSlider: React.FC<React.PropsWithChildren<IProps>> = ({
  files,
  getFilePath,
  getMetadataPath,
  apiService,
  readonly,
  currentSlideIndex,
  onSlideChange,
  onModeChange,
  skipCompanyId,
  disableVideoPlayback,
}) => {
  const { formatMessage } = useIntl();

  const carouselRef = useRef<any>(null);

  if (currentSlideIndex < 0) {
    return null;
  }

  const currentFile = files[currentSlideIndex];

  return (
    <>
      <Container>
        <Carousel
          initialSlide={currentSlideIndex}
          dots={false}
          ref={carouselRef}
          infinite={false}
          afterChange={(index) => {
            onSlideChange(index);
          }}
          adaptiveHeight
        >
          {files.map((file) => {
            const isVideo = file.contentType?.includes('video');

            let getFilePathCallback = undefined;

            if (getFilePath) {
              getFilePathCallback = () => getFilePath(file);
            } else if (disableVideoPlayback && isVideo) {
              getFilePathCallback = () => `files/${file.id}/video-preview`;
            }

            if (!disableVideoPlayback && isVideo) {
              return (
                <div key={file.id}>
                  {file.id === currentFile.id && (
                    <VideoContainer
                      fileId={file.id}
                      getFilePath={getFilePathCallback}
                      apiService={apiService}
                      skipCompanyId={skipCompanyId}
                    />
                  )}
                </div>
              );
            }

            return (
              <ImageContainer
                key={file.id}
                fileId={file.id}
                apiService={apiService}
                skipCompanyId={skipCompanyId}
                getFilePath={getFilePathCallback}
                getMetadataPath={getMetadataPath}
              >
                {({ imageUrl, onLoadImage, onLoadImageError }) => (
                  <ImageHolder>
                    <Image
                      src={imageUrl}
                      onLoad={onLoadImage}
                      onError={onLoadImageError}
                    />
                    {disableVideoPlayback && isVideo && (
                      <VideoPlaybackDisabledNotice />
                    )}
                  </ImageHolder>
                )}
              </ImageContainer>
            );
          })}
        </Carousel>
        {files.length > 1 && (
          <>
            <PrevButton
              type="button"
              disabled={currentSlideIndex === 0}
              onClick={() => {
                if (carouselRef?.current && currentSlideIndex - 1 >= 0) {
                  const newIndex = currentSlideIndex - 1;
                  carouselRef.current.goTo(currentSlideIndex - 1);
                  onSlideChange(newIndex);
                }
              }}
            >
              <ArrowLeft />
            </PrevButton>
            <NextButton
              type="button"
              disabled={currentSlideIndex === files.length - 1}
              onClick={() => {
                if (
                  carouselRef?.current &&
                  currentSlideIndex + 1 < files.length
                ) {
                  const newIndex = currentSlideIndex + 1;
                  carouselRef.current.goTo(currentSlideIndex + 1);
                  onSlideChange(newIndex);
                }
              }}
            >
              <ArrowRight />
            </NextButton>
          </>
        )}
      </Container>
      <Footer>
        <div>
          {formatMessage({ id: 'Photo' })} {currentSlideIndex + 1}{' '}
          {formatMessage({ id: 'of' })} {files.length}
        </div>
        {!readonly &&
        !!currentFile &&
        (currentFile.contentType === null ||
          currentFile.contentType.includes('image')) ? (
          <FooterButtons>
            <FooterButton
              type="button"
              onClick={() => {
                onModeChange(ImageGalleryMode.Crop);
              }}
            >
              <Crop />
              {formatMessage({ id: 'Crop' })}
            </FooterButton>
            <FooterButton
              type="button"
              onClick={() => {
                onModeChange(ImageGalleryMode.Draw);
              }}
            >
              <Edit2 />
              {formatMessage({ id: 'Draw' })}
            </FooterButton>
          </FooterButtons>
        ) : (
          <div />
        )}
      </Footer>
    </>
  );
};

export default FileSlider;
