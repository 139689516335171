import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Space from 'antd/es/space';

import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useAppDispatch, usePermission } from '@hooks';
import { e2eTestElements, routes } from '@config';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { Permission } from '@repo/shared/enums';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import PageHeader from '@components/shared/ant/PageHeader';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';
import Button from '@components/shared/ant/Button';
import ExportButton from '@src/presentation/Issues/IssuesHeader/ExportButton';

interface IProps {
  page: IssuesPage;
}

const IssuesHeader: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedFiltersQty = useSelector(issuesSelectors.getSelectedFiltersQty);
  const filters = useSelector(issuesSelectors.getFilters);

  const canEditIssues = usePermission(Permission.CanEditIssues);

  return (
    <PageHeader
      title={formatMessage({ id: 'Issues' })}
      extra={
        <Space>
          {page !== IssuesPage.My && (
            <>
              <SearchInput
                onEnter={(search: string) =>
                  dispatch(issuesActions.getIssues({ search, pageNumber: 1 }))
                }
                value={filters.search}
              />
              <FiltersButton
                showModal={() => {
                  dispatch(issuesActions.toggleFiltersModal(true));
                }}
                selectedFilters={selectedFiltersQty}
                resetFilters={() => {
                  dispatch(issuesActions.getIssues(null));
                }}
              />
            </>
          )}
          {(page === IssuesPage.Pending || page === IssuesPage.My) &&
            canEditIssues && (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(issuesActions.toggleCreateIssueModal(true));
                }}
                data-e2e={e2eTestElements.issues.issues.createIssue}
              >
                {formatMessage({
                  id: 'CreateIssue',
                })}
              </Button>
            )}
          <ExportButton />
        </Space>
      }
      footer={
        <PageTabbedSwitcher<IssuesPage>
          activePage={page}
          pages={[
            {
              id: IssuesPage.My,
              name: formatMessage({ id: 'MyIssues' }),
              onClick: () => {
                navigate(routes.myIssues);
              },
            },
            {
              id: IssuesPage.Pending,
              name: formatMessage({ id: 'Pending' }),
              onClick: () => {
                navigate(routes.pendingIssues);
              },
            },
            {
              id: IssuesPage.Archive,
              name: formatMessage({ id: 'Archive' }),
              onClick: () => {
                navigate(routes.archiveIssues);
              },
            },
          ]}
        />
      }
    />
  );
};

export default IssuesHeader;
