import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntButton from 'antd/es/button';

import { colors } from '@repo/shared/config';

const { Text: AntText } = Typography;

export const Container = styled.div`
  text-align: center;
  padding: 40px;
`;

export const CreateButton = styled(AntButton)`
  display: block;
  margin: 0 auto;
  color: ${colors.blue2};
`;

export const Footer = styled.div`
  width: 240px;
  border-top: 1px solid ${colors.gray3};
  padding-top: 15px;
  text-align: center;
  margin: 25px auto 0;
`;

export const FooterButton = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 8px 14px;
  border-radius: 5px;
  font-size: 12px;
  color: ${colors.gray8};

  svg {
    width: 13px;
    height: 13px;
    color: ${colors.gray8};
    margin-left: 4px;
  }
`;

export const HowItWorksButton = styled(FooterButton)``;

export const DemoButton = styled(FooterButton)`
  background: ${colors.gray3};
  margin-top: 15px;
`;
