import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% {
        background-position: 100% 50%
    }

    to {
        background-position: 0 50%
    }
`;

export const InlineSkeleton = styled.div`
  width: 100%;
  height: 15px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: ${loadingAnimation}
    ${({ animationDuration }: { animationDuration?: number }) =>
      animationDuration || 1.4}s
    linear infinite;
`;
