import React from 'react';
import AntSelect from 'antd/es/select';
import { useIntl } from 'react-intl';

import { Priority } from '@repo/shared/enums';

import PriorityLabel, {
  getTranslatedPriorityLabel,
} from '@components/shared/PriorityLabel/PriorityLabel';
import Select from '@components/shared/ant/Select/Select';

const { Option } = AntSelect;

interface IProps {
  value?: number;
  onChange?: (newPriority: number) => void;
  e2eDataAttr?: string;
  disabled?: boolean;
  allowAll?: boolean;
}

const PrioritySelect: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  onChange,
  e2eDataAttr,
  disabled,
  allowAll,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Select
      value={value}
      onChange={onChange}
      disabled={disabled}
      e2eDataAttr={e2eDataAttr}
      showSearch
      filterOption={(search, option) => {
        return getTranslatedPriorityLabel(option?.value)
          .toLowerCase()
          .includes(search.toLowerCase());
      }}
    >
      {allowAll && <Option value={-1}>{formatMessage({ id: 'All' })}</Option>}
      <Option value={Priority.Low}>
        <PriorityLabel priority={Priority.Low} />
      </Option>
      <Option value={Priority.Medium}>
        <PriorityLabel priority={Priority.Medium} />
      </Option>
      <Option value={Priority.High}>
        <PriorityLabel priority={Priority.High} />
      </Option>
    </Select>
  );
};

export default PrioritySelect;
