import { GetPendingAuditDto } from '@infrastructure/Audits/models/GetPendingAuditDto';
import { Audit } from '@domain/Audits/models/Audit';

export function transformPendingAuditDtoToAudit(
  dto: GetPendingAuditDto
): Audit {
  return {
    ...dto,
    startedBy: null,
    pendingActionsCount: null,
    completedActionsCount: null,
    expiredActionsCount: null,
    completedBy: null,
    completedAtInformation: null,
    score: null,
    scoreLabel: null,
    scoreColor: null,
    scoreChange: null,
    expiredBy: null,
    expiredAtInformation: null,
  };
}
