import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { generalSelectors, auditObjectsActions } from '@store';
import { ProfileSection } from '@components/account/EditProfile/styled';
import { useAppDispatch } from '@hooks';

import AuditObjectGroupContentWrapper, {
  AuditObjectGroupPage,
} from '@components/auditObjects/groups/single/AuditObjectGroupContentWrapper/AuditObjectGroupContentWrapper';
import HeaderActionMenu from './HeaderActionMenu/HeaderActionMenu';
import AddEditAuditObjectGroupModal from '@components/auditObjects/groups/list/AddEditAuditObjectGroupModal/AddEditAuditObjectGroupModal';
import DeleteAuditObjectGroupsModal from '@components/auditObjects/groups/list/DeleteAuditObjectGroupsModal';

const AuditObjectGroupDetails: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const auditObjectGroupsMap = useSelector(
    generalSelectors.getConciseAuditObjectGroupsMap
  );
  const { loading } = useSelector(generalSelectors.getConciseAuditObjectGroups);

  useEffect(() => {
    return () => {
      dispatch(
        auditObjectsActions.groups.toggleAddEditModal({ visible: false })
      );
    };
  }, []);

  return (
    <AuditObjectGroupContentWrapper
      page={AuditObjectGroupPage.Details}
      extra={<HeaderActionMenu auditObjectGroupId={id} />}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {id && auditObjectGroupsMap[id] && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Name' })}</h3>
              <span>{auditObjectGroupsMap[id]?.name}</span>
            </ProfileSection>
          )}
        </>
      )}
      <AddEditAuditObjectGroupModal />
      <DeleteAuditObjectGroupsModal />
    </AuditObjectGroupContentWrapper>
  );
};

export default AuditObjectGroupDetails;
