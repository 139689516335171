import { routes } from '@config';
import { AuditStatus } from '@repo/shared/enums';

export function getRoutesFromAuditStatus(status: AuditStatus) {
  switch (status) {
    case AuditStatus.Expired:
      return {
        list: routes.expiredAudits,
        single: routes.expiredAudit,
      };
    case AuditStatus.Completed:
      return {
        list: routes.completedAudits,
        single: routes.completedAudit,
      };
    case AuditStatus.Pending:
    case AuditStatus.InProgress:
    case AuditStatus.Reopened:
      return {
        list: routes.pendingAudits,
        single: routes.pendingAudit,
      };
  }
}
