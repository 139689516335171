import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Detail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const Label = styled.div`
  flex-grow: 1;
  color: ${colors.gray8};
  text-align: left;
`;

export const Value = styled.div`
  color: ${colors.gray10};

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      margin-left: 2px;
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: ${colors.gray5};
  margin: 16px 0;
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
`;
