export enum Locale {
  en = 'EN',
  es = 'ES',
  fr = 'FR',
  pt = 'PT',
}

export enum AuthEvent {
  SignIn,
  SignOut,
}

export enum CompanySize {
  '1-5',
  '6-15',
  '16-25',
  '26-50',
  '51-100',
  '101-250',
  '250+',
}

export enum UsagePurpose {
  Locations,
  Stores,
  Sites,
  Machines,
  Plants,
  Vehicles,
  SomethingElse,
  Processes,
}

export enum Industry {
  Agriculture,
  Construction,
  Education,
  Healthcare,
  Hotel,
  Manufacturing,
  Mining,
  OilAndGas,
  PropertyManagement,
  Restaurant,
  Retail,
  SeniorCare,
  Transportation,
  Travel,
  IndustryNotListed,
}

export enum AuthProvider {
  Email = 'email',
  Google = 'google',
}

export enum AnalyticsEvent {
  SignIn = 'login',
  SignUp = 'signUp',
  SetupCompany = 'setupCompanyCompleted',
  StartSampleAudit = 'startSampleAudit',
  WelcomePopupClosed = 'welcomePopupClosed',
  LiveConsultationClicked = 'liveConsultationClicked',
  SelfGuidedTourClicked = 'selfGuidedTourClicked',
  LearnTheBasicsVideoClicked = 'learnTheBasicsVideoClicked',
  FinishAuditClicked = 'finishAuditClicked',
  AddUsers = 'addUsersStepComplete',
  AddTemplate = 'createTemplateStepComplete',
  AddAuditObject = 'addAuditObjectStepComplete',
  AddSchedule = 'scheduleAuditStepComplete',
}

export enum SelfGuidedTourStep {
  StartTour = 0,
  SummaryReportWidgets = 1,
  Settings = 2,
  AddUser = 3,
  AddUserFormNameEmail = 4,
  AddUserFormRoles = 5,
  AddObject = 6,
  AddObjectForm = 7,
  CreateTemplate = 8,
  BuilderTemplateSetup = 9,
  BuilderTemplatePreview = 10,
  AddSchedule = 11,
  AddScheduleForm = 12,
  ViewAudits = 13,
  ViewAuditsReports = 14,
  AuditReport = 15,
  CustomizeAuditReport = 16,
  ExportAuditReportPDF = 17,
  SummaryDashboard = 18,
  TrackActionsWidget = 19,
  ActionsList = 20,
  ActionDetails = 21,
  AuditPerformanceWidget = 22,
  CustomReport = 23,
  EndTour = 24,
}

export enum SignUpStep {
  CreateAccount,
  SetupCompany,
}

export enum FeedbackEvent {
  AskMeLater,
  Submit,
  Capterra,
  Reject,
}

export enum FeedbackModalPage {
  LeaveFeedback,
  ThankYou,
  Capterra,
}

export enum LiveChatVisibility {
  maximized = 'maximized',
  minimized = 'minimized',
  hidden = 'hidden',
}
