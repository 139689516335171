import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  accountSelectors,
  generalActions,
  usersActions,
  usersSelectors,
} from '@store';
import { Modal, Permission, SubscriptionType } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { getInitialUsersFilters } from '@store/modules/users/users.reducer';

import PageHeader from '../../../shared/ant/PageHeader';
import SearchInput from '../../../shared/SeachInput/SearchInput';
import UsersLimits from './UsersLimits/UsersLimits';
import Button from '@components/shared/ant/Button';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';

const UsersHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const company = useSelector(accountSelectors.getCompany);
  const canManageUsers = usePermission(Permission.CanManageUsers);
  const selectedFiltersQty = useSelector(
    usersSelectors.list.getSelectedFiltersQty
  );
  const filters = useSelector(usersSelectors.list.getFilters);

  return (
    <>
      <PageHeader
        title={formatMessage({ id: 'Users' })}
        extra={
          <Space>
            <SearchInput
              value={filters.search}
              onEnter={(search) =>
                dispatch(usersActions.list.getData({ search }))
              }
            />
            <FiltersButton
              showModal={() =>
                dispatch(usersActions.list.toggleFiltersModal(true))
              }
              resetFilters={() => {
                dispatch(usersActions.list.getData(getInitialUsersFilters()));
              }}
              selectedFilters={selectedFiltersQty}
            />
            {canManageUsers && (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(generalActions.showModal({ name: Modal.AddEdit }));
                }}
                data-e2e={e2eTestElements.users.createUser}
              >
                {formatMessage({
                  id: 'NewUser',
                })}
              </Button>
            )}
          </Space>
        }
      />
      {company?.subscriptionType === SubscriptionType.Custom && <UsersLimits />}
    </>
  );
};

export default UsersHeader;
