import { BaseApiClient } from '@repo/shared/api';
import { IConcise, IPagedResponse, ITableFilters } from '@repo/shared/types';
import {
  SchedulePlan,
  SchedulePlanDetails,
} from '@domain/SchedulePlans/models/SchedulePlan';
import { CreateSchedulePlanDto } from '@infrastructure/SchedulePlans/models/CreateSchedulePlanDto';
import { UpdateSchedulePlanDto } from '@infrastructure/SchedulePlans/models/UpdateSchedulePlanDto';
import { ChangeStatusDto } from '@application/SchedulePlans/models/ChangeStatusDto';
import { Schedule } from '@domain/Schedules/models/Schedule';

class SchedulePlansApiClient extends BaseApiClient {
  public async getSchedulePlans(
    filters: ITableFilters
  ): Promise<IPagedResponse<SchedulePlan>> {
    return this._apiService.post({
      url: 'audit/schedule-plans/list',
      body: {
        ...filters,
      },
    });
  }

  public async getConciseSchedulePlans(): Promise<IConcise[]> {
    return this._apiService.get({
      url: 'audit/schedule-plans/concise',
    });
  }

  public async getSchedulePlanDetails(
    id: string
  ): Promise<SchedulePlanDetails> {
    return this._apiService.get({
      url: `audit/schedule-plans/${id}`,
    });
  }

  public async getSchedulePlanSchedule(
    schedulePlanId: string,
    scheduleId: string
  ): Promise<Schedule> {
    return this._apiService.get({
      url: `audit/schedule-plans/${schedulePlanId}/schedules/${scheduleId}`,
    });
  }

  public async createSchedulePlan({
    assignedUserGroups,
    assignedUsers,
    ...dto
  }: CreateSchedulePlanDto): Promise<string> {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `audit/schedule-plans`,
      body: {
        assignedUsersIds: assignedUsers.map(({ id }) => id),
        assignedUserGroupsIds: assignedUserGroups.map(({ id }) => id),
        ...dto,
      },
    });

    return id;
  }

  public async updateSchedulePlan({
    assignedUserGroups,
    assignedUsers,
    ...dto
  }: UpdateSchedulePlanDto): Promise<void> {
    await this._apiService.put({
      url: `audit/schedule-plans/${dto.id}`,
      body: {
        assignedUsersIds: assignedUsers.map(({ id }) => id),
        assignedUserGroupsIds: assignedUserGroups.map(({ id }) => id),
        ...dto,
      },
    });
  }

  public async deleteSchedulePlans(ids: string[]): Promise<void> {
    await this._apiService.delete({
      url: `audit/schedule-plans`,
      body: {
        ids,
      },
    });
  }

  public async changeSchedulePlanStatus({
    id,
    ...body
  }: ChangeStatusDto): Promise<void> {
    await this._apiService.post({
      url: `audit/schedule-plans/${id}/change-status`,
      body,
    });
  }
}

export default SchedulePlansApiClient;
