import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  padding: 12px 16px 7px;
  background: ${colors.gray3};
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const Title = styled.h4`
  font-size: 14px;
  line-height: 22px;
  margin: 0;
`;

export const DropDownTrigger = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  padding: 0;
  font-size: 12px;
  color: ${colors.gray8};

  svg {
    width: 12px;
    height: 12px;
    margin-left: 3px;
  }
`;
