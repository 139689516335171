import React, { useImperativeHandle } from 'react';

import Form from '@components/shared/ant/Form';

type RecursivePartial<T> =
  NonNullable<T> extends object
    ? {
        [P in keyof T]?: NonNullable<T[P]> extends (infer U)[]
          ? RecursivePartial<U>[]
          : NonNullable<T[P]> extends object
            ? RecursivePartial<T[P]>
            : T[P];
      }
    : T;

export interface IExposedFormRef<Values = any> {
  submitForm: () => Promise<Values | null>;
  setFieldsValue: (values: RecursivePartial<Values>) => void;
  resetFields: () => void;
}

export function useExposedForm<Values = any>(
  ref: React.ForwardedRef<IExposedFormRef<Values>>
) {
  const [form] = Form.useForm<Values>();

  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      try {
        return await form.validateFields();
      } catch (e) {
        return null;
      }
    },
    resetFields: () => {
      form.resetFields();
    },
    setFieldsValue: (values) => {
      form.setFieldsValue(values);
    },
  }));

  return form;
}
