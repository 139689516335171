import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';

import { Container } from './styled';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';
import { e2eTestElements } from '@config';

import Select from '@components/shared/ant/Select/Select';

const { Option } = AntSelect;

interface IProps {
  value?: number;
  onChange?: (value: number) => void;
  repeatPattern: AuditRepeatPattern;
  disabled?: boolean;
}

function useSuffix(repeatPattern: AuditRepeatPattern, value?: number) {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    switch (repeatPattern) {
      case AuditRepeatPattern.Daily:
        return formatMessage({ id: 'DaysPlural' }, { count: value || 1 });
      case AuditRepeatPattern.MultipleWeeks:
        return formatMessage({ id: 'WeeksPlural' }, { count: value || 1 });
      case AuditRepeatPattern.Monthly:
        return formatMessage({ id: 'MonthsPlural' }, { count: value || 1 });
      case AuditRepeatPattern.OneTime:
      case AuditRepeatPattern.Weekly:
      default:
        return '';
    }
  }, [value]);
}

const RepeatEvery: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  onChange,
  disabled,
  repeatPattern,
}) => {
  const { formatMessage } = useIntl();

  const suffix = useSuffix(repeatPattern, value);

  return (
    <Container>
      <span>{formatMessage({ id: 'RepeatEvery' })}</span>
      <Select
        disabled={disabled}
        value={value}
        onChange={onChange}
        width="70px"
        e2eDataAttr={
          e2eTestElements.schedules.addEditSchedule.repeatEverySelect
        }
      >
        {Array(10)
          .fill('')
          .map((_, num) => (
            <Option key={num} value={num + 1}>
              {num + 1}
            </Option>
          ))}
      </Select>
      <span>{suffix}</span>
    </Container>
  );
};

export default RepeatEvery;
