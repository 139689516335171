import React from 'react';
import { Calendar } from 'react-feather';
import styled from 'styled-components';
import AntDatePicker from 'antd/es/date-picker';

import 'antd/es/date-picker/style/index';
import { colors } from '@repo/shared/config';

const { RangePicker, TimePicker } = AntDatePicker;

const DatePicker = styled(
  React.forwardRef<any, any>(
    ({ picker, pickerPeriod, e2eDataAttr, ...props }, _) => {
      const pickerProps = {
        ...props,
        popupStyle: props.popupStyle || { zIndex: 3000 },
        suffixIcon: <Calendar />,
      };

      if (picker === 'range') {
        return (
          <RangePicker
            picker={pickerPeriod}
            data-e2e={e2eDataAttr}
            {...pickerProps}
          />
        );
      }

      if (picker === 'time') {
        return <TimePicker data-e2e={e2eDataAttr} />;
      }

      return <AntDatePicker data-e2e={e2eDataAttr} {...pickerProps} />;
    }
  )
)`
  width: 100%;
  border-radius: 5px;
  background: ${colors.gray3};
  border-color: ${colors.gray3};
  padding: 4px 12px;

  &.ant-picker.ant-picker-disabled {
    background: ${colors.gray3};
    border-color: ${colors.gray3};

    &:hover {
      background: ${colors.gray3};
      border-color: ${colors.gray3};
      box-shadow: 0 0 4px ${colors.gray3};
    }
  }

  input {
    &::placeholder {
      color: ${colors.gray8};
    }
  }

  &:hover {
    background: ${colors.white};
    border-color: ${colors.white};
    box-shadow: 0 0 4px ${colors.blue2};
  }

  &:focus {
    border-color: ${colors.blue2};
    box-shadow: 0 0 4px ${colors.blue2};
  }

  .ant-picker-suffix {
    line-height: 1;

    svg {
      width: 16px;
      height: 16px;
      color: ${colors.gray8};
    }
  }

  .ant-picker-range-separator {
    .ant-picker-separator {
      display: none;
    }
  }

  .ant-picker-clear {
    width: 16px;
  }
`;

export default DatePicker;
