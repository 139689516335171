import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Sign = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M14.75 5.5V3.25M14.75 3.25V1M14.75 3.25L12.5 3.25M14.75 3.25H17M1.25 12.0773C2.30676 11.8816 3.37731 11.3367 4.35156 10.6044M4.35156 10.6044C7.52597 8.21829 9.67807 3.84296 7 3.07729C4.40888 2.33648 3.18795 7.07772 4.35156 10.6044ZM4.35156 10.6044C4.75972 11.8414 5.46127 12.929 6.5 13.5781C7.39344 14.1365 8.23698 14.3168 8.99164 14.2433M8.99164 14.2433C11.6157 13.988 13.165 10.665 12 9.5C10.5028 8.0028 7.51121 10.6536 8.99164 14.2433ZM8.99164 14.2433C8.99441 14.25 8.9972 14.2568 9 14.2635C10.5 17.8635 13.9167 16.0781 14.75 14.0781"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default Sign;
