import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { AuditStatus } from '@domain/Audits/enums/AuditStatus';

export function getAuditStatusFromAuditsPage(page: AuditsPage): AuditStatus {
  switch (page) {
    case AuditsPage.Pending:
    case AuditsPage.My:
      return AuditStatus.Pending;
    case AuditsPage.Completed:
      return AuditStatus.Completed;
    case AuditsPage.Expired:
      return AuditStatus.Expired;
  }
}
