import React from 'react';
import AntModal from 'antd/es/modal';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { useAppDispatch } from '@hooks';
import { accountActions, accountSelectors } from '@store';
import {
  Content,
  Title,
  SubTitle,
  StoreLinks,
  StoreLink,
  StoreLabel,
  QRCode,
} from './styled';
import appStoreLabelImg from '../AppStoresLinks/app-store.png';
import googleStoreLabelImg from '../AppStoresLinks/google-play.png';
import appStoreQrCode from './app-store-qr-code.png';
import googleStoreQrCode from './google-store-qr-code.png';

const DownloadAppsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const visible = useSelector(accountSelectors.isDownloadAppsModalVisible);

  return (
    <AntModal
      open={visible}
      width={586}
      footer={null}
      closeIcon={<X />}
      onCancel={() => {
        dispatch(accountActions.toggleDownloadAppsModal(false));
      }}
      styles={{
        body: {
          marginBottom: 0,
        },
      }}
    >
      <Content>
        <Title>{formatMessage({ id: 'MobileApp' })}</Title>
        <SubTitle>
          {formatMessage({ id: 'InstallMobileAppFromAppStoreOrGooglePlay' })}
        </SubTitle>
        <StoreLinks>
          <StoreLink>
            <StoreLabel src={appStoreLabelImg} />
            <QRCode src={appStoreQrCode} />
          </StoreLink>
          <StoreLink>
            <StoreLabel src={googleStoreLabelImg} />
            <QRCode src={googleStoreQrCode} />
          </StoreLink>
        </StoreLinks>
      </Content>
    </AntModal>
  );
};

export default DownloadAppsModal;
