import React from 'react';

import { date } from '@utils';
import { ZonedDateTime as IZonedDateTime } from '@repo/shared/types';
import { config } from '@repo/shared/config';

interface IProps {
  dateTime: IZonedDateTime;
  format?: string;
}

const ZonedDateTime: React.FC<React.PropsWithChildren<IProps>> = ({
  dateTime,
  format,
}) => {
  return (
    <>
      {date(dateTime.localTime).format(format || config.dateFormat)}
      {dateTime.timeZoneAbbreviation
        ? ` (${dateTime.timeZoneAbbreviation})`
        : ''}
    </>
  );
};

export default ZonedDateTime;
