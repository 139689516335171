import { AuditTemplateDetails } from '@repo/shared/types';
import { ActionType, ItemType } from '@repo/shared/enums';
import { getTriggerTypeEnumValue } from '@application/AuditTemplateBuilder/utils/getTriggerTypeEnumValue';

export function prepareAuditTemplateDetailsItems(
  auditTemplateDetails: AuditTemplateDetails
): AuditTemplateDetails {
  for (let id in auditTemplateDetails.data) {
    const item = auditTemplateDetails.data[id];

    if (item.childrenIds.length > 1) {
      item.childrenIds = item.childrenIds.sort(
        (id1: string, id2: string) =>
          auditTemplateDetails.data[id1].index -
          auditTemplateDetails.data[id2].index
      );
    }

    if (
      item.itemType === ItemType.ConditionalItem &&
      item.childrenIds.length > 0 &&
      auditTemplateDetails.data[item.childrenIds[0]]?.itemType ===
        ItemType.Condition
    ) {
      item.conditions = [
        ...(item.conditions || []),
        {
          actionType: ActionType.AddItems,
          triggerType: getTriggerTypeEnumValue(
            auditTemplateDetails.data[item.childrenIds[0]].condition?.value
          ),
        },
      ];
    }
  }

  return auditTemplateDetails;
}
