import { useIntl } from 'react-intl';

import { AnswerType } from '@repo/shared/enums';
import {
  IButtonsAnswerData,
  INumericAnswerData,
  IChecklistAnswerData,
  ITemperatureAnswerData,
  IDropdownAnswerData,
  ISliderAnswerData,
} from '@repo/shared/types';
import { ActionItemAnswer } from '@domain/Actions/models/ActionItemAnswer';
import { isTextAnswerData } from '@utils';

export function useItemAnswerResponse(
  answer?: ActionItemAnswer | null
): string | null {
  const { formatMessage } = useIntl();

  if (!answer) {
    return null;
  }

  const {
    answerType,
    notApplicable,
    notApplicableInRowCount,
    failedInRowCount,
  } = answer;

  if (notApplicable) {
    let response = 'N/A';

    if (notApplicableInRowCount > 1) {
      response += ` (${formatMessage(
        { id: 'XTimesInRow' },
        { count: notApplicableInRowCount }
      )})`;
    }

    return response;
  }

  if (answerType === AnswerType.Text && isTextAnswerData(answer.data)) {
    return answer.data.text || '-';
  }

  let responseText = '';

  // TODO fix answer data interfaces
  const data = answer.data as
    | IButtonsAnswerData
    | INumericAnswerData
    | IChecklistAnswerData
    | ITemperatureAnswerData
    | IDropdownAnswerData
    | ISliderAnswerData;

  for (let i = 0; i < data.conditions.length; i++) {
    const condition = data.conditions[i] as any;

    if (condition.selected) {
      switch (answerType) {
        case AnswerType.PassFailButtons:
        case AnswerType.YesNoButtons:
        case AnswerType.Checklist:
        case AnswerType.Dropdown:
          responseText = condition.name;
          break;
        case AnswerType.Numeric:
        case AnswerType.Slider:
        case AnswerType.Temperature:
          responseText = condition.number;
          break;
        default:
      }

      if (condition.markAsFailed) {
        const failedCountSuffix =
          failedInRowCount > 1
            ? ` ${formatMessage(
                { id: 'XTimesInRow' },
                { count: failedInRowCount }
              )}`
            : '';

        responseText += ` (${formatMessage({
          id: 'ItemHasBeenFailed',
        })}${failedCountSuffix})`;
      }

      break;
    }
  }

  return responseText;
}
