import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '@src/core/frameworks/redux';
import { ActionTemplatesState } from '@application/ActionTemplates/models/ActionTemplatesState';
import { actionTemplatesAdapter } from '@application/ActionTemplates/store/entityAdapters';

const getActionTemplatesState = (state: IRootState): ActionTemplatesState =>
  state.actionTemplates;

const getActionTemplates = createSelector(
  getActionTemplatesState,
  ({ data, loading, error, meta }) => ({
    data: actionTemplatesAdapter.getSelectors().selectAll(data),
    loading,
    error,
    meta,
  })
);

const getActionTemplatesDictionary = createSelector(
  getActionTemplatesState,
  ({ data }) => actionTemplatesAdapter.getSelectors().selectEntities(data)
);

const getFilters = createSelector(
  getActionTemplatesState,
  ({ filters }) => filters
);

const getDeleteModalState = createSelector(
  getActionTemplatesState,
  ({ confirmDeleteModal }) => confirmDeleteModal
);

const getAddEditModalState = createSelector(
  getActionTemplatesState,
  ({ addEditModal }) => addEditModal
);

export const actionTemplatesSelectors = {
  getActionTemplates,
  getActionTemplatesDictionary,
  getFilters,
  getDeleteModalState,
  getAddEditModalState,
};
