import React, {
  useEffect,
  useState,
  FC,
  PropsWithChildren,
  useMemo,
} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import { IApiService } from '@repo/shared/types';
import { Logger } from '@repo/shared/services';

import Loader from '@repo/shared/components/Loader';
import Error from '@repo/shared/components/Error';
import { colors } from '@repo/shared/config';

const Container = styled.div`
  position: relative;
  background: black;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

interface IProps {
  fileId: string;
  getFilePath?: (fileId: string) => string;
  apiService: IApiService;
  containerProps?: React.CSSProperties;
  loaderEl?: React.ReactNode;
  errorEl?: React.ReactNode;
}

const VideoPlayer: FC<PropsWithChildren<IProps>> = ({
  fileId,
  getFilePath = (fileId) => `files/${fileId}`,
  apiService,
  containerProps,
  loaderEl = <Loader />,
  errorEl = <Error />,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [headers, setHeaders] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    async function getToken() {
      setLoading(true);

      try {
        setHeaders(await apiService.getAuthHeaders());
      } catch (e) {
        setHasError(true);

        Logger.captureException(e);
      }

      setLoading(false);
    }

    getToken();
  }, []);

  const videoUrl = useMemo(() => {
    let url = apiService.getFileApiHost();

    const companyId = apiService.getCurrentCompanyId();

    if (companyId) {
      url += `/${companyId}`;
    }

    url += `/${getFilePath(fileId)}/video/playlist`;

    return url;
  }, []);

  if (loading) {
    return <>{loaderEl}</>;
  }

  if (hasError || !headers) {
    return <>{errorEl}</>;
  }

  return (
    <ReactPlayer
      controls
      width="100%"
      height="100%"
      className="react-player"
      style={{
        background: colors.black,
      }}
      url={videoUrl}
      config={{
        file: {
          forceHLS: true,
          hlsOptions: {
            xhrSetup: (xhr: XMLHttpRequest) => {
              for (const header in headers) {
                xhr.setRequestHeader(header, headers[header]);
              }
            },
          },
        },
      }}
    />
  );
};

export default VideoPlayer;
