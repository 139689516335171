import React from 'react';
import { useAsync } from 'react-async-hook';
import { SelectProps } from 'antd/es/select';

import EntitySelect from './EntitySelect';
import { IOption } from '@repo/shared/types';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

interface IProps extends SelectProps<any> {
  apiUrl: string;
  width?: string;
  extraOptions?: IOption[];
  e2eDataAttr?: string;
}

const EntitySelectAsync: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef(({ apiUrl, ...props }, _) => {
    const { result, loading } = useAsync<any>(
      () =>
        apiService.get({
          url: apiUrl,
        }),
      []
    );

    return <EntitySelect {...props} options={result} loading={loading} />;
  });

export default EntitySelectAsync;
