import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Trigger } from './styled';
import { useAppDispatch } from '@hooks';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { Comment } from '@domain/common/Comment';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';

interface IProps {
  comment: Comment;
}

const ActionDetailsCommentMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  comment,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const action = useSelector(actionsSelectors.getActionDetailsAction);

  if (!action) {
    return null;
  }

  return (
    <DropDownMenu
      menuItems={[
        {
          name: formatMessage({
            id: 'Edit',
          }),
          onClick: () => dispatch(actionsActions.setEditCommentId(comment.id)),
        },
        {
          name: formatMessage({
            id: 'Delete',
          }),
          onClick: () =>
            dispatch(
              actionsActions.toggleDeleteActionCommentModal({
                commentId: comment.id,
                actionId: action.id,
              })
            ),
        },
      ]}
    >
      <Trigger type="button">
        <MoreHorizontal />
      </Trigger>
    </DropDownMenu>
  );
};

export default ActionDetailsCommentMenu;
