import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRole, IRoleDetails, ITableFilters } from '@repo/shared/types';
import {
  createGetEntitiesThunk,
  createAddOrUpdateEntityThunk,
  createDeleteEntityThunk,
} from '@utils';
import { getErrorMessage } from '@repo/shared/utils';
import { rolesSelectors } from './roles.selectors';
import { IRootState } from '../../../frameworks/redux';
import { Logger } from '@repo/shared/services';
import { apiUrls } from '@config';
import { Permission, RoleType } from '@repo/shared/enums';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

export const rolesActions = {
  getRoles: createGetEntitiesThunk<IRole, ITableFilters>({
    entityName: 'roles',
    apiUrl: `${apiUrls.roles}/detailed`,
    filtersSelector: rolesSelectors.getFilters,
  }),
  updateRole: createAddOrUpdateEntityThunk<IRole>({
    apiUrl: apiUrls.roles,
    entityName: 'roles',
  }),
  deleteRoles: createDeleteEntityThunk({
    apiUrl: apiUrls.roles,
    entityName: 'roles',
  }),
  setFilters: createAction<Partial<ITableFilters>>('roles/getDashboard'),
  getRoleDetails: createAsyncThunk<IRoleDetails, string, { state: IRootState }>(
    'roles/getDetails',
    async (id, { rejectWithValue }) => {
      try {
        return apiService.get({
          url: `${apiUrls.roles}/${id}`,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  getRolePermissionTypes: createAsyncThunk<
    Permission[],
    RoleType,
    { state: IRootState; rejectValue: string }
  >('roles/getRoleTypePermissions', async (roleType, { rejectWithValue }) => {
    try {
      return apiService.get({
        url: `${apiUrls.roles}/type/${roleType}/permissions`,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  showAddEditRoleModal: createAction<IRole | undefined>(
    'roles/showAddEditRoleModal'
  ),
  hideAddEditRoleModal: createAction('roles/hideAddEditRoleModal'),
};
