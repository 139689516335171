import styled from 'styled-components';
import AntDivider from 'antd/es/divider';
import AntTable from 'antd/es/table';

import { colors } from '@repo/shared/config';

export const StyledTable = styled(AntTable)<any>`
  flex-grow: 1;

  tbody:before {
    line-height: 7px;
    content: '\\200C';
    display: block;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #e6f7ff !important;
    border-color: rgba(0, 0, 0, 0.03);
  }

  &.clickable-row {
    .ant-table-row {
      & > td {
        cursor: pointer;
        transition: color 0.15s ease-in-out;
      }

      &:hover {
        & > td:not(.cell-text-link) {
          color: #000;
        }
      }
    }
  }

  .ant-table-thead {
    padding-bottom: 7px;

    .ant-table-cell {
      font-size: 12px;
      font-weight: normal;
      color: ${colors.gray8};
      border-bottom: 0;
      padding: 7px 16px;
    }

    .ant-table-cell {
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &.ant-table-selection-column {
        padding: 4px 10px;

        .ant-checkbox-wrapper {
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
        }

        .ant-checkbox {
          .ant-checkbox-inner {
            background: ${colors.gray5};
          }

          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background: ${colors.blue2};
              border-color: ${colors.blue2};
            }
          }

          &.ant-checkbox-disabled .ant-checkbox-inner {
            border-color: ${colors.gray5}!important;
          }
        }
      }
    }
  }

  .ant-table-column-sorter-full {
    margin-top: -4.8px;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 9px;
  }

  .ant-table-tbody {
    .ant-table-cell {
      border-bottom: 0;
      padding: 12px 16px;

      &.ant-table-selection-column {
        padding: 0;

        .ant-checkbox-wrapper {
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
        }
      }

      &.ant-table-column-sort {
        background: ${colors.white};
      }

      &.cell-text-link {
        color: ${colors.blue2};
        cursor: pointer;
      }
    }

    tr.ant-table-row:hover > td.ant-table-column-sort {
      background: #fafafa;
    }

    .ant-table-row:hover,
    .ant-table-row-selected {
      .ant-table-cell {
        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .ant-table-placeholder {
    background: none !important;
  }

  .ant-table-column-sorters {
    padding: 0;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      background: ${colors.gray5};
      border-color: ${colors.gray5}!important;
    }

    &:hover {
      .ant-checkbox-inner {
        background: ${colors.gray6};
        border-color: ${colors.gray6};
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background: ${colors.blue2};
        border-color: ${colors.blue2};
      }
    }

    &.ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background: ${colors.blue2};
    }
  }
`;

export const TableContainer = styled.div`
  display: flex;

  &:last-child {
    flex-grow: 1;
  }
`;

export const GroupsContainer = styled.div`
  width: 264px;
  margin-right: 12px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DisplayedRange = styled.span`
  font-size: 12px;
  color: ${colors.gray8};
`;

export const Divider = styled(AntDivider)`
  margin: 15px -25px 25px;
  border-top: 1px solid ${colors.gray5};
  width: auto;
`;

export const Button = styled.button`
  padding: 0;
  background: none;
  border: 0;
  color: ${colors.blue2};
  text-align: left;
`;

export const SelectRowCheckboxWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
`;
