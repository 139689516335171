import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '../shared/ActionsModal/styled';
import { generalActions, generalSelectors, tagsSelectors } from '@store';
import { Modal } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../shared/ActionsModal/ActionsModal';

const TagActionsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const tags = useSelector(tagsSelectors.getTagsLookUp);

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                dispatch(
                  generalActions.showModal({
                    name: Modal.AddEdit,
                    data: tags?.[selectedTableRowKeys[0]],
                  })
                );
                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          <Action
            onClick={() =>
              dispatch(
                generalActions.showModal({
                  name: Modal.ConfirmDelete,
                  data:
                    selectedTableRowKeys.length === 1
                      ? tags[selectedTableRowKeys[0]]
                      : undefined,
                })
              )
            }
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default TagActionsModal;
