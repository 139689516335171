import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { generalActions } from '@store';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { Modal, Permission } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { date } from '@utils';
import { Audit } from '@domain/Audits/models/Audit';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { performAuditActions } from '@application/PerformAudit/store/performAuditActions';
import { getAuditStatusFromAuditsPage } from '@application/Audits/utils/getAuditStatusFromAuditsPage';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  audit: Audit | null;
  context: 'modal' | 'table';
}

const AuditsActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  audit,
  context,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const page = useSelector(auditsSelectors.getPage);

  const [canDoAudits, canManageAudits] = usePermission([
    Permission.CanDoAudits,
    Permission.CanManageAudits,
  ]);

  if (!audit || page === null) {
    return null;
  }

  const menuItems = [];

  if (context === 'table') {
    menuItems.push({
      name: formatMessage({ id: 'Details' }),
      onClick: () => {
        dispatch(
          auditsActions.toggleViewAuditModal({
            opened: true,
            auditId: audit.id,
            status: getAuditStatusFromAuditsPage(page),
          })
        );
      },
    });
  }

  if ((page === AuditsPage.My || page === AuditsPage.Pending) && canDoAudits) {
    if (
      context === 'table' &&
      !date(audit.startDateInformation.localTime).isAfter(date(), 'day') &&
      audit.canStartAudit
    ) {
      menuItems.push({
        name: formatMessage({
          id: audit.startedAtInformation ? 'Continue' : 'Start',
        }),
        onClick: () => {
          dispatch(performAuditActions.openModal(audit.id));
        },
      });
    }

    menuItems.push({
      name: formatMessage({ id: 'Expire' }),
      onClick: () => {
        dispatch(
          generalActions.showModal({
            name: Modal.ConfirmExpire,
            data: [audit.id],
          })
        );
      },
    });
  }

  if (page === AuditsPage.Completed && canManageAudits) {
    menuItems.push({
      name: formatMessage({ id: 'Reopen' }),
      onClick: () => {
        dispatch(
          auditsActions.toggleReopenAuditModal({
            opened: true,
            auditId: audit.id,
          })
        );
      },
    });
  }

  if (canManageAudits) {
    menuItems.push({
      name: formatMessage({ id: 'Delete' }),
      onClick: () => {
        dispatch(
          auditsActions.toggleConfirmDeleteModal({
            multiple: false,
            data: audit,
          })
        );
      },
    });
  }

  if (menuItems.length === 0 && context === 'modal') {
    return null;
  }

  return <TableRowActionsMenu menuItems={menuItems} />;
};

export default AuditsActionsMenu;
