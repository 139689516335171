import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  margin: 0 0 3px 0;
`;

export const Input = styled.input`
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
  border-radius: 5px;
  background: ${colors.gray3};
  padding: 4px 8px;
  transition: all 0.3s;
  border: 1px solid
    ${({ hasError }: { hasError: boolean }) =>
      hasError ? colors.red : colors.gray3};

  &::placeholder {
    color: ${colors.gray8};
  }

  &:hover {
    background: ${colors.white};
    border-color: ${colors.white};
    box-shadow: 0 0 4px ${colors.blue2};
  }

  &:focus {
    background: ${colors.white};
    border-color: ${colors.blue2};
    box-shadow: 0 0 4px ${colors.blue2};
    outline: none;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin: 15px 0 10px;

  svg {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 8px;
    right: 8px;
    color: ${colors.gray5};
  }

  input:focus + svg {
    color: ${colors.gray5};
  }
`;
