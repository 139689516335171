import { createReducer } from '@reduxjs/toolkit';

import { getInitialFilters } from '@utils';
import { SchedulePlansState } from '@application/SchedulePlans/models/SchedulePlansState';
import {
  schedulePlansAdapter,
  schedulePlanPeriodAdapter,
  schedulePlanPeriodAuditorAdapter,
  schedulePlanPeriodAuditAdapter,
} from '@application/SchedulePlans/store/entityAdapters';
import { schedulePlansActions as actions } from '@application/SchedulePlans/store/schedulePlansActions';
import { SchedulePlanDetailsPage } from '@application/SchedulePlans/models/SchedulePlanDetailsPage';
import { SchedulePlanPeriodDetailsPage } from '@application/SchedulePlans/models/SchedulePlanPeriodDetailsPage';
import { conciseSchedulesAdapter } from '@application/Schedules/store/entityAdapters';

const listInitialState = {
  data: schedulePlansAdapter.getInitialState(),
  loading: false,
  error: null,
  meta: null,
  filters: {
    ...getInitialFilters(),
  },
};

const detailsInitialState = {
  data: null,
  meta: null,
  error: null,
  loading: false,
  filters: {
    ...getInitialFilters(),
  },
  page: SchedulePlanDetailsPage.SchedulingRules,
};

const periodsInitialState = {
  data: schedulePlanPeriodAdapter.getInitialState(),
  loading: false,
  meta: null,
  error: null,
  filters: {
    ...getInitialFilters(),
  },
};

const periodDetailsInitialState = {
  data: null,
  loading: false,
  meta: null,
  error: null,
  page: SchedulePlanPeriodDetailsPage.BusyAuditors,
  schedulePlanId: null,
  auditorsTable: {
    loading: false,
    data: schedulePlanPeriodAuditorAdapter.getInitialState(),
    error: null,
    filters: {
      ...getInitialFilters(),
    },
    meta: null,
  },
  showStartNewPeriodModal: false,
  auditsTable: {
    loading: false,
    data: schedulePlanPeriodAuditAdapter.getInitialState(),
    error: null,
    filters: {
      ...getInitialFilters(),
    },
    meta: null,
  },
};

const initialState: SchedulePlansState = {
  table: {
    ...listInitialState,
  },
  concise: {
    loading: false,
    data: conciseSchedulesAdapter.getInitialState(),
    error: null,
  },
  details: {
    ...detailsInitialState,
  },
  addEditModal: {
    show: false,
  },
  confirmDeleteModal: null,
  periods: {
    ...periodsInitialState,
  },
  periodDetails: {
    ...periodDetailsInitialState,
  },
};

export const schedulePlansReducer = createReducer<SchedulePlansState>(
  initialState,
  (builder) =>
    builder
      .addCase(actions.getSchedulePlans.pending, (state, { meta: { arg } }) => {
        state.table.error = null;
        state.table.loading = true;

        if (state.details.data) {
          state.details.filters =
            arg === null
              ? {
                  ...getInitialFilters(),
                }
              : {
                  ...state.details.filters,
                  ...arg,
                };
        } else {
          state.table.filters =
            arg === null
              ? {
                  ...getInitialFilters(),
                }
              : {
                  ...state.table.filters,
                  ...arg,
                };
        }
      })
      .addCase(actions.getSchedulePlans.fulfilled, (state, { payload }) => {
        schedulePlansAdapter.setAll(state.table.data, payload.data);
        state.table.meta = payload.meta;
        state.table.loading = false;
      })
      .addCase(actions.getSchedulePlans.rejected, (state, { payload }) => {
        state.table.error = payload || null;
        state.table.loading = false;
      })
      .addCase(actions.toggleConfirmDeleteModal, (state, { payload }) => {
        state.confirmDeleteModal = payload;
      })
      .addCase(actions.toggleAddEditModal, (state, { payload }) => {
        state.addEditModal = payload;
      })
      .addCase(actions.resetListData, (state) => {
        state.table = {
          ...listInitialState,
        };
      })
      .addCase(actions.resetDetailsData, (state) => {
        state.details = {
          ...detailsInitialState,
        };
      })
      .addCase(actions.getSchedulePlanDetails.pending, (state) => {
        state.details.error = null;
        state.details.loading = true;
      })
      .addCase(
        actions.getSchedulePlanDetails.fulfilled,
        (state, { payload }) => {
          state.details.loading = false;
          state.details.data = payload;
        }
      )
      .addCase(
        actions.getSchedulePlanDetails.rejected,
        (state, { payload }) => {
          state.details.loading = false;
          state.details.error = payload || null;
        }
      )
      .addCase(
        actions.changeStatus.fulfilled,
        (
          state,
          {
            payload: {
              dto: { id, ...changes },
              currentPeriod,
            },
          }
        ) => {
          if (state.details.data?.id === id) {
            state.details.data = {
              ...state.details.data,
              ...changes,
            };
            state.details.data.currentPeriod = currentPeriod;
          }

          if (
            schedulePlansAdapter.getSelectors().selectById(state.table.data, id)
          ) {
            schedulePlansAdapter.updateOne(state.table.data, {
              id,
              changes,
            });
          }
        }
      )
      .addCase(actions.updateSchedulePlan.fulfilled, (state, { payload }) => {
        if (payload.id === state.details.data?.id) {
          state.details.data = {
            ...state.details.data,
            ...payload,
          };
        }
      })
      .addCase(
        actions.getSchedulePlanPeriods.pending,
        (state, { meta: { arg } }) => {
          state.periods.loading = true;
          state.periods.error = null;

          state.periods.filters =
            arg === null
              ? {
                  ...getInitialFilters(),
                }
              : {
                  ...state.periods.filters,
                  ...arg,
                };
        }
      )
      .addCase(
        actions.getSchedulePlanPeriods.fulfilled,
        (state, { payload }) => {
          state.periods.loading = false;
          schedulePlanPeriodAdapter.setAll(state.periods.data, payload.data);
          state.periods.meta = payload.meta;
        }
      )
      .addCase(
        actions.getSchedulePlanPeriods.rejected,
        (state, { payload }) => {
          state.periods.loading = false;
          state.periods.error = payload || null;
        }
      )
      .addCase(actions.setSchedulePlanDetailsPage, (state, { payload }) => {
        state.details.page = payload;
      })
      .addCase(actions.resetPeriodsListData, (state) => {
        state.periods = {
          ...periodsInitialState,
        };
      })
      .addCase(
        actions.getSchedulePlanPeriod.pending,
        (state, { meta: { arg } }) => {
          state.periodDetails.loading = true;
          state.periodDetails.error = null;
          state.periodDetails.schedulePlanId = arg.schedulePlanId;
        }
      )
      .addCase(
        actions.getSchedulePlanPeriod.fulfilled,
        (state, { payload }) => {
          state.periodDetails.loading = false;
          state.periodDetails.data = payload;
        }
      )
      .addCase(actions.getSchedulePlanPeriod.rejected, (state, { payload }) => {
        state.periodDetails.loading = false;
        state.periodDetails.error = payload || null;
        state.periodDetails.schedulePlanId = null;
      })
      .addCase(actions.resetPeriodData, (state) => {
        state.periodDetails = {
          ...periodDetailsInitialState,
        };
      })
      .addCase(
        actions.getSchedulePlanPeriodAuditors.pending,
        (state, { meta: { arg } }) => {
          state.periodDetails.auditorsTable.loading = true;
          state.periodDetails.auditorsTable.error = null;

          state.periodDetails.auditorsTable.filters =
            arg === null
              ? {
                  ...getInitialFilters(),
                }
              : {
                  ...state.periodDetails.auditorsTable.filters,
                  ...arg,
                };
        }
      )
      .addCase(
        actions.getSchedulePlanPeriodAuditors.fulfilled,
        (state, { payload }) => {
          state.periodDetails.auditorsTable.loading = false;
          schedulePlanPeriodAuditorAdapter.setAll(
            state.periodDetails.auditorsTable.data,
            payload.data
          );
          state.periodDetails.auditorsTable.meta = payload.meta;
        }
      )
      .addCase(
        actions.getSchedulePlanPeriodAuditors.rejected,
        (state, { payload }) => {
          state.periodDetails.auditorsTable.loading = false;
          state.periodDetails.auditorsTable.error = payload || null;
        }
      )
      .addCase(
        actions.getSchedulePlanPeriodAudits.pending,
        (state, { meta: { arg } }) => {
          state.periodDetails.auditsTable.loading = true;
          state.periodDetails.auditsTable.error = null;

          state.periodDetails.auditsTable.filters =
            arg === null
              ? {
                  ...getInitialFilters(),
                }
              : {
                  ...state.periodDetails.auditsTable.filters,
                  ...arg,
                };
        }
      )
      .addCase(
        actions.getSchedulePlanPeriodAudits.fulfilled,
        (state, { payload }) => {
          state.periodDetails.auditsTable.loading = false;
          schedulePlanPeriodAuditAdapter.setAll(
            state.periodDetails.auditsTable.data,
            payload.data
          );
          state.periodDetails.auditsTable.meta = payload.meta;
        }
      )
      .addCase(
        actions.getSchedulePlanPeriodAudits.rejected,
        (state, { payload }) => {
          state.periodDetails.auditsTable.loading = false;
          state.periodDetails.auditsTable.error = payload || null;
        }
      )
      .addCase(actions.toggleStartNewPeriodModal, (state, { payload }) => {
        state.periodDetails.showStartNewPeriodModal = payload;
      })
      .addCase(
        actions.setSchedulePlanPeriodDetailsPage,
        (state, { payload }) => {
          state.periodDetails.page = payload;
        }
      )
      .addCase(actions.setSchedulePlanSchedulesCount, (state, { payload }) => {
        if (state.details.data) {
          state.details.data.schedulesCount = payload;
        }
      })
      .addCase(actions.getConciseSchedulePlans.pending, (state) => {
        state.concise.loading = true;
      })
      .addCase(
        actions.getConciseSchedulePlans.fulfilled,
        (state, { payload }) => {
          state.concise.loading = false;
          conciseSchedulesAdapter.setAll(state.concise.data, payload);
        }
      )
      .addCase(actions.getConciseSchedulePlans.rejected, (state) => {
        state.concise.loading = false;
      })
);
