import styled from 'styled-components';
import AntCheckbox from 'antd/es/checkbox';

import { colors } from '@repo/shared/config';

const Checkbox = styled(AntCheckbox)`
  .ant-checkbox-inner {
    background: ${colors.gray5};
    border-color: ${colors.gray5};
  }

  &:hover {
    .ant-checkbox-inner {
      background: ${colors.gray6};
      border-color: ${colors.gray6};
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: ${colors.blue2};
      border-color: ${colors.blue2};
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background: ${colors.blue2};
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      border-color: ${colors.gray3}!important;
      background: ${colors.gray3}!important;

      &:after {
        border-color: ${colors.gray8};
      }
    }
  }
`;

export default Checkbox;
