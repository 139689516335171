import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Section, Description, Container, Button } from './styled';
import { notification } from '@utils/notifications';
import { accountActions, accountSelectors } from '@store';
import { useAppDispatch } from '@hooks';

import PageHeader from '../../shared/ant/PageHeader';
import UploadLogo from './UploadLogo/UploadLogo';
import UploadIcon from './UploadIcon/UploadIcon';
import Form from '../../shared/ant/Form';
import CompanyForm from '../../account/CompanyForm';

const General: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const company = useSelector(accountSelectors.getCompany);

  return (
    <PageHeader title={formatMessage({ id: 'General' })}>
      <Container>
        <Description>
          {formatMessage({ id: 'SetUpYourCompanyAndAdditionalNeeds' })}
        </Description>
        <Section>
          <h3>{formatMessage({ id: 'Logo&Icon' })}</h3>
          <UploadLogo />
          <UploadIcon />
        </Section>
        <Section>
          <h3>{formatMessage({ id: 'Details' })}</h3>
          <CompanyForm
            form={form}
            initialValues={company}
            onSubmit={async (values) => {
              setLoading(true);

              const resultAction = await dispatch(
                accountActions.updateCompany({
                  update: {
                    ...values,
                  },
                })
              );

              if (accountActions.updateCompany.fulfilled.match(resultAction)) {
                notification.success({
                  message: formatMessage({
                    id: 'CompanyInformationHasBeenSuccessfullyUpdated',
                  }),
                });
              } else {
                notification.error({
                  message: formatMessage({ id: 'ErrorWhileUpdatingCompany' }),
                  description: resultAction.payload,
                });
              }

              setLoading(false);
            }}
          />
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            {formatMessage({ id: 'Update' })}
          </Button>
        </Section>
      </Container>
    </PageHeader>
  );
};

export default General;
