import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Action = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.gray10, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M20.4376 11.1094H6.72435L14.9322 3.98438C15.0634 3.86953 14.9837 3.65625 14.8103 3.65625H12.7361C12.6447 3.65625 12.5579 3.68906 12.49 3.74766L3.63294 11.4328C3.55182 11.5031 3.48676 11.5901 3.44218 11.6877C3.39759 11.7854 3.37451 11.8915 3.37451 11.9988C3.37451 12.1062 3.39759 12.2123 3.44218 12.3099C3.48676 12.4076 3.55182 12.4945 3.63294 12.5648L12.5415 20.2969C12.5767 20.3273 12.6189 20.3438 12.6634 20.3438H14.8079C14.9814 20.3438 15.0611 20.1281 14.9298 20.0156L6.72435 12.8906H20.4376C20.5408 12.8906 20.6251 12.8062 20.6251 12.7031V11.2969C20.6251 11.1937 20.5408 11.1094 20.4376 11.1094Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default Action;
