import React from 'react';

import { DayOfWeek } from '@repo/shared/enums';
import { Container, Day } from './styled';
import { getWeekDaysArray } from '@utils';

const weekDaysArray = getWeekDaysArray();

const dayAbbr = {
  [DayOfWeek.Monday]: 'M',
  [DayOfWeek.Tuesday]: 'T',
  [DayOfWeek.Wednesday]: 'W',
  [DayOfWeek.Thursday]: 'T',
  [DayOfWeek.Friday]: 'F',
  [DayOfWeek.Saturday]: 'S',
  [DayOfWeek.Sunday]: 'S',
};

interface IProps {
  value?: number[];
  onChange?: (update: number[]) => void;
  disabled?: boolean;
}

const DaysOfWeek: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <Container>
      {weekDaysArray.map((day) => {
        const selected = value?.includes(day) || false;

        return (
          <Day
            key={day}
            type="button"
            onClick={() => {
              let update = [...(value || [])];

              if (selected) {
                update = update.filter((d) => d !== day);
              } else {
                update.push(day);
              }

              if (onChange) {
                onChange(update);
              }
            }}
            selected={selected}
            disabled={disabled}
          >
            {dayAbbr[day as DayOfWeek]}
          </Day>
        );
      })}
    </Container>
  );
};

export default DaysOfWeek;
