import { AuditorAssignmentType } from '@domain/Schedules/enums/AuditorAssignmentType';

const langIds = {
  [AuditorAssignmentType.NoAuditorAssigned]: 'NoAuditorAssigned',
  [AuditorAssignmentType.AssignToSpecificAuditor]: 'AssignToSpecificAuditor',
  [AuditorAssignmentType.RandomAssignedAuditorFromGroup]:
    'RandomAssignedAuditorFromGroup',
  [AuditorAssignmentType.EvenlyDistributedAuditorFromGroup]:
    'EvenlyDistributedAuditorFromGroup',
};

export function getAuditorAssignmentTypeLangId(type: AuditorAssignmentType) {
  return langIds[type];
}
