import { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ChartItem } from '@repo/shared/types';
import { Container, Item, ItemWrap, ShowMoreBtn, Content } from './styled';
import { hasOwnProperty } from '@repo/shared/utils';

import Popover from '@components/shared/ant/Popover';

export type Layout = 'horizontal' | 'vertical';

interface IChartLegendItem extends Omit<ChartItem, 'count'> {
  count?: number;
}

interface IProps<T> {
  items: T[];
  loading: boolean;
  layout?: Layout;
  onItemClick?: (item: T | null) => void;
  maxCount?: number;
}

function ChartLegend<T extends IChartLegendItem = IChartLegendItem>({
  items,
  loading,
  layout = 'horizontal',
  onItemClick,
  maxCount,
}: IProps<T>) {
  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState<T | null>(null);

  const getItem = useCallback((item: T) => {
    return (
      <Item
        key={item.label}
        dotColor={item.color}
        style={{
          cursor: !!onItemClick ? 'pointer' : 'default',
          opacity: selected === null || selected.id === item.id ? 1 : 0.4,
        }}
        layout={layout}
        onClick={() => {
          const newSelected = selected && selected.id === item.id ? null : item;

          setSelected(newSelected);

          if (onItemClick) {
            onItemClick(newSelected);
          }
        }}
      >
        {item.label}
      </Item>
    );
  }, []);

  if (!Array.isArray(items)) {
    return null;
  }

  return (
    <Popover
      placement="bottom"
      content={
        maxCount !== undefined && items.length > maxCount ? (
          <Content>
            {items.map((item) => {
              if (hasOwnProperty(item, 'count') && item.count === 0) {
                return null;
              }

              return getItem(item);
            })}
          </Content>
        ) : null
      }
    >
      <Container layout={layout}>
        {!loading &&
          items.map((item, i) => {
            if (
              (maxCount !== undefined && i > maxCount - 1) ||
              (hasOwnProperty(item, 'count') && item.count === 0)
            ) {
              return null;
            }

            const itemEl = getItem(item);

            if (
              maxCount !== undefined &&
              items.length > maxCount &&
              i === maxCount - 1
            ) {
              return (
                <ItemWrap>
                  {itemEl}
                  <ShowMoreBtn type="button" onClick={() => {}}>
                    {formatMessage({ id: 'ShowMore' })}
                  </ShowMoreBtn>
                </ItemWrap>
              );
            }

            return itemEl;
          })}
      </Container>
    </Popover>
  );
}

export default ChartLegend;
