import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Body,
  Container,
  ValueContainer,
  DetailsButton,
  Header,
  Title,
} from './styled';
import { accountSelectors, generalActions } from '@store';
import { useAppDispatch } from '@hooks';
import { Issue } from '@domain/Issues/models/Issue';
import { issuesActions } from '@application/Issues/store';

import ZonedDateTime from '@components/shared/ZonedDateTime';
import IssueStatusName from '@presentation/Issues/IssueStatusName';
import Definition from '@repo/shared/components/Definition/Definition';

const containerStyle: React.CSSProperties = {
  fontSize: '12px',
};

const valueStyle: React.CSSProperties = {
  minHeight: '18px',
};

interface IProps {
  item: Issue;
}

const MyIssue: React.FC<React.PropsWithChildren<IProps>> = ({ item }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const objectName = useSelector(accountSelectors.getObjectName);

  return (
    <Container>
      <Header>
        <Title>{item.name ?? [item.number, item.type.name].join(' - ')}</Title>
      </Header>
      <Body>
        <div>
          {!!item.dueDate && (
            <Definition
              horizontal
              valueStyle={valueStyle}
              containerStyle={containerStyle}
              label={formatMessage({ id: 'DueDate' })}
              value={<ZonedDateTime dateTime={item.dueDate} />}
            />
          )}
          <Definition
            horizontal
            valueStyle={valueStyle}
            containerStyle={containerStyle}
            label={formatMessage({ id: 'Status' })}
            value={<IssueStatusName status={item.status} />}
          />
        </div>
        <div>
          {!!item.auditObject && (
            <Definition
              horizontal
              valueStyle={valueStyle}
              containerStyle={containerStyle}
              label={objectName.single}
              value={<ValueContainer>{item.auditObject.name}</ValueContainer>}
            />
          )}
          {!!item.type && (
            <Definition
              horizontal
              valueStyle={valueStyle}
              containerStyle={containerStyle}
              label={formatMessage({ id: 'IssueType' })}
              value={<ValueContainer>{item.type.name}</ValueContainer>}
            />
          )}
        </div>
        <DetailsButton
          onClick={() => {
            dispatch(issuesActions.toggleFiltersModal(false));
            dispatch(generalActions.selectTableRows([]));
            dispatch(issuesActions.toggleIssueDetailsModal(item.id));
          }}
        >
          {formatMessage({ id: 'Details' })}
        </DetailsButton>
      </Body>
    </Container>
  );
};

export default MyIssue;
