import React from 'react';

import Form from '@components/shared/ant/Form';
import DaysOfWeek from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/Weekly/DaysOfWeek';

interface IProps {
  disabled?: boolean;
}

const Weekly: React.FC<React.PropsWithChildren<IProps>> = ({ disabled }) => {
  return (
    <Form.Item name={['repeat', 'daysOfWeek']}>
      <DaysOfWeek disabled={disabled} />
    </Form.Item>
  );
};

export default Weekly;
