import styled from 'styled-components';
import Layout from 'antd/es/layout';
import AntMenu from 'antd/es/menu';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@repo/shared/utils';

const { Sider: AntSider } = Layout;

export const LogoContainer = styled.div`
  padding: 15px 20px;
  height: 64px;
  background: ${colors.blue6};
`;

export const Menu = styled(AntMenu)`
  &.ant-menu {
    background: ${colors.blue6};
    padding: 15px 0 50px;

    &.ant-menu-dark {
      color: ${hexToRGB(colors.white, 0.65)};
    }

    .ant-menu-item {
      margin: 0;
      color: ${hexToRGB(colors.white, 0.65)};

      &.ant-menu-item-selected {
        svg {
          color: ${colors.white};
        }
      }

      & > a {
        display: block;
      }

      &.onboarding-popover-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > * {
          &:first-child {
            flex-grow: 1;
          }
        }
      }
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item {
      height: auto;
      margin: 13px 0;
      line-height: 22px;

      a {
        white-space: normal;
      }
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding-left: 20px !important;
      padding-right: 6px !important;
      text-transform: capitalize;
    }

    .ant-menu-item,
    .ant-menu-item.ant-menu-item-selected,
    .ant-menu-sub {
      background: ${colors.blue6}!important;
    }

    .ant-menu-item-selected {
      a {
        color: ${colors.white};
      }
    }

    .ant-menu-item > svg,
    .ant-menu-submenu-title > svg {
      display: inline-block;
      color: inherit;
      font-style: normal;
      line-height: 0;
      text-align: center;
      text-transform: none;
      vertical-align: -0.175em;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
      width: 16px;
      height: 16px;
    }

    .ant-menu-sub .ant-menu-item {
      color: ${colors.gray7};

      &:hover {
        color: ${colors.white};
      }
    }

    .ant-menu-item-selected {
      overflow: visible;

      &:before {
        display: block;
        content: '';
        position: absolute;
        background: ${colors.blue1};
        height: 100%;
        left: -27px;
        width: 3px;
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        margin: 0;

        &:hover {
          background: none;
        }
      }

      .ant-menu-sub {
        padding-left: 27px;
      }
    }
  }
`;

export const Sider = styled(AntSider)`
  background: ${colors.blue6}!important;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;

    & > .ant-menu {
      flex-grow: 1;
    }
  }
`;

export const SidebarFooter = styled.div`
  padding: 0 0 20px 15px;
  margin: 0 -20px 0 -7px;
  text-align: center;
`;

export const YearCopy = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 0 5px 7px;
  }
`;

export const CopyRight = styled.div`
  font-size: 12px;
  color: ${colors.gray7};
  margin-top: 1px;
`;

export const LinkButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  color: hsla(0, 0%, 100%, 0.65);
  transition: color 0.3s;

  &:hover {
    color: ${colors.white};
  }
`;

export const AdditionalSubMenuTitleContent = styled.span`
  color: hsla(0, 0%, 100%, 0.65);
  transition: color 0.3s;

  &:hover {
    color: ${colors.white};
  }
`;

export const MenuItemWithPopover = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    flex-grow: 1;
  }

  &:last-child {
    height: 22px;
    width: 1px;
  }
`;

export const ExternalLink = styled.a`
  display: inline-flex;
  align-items: center;
  width: 138px;
  justify-content: space-between;

  svg {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    width: 13px;
    height: 13px;
    margin-top: -2px;
    color: ${colors.gray5};
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
