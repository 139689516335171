import React from 'react';
import Spin from 'antd/es/spin';

import { Container } from './styled';

const Loader: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <Spin size="large" />
    </Container>
  );
};

export default Loader;
