import React, { useState, useEffect } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate, useLocation } from 'react-router-dom';
import { config, useSpring } from 'react-spring';
import Measure from 'react-measure';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Container,
  AccountBtn,
  SelectedAccountBtn,
  Name,
  ToggleSwitch,
  ManageAccountsBtn,
  Expandable,
} from './styled';
import { useAccounts } from './hooks';
import { useAppDispatch } from '@hooks';
import { accountActions, accountSelectors } from '@store';
import { notification } from '@utils/notifications';
import { e2eTestElements, routes } from '@config';

import AccountIcon from '@components/shared/Sidebar/AccountsSelector/AccountIcon/AccountIconContainer';

const AccountsSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);
  const { selectedAccount, otherAccounts } = useAccounts();
  const [height, setHeight] = useState<number>(0);

  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const props = useSpring({
    config: config.stiff,
    from: { height: 0 },
    to: {
      height: expanded ? height : 0,
    },
  });

  useEffect(() => {
    setExpanded(false);
  }, [location.pathname]);

  if (!selectedAccount) {
    return null;
  }

  return (
    <Container>
      <SelectedAccountBtn
        type="button"
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
        data-e2e={e2eTestElements.account.selectAccount.expandable}
      >
        <AccountIcon account={selectedAccount} />
        <Name>{selectedAccount.company.name}</Name>
        <ToggleSwitch expanded={expanded}>
          <ChevronRight />
        </ToggleSwitch>
      </SelectedAccountBtn>
      <Expandable style={{ ...props, overflow: 'hidden' }}>
        <Measure
          bounds
          onResize={(contentRect) => {
            setHeight(contentRect.bounds?.height || 0);
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              {otherAccounts.map((account) => (
                <AccountBtn
                  key={account.id}
                  type="button"
                  data-e2e={e2eTestElements.account.selectAccount.account}
                  onClick={async () => {
                    const resultAction = await dispatch(
                      accountActions.changeAccount({
                        companyId: account.company.id,
                      })
                    );

                    if (
                      accountActions.changeAccount.rejected.match(resultAction)
                    ) {
                      notification.error({
                        message: formatMessage({
                          id: 'ErrorWhileChangingAccount',
                        }),
                      });
                    }
                  }}
                >
                  <AccountIcon account={account} />
                  <Name>{account.company.name}</Name>
                </AccountBtn>
              ))}
              {appConfiguration.allowSignUp && (
                <ManageAccountsBtn
                  type="button"
                  onClick={() => navigate(routes.accounts)}
                  data-e2e={
                    e2eTestElements.account.selectAccount.manageAccountsBtn
                  }
                >
                  {formatMessage({ id: 'ManageAccounts' })}
                </ManageAccountsBtn>
              )}
            </div>
          )}
        </Measure>
      </Expandable>
    </Container>
  );
};

export default AccountsSelector;
