import React, { ChangeEvent, useEffect, useState } from 'react';
import { Search, X } from 'react-feather';
import { useIntl } from 'react-intl';

import { Input, CloseButton } from './styled';

interface IProps {
  onEnter?: (search: string) => void;
  onChange?: (search: string) => void;
  value?: string;
  width?: string;
  disabled?: boolean;
  e2eDataAttr?: string;
  placeholder?: string;
}

const SearchInput: React.FC<React.PropsWithChildren<IProps>> = ({
  onEnter,
  onChange,
  width,
  value,
  disabled,
  e2eDataAttr,
  placeholder,
}) => {
  const [searchStr, setSearchStr] = useState('');
  const { formatMessage } = useIntl();

  useEffect(() => {
    setSearchStr(value || '');
  }, [value]);

  return (
    <Input
      placeholder={placeholder || formatMessage({ id: 'Search' })}
      data-e2e={e2eDataAttr}
      width={width}
      disabled={disabled}
      prefix={<Search />}
      suffix={
        <CloseButton
          type="button"
          onClick={() => {
            setSearchStr('');

            if (onChange) {
              onChange('');
            }

            if (!onChange && onEnter) {
              onEnter('');
            }
          }}
          disabled={searchStr === ''}
          show={searchStr !== ''}
        >
          <X />
        </CloseButton>
      }
      value={searchStr}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setSearchStr(e.target.value);

        if (onChange) {
          onChange(e.target.value);
        }
      }}
      onPressEnter={() => {
        if (onEnter) {
          onEnter(searchStr);
        }
      }}
    />
  );
};

export default SearchInput;
