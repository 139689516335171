import React from 'react';

import { Container, Title } from './styled';

import SubscriptionImage from './SubscriptionImage';

interface IProps {
  title: string | React.ReactNode;
  imageColor: string;
  leftColContent: React.ReactNode;
}

const TwoColsWithImage: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  imageColor,
  leftColContent,
}) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        {leftColContent}
      </div>
      <SubscriptionImage color={imageColor} />
    </Container>
  );
};

export default TwoColsWithImage;
