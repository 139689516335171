import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const DropDownTrigger = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  transition: background-color 0.2s ease-in;

  &:hover {
    background: ${colors.gray5};
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 19.2px;
    height: 19.2px;
  }
`;
