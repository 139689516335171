import { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { issuesActions } from '@application/Issues/store';

export function useSetIssuesPage(issuePage: IssuesPage) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(issuesActions.setPage(issuePage));
  }, []);
}
