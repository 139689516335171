import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { accountActions } from '@store';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { routes } from '@config';
import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { notification } from '@utils';
import { AnalyticsEvent } from '@repo/shared/enums';

import Modal from '@components/shared/ant/Modal/Modal';
import { performAuditActions } from '@application/PerformAudit/store/performAuditActions';

const StartSampleAuditModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const opened = useSelector(auditsSelectors.isStartSampleAuditModalOpened);

  function closeModal() {
    dispatch(auditsActions.toggleStartSampleAuditModal(false));
  }

  return (
    <Modal
      title={formatMessage({ id: 'StartSampleAudit' })}
      open={opened}
      width={390}
      confirmLoading={loading}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(auditsActions.getSampleAuditId());

        if (auditsActions.getSampleAuditId.fulfilled.match(resultAction)) {
          closeModal();

          const auditId = unwrapResult(resultAction);

          if (
            pathname !== routes.pendingAudits &&
            pathname !== routes.myAudits
          ) {
            navigate(routes.pendingAudits);
          }

          dispatch(performAuditActions.openModal(auditId));

          dispatch(
            accountActions.sendAnalyticsEvent(AnalyticsEvent.StartSampleAudit)
          );
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileStartingSampleAudit' }),
            description: resultAction.payload,
          });
        }

        setLoading(false);
      }}
      okText={formatMessage({ id: 'Start' })}
      okButtonBackground={colors.gray10}
      onCancel={() => {
        closeModal();
      }}
    >
      {formatMessage({ id: 'AreYouSureYouWantToStartSampleAudit' })}
    </Modal>
  );
};

export default StartSampleAuditModal;
