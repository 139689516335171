import { AnyAction, configureStore, Reducer } from '@reduxjs/toolkit';

import { combinedReducer, accountActions } from '@store';
// import { errorHandlingMiddleware } from '@errors/errorHandlingMiddleware';

const rootReducer: Reducer = (state: IRootState, action: AnyAction) => {
  if (action.type === accountActions.resetStore.type) {
    state = {} as IRootState;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(errorHandlingMiddleware),
});

// if (import.meta.env.VITE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default;
//     state.replaceReducer(newRootReducer);
//   });
// }

export type IRootState = ReturnType<typeof rootReducer>;

export type IAppDispatch = typeof store.dispatch;

export default store;
