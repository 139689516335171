import React from 'react';
import { Map, Marker } from '@vis.gl/react-google-maps';

import { Container } from './styled';
import { IGeoAddress } from '@repo/shared/types';
import { withMapsApiProvider } from '../withMapsApiProvider';

interface IProps {
  location: IGeoAddress;
  width?: string;
  height?: string;
  borderRadius?: string;
  zoom?: number;
}

const LocationMap: React.FC<React.PropsWithChildren<IProps>> = ({
  location,
  width = '370px',
  height = '280px',
  borderRadius = '10px',
  zoom = 16,
}) => {
  if (!location.lat || !location.lng) {
    return null;
  }

  const position = {
    lng: parseFloat(location.lng),
    lat: parseFloat(location.lat),
  };

  return (
    <Container style={{ width, height, borderRadius }}>
      <Map
        style={{ width, height }}
        center={position}
        defaultZoom={zoom}
        disableDefaultUI
      >
        <Marker position={position} />
      </Map>
    </Container>
  );
};

export default withMapsApiProvider(LocationMap);
