import { createSelector, EntityState } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import {
  conciseIssueTypesEntityAdapter,
  issuesTypesEntityAdapter,
} from '@application/IssueTypes/store/issueTypesEntityAdapters';
import { issueTypeFieldsEntityAdapter } from '@application/IssueTypes/store/issueTypesEntityAdapters';
import {
  IssueType,
  IssueTypeDetails,
} from '@domain/IssueTypes/models/IssueType';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { IssueTypeField } from '@domain/IssueTypes/models/IssueTypeField';
import { IConcise } from '@repo/shared/types';

const getIssueTypes = createSelector(
  [
    (state: IRootState) => state.issueTypes.issueTypes.data,
    (state: IRootState) => state.issueTypes.issueTypes.meta,
    (state: IRootState) => state.issueTypes.issueTypes.loading,
    (state: IRootState) => state.issueTypes.issueTypes.error,
  ],
  (data, meta, loading, error) => ({
    data: issuesTypesEntityAdapter.getSelectors().selectAll(data),
    meta,
    loading,
    error,
  })
);

const getConciseIssueTypes = createSelector(
  (
    state: IRootState
  ): {
    data: EntityState<IConcise, string>;
    loading: boolean;
    error: null;
  } => state.issueTypes.conciseIssueTypes,
  ({ data, error, loading }) => ({
    data: conciseIssueTypesEntityAdapter.getSelectors().selectAll(data),
    loading,
    error,
  })
);

const getIssueTypesDictionary = (state: IRootState) =>
  issuesTypesEntityAdapter
    .getSelectors()
    .selectEntities(state.issueTypes.issueTypes.data);

const getIssueTypesFilters = (state: IRootState) =>
  state.issueTypes.issueTypes.filters;

const getIssueTypeFields = createSelector(
  [
    (state: IRootState) => state.issueTypes.issueType.fields.data,
    (state: IRootState) => state.issueTypes.issueType.fields.meta,
    (state: IRootState) => state.issueTypes.issueType.fields.loading,
    (state: IRootState) => state.issueTypes.issueType.fields.error,
  ],
  (data, meta, loading, error) => ({
    data: issueTypeFieldsEntityAdapter.getSelectors().selectAll(data),
    meta,
    loading,
    error,
  })
);

const getIssueTypeFieldsDictionary = (state: IRootState) =>
  issueTypeFieldsEntityAdapter
    .getSelectors()
    .selectEntities(state.issueTypes.issueType.fields.data);

const getIssueTypeFieldsFilters = (state: IRootState) =>
  state.issueTypes.issueType.fields.filters;

const getIssueTypeFieldsAddEditModalState = (state: IRootState) =>
  state.issueTypes.issueType.fields.addEditModal;

const getIssueTypeDetails = (
  state: IRootState
): {
  loading: boolean;
  error: string | null;
  data: IssueTypeDetails | null;
} => state.issueTypes.issueType.details;

const getConfirmDeleteIssueTypesModalState = (
  state: IRootState
): DeleteModalState<IssueType> =>
  state.issueTypes.issueTypes.confirmDeleteModal;

const getConfirmDeleteIssueTypeFieldsModalState = (
  state: IRootState
): DeleteModalState<IssueTypeField> =>
  state.issueTypes.issueType.fields.confirmDeleteModal;

export const issueTypesSelectors = {
  getIssueTypes,
  getIssueTypesDictionary,
  getIssueTypesFilters,
  getIssueTypeFields,
  getIssueTypeFieldsFilters,
  getIssueTypeFieldsDictionary,
  getIssueTypeFieldsAddEditModalState,
  getIssueTypeDetails,
  getConfirmDeleteIssueTypesModalState,
  getConfirmDeleteIssueTypeFieldsModalState,
  getConciseIssueTypes,
};
