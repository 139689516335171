import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { summaryReportSelectors } from './summary-report.selectors';
import { generalActions, generalSelectors } from '@store';
import {
  IActionsAnalytics,
  IAuditActionsTotals,
  IAuditCompliance,
  IComparable,
  IComparables,
  ISharedFilters,
} from '@repo/shared/types';
import { IRootState } from '../../../frameworks/redux';
import {
  createGetAuditCompletionThunk,
  createGetAuditCompletionTime,
  createGetAuditPerformanceThunk,
  createGetEntitiesThunk,
  createGetFailedCriticalItemsThunk,
  createGetScoreBreakdownThunk,
  createGetSectionPerformanceThunk,
  prepareReportPDFRequestBody,
} from '@utils';
import {
  getErrorMessage,
  getExportFileMetaData,
  parseContentDispositionHeader,
  saveFile,
} from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import {
  ComparableChart,
  CompareType,
  DayOfWeek,
  ExportType,
  HttpMethods,
} from '@repo/shared/enums';
import { apiUrls } from '@config';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

const getTotals = createGetEntitiesThunk<
  IAuditActionsTotals,
  ISharedFilters,
  IAuditActionsTotals
>({
  apiUrl: `${apiUrls.reports.summary}/totals`,
  entityName: 'reports/summary/auditTotals',
  filtersSelector: generalSelectors.getSharedFilters,
  method: HttpMethods.post,
});
const getAuditCompliance = createGetEntitiesThunk<
  void,
  ISharedFilters,
  IAuditCompliance
>({
  apiUrl: `${apiUrls.reports.summary}/audit-compliance`,
  entityName: 'reports/summary/getAuditCompliance',
  filtersSelector: generalSelectors.getSharedFilters,
  method: HttpMethods.post,
});
const getScoreBreakdown = createGetScoreBreakdownThunk(
  generalSelectors.getSharedFilters
);
const getActionsAnalytics = createGetEntitiesThunk<
  void,
  ISharedFilters,
  IActionsAnalytics
>({
  apiUrl: `${apiUrls.reports.summary}/actions-analysis`,
  entityName: 'reports/summary/getActionsAnalytics',
  filtersSelector: generalSelectors.getSharedFilters,
  method: HttpMethods.post,
});
const getSectionPerformance = createGetSectionPerformanceThunk(
  summaryReportSelectors.getSectionPerformanceFilters
);
const getAuditPerformance = createGetAuditPerformanceThunk(
  summaryReportSelectors.getAuditPerformanceFilters
);
const getAuditCompletion = createGetAuditCompletionThunk(
  summaryReportSelectors.getAuditCompletionFilters
);
const getAuditDayOfWeekBreakdown = createGetEntitiesThunk<
  void,
  ISharedFilters,
  { count: number; dayOfWeek: DayOfWeek }[]
>({
  apiUrl: `${apiUrls.reports.summary}/audit-day-of-week-breakdown`,
  entityName: 'reports/summary/getAuditDayOfWeekBreakdown',
  filtersSelector: generalSelectors.getSharedFilters,
  method: HttpMethods.post,
});
const getAuditCompletionTime = createGetAuditCompletionTime(
  generalSelectors.getSharedFilters
);
const getFailedCriticalItems = createGetFailedCriticalItemsThunk(
  summaryReportSelectors.getFailedCriticalItemsFilters
);
const getTemplateSections = createAsyncThunk<
  {
    id: string;
    name: string;
    parentSectionId: string | null;
    parentSectionName: string | null;
  }[],
  string,
  { rejectValue: string; state: IRootState }
>('reports/getTemplateSections', async (templateId) => {
  return apiService.get({
    url: `${apiUrls.auditTemplates}/${templateId}/sections`,
  });
});
const getSummaryReport = createAsyncThunk<
  void,
  Partial<ISharedFilters> | undefined,
  { state: IRootState }
>('reports/getDashboard', async (update, { rejectWithValue, dispatch }) => {
  try {
    if (update) {
      dispatch(generalActions.setSharedFilters(update));
    }

    dispatch(getTotals());
    dispatch(getAuditCompliance());
    dispatch(getScoreBreakdown());
    dispatch(getActionsAnalytics());
    dispatch(getSectionPerformance());
    dispatch(getAuditPerformance());
    dispatch(getAuditCompletion());
    dispatch(getAuditDayOfWeekBreakdown());
    dispatch(getAuditCompletionTime());

    if (update?.templateIds) {
      dispatch(getTemplateSections(update.templateIds[0]));
    }
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

export const summaryReportActions = {
  getSummaryReport,
  getFailedCriticalItems,
  compareModal: {
    show: createAction<ComparableChart | null>('reports/compareModal/show'),
    setActiveTab: createAction<CompareType>(
      'reports/compareModal/setActiveTab'
    ),
    compare: createAction<IComparables>('reports/compareModal/compare'),
    reset: createAction('reports/compareModal/reset'),
  },
  addComparable: createAction<{
    comparable: IComparable;
    chart: ComparableChart;
  }>('reports/compareModal/toggleComparable'),
  removeComparable: createAction<{ id: string; chart: ComparableChart }>(
    'reports/compareModal/removeComparable'
  ),
  getTotals,
  getAuditCompliance,
  getScoreBreakdown,
  getActionsAnalytics,
  getSectionPerformance,
  getAuditPerformance,
  getAuditCompletion,
  getAuditDayOfWeekBreakdown,
  getAuditCompletionTime,
  setReportLoadingStatus: createAction<boolean>(
    'reports/setReportLoadingStatus'
  ),
  setSectionsPerformancePage: createAction<number>(
    'reports/setSectionsPerformancePage'
  ),
  exportReportAsPDF: createAsyncThunk<void, undefined, { state: IRootState }>(
    'reports/exportReportAsPDF',
    async (_, { getState, rejectWithValue }) => {
      const state = getState();

      const { sectionPerformance, auditPerformance, auditCompletion } =
        state.reports.summary;
      const { failedCriticalItems } = state.reports.itemAnalysis;

      try {
        const { data, headers } = await apiService.post<{
          data: 'arraybuffer';
          headers: any;
        }>({
          responseType: 'arraybuffer',
          url: `${apiUrls.reports.summary}/export-pdf`,
          body: prepareReportPDFRequestBody({
            state,
            sectionPerformanceCompare: sectionPerformance.comparables,
            auditPerformanceCompare: auditPerformance.comparables,
            auditCompletionCompare: auditCompletion.comparables,
            reportFilters: generalSelectors.getSharedFilters(state),
            failedCriticalItemsFilters: failedCriticalItems.filters,
          }),
          withHeaders: true,
        });

        const { mimeType } = getExportFileMetaData(ExportType.Pdf);

        saveFile({
          data,
          fileName:
            parseContentDispositionHeader(headers['content-disposition']) ||
            'report.pdf',
          mimeType,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  emailReport: createAsyncThunk<
    void,
    {
      emails: string[];
      userIds: string[];
    },
    { state: IRootState }
  >(
    'reports/emailReport',
    async ({ emails, userIds }, { getState, rejectWithValue }) => {
      const state = getState();

      const { sectionPerformance, auditPerformance, auditCompletion } =
        state.reports.summary;
      const { failedCriticalItems } = state.reports.itemAnalysis;

      try {
        await apiService.post({
          url: `${apiUrls.reports.summary}/email-pdf`,
          body: {
            ...prepareReportPDFRequestBody({
              state,
              sectionPerformanceCompare: sectionPerformance.comparables,
              auditPerformanceCompare: auditPerformance.comparables,
              auditCompletionCompare: auditCompletion.comparables,
              reportFilters: generalSelectors.getSharedFilters(state),
              failedCriticalItemsFilters: failedCriticalItems.filters,
            }),
            emails,
            userIds,
          },
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  toggleShareViaEmailModal: createAction<boolean>(
    'reports/toggleShareViaEmailModal'
  ),
  resetSummaryReport: createAction('reports/summary/reset'),
};
