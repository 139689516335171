import React from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  issueTypeQrCode: IssueTypeQrCode;
}

const IssueTypeQrCodesTableRowMenu: React.FC<
  React.PropsWithChildren<IProps>
> = ({ issueTypeQrCode }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const menuItems = [
    {
      name: formatMessage({ id: 'Show' }),
      onClick: () => {
        dispatch(issueTypeQrCodesActions.toggleQrCodeModal(issueTypeQrCode));
      },
    },
    {
      name: formatMessage({ id: 'Edit' }),
      onClick: () => {
        dispatch(
          issueTypeQrCodesActions.toggleAddEditModal({
            show: true,
            issueTypeQrCodeId: issueTypeQrCode.id,
          })
        );
      },
    },
    {
      name: formatMessage({ id: 'Delete' }),
      onClick: () => {
        dispatch(
          issueTypeQrCodesActions.toggleConfirmDeleteModal({
            multiple: false,
            data: issueTypeQrCode,
          })
        );
      },
    },
  ];

  return <TableRowActionsMenu menuItems={menuItems} />;
};

export default IssueTypeQrCodesTableRowMenu;
