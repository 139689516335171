import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';

import { IssueTypePage } from '@application/IssueTypes/enums/IssueTypePage';
import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { routes } from '@config';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';
import IssueTypeContentWrapper from '@src/presentation/IssueTypes/IssueType/IssueTypeContentWrapper/IssueTypeContentWrapper';
import IssueTypeQrCodes from '@src/presentation/IssueTypes/IssueTypeQrCodes/IssueTypeQrCodes';

interface IProps {}

const IssueTypeDetailsQrCodes: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate(routes.issueTypes);
    }
  }, []);

  return (
    <IssueTypeContentWrapper
      page={IssueTypePage.QrCodes}
      onIssueTypeDetailsFetch={async () => {
        const resultAction = await dispatch(
          issueTypeQrCodesActions.getQrCodes()
        );

        return !issueTypeQrCodesActions.getQrCodes.rejected.match(resultAction);
      }}
      extra={
        <>
          <SearchInput
            onEnter={(search: string) =>
              dispatch(
                issueTypeQrCodesActions.getQrCodes({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          <Button
            type="primary"
            onClick={() => {
              dispatch(
                issueTypeQrCodesActions.toggleAddEditModal({
                  show: true,
                })
              );
            }}
          >
            {formatMessage({
              id: 'CreateQrCode',
            })}
          </Button>
        </>
      }
    >
      <IssueTypeQrCodes issueTypeId={id} />
    </IssueTypeContentWrapper>
  );
};

export default IssueTypeDetailsQrCodes;
