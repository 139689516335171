import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '@repo/shared/config';

export const AuditsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  width: 100%;
  border: 1px solid ${colors.gray4};
  border-radius: 5px;
  color: ${colors.gray8};
  font-size: 12px;
  padding: 0 10px;
`;
