import styled from 'styled-components';
import { keyframes } from 'styled-components';

import { colors } from '@repo/shared/config';

import { LinkIcon } from '@components/reports/components/NumberStatsBlock/styled';

export const Container = styled.div`
  padding: 12px 16px;
  background: ${colors.gray3};
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  box-shadow: none;

  ${({ active }: { active: boolean }) =>
    active
      ? `
    cursor: pointer;
  
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 10px 20px -14px rgba(0, 0, 0, 0.4);
    }
  `
      : ''}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  height: 28px;
`;

export const ValueContainer = styled.span`
  display: flex;
  align-items: flex-start;
`;

export const Change = styled.div`
  font-size: 12px;
  margin-left: 3px;
  margin-top: 1px;
  color: ${colors.gray8};
`;

export const Value = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.gray8};
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -180px 0;
  }
  
  100% {
    background-position: 180px 0; 
  }
`;

export const Skeleton = styled.div`
  width: 120px;
  height: 12px;
  background-image: linear-gradient(
    to right,
    ${colors.gray3} 0%,
    ${colors.gray5} 20%,
    ${colors.gray5} 40%,
    ${colors.gray3} 100%
  );
  background-repeat: no-repeat;
  background-size: 200px 20px;
  margin-bottom: 7px;
  animation: ${loadingAnimation} 1.5s linear infinite;
`;

export const Icon = styled(LinkIcon)`
  top: 0;
`;
