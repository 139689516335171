import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@hooks';
import {
  Adhoc,
  Chat,
  Email,
  Phone,
  QuestionCircle,
  Search,
  Sync,
} from '@icons';
import { config, colors } from '@repo/shared/config';
import { Container, Trigger } from './styled';
import { accountActions, accountSelectors } from '@store';
import { AnalyticsEvent, LiveChatVisibility } from '@repo/shared/enums';

import Popover from '@components/shared/ant/Popover';
import HelpCenterButton from '@components/shared/Sidebar/HelpCenterPopover/HelpCenterButton/HelpCenterButton';

interface IProps {}

const HelpCenterPopover: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const isAdmin = useSelector(accountSelectors.isAdmin);

  return (
    <Popover
      placement="right"
      overlayClassName="help-center-popover"
      open={open}
      onOpenChange={(visible) => {
        setOpen(visible);
      }}
      content={
        <Container>
          <HelpCenterButton
            onClick={() => {
              dispatch(
                accountActions.setLiveChatVisibility(
                  LiveChatVisibility.maximized
                )
              );
            }}
            icon={<Chat color={colors.gray10} />}
            name={formatMessage({ id: 'LiveChat' })}
            description={formatMessage({ id: 'EngageInLiveChatForSupport' })}
          />
          <HelpCenterButton
            onClick={() => {
              window?.open(config.urls.submitTicket, '_blank');
            }}
            icon={<Search color={colors.gray10} />}
            name={formatMessage({ id: 'SubmitRequest' })}
            description={formatMessage({
              id: 'IssuesQuestionsBugReportsWarrantyClaims',
            })}
            external
          />
          <HelpCenterButton
            onClick={() => {
              window.open(config.urls.support, '_blank');
            }}
            icon={<QuestionCircle color={colors.gray10} />}
            name={formatMessage({ id: 'HelpCenter' })}
            description={formatMessage({ id: 'OurKnowledgeBase' })}
            external
          />
          {isAdmin && (
            <>
              <HelpCenterButton
                onClick={() => {
                  window?.open(config.urls.interactiveDemo, '_blank');

                  dispatch(
                    accountActions.sendAnalyticsEvent(
                      AnalyticsEvent.LiveConsultationClicked
                    )
                  );
                }}
                icon={<Adhoc color={colors.gray10} size={20} />}
                name={formatMessage({ id: 'BookDemonstration' })}
                external
              />
              <HelpCenterButton
                onClick={() => {
                  dispatch(accountActions.toggleFormsDigitizationModal(true));
                  setOpen(false);
                }}
                icon={<Sync color={colors.gray10} />}
                name={formatMessage({ id: 'DigitizeYourAuditForms' })}
                description={formatMessage({
                  id: 'ConvertYourAuditFormsIntoTemplates',
                })}
              />
            </>
          )}
          <HelpCenterButton
            onClick={() => {
              window.location.href = `mailto:${config.urls.supportEmail}`;
            }}
            icon={<Email color={colors.gray10} />}
            name={config.urls.supportEmail}
          />
          <HelpCenterButton
            onClick={() => {
              window.location.href = `tel::${config.urls.supportPhone}`;
            }}
            icon={<Phone color={colors.gray10} />}
            name={config.urls.supportPhone}
            description={formatMessage({ id: 'Support' })}
          />
        </Container>
      }
    >
      <Trigger>{children}</Trigger>
    </Popover>
  );
};

export default HelpCenterPopover;
