import { IRootState } from '@src/core/frameworks/redux';
import {
  issueEventsEntityAdapter,
  issuesEntityAdapter,
} from '@application/Issues/store/entityAdapters';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import {
  CompletedIssuesLateStatus,
  IssuesFilters,
  PendingIssuesDueDateStatus,
} from '@application/Issues/models/IssuesFilters';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import {
  Issue,
  IssueDetails,
  IssueDetailsAction,
  IssueDetailsAudit,
} from '@domain/Issues/models/Issue';
import { IPagingMeta } from '@repo/shared/types';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { AssignmentType, DayOfWeek } from '@repo/shared/enums';
import {
  IssueClosedEvent,
  IssueEvent,
  IssueEventType,
} from '@domain/Issues/models/IssueEvent';
import { IssueQuestion } from '@domain/Issues/models/IssueQuestion';
import { IssueContext } from '@domain/Issues/models/IssueContext';
import { getAuditObjectName } from '@repo/shared/utils/auditObjects';
import { accountSelectors } from '@store';
import { AssignUsersToIssuesModalState } from '@application/Issues/models/AssignUsersToIssuesModalState';
import { IssueTypeContactFieldType } from '@domain/IssueTypes/models/IssueType';
import { ItemsGroup } from '@application/Audits/enums/ItemsGroup';
import { date } from '@utils';
import { getInitialIssuesFilters } from '@application/Issues/store/issuesReducer';

const getPage = (state: IRootState): IssuesPage | null =>
  state.issues.issuesList.page;

const getIssues = createSelector(
  [
    (state: IRootState): EntityState<Issue, string> =>
      state.issues.issuesList.data,
    (state: IRootState): IPagingMeta => state.issues.issuesList.meta,
    (state: IRootState): boolean => state.issues.issuesList.loading,
    (state: IRootState): string | null => state.issues.issuesList.error,
  ],
  (data, meta, loading, error) => ({
    data: issuesEntityAdapter.getSelectors().selectAll(data),
    meta,
    loading,
    error,
  })
);

const getIssuesDictionary = (state: IRootState) =>
  issuesEntityAdapter
    .getSelectors()
    .selectEntities(state.issues.issuesList.data);

const getConfirmDeleteIssueTypeFieldsModalState = (
  state: IRootState
): DeleteModalState<Issue> => state.issues.confirmDeleteModal;

const getFilters = createSelector(
  [
    getPage,
    (state: IRootState): Record<IssuesPage, IssuesFilters> =>
      state.issues.issuesList.filters,
  ],
  (page, filters) => (page !== null ? filters[page] : getInitialIssuesFilters())
);

const getMyIssues = createSelector(
  getIssues,
  ({ loading, data, meta, error }) => {
    return {
      loading,
      issues: data.reduce<{
        list: ItemsGroup[];
        dictionary: Record<ItemsGroup, Issue[]>;
      }>(
        (acc, action) => {
          const a = acc;

          let group;

          const today = date().startOf('day');

          if (today.isAfter(action.dueDate?.localTime, 'day')) {
            group = ItemsGroup.Past;
          } else if (today.isSame(action.dueDate?.localTime, 'day')) {
            group = ItemsGroup.Today;
          } else if (today.isSame(action.dueDate?.localTime, 'week')) {
            switch (date(action.dueDate?.localTime).weekday()) {
              case DayOfWeek.Monday:
                group = ItemsGroup.Monday;
                break;
              case DayOfWeek.Tuesday:
                group = ItemsGroup.Tuesday;
                break;
              case DayOfWeek.Wednesday:
                group = ItemsGroup.Wednesday;
                break;
              case DayOfWeek.Thursday:
                group = ItemsGroup.Thursday;
                break;
              case DayOfWeek.Friday:
                group = ItemsGroup.Friday;
                break;
              case DayOfWeek.Saturday:
                group = ItemsGroup.Saturday;
                break;
              case DayOfWeek.Sunday:
                group = ItemsGroup.Sunday;
                break;
            }
          } else {
            group = ItemsGroup.Upcoming;
          }

          if (group !== undefined) {
            if (!a.dictionary[group]) {
              a.dictionary[group] = [];
              a.list.push(group);
            }

            a.dictionary[group].push(action);
          }

          return a;
        },
        {
          list: [],
          dictionary: {} as Record<ItemsGroup, Issue[]>,
        }
      ),
      meta,
      error,
    };
  }
);

const getSelectedFiltersQty = createSelector(getFilters, (filters) => {
  let selected = 0;

  if (filters === null) {
    return selected;
  }

  if (filters.priority !== null) {
    selected += 1;
  }

  if (filters.assignment !== AssignmentType.all) {
    selected += 1;
  }

  if (filters.auditObjectIds.length > 0) {
    selected += 1;
  }

  if (filters.issueTypeIds.length > 0) {
    selected += 1;
  }

  if (filters.startDate !== null || filters.endDate !== null) {
    selected += 1;
  }

  if (filters.reason !== null) {
    selected += 1;
  }

  if (
    filters.dueDateStatus !== PendingIssuesDueDateStatus.All ||
    filters.lateStatus !== CompletedIssuesLateStatus.All
  ) {
    selected += 1;
  }

  return selected;
});

const isFiltersModalOpened = (state: IRootState): boolean =>
  state.issues.issuesList.showFiltersModal;

const isCreateModalOpened = (state: IRootState): boolean =>
  state.issues.issuesList.showCreateIssueModal;

const getIssueDetailsEvents = createSelector(
  [
    (state: IRootState): EntityState<IssueEvent, string> =>
      state.issues.issueDetails.events.data,
    (state: IRootState): IPagingMeta => state.issues.issueDetails.events.meta,
    (state: IRootState): boolean => state.issues.issueDetails.events.loading,
    (state: IRootState): string | null =>
      state.issues.issueDetails.events.error,
  ],
  (data, meta, loading, error) => ({
    data: issueEventsEntityAdapter.getSelectors().selectAll(data),
    meta,
    loading,
    error,
  })
);

const getLastClosedEvent = createSelector(
  getIssueDetailsEvents,
  ({ data, loading }) => ({
    loading,
    event: data
      .reverse()
      .find((event) => event.type === IssueEventType.IssueClosed) as
      | IssueClosedEvent
      | undefined,
  })
);

const getDetailsIssue = (state: IRootState): IssueDetails | null =>
  state.issues.issueDetails.issue;

const getIssueDetailsLoading = (state: IRootState): boolean =>
  state.issues.issueDetails.loading;

const getIssueDetailsQuestions = (state: IRootState): IssueQuestion[] =>
  state.issues.issueDetails.questions;

const getIssueContactInformationFields = createSelector(
  getDetailsIssue,
  (issue) => {
    if (!issue) {
      return [];
    }

    return issue.contactInformationFields.filter(({ value }) => !!value);
  }
);

const getIssueCreatedByContactInformation = createSelector(
  getIssueContactInformationFields,
  (fields) => {
    let name;
    let email;
    let phone;

    for (let i = 0; i < fields.length; i++) {
      const { type, value } = fields[i];

      switch (type) {
        case IssueTypeContactFieldType.Name:
          name = value;
          break;
        case IssueTypeContactFieldType.Email:
          email = value;
          break;
        case IssueTypeContactFieldType.Phone:
          phone = value;
          break;
      }
    }

    return name || email || phone || null;
  }
);

const getIssueDetailsAudits = (state: IRootState): IssueDetailsAudit[] =>
  state.issues.issueDetails.audits;

const getIssueDetailsActions = (state: IRootState): IssueDetailsAction[] =>
  state.issues.issueDetails.correctiveActions;

const getIssueDetailsError = (state: IRootState): string | null =>
  state.issues.issueDetails.error;

const getReopenIssuesModalState = (state: IRootState): null | string[] =>
  state.issues.reopenIssuesModal;

const getCloseIssuesModalState = (state: IRootState): null | string[] =>
  state.issues.closeIssuesModal;

const getIssueContext = createSelector(
  [
    (
      state: IRootState
    ): {
      loading: boolean;
      error: string | null;
      data: IssueContext | null;
    } => state.issues.issueContext,
    accountSelectors.getLocale,
  ],
  ({ loading, data, error }, locale) => ({
    auditObjectName: data
      ? getAuditObjectName({
          usagePurpose: data.usagePurpose,
          usagePurposeObjectName: data.usagePurposeObjectName,
          locale,
        }).single
      : 'HistoryAudit Object',
    contextAuditObjects: data?.auditObjects || [],
    loading,
    error,
  })
);

const getEventsLastUpdateDateTime = (state: IRootState): string | null =>
  state.issues.issueDetails.events.lastUpdateDateTimeUtc;

const getAssignUsersToIssuesModalState = (
  state: IRootState
): AssignUsersToIssuesModalState => state.issues.assignUsersToIssuesModal;

const getIssueDetailsId = (state: IRootState): string | null =>
  state.issues.issueDetails.id;

export const issuesSelectors = {
  getPage,
  getIssues,
  getMyIssues,
  getIssueDetailsId,
  getIssuesDictionary,
  getFilters,
  getSelectedFiltersQty,
  isFiltersModalOpened,
  isCreateModalOpened,
  getConfirmDeleteIssueTypeFieldsModalState,
  getDetailsIssue,
  getIssueDetailsEvents,
  getLastClosedEvent,
  getIssueDetailsLoading,
  getIssueDetailsQuestions,
  getIssueDetailsAudits,
  getIssueDetailsActions,
  getIssueDetailsError,
  getReopenIssuesModalState,
  getCloseIssuesModalState,
  getIssueContext,
  getEventsLastUpdateDateTime,
  getAssignUsersToIssuesModalState,
  getIssueContactInformationFields,
  getIssueCreatedByContactInformation,
};
