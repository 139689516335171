import { createEntityAdapter } from '@reduxjs/toolkit';

import { Issue } from '@domain/Issues/models/Issue';
import { IssueEvent } from '@domain/Issues/models/IssueEvent';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';

export const issuesEntityAdapter = createEntityAdapter<Issue>();

export const issueEventsEntityAdapter = createEntityAdapter<IssueEvent>();

export const issuesQrCodesEntityAdapter =
  createEntityAdapter<IssueTypeQrCode>();
