import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { config } from '@repo/shared/config';
import { Audit } from '@domain/Audits/models/Audit';

import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import MyAudit from '@presentation/Audits/MyAuditsList/MyAudit/MyAudit';
import GroupedList from '@components/shared/GroupedList/GroupedList';

interface IProps {}

const MyAuditsList: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, audits, meta, error } = useSelector(
    auditsSelectors.getMyAudits
  );

  useEffect(() => {
    return () => {
      dispatch(auditsActions.resetListData());
    };
  }, []);

  return (
    <GroupedList<Audit>
      list={audits.list}
      dictionary={audits.dictionary}
      loading={loading}
      meta={meta}
      error={error}
      empty={
        <EmptyTable
          text={formatMessage({
            id: 'CurrentlyYouHaveNoAssignedAudits',
          })}
          howItWorksUrl={config.urls.auditsSupport}
        />
      }
      onScrollStop={(pageNumber) => {
        dispatch(
          auditsActions.getAudits({
            pageNumber,
          })
        );
      }}
      Item={MyAudit}
    />
  );
};

export default MyAuditsList;
