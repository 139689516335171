import { AuditsFiltersFormValues } from '@application/Audits/models/AuditsFiltersFormValues';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import { date, removeUndefinedAndNullProps } from '@utils';
import { config } from '@repo/shared/config';
import {
  AuditStartDateStatus,
  PendingAuditsStatusSelectOption,
} from '@repo/shared/enums';

export function transformFormValuesToAuditsFiltersRequestParams({
  score,
  range,
  pendingAuditsStatus,
  auditObject,
  completedBy,
  assignedTo,
  assignment,
  schedule,
  ...values
}: AuditsFiltersFormValues): Partial<AuditsFilters> {
  const additional: Partial<AuditsFilters> = {
    inProgress: null,
    startDateStatus: AuditStartDateStatus.All,
  };

  if (pendingAuditsStatus === PendingAuditsStatusSelectOption.InProgress) {
    additional.inProgress = true;
  }

  if (pendingAuditsStatus === PendingAuditsStatusSelectOption.ReadyToStart) {
    additional.startDateStatus = AuditStartDateStatus.Ready;
  }

  if (pendingAuditsStatus === PendingAuditsStatusSelectOption.Upcoming) {
    additional.startDateStatus = AuditStartDateStatus.Upcoming;
  }

  return {
    ...values,
    ...additional,
    ...removeUndefinedAndNullProps({
      ...values,
      pendingAuditsStatus,
      scoreMin: score?.[0],
      scoreMax: score?.[1],
    }),
    assignment,
    completedBy: completedBy.userId,
    completedByGroup: completedBy.userGroupId,
    assignedTo: assignedTo.userId,
    assignedToGroup: assignedTo.userGroupId,
    auditSchedulesIds: schedule.schedulesIds,
    schedulePlansIds: schedule.schedulePlansIds,
    pageNumber: 1,
    startDate: range?.[0]
      ? date(range[0]).startOf('day').format(config.apiDateFormat)
      : null,
    endDate: range?.[1]
      ? date(range[1]).startOf('day').format(config.apiDateFormat)
      : null,
    auditObjectIds: auditObject.auditObjectIds,
    auditObjectGroupIds: auditObject.auditObjectGroupIds,
    templateId: values.templateId === -1 ? null : values.templateId,
  };
}
