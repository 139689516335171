import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Content, AvatarContainer, ProfileSection } from './styled';
import { accountSelectors } from '@store';
import { useAppSelector } from '@hooks';

import Avatar from '../../shared/ant/Avatar';
import PageHeader from '../../shared/ant/PageHeader';
import ChangePasswordModal from './ChangePasswordModal';

const EditProfile = () => {
  const { formatMessage } = useIntl();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const user = useAppSelector(accountSelectors.getCurrentUser);

  if (!user) {
    return null;
  }

  return (
    <PageHeader title={formatMessage({ id: 'MyProfile' })}>
      <Content>
        <AvatarContainer>
          <Avatar user={user} size={80} />
        </AvatarContainer>
        <ProfileSection>
          <h3>{formatMessage({ id: 'Email' })}</h3>
          <span>{user.email}</span>
        </ProfileSection>
        <ProfileSection>
          <h3>{formatMessage({ id: 'Password' })}</h3>
          <button
            type="button"
            onClick={() => setShowChangePasswordModal(true)}
          >
            {formatMessage({ id: 'ChangePassword' })}
          </button>
        </ProfileSection>
      </Content>
      <ChangePasswordModal
        visible={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      />
    </PageHeader>
  );
};

export default EditProfile;
