import React from 'react';

import { Button } from './styled';

interface IProps {
  selected?: boolean;
  icon?: React.ReactNode;
  selectedIconColor?: string;
  e2eDataAttr?: string;
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

const StatusButton: React.FC<React.PropsWithChildren<IProps>> = ({
  selected,
  icon,
  selectedIconColor,
  e2eDataAttr,
  onClick,
  label,
  disabled,
}) => {
  return (
    <Button
      selected={selected}
      selectedIconColor={selectedIconColor}
      data-e2e={e2eDataAttr}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {label}
    </Button>
  );
};

export default StatusButton;
