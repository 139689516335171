import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';

import { settingsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { notification } from '@utils';
import { FileSourceType, MimeType } from '@repo/shared/enums';
import { intl } from '@repo/shared/components/IntlGlobalProvider';

import UploadFiles from '@components/shared/UploadFiles/UploadFiles';
import Integration from '../Integration/Integration';

const ImportSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [processing, setProcessing] = useState(false);

  return (
    <Integration
      title={formatMessage({ id: 'ImportSettings' })}
      description={formatMessage({
        id: 'UploadFileForDataImport',
      })}
      showDelete={false}
      content={
        <Spin spinning={processing}>
          <UploadFiles
            source={FileSourceType.AuditSignature}
            onUploadStarted={() => {
              setProcessing(true);
            }}
            onUploadFinished={async (files) => {
              const resultAction = await dispatch(
                settingsActions.integrations.startDataImport(files[0])
              );

              if (
                settingsActions.integrations.startDataImport.fulfilled.match(
                  resultAction
                )
              ) {
                notification.success({
                  message: formatMessage({
                    id: 'FileHasBeenSuccessfullyImported',
                  }),
                });
              } else {
                notification.error({
                  message: formatMessage({
                    id: 'ErrorOccurredWhileImportingFile',
                  }),
                  description: resultAction.payload,
                });
              }

              setProcessing(false);
            }}
            onUploadFailed={() => {
              setProcessing(false);

              notification.error({
                message: formatMessage({
                  id: 'ErrorOccurredWhileImportingFile',
                }),
                description: `${formatMessage({
                  id: 'IncorrectFileType',
                })} ${intl.formatMessage(
                  { id: 'SupportedFileTypes' },
                  {
                    fileTypes: 'CSV',
                  }
                )}`,
              });
            }}
            active
            allowedMimeTypes={[MimeType.csv]}
            showUploadList={false}
          />
        </Spin>
      }
    />
  );
};

export default ImportSettings;
