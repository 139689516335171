import { BaseApiClient } from '@repo/shared/api';
import { IPagedResponse, ITableFilters } from '@repo/shared/types';
import { ActionTemplate } from '@domain/ActionTemplates/models/ActionTemplate';
import { CreateActionTemplateDto } from '@infrastructure/ActionTemplates/models/CreateActionTemplateDto';
import { UpdateActionTemplateDto } from '@infrastructure/ActionTemplates/models/UpdateActionTemplateDto';

class ActionTemplatesApiClient extends BaseApiClient {
  public async getActionTemplates(filters: ITableFilters) {
    return this._apiService.get<IPagedResponse<ActionTemplate>>({
      url: 'library/actions/detailed',
      query: {
        ...filters,
      },
    });
  }

  public async getActionTemplate(id: string) {
    return this._apiService.get<ActionTemplate>({
      url: `library/actions/${id}`,
    });
  }

  public async createActionTemplate(dto: CreateActionTemplateDto) {
    return this._apiService.post<{ id: string }>({
      url: 'library/actions',
      body: {
        ...dto,
      },
    });
  }

  public async updateActionTemplate(dto: UpdateActionTemplateDto) {
    return this._apiService.put({
      url: 'library/actions',
      body: {
        ...dto,
      },
    });
  }

  public async deleteActionTemplates(ids: string[]) {
    return this._apiService.delete({
      url: 'library/actions',
      body: {
        ids,
      },
    });
  }
}

export default ActionTemplatesApiClient;
