import React, { memo } from 'react';
import TourPopoverElement from '@components/account/SelfGuidedTour/TourPopover/TourPopoverElement';
import Measure from 'react-measure';
import { ISpotlightBounds } from '@repo/shared/types';
import isEqual from 'lodash/isEqual';

interface IProps {
  onMeasure: (bounds: ISpotlightBounds) => void;
  overlayStyle?: {
    marginTop?: number;
    marginLeft?: number;
    height?: number;
    width?: number;
  };
}

const TourPopoverMeasure = memo<React.PropsWithChildren<IProps>>(
  ({ children, onMeasure, overlayStyle }) => {
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          if (contentRect.bounds) {
            onMeasure({
              top:
                window.scrollY +
                contentRect.bounds.top +
                (overlayStyle?.marginTop || 0) -
                10,
              left:
                contentRect.bounds.left + (overlayStyle?.marginLeft || 0) - 10,
              height: overlayStyle?.height
                ? overlayStyle.height
                : contentRect.bounds.height + 20,
              width: overlayStyle?.width
                ? overlayStyle.width
                : contentRect.bounds.width + 20,
            });
          }
        }}
      >
        {({ measureRef, measure }) => (
          <TourPopoverElement measure={measure} ref={measureRef}>
            {children}
          </TourPopoverElement>
        )}
      </Measure>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.overlayStyle, nextProps.overlayStyle)
);

export default TourPopoverMeasure;
