import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  margin-right: 65px;
  background: ${colors.gray2};
  border-radius: 4px;
  padding: 12px 12px 7px;
  max-width: 800px;
  margin-bottom: 12px;

  .ant-select-selector {
    background: ${colors.white}!important;
  }

  input {
    background: ${colors.white}!important;
  }
`;

export const Actions = styled.div`
  position: absolute;
  right: -65px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  & > * {
    margin: 0 -5px;
  }
`;

export const ActionBtn = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0 5px 0;
  width: 16px;
  height: 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.gray7};
    stroke-width: 3px;
    transition: color 0.2s ease-in-out;
  }

  &:disabled {
    cursor: default;

    svg {
      color: ${colors.gray5};
    }
  }
`;

export const ErrorListWrapper = styled.div`
  color: ${colors.red};
  margin-top: 8px;
`;

export const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -8px;

  & > * {
    margin: 0 8px 5px !important;
  }

  .ant-form-item-label {
    white-space: nowrap;
    text-align: end;
    padding: 0;
  }

  .ant-row.ant-form-item-row {
    flex-direction: row;
    align-items: center;
  }

  .ant-form-item-control {
    min-width: 0;
  }

  .ant-form-item-label > label {
    height: 32px;
    margin-bottom: 0 !important;
    margin-right: 10px;

    &::after {
      display: block;
      content: ':';
      position: relative;
      margin-block: 0;
      margin-inline-start: 2px;
      margin-inline-end: 8px;
    }
  }
`;

export const AddButton = styled.button`
  display: block;
  background: none;
  padding: 0;
  border: 0;
  font-size: 14px;
  color: ${colors.blue2};
`;
