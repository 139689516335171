import React from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { notification } from '@utils';
import { AuditsPage } from '@application/Audits/models/AuditsPage';

import ConfirmExpireModal from '@components/shared/ConfirmExpireModal';

interface IProps {
}

const ConfirmExpireAuditModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <ConfirmExpireModal
      onExpireButtonClick={async (ids) => {
        const resultAction = await dispatch(
          auditsActions.expirePendingAudits(ids)
        );

        if (auditsActions.expirePendingAudits.fulfilled.match(resultAction)) {
          dispatch(auditsActions.getAudits(null));
          dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
          return true;
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileExpiringAudit' }),
            description: resultAction.payload,
          });

          return false;
        }
      }}
      descLangId="AreYouSureYouWantToExpireAudit"
    />
  );
};

export default ConfirmExpireAuditModal;
