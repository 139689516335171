import React from 'react';
import { useIntl } from 'react-intl';

import { IssueStatus } from '@domain/Issues/models/Issue';

const langIdsDictionary = {
  [IssueStatus.Opened]: 'Opened',
  [IssueStatus.Closed]: 'Closed',
};

interface IProps {
  status: IssueStatus;
}

const IssueStatusName: React.FC<React.PropsWithChildren<IProps>> = ({
  status,
}) => {
  const { formatMessage } = useIntl();

  return <>{formatMessage({ id: langIdsDictionary[status] })}</>;
};

export default IssueStatusName;
