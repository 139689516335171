import { Issue } from '@domain/Issues/models/Issue';

export function constructIssueName(issue: Issue) {
  const nameParts: string[] = [];

  if (issue.number) {
    nameParts.push(issue.number);
  }

  if (issue.name) {
    nameParts.push(issue.name);
  }

  return nameParts.join(' - ');
}
