import React from 'react';

import { Container } from './styled';
import { IRating } from '@repo/shared/types';
import { colors } from '@repo/shared/config';

import Star from './Star';

interface IProps {
  rating: IRating | undefined;
  onClick?: (newRating: IRating) => void;
}

const RATING_OPTIONS = [1, 2, 3, 4, 5].reverse();

const Stars: React.FC<React.PropsWithChildren<IProps>> = ({
  onClick,
  rating,
}) => {
  return (
    <Container>
      {RATING_OPTIONS.map((ratingValue) => {
        return (
          <Star
            key={ratingValue}
            color={
              rating !== undefined && ratingValue <= rating
                ? colors.yellow
                : colors.gray5
            }
            onClick={
              !!onClick
                ? () => {
                    onClick(ratingValue as IRating);
                  }
                : undefined
            }
          />
        );
      })}
    </Container>
  );
};

export default Stars;
