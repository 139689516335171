import React from 'react';
import { SelectProps } from 'antd/es/select';

import EntitySelectAsync from './EntitySelectAsync';
import { apiUrls } from '@src/config/apiUrls';

interface IProps extends SelectProps<any> {
  extraOptions?: { id: string | number | null; name: string }[];
}

const CompletedAuditsSelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef((props, _) => {
    return (
      <EntitySelectAsync
        {...props}
        apiUrl={`${apiUrls.audit}/complete/concise`}
      />
    );
  });

export default CompletedAuditsSelect;
