import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '@components/shared/ActionsModal/styled';
import { generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';

interface IProps {}

const IssueTypeQrCodesActionsModal: React.FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const issueQrCodesDictionary = useSelector(
    issueTypeQrCodesSelectors.getIssueQrCodesDictionary
  );

  let issueQrCode =
    selectedTableRowKeys.length === 1
      ? issueQrCodesDictionary[selectedTableRowKeys[0]]
      : null;

  return (
    <ActionsModal>
      {() => (
        <>
          {!!issueQrCode && (
            <>
              <Action
                onClick={() => {
                  dispatch(
                    issueTypeQrCodesActions.toggleQrCodeModal(issueQrCode!)
                  );
                }}
              >
                {formatMessage({ id: 'Show' })}
              </Action>
              <Action
                onClick={() => {
                  dispatch(
                    issueTypeQrCodesActions.toggleAddEditModal({
                      show: true,
                      issueTypeQrCodeId: issueQrCode?.id!,
                    })
                  );
                }}
              >
                {formatMessage({ id: 'Edit' })}
              </Action>
            </>
          )}
          <Action
            onClick={() => {
              dispatch(
                issueTypeQrCodesActions.toggleConfirmDeleteModal(
                  selectedTableRowKeys.length > 1 || !issueQrCode
                    ? {
                        multiple: true,
                        data: selectedTableRowKeys,
                      }
                    : {
                        multiple: false,
                        data: issueQrCode,
                      }
                )
              );
            }}
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default IssueTypeQrCodesActionsModal;
