import React from 'react';
import { useIntl } from 'react-intl';

import { accountSelectors, usersActions, usersSelectors } from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import PageHeader from '@components/shared/ant/PageHeader';
import SearchInput from '@components/shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';
import { useSelector } from 'react-redux';

const JobTitlesHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [canManageUsers, canManageAuditObjects] = usePermission([
    Permission.CanManageUsers,
    Permission.CanManageAuditObjects,
  ]);
  const { isJobTitlesAndAccessReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const filters = useSelector(usersSelectors.jobTitles.getFilters);

  return (
    <PageHeader
      title={formatMessage({ id: 'JobTitles' })}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                usersActions.jobTitles.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isJobTitlesAndAccessReadonly &&
            canManageUsers &&
            canManageAuditObjects && (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(
                    usersActions.jobTitles.toggleAddEditModal({
                      show: true,
                    })
                  );
                }}
                data-e2e={e2eTestElements.users.jobTitles.openAddEditModal}
              >
                {formatMessage({
                  id: 'NewJobTitle',
                })}
              </Button>
            )}
        </>
      }
    />
  );
};

export default JobTitlesHeader;
