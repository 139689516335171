import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  margin-bottom: 8px;

  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Label = styled.div`
  font-size: 12px;
  width: 120px;
  color: ${colors.gray8};
  padding-right: 16px;
  line-height: 16px;
  margin-top: 4px;
`;

export const Content = styled.div`
  width: 570px;
`;
