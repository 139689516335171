import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const SubHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 35px;
`;

export const FilePreviews = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 0;
`;

export const AttachLabel = styled.div`
  display: block;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  color: ${colors.blue2};
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
