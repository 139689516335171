import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import { AuditsFiltersFormValues } from '@application/Audits/models/AuditsFiltersFormValues';
import { date } from '@utils';

export function transformAuditsFiltersToFormValues({
  pendingAuditsStatus,
  auditObjectIds,
  auditObjectGroupIds,
  completedBy,
  completedByGroup,
  assignedTo,
  assignedToGroup,
  auditSchedulesIds,
  schedulePlansIds,
  templateId,
  scoreMin,
  scoreMax,
  startDate,
  endDate,
  ...filters
}: AuditsFilters): AuditsFiltersFormValues {
  return {
    ...filters,
    pendingAuditsStatus:
      pendingAuditsStatus === null ? -1 : pendingAuditsStatus,
    auditObject: {
      auditObjectIds,
      auditObjectGroupIds,
    },
    completedBy: {
      userId: completedBy,
      userGroupId: completedByGroup,
    },
    assignedTo: {
      userId: assignedTo,
      userGroupId: assignedToGroup,
    },
    schedule: {
      schedulesIds: auditSchedulesIds,
      schedulePlansIds: schedulePlansIds,
    },
    templateId: templateId === null ? -1 : templateId,
    score: [scoreMin || 0, scoreMax || 100],
    range: startDate && endDate ? [date(startDate), date(endDate)] : null,
  };
}
