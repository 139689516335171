import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { GetExpiredAuditDetailsDto } from '@infrastructure/Audits/models/GetExpiredAuditDto';

export function transformExpiredAuditDetailsDtoToAudit(
  dto: GetExpiredAuditDetailsDto
): AuditDetails {
  return {
    ...dto,
    startedBy: null,
    startedAtInformation: null,
    dueInDays: 0,
    completedBy: null,
    completedAtInformation: null,
    pendingActionsCount: 0,
    completedActionsCount: 0,
    expiredActionsCount: 0,
    score: null,
    scoreColor: null,
    scoreChange: null,
    scoreLabel: null,
    canStartAudit: false,
  };
}
