import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { routes } from '@config';

const pageToRouteMap = {
  [ActionsPage.My]: {
    list: routes.myActions,
    single: routes.myAction,
  },
  [ActionsPage.Pending]: {
    list: routes.pendingActions,
    single: routes.pendingAction,
  },
  [ActionsPage.Completed]: {
    list: routes.completedActions,
    single: routes.completedAction,
  },
  [ActionsPage.Expired]: {
    list: routes.expiredActions,
    single: routes.expiredAction,
  },
};

export function getRoutesFromActionPage(page: ActionsPage) {
  return pageToRouteMap[page];
}
