import styled from 'styled-components';
import AntForm, { FormProps } from 'antd/es/form';
import { colors } from '@repo/shared/config';

const StyledForm = styled(AntForm)<any>`
  .ant-form-item {
    display: block;
    margin-bottom: 14px;

    &.hide-text-error-message {
      .ant-form-item-explain {
        display: none;
      }
    }

    .ant-form-item-label {
      padding: 0 0 1px 0 !important;

      label {
        font-size: 12px;
        height: auto;
        margin-bottom: 6px;
        color: ${colors.gray8};
        white-space: normal;
        text-align: left;
        line-height: 1.4;
        display: flex;
        align-items: center;

        &:before {
          margin-top: 3px;
        }

        &:after {
          display: none;
        }
      }
    }

    .ant-form-item-control-input-content > .ant-input,
    .ant-form-item-control-input-content > .ant-input-password {
      border-radius: 5px;
      background: ${colors.gray3};
      border-color: ${colors.gray3};
      padding: 4px 10px;

      &::placeholder,
      & > input::placeholder {
        color: ${colors.gray8};
      }

      &.ant-input-password {
        & > input {
          background: ${colors.gray3};
          border-radius: 0;
        }
      }

      &:hover {
        background: ${colors.white};
        border-color: ${colors.white};
        box-shadow: 0 0 4px ${colors.blue2};

        &.ant-input-password {
          & > input {
            background: ${colors.white};
          }
        }
      }

      &.ant-input-disabled,
      &[disabled] {
        background: ${colors.gray2};
        border-color: ${colors.gray2};

        &:hover {
          background: ${colors.gray2};
          border-color: ${colors.gray2};
          box-shadow: none;

          &.ant-input-password {
            & > input {
              background: ${colors.gray2};
            }
          }
        }
      }

      &:focus,
      &.ant-input-affix-wrapper-focused {
        border-color: ${colors.blue2};
        box-shadow: 0 0 4px ${colors.blue2};
        background: ${colors.white};

        input {
          background: ${colors.white};
        }
      }

      &.ant-input-lg {
        padding: 8.48px 12px;
      }
    }

    .ant-input-suffix,
    .ant-input-prefix {
      width: 16px;
      color: ${colors.gray6};
    }

    &.ant-form-item-has-error {
      .ant-form-item-control-input-content > .ant-input,
      .ant-form-item-control-input-content > .ant-input-password {
        border-color: ${colors.red};

        &:hover {
          box-shadow: 0 0 4px ${colors.red};
        }

        &:focus {
          border-color: ${colors.red};
          box-shadow: 0 0 4px ${colors.red};
        }
      }
    }

    .ant-form-item-explain {
      line-height: 19.2px;
      margin-top: 2px;
      min-height: auto;
      font-size: 13px;
      margin-bottom: 4px;
    }

    &.ant-form-item-has-error .ant-input,
    &.ant-form-item-has-error .ant-input-affix-wrapper {
      background: ${colors.gray3};
    }

    &.ant-form-item-has-error .ant-input:hover,
    &.ant-form-item-has-error .ant-input-affix-wrapper:hover,
    &.ant-form-item-has-error .ant-input:hover input,
    &.ant-form-item-has-error .ant-input-affix-wrapper:hover input {
      background: ${colors.white};
    }
  }
`;

export const FormTitle = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0;
`;

function Form<T>(props: FormProps<T>) {
  return <StyledForm {...props} />;
}

Form.Item = AntForm.Item;
Form.List = AntForm.List;
Form.ErrorList = AntForm.ErrorList;
Form.useForm = AntForm.useForm;

export default Form;
