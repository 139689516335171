import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { accountSelectors } from '@store';
import { date, getColumnSortProperties } from '@utils';
import { AuditName, ButtonLink, CountButton, Mark } from './styled';
import { Permission } from '@repo/shared/enums';
import { Audit } from '@domain/Audits/models/Audit';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { useAppDispatch, usePermission } from '@hooks';
import { e2eTestElements, routes } from '@config';
import { config } from '@repo/shared/config';
import { IConcise, ZonedDateTime as IZonedDateTime } from '@repo/shared/types';
import { ScheduleConcise } from '@domain/Schedules/models/ScheduleConcise';
import { AuditStatus } from '@domain/Audits/enums/AuditStatus';
import { getAuditStatusFromAuditsPage } from '@application/Audits/utils/getAuditStatusFromAuditsPage';
import { delay } from '@repo/shared/utils';

import { SampleBadge } from '@components/shared/SampleBadgedName';
import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import AuditsActionsMenu from './AuditsActionsMenu';
import AuditStatusName from '../AuditStatusName';
import DueDateTableCell from '@components/shared/DateTableCellWithDueLabel/DueDateTableCell';
import ConciseListStr from '@components/shared/ConciseListStr/ConciseListStr';
import ExpiredDateWithBadge from '@presentation/Audits/ExpiredDateWithBadge/ExpiredDateWithBadge';
import CompleteDateTableCell from '@components/shared/DateTableCellWithDueLabel/CompleteDateTableCell';
import ScoreChange from '@components/shared/ScoreChange/ScoreChange';
import ZonedDateTime from '@components/shared/ZonedDateTime';

interface IProps {
  page: AuditsPage;
}

const AuditsTable: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [canManageAudits, canScheduleAudits] = usePermission([
    Permission.CanManageAudits,
    Permission.CanScheduleAudits,
  ]);
  const { loading, meta, data, error } = useSelector(auditsSelectors.getAudits);
  const filters = useSelector(auditsSelectors.getFilters);
  const viewAuditModal = useSelector(auditsSelectors.getViewAuditModalState);
  const filtersModalOpened = useSelector(auditsSelectors.isFiltersModalOpened);
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  const getAssignmentColumn = useCallback(
    () => ({
      title: formatMessage({ id: 'AssignedTo' }),
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      ...getColumnSortProperties(
        filters.orderByDirection,
        filters.orderBy === 'assignedTo'
      ),
      render(_: IConcise[], audit: Audit) {
        return (
          <ConciseListStr items={audit.assignees} showExclamationPlaceholder />
        );
      },
    }),
    [JSON.stringify(filters)]
  );

  return (
    <Table<Audit>
      onPageChange={(update) => {
        dispatch(auditsActions.getAudits(update));
      }}
      onUnmount={() => {
        dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
        dispatch(auditsActions.resetListData());
      }}
      e2eDataAttr={e2eTestElements.audits.table}
      canSelectRow={canManageAudits}
      loading={loading}
      meta={meta}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          onCell: (audit) => ({
            onClick: async (e) => {
              e.stopPropagation();
              e.preventDefault();

              if (filtersModalOpened) {
                dispatch(auditsActions.toggleFiltersModal(false));
              }

              if (viewAuditModal.opened) {
                dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
                await delay(250);
              }

              dispatch(
                auditsActions.toggleViewAuditModal({
                  opened: true,
                  auditId: audit.id,
                  status: getAuditStatusFromAuditsPage(page),
                })
              );
            },
          }),
          render(_: string, audit) {
            return (
              <AuditName>
                <ButtonLink type="button">
                  {audit.number ? `${audit.number} - ` : ''}
                  {audit.name}
                  {audit.isRecurring && (
                    <Mark>{formatMessage({ id: 'RecurringAudit' })}</Mark>
                  )}
                </ButtonLink>
                {audit.isSample && (
                  <SampleBadge>{formatMessage({ id: 'Sample' })}</SampleBadge>
                )}
              </AuditName>
            );
          },
          className: 'cell-text-link',
        },
        ...(canScheduleAudits
          ? [
              {
                title: formatMessage({ id: 'Schedule' }),
                dataIndex: 'schedule',
                key: 'schedule',
                onCell: (audit: Audit) => ({
                  onClick: async (e: React.MouseEvent) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (
                      audit.schedulePlanSchedule &&
                      !audit.schedulePlanSchedule.isDeleted
                    ) {
                      navigate(
                        routes.advancedScheduleDetails.replace(
                          ':id',
                          audit.schedulePlanSchedule!.schedulePlanId
                        )
                      );
                    } else if (
                      audit.auditSchedule &&
                      !audit.auditSchedule.isDeleted
                    ) {
                      navigate(
                        routes.scheduleDetails.replace(
                          ':id',
                          audit.auditSchedule!.id
                        )
                      );
                    }
                  },
                }),
                render(_: ScheduleConcise, audit: Audit) {
                  if (audit.schedulePlanSchedule) {
                    return (
                      <ButtonLink
                        type="button"
                        disabled={audit.schedulePlanSchedule.isDeleted}
                      >
                        {audit.schedulePlanSchedule.name}
                      </ButtonLink>
                    );
                  }

                  if (audit.auditSchedule) {
                    return (
                      <ButtonLink
                        type="button"
                        disabled={audit.auditSchedule.isDeleted}
                      >
                        {audit.auditSchedule.name}
                      </ButtonLink>
                    );
                  }

                  return '-';
                },
              },
            ]
          : []),
        {
          title: auditObjectName.single,
          dataIndex: 'auditObjectName',
          key: 'auditObjectName',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'auditObjectName'
          ),
          render(_: string, audit: Audit) {
            return audit.auditObject.name;
          },
        },
        ...(page === AuditsPage.Pending
          ? [
              {
                title: formatMessage({ id: 'Status' }),
                dataIndex: 'status',
                key: 'status',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'status'
                ),
                render(_: AuditStatus, audit: Audit) {
                  return <AuditStatusName audit={audit} />;
                },
              },
              {
                title: formatMessage({ id: 'StartDate' }),
                dataIndex: 'startDate',
                key: 'startDate',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'startDate'
                ),
                render(_: IZonedDateTime, audit: Audit) {
                  return (
                    <ZonedDateTime dateTime={audit.startDateInformation} />
                  );
                },
              },
              {
                title: formatMessage({ id: 'DueDate' }),
                dataIndex: 'endDate',
                key: 'endDate',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'endDate'
                ),
                render(_: IZonedDateTime, audit: Audit) {
                  return (
                    <DueDateTableCell
                      dueDate={audit.endDateInformation.localTime}
                      timeZoneAbbreviation={
                        audit.endDateInformation.timeZoneAbbreviation
                      }
                      dueInDays={audit.dueInDays}
                    />
                  );
                },
              },
              getAssignmentColumn(),
            ]
          : []),
        ...(page === AuditsPage.Completed
          ? [
              {
                title: (
                  <>
                    {formatMessage({ id: 'Actions' })}
                    <br />(
                    {formatMessage({
                      id: 'Pending',
                    })}
                    &nbsp;/&nbsp;{formatMessage({ id: 'Completed' })}
                    &nbsp;/&nbsp;{formatMessage({ id: 'Expired' })})
                  </>
                ),
                dataIndex: 'pendingActionsCount',
                key: 'pendingActionsCount',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'pendingActionsCount'
                ),
                render(_: number, audit: Audit) {
                  const queryParams = `auditId=${audit.id}&auditName=${audit.name}`;

                  return (
                    <>
                      <CountButton
                        type="button"
                        onClick={() =>
                          navigate(`${routes.pendingActions}?${queryParams}`)
                        }
                      >
                        {audit.pendingActionsCount || 0}
                      </CountButton>
                      /
                      <CountButton
                        type="button"
                        onClick={() =>
                          navigate(`${routes.completedActions}?${queryParams}`)
                        }
                      >
                        {audit.completedActionsCount || 0}
                      </CountButton>
                      /
                      <CountButton
                        type="button"
                        onClick={() =>
                          navigate(`${routes.expiredActions}?${queryParams}`)
                        }
                      >
                        {audit.expiredActionsCount || 0}
                      </CountButton>
                    </>
                  );
                },
              },
              {
                title: formatMessage({ id: 'Score' }),
                dataIndex: 'score',
                key: 'score',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'score'
                ),
                render(_: number | null, audit: Audit) {
                  if (audit.score === null) {
                    return null;
                  }

                  return (
                    <ScoreChange
                      score={audit.score}
                      change={audit.scoreChange}
                    />
                  );
                },
              },
              {
                title: formatMessage({ id: 'Completed' }),
                key: 'completeDate',
                dataIndex: 'completeDate',
                render: (_: IZonedDateTime, audit: Audit) => {
                  if (audit.completedAtInformation === null) {
                    return null;
                  }

                  return (
                    <CompleteDateTableCell
                      completeDate={audit.completedAtInformation.localTime}
                      timeZoneAbbreviation={
                        audit.completedAtInformation.timeZoneAbbreviation
                      }
                      showLabel={date(
                        audit.completedAtInformation.localTime
                      ).isAfter(audit.endDateInformation.localTime, 'day')}
                      labelText={formatMessage({ id: 'Late' })}
                    />
                  );
                },
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'completeDate'
                ),
              },
              {
                title: formatMessage({ id: 'CompletedBy' }),
                dataIndex: 'completedBy',
                key: 'completedBy',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'completedBy'
                ),
                render(_: IConcise, audit: Audit) {
                  if (!audit.completedBy) {
                    return null;
                  }

                  return <>{audit.completedBy.name}</>;
                },
              },
            ]
          : []),
        ...(page === AuditsPage.Expired
          ? [
              getAssignmentColumn(),
              {
                title: formatMessage({ id: 'ExpiredDate' }),
                key: 'expiredDate',
                dataIndex: 'expiredDate',
                render: (_: IZonedDateTime, audit: Audit) => {
                  if (audit.expiredAtInformation === null) {
                    return null;
                  }

                  return (
                    <ExpiredDateWithBadge
                      date={`${date(
                        audit.expiredAtInformation.localTime
                      ).format(config.dateFormat)}${
                        audit.expiredAtInformation.timeZoneAbbreviation
                          ? ` (${audit.expiredAtInformation.timeZoneAbbreviation})`
                          : ''
                      }`}
                      isAutoExpired={audit.expiredBy === null}
                      onlyAutoExpired
                    />
                  );
                },
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'expiredDate'
                ),
              },
            ]
          : []),
        {
          title: formatMessage({ id: 'Action' }),
          key: 'action',
          align: 'center',
          width: 70,
          render: (audit) => (
            <AuditsActionsMenu audit={audit} context="table" />
          ),
        },
      ]}
      dataSource={data.map((audit) => ({
        ...audit,
        key: audit.id,
      }))}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              filters.search !== ''
                ? formatMessage(
                    { id: 'NoAuditsSearchResults' },
                    { searchStr: filters.search, linebreak: <br /> }
                  )
                : formatMessage({ id: 'NoAudits' })
            }
            howItWorksUrl={config.urls.auditsSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(auditsActions.getAudits({ orderBy, orderByDirection }))
      }
    />
  );
};

export default AuditsTable;
