import React from 'react';

import { TagType } from '@repo/shared/enums';

import Tags from './Tags';

const ActionsTags: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <Tags page={TagType.Action} />;
};

export default ActionsTags;
