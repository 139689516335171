import { TriggerType } from '@repo/shared/enums';

export function getTriggerTypeEnumValue(key: string) {
  if (key === 'Passed' || key === 'Yes') {
    return TriggerType.Passed;
  }

  if (key === 'Failed' || key === 'No') {
    return TriggerType.Failed;
  }

  return TriggerType.IsAnswered;
}
