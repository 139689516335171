import styled, { css } from 'styled-components';

import { colors } from '@repo/shared/config';

const dimensions = css`
  width: 160px;
  height: 80px;
`;

export const Container = styled.div`
  width: 160px;
  margin-right: 10px;
`;

export const SignContainer = styled.div`
  position: relative;
  ${dimensions};
`;

export const Sign = styled.img`
  ${dimensions};
`;

export const SignedBy = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  margin-top: 8px;

  span {
    color: ${colors.gray10};
  }
`;

export const RemoveSignatureBtn = styled.button`
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  padding: 0;
  background: none;
  border: 0;
  transition: all 0.2s ease-in-out;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.gray7};
  }
`;
