import React, { lazy, Suspense } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';

import { dashboardActions, dashboardSelectors } from '@store';
import { ShowUnresolvedActionsBy } from '@repo/shared/enums';
import { useTranslatedOptions } from '@utils';
import { Container, ChartWrapper } from './styled';
import placeholderSrc from './placeholder.svg';
import { IUnresolvedActionsChartItem } from '@repo/shared/types';
import { useAppDispatch } from '@hooks';
import { enumToSelectOptions } from '@src/core/utils/enums';

import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import ChartLegend from '../ChartLegend/ChartLegend';
import Loader from '@repo/shared/components/Loader';
const UnresolvedActionsChart = lazy(() => import('./UnresolvedActionsChart'));

const filterItems = enumToSelectOptions<ShowUnresolvedActionsBy>(
  ShowUnresolvedActionsBy
);

interface IProps {
  width: number;
}

const UnresolvedActions: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { data, filters, loading } = useSelector(
    dashboardSelectors.getUnresolvedActions
  );
  const translatedFilterItems =
    useTranslatedOptions<ShowUnresolvedActionsBy>(filterItems);

  return (
    <WidgetWrapper<ShowUnresolvedActionsBy>
      title={formatMessage({ id: 'PendingActions' })}
      filters={{
        value: filters.showBy,
        items: translatedFilterItems,
        onChange: (showBy) =>
          dispatch(dashboardActions.getUnresolvedActions({ showBy })),
      }}
    >
      <Container>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {(data || []).every(
              (item: IUnresolvedActionsChartItem) => item.count === 0
            ) ? (
              <img src={placeholderSrc} alt="" />
            ) : (
              <>
                <ChartWrapper>
                  <Suspense fallback={<Loader />}>
                    <UnresolvedActionsChart />
                  </Suspense>
                </ChartWrapper>
                <ChartLegend items={data} loading={loading} />
              </>
            )}
          </>
        )}
      </Container>
    </WidgetWrapper>
  );
};

export default UnresolvedActions;
