import { BaseApiClient } from '@repo/shared/api';
import { IPagedResponse, ITableFilters } from '@repo/shared/types';
import {
  SchedulePlanPeriod,
  SchedulePlanPeriodAuditor,
} from '@domain/SchedulePlans/models/SchedulePlanPeriod';
import { SchedulePlanPeriodAuditorType } from '@application/SchedulePlans/models/SchedulePlanPeriodAuditorType';
import { SchedulePlanPeriodAudit } from '@domain/SchedulePlans/models/SchedulePlanPeriodAudit';

class SchedulePlanPeriodsApiClient extends BaseApiClient {
  public async getPeriods(schedulePlanId: string, filters: ITableFilters) {
    return this._apiService.get<IPagedResponse<SchedulePlanPeriod>>({
      url: `audit/schedule-plans/${schedulePlanId}/history`,
      query: {
        ...filters,
      },
    });
  }

  public async getPeriod(schedulePlanId: string, schedulePlanPeriodId: string) {
    return this._apiService.get<SchedulePlanPeriod>({
      url: `audit/schedule-plans/${schedulePlanId}/history/${schedulePlanPeriodId}`,
    });
  }

  public async getPeriodAuditors({
    schedulePlanId,
    schedulePlanPeriodId,
    filters,
    type,
  }: {
    schedulePlanId: string;
    schedulePlanPeriodId: string;
    filters: ITableFilters;
    type: SchedulePlanPeriodAuditorType;
  }) {
    return this._apiService.get<IPagedResponse<SchedulePlanPeriodAuditor>>({
      url: `audit/schedule-plans/${schedulePlanId}/history/${schedulePlanPeriodId}/${
        type === SchedulePlanPeriodAuditorType.Waiting
          ? 'waiting-auditors'
          : 'busy-auditors'
      }`,
      query: {
        ...filters,
      },
    });
  }

  public async getPeriodAudits({
    schedulePlanId,
    schedulePlanPeriodId,
    filters,
  }: {
    schedulePlanId: string;
    schedulePlanPeriodId: string;
    filters: ITableFilters;
  }) {
    return this._apiService.get<IPagedResponse<SchedulePlanPeriodAudit>>({
      url: `audit/schedule-plans/${schedulePlanId}/history/${schedulePlanPeriodId}/audits`,
      query: {
        ...filters,
      },
    });
  }

  public async regenerate(schedulePlanId: string) {
    await this._apiService.post({
      url: `audit/schedule-plans/${schedulePlanId}/regenerate-periods`,
    });
  }
}

export default SchedulePlanPeriodsApiClient;
