import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import PageHeader from '@components/shared/ant/PageHeader';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';
import Button from '@components/shared/ant/Button';
import { e2eTestElements } from '@config';

interface IProps {}

const IssueTypeQrCodesHeader: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const selectedFiltersQty = useSelector(
    issueTypeQrCodesSelectors.getSelectedFiltersQty
  );
  const filters = useSelector(issueTypeQrCodesSelectors.getFilters);

  return (
    <PageHeader
      title={formatMessage({ id: 'QrCodes' })}
      extra={
        <Space size={15}>
          <SearchInput
            onEnter={(search: string) =>
              dispatch(
                issueTypeQrCodesActions.getQrCodes({
                  search,
                  pageNumber: 1,
                })
              )
            }
            value={filters.search}
          />
          <FiltersButton
            showModal={() => {
              dispatch(issueTypeQrCodesActions.toggleFiltersModal(true));
            }}
            selectedFilters={selectedFiltersQty}
            resetFilters={() => {
              dispatch(issueTypeQrCodesActions.getQrCodes(null));
            }}
          />
          <Button
            type="primary"
            data-e2e={e2eTestElements.issues.issueTypes.qrCodes.createBtn}
            onClick={() => {
              dispatch(
                issueTypeQrCodesActions.toggleAddEditModal({ show: true })
              );
            }}
          >
            {formatMessage({
              id: 'CreateQrCode',
            })}
          </Button>
        </Space>
      }
    />
  );
};

export default IssueTypeQrCodesHeader;
