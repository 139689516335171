import React from 'react';
import { useIntl } from 'react-intl';

import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { SchedulePlan } from '@domain/SchedulePlans/models/SchedulePlan';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  schedulePlan: SchedulePlan;
}

const SchedulePlansActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  schedulePlan,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);

  if (!canScheduleAudits) {
    return null;
  }

  return (
    <TableRowActionsMenu
      menuItems={[
        ...(!schedulePlan.active
          ? [
              {
                name: formatMessage({ id: 'Edit' }),
                onClick: () => {
                  dispatch(
                    schedulePlansActions.toggleAddEditModal({
                      show: true,
                      schedulePlanId: schedulePlan.id,
                    })
                  );
                },
              },
            ]
          : []),
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () => {
            dispatch(
              schedulePlansActions.toggleConfirmDeleteModal({
                multiple: false,
                data: schedulePlan,
              })
            );
          },
        },
      ]}
    />
  );
};

export default SchedulePlansActionsMenu;
