import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { InlineSkeleton } from '@components/shared/InlineSkeleton';

export const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.gray5};
  border-bottom: 1px solid ${colors.gray5};
  margin: 24px 0;
  padding: 24px 0;
`;

export const Metric = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MetricLabel = styled.div`
  color: ${colors.gray8};
  font-size: 13px;
  margin-bottom: 6px;
  line-height: 1;
`;

export const MetricValue = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 600;
`;

export const MetricChange = styled.div`
  font-size: 10px;
  font-weight: 400;
  margin-top: -1px;
  margin-left: 2px;
  color: ${({ value }: { value: number }) =>
    value > 0 ? colors.green : colors.red};
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: ${({ badgeColor }: { badgeColor?: string }) =>
    badgeColor || colors.blue2};
  margin-right: 12px;
  border-radius: 100%;

  svg {
    color: ${colors.white};
    width: 24px;
    height: 24px;
  }
`;

export const Skeleton = styled(InlineSkeleton)`
  width: 75px;
  height: 12px;
  margin: 2px 0;
`;

export const ProgressBarSkeleton = styled(InlineSkeleton)`
  height: 6px;
  border-radius: 5px;
`;
