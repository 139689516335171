import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  generalSelectors,
  generalActions,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '../../../../shared/ant/Modal/Modal';

const ConfirmDeleteAssignmentModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const assignmentIds = useSelector(
    auditObjectsSelectors.attributesAssignments.getDeleteCandidatesIds
  );
  const assignmentsMap = useSelector(
    auditObjectsSelectors.attributesAssignments.getMap
  );
  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );
  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );

  const assignment =
    assignmentIds.length === 1 ? assignmentsMap[assignmentIds[0]] : null;

  const closeModal = useCallback(
    () =>
      dispatch(
        auditObjectsActions.attributesAssignments.toggleConfirmDeleteModal([])
      ),
    []
  );

  return (
    <Modal
      title={formatMessage(
        { id: 'RemoveAttributes' },
        { itemCount: assignmentIds.length }
      )}
      open={assignmentIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          auditObjectsActions.attributesAssignments.deleteAssignments(
            assignmentIds
          )
        );

        if (
          auditObjectsActions.attributesAssignments.deleteAssignments.fulfilled.match(
            resultAction
          )
        ) {
          dispatch(auditObjectsActions.attributesAssignments.getData());

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeletingAttributes' }),
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {assignment
        ? formatMessage(
            { id: 'YouAreAboutToRemoveAttributeAssignmentFromSystem' },
            {
              attributeName: attributesMap[assignment.attributeId]?.name,
              locationName: auditObjectsMap[assignment.auditObjectId]?.name,
            }
          )
        : formatMessage(
            { id: 'YouAreAboutToRemoveAttributesFromSystem' },
            { count: assignmentIds.length }
          )}
    </Modal>
  );
};

export default ConfirmDeleteAssignmentModal;
