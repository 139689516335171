import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { SelfGuidedTourStep, AnalyticsEvent } from '@repo/shared/enums';
import { Buttons, Error } from './styled';
import { useAppDispatch } from '@hooks';
import { accountSelectors, accountActions } from '@store';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { apiUrls, e2eTestElements } from '@config';
import { ScheduleFormValues } from '@application/Schedules/models/ScheduleFormValues';
import { useInitialScheduleFormValues } from '@application/Schedules/hooks/useInitialScheduleFormValues';
import { transformFormValuesToScheduleDto } from '@application/Schedules/utils/transformFormValuesToScheduleDto';
import { notification } from '@utils';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';

import Form from '@components/shared/ant/Form';
import { Input } from '@components/shared/ant/Input';
import Button from '@components/shared/ant/Button';
import AuditTemplatesSelect from '@components/shared/EntitySelects/AuditTemplatesSelect';
import EntityAndGroupSelectAsync from '@components/shared/EntitySelects/EntityAndGroupSelectAsync';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import SlideModal from '@components/shared/SideModal/SideModal';
import RepeatPatterns from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/RepeatPatterns';
import AuditorAssignment from '@presentation/Schedules/AddEditScheduleModal/AuditorAssignment';
import AuditGenerationModeFormItem from '@presentation/Schedules/AddEditScheduleModal/AuditGenerationModeFormItem';

interface IProps {
  disabled?: boolean;
}

const AddEditScheduleModal: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ScheduleFormValues>();

  const [saving, setSaving] = useState(false);

  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const modalState = useSelector(schedulesSelectors.getAddEditModalState);
  const { data: schedulePlan } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );
  const { data: schedules } = useSelector(schedulesSelectors.getSchedules);
  const {
    data: scheduleDetails,
    loading: scheduleDetailsLoading,
    error: scheduleDetailsError,
  } = useSelector(schedulesSelectors.getScheduleDetails);

  const initialValues = useInitialScheduleFormValues(
    scheduleDetails,
    schedulePlan
  );

  useEffect(() => {
    if (modalState.opened && modalState.scheduleId) {
      dispatch(
        schedulesActions.getScheduleDetails({
          scheduleId: modalState.scheduleId,
          schedulePlanId: schedulePlan?.id,
        })
      );
    }
  }, [modalState.opened]);

  useEffect(() => {
    if (modalState.opened) {
      form.setFieldsValue(initialValues);
    }

    if (!modalState.opened) {
      dispatch(schedulesActions.resetScheduleDetails());
    }
  }, [modalState.opened, JSON.stringify(initialValues)]);

  const closeModal = useCallback(() => {
    dispatch(schedulesActions.toggleAddEditModal({ opened: false }));
  }, []);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  const edit = modalState.opened && !!modalState.scheduleId;
  const itemName = formatMessage({ id: 'Schedule' }).toLowerCase();

  return (
    <SlideModal
      opened={modalState.opened}
      onModalClose={closeModal}
      title={formatMessage({
        id: edit ? 'EditSchedule' : 'CreateSchedule',
      })}
    >
      {scheduleDetailsLoading ? (
        <Skeleton active />
      ) : (
        <>
          {scheduleDetailsError ? (
            <Error>{formatMessage({ id: 'UnexpectedError' })}</Error>
          ) : (
            <TourPopover
              step={SelfGuidedTourStep.AddScheduleForm}
              placement="left"
              overlayStyle={{
                height: 685,
                marginTop: -50,
              }}
            >
              <Form<ScheduleFormValues>
                form={form}
                name="add-new-scheduled-audit-form"
                layout="vertical"
                initialValues={initialValues}
                onFinish={async (values) => {
                  setSaving(true);

                  const update = transformFormValuesToScheduleDto(values);

                  const actionCreator = edit
                    ? schedulesActions.updateSchedule
                    : schedulesActions.createSchedule;

                  const resultAction = await (modalState.opened &&
                  modalState.scheduleId
                    ? dispatch(
                        schedulesActions.updateSchedule({
                          id: modalState.scheduleId,
                          schedule: update,
                          schedulePlanId: schedulePlan?.id,
                        })
                      )
                    : dispatch(
                        schedulesActions.createSchedule({
                          schedule: update,
                          schedulePlanId: schedulePlan?.id,
                        })
                      ));

                  if (actionCreator.fulfilled.match(resultAction)) {
                    const hasSchedulesCreatedByCurrentUser = schedules.some(
                      ({ isSample }) => !isSample
                    );

                    if (!hasSchedulesCreatedByCurrentUser) {
                      dispatch(
                        accountActions.sendAnalyticsEvent(
                          AnalyticsEvent.AddSchedule
                        )
                      );
                    }

                    closeModal();

                    dispatch(schedulesActions.getSchedules());
                    dispatch(schedulesActions.getConciseSchedules());

                    notification.success({
                      message: formatMessage(
                        {
                          id: edit
                            ? 'YouHaveSuccessfullyEditedItem'
                            : 'YouHaveSuccessfullyCreatedItem',
                        },
                        { item: itemName }
                      ),
                    });
                  } else {
                    notification.error({
                      message: formatMessage(
                        {
                          id: edit
                            ? 'ErrorWhileEditingItem'
                            : 'ErrorWhileCreatingItem',
                        },
                        { item: itemName }
                      ),
                      description: resultAction.payload,
                    });
                  }

                  setSaving(false);
                }}
              >
                <Form.Item
                  name="name"
                  label={formatMessage({ id: 'Name' })}
                  rules={[
                    ...(schedulePlan
                      ? [
                          {
                            required: true,
                            message: formatMessage({
                              id: 'RequiredField',
                            }),
                          },
                        ]
                      : []),
                    {
                      max: 800,
                      message: formatMessage(
                        {
                          id: 'NameMustNotExceedXCharacters',
                        },
                        { count: 800 }
                      ),
                    },
                  ]}
                >
                  <Input
                    disabled={saving || disabled}
                    placeholder={formatMessage({
                      id: schedulePlan ? 'EnterName' : 'EnterNameOptional',
                    })}
                    data-e2e={e2eTestElements.schedules.addEditSchedule.name}
                  />
                </Form.Item>
                <Form.Item
                  name="templateId"
                  label={formatMessage({ id: 'Template' })}
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'PleaseChooseAuditTemplate',
                      }),
                    },
                  ]}
                >
                  <AuditTemplatesSelect
                    disabled={edit || saving || disabled}
                    placeholder={formatMessage({ id: 'SelectTemplate' })}
                    e2eDataAttr={
                      e2eTestElements.schedules.addEditSchedule.templateSelect
                    }
                  />
                </Form.Item>
                {!schedulePlan && (
                  <AuditorAssignment disabled={disabled || saving} />
                )}
                <Form.Item
                  name="auditObjectAndGroupIds"
                  label={formatMessage(
                    { id: 'AuditObjectsAndGroups' },
                    { objectName: auditObjectName.plural }
                  )}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (
                          value.entityIds.length === 0 &&
                          value.groupIds.length === 0
                        ) {
                          return Promise.reject(
                            formatMessage(
                              { id: 'PleaseChooseAuditObject' },
                              {
                                objectName:
                                  auditObjectName.single.toLowerCase(),
                              }
                            )
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                    {
                      required: true,
                      message: formatMessage(
                        { id: 'PleaseChooseAuditObject' },
                        { objectName: auditObjectName.single.toLowerCase() }
                      ),
                    },
                  ]}
                >
                  <EntityAndGroupSelectAsync
                    entity={{
                      api: `${apiUrls.auditObjects}/concise`,
                      label: auditObjectName.plural,
                    }}
                    e2eDataAttr={
                      e2eTestElements.schedules.addEditSchedule
                        .objectOrObjectGroupSelect
                    }
                    group={{
                      api: `${apiUrls.auditObjectsGroups}/concise`,
                      label: formatMessage(
                        { id: 'AuditObjectGroups' },
                        { objectName: auditObjectName.plural }
                      ),
                    }}
                    placeholder={formatMessage(
                      { id: 'SelectObjectOrGroups' },
                      { objectName: auditObjectName.plural }
                    )}
                    disabled={saving || disabled}
                  />
                </Form.Item>
                <RepeatPatterns
                  form={form}
                  disabled={saving || disabled}
                  isSchedulePlanSchedule={!!schedulePlan}
                />
                {!!schedulePlan && (
                  <AuditGenerationModeFormItem disabled={disabled} />
                )}
                <Form.Item
                  name="auditorHint"
                  label={
                    <>
                      <>
                        {formatMessage({ id: 'AuditorHint' })}
                        <CircleTooltip contentMaxWidth={250}>
                          <p>
                            {formatMessage({
                              id: 'ProvideAnyAdditionalDetailsThatCouldAssistDuringTheAudit',
                            })}
                          </p>
                        </CircleTooltip>
                      </>
                    </>
                  }
                  rules={[
                    {
                      max: 800,
                      message: formatMessage(
                        {
                          id: 'NameMustNotExceedXCharacters',
                        },
                        { count: 800 }
                      ),
                    },
                  ]}
                >
                  <Input.TextArea
                    data-e2e={
                      e2eTestElements.objects.addEditObject.timeZoneSelect
                    }
                    disabled={saving || disabled}
                    placeholder={formatMessage({
                      id: 'EnterAnyHelpfulHintsOrPointersHere',
                    })}
                    rows={3}
                  />
                </Form.Item>
                <Buttons>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                    disabled={disabled}
                    data-e2e={
                      e2eTestElements.schedules.addEditSchedule.scheduleButton
                    }
                  >
                    {edit
                      ? formatMessage({ id: 'Save' })
                      : formatMessage({ id: 'Create' })}
                  </Button>
                  <Button
                    type="default"
                    data-e2e={
                      e2eTestElements.schedules.addEditSchedule.cancelButton
                    }
                    onClick={() => {
                      form.resetFields();

                      setTimeout(() => {
                        closeModal();
                      }, 50);
                    }}
                  >
                    {formatMessage({ id: 'Cancel' })}
                  </Button>
                </Buttons>
              </Form>
            </TourPopover>
          )}
        </>
      )}
    </SlideModal>
  );
};

export default AddEditScheduleModal;
