import styled from 'styled-components';

import Form from '@components/shared/ant/Form';

import { colors } from '@repo/shared/config';

export const AttachFilesText = styled.div`
  color: ${colors.blue2};
  font-size: 13px;
  cursor: pointer;
`;

export const FileAttachmentsFormItem = styled(Form.Item)`
  margin-top: 5px;

  .ant-form-item-control-input {
    min-height: auto !important;
  }

  .ant-form-item-explain {
    line-height: 1.5 !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }
`;

export const IssueQuestionAnswerFormItem = styled(Form.Item)`
  .ant-form-item-explain {
    margin-bottom: 0 !important;
  }
`;

export const PreviewsContainer = styled.div`
  margin: 5px 0 7px;
`;
