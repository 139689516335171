import { createSelector, EntityState } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { INotification } from '@repo/shared/types';
import {
  MqaNotification,
  NotificationsGroupType,
  NotificationsTab,
} from '@repo/shared/enums';
import { notificationsAdapter } from '@store/entityAdapters';

const notificationsByGroups: Record<
  NotificationsGroupType,
  [MqaNotification, string][]
> = {
  [NotificationsGroupType.AuditsAssignedToMe]: [
    [MqaNotification.AuditAssignedAssigneesNotification, 'WhenAuditAssigned'],
    [MqaNotification.AuditCompletedAssigneesNotification, 'WhenAuditCompleted'],
    [MqaNotification.AuditDeletedAssigneesNotification, 'WhenAuditDeleted'],
    [MqaNotification.AuditDueSoonAssigneesNotification, 'WhenAuditDueSoon'],
    [
      MqaNotification.AuditRescheduledAssigneesNotification,
      'WhenAuditRescheduled',
    ],
    [MqaNotification.AuditOverdueAssigneesNotification, 'WhenAuditIsOverdue'],
    [MqaNotification.AuditExpiredAssigneesNotification, 'WhenAuditExpires'],
  ],
  [NotificationsGroupType.AuditsAssignedToSomeoneElse]: [
    [
      MqaNotification.AuditCompletedParticipantsNotification,
      'WhenAuditCompleted',
    ],
  ],
  [NotificationsGroupType.ActionsAssignedToMe]: [
    [
      MqaNotification.CorrectiveActionAssignedAssigneesNotification,
      'WhenActionAssigned',
    ],
    [
      MqaNotification.CorrectiveActionApprovedAssigneesNotification,
      'WhenActionApproved',
    ],
    [
      MqaNotification.CorrectiveActionRejectedAssigneesNotification,
      'WhenActionRejected',
    ],
    [
      MqaNotification.CorrectiveActionDeletedAssigneesNotification,
      'WhenActionDeleted',
    ],
    [
      MqaNotification.CorrectiveActionCommentAddedAssigneesNotification,
      'WhenActionCommendAdded',
    ],
    [
      MqaNotification.CorrectiveActionDueSoonAssigneesNotification,
      'WhenActionDueSoon',
    ],
    [
      MqaNotification.CorrectiveActionOverDueAssigneesNotification,
      'WhenActionIsOverdue',
    ],
    [
      MqaNotification.CorrectiveActionExpiredAssigneesNotification,
      'WhenActionExpired',
    ],
  ],
  [NotificationsGroupType.ActionsCreatedByMe]: [
    [
      MqaNotification.CorrectiveActionSubmittedCreatedByNotification,
      'WhenActionSubmitted',
    ],
    [
      MqaNotification.CorrectiveActionCommentAddedCreatedByNotification,
      'WhenActionCommendAdded',
    ],
  ],
  [NotificationsGroupType.ActionsAssignedToSomeoneElse]: [
    [
      MqaNotification.CorrectiveActionApprovedParticipantsNotification,
      'WhenActionApproved',
    ],
    [
      MqaNotification.CorrectiveActionSubmittedParticipantsNotification,
      'WhenActionSubmitted',
    ],
    [
      MqaNotification.CorrectiveActionCommentAddedParticipantNotification,
      'WhenActionCommendAdded',
    ],
    [
      MqaNotification.CorrectiveActionOverDueParticipantNotification,
      'WhenActionIsOverdue',
    ],
    [
      MqaNotification.CorrectiveActionRejectedParticipantNotification,
      'WhenActionRejected',
    ],
  ],
  [NotificationsGroupType.IssuesAssignedToMe]: [
    [MqaNotification.IssueAssignedAssigneesNotification, 'WhenIssueAssigned'],
    [MqaNotification.IssueClosedAssigneesNotification, 'WhenIssueClosed'],
    [MqaNotification.IssueReopenedAssigneesNotification, 'WhenIssueReopened'],
    [
      MqaNotification.IssueCommentAddedAssigneesNotification,
      'WhenIssueCommendAdded',
    ],
    [MqaNotification.IssueDueSoonAssigneesNotification, 'WhenIssueDueSoon'],
    [MqaNotification.IssueOverDueAssigneesNotification, 'WhenIssueIsOverdue'],
  ],
  [NotificationsGroupType.IssuesAssignedToParticipants]: [
    [MqaNotification.IssueClosedParticipantsNotification, 'WhenIssueClosed'],
    [MqaNotification.IssueReopenedParticipantNotification, 'WhenIssueReopened'],
    [
      MqaNotification.IssueCommentAddedParticipantNotification,
      'WhenIssueCommendAdded',
    ],
    [
      MqaNotification.IssueSubmittedParticipantsNotification,
      'WhenIssueSubmitted',
    ],
    [MqaNotification.IssueOverDueParticipantNotification, 'WhenIssueIsOverdue'],
  ],
};

const notificationsTabsByGroups = {
  [NotificationsGroupType.AuditsAssignedToMe]: NotificationsTab.Audit,
  [NotificationsGroupType.AuditsAssignedToSomeoneElse]: NotificationsTab.Audit,
  [NotificationsGroupType.ActionsCreatedByMe]: NotificationsTab.Action,
  [NotificationsGroupType.ActionsAssignedToMe]: NotificationsTab.Action,
  [NotificationsGroupType.ActionsAssignedToSomeoneElse]:
    NotificationsTab.Action,
  [NotificationsGroupType.IssuesAssignedToMe]: NotificationsTab.Issue,
  [NotificationsGroupType.IssuesAssignedToParticipants]: NotificationsTab.Issue,
};

export const settingsSelectors = {
  getNotificationsLoading: (state: IRootState): boolean =>
    state.settings.notifications.loading,
  getNotificationsCountByTab: createSelector(
    (state: IRootState): EntityState<INotification, number> =>
      state.settings.notifications.data,
    (notifications) =>
      notificationsAdapter
        .getSelectors()
        .selectAll(notifications)
        .reduce<Record<NotificationsTab, number>>(
          (acc, notification) => {
            const a = acc;

            const notificationType = notification.notificationSettingType;

            const notificationGroup = (
              Object.keys(notificationsByGroups) as unknown as Array<
                keyof typeof notificationsByGroups
              >
            ).find((group) =>
              notificationsByGroups[group].find(
                ([type]) => type === notificationType
              )
            );

            if (notificationGroup === undefined) {
              return a;
            }

            a[notificationsTabsByGroups[notificationGroup]] += 1;

            return a;
          },
          {
            [NotificationsTab.Audit]: 0,
            [NotificationsTab.Action]: 0,
            [NotificationsTab.Issue]: 0,
          }
        )
  ),
  getNotificationsByGroup: (notificationsGroup: NotificationsGroupType) =>
    createSelector(
      (state: IRootState): EntityState<INotification, number> =>
        state.settings.notifications.data,
      (data) => {
        const groupNotifications = notificationsByGroups[notificationsGroup];

        return groupNotifications
          .reduce<{ notification: INotification; langId: string }[]>(
            (acc, [notificationType, langId]) => {
              const a = acc;

              const notification = notificationsAdapter
                .getSelectors()
                .selectById(data, notificationType);

              if (notification) {
                a.push({
                  notification,
                  langId,
                });
              }

              return a;
            },
            []
          )
          .sort((a, b) => {
            if (a.notification.isEditable !== b.notification.isEditable) {
              return a.notification.isEditable ? -1 : 1;
            }

            if (a.notification.isEnabled !== b.notification.isEnabled) {
              return a.notification.isEnabled ? -1 : 1;
            }

            return 0;
          });
      }
    ),
  getNotificationsTab: (state: IRootState): NotificationsTab =>
    state.settings.notifications.tab,
  integrations: {
    getIntegrations: createSelector(
      [
        (state: IRootState) => state.settings.integrations.loading,
        (state: IRootState) => state.settings.integrations.error,
      ],
      (loading, error) => ({ loading, error })
    ),
    getApiKey: (state: IRootState) => state.settings.integrations.apiKey,
    getWebhookUrl: (state: IRootState) => state.settings.integrations.webhook,
    isConfirmRegenerateModalVisible: (state: IRootState) =>
      state.settings.integrations.showConfirmRegenerateModal,
  },
};
