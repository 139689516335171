import React from 'react';
import { useIntl } from 'react-intl';

import { Container, Badge } from './styled';

interface IProps {
  date: string | React.ReactNode;
  showBadge?: boolean;
  onlyAutoExpired?: boolean;
  isAutoExpired: boolean;
  align?: 'flex-start' | 'space-between';
}

const ExpiredDateWithBadge: React.FC<IProps> = ({
  date,
  showBadge = true,
  onlyAutoExpired,
  isAutoExpired,
  align,
}) => {
  const { formatMessage } = useIntl();

  if (!showBadge || (onlyAutoExpired && !isAutoExpired)) {
    return <>{date}</>;
  }

  return (
    <Container style={{ justifyContent: align || 'space-between' }}>
      {date}
      <Badge>
        {formatMessage({ id: isAutoExpired ? 'AutoExpired' : 'Expired' })}
      </Badge>
    </Container>
  );
};

export default ExpiredDateWithBadge;
