import React from 'react';
import { useIntl } from 'react-intl';

import { ITag } from '@repo/shared/types';

import AddEditModal from '../../shared/AddEditModal/AddEditModal';
import AddEditTagModalForm from './AddEditTagModalForm';

const AddEditTagModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <AddEditModal
      titles={{
        add: formatMessage({ id: 'AddNewTag' }),
        edit: formatMessage({ id: 'EditTag' }),
      }}
    >
      {({ closeModal, data }) => (
        <AddEditTagModalForm
          data={data as ITag | undefined}
          closeModal={closeModal}
        />
      )}
    </AddEditModal>
  );
};

export default AddEditTagModal;
