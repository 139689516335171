import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Content = styled.div`
  //width: 320px;
  //margin: 40px auto 50px;
`;

export const AvatarContainer = styled.div`
  margin: 30px 0 45px;
`;

export const ProfileSection = styled.div`
  margin-bottom: 32px;

  div {
    margin-bottom: 10px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  button {
    background: none;
    border: 0;
    font-size: 12px;
    color: ${colors.blue2};
    padding: 0;
  }
`;

export const ChangePasswordContent = styled.div`
  margin: 25px 0 35px;
`;
