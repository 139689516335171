import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import { useIntl } from 'react-intl';

import {
  Container,
  Name,
  TagsContainer,
  DefinitionsRow,
  DefinitionValueHolder,
} from './styled';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { config, colors } from '@repo/shared/config';

import Definition from '@components/shared/Definition/Definition';
import ZonedDateTime from '@components/shared/ZonedDateTime';
import ActionStatusName from '@src/presentation/Actions/ActionStatusName';
import TagList from '@components/shared/TagList/TagList';

interface IProps {}

const ActionDetailsTagline: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  const loading = useSelector(actionsSelectors.getActionDetailsLoading);
  const action = useSelector(actionsSelectors.getActionDetailsAction);

  if (!loading && !action) {
    return null;
  }

  return (
    <Container>
      <Name>
        {loading ? (
          <Skeleton.Input active style={{ borderRadius: 5 }} />
        ) : (
          <>
            {action?.number ? `${action.number} - ` : ''}
            {action?.name}
          </>
        )}
      </Name>
      <DefinitionsRow>
        <Definition
          label={formatMessage({ id: 'Status' })}
          horizontal
          value={
            <DefinitionValueHolder>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                <ActionStatusName status={action?.status!} />
              )}
            </DefinitionValueHolder>
          }
        />
        <Definition
          label={formatMessage({ id: 'CreatedBy' })}
          horizontal
          value={
            <DefinitionValueHolder>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                <>
                  {action?.createdBy.name && (
                    <div
                      style={{
                        display: 'inline-block',
                        margin: '0 7px 0 -2px',
                      }}
                    >
                      {action?.createdBy.name}
                    </div>
                  )}
                  <span style={{ color: colors.gray8, textIndent: '-2px' }}>
                    {formatMessage({ id: 'on' })}{' '}
                    <ZonedDateTime
                      dateTime={action?.createdAtInformation!}
                      format={config.dateTimeFormat}
                    />
                  </span>
                </>
              )}
            </DefinitionValueHolder>
          }
        />
      </DefinitionsRow>
      {!!action?.tags && (
        <TagsContainer>
          <TagList tags={action.tags} />
        </TagsContainer>
      )}
    </Container>
  );
};

export default ActionDetailsTagline;
