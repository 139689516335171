import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const UserGroupRow = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    color: ${colors.blue2};
  }
`;

export const JobTitleRow = styled.div``;

export const GroupName = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
`;
