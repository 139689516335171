import React from 'react';

import { Container, InputRow } from './styled';

interface IProps {}

const CreditCardFormFields: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <Container id="chargify-form">
      <div id="card-number" />
      <InputRow>
        <div id="month" />
        <div id="year" />
        <div id="cvv" />
      </InputRow>
      <InputRow>
        <div id="firstName" />
        <div id="lastName" />
      </InputRow>
    </Container>
  );
};

export default CreditCardFormFields;
