import TagManager from 'react-gtm-module';

import { AnalyticsEvent, AuthProvider } from '@repo/shared/enums';
import { config } from '@repo/shared/config';

const gtm = {
  init() {
    // if (import.meta.env.VITE_ENV === 'production') {
    TagManager.initialize({ gtmId: config.gtmId });
    // }
  },
  sendPageViewEvent(userId?: string) {
    TagManager.dataLayer({
      dataLayer: {
        userId,
      },
    });
  },
  sendEvent({
    event,
    userId,
    authProvider,
    companyId,
  }: {
    event: AnalyticsEvent;
    userId?: string;
    authProvider: AuthProvider;
    companyId: string;
  }) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        userId,
        formName: event === AnalyticsEvent.SignIn ? 'Signup' : 'Signup',
        type: authProvider,
        companyId,
      },
    });
  },
};

export default gtm;
