import React from 'react';
import { useIntl } from 'react-intl';
import { Calendar, MapPin } from 'react-feather';
import { useSelector } from 'react-redux';
import Space from 'antd/es/space';

import {
  Body,
  Container,
  Detail,
  DetailIcon,
  DetailLabel,
  Details,
  DetailsButton,
  DetailValue,
  Header,
  StartButton,
  Status,
  Title,
} from './styled';
import { Audit } from '@domain/Audits/models/Audit';
import { accountSelectors } from '@store';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { useAppDispatch } from '@hooks';
import { performAuditActions } from '@application/PerformAudit/store/performAuditActions';
import { getAuditStatusFromAuditsPage } from '@application/Audits/utils/getAuditStatusFromAuditsPage';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { delay } from '@repo/shared/utils';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';

import ZonedDateTime from '@components/shared/ZonedDateTime';
import AuditStatusName from '@presentation/Audits/AuditStatusName';

interface IProps {
  item: Audit;
}

const MyAudit: React.FC<React.PropsWithChildren<IProps>> = ({ item }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const viewAuditModal = useSelector(auditsSelectors.getViewAuditModalState);
  const objectName = useSelector(accountSelectors.getObjectName);

  return (
    <Container>
      <Header>
        <Title>{item.name}</Title>
        <Status>
          <AuditStatusName audit={item} />
        </Status>
      </Header>
      <Body>
        <Details>
          <Detail>
            <DetailIcon>
              <MapPin />
            </DetailIcon>
            <DetailLabel>{objectName.single}</DetailLabel>
            <DetailValue>{item.auditObject.name}</DetailValue>
          </Detail>
          {!!item.endDateInformation && (
            <Detail>
              <DetailIcon>
                <Calendar />
              </DetailIcon>
              <DetailLabel>{formatMessage({ id: 'DueDate' })}</DetailLabel>
              <DetailValue>
                <ZonedDateTime dateTime={item.endDateInformation} />
              </DetailValue>
            </Detail>
          )}
        </Details>
        <Space size={12}>
          <DetailsButton
            type="default"
            onClick={async () => {
              if (viewAuditModal.opened) {
                dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
                await delay(250);
              }

              dispatch(
                auditsActions.toggleViewAuditModal({
                  opened: true,
                  auditId: item.id,
                  status: getAuditStatusFromAuditsPage(AuditsPage.My),
                })
              );
            }}
          >
            {formatMessage({ id: 'Details' })}
          </DetailsButton>
          <StartButton
            type="primary"
            disabled={!item.canStartAudit}
            onClick={() => {
              dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
              dispatch(performAuditActions.openModal(item.id));
            }}
          >
            {formatMessage({
              id: item.startedAtInformation ? 'Continue' : 'Start',
            })}
          </StartButton>
        </Space>
      </Body>
    </Container>
  );
};

export default MyAudit;
