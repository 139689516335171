import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

export const Content = styled.div`
  position: relative;
  width: 100%;
`;

export const Thanks = styled(animated.div)`
  position: absolute;
  left: -1px;
  top: -1px;
  bottom: -1px;
  right: -1px;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  background: ${colors.blue6};
  color: ${colors.white};
  text-align: center;
  font-size: 14px;
  line-height: 1.5;

  & > * {
    &:first-child {
      margin-bottom: 30px;
    }
  }
`;

export const ThankYouTitle = styled.div`
  font-size: 17px;
  margin-bottom: 12px;
  font-weight: 500;
`;

export const ThankYouDesc = styled.div`
  width: 350px;
`;

export const Definitions = styled.div`
  font-size: 14px;
  margin: 6px 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    margin-bottom: 7px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
