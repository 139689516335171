import React from 'react';
import { useIntl } from 'react-intl';

import {
  Container,
  Title,
  Step,
  StepTitle,
  StepDescription,
  Divider,
  Disclaimer,
} from './styled';
import { config } from '@repo/shared/config';

interface IProps {}

const TextColumn: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Title>{formatMessage({ id: 'DigitizeYourAuditForms' })}</Title>
      <Step>
        <StepTitle>1. {formatMessage({ id: 'UploadYourFiles' })}</StepTitle>
        <StepDescription>
          {formatMessage({ id: 'SendUsYourTemplates' })}
        </StepDescription>
      </Step>
      <Step>
        <StepTitle>
          2. {formatMessage({ id: 'WeConvertYourDocument' })}
        </StepTitle>
        <StepDescription>
          {formatMessage({ id: 'WeWillCreateMonitorQATemplatesFromYourFiles' })}
        </StepDescription>
      </Step>
      <Step>
        <StepTitle>
          3. {formatMessage({ id: 'StartAuditingWithMonitorQA' })}
        </StepTitle>
        <StepDescription>
          {formatMessage({
            id: 'ReceiveNotificationsOnceYourTemplateIsPrepared',
          })}
        </StepDescription>
      </Step>
      <Divider />
      <Disclaimer>
        <p>
          {
            // @ts-ignore
            formatMessage(
              {
                id: 'YourFilesWillBeSharedWithOurTeamToCreateTemplate',
              },
              {
                a: (chunks) => (
                  <a
                    href={config.urls.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }
            )
          }
        </p>
        {formatMessage({
          id: 'ForFilesInLanguagesOtherThanEnglishOurTeamUtilizesGoogleTranslateForConversion',
        })}
      </Disclaimer>
    </Container>
  );
};

export default TextColumn;
