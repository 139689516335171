import { createReducer } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { getInitialFilters } from '@utils';
import { actionsActions as actions } from '@application/Actions/store/actionsActions';
import { ActionDueDateStatus } from '@application/Actions/enums/ActionDueDateStatus';
import { actionsAdapter } from '@application/Actions/store/entityAdapters';
import { ActionStatus, AssignmentType } from '@repo/shared/enums';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { ActionsState } from '@application/Actions/models/ActionsState';

export const getInitialActionsFilters = () => ({
  ...getInitialFilters(),
  status: null,
  templateId: null,
  priority: null,
  orderBy: 'dueDate',
  createdBy: null,
  approvedBy: null,
  createdByGroup: null,
  assignedTo: null,
  assignedToGroup: null,
  auditObjectIds: [],
  auditObjectGroupIds: [],
  startDate: null,
  endDate: null,
  completeAuditDate: null,
  auditId: null,
  assignment: AssignmentType.all,
  dueDateStatus: ActionDueDateStatus.All,
  tagsIds: [],
});

const initialActionsFilters = getInitialActionsFilters();

const initialActionsListState = {
  data: actionsAdapter.getInitialState(),
  loading: false,
  meta: null,
  error: null,
  filters: {
    [ActionsPage.My]: { ...initialActionsFilters },
    [ActionsPage.Pending]: { ...initialActionsFilters },
    [ActionsPage.Completed]: { ...initialActionsFilters },
    [ActionsPage.Expired]: { ...initialActionsFilters },
  },
  page: null,
  showFiltersModal: false,
  currentRequestId: null,
};

const initialActionDetailsState = {
  id: null,
  loading: false,
  data: null,
  error: null,
  deleteActionCommentModal: null,
  editingCommentId: null,
};

export const actionsReducer = createReducer<ActionsState>(
  {
    actionsList: {
      ...initialActionsListState,
    },
    actionDetails: {
      ...initialActionDetailsState,
    },
    auditActions: {
      data: actionsAdapter.getInitialState(),
      loading: false,
      error: null,
    },
    changeStatusModal: { status: null },
    assignUsersToActionsModal: { opened: false },
  },
  (builder) =>
    builder
      .addCase(
        actions.getActions.pending,
        (state, { meta: { arg, requestId } }) => {
          state.actionsList.loading = true;
          state.actionsList.error = null;

          if (state.actionsList.page === ActionsPage.My && arg === null) {
            state.actionsList.data = actionsAdapter.getInitialState();
          }

          if (
            state.actionsList.page !== null &&
            state.actionsList.filters !== null
          ) {
            const newFilters =
              arg === null
                ? {
                    ...getInitialActionsFilters(),
                  }
                : {
                    ...state.actionsList.filters[state.actionsList.page],
                    ...(arg || {}),
                  };

            state.actionsList.filters = {
              ...state.actionsList.filters,
              [state.actionsList.page]: newFilters,
            };

            state.actionsList.currentRequestId = requestId;
          }
        }
      )
      .addCase(actions.getActions.fulfilled, (state, { payload, meta }) => {
        if (state.actionsList.currentRequestId === meta.requestId) {
          if (state.actionsList.page === ActionsPage.My && meta.arg !== null) {
            actionsAdapter.addMany(state.actionsList.data, payload.data);
          } else {
            actionsAdapter.setAll(state.actionsList.data, payload.data);
          }
          state.actionsList.meta = payload.meta;
          state.actionsList.loading = false;
        }
      })
      .addCase(actions.getActions.rejected, (state, { payload, meta }) => {
        if (state.actionsList.currentRequestId === meta.requestId) {
          state.actionsList.loading = false;
          state.actionsList.error = payload || null;
        }
      })
      .addCase(actions.toggleChangeStatusModal, (state, { payload }) => {
        state.changeStatusModal =
          payload.status !== null
            ? {
                status: payload.status,
                ids: payload.ids,
              }
            : { status: null };
      })
      .addCase(
        actions.changeActionsStatuses.fulfilled,
        (state, { payload: { status, ids } }) => {
          if (
            state.actionDetails.data &&
            ids.includes(state.actionDetails.data.id)
          ) {
            state.actionDetails.data.status = status;
          }
        }
      )
      .addCase(actions.getActionDetails.pending, (state) => {
        state.actionDetails.loading = true;
      })
      .addCase(actions.getActionDetails.fulfilled, (state, { payload }) => {
        state.actionDetails.data = {
          ...payload,
          comments: payload.comments.sort((a, b) =>
            dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1
          ),
        };
        state.actionDetails.loading = false;
      })
      .addCase(actions.getActionDetails.rejected, (state, { payload }) => {
        state.actionDetails.loading = false;
        state.actionDetails.error = payload || null;
      })
      .addCase(actions.updateAction.fulfilled, (state, { payload }) => {
        if (state.actionDetails.data?.id === payload.id) {
          state.actionDetails.data = {
            ...state.actionDetails.data,
            ...payload,
          };
        }

        const actionInList = actionsAdapter
          .getSelectors()
          .selectById(state.actionsList.data, payload.id);

        if (actionInList) {
          actionsAdapter.updateOne(state.actionsList.data, {
            id: payload.id,
            changes: { ...payload },
          });
        }
      })
      .addCase(actions.resetListData, (state) => {
        state.actionsList = {
          ...state.actionsList,
          showFiltersModal: false,
          currentRequestId: null,
          data: actionsAdapter.getInitialState(),
          loading: false,
          meta: null,
          error: null,
        };

        if (state.actionsList.page !== null) {
          state.actionsList.filters = {
            ...state.actionsList.filters,
            [state.actionsList.page]: {
              ...state.actionsList.filters[state.actionsList.page],
              pageNumber: 1,
            },
          };
        }
      })
      .addCase(actions.resetActionDetailsData, (state) => {
        state.actionDetails = {
          ...initialActionDetailsState,
        };
      })
      .addCase(actions.toggleFiltersModal, (state, { payload }) => {
        state.actionsList.showFiltersModal = payload;
      })
      .addCase(
        actions.createActionComment.pending,
        (state, { meta: { arg } }) => {
          if (state.actionDetails.data) {
            state.actionDetails.data.comments.push(arg.comment);
          }
        }
      )
      .addCase(actions.createActionComment.rejected, (state) => {
        if (state.actionDetails.data) {
          state.actionDetails.data.comments.pop();
        }
      })
      .addCase(
        actions.editActionComment.pending,
        (state, { meta: { arg } }) => {
          if (state.actionDetails.data) {
            for (let i = 0; i < state.actionDetails.data.comments.length; i++) {
              if (
                state.actionDetails.data.comments[i].id ===
                state.actionDetails.editingCommentId
              ) {
                state.actionDetails.data.comments[i] = {
                  ...state.actionDetails.data.comments[i],
                  files: arg.comment.files,
                  body: arg.comment.body,
                };
                break;
              }
            }
          }
        }
      )
      .addCase(actions.toggleDeleteActionCommentModal, (state, { payload }) => {
        state.actionDetails.deleteActionCommentModal = payload;
      })
      .addCase(actions.deleteActionComment.fulfilled, (state, { payload }) => {
        if (state.actionDetails.data) {
          state.actionDetails.data.comments =
            state.actionDetails.data.comments.filter(
              ({ id }) => id !== payload
            );
        }
      })
      .addCase(actions.setEditCommentId, (state, { payload }) => {
        state.actionDetails.editingCommentId = payload;
      })
      .addCase(actions.setPage, (state, { payload }) => {
        state.actionsList.page = payload;
      })
      .addCase(
        actions.toggleAssignUsersToActionsModal,
        (state, { payload }) => {
          state.assignUsersToActionsModal =
            payload === null ? { opened: false } : { opened: true, ...payload };
        }
      )
      .addCase(actions.expireActions.fulfilled, (state, { payload }) => {
        if (
          state.actionDetails.data &&
          payload.includes(state.actionDetails.data.id)
        ) {
          state.actionDetails.data.status = ActionStatus.Expired;
        }
      })
      .addCase(
        actions.updateActionDetailsAssignees,
        (state, { payload: { actionId, assignees } }) => {
          if (state.actionDetails.data?.id === actionId) {
            state.actionDetails.data.assignedUsers = assignees;
          }

          const actionInList = actionsAdapter
            .getSelectors()
            .selectById(state.actionsList.data, actionId);

          if (actionInList) {
            actionsAdapter.updateOne(state.actionsList.data, {
              id: actionId,
              changes: { assignedUsers: assignees },
            });
          }
        }
      )
      .addCase(actions.getAllAuditActions.pending, (state) => {
        state.auditActions.loading = true;
        state.auditActions.error = null;
      })
      .addCase(actions.getAllAuditActions.fulfilled, (state, { payload }) => {
        state.auditActions.loading = false;
        actionsAdapter.setAll(state.auditActions.data, payload);
      })
      .addCase(actions.getAllAuditActions.rejected, (state, { payload }) => {
        state.auditActions.loading = false;
        state.auditActions.error = payload || null;
      })
      .addCase(actions.toggleActionDetailsModal, (state, { payload }) => {
        state.actionDetails.id = payload;

        if (payload === null) {
          state.actionDetails = {
            ...initialActionDetailsState,
          };
        } else {
          state.actionDetails.id = payload;
          state.actionDetails.loading = true;
        }
      })
      .addCase(actions.deleteActionLocally, (state, { payload }) => {
        actionsAdapter.removeOne(state.actionsList.data, payload);
      })
      .addCase(actions.updateActionLocally, (state, { payload }) => {
        actionsAdapter.updateOne(state.actionsList.data, payload);
      })
);
