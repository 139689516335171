import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { createAddOrUpdateEntityThunk, createDeleteEntityThunk } from '@utils';
import { getErrorMessage } from '@repo/shared/utils';
import { ITag, IPagedResponse, ITagsFilters } from '@repo/shared/types';
import { IRootState } from '../../../frameworks/redux';
import { apiUrls } from '@config';
import { Logger } from '@repo/shared/services';
import { InternalApiService } from '@repo/shared/api';
import { TagsPage } from '@types';

const apiService = InternalApiService.getInstance();

export const tagsActions = {
  setPage: createAction<TagsPage>('tags/setPage'),
  getTags: createAsyncThunk<
    IPagedResponse<ITag>,
    Partial<ITagsFilters> | undefined,
    { state: IRootState; rejectValue: string }
  >('tags/getTags', async (_, { getState, rejectWithValue }) => {
    const { filters, page } = getState().tags;

    try {
      return apiService.get({
        url: `${apiUrls.tags}/detailed`,
        query: {
          ...filters[page],
        },
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  updateTag: createAddOrUpdateEntityThunk<ITag>({
    apiUrl: apiUrls.tags,
    entityName: 'tags',
  }),
  deleteTags: createDeleteEntityThunk({
    apiUrl: apiUrls.tags,
    entityName: 'tags',
  }),
};
