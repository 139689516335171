import React from 'react';
import { X } from 'react-feather';

import { useAppDispatch } from '@hooks';
import { Container, CloseButton, LeftContent } from './styled';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { e2eTestElements } from '@config';

import ActionDetailsHeaderMenu from '@src/presentation/Actions/ActionDetails/ActionDetailsHeader/ActionDetailsHeaderMenu/ActionDetailsHeaderMenu';
import ActionDetailsStatusButtons from '@src/presentation/Actions/ActionDetails/ActionDetailsHeader/ActionDetailsStatusButtons/ActionDetailsStatusButtons';

interface IProps {}

const ActionDetailsHeader: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <LeftContent>
        <ActionDetailsHeaderMenu />
      </LeftContent>
      <ActionDetailsStatusButtons />
      <div>
        <CloseButton
          data-e2e={e2eTestElements.actions.detailsModal.backBtn}
          type="button"
          onClick={() => {
            dispatch(actionsActions.toggleActionDetailsModal(null));
          }}
        >
          <X />
        </CloseButton>
      </div>
    </Container>
  );
};

export default ActionDetailsHeader;
