import React from 'react';
import { Check } from 'react-feather';

import { OptGroupHeader, RowButton } from './styled';
import { IParticipant, IConcise } from '@repo/shared/types';

import User from '../../../User/User';
import JobTitlesList from '@components/shared/Assignees/JobTitlesList/JobTitlesList';

interface IProps {
  label: string;
  options: (IConcise | IParticipant)[];
  selected: (IConcise | IParticipant)[];
  onSelect: (selected: (IConcise | IParticipant)[]) => void;
  showJobTitles?: boolean;
}

const AssigneeOptionGroup: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  options,
  selected,
  onSelect,
  showJobTitles,
}) => {
  if (options.length === 0) {
    return null;
  }

  return (
    <>
      <OptGroupHeader>{label}</OptGroupHeader>
      {options.map((participant: IConcise | IParticipant) => {
        const isSelected = selected.find(
          (u: IConcise | IParticipant) => u.id === participant.id
        );

        return (
          <RowButton
            type="button"
            key={participant.id}
            onClick={() => {
              onSelect(
                isSelected
                  ? selected.filter(
                      (u: IConcise | IParticipant) => u.id !== participant.id
                    )
                  : [...selected, participant]
              );
            }}
          >
            <User
              extraName={
                <JobTitlesList show={showJobTitles} assignee={participant} />
              }
            >
              {participant.name}
            </User>
            {isSelected && <Check />}
          </RowButton>
        );
      })}
    </>
  );
};

export default AssigneeOptionGroup;
