import { ItemFlag } from '@repo/shared/enums';
import { colors } from '@repo/shared/config/colors';

export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600,
};

export const FLAGS = {
  [ItemFlag.Warning]: {
    color: colors.yellow,
    langId: 'Warning',
  },
  [ItemFlag.NeedsTraining]: {
    color: colors.orange,
    langId: 'NeedsTraining',
  },
  [ItemFlag.Urgent]: {
    color: colors.red,
    langId: 'Urgent',
  },
  [ItemFlag.GreatJob]: {
    color: colors.green,
    langId: 'GreatJob',
  },
  [ItemFlag.FollowUp]: {
    color: colors.blue2,
    langId: 'FollowUp',
  },
  [ItemFlag.NeedsAssistance]: {
    color: colors.blue4,
    langId: 'NeedsAssistance',
  },
  [ItemFlag.CorrectedOnSite]: {
    color: colors.wingstop,
    langId: 'CorrectedOnSite',
  },
};
