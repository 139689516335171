import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { AlertTriangle } from 'react-feather';
import { colors } from '@repo/shared/config';

interface IProps {
  error?: string | null;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  text-align: center;
  max-width: 300px;
  line-height: 1.4;

  svg {
    width: 50px;
    height: 50px;
    color: ${colors.red};
    margin-bottom: 10px;
  }
`;

const Error: React.FC<React.PropsWithChildren<IProps>> = ({ error }) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Content>
        <AlertTriangle />
        {error || formatMessage({ id: 'UnexpectedError' })}
      </Content>
    </Container>
  );
};

export default Error;
