import React from 'react';
import { useIntl } from 'react-intl';

import { Container, Icon } from './styled';

interface IProps {}

const NoCommentsPlaceholder: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Icon />
      {formatMessage({ id: 'CurrentlyThereAreNoActivitiesHere' })}
    </Container>
  );
};

export default NoCommentsPlaceholder;
