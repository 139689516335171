import { IConcise, ZonedDateTime } from '@repo/shared/types';
import { AuditStatus, Priority, ActionStatus } from '@repo/shared/enums';
import { IssueQuestion } from '@domain/Issues/models/IssueQuestion';
import { IssueTypeContactInformationField } from '@domain/IssueTypes/models/IssueType';

export enum IssueStatus {
  Opened,
  Closed,
}

export interface Issue {
  id: string;
  name: string | null;
  auditObject: IConcise;
  type: IConcise;
  status: IssueStatus;
  priority: Priority;
  createdBy: IConcise;
  createDate: ZonedDateTime;
  dueDate: ZonedDateTime;
  closedDate: ZonedDateTime | null;
  description: string;
  isSample: boolean;
  dueInDays?: number;
  assignedUsers: IConcise[];
  ianaTimeZone: string;
  timeZoneAbbreviation: string;
  number: string | null;
  firstQuestion: string;
  firstQuestionAnswer: string;
}

export interface IssueDetailsAudit {
  id: string;
  name: string;
  number: number | null;
  status: AuditStatus;
  dueInDays: number | null;
  scoreLabel: string | null;
  scoreColor: string | null;
}

export interface IssueDetailsAction {
  id: string;
  name: string;
  number: number | null;
  status: ActionStatus;
  dueInDays: number | null;
}

export interface IssueContactInformationField
  extends IssueTypeContactInformationField {
  value: string;
}

export interface IssueDetails extends Issue {
  questions: IssueQuestion[];
  audits: IssueDetailsAudit[];
  correctiveActions: IssueDetailsAction[];
  contactInformationFields: IssueContactInformationField[];
}
