import { createReducer, Reducer } from '@reduxjs/toolkit';

import { generalActions } from '../general/general.actions';
import { dashboardActions as actions } from './dashboard.actions';
import {
  DashboardDateRangeType,
  ShowUnresolvedActionsBy,
  AuditDueDateStatus,
} from '@repo/shared/enums';
import { IUnresolvedActionsChartItem } from '@repo/shared/types';
import { IDashboardState } from '@types';
import { ActionStatus } from '@repo/shared/enums';
import { getInitialAuditsFilters } from '@application/Audits/utils/getInitialAuditsFilters';
import { config, colors } from '@repo/shared/config';
import { date } from '@utils';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { auditsDashboardAdapter } from '../../entityAdapters';
import { Priority } from '@repo/shared/enums';

const auditCompletionInitialFilters = {
  dateRangeType: DashboardDateRangeType.PriorWeek,
  startDate: date()
    .subtract(1, 'week')
    .startOf('day')
    .format(config.apiDateFormat),
  endDate: date().endOf('day').format(config.apiDateFormat),
};

const unresolvedActionFilters = {
  showBy: ShowUnresolvedActionsBy.ByStatus,
};

const initialState = {
  error: null,
  auditsTotals: {
    data: null,
    error: null,
    loading: false,
  },
  auditsCompletion: {
    data: null,
    filters: auditCompletionInitialFilters,
    error: null,
    loading: false,
  },
  unresolvedActions: {
    data: null,
    filters: unresolvedActionFilters,
    error: null,
    loading: false,
  },
  audits: {
    data: auditsDashboardAdapter.getInitialState(),
    error: null,
    loading: false,
    filters: {
      ...getInitialAuditsFilters(),
      dueDateStatus: AuditDueDateStatus.DueSoon,
      pageSize: 1000,
    },
  },
};

export const dashboardReducer: Reducer<IDashboardState> =
  createReducer<IDashboardState>(initialState, (builder) =>
    builder
      .addCase(actions.getTotals.pending, (state) => {
        state.auditsTotals.data = null;
        state.auditsTotals.error = null;
        state.auditsTotals.loading = true;
      })
      .addCase(actions.getTotals.fulfilled, (state, { payload }) => {
        state.auditsTotals.data = payload;
        state.auditsTotals.loading = false;
      })
      .addCase(actions.getTotals.rejected, (state, { payload }) => {
        if (payload) {
          state.auditsTotals.error = payload;
        }

        state.auditsTotals.loading = true;
      })
      .addCase(
        actions.getAuditsCompletion.pending,
        (state, { meta: { arg } }) => {
          state.auditsCompletion.error = null;
          state.auditsCompletion.data = null;
          state.auditsCompletion.loading = true;
          state.auditsCompletion.filters = {
            ...state.auditsCompletion.filters,
            ...(arg || {}),
          };
        }
      )
      .addCase(
        actions.getAuditsCompletion.fulfilled,
        (state, { payload: { data, averageScore } }) => {
          state.auditsCompletion.data = {
            chart: data,
            averageScore,
          };
          state.auditsCompletion.loading = false;
        }
      )
      .addCase(actions.getAuditsCompletion.rejected, (state, { payload }) => {
        if (payload) {
          state.auditsCompletion.error = payload;
        }

        state.auditsCompletion.loading = false;
      })
      .addCase(
        actions.getUnresolvedActions.pending,
        (state, { meta: { arg } }) => {
          state.unresolvedActions.error = null;
          state.unresolvedActions.data = null;
          state.unresolvedActions.loading = true;
          state.unresolvedActions.filters = {
            ...state.unresolvedActions.filters,
            ...(arg || {}),
          };
        }
      )
      .addCase(actions.getUnresolvedActions.fulfilled, (state, { payload }) => {
        if (!intl) {
          return;
        }

        const { showBy } = state.unresolvedActions.filters;

        state.unresolvedActions.data = Object.keys(payload).map((key) => {
          const { total, ...totals } = payload[key];

          const data =
            showBy === ShowUnresolvedActionsBy.ByPriority
              ? {
                  low: {
                    value: Priority.Low,
                    color: colors.blue2,
                    label: intl?.formatMessage({ id: 'Low' }),
                  },
                  medium: {
                    value: Priority.Medium,
                    color: colors.yellow,
                    label: intl?.formatMessage({ id: 'Medium' }),
                  },
                  high: {
                    value: Priority.High,
                    color: colors.red,
                    label: intl?.formatMessage({ id: 'High' }),
                  },
                }
              : {
                  open: {
                    value: ActionStatus.Opened,
                    color: colors.blue2,
                    label: intl?.formatMessage({ id: 'Opened' }),
                  },
                  submitted: {
                    value: ActionStatus.Submitted,
                    color: colors.yellow,
                    label: intl?.formatMessage({ id: 'Submitted' }),
                  },
                  rejected: {
                    value: ActionStatus.Rejected,
                    color: colors.red,
                    label: intl?.formatMessage({ id: 'Rejected' }),
                  },
                };

          return {
            count: total,
            ...data[key as keyof typeof data],
            ...totals,
          };
        }) as IUnresolvedActionsChartItem[];

        state.unresolvedActions.loading = false;
      })
      .addCase(actions.getUnresolvedActions.rejected, (state, { payload }) => {
        state.unresolvedActions.error = payload || null;
        state.unresolvedActions.loading = false;
      })
      .addCase(actions.getAudits.pending, (state, { meta: { arg } }) => {
        state.audits.filters = {
          ...state.audits.filters,
          ...(arg || {}),
        };
        state.audits.loading = true;
        state.audits.error = null;
      })
      .addCase(actions.getAudits.fulfilled, (state, { payload }) => {
        auditsDashboardAdapter.setAll(state.audits.data, payload);
        state.audits.loading = false;
      })
      .addCase(actions.getAudits.rejected, (state, { payload: error }) => {
        state.audits.loading = false;
        state.audits.error = error || null;
      })
      .addCase(
        actions.setDashboardLoadingStatus,
        (state, { payload: loading }) => {
          state.unresolvedActions.loading = loading;
          state.auditsCompletion.loading = loading;
          state.auditsTotals.loading = loading;
          state.audits.loading = loading;
        }
      )
      .addCase(generalActions.resetAccountData, () => {
        return {
          ...initialState,
        };
      })
  );
