import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import {
  AuditTemplateBuilderMode,
  ConfirmDeleteItem,
  EditIntervalsModalState,
  ExpandedItems,
  SaveChangesModalState,
  SetupFormValidationStatus,
} from '@application/AuditTemplateBuilder/models/AuditTemplateBuilderState';
import {
  calculateItemTreeStats,
  calculateTemplatePreviewTotals,
  checkIsChildNADisabledInTree,
  checkIsParentNADisabled,
  convertItemsMapToTree,
  convertTemplateItemsMapToTree,
  convertTemplateItemToAuditItemPreview,
  getAncestorsSections,
  isNumericAnswerData,
  isSliderAnswerData,
  isTemperatureAnswerData,
} from '@utils';
import { IScore, ITemplateItem } from '@repo/shared/types';
import { ItemType } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';
import { AuditTemplateDetails } from '@repo/shared/types';

const getTemplateDetails = (state: IRootState): AuditTemplateDetails | null =>
  state.auditTemplateBuilder.template;

const getItemsDictionary = (
  state: IRootState
): Record<string, ITemplateItem> | null =>
  state.auditTemplateBuilder.template?.data || null;

const getRootItemId = (state: IRootState): string | null =>
  state.auditTemplateBuilder.rootItemId;

const getExpandedAnswers = (state: IRootState): ExpandedItems =>
  state.auditTemplateBuilder.expanded.answers;

const getMode = (state: IRootState): AuditTemplateBuilderMode =>
  state.auditTemplateBuilder.mode;

const isLoading = (state: IRootState): boolean =>
  state.auditTemplateBuilder.loading;

const hasUnsavedChanges = (state: IRootState): boolean =>
  state.auditTemplateBuilder.template?.hasChanges || false;

const getSetupFormValidationStatus = (
  state: IRootState
): SetupFormValidationStatus => state.auditTemplateBuilder.validationStatus;

const getError = (state: IRootState): string | null =>
  state.auditTemplateBuilder.error;

const getOpenedSectionId = (state: IRootState): string | null =>
  state.auditTemplateBuilder.openedSectionId;

const getTemplateItemTree = createSelector(
  [isLoading, getItemsDictionary, getError, getRootItemId, getOpenedSectionId],
  (loading, items, error, rootItemId, openedSectionId) => ({
    loading,
    tree: convertTemplateItemsMapToTree(
      items,
      openedSectionId || rootItemId,
      true
    ),
    error,
    index: openedSectionId ? items?.[openedSectionId]?.index : 0,
  })
);

const getScrollItemId = (state: IRootState): string | null =>
  state.auditTemplateBuilder.scrollItemId;

const makeGetTemplatePreviewItemTree = () =>
  createSelector(
    [
      getItemsDictionary,
      (state: IRootState, parentId?: string | null) => parentId,
    ],
    (items, rootItemId) => {
      if (!items || !rootItemId) {
        return {
          tree: null,
          isParentNADisabled: false,
          isChildNADisabled: false,
        };
      }

      const isParentNADisabled = checkIsParentNADisabled(
        items,
        rootItemId,
        true
      );

      const itemsTree = convertItemsMapToTree({
        itemsMap: items,
        rootItemId,
        includeCondition: true,
      });

      const isChildNADisabled = checkIsChildNADisabledInTree(itemsTree);

      return {
        isParentNADisabled,
        tree: itemsTree,
        isChildNADisabled,
      };
    }
  );

const geTemplateScoreSystem = (state: IRootState): IScore | null =>
  state.auditTemplateBuilder.scoreSystem;

const makeGetStatsById = () =>
  createSelector(
    [
      geTemplateScoreSystem,
      getItemsDictionary,
      getRootItemId,
      (state: IRootState, rootItemId: string | null) => rootItemId,
    ],
    (auditScoreSystem, items, templateRootItemId, rootItemId) => {
      if (!items || !rootItemId || !items[rootItemId] || !templateRootItemId) {
        return null;
      }

      return calculateItemTreeStats({
        itemsMap: items,
        rootItemId,
        templateOrAuditRootItemId: templateRootItemId,
        auditScoreSystem,
      });
    }
  );

const makeGetTemplateItemPreviewById = () =>
  createSelector(
    [getItemsDictionary, (_: IRootState, id: string | null) => id],
    (items, id) =>
      id && items?.[id]
        ? convertTemplateItemToAuditItemPreview(items[id])
        : null
  );

const getTemplatePreviewTotals = createSelector(
  [getItemsDictionary, getRootItemId],
  (items, rootItemId) => {
    return rootItemId && items
      ? calculateTemplatePreviewTotals(items, rootItemId)
      : null;
  }
);

const getRootItem = createSelector(
  [getItemsDictionary, getRootItemId],
  (items, rootItemId) => (items && rootItemId ? items[rootItemId] : null)
);

const getItemsCount = createSelector(getItemsDictionary, (items) =>
  items ? Object.keys(items).length : 0
);

const makeIsNADisabled = () =>
  createSelector(
    [
      getItemsDictionary,
      (state: IRootState, id: string | null | undefined) => id,
    ],
    (items, itemId) => {
      return !itemId || !items?.[itemId]
        ? false
        : checkIsParentNADisabled(items, itemId);
    }
  );

const isInformationPhotosUploading = (state: IRootState): boolean | null =>
  state.auditTemplateBuilder.isInformationPhotosUploading;

const getConfirmDeleteItem = (state: IRootState): ConfirmDeleteItem =>
  state.auditTemplateBuilder.confirmDeleteItem;

const isSectionExpanded = (sectionId: string) => (state: IRootState) =>
  state.auditTemplateBuilder.expanded.sections[sectionId] !== undefined;

const isAnswerExpanded = (itemId: string) => (state: IRootState) =>
  state.auditTemplateBuilder.expanded.answers[itemId] !== undefined;

const getTemplateSections = createSelector(
  [getItemsDictionary, getRootItemId],
  (items, rootItemId) => {
    if (!items || !rootItemId || !items[rootItemId]) {
      return [];
    }

    return items[rootItemId].childrenIds
      .slice()
      .sort((id1: string, id2: string) => items[id1].index - items[id2].index)
      .map((id: string) => items[id]);
  }
);

const getSectionVisibilityModalData = createSelector(
  [
    getItemsDictionary,
    (state: IRootState): string | null =>
      state.auditTemplateBuilder.sectionVisibilityModalId,
  ],
  (items, sectionVisibilityModalId) => {
    if (
      sectionVisibilityModalId === null ||
      !items?.[sectionVisibilityModalId]
    ) {
      return null;
    }

    return items[sectionVisibilityModalId];
  }
);

const getSaveChangesModalState = (state: IRootState): SaveChangesModalState =>
  state.auditTemplateBuilder.saveChangesModal;

const isPublishChangesModalOpened = (state: IRootState): boolean =>
  state.auditTemplateBuilder.showPublishChangesModal;

const isConfirmTemplateDeleteModalOpened = (state: IRootState): boolean =>
  state.auditTemplateBuilder.showConfirmTemplateDeleteModal;

const isCreateDraftCopyModalOpened = (state: IRootState): boolean =>
  state.auditTemplateBuilder.showCreateDraftCopyModal;

const getAnswerValidation = (
  state: IRootState
): {
  errors: Record<string, Record<string, string>>;
} => state.auditTemplateBuilder.answerValidation;

const getErrors = (answerId: string) =>
  createSelector(getAnswerValidation, ({ errors }) => errors[answerId] || {});

const hasErrors = createSelector(getAnswerValidation, ({ errors }) => {
  return Object.values<Record<string, string>>(errors).some(
    (answerErrors) => Object.keys(answerErrors).length > 0
  );
});

const getEditIntervalsModalState = (
  state: IRootState
): EditIntervalsModalState => state.auditTemplateBuilder.editIntervalsModal;

const getEditIntervalsModalData = createSelector(
  [getItemsDictionary, getEditIntervalsModalState],
  (items, modalState) => {
    const data = items && modalState ? items[modalState.itemId]?.data : null;

    if (
      !data ||
      (!isNumericAnswerData(data) &&
        !isSliderAnswerData(data) &&
        !isTemperatureAnswerData(data))
    ) {
      return null;
    }

    return { ...modalState, data };
  }
);

const getActionTemplatesItemsQty = createSelector(
  getItemsDictionary,
  (items) => {
    if (!items) {
      return [];
    }

    const actionTemplatesItemsQty: Record<
      string,
      { actionTemplateId: string; itemsQty: number }
    > = {};

    for (let itemId in items) {
      const item = items[itemId];

      if (
        (item.itemType === ItemType.Item ||
          item.itemType === ItemType.ConditionalItem) &&
        item.actionTemplates.length > 0
      ) {
        for (let i = 0; i < item.actionTemplates.length; i++) {
          const actionTemplateId = item.actionTemplates[i].id;

          if (!actionTemplatesItemsQty[actionTemplateId]) {
            actionTemplatesItemsQty[actionTemplateId] = {
              actionTemplateId,
              itemsQty: 0,
            };
          }

          actionTemplatesItemsQty[actionTemplateId].itemsQty++;
        }
      }
    }

    return Object.values(actionTemplatesItemsQty);
  }
);

const getItemsByActionTemplateGroupBySection = createSelector(
  [
    (state: IRootState): string | null =>
      state.auditTemplateBuilder.itemsByActionTemplateModalTemplateId,
    getItemsDictionary,
    getRootItemId,
  ],
  (itemsByActionTemplateModalTemplateId, items, rootItemId) => {
    if (!itemsByActionTemplateModalTemplateId || !items || !rootItemId) {
      return null;
    }

    const itemsWithActionTemplate = Object.values(items).filter((item) =>
      item.actionTemplates.some(
        (actionTemplate) =>
          actionTemplate.id === itemsByActionTemplateModalTemplateId
      )
    );

    const sectionsMap: Record<
      string,
      {
        sectionId: string;
        items: ITemplateItem[];
      }
    > = {};

    for (let i = 0; i < itemsWithActionTemplate.length; i++) {
      const { section } = getAncestorsSections(
        items,
        itemsWithActionTemplate[i].id,
        rootItemId
      );

      if (!section) {
        continue;
      }

      if (!sectionsMap[section.id]) {
        sectionsMap[section.id] = {
          sectionId: section.id,
          items: [],
        };
      }

      sectionsMap[section.id].items.push(itemsWithActionTemplate[i]);
    }

    return {
      itemsQty: itemsWithActionTemplate.length,
      sections: Object.values(sectionsMap),
      actionTemplateId: itemsByActionTemplateModalTemplateId,
    };
  }
);

const getAccentColor = createSelector(
  [(state: IRootState) => state.account.company, getTemplateDetails],
  (company, template) =>
    template?.pdfSettings.accentColor || company?.accentColor || colors.blue2
);

const isPublished = createSelector(
  getTemplateDetails,
  (template) => !!template && !template.isDraft
);

const isGalleryUsageAllowed = (state: IRootState) =>
  !state.auditTemplateBuilder.template?.restrictGalleryUsage;

export const auditTemplateBuilderSelectors = {
  getTemplateDetails,
  getRootItemId,
  getExpandedAnswers,
  getMode,
  isLoading,
  hasUnsavedChanges,
  getSetupFormValidationStatus,
  getTemplateItemTree,
  getScrollItemId,
  makeGetTemplatePreviewItemTree,
  makeGetStatsById,
  makeGetTemplateItemPreviewById,
  getTemplatePreviewTotals,
  getItemsDictionary,
  getRootItem,
  getItemsCount,
  makeIsNADisabled,
  getOpenedSectionId,
  isInformationPhotosUploading,
  isSectionExpanded,
  isAnswerExpanded,
  getTemplateSections,
  getSectionVisibilityModalData,
  getSaveChangesModalState,
  isPublishChangesModalOpened,
  isConfirmTemplateDeleteModalOpened,
  answerValidation: {
    getErrors,
    hasErrors,
  },
  getEditIntervalsModalData,
  getActionTemplatesItemsQty,
  getItemsByActionTemplateGroupBySection,
  getAccentColor,
  getConfirmDeleteItem,
  isPublished,
  isCreateDraftCopyModalOpened,
  isGalleryUsageAllowed,
};
