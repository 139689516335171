import React, { useEffect } from 'react';

import { Permission } from '@repo/shared/enums';
import { convertQueryParamToAssignment } from '@utils';
import { getQueryStringParams } from '@repo/shared/utils';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { useAppDispatch, usePermission } from '@hooks';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';

import Audits from './Audits';

const ExpiredAudits: React.FC = () => {
  const dispatch = useAppDispatch();

  const [canViewAuditResults] = usePermission([Permission.CanViewAuditResults]);

  useEffect(() => {
    dispatch(auditsActions.setAuditsPage(AuditsPage.Expired));

    const { assignment, ...query } = getQueryStringParams([
      'auditObjectIds',
      'auditObjectGroupIds',
      'templateId',
      'assignment',
      'isAutoExpired',
    ]);

    const defaultFilters: Partial<AuditsFilters> = {
      ...query,
      orderBy: 'expiredDate',
      orderByDirection: 'desc',
    };

    if (assignment !== undefined) {
      defaultFilters.assignment = convertQueryParamToAssignment(assignment);
    }

    dispatch(auditsActions.getAudits(defaultFilters));

    return () => {
      dispatch(auditsActions.setAuditsPage(null));
    };
  }, [dispatch, canViewAuditResults]);

  return <Audits page={AuditsPage.Expired} />;
};

export default ExpiredAudits;
