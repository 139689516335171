import styled from 'styled-components';

import NotFoundIcon from './404.svg';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 100px 0;
`;

export const Image = styled.div`
  width: 563px;
  height: 274px;
  margin-bottom: 40px;
  background: url(${NotFoundIcon}) no-repeat center center;
`;

export const Title = styled.h2`
  margin-bottom: 5px;
`;

export const Desc = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  color: ${colors.gray8};
`;
