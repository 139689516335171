import React from 'react';
import { HelpCircle } from 'react-feather';

import { Trigger, Content } from './styled';
import { colors } from '@repo/shared/config';

import Popover from '../ant/Popover';

interface IProps {
  children: React.ReactNode;
  contentMaxWidth?: number;
  circleBg?: string;
  hoverCircleBg?: string;
  icon?: React.ReactNode;
  triggerStyles?: React.CSSProperties;
}

const CircleTooltip: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  contentMaxWidth,
  circleBg = colors.gray8,
  hoverCircleBg = colors.blue2,
  icon = <HelpCircle />,
  triggerStyles,
}) => {
  return (
    <Popover zIndex={3000} content={<Content maxWidth={contentMaxWidth}>{children}</Content>}>
      <Trigger
        circleBg={circleBg}
        hoverCircleBg={hoverCircleBg}
        style={triggerStyles}
      >
        {icon}
      </Trigger>
    </Popover>
  );
};

export default CircleTooltip;
