import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/es/select';

import { accountSelectors } from '@store';
import { filterOptionsByChildren } from '@utils';
import { e2eTestElements, apiUrls } from '@config';

import EntityAndGroupSelectAsync from './EntityAndGroupSelectAsync';

interface IProps extends Omit<SelectProps<any>, 'value' | 'onChange'> {
  value?: {
    auditObjectIds: string[];
    auditObjectGroupIds: string[];
  };
  onChange?: (update: {
    auditObjectIds: string[];
    auditObjectGroupIds: string[];
  }) => void;
  useAccessible?: boolean;
  width?: string;
}

const AuditObjectOrAuditObjectGroupSelect: React.FC<
  React.PropsWithChildren<IProps>
> = ({
  disabled,
  placeholder,
  value,
  onChange,
  useAccessible,
  width,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const auditObjectName = useSelector(accountSelectors.getObjectName);

  return (
    <EntityAndGroupSelectAsync
      {...props}
      entity={{
        api: useAccessible
          ? `${apiUrls.auditObjects}/accessible/concise`
          : `${apiUrls.auditObjects}/concise`,
        label: auditObjectName.plural,
      }}
      group={{
        api: `${apiUrls.auditObjectsGroups}/concise`,
        label: formatMessage(
          { id: 'AuditObjectsGroups' },
          { objectName: auditObjectName.plural }
        ),
      }}
      value={{
        entityIds: value?.auditObjectIds || [],
        groupIds: value?.auditObjectGroupIds || [],
      }}
      onChange={({ groupIds, entityIds }) => {
        if (!onChange) {
          return;
        }

        onChange({
          auditObjectIds: entityIds,
          auditObjectGroupIds: groupIds,
        });
      }}
      disabled={disabled}
      placeholder={
        placeholder ||
        formatMessage(
          { id: 'SelectAuditObjectOrAuditObjectGroup' },
          {
            objectName: auditObjectName.single.toLowerCase(),
            objectNamePlural: auditObjectName.plural.toLowerCase(),
          }
        )
      }
      width={width}
      showSearch
      optionFilterProp="children"
      filterOption={filterOptionsByChildren}
      e2eDataAttr={e2eTestElements.common.selects.auditObjectsAndGroups}
    />
  );
};

export default AuditObjectOrAuditObjectGroupSelect;
