import React from 'react';
import { useIntl } from 'react-intl';

import Form from '../../../shared/ant/Form';
import { Input } from '../../../shared/ant/Input';
import Button from '../../../shared/ant/Button';

interface IProps {
  sendPasswordEmail: (email: string) => Promise<void>;
  loading: boolean;
}

const ResetPasswordForm: React.FC<React.PropsWithChildren<IProps>> = ({
  loading,
  sendPasswordEmail,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form
      name="reset-password-form"
      layout="vertical"
      onFinish={({ email }: any) => sendPasswordEmail(email)}
    >
      <Form.Item
        name="email"
        label={formatMessage({ id: 'Email' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseEnterYourEmail' }),
          },
          {
            type: 'email',
            message: formatMessage({ id: 'PleaseEnterValidEmail' }),
          },
        ]}
      >
        <Input disabled={loading} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%', marginTop: '16px' }}
          loading={loading}
        >
          {formatMessage({ id: 'Reset' })}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ResetPasswordForm;
