import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  accountSelectors,
} from '@store';
import { e2eTestElements } from '@config';
import { useAppDispatch } from '@hooks';

import AddEditAuditObjectModalForm from './AddEditAuditObjectModalForm';
import SlideModal from '../../../shared/SideModal/SideModal';

const AddEditAuditObjectModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { show, data } = useSelector(
    auditObjectsSelectors.getAddEditAuditObjectModalState
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  const closeModal = useCallback(() => {
    dispatch(auditObjectsActions.objects.hideAddEditModal());
  }, [dispatch]);

  return (
    <SlideModal
      opened={show}
      onModalClose={() => {
        closeModal();
      }}
      title={
        !data
          ? formatMessage(
              { id: 'AddNewAuditObject' },
              { objectName: auditObjectName.single.toLowerCase() }
            )
          : formatMessage(
              { id: 'EditAuditObject' },
              { objectName: auditObjectName.single.toLowerCase() }
            )
      }
      closeBtnE2eDataAttr={e2eTestElements.users.addEditUserForm.closeModal}
    >
      <AddEditAuditObjectModalForm
        data={data}
        closeModal={closeModal}
        opened={show}
      />
    </SlideModal>
  );
};

export default AddEditAuditObjectModal;
