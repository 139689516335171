import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MapPin } from 'react-feather';
import { useIntl } from 'react-intl';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

import { Container, Input, InputWrapper } from './styled';
import { withMapsApiProvider } from '../withMapsApiProvider';
import { IGeoAddress } from '@repo/shared/types';

import LocationMap from '../LocationMap/LocationMap';

interface IProps {
  value?: IGeoAddress | null;
  onChange?: (value: IGeoAddress | null) => void;
  invalidLocation: boolean;
  setInvalidLocation: (invalidLocation: boolean) => void;
}

const LocationInput: React.FC<React.PropsWithChildren<IProps>> = ({
  value,
  onChange,
  invalidLocation,
  setInvalidLocation,
}) => {
  const { formatMessage } = useIntl();

  const [inputValue, setInputValue] = useState(value?.address || '');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const placesLib = useMapsLibrary('places');
  const autocomplete = useMemo(
    () =>
      placesLib &&
      inputRef.current &&
      new placesLib.Autocomplete(inputRef.current, {
        fields: ['geometry', 'name', 'formatted_address'],
      }),
    [placesLib, inputRef.current]
  );
  const geocodingLib = useMapsLibrary('geocoding');
  const geocoder = useMemo(
    () => geocodingLib && new geocodingLib.Geocoder(),
    [geocodingLib]
  );

  useEffect(() => {
    if (!autocomplete) {
      return;
    }

    autocomplete.addListener('place_changed', () => {
      const { geometry, formatted_address, name } = autocomplete.getPlace();

      if (!geometry?.location || !formatted_address || !name) {
        if (onChange) {
          onChange(null);
        }

        return;
      }

      setInputValue(formatted_address);

      if (onChange) {
        onChange({
          lat: geometry.location.lat().toString(),
          lng: geometry.location.lng().toString(),
          name,
          address: formatted_address,
        });
      }
    });
  }, [autocomplete, onChange, value]);

  const findLocationByAddress = useCallback(() => {
    if (!onChange || !geocoder) {
      return;
    }

    geocoder.geocode({ address: inputValue }, (result, status) => {
      if (status === 'OK' && result) {
        const {
          geometry: { location },
          formatted_address,
          address_components,
        } = result[0];

        onChange({
          lat: location.lat().toString(),
          lng: location.lng().toString(),
          name: address_components[0].long_name,
          address: formatted_address,
        });
      } else {
        onChange(null);
      }
    });
  }, [geocoder, inputValue, onChange, setInvalidLocation]);

  return (
    <Container>
      <InputWrapper>
        <Input
          ref={inputRef}
          value={inputValue}
          hasError={invalidLocation}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          placeholder={formatMessage({ id: 'EnterLocation' })}
          onBlur={() => {
            findLocationByAddress();
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              findLocationByAddress();
            }
          }}
        />
        <MapPin />
      </InputWrapper>
      {!!value && (
        <LocationMap
          location={value}
          height={'254px'}
          width={'100%'}
          zoom={16}
        />
      )}
    </Container>
  );
};

export default withMapsApiProvider(LocationInput);
