import { ItemType } from '@repo/shared/enums';
import { IAllowedRange, IScore } from '@repo/shared/types';
import { AuditTemplateDetails } from '@repo/shared/types';
import { IDragItem } from '@application/AuditTemplateBuilder/models/DragItem';

export enum AuditTemplateBuilderMode {
  Setup,
  Report,
  Sections,
  Preview,
  ActionTemplates,
  EditSection,
}

export enum SetupFormValidationStatus {
  Idle,
  Validate,
  Passed,
  Failed,
}

export interface ExpandedItems {
  [id: string]: true;
}

export interface ConfirmDeleteItem {
  entityId: string | null;
  itemType: ItemType | null;
}

export type EditIntervalsModalState = {
  itemId: string;
  intervalPrefix: string;
  allowedRange?: IAllowedRange;
} | null;

export interface SaveChangesModalState {
  show: boolean;
  closeBuilder?: true;
}

export interface AuditTemplateBuilderState {
  mode: AuditTemplateBuilderMode;
  template: AuditTemplateDetails | null;
  // scoreSystem: IScore | null;
  rootItemId: string | null;
  loading: boolean;
  error: string | null;
  validationStatus: SetupFormValidationStatus;
  dragItem: IDragItem | null;
  expanded: {
    sections: ExpandedItems;
    answers: ExpandedItems;
  };
  confirmDeleteItem: ConfirmDeleteItem;
  openedSectionId: string | null;
  sectionVisibilityModalId: string | null;
  saveChangesModal: SaveChangesModalState;
  showPublishChangesModal: boolean;
  showConfirmTemplateDeleteModal: boolean;
  showEditAsDraftModal: boolean;
  showCreateDraftCopyModal: boolean;
  isInformationPhotosUploading: boolean;
  answerValidation: {
    errors: Record<string, Record<string, string>>;
  };
  editIntervalsModal: EditIntervalsModalState;
  itemsByActionTemplateModalTemplateId: string | null;
  scrollItemId: string | null;
}
