import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background: ${({ defaultBg }: { defaultBg?: string }) => defaultBg};
`;

export const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface LabelProps {
  hoverText: string;
  defaultBg: string;
  disabled?: boolean;
}

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  color: ${colors.white};
  font-size: 24px;
  font-weight: 600;
  z-index: 1;

  &:before {
    display: ${({ disabled }: LabelProps) => (disabled ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    content: '${({ hoverText }: LabelProps) => hoverText}';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    font-weight: 300;
    background: rgba(0, 0, 0, 0.4);
    color: ${colors.white};
    font-size: 12px;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const RemoveBtn = styled.button`
  position: absolute;
  right: -1px;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 100%;
  background: ${colors.red};
  padding: 0;
  z-index: 2;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.white};
  }
`;

export const ImageHolder = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  z-index: 0;
  object-fit: cover;
`;
