import React from 'react';
import { useIntl } from 'react-intl';

import { e2eTestElements } from '@config';

import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import Form from '@components/shared/ant/Form';
import { Dayjs } from 'dayjs';
import { date } from '@utils';

interface IProps {
  disabled?: boolean;
}

const OneTime: React.FC<React.PropsWithChildren<IProps>> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Item
      name={['repeat', 'range']}
      rules={[
        {
          required: true,
          message: formatMessage({ id: 'PleaseSelectAuditDates' }),
        },
      ]}
    >
      <DatePicker
        picker="range"
        disabled={disabled}
        allowClear={false}
        disabledDate={(current: Dayjs) =>
          current.isBefore(date().startOf('day'))
        }
        data-e2e={e2eTestElements.schedules.addEditSchedule.repeatRange}
      />
    </Form.Item>
  );
};

export default OneTime;
