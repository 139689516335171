import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { issueTypesActions } from '@application/IssueTypes/store';

import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import IssueTypesHeader from '@src/presentation/IssueTypes/IssueTypesHeader';
import IssueTypesTable from '@src/presentation/IssueTypes/IssueTypesTable/IssueTypesTable';
import DeleteIssueTypesModal from '@src/presentation/IssueTypes/DeleteIssueTypesModal';
import IssueTypesActionsModal from '@src/presentation/IssueTypes/IssueTypesActionsModal';

interface IProps {}

const IssueTypes: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(issueTypesActions.getIssueTypes());
  }, []);

  return (
    <>
      <SampleDataAlert />
      <IssueTypesHeader />
      <IssueTypesTable />
      <DeleteIssueTypesModal />
      <IssueTypesActionsModal />
    </>
  );
};

export default IssueTypes;
