import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Locale = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${colors.white};

  & > * {
    &:first-child {
      margin-right: 5px;
    }
  }
`;

export const DropDownTrigger = styled(Locale)`
  color: ${colors.white};
`;

export const MenuItem = styled(Locale)`
  color: ${colors.gray10};
`;

export const LocaleIcon = styled.img`
  width: 14px;
  height: 14px;
`;
