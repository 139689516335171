import React from 'react';

import { Container, Score, Change } from './styled';
import { round } from '@utils';

interface IProps {
  score: number;
  change?: number | null;
  color?: string;
}

const ScoreChange: React.FC<React.PropsWithChildren<IProps>> = ({
  score,
  change,
  color,
}) => {
  return (
    <Container>
      <Score circleColor={color}>{round(score, 0)}%</Score>
      {!!change && (
        <Change isPositive={change > 0}>
          {change > 0 && '+'}
          {round(change, 0)}%
        </Change>
      )}
    </Container>
  );
};

export default ScoreChange;
