import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Info = styled.div`
  margin-bottom: 20px;
`;

export const Detail = styled.div`
  display: flex;
  margin-bottom: 15px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Icon = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 9px;
  position: relative;
  top: 0.05em;

  svg {
    width: 18px;
    height: 18px;
    color: ${colors.gray7};
  }
`;

export const Text = styled.div`
  word-break: break-word;
  line-height: 1.35;
  flex-grow: 1;
`;

export const Link = styled.button`
  word-break: break-word;
  line-height: 1.35;
  flex-grow: 1;
  color: ${colors.blue2};
  background: none;
  border: 0;
  text-align: left;
  padding: 0;

  &:disabled {
    color: #000;
  }
`;

export const DetailHeader = styled.div`
  font-size: 13px;
  line-height: 20px;
  color: ${colors.gray8};
  margin-bottom: 6px;
  margin-right: 10px;
`;

export const Signatures = styled.div`
  & > * {
    margin-bottom: 24px;
  }
`;

export const SubHeader = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
`;
