import React from 'react';

interface IProps {}

const AuditIcon: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_93_6295)">
        <path
          d="M8.75016 2.33333H9.80016C10.4536 2.33333 10.7803 2.33333 11.0298 2.46049C11.2493 2.57234 11.4278 2.75082 11.5397 2.97034C11.6668 3.2199 11.6668 3.5466 11.6668 4.2V10.9667C11.6668 11.6201 11.6668 11.9468 11.5397 12.1963C11.4278 12.4158 11.2493 12.5943 11.0298 12.7062C10.7803 12.8333 10.4536 12.8333 9.80016 12.8333H4.20016C3.54677 12.8333 3.22007 12.8333 2.97051 12.7062C2.75098 12.5943 2.57251 12.4158 2.46066 12.1963C2.3335 11.9468 2.3335 11.6201 2.3335 10.9667V4.2C2.3335 3.5466 2.3335 3.2199 2.46066 2.97034C2.57251 2.75082 2.75098 2.57234 2.97051 2.46049C3.22007 2.33333 3.54677 2.33333 4.20016 2.33333H5.25016M5.25016 7.58333L6.41683 8.75L8.75016 6.41666M6.1835 3.50001H7.81683C8.14353 3.50001 8.30687 3.50001 8.43166 3.43643C8.54142 3.3805 8.63066 3.29126 8.68658 3.1815C8.75016 3.05672 8.75016 2.89337 8.75016 2.56667V2.10001C8.75016 1.77331 8.75016 1.60996 8.68658 1.48518C8.63066 1.37542 8.54142 1.28618 8.43166 1.23025C8.30687 1.16667 8.14353 1.16667 7.81683 1.16667H6.1835C5.8568 1.16667 5.69345 1.16667 5.56867 1.23025C5.45891 1.28618 5.36967 1.37542 5.31374 1.48518C5.25016 1.60996 5.25016 1.77331 5.25016 2.10001V2.56667C5.25016 2.89337 5.25016 3.05672 5.31374 3.1815C5.36967 3.29126 5.45891 3.3805 5.56867 3.43643C5.69345 3.50001 5.8568 3.50001 6.1835 3.50001Z"
          stroke="#92929D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 7.58326L6.41667 8.74993L8.75 6.4166"
          stroke="#92929D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_6295">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AuditIcon;
