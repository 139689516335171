import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray5};
  border-radius: 5px;

  & > * {
    &:nth-child(2) {
      border-left: 1px solid ${colors.gray5};
    }
  }
`;
