import styled from 'styled-components';

import playIcon from './play.svg';
import syncIcon from './sync.svg';
import { colors } from '@repo/shared/config';
import { hexToRGB } from '@repo/shared/utils';

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  background: ${colors.gray3};
  border-radius: 5px;
`;

export const ImageHolder = styled.div`
  position: relative;

  ${({ isVideoPreview }: { isVideoPreview: boolean }) =>
    isVideoPreview &&
    `
    background: ${hexToRGB(colors.black, 0.3)};
    
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: block;
      content: '';
      background: url(${playIcon}) center center no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
    }
  `}
`;

export const Image = styled.img`
  object-fit: cover;
  cursor: pointer;
`;

export const Placeholder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const RemoveFile = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  background: ${hexToRGB(colors.gray10, 0.8)};
  line-height: 1;
  z-index: 20;
  border-top-right-radius: 5px;

  svg {
    width: 16px;
    height: 16px;
    color: ${colors.white};
  }
`;

export const SyncIcon = styled.div`
  width: 20px;
  height: 20px;
  background: url(${syncIcon}) center center no-repeat;
  background-size: contain;
`;
