import React from 'react';

import UsersTable from './UsersTable/UsersTable';
import UsersHeader from './UsersHeader/UsersHeader';
import AddEditUserModal from './AddEditUserModal/AddEditUserModal';
import DeleteUserModal from './DeleteUserModal';
import UserActionsModal from './UserActionsModal';
import DeleteUserGroupModal from '../groups/list/DeleteUserGroupModal';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import SetUserPasswordModal from './SetUserPasswordModal';
import UsersFiltersModal from '@components/users/list/UsersFiltersModal/UsersFiltersModal';

const Users: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SampleDataAlert />
      <UsersHeader />
      <UsersTable />
      <AddEditUserModal />
      <DeleteUserModal />
      <DeleteUserGroupModal />
      <UserActionsModal />
      <UsersFiltersModal />
      <SetUserPasswordModal />
    </>
  );
};

export default Users;
