import React from 'react';

import { Header, Label } from './styled';

interface IProps {
  label: string;
  children: React.ReactNode;
}

const LabeledFilter: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  children,
}) => {
  return (
    <>
      <Header>
        <Label ellipsis={{ tooltip: label }}>
          <>{label}</>
        </Label>
      </Header>
      {children}
    </>
  );
};

export default LabeledFilter;
