import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPagedResponse, ITableFilters } from '@repo/shared/types';
import { IRootState } from '@src/core/frameworks/redux';
import { actionTemplatesSelectors } from '@application/ActionTemplates/store/actionTemplatesSelectors';
import { getErrorMessage } from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import { ActionTemplate } from '@domain/ActionTemplates/models/ActionTemplate';
import ActionTemplatesApiClient from '@infrastructure/ActionTemplates/api/ActionTemplatesApiClient';
import { CreateActionTemplateDto } from '@infrastructure/ActionTemplates/models/CreateActionTemplateDto';
import { UpdateActionTemplateDto } from '@infrastructure/ActionTemplates/models/UpdateActionTemplateDto';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';

const apiClient = new ActionTemplatesApiClient();

const getActionTemplates = createAsyncThunk<
  IPagedResponse<ActionTemplate>,
  Partial<ITableFilters> | undefined | null,
  { state: IRootState; rejectValue: string }
>(
  'actionTemplates/getActionTemplates',
  async (_, { rejectWithValue, getState }) => {
    try {
      return await apiClient.getActionTemplates(
        actionTemplatesSelectors.getFilters(getState())
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getActionTemplate = createAsyncThunk<
  ActionTemplate,
  string,
  { state: IRootState; rejectValue: string }
>(
  'actionTemplates/getActionTemplate',
  async (id, { rejectWithValue }) => {
    try {
      return await apiClient.getActionTemplate(id);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const createActionTemplate = createAsyncThunk<
  void,
  CreateActionTemplateDto,
  { state: IRootState; rejectValue: string }
>('actionTemplates/createActionTemplate', async (dto, { rejectWithValue }) => {
  try {
    await apiClient.createActionTemplate(dto);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const updateActionTemplate = createAsyncThunk<
  void,
  UpdateActionTemplateDto,
  { state: IRootState; rejectValue: string }
>('actionTemplates/updateActionTemplate', async (dto, { rejectWithValue }) => {
  try {
    await apiClient.updateActionTemplate(dto);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const deleteActionTemplates = createAsyncThunk<
  void,
  string[],
  { state: IRootState; rejectValue: string }
>('actionTemplates/deleteActionTemplates', async (ids, { rejectWithValue }) => {
  try {
    await apiClient.deleteActionTemplates(ids);
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleAddEditModal = createAction<{
  show: boolean;
  actionTemplate?: ActionTemplate;
}>('actionTemplates/toggleAddEditModal');

const toggleDeleteModal = createAction<DeleteModalState<ActionTemplate>>(
  'actionTemplates/toggleDeleteModal'
);

export const actionTemplatesActions = {
  getActionTemplates,
  getActionTemplate,
  createActionTemplate,
  updateActionTemplate,
  deleteActionTemplates,
  toggleAddEditModal,
  toggleDeleteModal,
};
