import React from 'react';
import { useIntl } from 'react-intl';

import {
  IssueQuestion,
  IssueQuestionType,
} from '@domain/Issues/models/IssueQuestion';
import { FileAttachmentsFormItem, IssueQuestionAnswerFormItem } from './styled';
import { requireNotEmptyArray } from '@utils';

import IssueQuestionAnswer from './IssueQuestionAnswer';
import IssueQuestionAttachments from './IssueQuestionAttachments';
import { FileMetadata } from '@repo/shared/types';
import { e2eTestElements } from '@config';

interface IProps {
  index: number;
  question: IssueQuestion;
  onChange?: (question: IssueQuestion) => void;
  getFilePath?: (file: FileMetadata) => string;
  getFileUploadPath?: (fileId: string) => string;
  getFileMetadataPath?: (fileId: string) => string;
  disabled?: boolean;
}

const IssueQuestionFormItem: React.FC<React.PropsWithChildren<IProps>> = ({
  index,
  question,
  onChange,
  getFilePath,
  getFileUploadPath,
  getFileMetadataPath,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  const rules = [];

  if (question.required) {
    rules.push({
      required: true,
      message: formatMessage({ id: 'RequiredField' }),
    });
  }

  const maxLength = question.type === IssueQuestionType.TextArea ? 1600 : 300;

  rules.push({
    max: maxLength,
    message: formatMessage(
      {
        id: 'NameMustNotExceedXCharacters',
      },
      { count: maxLength }
    ),
  });

  return (
    <>
      <IssueQuestionAnswerFormItem
        name={[index, 'answer', 'text']}
        label={question.name}
        rules={rules}
        style={{ marginBottom: question.allowAttachments ? 0 : '20px' }}
      >
        <IssueQuestionAnswer
          type={question.type}
          e2eDataAttr={
            e2eTestElements.issues.issues.createModal.questionAnswer
          }
          disabled={disabled}
          onBlur={(value) => {
            if (!onChange) {
              return;
            }

            switch (question.type) {
              case IssueQuestionType.TextField:
              case IssueQuestionType.TextArea: {
                onChange({
                  ...question,
                  answer: {
                    text: value,
                  },
                });
                break;
              }
            }
          }}
        />
      </IssueQuestionAnswerFormItem>
      {question.allowAttachments && (
        <FileAttachmentsFormItem
          name={[index, 'files']}
          rules={
            question.requireAttachments ? [requireNotEmptyArray()] : undefined
          }
        >
          <IssueQuestionAttachments
            disabled={disabled}
            getFilePath={getFilePath}
            getFileUploadPath={getFileUploadPath}
            getFileMetadataPath={getFileMetadataPath}
            onChange={(files) => {
              if (onChange) {
                onChange({
                  ...question,
                  files: files.map(({ file, ...rest }) => rest),
                });
              }
            }}
          />
        </FileAttachmentsFormItem>
      )}
    </>
  );
};

export default IssueQuestionFormItem;
