import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { IRootState } from '../../../frameworks/redux';
import { generalSelectors, itemAnalysisReportActions } from '@store';
import {
  IScoreBreakdownChartItem,
  ISectionAnalysisDataItem,
} from '@repo/shared/types';
import {
  detailsModalAuditsAdapter,
  failedCriticalItemsAdapter,
  mostFailedItemsAdapter,
  templateSectionsAdapter,
} from '@store/entityAdapters';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { AuditStatusReports, DetailsModalType } from '@repo/shared/enums';
import { DetailsModalItem } from '@domain/common/DetailsModalItem';

const getFilters = createSelector(
  [
    generalSelectors.getSharedFilters,
    (state: IRootState) => state.reports.itemAnalysis.filters,
  ],
  (sharedFilters, filters) => ({
    ...sharedFilters,
    ...filters,
  })
);

export const itemAnalysisReportSelectors = {
  getFilters,
  getMostFailedItemsFilters: createSelector(
    [
      getFilters,
      (state: IRootState) => state.reports.itemAnalysis.mostFailedItems.filters,
    ],
    (filters, mostFailedItemsFilters) => ({
      ...filters,
      ...mostFailedItemsFilters,
    })
  ),
  getFailedCriticalItemsFilters: createSelector(
    [
      getFilters,
      (state: IRootState) =>
        state.reports.itemAnalysis.failedCriticalItems.filters,
    ],
    (filters, failedCriticalItemsFilters) => {
      return {
        ...filters,
        ...failedCriticalItemsFilters,
      };
    }
  ),
  getSectionAnalysis: createSelector(
    (state: IRootState) => state.reports.itemAnalysis.sectionAnalysis,
    ({ loading, data, error, page }) => {
      let legend: IScoreBreakdownChartItem[] = [];

      const chartData = (data || [])
        .slice(page * 3, page * 3 + 3)
        .map(
          (
            { name, scoreBreakdownItems, id }: ISectionAnalysisDataItem,
            i: number
          ) => {
            const scoreItems = scoreBreakdownItems.map(
              (item: IScoreBreakdownChartItem, j: number) => {
                if (legend?.[j]?.count < item.count) {
                  legend[j].count = item.count;
                }

                return {
                  ...item,
                  label: `${item.label} (${item.scoreMin}%-${item.scoreMax}%)`,
                };
              }
            );

            if (i === 0) {
              legend = cloneDeep(scoreItems);
            }

            return {
              name,
              scoreBreakdownItems: scoreBreakdownItems.map(
                (item: IScoreBreakdownChartItem, j: number) => {
                  if (legend?.[j]?.count < item.count) {
                    legend[j].count = item.count;
                  }

                  return {
                    ...item,
                    label: `${item.label} (${item.scoreMin}%-${item.scoreMax}%)`,
                    onClickAction: itemAnalysisReportActions.detailsModal.show({
                      title:
                        item.label || intl.formatMessage({ id: 'ScoreSystem' }),
                      count: item.count,
                      type: DetailsModalType.Audits,
                      filters: {
                        sectionId: id,
                        scoreElementId: item.id,
                        auditStatus: AuditStatusReports.Completed,
                      },
                    }),
                  };
                }
              ),
            };
          }
        );

      return {
        loading,
        page,
        totalCount: (data || []).length,
        data: chartData,
        error,
        legend,
      };
    }
  ),
  getItemStats: createSelector(
    (state: IRootState) => state.reports.itemAnalysis.itemStats,
    (itemStats) => itemStats
  ),
  getMostFailedItems: createSelector(
    [
      (state: IRootState) => state.reports.itemAnalysis.mostFailedItems.loading,
      (state: IRootState) => state.reports.itemAnalysis.mostFailedItems.meta,
      (state: IRootState) => state.reports.itemAnalysis.mostFailedItems.error,
      (state: IRootState) =>
        state.reports.itemAnalysis.mostFailedItems.entities,
    ],
    (loading, meta, error, entities) => ({
      loading,
      error,
      meta,
      items: mostFailedItemsAdapter.getSelectors().selectAll(entities),
    })
  ),
  getItemsFlagsStats: createSelector(
    (state: IRootState) => state.reports.itemAnalysis.auditItemsFlagsStats,
    (auditItemsStats) => auditItemsStats
  ),
  getFailedCriticalItems: createSelector(
    [
      (state: IRootState) =>
        state.reports.itemAnalysis.failedCriticalItems.loading,
      (state: IRootState) =>
        state.reports.itemAnalysis.failedCriticalItems.meta,
      (state: IRootState) =>
        state.reports.itemAnalysis.failedCriticalItems.error,
      (state: IRootState) =>
        state.reports.itemAnalysis.failedCriticalItems.entities,
    ],
    (loading, meta, error, entities) => ({
      loading,
      error,
      meta,
      items: failedCriticalItemsAdapter.getSelectors().selectAll(entities),
    })
  ),
  getTemplateSections: createSelector(
    (state: IRootState) => state.reports.itemAnalysis.templateSections,
    ({ loading, data }) => ({
      loading,
      sections: templateSectionsAdapter
        .getSelectors()
        .selectAll(data)
        .map(({ name, id, parentSectionName }) => ({
          label: `${parentSectionName ? `${parentSectionName}/` : ''}${name}`,
          value: id,
        })),
      sectionsMap: templateSectionsAdapter.getSelectors().selectEntities(data),
    })
  ),
  detailsModal: {
    getType: (state: IRootState): DetailsModalType | null =>
      state.reports.itemAnalysis.detailsModal.type,
    hasMoreData: createSelector(
      (state: IRootState) => state.reports.itemAnalysis.detailsModal.meta,
      (meta) =>
        meta !== null && meta.pageNumber * meta.pageSize < meta.totalCount
    ),
    isLoading: (state: IRootState) =>
      state.reports.itemAnalysis.detailsModal.loading,
    getError: (state: IRootState) =>
      state.reports.itemAnalysis.detailsModal.error,
    getMeta: (state: IRootState) =>
      state.reports.itemAnalysis.detailsModal.meta,
    getFilters: (state: IRootState) =>
      state.reports.itemAnalysis.detailsModal.filters,
    getAudits: createSelector(
      (state: IRootState) => state.reports.itemAnalysis.detailsModal.audits,
      (data) => detailsModalAuditsAdapter.getSelectors().selectAll(data)
    ),
    getItemsByAudit: (state: IRootState): Record<string, DetailsModalItem[]> =>
      state.reports.itemAnalysis.detailsModal.itemsByAudit,
    getHeader: createSelector(
      [
        (state: IRootState) => state.reports.itemAnalysis.detailsModal.title,
        (state: IRootState) => state.reports.itemAnalysis.detailsModal.count,
      ],
      (title, count) => ({
        title,
        count,
      })
    ),
    getEndpoint: (state: IRootState): string | null =>
      state.reports.itemAnalysis.detailsModal.endpoint,
  },
};
