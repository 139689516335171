import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';
import { ActionStatus } from '@repo/shared/enums';
import { IColorChartDataItem, IStatusItem } from '@repo/shared/types';
import {
  prepareColorLineChartData,
  prepareAuditorPerfChartItemNames,
  selectAuditorPerfReportAnalyticsChartsData,
} from '@utils';
import { generalSelectors } from '@store';
import { failedItemStatuses, actionStatuses, auditStatuses } from '@config';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { AuditStatus, FailedItemStatus } from '@repo/shared/enums';
import { openActionsAdapter } from '@store/entityAdapters';

const getFilters = createSelector(
  [
    generalSelectors.getSharedFilters,
    (state: IRootState) => state.reports.performance.filters,
  ],
  (
    {
      templateIds,
      auditObjectGroupIds,
      auditObjectIds,
      userIds,
      userGroupIds,
      dateRangeType,
      startDate,
      endDate,
      ...sharedFilters
    },
    filters
  ) => ({
    ...sharedFilters,
    ...filters,
  })
);
const getLinesColors = (state: IRootState) =>
  state.reports.performance.linesColors;

export const performanceReportSelectors = {
  getPage: (state: IRootState) => state.reports.performance.page,
  getType: (state: IRootState) => state.reports.performance.type,
  getFilters,
  getAuditsAnalytics: createSelector(
    (state: IRootState) => state.reports.performance.auditsAnalytics,
    ({ data, loading }) =>
      selectAuditorPerfReportAnalyticsChartsData<AuditStatus>({
        data,
        loading,
        statuses: auditStatuses,
      })
  ),
  getActionsAnalytics: createSelector(
    (state: IRootState) => state.reports.performance.actionsAnalytics,
    ({ data, loading }) =>
      selectAuditorPerfReportAnalyticsChartsData<ActionStatus>({
        data,
        loading,
        statuses: actionStatuses,
      })
  ),
  getActionsOverTime: createSelector(
    [
      (state: IRootState) => state.reports.performance.actionsOverTime,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getResolvedActionsOverTime: createSelector(
    [
      (state: IRootState) => state.reports.performance.resolvedActionsOverTime,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getFailedItemsOverTimePeriod: createSelector(
    [
      (state: IRootState) =>
        state.reports.performance.failedItemsOverTimePeriod,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getCompletedAuditsOverTime: createSelector(
    [
      (state: IRootState) => state.reports.performance.completedAuditsOverTime,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getAuditsBurndown: createSelector(
    [
      (state: IRootState) => state.reports.performance.auditsBurndown,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getActionsBurndown: createSelector(
    [
      (state: IRootState) => state.reports.performance.actionsBurndown,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getAuditParticipationRate: createSelector(
    [
      (state: IRootState) => state.reports.performance.auditParticipationRate,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getActionParticipationRate: createSelector(
    [
      (state: IRootState) => state.reports.performance.actionParticipationRate,
      getLinesColors,
    ],
    ({ loading, data }, linesColors) => ({
      loading,
      ...prepareColorLineChartData<IColorChartDataItem>(data, linesColors),
    })
  ),
  getOpenActionsFilters: createSelector(
    [
      getFilters,
      (state: IRootState) => state.reports.performance.openActions.filters,
    ],
    (sharedFilters, filters) => ({
      ...sharedFilters,
      ...filters,
    })
  ),
  getFailedItemsByTags: createSelector(
    (state: IRootState) => state.reports.performance.failedItemsByTags,
    ({ data, loading, page, statuses }) => {
      return {
        page,
        statuses: Object.values<IStatusItem<FailedItemStatus>>(statuses).map(
          (status) => ({
            ...status,
            label: intl?.formatMessage({
              id: failedItemStatuses[status.status],
            }),
          })
        ),
        items: prepareAuditorPerfChartItemNames<FailedItemStatus>(data, page),
        loading,
      };
    }
  ),
  getActionsByTags: createSelector(
    (state: IRootState) => state.reports.performance.actionsByTags,
    ({ data, loading, page, statuses }) => {
      return {
        page,
        statuses: Object.values<IStatusItem<ActionStatus>>(statuses).map(
          (status) => ({
            ...status,
            label: intl?.formatMessage({
              id: actionStatuses[status.status],
            }),
          })
        ),
        items: prepareAuditorPerfChartItemNames<ActionStatus>(data, page),
        loading,
      };
    }
  ),
  getOpenActions: createSelector(
    (state: IRootState) => state.reports.performance.openActions,
    ({ entities, ...data }) => ({
      ...data,
      actions: openActionsAdapter.getSelectors().selectAll(entities),
    })
  ),
};
