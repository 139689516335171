import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import { Navigate } from 'react-router-dom';

import { useAppDispatch } from '@hooks';
import { accountActions, accountSelectors } from '@store';
import { Container } from './styled';
import { SubscriptionType } from '@repo/shared/enums';

import PaymentFormContent from './PaymentFormContent';
import { routes } from '@src/config/routes';

const PaymentForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  const {
    loading: subscriptionLoading,
    data: subscription,
    error: subscriptionError,
  } = useSelector(accountSelectors.getSubscription);
  const {
    loading: pricesLoading,
    data: prices,
    error: pricesError,
  } = useSelector(accountSelectors.getPrices);

  useEffect(() => {
    if (prices === null) {
      dispatch(accountActions.getPrices());
    }

    if (subscription === null) {
      dispatch(accountActions.getSubscription());
    }
  }, [dispatch, prices, subscription]);

  const isEdit =
    !!subscription &&
    subscription.automatedSubscriptionData !== null &&
    subscription.subscriptionType !== SubscriptionType.Trial;

  if (
    !pricesLoading &&
    !subscriptionLoading &&
    (subscriptionError || pricesError)
  ) {
    return <Navigate to={routes.subscription} replace />;
  }

  return (
    <Container>
      {subscriptionLoading || pricesLoading ? (
        <Skeleton active style={{ width: '400px', marginTop: '40px' }} />
      ) : (
        <PaymentFormContent editExistingSubscription={isEdit} />
      )}
    </Container>
  );
};

export default PaymentForm;
