import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountSelectors } from '@store';
import { IAccount } from '@repo/shared/types';

import Table from '../../shared/ant/Table/Table';

const AccountsTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  const { loading, data, error } = useSelector(accountSelectors.getAccounts);

  return (
    <Table
      loading={loading}
      meta={null}
      error={error}
      canSelectRow={false}
      columns={[
        {
          title: formatMessage({ id: 'CompanyName' }),
          dataIndex: 'companyName',
          key: 'companyName',
          render(_, { company: { name } }: IAccount) {
            return name;
          },
        },
        {
          title: formatMessage({ id: 'Role' }),
          dataIndex: 'roleName',
          key: 'roleName',
          render(_, { role: { name } }: IAccount) {
            return name;
          },
        },
      ]}
      dataSource={
        data
          ? data.map((account: IAccount) => ({
              key: account.id,
              ...account,
            }))
          : []
      }
    />
  );
};

export default AccountsTable;
