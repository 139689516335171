import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Label = styled.div`
  color: ${colors.gray8};
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 3px;
`;
