import React from 'react';
import { useIntl } from 'react-intl';

import {
  Container,
  Header,
  Description,
  Date,
  Event,
  AvatarWrap,
  MQAAvatar,
} from './styled';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';

import Avatar from '@components/shared/ant/Avatar';
import ZonedDateTime from '@components/shared/ZonedDateTime';

interface IProps {
  audit: AuditDetails;
}

const Activity: React.FC<React.PropsWithChildren<IProps>> = ({ audit }) => {
  const { formatMessage } = useIntl();

  if (
    !audit ||
    (!audit.startedAtInformation &&
      !audit.completedAtInformation &&
      !audit.expiredAtInformation)
  ) {
    return null;
  }

  return (
    <Container>
      <Header>{formatMessage({ id: 'Activity' })}</Header>
      {!!audit.startedAtInformation && (
        <Event>
          <AvatarWrap>
            {audit.startedBy ? (
              <Avatar user={audit.startedBy} />
            ) : (
              <MQAAvatar />
            )}
          </AvatarWrap>
          <div>
            <Description>
              {audit.startedBy
                ? formatMessage(
                    { id: 'UserHasStartedAudit' },
                    { username: audit.startedBy.name }
                  )
                : formatMessage({ id: 'AuditHasBeenStarted' })}
            </Description>
            <Date>
              <ZonedDateTime dateTime={audit.startedAtInformation} />
            </Date>
          </div>
        </Event>
      )}
      {!!audit.completedAtInformation && !!audit.completedBy && (
        <Event>
          <AvatarWrap>
            <Avatar user={audit.completedBy} />
          </AvatarWrap>
          <div>
            <Description>
              {formatMessage(
                { id: 'UserHasCompletedAudit' },
                { username: audit.completedBy.name }
              )}
            </Description>
            <Date>
              <ZonedDateTime dateTime={audit.completedAtInformation} />
            </Date>
          </div>
        </Event>
      )}
      {!!audit.expiredAtInformation && (
        <Event>
          <AvatarWrap>
            {audit.expiredBy ? (
              <Avatar user={audit.expiredBy} />
            ) : (
              <MQAAvatar />
            )}
          </AvatarWrap>
          <div>
            <Description>
              {audit.expiredBy
                ? formatMessage(
                    { id: 'UserHasExpiredAudit' },
                    { username: audit.expiredBy.name }
                  )
                : formatMessage({ id: 'AutoExpired' })}
            </Description>
            <Date>
              <ZonedDateTime dateTime={audit.expiredAtInformation} />
            </Date>
          </div>
        </Event>
      )}
    </Container>
  );
};

export default Activity;
