import styled from 'styled-components';
import Space from 'antd/es/space';

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const Error = styled.div`
  line-height: 1.4;
`;
