import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

export const Badge = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 6px 16px;
  border-radius: 18px;
  border: 1px solid ${colors.blue2};
  color: ${colors.blue2};
  background: ${hexToRGB(colors.blue2, 0.08)};
`;

export const ContactInformation = styled.div`
  margin: 5px 0 15px;
`;
