import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Skeleton from 'antd/es/skeleton';
import { useIntl } from 'react-intl';
import { FormListFieldData } from 'antd/es/form/FormList';

import { IRole, IRoleDetails } from '@repo/shared/types';
import { Permission, RoleType, PermissionGroup } from '@repo/shared/enums';
import { Buttons, SubHeader, PermissionGroupHeader } from './styled';
import { useAppDispatch } from '@hooks';
import {
  accountActions,
  accountSelectors,
  generalActions,
  rolesActions,
  rolesSelectors,
} from '@store';
import { enumToSelectOptions, notification } from '@utils';
import { delay } from '@repo/shared/utils';
import { PERMISSION_TYPES } from '@config';

import Form from '../../shared/ant/Form';
import { Input } from '../../shared/ant/Input';
import Button from '../../shared/ant/Button';
import Checkbox from '../../shared/ant/Checkbox';
import Select from '../../shared/ant/Select/Select';
import CheckboxRow from '@components/shared/CheckboxRow/CheckboxRow';

const roleTypeOptions = enumToSelectOptions(RoleType);

interface FormListFieldDataLangId extends FormListFieldData {
  langId: string;
  tooltipLangId?: string;
}

type IGroupedPermissions = Record<PermissionGroup, FormListFieldDataLangId[]>;

interface IProps {
  data?: IRole;
  closeModal: () => void;
  opened: boolean;
}

const AddEditRoleModalForm: React.FC<React.PropsWithChildren<IProps>> = ({
  data: role,
  closeModal,
  opened,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const user = useSelector(accountSelectors.getCurrentUser);
  const { rolePermissionTypes } = useSelector(
    rolesSelectors.getAddEditRoleModalState
  );
  const { loading: rolesLoading } = useSelector(rolesSelectors.getRoles);
  const isEditMode = !!role;

  async function getAndSetRolePermissionsTypes(roleType: RoleType) {
    const resultAction = await dispatch(
      rolesActions.getRolePermissionTypes(roleType)
    );

    if (rolesActions.getRolePermissionTypes.fulfilled.match(resultAction)) {
      const types: Permission[] = unwrapResult(resultAction);

      form.setFieldsValue({
        permissions: types.map((type) => ({
          isEnabled: false,
          rolePermissionType: type,
        })),
      });
    }
  }

  async function fetchRoleDetails(role: IRole) {
    setLoading(true);

    const resultAction = await dispatch(rolesActions.getRoleDetails(role.id));

    if (rolesActions.getRoleDetails.fulfilled.match(resultAction)) {
      const roleDetails: IRoleDetails = unwrapResult(resultAction);

      form.setFieldsValue({
        name: role.name,
        description: role.description,
        roleType: roleDetails.roleType,
        permissions: roleDetails.permissions.map((permission) => ({
          isEnabled: permission.isEnabled,
          rolePermissionType: permission.rolePermissionType,
        })),
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!rolesLoading && opened) {
      if (role) {
        fetchRoleDetails(role);
      } else {
        getAndSetRolePermissionsTypes(RoleType.Observer);
      }
    }
  }, [opened, role, form, dispatch, rolesLoading]);

  return (
    <Form
      form={form}
      onFinish={async (values: any) => {
        setSaving(true);

        const resultAction = await dispatch(
          rolesActions.updateRole({
            id: role?.id,
            ...values,
          })
        );

        if (rolesActions.updateRole.fulfilled.match(resultAction)) {
          if (user && role?.id === user?.role.id) {
            dispatch(
              accountActions.updateCurrentUserLocally({
                role: {
                  ...user.role,
                  ...values,
                },
              })
            );
          }

          closeModal();
          dispatch(rolesActions.getRoles());
          dispatch(generalActions.getConciseRoles());

          notification.success({
            message: isEditMode
              ? 'You have successfully edited role'
              : 'You have successfully created role',
          });
        } else {
          notification.error({
            message: isEditMode
              ? 'Error while creating role'
              : 'Error while editing role',
            description: resultAction.payload as string,
          });
        }

        await delay(200);

        setSaving(false);
      }}
      name="role-form"
      layout="vertical"
      initialValues={{
        name: '',
        roleType: RoleType.Observer,
        description: '',
        permissions: [],
      }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Form.Item
            name="name"
            label={formatMessage({ id: 'RoleName' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'PleaseEnterRoleName' }),
              },
            ]}
          >
            <Input
              disabled={saving}
              placeholder={formatMessage({ id: 'EnterName' })}
            />
          </Form.Item>
          <Form.Item name="roleType" label={formatMessage({ id: 'Type' })}>
            <Select
              options={roleTypeOptions}
              onChange={(newRoleType) => {
                getAndSetRolePermissionsTypes(newRoleType);
              }}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={formatMessage({ id: 'DescriptionOptional' })}
          >
            <Input.TextArea disabled={saving} rows={3} />
          </Form.Item>
          <SubHeader>{formatMessage({ id: 'Permissions' })}</SubHeader>
          {rolePermissionTypes.loading ? (
            <Skeleton />
          ) : (
            <>
              {rolePermissionTypes.error ? (
                <>
                  {formatMessage({
                    id: 'ErrorOccurredWhileLoadingPermissions',
                  })}
                </>
              ) : (
                <Form.List name="permissions">
                  {(fields) => {
                    const groupedByPermissionGroup =
                      fields.reduce<IGroupedPermissions>((grouped, field) => {
                        const rolePermissionType = form.getFieldValue([
                          'permissions',
                          field.name,
                        ]).rolePermissionType as Permission;

                        if (
                          PERMISSION_TYPES[rolePermissionType] === undefined
                        ) {
                          return grouped;
                        }

                        const { group, langId, tooltipLangId } =
                          PERMISSION_TYPES[rolePermissionType];

                        if (!grouped[group]) {
                          grouped[group] = [];
                        }

                        grouped[group].push({
                          ...field,
                          langId,
                          tooltipLangId,
                        });

                        return grouped;
                      }, {} as IGroupedPermissions);

                    return (
                      <div>
                        {Object.entries(groupedByPermissionGroup).map(
                          ([group, fields]) => {
                            return (
                              <React.Fragment key={group}>
                                <PermissionGroupHeader>
                                  {formatMessage({ id: group })}
                                </PermissionGroupHeader>
                                {fields.map(
                                  ({ langId, tooltipLangId, ...field }) => (
                                    <CheckboxRow
                                      key={field.key}
                                      label={formatMessage({
                                        id: langId,
                                      })}
                                      tooltip={
                                        tooltipLangId
                                          ? formatMessage({ id: tooltipLangId })
                                          : undefined
                                      }
                                    >
                                      <Form.Item
                                        {...field}
                                        name={[field.name, 'isEnabled']}
                                        valuePropName="checked"
                                      >
                                        <Checkbox disabled={saving} />
                                      </Form.Item>
                                    </CheckboxRow>
                                  )
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    );
                  }}
                </Form.List>
              )}
            </>
          )}
          <Buttons>
            <Button type="primary" htmlType="submit" loading={saving}>
              {isEditMode
                ? formatMessage({ id: 'Save' })
                : formatMessage({ id: 'AddNewRole' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                form.resetFields();
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Buttons>
        </>
      )}
    </Form>
  );
};

export default AddEditRoleModalForm;
