import React from 'react';
import AntSelect from 'antd/es/select';
import { useIntl } from 'react-intl';

import { AuditorAssignmentType } from '@domain/Schedules/enums/AuditorAssignmentType';
import { enumToSelectOptions, useTranslatedOptions } from '@utils';
import { ScheduleFormValues } from '@application/Schedules/models/ScheduleFormValues';
import { e2eTestElements } from '@config';

import Select from '@components/shared/ant/Select/Select';
import Form from '@components/shared/ant/Form';
import UsersSelect from '@components/shared/EntitySelects/UsersSelect';
import UserGroupsSelect from '@components/shared/EntitySelects/UserGroupsSelect';

interface IProps {
  disabled: boolean;
}

const auditorAssignmentsTypesOptions = enumToSelectOptions(
  AuditorAssignmentType
);
const { Option } = AntSelect;

const AuditorAssignment: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
}) => {
  const { formatMessage } = useIntl();

  const auditorAssignmentsTypesOptionsTranslated = useTranslatedOptions(
    auditorAssignmentsTypesOptions
  );

  return (
    <>
      <Form.Item
        name="auditorAssignmentType"
        label={formatMessage({ id: 'Auditors' })}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          disabled={disabled}
          placeholder={formatMessage({ id: 'SelectTemplate' })}
        >
          {auditorAssignmentsTypesOptionsTranslated.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item<ScheduleFormValues>
        noStyle
        shouldUpdate={(prevValues, nextValues) =>
          prevValues.auditorAssignmentType !== nextValues.auditorAssignmentType
        }
      >
        {({ getFieldValue }) => {
          const auditorAssignmentType: AuditorAssignmentType = getFieldValue(
            'auditorAssignmentType'
          );

          switch (auditorAssignmentType) {
            case AuditorAssignmentType.RandomAssignedAuditorFromGroup:
            case AuditorAssignmentType.EvenlyDistributedAuditorFromGroup:
              return (
                <Form.Item
                  name="assignedUserGroupsIds"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'ChooseUserGroups' }),
                    },
                  ]}
                >
                  <UserGroupsSelect
                    disabled={disabled}
                    placeholder={formatMessage({ id: 'ChooseUserGroups' })}
                    mode="multiple"
                    e2eDataAttr={
                      e2eTestElements.schedules.addEditSchedule
                        .auditorAssignmentSelect
                    }
                  />
                </Form.Item>
              );
            case AuditorAssignmentType.AssignToSpecificAuditor:
              return (
                <Form.Item
                  name="assignedUsersIds"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'PleaseSelectAuditors',
                      }),
                    },
                  ]}
                >
                  <UsersSelect
                    mode="multiple"
                    width="100%"
                    disabled={disabled}
                    placeholder={formatMessage({ id: 'SelectAuditors' })}
                    filterOptions={(user) => user.canDoAudit}
                    e2eDataAttr={
                      e2eTestElements.schedules.addEditSchedule
                        .auditorAssignmentSelect
                    }
                  />
                </Form.Item>
              );
          }
        }}
      </Form.Item>
    </>
  );
};

export default AuditorAssignment;
