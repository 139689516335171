import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  generalActions,
  generalSelectors,
  usersActions,
  usersSelectors,
} from '@store';
import { getTablePageNumberAfterDeletion, notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import { colors } from '@repo/shared/config';

import Modal from '../../../shared/ant/Modal/Modal';

const DeleteUserGroupModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const groups = useSelector(usersSelectors.groups.getMap);
  const { meta } = useSelector(usersSelectors.groups.getData);
  const deleteCandidatesIds = useSelector(
    usersSelectors.groups.getDeleteCandidatesIds
  );
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const closeModal = useCallback(
    () => dispatch(usersActions.groups.toggleConfirmDeleteGroupsModal([])),
    []
  );

  return (
    <Modal
      title={formatMessage(
        { id: 'DeleteUsersGroups' },
        { itemCount: deleteCandidatesIds.length }
      )}
      open={deleteCandidatesIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          usersActions.groups.deleteUsersGroups(deleteCandidatesIds)
        );

        if (
          usersActions.groups.deleteUsersGroups.fulfilled.match(resultAction)
        ) {
          dispatch(
            usersActions.groups.toggleAddEditGroupModal({ visible: false })
          );

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          dispatch(
            usersActions.groups.getData({
              pageNumber: getTablePageNumberAfterDeletion(meta),
            })
          );
          dispatch(generalActions.getConciseUserGroups());

          closeModal();

          navigate(routes.users.groupsList);
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingUsersGroups' },
              { itemCount: deleteCandidatesIds.length }
            ),
            description: resultAction.payload,
          });
        }

        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => closeModal()}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveUsersGroupsFromSystem' },
        { itemCount: deleteCandidatesIds.length }
      ).replace(
        '%replaceValue%',
        deleteCandidatesIds.length === 1
          ? groups[deleteCandidatesIds[0]]?.name
          : deleteCandidatesIds.length
      )}
    </Modal>
  );
};

export default DeleteUserGroupModal;
