import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import xorWith from 'lodash/xorWith';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import {
  IAnyObject,
  IConcise,
  ILabelValue,
  IOrderByDirection,
  IPagingMeta,
  ITableFilters,
} from '@repo/shared/types';
import { FormInstance } from 'antd/es/form';
import { notification } from './notifications';
import { DayOfWeek } from '@repo/shared/enums';
import { enumToArray } from '@utils/enums';

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}${alpha !== undefined ? `, ${alpha}` : ''})`;
}

type AntTableOrderByDirection = 'descend' | 'ascend' | null | undefined;

export function normalizeOrderByDirection(
  antOrderByDirection: AntTableOrderByDirection
): IOrderByDirection {
  if (antOrderByDirection === 'descend') {
    return 'desc';
  }

  if (antOrderByDirection === 'ascend') {
    return 'asc';
  }

  return null;
}

export function getColumnSortProperties(
  orderByDirection: IOrderByDirection,
  shouldOrder: boolean
): {
  sorter: true;
  sortOrder: AntTableOrderByDirection;
  sortDirections: ['ascend', 'descend', 'ascend'];
} {
  let sortOrder = null;

  if (typeof orderByDirection === 'string') {
    sortOrder = shouldOrder && `${orderByDirection}end`;
  }

  return {
    sorter: true,
    sortOrder: sortOrder as AntTableOrderByDirection,
    sortDirections: ['ascend', 'descend', 'ascend'],
  };
}

export function getInitialFilters(): ITableFilters {
  return {
    search: '',
    pageNumber: 1,
    pageSize: 10,
    orderBy: 'name',
    orderByDirection: 'asc',
  };
}

export function filterOptionsByChildren(inputValue: string, option: any) {
  if (!option?.children) {
    return false;
  }

  return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
}

export function filterOptionsByLabel(inputValue: string, option: any) {
  if (!option?.label) {
    return false;
  }

  return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
}

/**
 * @param object
 * @param replaceWithEmptyString — https://stackoverflow.com/questions/63037205/modelstate-is-invalid-for-a-nullable-property/66712465#66712465
 */
export function removeUndefinedAndNullProps(
  object: IAnyObject,
  replaceWithEmptyString?: boolean
) {
  return Object.entries(object).reduce((a: IAnyObject, [k, v]) => {
    if (v === null || v === undefined) {
      return replaceWithEmptyString ? ((a[k] = ''), a) : a;
    }

    return (a[k] = v), a; // eslint-disable-line no-sequences
  }, {});
}

export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}

export function isObject(value: any): value is IAnyObject {
  return typeof value === 'object' && value !== null;
}

export const getDecimals = (value: number) => {
  const valueAsString = value.toString();
  return valueAsString.includes('.') ? valueAsString.split('.')[1].length : 0;
};

export const round = (value: number, decimals = 2) => {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
};

export function useTranslatedOptions<
  T extends string | number = string | number,
>(options: { label: string; value: T }[]) {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return options.map((opt) => ({
      ...opt,
      label: formatMessage({ id: opt.label }),
    }));
  }, [formatMessage]);
}

export const shadeColor = (col: string, amt: number) => {
  col = col.replace(/^#/, '');
  if (col.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  // @ts-ignore
  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};

export const concatNames = (entities: { name: string }[]) => {
  return entities.reduce(
    (acc: string, { name }: { name: string }, i: number) => {
      let a = acc;

      a += name;

      if (i !== entities.length - 1) {
        a += ', ';
      }

      return a;
    },
    ''
  );
};

export const isArrayEqual = (x: IAnyObject[], y: IAnyObject[]) =>
  isEmpty(xorWith(x, y, isEqual));

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function getFirstCharacter(name: string) {
  return name?.charAt(0).toUpperCase();
}

export function showFormErrorsNotifications(form: FormInstance) {
  const errors = uniq(
    form.getFieldsError().reduce((acc: string[], field) => {
      const a = acc;

      if (field.name.includes('data') && field.errors.length > 0) {
        for (let i = 0; i < field.errors.length; i++) {
          if (field.errors[i] && field.errors[i].trim()) {
            a.push(field.errors[i]);
          }
        }
      }

      return a;
    }, [])
  );

  if (errors.length > 0) {
    notification.error({
      message: intl?.formatMessage({
        id: 'PleaseFixTheNextErrorsToSaveTheAnswer',
      }),
      description: errors.join(', '),
    });
  }
}

export function sortConciseArrayByName<T extends IConcise>(array: T[]): T[] {
  return array.sort((a, b) => a.name.localeCompare(b.name));
}

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function formatBytes(x: number) {
  let l = 0,
    n = x || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

export function getTablePageNumberAfterDeletion(
  meta: IPagingMeta | null
): number {
  if (meta === null) {
    return 1;
  }

  return (meta.totalCount - 1) % meta.pageSize === 0 && meta.pageNumber - 1 > 0
    ? meta.pageNumber - 1
    : meta.pageNumber;
}

export function convertLabelValueToConcise({
  label,
  value,
}: ILabelValue): IConcise {
  return {
    id: value,
    name: label,
  };
}

export function convertConciseToLabelValue({
  name,
  id,
}: IConcise): ILabelValue {
  return {
    label: name,
    value: id,
  };
}

export function getWeekDaysArray(): number[] {
  return enumToArray<number>(DayOfWeek)
    .map(([_, day]) => day)
    .sort((a, b) => a - b);
}
