import AntAlert from 'antd/es/alert';
import styled from 'styled-components';

import { colors } from '@repo/shared/config';

const Alert = styled(AntAlert)`
  padding: 10px 11px;
  display: flex;
  align-items: center;

  .ant-alert-icon {
    margin-right: 11px;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .ant-alert-message,
  .ant-alert-description {
    font-size: 14px;
    line-height: 22px;
  }

  &.ant-alert-warning .ant-alert-description {
    color: ${colors.yellow};
  }
`;

export default Alert;
