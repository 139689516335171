import React from 'react';
import styled from 'styled-components';
import Spin, { SpinSize } from 'antd/es/spin';

interface IProps {
  size?: SpinSize;
  containerStyles?: React.CSSProperties;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

const Loader: React.FC<React.PropsWithChildren<IProps>> = ({
  size = 'large',
  containerStyles,
}) => {
  return (
    <Container style={containerStyles}>
      <Spin size={size} />
    </Container>
  );
};

export default Loader;
