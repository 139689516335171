import React from 'react';

import {
  FooterButton,
  FormContainer,
  AuthLogo,
  Tagline,
  Title,
  Container,
} from './styled';

import AppLogo from '@components/shared/AppLogo';

interface IProps {
  title?: string;
  titleStyle?: React.CSSProperties;
  containerMaxWidth?: number;
  tagline?: string;
  children: React.ReactNode;
  footerAction?: () => void;
  footerText?: string;
  e2eDataAttrFooterBtn?: string;
  logo?: React.ReactNode;
}

const OneColAuthWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  title,
  titleStyle,
  containerMaxWidth,
  tagline,
  footerAction,
  footerText,
  e2eDataAttrFooterBtn,
  logo,
}) => {
  return (
    <Container maxWidth={containerMaxWidth}>
      <div>
        <AuthLogo>
          {logo || <AppLogo width={200} height={51} align="center" />}
        </AuthLogo>
        <FormContainer hasFooter={footerAction !== undefined}>
          {title && (
            <Title style={titleStyle} level={4}>
              {title}
            </Title>
          )}
          {tagline && <Tagline>{tagline}</Tagline>}
          {children}
          {footerAction && (
            <FooterButton
              type="button"
              onClick={footerAction}
              data-e2e={e2eDataAttrFooterBtn}
            >
              {footerText}
            </FooterButton>
          )}
        </FormContainer>
      </div>
    </Container>
  );
};

export default OneColAuthWrapper;
