import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button, ButtonsRow } from './styled';
import { AssignmentType } from '@application/Audits/enums/AssignmentType';
import { accountSelectors } from '@store';

interface IProps {
  onChange?: (value: any) => void;
  value?: AssignmentType;
}

const AssignmentButtonGroup: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef(({ onChange, value }, _) => {
    const { formatMessage } = useIntl();
    const user = useSelector(accountSelectors.getCurrentUser);

    function triggerChange(change: AssignmentType) {
      if (onChange) {
        onChange(change);
      }
    }

    return (
      <ButtonsRow>
        <Button
          type="default"
          selected={value === AssignmentType.all}
          onClick={() => triggerChange(AssignmentType.all)}
        >
          {formatMessage({ id: 'All' })}
        </Button>
        <Button
          type="default"
          selected={value === AssignmentType.unassigned}
          onClick={() => triggerChange(AssignmentType.unassigned)}
        >
          {formatMessage({ id: 'Unassigned' })}
        </Button>
        {user && (
          <Button
            type="default"
            selected={value === AssignmentType.me}
            onClick={() => triggerChange(AssignmentType.me)}
          >
            {formatMessage({ id: 'AssignedToMe' })}
          </Button>
        )}
      </ButtonsRow>
    );
  });

export default AssignmentButtonGroup;
