import { createReducer, Draft, Reducer } from '@reduxjs/toolkit';

import { performanceReportActions as actions } from './performance-report.actions';
import { generalActions } from '../general/general.actions';
import { ActionStatus } from '@repo/shared/enums';
import {
  IColorChartData,
  IColorChartDataItem,
  IStatuses,
} from '@repo/shared/types';
import { IPerformanceReportState } from '@types';
import {
  ChartLineColorGenerator,
  date,
  prepareAuditorPerfBarChartData,
} from '@utils';
import {
  DateRangeType,
  FailedItemStatus,
  PerformanceReportPage,
} from '@repo/shared/enums';
import { config } from '@repo/shared/config';
import { openActionsAdapter } from '@store/entityAdapters';

const colorGenerator = ChartLineColorGenerator.use();

function setLinesColors(
  state: Draft<IPerformanceReportState>,
  payload: IColorChartData<IColorChartDataItem>[]
) {
  for (let i = 0; i < payload.length; i++) {
    const { id } = payload[i];

    if (state.linesColors[id] === undefined) {
      state.linesColors[id] = colorGenerator.getNextColor();
    }
  }
}

const initialState = {
  page: PerformanceReportPage.RealTime,
  type: null,
  filters: {
    templateIds: [],
    userIds: [],
    userGroupIds: [],
    auditObjectIds: [],
    auditObjectGroupIds: [],
    tagsIds: [],
    dateRangeType: DateRangeType.PriorMonth,
    startDate: date()
      .startOf('day')
      .subtract(1, 'month')
      .format(config.apiDateFormat),
    endDate: date().endOf('day').format(config.apiDateFormat),
  },
  auditsAnalytics: {
    loading: false,
    data: null,
    error: null,
  },
  actionsAnalytics: {
    data: null,
    error: null,
    loading: false,
  },
  actionsOverTime: {
    loading: false,
    error: null,
    data: [],
  },
  resolvedActionsOverTime: {
    loading: false,
    error: null,
    data: [],
  },
  failedItemsOverTimePeriod: {
    loading: false,
    error: null,
    data: [],
  },
  actionsByTags: {
    statuses: {} as IStatuses<ActionStatus>,
    loading: false,
    error: null,
    data: [],
    page: 0,
  },
  failedItemsByTags: {
    statuses: {} as IStatuses<FailedItemStatus>,
    loading: false,
    data: [],
    error: null,
    page: 0,
  },
  auditsBurndown: {
    loading: false,
    error: null,
    data: [],
  },
  actionsBurndown: {
    loading: false,
    error: null,
    data: [],
  },
  linesColors: {} as Record<string, string>,
  completedAuditsOverTime: {
    loading: false,
    error: null,
    data: [],
  },
  auditParticipationRate: {
    loading: false,
    error: null,
    data: [],
  },
  actionParticipationRate: {
    loading: false,
    error: null,
    data: [],
  },
  openActions: {
    loading: false,
    entities: openActionsAdapter.getInitialState(),
    filters: {
      pageSize: 15,
      pageNumber: 1,
      orderBy: 'completeDate',
      orderByDirection: 'desc',
    },
    meta: null,
    error: null,
  },
};

export const performanceReportReducer: Reducer<IPerformanceReportState> =
  createReducer<IPerformanceReportState>(initialState, (builder) =>
    builder
      .addCase(
        actions.getAuditorPerformanceReport.pending,
        (state, { meta: { arg } }) => {
          if (arg?.auditObjectIds) {
            state.filters.auditObjectIds = arg.auditObjectIds;
          }

          if (arg?.auditObjectGroupIds) {
            state.filters.auditObjectGroupIds = arg.auditObjectGroupIds;
          }

          if (arg?.userIds) {
            state.filters.userIds = arg.userIds;
          }

          if (arg?.userGroupIds) {
            state.filters.userGroupIds = arg.userGroupIds;
          }

          if (arg?.templateIds) {
            state.filters.templateIds = arg.templateIds;
          }

          if (arg?.tagsIds) {
            state.filters.tagsIds = arg.tagsIds;
          }

          if (arg?.dateRangeType !== undefined) {
            state.filters.dateRangeType = arg.dateRangeType;
          }

          if (arg?.startDate) {
            state.filters.startDate = arg.startDate;
          }

          if (arg?.endDate) {
            state.filters.endDate = arg.endDate;
          }
        }
      )
      .addCase(actions.getAuditsAnalytics.pending, (state) => {
        state.auditsAnalytics.error = null;
        state.auditsAnalytics.data = null;
        state.auditsAnalytics.loading = true;
      })
      .addCase(actions.getAuditsAnalytics.fulfilled, (state, { payload }) => {
        state.auditsAnalytics.data = payload;
        state.auditsAnalytics.loading = false;
      })
      .addCase(actions.getAuditsAnalytics.rejected, (state, { payload }) => {
        state.auditsAnalytics.loading = false;
        state.auditsAnalytics.error = payload || null;
      })
      .addCase(actions.getActionsAnalytics.pending, (state) => {
        state.actionsAnalytics.error = null;
        state.actionsAnalytics.data = null;
        state.actionsAnalytics.loading = true;
      })
      .addCase(actions.getActionsAnalytics.fulfilled, (state, { payload }) => {
        state.actionsAnalytics.data = payload;
        state.actionsAnalytics.loading = false;
      })
      .addCase(actions.getActionsAnalytics.rejected, (state, { payload }) => {
        state.actionsAnalytics.error = payload || null;
        state.actionsAnalytics.loading = false;
      })
      .addCase(actions.getActionsOverTime.pending, (state) => {
        state.actionsOverTime.error = null;
        state.actionsOverTime.data = [];
        state.actionsOverTime.loading = true;
      })
      .addCase(actions.getActionsOverTime.fulfilled, (state, { payload }) => {
        setLinesColors(state, payload);
        state.actionsOverTime.data = payload;
        state.actionsOverTime.loading = false;
      })
      .addCase(actions.getActionsOverTime.rejected, (state, { payload }) => {
        state.actionsOverTime.error = payload || null;
        state.actionsOverTime.loading = false;
      })
      .addCase(actions.getResolvedActionsOverTime.pending, (state) => {
        state.resolvedActionsOverTime.error = null;
        state.resolvedActionsOverTime.data = [];
        state.resolvedActionsOverTime.loading = true;
      })
      .addCase(
        actions.getResolvedActionsOverTime.fulfilled,
        (state, { payload }) => {
          setLinesColors(state, payload);
          state.resolvedActionsOverTime.data = payload;
          state.resolvedActionsOverTime.loading = false;
        }
      )
      .addCase(
        actions.getResolvedActionsOverTime.rejected,
        (state, { payload }) => {
          state.resolvedActionsOverTime.error = payload || null;
          state.resolvedActionsOverTime.loading = false;
        }
      )
      .addCase(actions.getFailedItemsOverTimePeriod.pending, (state) => {
        state.failedItemsOverTimePeriod.error = null;
        state.failedItemsOverTimePeriod.data = [];
        state.failedItemsOverTimePeriod.loading = true;
      })
      .addCase(
        actions.getFailedItemsOverTimePeriod.fulfilled,
        (state: Draft<IPerformanceReportState>, { payload }) => {
          setLinesColors(state, payload);
          state.failedItemsOverTimePeriod.data = payload;
          state.failedItemsOverTimePeriod.loading = false;
        }
      )
      .addCase(
        actions.getFailedItemsOverTimePeriod.rejected,
        (state, { payload }) => {
          state.failedItemsOverTimePeriod.error = payload || null;
          state.failedItemsOverTimePeriod.loading = false;
        }
      )
      .addCase(actions.getActionsByTags.pending, (state) => {
        state.actionsByTags.error = null;
        state.actionsByTags.data = [];
        state.actionsByTags.loading = true;
      })
      .addCase(actions.getActionsByTags.fulfilled, (state, { payload }) => {
        state.actionsByTags.loading = false;
        const { statuses, data } =
          prepareAuditorPerfBarChartData<ActionStatus>(payload);
        state.actionsByTags.data = data;
        state.actionsByTags.statuses = statuses;
      })
      .addCase(actions.getActionsByTags.rejected, (state, { payload }) => {
        state.actionsByTags.error = payload || null;
        state.actionsByTags.loading = false;
      })
      .addCase(actions.getFailedItemsByTags.pending, (state) => {
        state.failedItemsByTags.error = null;
        state.failedItemsByTags.data = [];
        state.failedItemsByTags.loading = true;
      })
      .addCase(actions.getFailedItemsByTags.fulfilled, (state, { payload }) => {
        state.failedItemsByTags.loading = false;
        const { statuses, data } =
          prepareAuditorPerfBarChartData<FailedItemStatus>(payload);
        state.failedItemsByTags.data = data;
        state.failedItemsByTags.statuses = statuses;
      })
      .addCase(actions.getFailedItemsByTags.rejected, (state, { payload }) => {
        state.failedItemsByTags.error = payload || null;
        state.failedItemsByTags.loading = false;
      })
      .addCase(actions.getCompletedAuditsOverTime.pending, (state) => {
        state.completedAuditsOverTime.error = null;
        state.completedAuditsOverTime.data = [];
        state.completedAuditsOverTime.loading = true;
      })
      .addCase(
        actions.getCompletedAuditsOverTime.fulfilled,
        (state, { payload }) => {
          setLinesColors(state, payload);
          state.completedAuditsOverTime.data = payload;
          state.completedAuditsOverTime.loading = false;
        }
      )
      .addCase(
        actions.getCompletedAuditsOverTime.rejected,
        (state, { payload }) => {
          state.completedAuditsOverTime.error = payload || null;
          state.completedAuditsOverTime.loading = false;
        }
      )
      .addCase(actions.getAuditsBurndown.pending, (state) => {
        state.auditsBurndown.error = null;
        state.auditsBurndown.data = [];
        state.auditsBurndown.loading = true;
      })
      .addCase(actions.getAuditsBurndown.fulfilled, (state, { payload }) => {
        setLinesColors(state, payload);
        state.auditsBurndown.data = payload;
        state.auditsBurndown.loading = false;
      })
      .addCase(actions.getAuditsBurndown.rejected, (state, { payload }) => {
        state.auditsBurndown.error = payload || null;
        state.auditsBurndown.loading = false;
      })
      .addCase(actions.getActionsBurndown.pending, (state) => {
        state.actionsBurndown.error = null;
        state.actionsBurndown.data = [];
        state.actionsBurndown.loading = true;
      })
      .addCase(actions.getActionsBurndown.fulfilled, (state, { payload }) => {
        setLinesColors(state, payload);
        state.actionsBurndown.data = payload;
        state.actionsBurndown.loading = false;
      })
      .addCase(actions.getActionsBurndown.rejected, (state, { payload }) => {
        state.actionsBurndown.error = payload || null;
        state.actionsBurndown.loading = false;
      })
      .addCase(actions.setReportLoadingStatus, (state, { payload }) => {
        state.auditsAnalytics.loading = payload;
        state.actionsOverTime.loading = payload;
        state.resolvedActionsOverTime.loading = payload;
        state.actionsAnalytics.loading = payload;
        state.failedItemsByTags.loading = payload;
        state.actionsByTags.loading = payload;
        state.failedItemsOverTimePeriod.loading = payload;
        state.auditsBurndown.loading = payload;
        state.actionsBurndown.loading = payload;
        state.openActions.loading = payload;
      })
      .addCase(actions.getAuditParticipationRate.pending, (state) => {
        state.auditParticipationRate.error = null;
        state.auditParticipationRate.data = [];
        state.auditParticipationRate.loading = true;
      })
      .addCase(
        actions.getAuditParticipationRate.fulfilled,
        (state, { payload }) => {
          setLinesColors(state, payload);
          state.auditParticipationRate.data = payload;
          state.auditParticipationRate.loading = false;
        }
      )
      .addCase(
        actions.getAuditParticipationRate.rejected,
        (state, { payload }) => {
          state.auditParticipationRate.error = payload || null;
          state.auditParticipationRate.loading = false;
        }
      )
      .addCase(actions.getActionParticipationRate.pending, (state) => {
        state.actionParticipationRate.error = null;
        state.actionParticipationRate.data = [];
        state.actionParticipationRate.loading = true;
      })
      .addCase(
        actions.getActionParticipationRate.fulfilled,
        (state, { payload }) => {
          setLinesColors(state, payload);
          state.actionParticipationRate.data = payload;
          state.actionParticipationRate.loading = false;
        }
      )
      .addCase(
        actions.getActionParticipationRate.rejected,
        (state, { payload }) => {
          state.actionParticipationRate.error = payload || null;
          state.actionParticipationRate.loading = false;
        }
      )
      .addCase(actions.getOpenActions.pending, (state, { meta: { arg } }) => {
        state.openActions.loading = true;
        state.actionParticipationRate.error = null;
        state.actionParticipationRate.data = [];
        state.openActions.filters = {
          ...state.openActions.filters,
          ...(arg || {}),
        };
      })
      .addCase(actions.getOpenActions.fulfilled, (state, { payload }) => {
        openActionsAdapter.setAll(
          state.openActions.entities,
          payload.data.map(({ dueDateInformation, ...action }) => ({
            dueDate: dueDateInformation.localTime,
            dueDateTimeZone: dueDateInformation.timeZoneAbbreviation,
            ...action,
          }))
        );
        state.openActions.meta = payload.meta;
        state.openActions.loading = false;
      })
      .addCase(actions.getOpenActions.rejected, (state) => {
        state.openActions.loading = false;
      })
      .addCase(actions.setup, (state, { payload: { page, type } }) => {
        state.page = page;
        state.type = type;
      })
      .addCase(actions.setFailedItemsByTagsPage, (state, { payload }) => {
        state.failedItemsByTags.page = payload;
      })
      .addCase(actions.setActionsByTagsPage, (state, { payload }) => {
        state.actionsByTags.page = payload;
      })
      .addCase(actions.updateFilters, (state, { payload }) => {
        state.filters = {
          ...state.filters,
          ...payload,
        };
      })
      .addCase(generalActions.resetAccountData, () => ({
        ...initialState,
      }))
  );
