import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 30px 0 16px;
  padding-top: 20px;
  border-top: 1px solid ${colors.gray4};
  text-align: center;
`;

export const ContributorGuidance = styled.div`
  margin: -8px 0 16px;
  font-size: 13px;
  text-align: center;
`;
