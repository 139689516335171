import { BaseApiClient } from '@repo/shared/api';
import { AuditTemplateDetails } from '@repo/shared/types';
import { prepareAuditTemplateDetailsItems } from '@infrastructure/AuditTemplateBuilder/transformers/prepareAuditTemplateDetailsItems';
import { transformTemplateToUpdateTemplateDto } from '@infrastructure/AuditTemplateBuilder/transformers/transformTemplateToUpdateTemplateDto';

class AuditTemplateBuilderApiClient extends BaseApiClient {
  public async getTemplateDetails(templateId: string, draft?: boolean) {
    const auditTemplateDetails =
      await this._apiService.get<AuditTemplateDetails>({
        url: draft
          ? `audit/templates/draft/${templateId}`
          : `audit/templates/${templateId}`,
      });

    return prepareAuditTemplateDetailsItems(auditTemplateDetails);
  }

  public async createDraft(template: AuditTemplateDetails): Promise<string> {
    const { id: newId } = await this._apiService.post<{ id: string }>({
      url: `audit/templates/draft`,
      body: transformTemplateToUpdateTemplateDto(template),
    });

    return newId;
  }

  public async saveAsDraft(publishedTemplateId: string): Promise<string> {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `audit/templates/${publishedTemplateId}/save-as-draft`,
    });

    return id;
  }

  public async saveTemplate(template: AuditTemplateDetails): Promise<void> {
    await this._apiService.put({
      url: template.isDraft
        ? `audit/templates/draft/${template.id}`
        : `audit/templates/${template.id}`,
      body: transformTemplateToUpdateTemplateDto(template),
    });
  }

  public async publishDraft(templateId: string) {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `audit/templates/draft/${templateId}/apply`,
    });

    return id;
  }
}

export default AuditTemplateBuilderApiClient;
