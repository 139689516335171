import styled from 'styled-components';
import Space from 'antd/es/space';

import { colors } from '@repo/shared/config';

export const FilterGroupHeader = styled.div`
  color: ${colors.gray8};
  margin: 25px 0 12px;
`;

export const Buttons = styled(Space)`
  margin-top: 35px;
`;
