import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { generalSelectors } from '@store';
import { ProfileSection } from '@components/account/EditProfile/styled';
import { useAppDispatch } from '@hooks';
import { usersActions } from '@store';

import UserGroupContentWrapper, {
  UserGroupPage,
} from '@components/users/groups/single/UserGroupContentWrapper/UserGroupContentWrapper';
import HeaderActionMenu from './HeaderActionMenu/HeaderActionMenu';
import AddEditUserGroupModal from '@components/users/groups/list/AddEditUserGroupModal/AddEditUserGroupModal';
import DeleteUserGroupModal from '@components/users/groups/list/DeleteUserGroupModal';

const UserGroupDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const userGroupsMap = useSelector(generalSelectors.getConciseUserGroupsMap);
  const { loading } = useSelector(generalSelectors.getConciseUserGroups);

  useEffect(() => {
    return () => {
      dispatch(usersActions.groups.toggleAddEditGroupModal({ visible: false }));
    };
  }, []);

  return (
    <UserGroupContentWrapper
      page={UserGroupPage.Details}
      extra={<HeaderActionMenu userGroupId={id} />}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {id && !!userGroupsMap[id] && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Name' })}</h3>
              <span>{userGroupsMap[id]?.name}</span>
            </ProfileSection>
          )}
        </>
      )}
      <AddEditUserGroupModal />
      <DeleteUserGroupModal />
    </UserGroupContentWrapper>
  );
};

export default UserGroupDetails;
