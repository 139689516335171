import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useIntl } from 'react-intl';

import { Buttons, LabelTagline, LabelTitle, LocationLabel } from './styled';
import { notification } from '@utils';
import { IAuditObject, IConcise } from '@repo/shared/types';
import { useAppDispatch } from '@hooks';
import {
  accountActions,
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
  generalActions,
} from '@store';
import { AnalyticsEvent, SelfGuidedTourStep } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import Form from '../../../shared/ant/Form';
import { Input } from '../../../shared/ant/Input';
import Button from '../../../shared/ant/Button';
import LocationInput from '@repo/shared/components/Location/LocationInput/LocationInput';
import Switch from '../../../shared/ant/Switch';
import AuditObjectsGroupsSelect from '../../../shared/EntitySelects/AuditObjectsGroupsSelect';
import TimeZonesSelect from '@components/shared/EntitySelects/TimeZonesSelect';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

interface IProps {
  data?: IAuditObject | null;
  closeModal: () => void;
  opened: boolean;
}

const AddEditAuditObjectModalForm: React.FC<
  React.PropsWithChildren<IProps>
> = ({ data: auditObject, closeModal, opened }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [invalidLocation, setInvalidLocation] = useState(false);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const { auditObjects } = useSelector(auditObjectsSelectors.getAuditObjects);
  const { data: auditObjectDetails } = useSelector(
    auditObjectsSelectors.getObjectDetails
  );

  const [form] = Form.useForm();
  const isEditMode = !!auditObject;

  useEffect(() => {
    if (auditObject && opened) {
      const fetchAuditObjectDetails = async () => {
        setLoading(true);

        const resultAction = await dispatch(
          auditObjectsActions.objects.getObjectDetails(auditObject.id)
        );

        if (
          auditObjectsActions.objects.getObjectDetails.fulfilled.match(
            resultAction
          )
        ) {
          const auditObjectWithDetails: IAuditObject =
            unwrapResult(resultAction);

          form.setFieldsValue({
            name: auditObjectWithDetails.name,
            auditObjectGroupIds: (
              auditObjectWithDetails.auditObjectGroups || []
            ).map(({ id }) => id),
            geoAddress:
              auditObjectWithDetails?.geoAddress?.lat &&
              auditObjectWithDetails?.geoAddress?.lng
                ? {
                    ...auditObjectWithDetails.geoAddress,
                    lat: auditObjectWithDetails.geoAddress.lat,
                    lng: auditObjectWithDetails.geoAddress.lng,
                  }
                : null,
            ianaTimeZone: auditObjectWithDetails?.ianaTimeZone || null,
            notes: auditObjectWithDetails?.notes || '',
          });

          if (
            auditObjectWithDetails.geoAddress?.lat &&
            auditObjectWithDetails.geoAddress?.lng
          ) {
            setShowMap(true);
          }
        }

        setLoading(false);
      };

      if (auditObjectDetails?.id !== auditObject.id) {
        fetchAuditObjectDetails();
      } else {
        form.setFieldsValue({
          name: auditObjectDetails.name,
          auditObjectGroupIds: (auditObjectDetails.auditObjectGroups || []).map(
            ({ id }: IConcise) => id
          ),
          geoAddress:
            auditObjectDetails?.geoAddress?.lat &&
            auditObjectDetails?.geoAddress?.lng
              ? {
                  ...auditObjectDetails.geoAddress,
                  lat: parseFloat(auditObjectDetails.geoAddress.lat),
                  lng: parseFloat(auditObjectDetails.geoAddress.lng),
                }
              : null,
          ianaTimeZone: auditObjectDetails?.ianaTimeZone || null,
          notes: auditObjectDetails?.notes || '',
        });

        if (
          auditObjectDetails.geoAddress?.lat &&
          auditObjectDetails.geoAddress?.lng
        ) {
          setShowMap(true);
        }
      }
    }
  }, [opened, auditObject, form, dispatch, auditObjectDetails]);

  const itemName = auditObjectName.single.toLowerCase();

  return (
    <Form
      form={form}
      onFinish={async ({ ...values }: any) => {
        setLoading(true);

        const update = {
          id: auditObject?.id,
          ...values,
          geoAddress: values.geoAddress
            ? {
                ...values.geoAddress,
                lat: values.geoAddress.lat.toString(),
                lng: values.geoAddress.lng.toString(),
              }
            : null,
        };

        const resultAction = await dispatch(
          auditObjectsActions.objects.updateObject(update)
        );

        if (
          auditObjectsActions.objects.updateObject.fulfilled.match(resultAction)
        ) {
          const hasObjectsCreatedByCurrentUser = auditObjects.some(
            ({ isSample }) => !isSample
          );

          if (!hasObjectsCreatedByCurrentUser) {
            dispatch(
              accountActions.sendAnalyticsEvent(AnalyticsEvent.AddAuditObject)
            );
          }

          dispatch(auditObjectsActions.objects.getData());
          dispatch(generalActions.getConciseAuditObjects());
          dispatch(generalActions.getConciseAccessibleAuditObjects());
          closeModal();

          if (
            !!auditObjectDetails &&
            auditObjectDetails.id === auditObject?.id
          ) {
            dispatch(auditObjectsActions.objects.updateObjectDetails(update));
          }

          notification.success({
            message: isEditMode
              ? formatMessage(
                  { id: 'YouHaveSuccessfullyEditedItem' },
                  { item: itemName }
                )
              : formatMessage(
                  { id: 'YouHaveSuccessfullyCreatedItem' },
                  { item: itemName }
                ),
          });
        } else {
          notification.error({
            message: isEditMode
              ? formatMessage(
                  { id: 'ErrorWhileCreatingItem' },
                  { item: itemName }
                )
              : formatMessage(
                  { id: 'ErrorWhileEditingItem' },
                  { item: itemName }
                ),
            description: resultAction.payload as string,
          });

          setLoading(false);
        }
      }}
      name="add-new-audit-object-form"
      layout="vertical"
      initialValues={{
        name: '',
        tagsIds: [],
        auditObjectGroupIds: [],
        geoAddress: null,
        ianaTimeZone: null,
        participantAndGroupUserIds: {
          userIds: [],
          userGroupIds: [],
        },
        notes: '',
      }}
    >
      <TourPopover
        step={SelfGuidedTourStep.AddObjectForm}
        overlayStyle={{
          height: 330,
          marginTop: -50,
        }}
        placement="left"
      >
        <Form.Item
          name="name"
          label={formatMessage({ id: 'Name' })}
          rules={[
            {
              required: true,
              message: formatMessage(
                { id: 'PleaseEnterAuditObjectName' },
                { objectName: itemName }
              ),
            },
            {
              max: 255,
              message: formatMessage(
                {
                  id: 'NameMustNotExceedXCharacters',
                },
                { count: 255 }
              ),
            },
          ]}
        >
          <Input
            disabled={loading}
            placeholder={formatMessage({ id: 'EnterName' })}
            data-e2e={e2eTestElements.objects.addEditObject.nameInput}
          />
        </Form.Item>
        {form.getFieldValue('auditObjectGroupIds')?.length > 0 && (
          <Form.Item
            name="auditObjectGroupIds"
            label={formatMessage(
              { id: 'AuditObjectGroups' },
              { objectName: auditObjectName.plural }
            )}
          >
            <AuditObjectsGroupsSelect mode="multiple" disabled />
          </Form.Item>
        )}
        <Form.Item
          name="ianaTimeZone"
          label={formatMessage({ id: 'Timezone' })}
        >
          <TimeZonesSelect
            disabled={loading}
            allowClear
            placeholder={formatMessage({ id: 'ChangeTimezone' })}
            e2eDataAttr={e2eTestElements.objects.addEditObject.timeZoneSelect}
          />
        </Form.Item>
        <Form.Item
          name="notes"
          label={
            <>
              <>
                {formatMessage({ id: 'Notes' })}
                <CircleTooltip contentMaxWidth={250}>
                  <p>
                    {formatMessage({
                      id: 'ProvideAnyAdditionalDetailsThatCouldAssistDuringTheAudit',
                    })}
                  </p>
                </CircleTooltip>
              </>
            </>
          }
          rules={[
            {
              max: 800,
              message: formatMessage(
                {
                  id: 'NameMustNotExceedXCharacters',
                },
                { count: 800 }
              ),
            },
          ]}
        >
          <Input.TextArea
            data-e2e={e2eTestElements.objects.addEditObject.notes}
            disabled={loading}
            placeholder={formatMessage({
              id: 'EnterAnyHelpfulHintsOrPointersHere',
            })}
            rows={3}
          />
        </Form.Item>
        <LocationLabel>
          <LabelTitle>
            {formatMessage({ id: 'Location' })}
            <LabelTagline>
              {formatMessage({ id: 'AddressOrCoordinates' })}
            </LabelTagline>
          </LabelTitle>
          <Switch
            onChange={() => setShowMap(!showMap)}
            defaultChecked={showMap}
            checked={showMap}
            data-e2e={e2eTestElements.objects.addEditObject.locationSwitch}
          />
        </LocationLabel>
        {showMap && (
          <Form.Item
            name="geoAddress"
            rules={[
              () => ({
                validator(_, value) {
                  if (value === null) {
                    return Promise.reject(
                      formatMessage({ id: 'LocationAddressIsNotValid' })
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <LocationInput
              setInvalidLocation={setInvalidLocation}
              invalidLocation={invalidLocation}
              data-e2e={e2eTestElements.objects.addEditObject.locationInput}
            />
          </Form.Item>
        )}
        <Buttons>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            data-e2e={e2eTestElements.objects.addEditObject.addEditButton}
          >
            {isEditMode
              ? formatMessage({ id: 'Save' })
              : formatMessage({ id: 'AddObject' }, { objectName: itemName })}
          </Button>
          <Button
            type="default"
            data-e2e={e2eTestElements.objects.addEditObject.cancelButton}
            onClick={() => {
              form.resetFields();
              setTimeout(() => {
                closeModal();
              }, 50);
            }}
          >
            {formatMessage({ id: 'Cancel' })}
          </Button>
        </Buttons>
      </TourPopover>
    </Form>
  );
};

export default AddEditAuditObjectModalForm;
