import React from 'react';
import { useIntl } from 'react-intl';

import { Container, Icon, Title } from './styled';

interface IProps {}

const ThankYou: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <div>
        <Icon />
        <Title>{formatMessage({ id: 'WeReallyAppreciateYourHelp' })}</Title>
      </div>
    </Container>
  );
};

export default ThankYou;
