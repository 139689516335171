import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
`;

export const CloseModalButton = styled.button`
  display: block;
  border: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: none;
  padding: 0;
`;
