import React, { useMemo } from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { TagColorCircle } from '../ant/Tag/styled';
import { ITagConcise } from '@repo/shared/types';
import { generalSelectors } from '@store';
import { TagType } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';

import Select from '../ant/Select/Select';
import Tag from '../ant/Tag/Tag';

const { Option } = AntSelect;

interface IProps extends Omit<SelectProps<any>, 'mode'> {
  width?: string;
  e2eDataAttr?: string;
  tagType?: TagType;
  mode?: 'multiple' | 'single';
}

const TagSelect: React.FC<React.PropsWithChildren<IProps>> = React.forwardRef<
  HTMLDivElement,
  IProps
>(({ disabled, placeholder, tagType, mode = 'multiple', ...props }, _) => {
  const { formatMessage } = useIntl();
  const { data, loading } = useSelector(generalSelectors.getConciseTags);
  const tagsMap = useSelector(generalSelectors.getConciseTagsMap);

  const tags = useMemo(
    () =>
      data.filter(
        (tag: ITagConcise) => tagType === undefined || tagType === tag.tagType
      ),
    [loading]
  );

  const multiple = mode === 'multiple';

  const value = useMemo(() => {
    let value = props.value;

    if (loading) {
      return multiple ? [] : null;
    }

    if (props.labelInValue) {
      value = value
        .filter(({ id }: ITagConcise) => tagsMap[id] !== undefined)
        .map(({ id, name, color }: ITagConcise) => ({
          value: id,
          label: name,
          color: color || colors.gray8,
        }));
    } else {
      value = value.filter((id: string) => tagsMap[id] !== undefined);
    }

    return multiple ? value : value?.[0] || null;
  }, [
    loading,
    JSON.stringify(props.value || []),
    props.labelInValue,
    multiple,
  ]);

  return (
    <Select
      {...props}
      mode={multiple ? 'multiple' : undefined}
      value={value}
      onChange={(newValue) => {
        if (props.onChange) {
          if (multiple) {
            props.onChange(
              props.labelInValue
                ? newValue.map(({ value, label, color }: any) => ({
                    id: value,
                    name: Array.isArray(label) ? label[0] : label,
                    color: color || tagsMap[value]?.color,
                  }))
                : newValue,
              tags
            );
          } else {
            props.onChange(
              props.labelInValue
                ? [
                    {
                      id: newValue.value,
                      name: Array.isArray(newValue.label)
                        ? newValue.label[0]
                        : newValue.label,
                      color: newValue.color || tagsMap[value]?.color,
                    },
                  ]
                : newValue !== undefined
                  ? [newValue]
                  : [],
              tags
            );
          }
        }
      }}
      placeholder={
        loading && placeholder
          ? `${placeholder} ${formatMessage({ id: 'loading' })}...`
          : placeholder
      }
      disabled={loading || disabled}
      filterOption={(inputValue, option) => {
        if (!option?.children?.[0]) {
          return false;
        }

        return (
          option.children[0].toLowerCase().indexOf(inputValue.toLowerCase()) !==
          -1
        );
      }}
      tagRender={({ value, closable, onClose }) => {
        const tag = tagsMap[value];

        if (!tag) {
          return <></>;
        }

        return (
          <Tag
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3, marginTop: 2 }}
            tagcolor={tag?.color}
          >
            {tag?.name}
          </Tag>
        );
      }}
    >
      {(tags || []).map((tag: ITagConcise) => (
        <Option key={tag.id} value={tag.id}>
          {tag.name}
          <TagColorCircle color={tag.color} size="sm" />
        </Option>
      ))}
    </Select>
  );
});

export default TagSelect;
