import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';

import { Buttons, CheckboxRowWrapper, FilterGroupHeader } from './styled';
import { accountSelectors } from '@store';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { getInitialAuditsFilters } from '@application/Audits/utils/getInitialAuditsFilters';
import { PendingAuditsStatusSelectOption, TagType } from '@repo/shared/enums';
import { AuditDueDateStatus } from '@application/Audits/enums/AuditDueDateStatus';
import { AuditStartDateStatus } from '@application/Audits/enums/AuditStartDateStatus';
import { date, enumToSelectOptions, useTranslatedOptions } from '@utils';
import { useAppDispatch } from '@hooks';
import { config } from '@repo/shared/config';
import {
  ClearBtn,
  FilterQty,
} from '@src/presentation/Actions/ActionsFiltersModal/styled';
import { AuditsFilters } from '@application/Audits/models/AuditsFilters';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { AuditsFiltersFormValues } from '@application/Audits/models/AuditsFiltersFormValues';
import { transformAuditsFiltersToFormValues } from '@application/Audits/utils/transformAuditsFiltersToFormValues';
import { transformFormValuesToAuditsFiltersRequestParams } from '@application/Audits/utils/transformFormValuesToAuditsFiltersRequestParams';

import SlideModal from '@components/shared/SideModal/SideModal';
import AuditTemplatesSelect from '@components/shared/EntitySelects/AuditTemplatesSelect';
import Form from '@components/shared/ant/Form';
import AssignmentButtonGroup from './AssignmentButtonGroup/AssignmentButtonGroup';
import Slider from '@components/shared/ant/Slider';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import Select from '@components/shared/ant/Select/Select';
import AuditObjectsAndAuditObjectGroupsSelect from '@components/shared/EntitySelects/AuditObjectsAndAuditObjectGroupsSelect';
import UserOrUserGroupSelect from '@components/shared/EntitySelects/UserOrUserGroupSelect';
import TagSelect from '@components/shared/EntitySelects/TagSelect';
import Checkbox from '@components/shared/ant/Checkbox';
import CheckboxRow from '@components/shared/CheckboxRow/CheckboxRow';
import SchedulesAndSchedulePlansSelect from '@components/shared/EntitySelects/SchedulesAndSchedulePlansSelect';

interface IProps {
  type: AuditsPage;
}

const dueDateStatusOptions = enumToSelectOptions(AuditDueDateStatus);
const pendingAuditsStatusSelectOptions = enumToSelectOptions(
  PendingAuditsStatusSelectOption
);

const AuditsFiltersModal: React.FC<React.PropsWithChildren<IProps>> = ({
  type,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<AuditsFiltersFormValues>();

  const opened = useSelector(auditsSelectors.isFiltersModalOpened);
  const filters = useSelector(auditsSelectors.getFilters);
  const selectedFiltersQty = useSelector(auditsSelectors.getSelectedFiltersQty);
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  const getAudits = useCallback(
    (update: Partial<AuditsFilters>) =>
      dispatch(auditsActions.getAudits(update)),
    [dispatch]
  );

  const closeModal = useCallback(
    () => dispatch(auditsActions.toggleFiltersModal(false)),
    [dispatch]
  );

  useEffect(() => {
    closeModal();
  }, [type]);

  useEffect(() => {
    if (opened) {
      form.resetFields();
    }
  }, [opened]);

  const translatedOptions = useTranslatedOptions(dueDateStatusOptions);
  const translatedDueDateStatusOptions = useMemo(
    () =>
      translatedOptions.filter(({ value }) => {
        if (
          value === AuditDueDateStatus.OnTime ||
          value === AuditDueDateStatus.Upcoming
        ) {
          return false;
        }

        return (
          type === AuditsPage.Pending || value !== AuditDueDateStatus.DueSoon
        );
      }),
    [type]
  );
  const translatedStatusSelectOptions = useTranslatedOptions([
    { label: formatMessage({ id: 'All' }), value: -1 },
    ...pendingAuditsStatusSelectOptions,
  ]);

  return (
    <SlideModal
      opened={opened}
      onModalClose={() => {
        closeModal();
      }}
    >
      <Form<AuditsFiltersFormValues>
        layout="vertical"
        form={form}
        name="audits-filters-form"
        onFinish={(values) => {
          getAudits(transformFormValuesToAuditsFiltersRequestParams(values));
          closeModal();
        }}
        initialValues={transformAuditsFiltersToFormValues(filters)}
      >
        <Form.Item name="assignment">
          <AssignmentButtonGroup
            onChange={(assignment) => {
              form.setFieldsValue({
                assignedTo: { userId: null, userGroupId: null },
              });

              getAudits({
                assignment,
                assignedTo: null,
                assignedToGroup: null,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'OrAssignedTo' })}
        </FilterGroupHeader>
        <Form.Item name="assignedTo">
          <UserOrUserGroupSelect
            allowClear
            onChange={({ userGroupId, userId }) => {
              form.setFieldsValue({ assignment: null });
              getAudits({
                assignment: null,
                assignedTo: userId,
                assignedToGroup: userGroupId,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        {type === AuditsPage.Completed && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'CompletedBy' })}
            </FilterGroupHeader>
            <Form.Item name="completedBy">
              <UserOrUserGroupSelect
                allowClear
                onChange={({ userGroupId, userId }) =>
                  getAudits({
                    completedBy: userId,
                    completedByGroup: userGroupId,
                    pageNumber: 1,
                  })
                }
              />
            </Form.Item>
            <FilterGroupHeader>
              {formatMessage({ id: 'Tags' })}
            </FilterGroupHeader>
            <Form.Item name="tagsIds">
              <TagSelect
                allowClear
                onChange={(tagsIds: string[]) =>
                  getAudits({
                    tagsIds,
                  })
                }
                placeholder={formatMessage({ id: 'SelectTags' })}
                tagType={TagType.Audit}
              />
            </Form.Item>
          </>
        )}
        <FilterGroupHeader>{`${auditObjectName.plural} ${formatMessage({
          id: 'or',
        })} ${formatMessage({ id: 'Groups' })}`}</FilterGroupHeader>
        <Form.Item name="auditObject">
          <AuditObjectsAndAuditObjectGroupsSelect
            allowClear
            onChange={({ auditObjectGroupIds, auditObjectIds }) =>
              getAudits({
                auditObjectGroupIds,
                auditObjectIds,
                pageNumber: 1,
              })
            }
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'AuditTemplate' })}
        </FilterGroupHeader>
        <Form.Item name="templateId">
          <AuditTemplatesSelect
            placeholder={formatMessage({ id: 'AuditTemplates' })}
            extraOptions={[
              {
                id: -1,
                name: formatMessage({ id: 'All' }),
              },
            ]}
            onChange={(templateId) => {
              getAudits({
                templateId: templateId === -1 ? null : templateId,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'DateRange' })}
        </FilterGroupHeader>
        <Form.Item name="range">
          <DatePicker
            picker="range"
            onChange={(range: [Dayjs, Dayjs]) => {
              getAudits({
                startDate: range?.[0]
                  ? date(range[0]).startOf('day').format(config.apiDateFormat)
                  : null,
                endDate: range?.[1]
                  ? date(range[1]).startOf('day').format(config.apiDateFormat)
                  : null,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'SchedulesOrAdvancedSchedules' })}
        </FilterGroupHeader>
        <Form.Item name="schedule">
          <SchedulesAndSchedulePlansSelect
            onChange={({ schedulesIds, schedulePlansIds }) => {
              getAudits({
                auditSchedulesIds: schedulesIds,
                schedulePlansIds,
              });
            }}
          />
        </Form.Item>
        {type === AuditsPage.Completed && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'Score' })}
            </FilterGroupHeader>
            <Form.Item name="score">
              <Slider
                range
                marks={{ 0: '0%', 100: '100%' }}
                onChange={(score: any) => {
                  getAudits({
                    scoreMin: score?.[0],
                    scoreMax: score?.[1],
                  });
                }}
              />
            </Form.Item>
          </>
        )}
        {type === AuditsPage.Pending && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'Status' })}
            </FilterGroupHeader>
            <Form.Item name="pendingAuditsStatus">
              <Select
                options={translatedStatusSelectOptions}
                onChange={(pendingAuditsStatus) => {
                  const update: Partial<AuditsFilters> = {
                    inProgress: null,
                    startDateStatus: AuditStartDateStatus.All,
                    pageNumber: 1,
                    pendingAuditsStatus,
                  };

                  if (
                    pendingAuditsStatus ===
                    PendingAuditsStatusSelectOption.InProgress
                  ) {
                    update.inProgress = true;
                  }

                  if (
                    pendingAuditsStatus ===
                    PendingAuditsStatusSelectOption.ReadyToStart
                  ) {
                    update.startDateStatus = AuditStartDateStatus.Ready;
                  }

                  if (
                    pendingAuditsStatus ===
                    PendingAuditsStatusSelectOption.Upcoming
                  ) {
                    update.startDateStatus = AuditStartDateStatus.Upcoming;
                  }

                  getAudits(update);
                }}
              />
            </Form.Item>
          </>
        )}
        <FilterGroupHeader>{formatMessage({ id: 'Late' })}</FilterGroupHeader>
        <Form.Item name="dueDateStatus">
          <Select
            options={translatedDueDateStatusOptions}
            onChange={(dueDateStatus) => {
              getAudits({
                dueDateStatus,
              });
            }}
          />
        </Form.Item>
        {type === AuditsPage.Expired && (
          <CheckboxRowWrapper>
            <CheckboxRow label={formatMessage({ id: 'AutoExpired' })}>
              <Form.Item name="isAutoExpired" valuePropName="checked">
                <Checkbox
                  onChange={(e) =>
                    getAudits({
                      isAutoExpired: e.target.checked,
                    })
                  }
                />
              </Form.Item>
            </CheckboxRow>
          </CheckboxRowWrapper>
        )}
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                const { search, ...initialModalFilters } =
                  getInitialAuditsFilters();
                getAudits(initialModalFilters);
                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default AuditsFiltersModal;
