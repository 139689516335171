import React from 'react';
import { useSelector } from 'react-redux';

import { Action } from '../../../../shared/ActionsModal/styled';
import { generalSelectors, usersActions } from '@store';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../../../../shared/ActionsModal/ActionsModal';

interface IProps {
  removeLabel: string;
}

const AuditObjectsActionsModal: React.FC<React.PropsWithChildren<IProps>> = ({
  removeLabel,
}) => {
  const dispatch = useAppDispatch();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  return (
    <ActionsModal>
      {() => (
        <Action
          onClick={() =>
            dispatch(
              usersActions.groupAssignments.toggleConfirmDeleteModal(
                selectedTableRowKeys
              )
            )
          }
        >
          {removeLabel}
          {selectedTableRowKeys.length > 1
            ? ` (${selectedTableRowKeys.length})`
            : ''}
        </Action>
      )}
    </ActionsModal>
  );
};

export default AuditObjectsActionsModal;
