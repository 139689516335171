import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.gray3};
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 8px 13px;
  font-size: 14px;
`;

export const Title = styled.div`
  color: ${colors.gray8};
  margin-right: 30px;
  white-space: nowrap;
`;

export const RoleNames = styled.div`
  margin-right: 12px;
`;

export const Numbers = styled.div`
  color: ${colors.gray8};
  margin-right: 24px;
`;
