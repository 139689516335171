import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AlertCircle } from 'react-feather';

import { Action } from '@components/shared/ActionsModal/styled';
import { generalSelectors } from '@store';
import { useAppDispatch, usePermission } from '@hooks';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { IConcise } from '@repo/shared/types';
import { colors } from '@repo/shared/config';
import { Permission } from '@repo/shared/enums';
import {
  AssignWrapper,
  TooltipWrapper,
} from '@src/presentation/Actions/ActionsSideModal/styled';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

interface IProps {
  page: IssuesPage;
}

const IssueTypesActionsModal: React.FC<IProps> = ({ page }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const issuesDictionary = useSelector(issuesSelectors.getIssuesDictionary);

  const [canEditIssues, canDeleteIssues, canChangeIssueStatus] = usePermission([
    Permission.CanEditIssues,
    Permission.CanDeleteIssues,
    Permission.CanChangeIssueStatus,
  ]);

  const selectedAuditObjectId = useMemo(() => {
    const objects = selectedTableRowKeys.reduce<Record<string, IConcise>>(
      (acc, issueId) => {
        const a = acc;
        const issue = issuesDictionary[issueId];

        if (issue?.auditObject?.id) {
          a[issue.auditObject.id] = issue.auditObject;
        }

        return a;
      },
      {}
    );

    const auditObjectsIds = Object.keys(objects);

    return auditObjectsIds.length === 1 ? auditObjectsIds[0] : null;
  }, [JSON.stringify(selectedTableRowKeys)]);

  let issue =
    selectedTableRowKeys.length === 1
      ? issuesDictionary[selectedTableRowKeys[0]]
      : null;

  return (
    <ActionsModal>
      {() => (
        <>
          {page === IssuesPage.Pending && canChangeIssueStatus && (
            <Action
              onClick={() => {
                dispatch(
                  issuesActions.toggleCloseIssuesModal(selectedTableRowKeys)
                );
              }}
            >
              {formatMessage({ id: 'Close' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
          {page === IssuesPage.Archive && canChangeIssueStatus && (
            <Action
              onClick={() => {
                dispatch(
                  issuesActions.toggleReopenIssuesModal(selectedTableRowKeys)
                );
              }}
            >
              {formatMessage({ id: 'Reopen' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
          {canEditIssues && (
            <AssignWrapper>
              <Action
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                  dispatch(
                    issuesActions.toggleAssignUsersToIssuesModal({
                      issuesIds: selectedTableRowKeys,
                      auditObjectId: selectedAuditObjectId!,
                    })
                  );
                }}
                disabled={!selectedAuditObjectId}
              >
                {formatMessage({ id: 'AssignUsers' })}
                {!!selectedAuditObjectId &&
                  selectedTableRowKeys.length > 1 &&
                  ` (${selectedTableRowKeys.length})`}
              </Action>
              {!selectedAuditObjectId && (
                <TooltipWrapper>
                  <CircleTooltip
                    circleBg={colors.red}
                    hoverCircleBg={colors.red}
                    icon={<AlertCircle />}
                    contentMaxWidth={270}
                  >
                    <p>
                      {formatMessage({
                        id: 'IssuesFromDifferentAuditObjectsHaveBeenSelected',
                      })}
                    </p>
                  </CircleTooltip>
                </TooltipWrapper>
              )}
            </AssignWrapper>
          )}
          {canDeleteIssues && (
            <Action
              onClick={() => {
                dispatch(
                  issuesActions.toggleConfirmDeleteIssuesModal(
                    selectedTableRowKeys.length > 1 || !issue
                      ? {
                          multiple: true,
                          data: selectedTableRowKeys,
                        }
                      : {
                          multiple: false,
                          data: issue,
                        }
                  )
                );
              }}
            >
              {formatMessage({ id: 'Delete' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
        </>
      )}
    </ActionsModal>
  );
};

export default IssueTypesActionsModal;
