import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  Info as Details,
  Detail,
  DetailHeader,
} from '../ViewAuditModal/Details/styled';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { useAppDispatch } from '@hooks';
import { Buttons, FormGroupRow, Dates, Alert } from './styled';
import { notification, date } from '@utils';
import { delay } from '@repo/shared/utils';
import { IConcise } from '@repo/shared/types';
import { config } from '@repo/shared/config';
import { AssigneesModalContext } from '@repo/shared/enums';

import SlideModal from '@components/shared/SideModal/SideModal';
import Button from '@components/shared/ant/Button';
import Form from '@components/shared/ant/Form';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import Assignees from '@components/shared/Assignees/Assignees';

const ReopenAuditModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const modalState = useSelector(auditsSelectors.getReopenModalState);
  const audits = useSelector(auditsSelectors.getAuditsDictionary);

  const audit = modalState.opened ? audits[modalState.auditId] : null;
  const auditAssignees = audit?.assignees || [];
  const closeModal = () => {
    dispatch(auditsActions.toggleReopenAuditModal({ opened: false }));

    setTimeout(() => {
      setError(null);
    }, 200);
  };

  const [assignees, setAssignees] = useState<IConcise[]>(
    audit?.assignees || []
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setAssignees(auditAssignees);
  }, [JSON.stringify(auditAssignees)]);

  useEffect(() => {
    if (modalState.opened) {
      form.resetFields();
    }
  }, [modalState.opened]);

  return (
    <SlideModal
      opened={modalState.opened}
      onModalClose={closeModal}
      title={formatMessage({ id: 'ReopenAudit' })}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          startDate: date(audit?.startDateInformation.localTime),
          endDate: date(audit?.endDateInformation.localTime),
        }}
        onFinish={async (values: any) => {
          setLoading(true);

          if (audit?.id === undefined) {
            notification.error({
              message: formatMessage({ id: 'ErrorWhileUpdatingAudit' }),
            });
            return;
          }

          const resultAction = await dispatch(
            auditsActions.reopenAudit({
              id: audit?.id,
              startDate: date(values.startDate)
                .startOf('day')
                .format(config.apiDateFormat),
              endDate: date(values.endDate)
                .startOf('day')
                .format(config.apiDateFormat),
              assignees: assignees.map((assignee) => assignee.id),
            })
          );

          if (
            auditsActions.reopenAudit.fulfilled.match(resultAction) &&
            audit?.id
          ) {
            closeModal();
            dispatch(auditsActions.getAudits());
          } else {
            setError(resultAction.payload as string);
          }

          await delay(200);

          setLoading(false);
        }}
      >
        <Dates>
          <FormGroupRow>
            <span>{formatMessage({ id: 'StartDate' })}</span>
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'RequiredField',
                  }),
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </FormGroupRow>
          <FormGroupRow>
            <span>{formatMessage({ id: 'EndDate' })}</span>
            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'RequiredField',
                  }),
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </FormGroupRow>
        </Dates>
        <Details>
          <Detail>
            <DetailHeader>{formatMessage({ id: 'AssignedTo' })}</DetailHeader>
            <Assignees
              onChange={async (assignees) => {
                setAssignees(assignees);
              }}
              auditObjectId={audit?.auditObject.id}
              users={assignees}
              context={AssigneesModalContext.Audit}
            />
          </Detail>
          <Detail />
        </Details>
        <Buttons>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={error !== null}
          >
            {formatMessage({ id: 'Reopen' })}
          </Button>
          <Button type="default" onClick={closeModal}>
            {formatMessage({ id: 'Cancel' })}
          </Button>
        </Buttons>
        {error && (
          <Alert
            message={formatMessage({ id: 'Warning' })}
            description={error}
            showIcon
            type="warning"
          />
        )}
      </Form>
    </SlideModal>
  );
};

export default ReopenAuditModal;
