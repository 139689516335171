import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  padding: 0;
  color: ${colors.blue2};
  margin-right: 25px;
  cursor: pointer;

  svg {
    color: ${colors.blue2};
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }
`;

export const PopoverContent = styled.div`
  width: 460px;
  padding: 5px;
`;

export const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.gray10};

  & > * {
    &:first-child {
      width: 26px;
      margin-right: 8px;
    }
  }
`;

export const UserName = styled.div`
  font-size: 14px;
  line-height: 1.4;
  width: 210px;
`;

export const Date = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: ${colors.gray8};
  margin-left: 20px;
  white-space: nowrap;
`;

export const Comment = styled.div`
  margin-top: 20px;
`;
