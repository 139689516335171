import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { notification } from '@utils';
import { accountActions } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import OneColAuthWrapper from '../OneColAuthWrapper';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';

export enum ResetPasswordSteps {
  send,
  success,
}

const ResetPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [step, setStep] = useState<ResetPasswordSteps>(ResetPasswordSteps.send);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  async function sendPasswordEmail(newEmail?: string) {
    setLoading(true);

    const resultAction = await dispatch(
      accountActions.sendPasswordResetEmail(newEmail || email)
    );

    if (accountActions.sendPasswordResetEmail.fulfilled.match(resultAction)) {
      if (newEmail) {
        setEmail(newEmail);
      }

      setStep(ResetPasswordSteps.success);
    } else {
      notification.error({
        message: formatMessage({ id: 'ErrorWhileRecoveringPassword' }),
        description: resultAction.payload,
      });
    }

    setLoading(false);
  }

  return (
    <>
      {step === ResetPasswordSteps.send && (
        <OneColAuthWrapper
          title={formatMessage({
            id: 'ResetYourPassword',
          })}
          tagline={formatMessage({
            id: 'EnterTheEmailAddressAssociatedWithYourAccount',
          })}
          footerAction={() => navigate(routes.signin)}
          footerText={formatMessage({
            id: 'GoBackToSignInPage',
          })}
        >
          <ResetPasswordForm
            loading={loading}
            sendPasswordEmail={sendPasswordEmail}
          />
        </OneColAuthWrapper>
      )}
      {step === ResetPasswordSteps.success && (
        <OneColAuthWrapper
          footerAction={() => navigate(routes.signin)}
          footerText={formatMessage({
            id: 'GoBackToSignInPage',
          })}
        >
          <ResetPasswordSuccess loading={loading} />
        </OneColAuthWrapper>
      )}
    </>
  );
};

export default ResetPassword;
