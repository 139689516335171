import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import PageHeader from '../../../shared/ant/PageHeader';
import SearchInput from '../../../shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';

const AttributesHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const filters = useSelector(auditObjectsSelectors.attributes.getFilters);

  return (
    <PageHeader
      title={`${auditObjectName.plural} ${formatMessage({ id: 'Attributes' })}`}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                auditObjectsActions.attributes.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isLocationsReadonly && canManageAuditObjects && (
            <Button
              type="primary"
              data-e2e={e2eTestElements.objects.attributes.openAddEditModal}
              onClick={() => {
                dispatch(
                  auditObjectsActions.attributes.toggleAddEditModal({
                    show: true,
                  })
                );
              }}
            >
              {formatMessage({
                id: 'NewAttribute',
              })}
            </Button>
          )}
        </>
      }
    />
  );
};

export default AttributesHeader;
