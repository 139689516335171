import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const ObjectsNamesContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const More = styled.div`
  position: relative;
  top: -1px;
  font-size: 13px;
  padding: 0 0 0 6px;
  color: ${colors.blue2};
  text-align: left;
`;

export const PopoverContent = styled.div`
  max-width: 300px;
  font-size: 12px;
`;
