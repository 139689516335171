import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '@repo/shared/config';

export const Text = styled.div`
  margin-bottom: 14px;
`;

const linkStyles = css`
  display: flex;
  align-items: flex-start;
  background: ${colors.gray4};
  border: 0;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 5px;
  width: 100%;
  text-align: left;
  color: ${colors.gray10};

  svg {
    position: relative;
    top: 1px;
    width: 17px;
    height: 17px;
    color: ${colors.gray8};
  }
`;

export const ExternalLink = styled(Link)`
  ${linkStyles}
`;

export const DisabledLink = styled.div`
  ${linkStyles}
`;

export const Icon = styled.div`
  width: 17px;
  margin-right: 10px;
  line-height: 1;
`;

export const Name = styled.div`
  flex-grow: 1;
`;
