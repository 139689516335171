import React from 'react';
import Modal from 'antd/es/modal';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  ButtonRow,
  CancelButton,
  Content,
  Icon,
  BlueButton,
  Text,
  Title,
} from './styled';
import { accountActions, accountSelectors } from '@store';
import { SelfGuidedTourStep } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { useSelfGuidedTourStepData } from '../useSelfGuidedTourData';
import { e2eTestElements } from '@config';

const StartTourModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { step: tourStep } = useSelector(accountSelectors.getSelfGuidedTour);
  const stepData = useSelfGuidedTourStepData(tourStep);

  return (
    <Modal
      open={tourStep === SelfGuidedTourStep.StartTour}
      width={400}
      closable={false}
      onCancel={() => {
        dispatch(accountActions.selfGuidedTour.stop());
      }}
      footer={null}
      zIndex={2100}
      styles={{
        body: {
          marginBottom: 0,
        },
      }}
      mask={false}
    >
      <Content>
        {stepData && (
          <>
            <Icon src={stepData.icon} />
            <Title>{stepData.title}</Title>
            <Text>{stepData.text}</Text>
          </>
        )}
      </Content>
      <ButtonRow>
        <CancelButton
          type="default"
          data-e2e={e2eTestElements.account.selfGuidedTour.cancelTourBtn}
          onClick={() => {
            dispatch(accountActions.selfGuidedTour.stop());
          }}
        >
          {formatMessage({ id: 'Cancel' })}
        </CancelButton>
        <BlueButton
          type="primary"
          data-e2e={e2eTestElements.account.selfGuidedTour.beginTourBtn}
          onClick={() => {
            dispatch(accountActions.selfGuidedTour.nextStep());
          }}
        >
          {formatMessage({ id: 'BeginTour' })}
        </BlueButton>
      </ButtonRow>
    </Modal>
  );
};

export default StartTourModal;
