import styled from 'styled-components';
import Space from 'antd/es/space';

import { colors } from '@repo/shared/config';

import StyledButton from '@components/shared/ant/Button';

export const ButtonsRow = styled.div`
  display: flex;
  margin: 0 -10px;

  & > * {
    margin: 0 10px;
  }
`;

interface ButtonProps {
  selected: boolean;
}

export const Button = styled(StyledButton)`
  &.ant-btn-default {
    color: ${({ selected }: ButtonProps) =>
      selected ? colors.white : colors.gray8};
    background: ${({ selected }: ButtonProps) =>
      selected ? colors.blue2 : colors.gray3};
  }
`;

export const Buttons = styled(Space)`
  margin-top: 35px;
`;
