import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useSearchParams, useParams } from 'react-router-dom';

import { validations, notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { accountActions } from '@store';
import { routes } from '@config';

import Form from '../../../shared/ant/Form';
import { InputPassword } from '../../../shared/ant/Input';
import Button from '../../../shared/ant/Button';

interface IProps {
  onSuccess: () => void;
}

const SetNewPasswordForm: React.FC<React.PropsWithChildren<IProps>> = ({
  onSuccess,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { userAuthId } = useParams();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  if (!searchParams.get('token') || !userAuthId) {
    return <Navigate to={routes.signin} replace />;
  }

  return (
    <Form
      name="set-new-password-form"
      layout="vertical"
      onFinish={async (values: any) => {
        setLoading(true);

        const resultAction = await dispatch(
          accountActions.setNewPassword({
            password: values.password,
            token: searchParams.get('token')!,
            userAuthId,
          })
        );

        if (accountActions.setNewPassword.fulfilled.match(resultAction)) {
          onSuccess();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileResettingPassword' }),
          });

          if (
            resultAction.payload === 'user/password-link-expired' ||
            resultAction.payload === 'user/token-not-valid'
          ) {
            notification.error({
              message: formatMessage({ id: 'TryResettingYourPasswordAgain' }),
              description: formatMessage({
                id: 'YourRequestToResetYourPasswordHasExpiredOrTheLinkHasAlreadyBeenUsed',
              }),
            });
          } else {
          }
        }

        setLoading(false);
      }}
      initialValues={{
        password: '',
        passwordConfirm: '',
      }}
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseEnterYourPassword' }),
          },
          {
            pattern: validations.password,
            message: formatMessage({
              id: 'PasswordShouldContainMinimumEightCharacters',
            }),
          },
        ]}
        label={formatMessage({ id: 'NewPassword' })}
      >
        <InputPassword disabled={loading} />
      </Form.Item>
      <Form.Item
        name="passwordConfirm"
        rules={[
          {
            required: true,
            message: formatMessage({
              id: 'PleaseEnterPasswordConfirmation',
            }),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                !getFieldValue('password') ||
                getFieldValue('password') === value
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                formatMessage({ id: 'PasswordsDontMatch' })
              );
            },
          }),
        ]}
        label={formatMessage({ id: 'ConfirmPassword' })}
      >
        <InputPassword disabled={loading} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%', marginTop: '16px' }}
          loading={loading}
        >
          {formatMessage({ id: 'Save' })}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SetNewPasswordForm;
