import React from 'react';

import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { useSetActionsPage } from '@application/Actions/hooks/useSetActionsPage';

import Actions from './Actions';

const PendingActions: React.FC<React.PropsWithChildren<unknown>> = () => {
  useSetActionsPage(ActionsPage.Completed);

  return (
    <Actions
      page={ActionsPage.Completed}
      initialFilters={{
        orderBy: 'completedAt',
        orderByDirection: 'desc',
      }}
    />
  );
};

export default PendingActions;
