import React from 'react';
import { PaginationProps } from 'antd/es/pagination';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { StyledPagination } from './styled';

const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = (
  props
) => {
  return (
    <StyledPagination
      {...props}
      itemRender={(_, type, originalElement) => {
        if (type === 'prev') {
          return (
            // eslint-disable-next-line
            <a className="ant-pagination-item-link">
              <ChevronLeft />
            </a>
          );
        }

        if (type === 'next') {
          return (
            // eslint-disable-next-line
            <a className="ant-pagination-item-link">
              <ChevronRight />
            </a>
          );
        }

        return originalElement;
      }}
    />
  );
};

export default Pagination;
