import React from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';

import {
  Container,
  Disclaimer,
  GroupName,
  Loader,
  EmptyContainer,
} from './styled';
import { ItemsGroup } from '@application/Audits/enums/ItemsGroup';
import { IPagingMeta } from '@repo/shared/types';

import Scrollbar from '@components/shared/Scrollbar/Scrollbar';

const groupNamesLangIds = {
  [ItemsGroup.Past]: 'Past',
  [ItemsGroup.Today]: 'Today',
  [ItemsGroup.Monday]: 'Monday',
  [ItemsGroup.Tuesday]: 'Tuesday',
  [ItemsGroup.Wednesday]: 'Wednesday',
  [ItemsGroup.Thursday]: 'Thursday',
  [ItemsGroup.Friday]: 'Friday',
  [ItemsGroup.Saturday]: 'Saturday',
  [ItemsGroup.Sunday]: 'Sunday',
  [ItemsGroup.Upcoming]: 'Upcoming',
};

interface IProps<T> {
  list: ItemsGroup[];
  dictionary: Record<ItemsGroup, T[]>;
  loading: boolean;
  meta: IPagingMeta | null;
  error: string | null;
  empty: React.ReactNode;
  disclaimer?: string;
  onScrollStop: (pageNumber: number) => void;
  Item: React.FC<{ item: T }>;
}

function GroupedList<T extends { id: string }>({
  list,
  dictionary,
  loading,
  meta,
  error,
  disclaimer,
  empty,
  onScrollStop,
  Item,
}: IProps<T>) {
  const { formatMessage } = useIntl();

  return (
    <Container>
      {!!disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
      {list.length === 0 && loading ? (
        <Skeleton active style={{ width: '400px', marginTop: '40px' }} />
      ) : (
        <>
          {list.length === 0 ? (
            <EmptyContainer>{empty}</EmptyContainer>
          ) : (
            <>
              {error ? (
                error
              ) : (
                <Scrollbar
                  onScrollStop={(event) => {
                    const { scrollTop, clientHeight, scrollHeight } = event;

                    if (
                      scrollTop + clientHeight >= scrollHeight &&
                      meta &&
                      meta.totalCount > meta.pageNumber * meta.pageSize &&
                      !loading
                    ) {
                      onScrollStop(meta.pageNumber + 1);
                    }
                  }}
                >
                  {list.map((group) => {
                    return (
                      <React.Fragment key={group}>
                        <GroupName>
                          {formatMessage({ id: groupNamesLangIds[group] })}
                        </GroupName>
                        {dictionary[group].map((item) => (
                          <Item key={item.id} item={item} />
                        ))}
                      </React.Fragment>
                    );
                  })}
                  {loading && (
                    <Loader>
                      <Spin />
                    </Loader>
                  )}
                </Scrollbar>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default GroupedList;
