import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import YouTube from 'react-youtube';

import { SelfGuidedTourStep } from '@repo/shared/enums';

import bulbIcon from './icons/bulb.png';
import gearIcon from './icons/gear.png';
import infoIcon from './icons/information.png';
import oneIcon from './icons/one.png';
import postboxIcon from './icons/postbox.png';
import twoIcon from './icons/two.png';
import zoomIcon from './icons/zoom.png';
import threeIcon from './icons/three.png';
import hammerIcon from './icons/hammer.png';
import eyesIcon from './icons/eyes.png';
import fourIcon from './icons/four.png';
import fileCabinetIcon from './icons/file-cabinet.png';
import fiveIcon from './icons/five.png';
import floppyDiskIcon from './icons/floppy-disk.png';
import sixIcon from './icons/six.png';
import checkIcon from './icons/check.png';
import barChartIcon from './icons/bar-chart.png';
import plusIcon from './icons/plus.png';
import flagIcon from './icons/flag.png';

export function useSelfGuidedTourStepData(step: SelfGuidedTourStep): {
  icon: string;
  title: string;
  text: React.ReactNode;
} {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    switch (step) {
      case SelfGuidedTourStep.StartTour:
        return {
          icon: bulbIcon,
          title: formatMessage({ id: 'SelfGuidedTour' }),
          text: (
            <>
              <p>{formatMessage({ id: 'LetsQuicklyWalkThroughMonitorQA' })}</p>
              <p>
                {formatMessage({
                  id: 'TheseSimpleStepsWillExplainHowToSetUpYourAccountSoYouCanStartUsingTheSystem',
                })}
              </p>
            </>
          ),
        };
      case SelfGuidedTourStep.SummaryReportWidgets:
        return {
          icon: infoIcon,
          title: formatMessage({ id: 'Widgets' }),
          text: (
            <p>
              {formatMessage({
                id: 'TheWidgetsOnTheExecutiveSummaryReportProvideInsightsIntoYourOperations',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.Settings:
        return {
          icon: gearIcon,
          title: formatMessage({ id: 'Settings' }),
          text: (
            <>
              <p>
                {formatMessage({
                  id: 'FirstLetsGetThingsSetUp',
                })}
              </p>
              <p>
                {formatMessage({
                  id: 'SetUpYourEntireSystemUsingTheMenuOnTheLeftColumn',
                })}
              </p>
              <p>
                {formatMessage({
                  id: 'WeWillStartInTheSettingsSection',
                })}
              </p>
            </>
          ),
        };
      case SelfGuidedTourStep.AddUser:
        return {
          icon: oneIcon,
          title: formatMessage({ id: 'AddUser' }),
          text: (
            <p>
              {formatMessage({
                id: 'TheFirstStepToSettingUpYourSystemIsToAddUser',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.AddUserFormNameEmail:
        return {
          icon: postboxIcon,
          title: formatMessage({ id: 'FillTheRequiredFields' }),
          text: (
            <p>
              {formatMessage({
                id: 'JustInputYourColleagueNameAndEmailAddress',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.AddUserFormRoles:
        return {
          icon: postboxIcon,
          title: formatMessage({ id: 'UserRoles' }),
          text: (
            <>
              <p>
                {formatMessage({
                  id: 'ThereAre3PrimaryRoles',
                })}
              </p>
              <p>
                <strong>
                  {formatMessage({
                    id: 'Administrator',
                  })}
                </strong>
                :{' '}
                {formatMessage({
                  id: 'CanConfigureAnyAspectOfTheSystem',
                })}
              </p>
              <p>
                <strong>
                  {formatMessage({
                    id: 'Auditor',
                  })}
                </strong>
                :{' '}
                {formatMessage({
                  id: 'CanPerformAuditsCreateAndApproveCorrectiveActions',
                })}
              </p>
              <p>
                <strong>
                  {formatMessage({
                    id: 'Auditee',
                  })}
                </strong>
                :{' '}
                {formatMessage({
                  id: 'ThePersonInChargeOfTheThingBeingAudited',
                })}
              </p>
            </>
          ),
        };
      case SelfGuidedTourStep.AddObject:
        return {
          icon: twoIcon,
          title: formatMessage({ id: 'AddAuditObjectLiterally' }),
          text: (
            <p>
              {formatMessage({
                id: 'TheAuditObjectIsTheThingThatIsBeingAudited',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.AddObjectForm:
        return {
          icon: zoomIcon,
          title: formatMessage({ id: 'SetUpYourAuditObject' }),
          text: (
            <ul>
              <li>
                {formatMessage({
                  id: 'NameYourAuditObject',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'AddUsersWhoAreConnectedToThatAuditObject',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'AuditObjectsCanBeGroupedForReportingPurposes',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'IfTheAuditObjectHasAnAddressInputItHere',
                })}
              </li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.CreateTemplate:
        return {
          icon: threeIcon,
          title: formatMessage({ id: 'CreateAuditTemplate' }),
          text: (
            <>
              <p>
                {formatMessage({
                  id: 'TheAuditTemplateIsYourChecklistOfInspectionItemsThatWillBeUsedInYourAuditOrInspection',
                })}
              </p>
              <p>
                {formatMessage({
                  id: 'WeHaveTwoTypesOfTemplates',
                })}
              </p>
              <ul>
                <li>
                  {formatMessage({
                    id: 'AuditPerformedByAnAuditorOnAnAuditObjectThatIsManagedByTheAuditee',
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: 'ChecklistSelfInspectionPerformedByAnAuditeeOnTheirOwnAuditObject',
                  })}
                </li>
              </ul>
            </>
          ),
        };
      case SelfGuidedTourStep.BuilderTemplateSetup:
        return {
          icon: hammerIcon,
          title: formatMessage({ id: 'TemplateBuilder' }),
          text: (
            <>
              <p>
                {formatMessage({ id: 'AuditAndInspectionFormsAreEasyToBuild' })}
              </p>
              <ul>
                <li>
                  {formatMessage({
                    id: 'NoITKnowledgeRequired',
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: 'CustomScoringSystemToMeetYourNeeds',
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: 'OptionToRequireSignaturesOfAuditorAndAuditee',
                  })}
                </li>
              </ul>
            </>
          ),
        };
      case SelfGuidedTourStep.BuilderTemplatePreview:
        return {
          icon: eyesIcon,
          title: formatMessage({ id: 'FormTemplatePreview' }),
          text: (
            <p>
              {formatMessage({
                id: 'PreviewYourInspectionFormBeforeDeployment',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.AddSchedule:
        return {
          icon: fourIcon,
          title: formatMessage({ id: 'ScheduleAudit' }),
          text: (
            <>
              <p>
                {formatMessage({
                  id: 'ScheduleAnAuditScheduleRecurringAuditAutomaticallyNotifyTheAuditorEveryTimeAnAuditIsDue',
                })}
              </p>
              <p>
                {formatMessage({
                  id: 'AdHocAuditsFromWithinTheMobileAppUserCanStartAnAuditAtAnyTime',
                })}
              </p>
              <p>
                <YouTube
                  videoId="rqix8VZ0h30"
                  opts={{ width: 242, height: 164 }}
                />
              </p>
              <p>
                {formatMessage({
                  id: 'NowItsTimeToPerformAnAuditOrInspectionUsingOurMobileApp',
                })}
              </p>
              <p>
                {formatMessage({
                  id: 'CheckOutThisQuickVideoDemoOfOurMobileApp',
                })}
              </p>
            </>
          ),
        };
      case SelfGuidedTourStep.AddScheduleForm:
        return {
          icon: fourIcon,
          title: formatMessage({ id: 'ScheduleAudit' }),
          text: (
            <ul>
              <li>{formatMessage({ id: 'SelectAuditFormTemplate' })}</li>
              <li>{formatMessage({ id: 'SelectAuditObjectLiterally' })}</li>
              <li>{formatMessage({ id: 'NameThisAudit(optional)' })}</li>
              <li>{formatMessage({ id: 'ScheduleFrequency' })}</li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.ViewAudits:
        return {
          icon: fileCabinetIcon,
          title: formatMessage({ id: 'ViewAudits' }),
          text: (
            <ul>
              <li>{formatMessage({ id: 'UpcomingAuditsPastAudits' })}</li>
              <li>{formatMessage({ id: 'OutstandingCorrectiveActions' })}</li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.ViewAuditsReports:
        return {
          icon: fiveIcon,
          title: formatMessage({ id: 'ViewAuditReports' }),
          text: (
            <>
              <p>
                {formatMessage({
                  id: 'ViewPastAuditResultsAtGlanceInMultipleFormats',
                })}
              </p>
              <ul>
                <li>{formatMessage({ id: 'QuickSummary' })}</li>
                <li>{formatMessage({ id: 'FullOnlineReport' })}</li>
                <li>{formatMessage({ id: 'ExportToPDForCSV' })}</li>
                <li>{formatMessage({ id: 'InsightfulDashboards' })}</li>
              </ul>
              <p>{formatMessage({ id: 'ClickAuditToViewSummaryReport' })}</p>
            </>
          ),
        };
      case SelfGuidedTourStep.AuditReport:
        return {
          icon: eyesIcon,
          title: formatMessage({ id: 'AuditReport' }),
          text: (
            <p>
              {formatMessage({
                id: 'ViewTheEntireReportOnline',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.CustomizeAuditReport:
        return {
          icon: floppyDiskIcon,
          title: formatMessage({ id: 'CustomizeAuditReport' }),
          text: (
            <ul>
              <li>
                {formatMessage({
                  id: 'CustomizeYourPDFReportToIncludePhotosCorrectiveActions',
                })}
              </li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.ExportAuditReportPDF:
        return {
          icon: floppyDiskIcon,
          title: formatMessage({ id: 'AuditReportExportToPDF' }),
          text: (
            <ul>
              <li>
                {formatMessage({
                  id: 'ExportFullReportInPDFFormatForSharing',
                })}
              </li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.SummaryDashboard:
        return {
          icon: eyesIcon,
          title: formatMessage({ id: 'SummaryDashboard' }),
          text: (
            <ul>
              <li>
                {formatMessage({
                  id: 'ViewAuditPerformance',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'ViewOutstandingCorrectiveActions',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'ViewAuditorPerformance',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'ComparePerformanceBetweenRegionsAuditorsLocations',
                })}
              </li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.TrackActionsWidget:
        return {
          icon: sixIcon,
          title: formatMessage({ id: 'TrackCorrectiveActions' }),
          text: (
            <>
              <p>{formatMessage({ id: 'ViewStatusOfCorrectiveActions' })}</p>
              <ul>
                <li>
                  {formatMessage({
                    id: 'Open',
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: 'PastDue',
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: 'Rejected',
                  })}
                </li>
                <li>
                  {formatMessage({
                    id: 'Approved',
                  })}
                </li>
              </ul>
            </>
          ),
        };
      case SelfGuidedTourStep.ActionsList:
        return {
          icon: eyesIcon,
          title: formatMessage({ id: 'CorrectiveActionList' }),
          text: <p>{formatMessage({ id: 'CorrectiveActionsAtGlance' })}</p>,
        };
      case SelfGuidedTourStep.ActionDetails:
        return {
          icon: checkIcon,
          title: formatMessage({ id: 'ResolveCorrectiveActionsAsTeam' }),
          text: (
            <ul>
              <li>
                {formatMessage({
                  id: 'ThreadedConversationsForEachCorrection',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'SubmitPhotosToImproveClarityOfCommunication',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'AuditeeSubmitCorrectionsForReviewFromTheirPhone',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'AuditorOrAdminApproveOrRejectCorrectionsFromYourComputerOrYourPhone',
                })}
              </li>
            </ul>
          ),
        };
      case SelfGuidedTourStep.AuditPerformanceWidget:
        return {
          icon: barChartIcon,
          title: formatMessage({ id: 'SummaryDashboard' }),
          text: (
            <>
              <p>{formatMessage({ id: 'ComparePerformanceBy' })}</p>
              <ul>
                <li>{formatMessage({ id: 'AuditObject' })}</li>
                <li>{formatMessage({ id: 'Auditor' })}</li>
                <li>{formatMessage({ id: 'Region' })}</li>
                <li>{formatMessage({ id: 'AnyGroupingYouWish' })}!</li>
              </ul>
            </>
          ),
        };
      case SelfGuidedTourStep.CustomReport:
        return {
          icon: plusIcon,
          title: formatMessage({ id: 'BuildCustomReports' }),
          text: (
            <p>
              {formatMessage({
                id: 'SliceAndDiceYourDataHoweverYouWishSelectParametersForYourReportAndWhichColumnsToDisplay',
              })}
            </p>
          ),
        };
      case SelfGuidedTourStep.EndTour:
        return {
          icon: flagIcon,
          title: formatMessage({ id: 'GetStartedToday' }),
          text: (
            <p>
              {formatMessage({
                id: 'SliceAndDiceYourDataHoweverYouWishSelectParametersForYourReportAndWhichColumnsToDisplay',
              })}
            </p>
          ),
        };
      default:
        return {
          icon: '',
          title: '',
          text: <></>,
        };
    }
  }, [formatMessage, step]);
}
