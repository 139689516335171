import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../../frameworks/redux';

import { rolesAdapter } from '@store/entityAdapters';

export const rolesSelectors = {
  getRoles: createSelector(
    [
      (state: IRootState) => state.roles.loading,
      (state: IRootState) => state.roles.meta,
      (state: IRootState) => state.roles.error,
      (state: IRootState) => state.roles.entities,
    ],
    (loading, meta, error, entities) => ({
      loading,
      error,
      meta,
      roles: rolesAdapter.getSelectors().selectAll(entities),
    })
  ),
  getRolesLookup: (state: IRootState) =>
    rolesAdapter.getSelectors().selectEntities(state.roles.entities),
  getFilters: (state: IRootState) => state.roles.filters,
  getAddEditRoleModalState: (state: IRootState) => state.roles.addEditRoleModal,
};
