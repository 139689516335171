import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch, usePermission } from '@hooks';
import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import AuditObjectGroupContentWrapper, {
  AuditObjectGroupPage,
} from '@components/auditObjects/groups/single/AuditObjectGroupContentWrapper/AuditObjectGroupContentWrapper';
import AddToGroupModal from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/AddToGroupModal/AddToGroupModal';
import ConfirmDeleteGroupAssignmentModal from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/ConfirmDeleteGroupAssignmentModal';
import GroupsAssignmentsActionsModal from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/GroupsAssignmentsActionsModal';
import GroupsAssignmentsTable from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/GroupsAssignmentsTable/GroupsAssignmentsTable';
import Button from '@components/shared/ant/Button';
import SearchInput from '@components/shared/SeachInput/SearchInput';

const AuditObjectGroupAssignments: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const filters = useSelector(
    auditObjectsSelectors.groupAssignments.getFilters
  );

  useEffect(() => {
    if (id) {
      dispatch(
        auditObjectsActions.groupAssignments.getData({
          auditObjectGroupIds: [id],
        })
      );
    }
  }, [id]);

  return (
    <AuditObjectGroupContentWrapper
      page={AuditObjectGroupPage.Assignments}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                auditObjectsActions.groupAssignments.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isLocationsReadonly && canManageAuditsObjects && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  auditObjectsActions.groupAssignments.toggleAddToGroupModal(
                    true
                  )
                );
              }}
              data-e2e={
                e2eTestElements.objects.groupsAssignments.openAddToGroupModal
              }
            >
              {formatMessage({
                id: 'AddToGroup',
              })}
            </Button>
          )}
        </>
      }
    >
      <GroupsAssignmentsTable auditObjectGroupId={id} />
      <AddToGroupModal auditObjectGroupId={id} />
      <ConfirmDeleteGroupAssignmentModal />
      <GroupsAssignmentsActionsModal />
    </AuditObjectGroupContentWrapper>
  );
};

export default AuditObjectGroupAssignments;
