import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';
interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Search = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M9.45187 13.794L2.25 10.9776L21.9008 3.75L15.7535 20.25L9.45187 13.794ZM9.45187 13.794L8.96314 18.7067L11.3423 15.736L9.45187 13.794ZM9.45187 13.794L21.9138 3.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default Search;
