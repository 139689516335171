import { IFile } from '@repo/shared/types';

export enum IssueQuestionType {
  TextField,
  TextArea,
}

interface IssueQuestionBase {
  id: string;
  name: string;
  required: boolean;
  allowAttachments: boolean;
  requireAttachments: boolean;
  issueTypeFieldId: string;
  files: IFile[];
}

interface IssueQuestionTextField extends IssueQuestionBase {
  type: IssueQuestionType.TextField;
  answer: {
    text: string;
  };
}

interface IssueQuestionTextArea extends IssueQuestionBase {
  type: IssueQuestionType.TextArea;
  answer: {
    text: string;
  };
}

export type IssueQuestion = IssueQuestionTextField | IssueQuestionTextArea;
