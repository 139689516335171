import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  generalActions,
  generalSelectors,
  tagsActions,
  tagsSelectors,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { getTablePageNumberAfterDeletion } from '@utils';

import ConfirmDeleteModal from '../../shared/ConfirmDeleteModal/ConfirmDeleteModal';

const DeleteTagModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const tags = useSelector(tagsSelectors.getTagsLookUp);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const { meta } = useSelector(tagsSelectors.getTags);
  const { formatMessage } = useIntl();

  return (
    <ConfirmDeleteModal
      text={{
        title: formatMessage(
          { id: 'DeleteTags' },
          { itemCount: selectedTableRowKeys.length }
        ),
        error: formatMessage(
          { id: 'ErrorWhileDeletingTags' },
          { itemCount: selectedTableRowKeys.length }
        ),
        desc: formatMessage(
          { id: 'YouAreAboutToRemoveTagsFromSystem' },
          { itemCount: selectedTableRowKeys.length }
        ),
      }}
      onSuccess={() => {
        dispatch(
          tagsActions.getTags({
            pageNumber: getTablePageNumberAfterDeletion(meta),
          })
        );
        dispatch(generalActions.getConciseTags());
      }}
      onSubmit={async (ids) => {
        const resultAction = await dispatch(tagsActions.deleteTags(ids));

        if (tagsActions.deleteTags.rejected.match(resultAction)) {
          notification.error({
            message: resultAction.payload,
          });
        }
      }}
      data={tags}
    />
  );
};

export default DeleteTagModal;
