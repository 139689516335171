import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import { useIntl } from 'react-intl';

import { generalSelectors } from '@store';
import { Container, Title, Numbers, RoleNames } from './styled';
import { useUserRolesLimitsInfo } from '@utils';

const UsersLimits: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const {
    loading,
    otherRoleNames,
    observerNames,
    otherRolesLimit,
    observersLimit,
    otherRolesCount,
    observersCount,
  } = useUserRolesLimitsInfo();

  const { observers, otherRoles } = useSelector(
    generalSelectors.getConciseRoles
  );

  return (
    <Container>
      <Title>{formatMessage({ id: 'UserLimits' })}</Title>
      {loading ? (
        <Skeleton
          paragraph={false}
          active
          title={{ width: 200, style: { margin: 0 } }}
        />
      ) : (
        <>
          {otherRoles.length > 0 && (
            <>
              <RoleNames>{otherRoleNames}</RoleNames>
              <Numbers>{`${otherRolesCount}/${
                otherRolesLimit || '-'
              }`}</Numbers>
            </>
          )}
          {observers.length > 0 && (
            <>
              <RoleNames>{observerNames}</RoleNames>
              <Numbers>{`${observersCount}/${observersLimit || '-'}`}</Numbers>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default UsersLimits;
