import React from 'react';
import { ChevronDown } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Container,
  Info,
  UserName,
  Company,
  DropDownMenuTrigger,
  AvatarContainer,
} from './styled';
import { accountActions, accountSelectors } from '@store';
import { routes, e2eTestElements } from '@config';
import { useAppDispatch } from '@hooks';

import DropDownMenu from '../../DropDownMenu/DropDownMenu';
import Avatar from '../../ant/Avatar';

const HeaderUser: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(accountSelectors.getCurrentUser);
  const company = useSelector(accountSelectors.getCompany);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  if (!user) {
    return null;
  }

  return (
    <Container>
      <AvatarContainer>
        <Avatar user={user} />
      </AvatarContainer>
      <DropDownMenu
        menuItems={[
          {
            name: formatMessage({ id: 'MyProfile' }),
            onClick: () => {
              navigate(routes.profile);
            },
          },
          {
            name: formatMessage({ id: 'LogOut' }),
            e2eDataAttr: e2eTestElements.common.header.signOutButton,
            onClick: async () => {
              await dispatch(accountActions.signOut());
              navigate(routes.signin);
            },
          },
        ]}
      >
        <DropDownMenuTrigger data-e2e={e2eTestElements.common.header.userMenu}>
          <Info>
            <UserName>{user.name}</UserName>
            <Company>{company.name}</Company>
          </Info>
          <ChevronDown />
        </DropDownMenuTrigger>
      </DropDownMenu>
    </Container>
  );
};

export default HeaderUser;
