import React from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';

import { filterOptionsByChildren } from '@utils';
import { IConcise } from '@repo/shared/types';

import Select from '../ant/Select/Select';

const { OptGroup, Option } = AntSelect;

interface IProps extends Omit<SelectProps<any>, 'value'> {
  entity: {
    options: IConcise[];
    label: string;
  };
  group: {
    options: IConcise[];
    label: string;
  };
  loading: boolean;
  value?: {
    entityIds: string[];
    groupIds: string[];
  };
  onChange?: (
    update: {
      entityIds: string[];
      groupIds: string[];
    },
    option: any
  ) => void;
  width?: string;
  e2eDataAttr?: string;
}

export const ENTITY_ID = 'entity';
export const GROUP_ID = 'group';

function EntityAndGroupSelectAsync({
  entity,
  group,
  onChange,
  value,
  loading,
  ...props
}: IProps) {
  const { formatMessage } = useIntl();

  const { disabled, placeholder } = props;

  let normalizedValue = loading
    ? []
    : [
        ...(value?.entityIds || []).map((id) => `${ENTITY_ID}-${id}`),
        ...(value?.groupIds || []).map((id) => `${GROUP_ID}-${id}`),
      ];

  return (
    <Select
      {...props}
      value={normalizedValue}
      onChange={(selected, option) => {
        if (onChange) {
          onChange(
            {
              entityIds: selected
                .filter((id: string) => id.includes(`${ENTITY_ID}-`))
                .map((id: string) => id.split(`${ENTITY_ID}-`)[1]),
              groupIds: selected
                .filter((id: string) => id.includes(`${GROUP_ID}-`))
                .map((id: string) => id.split(`${GROUP_ID}-`)[1]),
            },
            option
          );
        }
      }}
      disabled={loading || disabled}
      placeholder={loading ? formatMessage({ id: 'loading' }) : placeholder}
      loading={loading}
      mode="multiple"
      showSearch
      optionFilterProp="children"
      filterOption={filterOptionsByChildren}
    >
      <OptGroup label={entity.label}>
        {entity.options.length > 0 ? (
          entity.options.map((entityItem, i) => (
            <Option
              value={`${ENTITY_ID}-${entityItem.id}`}
              key={`${entityItem.id}-${i}`}
            >
              {entityItem.name}
            </Option>
          ))
        ) : (
          <Option value="no-items" key="no-items" disabled>
            {formatMessage({ id: 'NoItems' })}
          </Option>
        )}
      </OptGroup>
      <OptGroup label={group.label}>
        {group.options.length > 0 ? (
          group.options.map((groupItem, i) => (
            <Option
              value={`${GROUP_ID}-${groupItem.id}`}
              key={`${groupItem.id}-${i}`}
            >
              {groupItem.name}
            </Option>
          ))
        ) : (
          <Option value="no-group-items" key="no-group-items" disabled>
            {formatMessage({ id: 'NoItems' })}
          </Option>
        )}
      </OptGroup>
    </Select>
  );
}

export default React.memo(EntityAndGroupSelectAsync);
