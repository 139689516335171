import antNotification from 'antd/es/notification';
import {
  NotificationPlacement,
  ArgsProps,
} from 'antd/es/notification/interface';
import { X, XCircle, CheckCircle, AlertCircle } from 'react-feather';

const common = {
  placement: 'bottomRight' as NotificationPlacement,
  closeIcon: <X />,
};

antNotification.config({
  placement: 'bottomRight',
});

const notification = {
  error: (props: ArgsProps) =>
    antNotification.error({
      icon: <XCircle className="ant-notification-notice-icon-error" />,
      ...common,
      ...props,
    }),
  success: (props: ArgsProps) =>
    antNotification.success({
      icon: <CheckCircle className="ant-notification-notice-icon-success" />,
      ...common,
      ...props,
    }),
  info: (props: ArgsProps) =>
    antNotification.info({
      icon: <AlertCircle className="ant-notification-notice-icon-info" />,
      ...common,
      ...props,
    }),
  warning: (props: ArgsProps) =>
    antNotification.info({
      icon: <AlertCircle className="ant-notification-notice-icon-warning" />,
      ...common,
      ...props,
    }),
};

export { notification };
