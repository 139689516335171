import React from 'react';

import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { useSetIssuesPage } from '@application/Issues/hooks/useSetIssuesPage';

import Issues from '@src/presentation/Issues/Issues';

interface IProps {}

const PendingIssues: React.FC<React.PropsWithChildren<IProps>> = () => {
  useSetIssuesPage(IssuesPage.Pending);

  return <Issues page={IssuesPage.Pending} />;
};

export default PendingIssues;
