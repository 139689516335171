import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { generalActions, usersActions } from '@store';

import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import UserGroupsTable from '@components/users/groups/list/UserGroupsTable/UserGroupsTable';
import UserGroupsActionsModal from '@components/users/groups/list/UserGroupsActionsModal';
import AddEditUserGroupModal from '@components/users/groups/list/AddEditUserGroupModal/AddEditUserGroupModal';
import DeleteUserGroupModal from '@components/users/groups/list/DeleteUserGroupModal';
import UserGroupsHeader from '@components/users/groups/list/UserGroupsHeader';

interface IProps {}

const UserGroups: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(usersActions.groups.getData());
    dispatch(generalActions.getConciseUserGroups());
    dispatch(generalActions.getConciseUsers());

    return () => {
      dispatch(usersActions.groups.toggleAddEditGroupModal({ visible: false }));
    };
  }, []);

  return (
    <>
      <SampleDataAlert />
      <UserGroupsHeader />
      <UserGroupsTable />
      <UserGroupsActionsModal />
      <AddEditUserGroupModal />
      <DeleteUserGroupModal />
    </>
  );
};

export default UserGroups;
