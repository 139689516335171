import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  BillingPage,
  SubscriptionType,
  InvoiceStatus,
} from '@repo/shared/enums';
import { accountActions, accountSelectors } from '@store';
import { IInvoice } from '@repo/shared/types';
import { routes } from '@config';
import { config } from '@repo/shared/config';
import { date } from '@utils';
import { useAppDispatch } from '@hooks';

import BillingLayout from '../BillingLayout/BillingLayout';
import Table from '../../../shared/ant/Table/Table';
import EmptyTable from '../../../shared/ant/EmptyTable/EmptyTable';

const invoiceNamesLangIds = new Map();
invoiceNamesLangIds.set(InvoiceStatus.Draft, 'Draft');
invoiceNamesLangIds.set(InvoiceStatus.Pending, 'Pending');
invoiceNamesLangIds.set(InvoiceStatus.Open, 'Open');
invoiceNamesLangIds.set(InvoiceStatus.Paid, 'Paid');
invoiceNamesLangIds.set(InvoiceStatus.Voided, 'Voided');
invoiceNamesLangIds.set(InvoiceStatus.Canceled, 'Canceled');

const Invoices: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { loading: subscriptionLoading, data: subscription } = useSelector(
    accountSelectors.getSubscription
  );
  const subscriptionType = subscription?.subscriptionType;

  const { loading, error, data } = useSelector(accountSelectors.getInvoices);

  useEffect(() => {
    if (
      !subscriptionLoading &&
      subscriptionType !== SubscriptionType.Automated
    ) {
      navigate(routes.subscription);
    }
  }, [subscriptionLoading, subscriptionType]);

  useEffect(() => {
    dispatch(accountActions.getInvoices());
  }, []);

  return (
    <BillingLayout page={BillingPage.Invoices}>
      <Table
        error={error}
        loading={loading}
        meta={null}
        canSelectRow={false}
        dataSource={
          data
            ? data.map((invoice: IInvoice) => ({
                key: invoice.uid,
                ...invoice,
              }))
            : []
        }
        columns={[
          {
            title: formatMessage({ id: 'Date' }),
            dataIndex: 'issueDate',
            key: 'issueDate',
            render(issueDate) {
              return date(issueDate).format(config.dateFormat);
            },
          },
          {
            title: formatMessage({ id: 'Description' }),
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: formatMessage({ id: 'Status' }),
            dataIndex: 'status',
            key: 'status',
            render(status) {
              return formatMessage({ id: invoiceNamesLangIds.get(status) });
            },
          },
          {
            title: formatMessage({ id: 'Total' }),
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render(total) {
              return `$${total}`;
            },
          },
          {
            title: formatMessage({ id: 'Total' }),
            dataIndex: 'url',
            key: 'url',
            render(url) {
              return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {formatMessage({ id: 'ViewInvoice' })}
                </a>
              );
            },
          },
        ]}
        locale={{
          emptyText: <EmptyTable text={formatMessage({ id: 'NoInvoices' })} />,
        }}
      />
    </BillingLayout>
  );
};

export default Invoices;
