import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Container } from './styled';
import { accountSelectors } from '@store';

import ChangeLocale from '../ChangeLocale/ChangeLocale';

interface IProps {
  hasBackground?: boolean;
}

const Footer: React.FC<React.PropsWithChildren<IProps>> = ({
  hasBackground,
}) => {
  const { formatMessage } = useIntl();

  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  return (
    <Container hasBackground={hasBackground}>
      &copy; {new Date().getFullYear()} {appConfiguration.appName}{' '}
      {!!appConfiguration?.termsOfServiceUrl && (
        <>
          <a href={appConfiguration.termsOfServiceUrl} target="_blank">
            {formatMessage({ id: 'TermsOfService' })}
          </a>{' '}
        </>
      )}
      {!!appConfiguration?.privacyAndTermsUrl && (
        <>
          <a href={appConfiguration.privacyAndTermsUrl} target="_blank">
            {formatMessage({ id: 'PrivacyAndTerms' })}
          </a>{' '}
        </>
      )}
      {!!appConfiguration?.contactUsUrl && (
        <a href={appConfiguration.contactUsUrl} target="_blank">
          {formatMessage({ id: 'ContactUs' })}
        </a>
      )}
      <ChangeLocale />
    </Container>
  );
};

export default Footer;
