import React from 'react';
import { AlertTriangle } from 'react-feather';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';

import {
  Footer,
  Container,
  Content,
  ErrorText,
  FooterButton,
  FooterLink,
  Tabs,
} from './styled';

import SlideModal from '@components/shared/SideModal/SideModal';

export interface FooterAction {
  title: string;
  footerButtonRoute?: string;
  onFooterButtonClick: () => void;
  color: string;
  icon?: React.ReactNode;
  e2eDataAttr?: string;
  disabled?: boolean;
}

interface IProps {
  loading: boolean;
  opened: boolean;
  closeModal: () => void;
  tabs: { title: string; content: React.ReactNode }[];
  error?: string | null;
  topBarLeadingContent?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  footerActions: FooterAction[];
}

const ViewModalLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  loading,
  error,
  opened,
  closeModal,
  tabs,
  topBarLeadingContent,
  title,
  subTitle,
  footerActions,
}) => {
  return (
    <SlideModal
      opened={opened}
      onModalClose={closeModal}
      topBarLeadingContent={topBarLeadingContent}
      headerAlign="left"
      title={title}
      zIndex={1100}
      subTitle={subTitle}
      footer={
        footerActions.length > 0 ? (
          <Footer>
            {loading ? (
              <Spin spinning />
            ) : (
              <>
                {footerActions.map(
                  (
                    {
                      title,
                      color,
                      onFooterButtonClick,
                      footerButtonRoute,
                      e2eDataAttr,
                      icon,
                      disabled,
                    },
                    i
                  ) => {
                    if (footerButtonRoute) {
                      return (
                        <FooterLink
                          key={i}
                          color={color}
                          to={footerButtonRoute}
                        >
                          {icon}
                          {title}
                        </FooterLink>
                      );
                    }

                    return (
                      <FooterButton
                        key={i}
                        disabled={disabled}
                        color={color}
                        onClick={onFooterButtonClick}
                        data-e2e={e2eDataAttr}
                      >
                        {icon}
                        {title}
                      </FooterButton>
                    );
                  }
                )}
              </>
            )}
          </Footer>
        ) : undefined
      }
      footerHeight={44}
      footerPadding={0}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {error ? (
            <ErrorText>
              <AlertTriangle />
              <div>{error}</div>
            </ErrorText>
          ) : (
            <Container>
              <Content>
                {tabs.length === 1 ? (
                  tabs[0].content
                ) : (
                  <Tabs
                    defaultActiveKey="1"
                    items={tabs.map(({ title, content }, i) => ({
                      key: (i + 1).toString(),
                      label: title,
                      children: content,
                    }))}
                  />
                )}
              </Content>
            </Container>
          )}
        </>
      )}
    </SlideModal>
  );
};

export default ViewModalLayout;
