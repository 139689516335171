import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { Action } from '@components/shared/ActionsModal/styled';
import { generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import {
  issueTypesActions,
  issueTypesSelectors,
} from '@application/IssueTypes/store';
import { routes } from '@config';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';

const IssueTypesActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const issueTypesDictionary = useSelector(
    issueTypesSelectors.getIssueTypesDictionary
  );
  let issueType =
    selectedTableRowKeys.length === 1
      ? issueTypesDictionary[selectedTableRowKeys[0]]
      : null;

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                if (issueType) {
                  navigate(
                    routes.issueTypeDetails.replace(':id', issueType.id)
                  );
                }

                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          <Action
            onClick={() => {
              dispatch(
                issueTypesActions.toggleConfirmDeleteIssueTypesModal(
                  selectedTableRowKeys.length > 1 || !issueType
                    ? {
                        multiple: true,
                        data: selectedTableRowKeys,
                      }
                    : {
                        multiple: false,
                        data: issueType,
                      }
                )
              );
            }}
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default IssueTypesActionsModal;
