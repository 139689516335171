import React from 'react';

import { Container, DueDateLabelType, Label } from './styled';
import { config } from '@repo/shared/config';
import { date } from '@utils';

interface IProps {
  completeDate: string;
  timeZoneAbbreviation?: string;
  showLabel: boolean;
  labelText: string;
}

const CompleteDateTableCell: React.FC<React.PropsWithChildren<IProps>> = ({
  completeDate,
  timeZoneAbbreviation,
  showLabel,
  labelText,
}) => {
  return (
    <Container>
      {date(completeDate).format(config.dateFormat)}
      {timeZoneAbbreviation ? ` (${timeZoneAbbreviation})` : ''}
      {showLabel && <Label type={DueDateLabelType.Error}>{labelText}</Label>}
    </Container>
  );
};

export default CompleteDateTableCell;
