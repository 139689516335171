import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const ActionContainer = styled.button`
  margin-bottom: 16px;
  border-radius: 5px;
  border: 0;
  background: ${colors.gray3};
`;

export const Content = styled.div`
  padding: 18px 16px;
  text-align: left;
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 10px;

  svg {
    width: 18px;
    height: 18px;
    color: ${colors.green};
    margin: 2px 7px 0 0;
  }
`;

export const Text = styled.div`
  font-size: 14px;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${colors.white};
  padding: 16px 16px 10px;
`;

export const FooterItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 8px;

  label {
    color: ${colors.gray8};
    margin-right: 6px;
  }
`;

export const FooterItemDivider = styled.div`
  width: 1px;
  height: 16px;
  background: ${colors.white};
  margin: 0 16px 8px;
`;
