import { initializeApp, getApps, getApp, FirebaseError } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInWithPopup,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  confirmPasswordReset as confirmPasswordResetFirebase,
  User,
} from 'firebase/auth';
import { UnexpectedError, AuthenticationError } from '../errors';

const firebaseApp =
  getApps().length === 0
    ? initializeApp({
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_FIREBASE_APP_ID,
        measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
      })
    : getApp();
const firebaseAuth = getAuth(firebaseApp);

export namespace AuthService {
  export async function getAuthToken(
    forceRefresh?: boolean
  ): Promise<string | undefined> {
    const user = await _getFirebaseUser();
    return user?.getIdToken(forceRefresh);
  }

  export async function isAuthenticated(): Promise<boolean> {
    const user = await _getFirebaseUser();
    return !!user;
  }

  export async function getAuthUserId(): Promise<string | undefined> {
    const user = await _getFirebaseUser();
    return user?.uid;
  }

  export async function signInWithPassword(email: string, password: string) {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password);
    } catch (e: unknown) {
      if (e instanceof FirebaseError) {
        throw new AuthenticationError(e.code);
      }

      throw new UnexpectedError();
    }
  }

  export async function signInWithGoogle() {
    try {
      await signInWithPopup(firebaseAuth, new GoogleAuthProvider());
    } catch (e) {
      if (e instanceof FirebaseError) {
        throw new AuthenticationError(e.code);
      }

      throw new UnexpectedError();
    }
  }

  export async function signInWithToken(token: string) {
    try {
      await signInWithCustomToken(firebaseAuth, token);
    } catch (e) {
      if (e instanceof FirebaseError) {
        throw new AuthenticationError(e.code);
      }

      throw new UnexpectedError();
    }
  }

  export async function signOut() {
    try {
      await firebaseAuth.signOut();
    } catch (e) {
      if (e instanceof FirebaseError) {
        throw new AuthenticationError(e.code);
      }

      throw new UnexpectedError();
    }
  }

  export async function changePassword({
    email,
    password,
    currentPassword,
  }: {
    email: string;
    password: string;
    currentPassword?: string;
  }) {
    try {
      const user = await _getFirebaseUser();

      if (!user) {
        throw new Error('Firebase user is not instantiated');
      }

      if (currentPassword) {
        await reauthenticateWithCredential(
          user,
          EmailAuthProvider.credential(email, currentPassword)
        );
      }

      await updatePassword(user, password);

      await reauthenticateWithCredential(
        user,
        EmailAuthProvider.credential(email, password)
      );
    } catch (e) {
      if (e instanceof FirebaseError) {
        throw new AuthenticationError(e.code);
      }

      throw new UnexpectedError();
    }
  }

  export async function confirmPasswordReset(code: string, password: string) {
    try {
      await confirmPasswordResetFirebase(firebaseAuth, code, password);
    } catch (e) {
      if (e instanceof FirebaseError) {
        throw new AuthenticationError(e.code);
      }

      throw new UnexpectedError();
    }
  }

  async function _getFirebaseUser() {
    return new Promise<User | null>((resolve, reject) => {
      const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }
}
