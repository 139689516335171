import React from 'react';

import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

import { Container, Label } from './styled';

interface IProps {
  label: string;
  tooltip?: string;
  containerStyle?: React.CSSProperties;
}

const CheckboxRow: React.FC<React.PropsWithChildren<IProps>> = ({
  label,
  tooltip,
  children,
  containerStyle,
}) => {
  return (
    <Container style={containerStyle}>
      <Label>
        {label}
        {!!tooltip && (
          <CircleTooltip contentMaxWidth={250}>{tooltip}</CircleTooltip>
        )}
      </Label>
      {children}
    </Container>
  );
};

export default CheckboxRow;
