import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  accountSelectors,
  generalActions,
} from '@store';
import { Footer } from './styled';
import { notification } from '@utils/notifications';
import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';

import SlideModal from '../../../../shared/SideModal/SideModal';
import Form from '../../../../shared/ant/Form';
import { Input } from '../../../../shared/ant/Input';
import Button from '../../../../shared/ant/Button';

const AddEditAuditObjectGroupModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { visible, groupId } = useSelector(
    auditObjectsSelectors.groups.getAddEditGroupModalState,
    shallowEqual
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const [loading, setLoading] = useState(false);
  const isEdit = groupId !== null;
  const groups = useSelector(auditObjectsSelectors.groups.getGroupsMap);
  const group = (groupId && groups[groupId]) || null;
  const [form] = Form.useForm();
  const closeModal = useCallback(
    () =>
      dispatch(
        auditObjectsActions.groups.toggleAddEditModal({ visible: false })
      ),
    []
  );

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, group]);

  const itemName = formatMessage(
    { id: 'AuditObjectNameGroup' },
    { objectName: auditObjectName.single, count: 1 }
  ).toLowerCase();

  return (
    <SlideModal
      opened={visible}
      onModalClose={() => closeModal()}
      title={formatMessage({
        id: isEdit ? 'EditGroup' : 'CreateGroup',
      })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
            >
              {isEdit
                ? formatMessage({ id: 'Save' })
                : formatMessage({ id: 'CreateGroup' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                form?.resetFields();
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
          {isEdit && (
            <Button
              type="primary"
              style={{ background: colors.red }}
              onClick={() => {
                dispatch(
                  auditObjectsActions.groups.toggleConfirmDeleteModal([groupId])
                );
              }}
            >
              {formatMessage({ id: 'DeleteGroup' })}
            </Button>
          )}
        </Footer>
      }
    >
      <Form
        onFinish={async ({ auditObjectIds, name }: any) => {
          setLoading(true);

          const resultAction = await dispatch(
            auditObjectsActions.groups.updateGroup({
              id: group?.id,
              name,
              auditObjectIds,
            })
          );

          if (
            auditObjectsActions.groups.updateGroup.fulfilled.match(resultAction)
          ) {
            closeModal();
            dispatch(auditObjectsActions.groups.getData());
            dispatch(generalActions.getConciseAuditObjectGroups());

            notification.success({
              message: isEdit
                ? formatMessage(
                    { id: 'YouHaveSuccessfullyEditedItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'YouHaveSuccessfullyCreatedItem' },
                    { item: itemName }
                  ),
            });
          } else {
            notification.error({
              message: isEdit
                ? formatMessage(
                    { id: 'ErrorWhileCreatingItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'ErrorWhileEditingItem' },
                    { item: itemName }
                  ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        form={form}
        initialValues={{
          name: group?.name || '',
          auditObjectIds: group?.auditObjectIds || [],
        }}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label={formatMessage({ id: 'GroupTitle' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
        >
          <Input
            disabled={loading}
            placeholder={formatMessage({ id: 'EnterTitle' })}
          />
        </Form.Item>
      </Form>
    </SlideModal>
  );
};

export default AddEditAuditObjectGroupModal;
