import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountSelectors } from '@store';

import LabeledFilter from './LabeledFilter/LabeledFilter';
import AuditObjectsAndAuditObjectGroupsSelect from '@components/shared/EntitySelects/AuditObjectsAndAuditObjectGroupsSelect';

interface IProps {
  selectedValue: {
    auditObjectIds: string[];
    auditObjectGroupIds: string[];
  };
  onChange: (update: {
    auditObjectIds: string[];
    auditObjectGroupIds: string[];
  }) => void;
}

const AuditObject: React.FC<React.PropsWithChildren<IProps>> = ({
  selectedValue,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const auditObjectName = useSelector(accountSelectors.getObjectName);

  return (
    <LabeledFilter
      label={`${auditObjectName.plural} ${formatMessage({
        id: 'or',
      })} ${formatMessage({ id: 'Groups' })}`}
    >
      <AuditObjectsAndAuditObjectGroupsSelect
        useAccessible
        width="100%"
        value={selectedValue}
        maxTagCount="responsive"
        placeholder={formatMessage(
          { id: 'AddAuditObject' },
          {
            objectName: auditObjectName.single.toLowerCase(),
          }
        )}
        onChange={({ auditObjectIds, auditObjectGroupIds }) =>
          onChange({ auditObjectIds, auditObjectGroupIds })
        }
      />
    </LabeledFilter>
  );
};

export default AuditObject;
