import styled from 'styled-components';

import { colors } from '@repo/shared/config';

interface ButtonProps {
  selected?: boolean;
  selectedIconColor?: string;
}

export const Button = styled.button`
  border: 0;
  background: ${(props: ButtonProps) =>
    props.selected ? colors.gray3 : 'none'};
  color: ${(props: ButtonProps) =>
    props.selected ? colors.gray10 : colors.gray8};
  cursor: ${(props: ButtonProps) => (props.selected ? 'default' : 'pointer')};
  font-size: 14px;
  line-height: 20px;
  padding: 6px 22px;

  &:focus {
    outline: none;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
    width: 18px;
    height: 18px;
    margin-top: -3px;
    color: ${(props: ButtonProps) =>
      props.selected ? props.selectedIconColor : colors.gray8};
  }
`;
