import styled from 'styled-components';
import Space from 'antd/es/space';
import Radio from 'antd/es/radio';

import { FormTitle } from '../../shared/ant/Form';
import { colors } from '@repo/shared/config';

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

interface IColorButtonProps {
  value: string;
}

export const ColorsHeader = styled(FormTitle)`
  margin-bottom: 8px;
`;

export const Colors = styled(Radio.Group)`
  margin: 10px -8px 0;
  text-align: center;
`;

export const ColorButton = styled(Radio.Button)`
  position: relative;
  width: 40px;
  height: 40px;
  border: 0 !important;
  margin: 0 7px 18px;
  cursor: pointer;
  background: none !important;
  padding: 0;

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    border-radius: 100%;
  }

  &:before {
    width: 32px !important;
    height: 32px !important;
    left: 50% !important;
    top: 50% !important;
    padding: 0 !important;
    background-color: ${(props: IColorButtonProps) => props.value}!important;
    transform: translate(-50%, -50%);
  }

  &:after {
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    border: 2px solid ${(props: IColorButtonProps) => props.value};
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &:focus,
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }

  &.ant-radio-button-wrapper-checked {
    &:after {
      opacity: 1;
    }
  }
`;

export const Hex = styled.div`
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.gray10};
  border: 1px solid ${colors.gray5};
  border-radius: 10px;
  padding: 5px 12px;
  width: 90px;
  margin-bottom: 10px;
`;

export const Preview = styled.div`
  background: ${colors.gray2};
  border-radius: 10px;
  padding: 40px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
