import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { config, colors } from '@repo/shared/config';

const iframeStyles = {
  '#card-number': { width: '420px' },
  '#month': { width: '131px' },
  '#year': { width: '131px' },
  '#cvv': { width: '131px' },
  '#name': { width: '421px' },
  '#firstName': { width: '204px' },
  '#lastName': { width: '203px' },
};

const inputStyles = {
  field: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    margin: '0px',
  },
  input: {
    backgroundColor: colors.gray3,
    placeholder: { color: colors.gray8 },
  },
  requiredLabel: '*',
  label: {
    fontSize: '12px',
    color: colors.gray8,
    fontWeight: 400,
    margin: '0 0 9px 0',
    lineHeight: 1,
  },
  message: {
    padding: '0px',
    marginTop: '2px',
    color: 'transparent',
    fontSize: '0px',
  },
};

function useChargifyConfig() {
  const { formatMessage } = useIntl();

  return {
    selector: '#chargify-form',
    publicKey: config.chargify.publicKey,
    serverHost: config.chargify.host,
    type: 'card',
    style: {
      ...iframeStyles,
    },
    fields: {
      number: {
        selector: '#card-number',
        label: formatMessage({ id: 'CardNumber' }),
        required: true,
        style: inputStyles,
      },
      month: {
        selector: '#month',
        label: formatMessage({ id: 'Month' }),
        required: true,
        style: inputStyles,
      },
      year: {
        selector: '#year',
        label: formatMessage({ id: 'Year' }),
        required: true,
        style: inputStyles,
      },
      cvv: {
        selector: '#cvv',
        label: formatMessage({ id: 'CVC/CVV' }),
        required: true,
        style: inputStyles,
      },
      firstName: {
        selector: '#firstName',
        label: formatMessage({ id: 'FirstName' }),
        required: true,
        style: inputStyles,
      },
      lastName: {
        selector: '#lastName',
        label: formatMessage({ id: 'LastName' }),
        required: true,
        style: inputStyles,
      },
    },
  };
}

const chargify = new window.Chargify();

export default function useChargify() {
  const config = useChargifyConfig();
  const chargifyFormRef = useRef<HTMLFormElement | null>(null);
  const chargifyRef = useRef(chargify);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function loadChargify() {
      await chargifyRef.current.load(config);
      setLoading(false);
    }

    loadChargify();
  }, []);

  return {
    chargifyLoading: loading,
    chargifyFormRef,
    submitChargifyForm() {
      return new Promise((resolve, reject) => {
        chargifyRef.current.token(
          chargifyFormRef.current,
          (token: string) => {
            return resolve(token);
          },
          (errResponse: { status: number; errors: string }) => {
            return reject(errResponse);
          }
        );
      });
    },
  };
}
