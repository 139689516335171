import React from 'react';
import { useIntl } from 'react-intl';

import { useItemAnswerResponse } from '@application/Actions/hooks/useItemAnswerResponse';
import { ActionDetails } from '@domain/Actions/models/Action';

import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';

interface IProps {
  action: ActionDetails;
}

const ActionsDetailsQuestion: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  const answer = useItemAnswerResponse(action?.answer);

  if (!answer) {
    return null;
  }

  return (
    <ActionDetailsInfoItem
      label={formatMessage({ id: 'Answer' })}
      content={answer}
    />
  );
};

export default ActionsDetailsQuestion;
