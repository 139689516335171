import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  padding: 50px 40px 40px 40px;
  background: ${colors.white};
  width: 450px;
`;

export const UploadFilesContainer = styled.div`
  margin: 0 0 15px 0;
`;

export const Error = styled.div`
  margin-top: 6px;
  color: ${colors.red};
  font-size: 13px;
`;
