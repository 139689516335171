import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { settingsSelectors, settingsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { notification } from '@utils';

import Integration from '../Integration/Integration';
import ApiKeyInput from './ApiKeyInput/ApiKeyInput';
import ConfirmRegenerateApiKeyModal from './ConfirmRegenerateApiKeyModal';

const ApiKey: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const apiKey = useSelector(settingsSelectors.integrations.getApiKey);

  return (
    <>
      <Integration
        title={formatMessage({ id: 'APIKey' })}
        description={formatMessage({
          id: 'YourAPIKeyNeedToBeTreatedAsSecurelyAsAnyOtherPassword',
        })}
        showDelete={apiKey !== null}
        content={<ApiKeyInput apiKey={apiKey} />}
        onDelete={async () => {
          const resultAction = await dispatch(
            settingsActions.integrations.deleteApiKey()
          );

          if (
            settingsActions.integrations.deleteApiKey.fulfilled.match(
              resultAction
            )
          ) {
            notification.success({
              message: formatMessage({
                id: 'ApiKeyHasBeenSuccessfullyDeleted',
              }),
            });
          } else {
            notification.success({
              message: formatMessage({
                id: 'AnErrorOccurredWhileDeletingApiKey',
              }),
            });
          }
        }}
        onSave={async () => {
          if (apiKey !== null) {
            dispatch(
              settingsActions.integrations.toggleConfirmRegenerateModal(true)
            );
            return;
          }

          const resultAction = await dispatch(
            settingsActions.integrations.generateApiKey({
              regenerate: false,
            })
          );

          if (
            settingsActions.integrations.generateApiKey.fulfilled.match(
              resultAction
            )
          ) {
            notification.success({
              message: formatMessage({
                id: 'ApiKeyHasBeenSuccessfullyGenerated',
              }),
            });
          } else {
            notification.error({
              message: formatMessage({
                id: 'AnErrorOccurredWhileGeneratingApiKey',
              }),
            });
          }
        }}
        onSaveBtnLabel={formatMessage({
          id: apiKey === null ? 'Generate' : 'Regenerate',
        })}
      />
      <ConfirmRegenerateApiKeyModal />
    </>
  );
};

export default ApiKey;
