import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  border-top: 1px solid ${colors.gray2};

  .ant-btn-link {
    color: ${colors.gray8};
    padding-left: 7px;
  }
`;

export const UserListWrapper = styled.div`
  height: 300px;
  padding-left: 15px;
`;

export const SearchWrapper = styled.div`
  padding: 12px 10px;
`;
