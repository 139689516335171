import React from 'react';
import { useIntl } from 'react-intl';

import Form from '@components/shared/ant/Form';
import { Input } from '@components/shared/ant/Input';
import { AttributeFieldType } from '@repo/shared/enums';
import Select from '@components/shared/ant/Select/Select';
import { IAuditObjectAttribute } from '@repo/shared/types';
import { e2eTestElements } from '@config';

interface IProps {
  loading?: boolean;
  attributeId: string;
  label?: string;
  attributesMap: Record<string, IAuditObjectAttribute>;
  required?: boolean;
  placeholder?: string;
  formItemName?: string;
  onChange?: (newOptionId: string | null) => void;
}

const AttributeValue: React.FC<React.PropsWithChildren<IProps>> = ({
  loading,
  attributeId,
  label,
  attributesMap,
  required,
  placeholder,
  formItemName = 'optionId',
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const attribute =
    attributesMap && attributesMap[attributeId]
      ? attributesMap[attributeId]
      : null;

  return (
    <Form.Item
      name={formItemName}
      label={label}
      rules={
        required
          ? [
              {
                required: true,
                message: formatMessage({ id: 'RequiredField' }),
              },
            ]
          : undefined
      }
    >
      {attribute?.fieldType === AttributeFieldType.Dropdown &&
      Array.isArray(attribute.options) ? (
        <Select
          disabled={loading}
          options={attribute.options.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          allowClear={!required}
          placeholder={placeholder}
          onChange={(newAttributeId) => {
            if (onChange) {
              onChange(newAttributeId || null);
            }
          }}
          onClear={() => {
            if (onChange) {
              onChange(null);
            }
          }}
          e2eDataAttr={
            e2eTestElements.objects.attributesAssignments
              .selectDropdownAttributeValueDropdown
          }
        />
      ) : (
        <Input
          data-e2e={
            e2eTestElements.objects.attributesAssignments
              .selectInputAttributeValue
          }
          disabled={loading}
          placeholder={placeholder}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
        />
      )}
    </Form.Item>
  );
};

export default AttributeValue;
