import { createReducer, Reducer } from '@reduxjs/toolkit';

import { generalActions } from './general.actions';
import { IFileUploads, IModalState } from '@repo/shared/types';
import { IGeneralState } from '@types';
import {
  DateRangeType,
  ParticipationType,
  UploadStatus,
  Modal,
} from '@repo/shared/enums';
import { date } from '@utils';
import { config } from '@repo/shared/config';
import {
  accessibleAuditObjectsAdapter,
  auditObjectsConciseAdapter,
  auditObjectsGroupConciseAdapter,
  conciseRolesAdapter,
  tagsConciseAdapter,
  templatesConciseAdapter,
  userGroupsConciseAdapter,
  usersConciseAdapter,
  actionTemplatesConciseAdapter,
  attributesAdapter,
  jobTitlesConciseAdapter,
  participantsAdapter,
} from '@store/entityAdapters';
import { v4 as uuid } from 'uuid';

export const initialState = {
  selectedTableRowKeys: [],
  modals: {
    [Modal.AddEdit]: {
      opened: false,
    },
    [Modal.ConfirmDelete]: {
      opened: false,
    },
    [Modal.SuspendActivate]: {
      opened: false,
    },
    [Modal.SelectUser]: {
      opened: false,
    },
    [Modal.View]: {
      opened: false,
    },
    [Modal.Information]: {
      opened: false,
    },
    [Modal.ConfirmExpire]: {
      opened: false,
    },
  },
  concise: {
    users: {
      loading: false,
      error: null,
      data: usersConciseAdapter.getInitialState(),
    },
    userGroups: {
      loading: false,
      error: null,
      data: userGroupsConciseAdapter.getInitialState(),
    },
    auditTemplates: {
      loading: false,
      error: null,
      data: templatesConciseAdapter.getInitialState(),
    },
    auditObjects: {
      loading: false,
      error: null,
      data: auditObjectsConciseAdapter.getInitialState(),
    },
    accessibleAuditObjects: {
      loading: false,
      error: null,
      data: accessibleAuditObjectsAdapter.getInitialState(),
    },
    auditObjectGroups: {
      loading: false,
      error: null,
      data: auditObjectsGroupConciseAdapter.getInitialState(),
    },
    tags: {
      loading: false,
      error: null,
      data: tagsConciseAdapter.getInitialState(),
    },
    roles: {
      loading: false,
      error: null,
      data: conciseRolesAdapter.getInitialState(),
    },
    auditObjectAttributes: {
      loading: false,
      error: null,
      data: attributesAdapter.getInitialState(),
    },
    actionTemplates: {
      loading: false,
      error: null,
      data: actionTemplatesConciseAdapter.getInitialState(),
    },
    jobTitles: {
      loading: false,
      error: null,
      data: jobTitlesConciseAdapter.getInitialState(),
    },
  },
  timeZones: {
    loading: false,
    data: [],
    error: null,
  },
  sharedFilters: {
    templateIds: [],
    auditObjectIds: [],
    auditObjectGroupIds: [],
    dateRangeType: DateRangeType.PriorMonth,
    startDate: date()
      .startOf('day')
      .subtract(1, 'month')
      .format(config.apiDateFormat),
    endDate: date().endOf('day').format(config.apiDateFormat),
    userIds: [],
    userGroupIds: [],
    tagsIds: [],
    participationType: ParticipationType.HasAccess,
  },
  fileUploads: {} as IFileUploads,
  assigneesModal: {
    show: false,
    context: null,
    participants: {
      data: participantsAdapter.getInitialState(),
      loading: false,
      error: null,
    },
  },
  feedbackModal: {
    page: null,
    rating: undefined,
  },
  answerTypes: {
    loading: false,
    error: null,
    data: [],
  },
};

export const generalReducer: Reducer<IGeneralState> =
  createReducer<IGeneralState>(initialState, (builder) =>
    builder
      .addCase(generalActions.showModal, (state, { payload }) => {
        const modalData: IModalState = {
          opened: true,
        };

        if (payload.data) {
          modalData.data = payload.data;
        }

        state.modals[payload.name as Modal] = modalData;
      })
      .addCase(
        generalActions.hideModal.pending,
        (state, { meta: { arg: modal } }) => {
          state.modals[modal] = {
            ...state.modals[modal],
            opened: false,
          };
        }
      )
      .addCase(
        generalActions.hideModal.fulfilled,
        (state, { payload: modal }) => {
          state.modals[modal] = {
            opened: false,
          };
        }
      )
      .addCase(generalActions.selectTableRows, (state, { payload }) => {
        state.selectedTableRowKeys = payload;
      })
      .addCase(generalActions.getConciseUsers.pending, (state) => {
        state.concise.users.loading = true;
        state.concise.users.error = null;
      })
      .addCase(
        generalActions.getConciseUsers.fulfilled,
        (state, { payload }) => {
          usersConciseAdapter.setAll(state.concise.users.data, payload);
          state.concise.users.loading = false;
        }
      )
      .addCase(generalActions.getConciseUsers.rejected, (state, payload) => {
        state.concise.users.loading = false;
        state.concise.users.error = payload.payload as string;
      })
      .addCase(generalActions.getConciseUserGroups.pending, (state) => {
        state.concise.userGroups.loading = true;
        state.concise.userGroups.error = null;
      })
      .addCase(
        generalActions.getConciseUserGroups.fulfilled,
        (state, { payload }) => {
          userGroupsConciseAdapter.setAll(
            state.concise.userGroups.data,
            payload
          );
          state.concise.userGroups.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseUserGroups.rejected,
        (state, payload) => {
          state.concise.userGroups.loading = false;
          state.concise.userGroups.error = payload.payload as string;
        }
      )
      .addCase(generalActions.getConciseAuditObjects.pending, (state) => {
        state.concise.auditObjects.loading = true;
        state.concise.auditObjects.error = null;
      })
      .addCase(
        generalActions.getConciseAuditObjects.fulfilled,
        (state, { payload }) => {
          auditObjectsConciseAdapter.setAll(
            state.concise.auditObjects.data,
            payload
          );
          state.concise.auditObjects.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseAuditObjects.rejected,
        (state, payload) => {
          state.concise.auditObjects.loading = false;
          state.concise.auditObjects.error = payload.payload as string;
        }
      )
      .addCase(generalActions.getConciseAuditObjectGroups.pending, (state) => {
        state.concise.auditObjectGroups.loading = true;
        state.concise.auditObjectGroups.error = null;
      })
      .addCase(
        generalActions.getConciseAuditObjectGroups.fulfilled,
        (state, { payload }) => {
          auditObjectsGroupConciseAdapter.setAll(
            state.concise.auditObjectGroups.data,
            payload
          );
          state.concise.auditObjectGroups.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseAuditObjectGroups.rejected,
        (state, payload) => {
          state.concise.auditObjectGroups.loading = false;
          state.concise.auditObjectGroups.error = payload.payload as string;
        }
      )
      .addCase(generalActions.getConciseTemplates.pending, (state) => {
        state.concise.auditTemplates.loading = true;
        state.concise.auditTemplates.error = null;
      })
      .addCase(
        generalActions.getConciseTemplates.fulfilled,
        (state, { payload }) => {
          templatesConciseAdapter.setAll(
            state.concise.auditTemplates.data,
            payload
          );
          state.concise.auditTemplates.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseTemplates.rejected,
        (state, payload) => {
          state.concise.auditTemplates.loading = false;
          state.concise.auditTemplates.error = payload.payload as string;
        }
      )
      .addCase(generalActions.getConciseTags.pending, (state) => {
        state.concise.tags.loading = true;
        state.concise.tags.error = null;
      })
      .addCase(
        generalActions.getConciseTags.fulfilled,
        (state, { payload }) => {
          tagsConciseAdapter.setAll(state.concise.tags.data, payload);
          state.concise.tags.loading = false;
        }
      )
      .addCase(generalActions.getConciseTags.rejected, (state, payload) => {
        state.concise.tags.loading = false;
        state.concise.tags.error = payload.payload || null;
      })
      .addCase(generalActions.getConciseRoles.pending, (state) => {
        state.concise.roles.loading = true;
        state.concise.roles.error = null;
      })
      .addCase(
        generalActions.getConciseRoles.fulfilled,
        (state, { payload }) => {
          conciseRolesAdapter.setAll(state.concise.roles.data, payload);
          state.concise.roles.loading = false;
        }
      )
      .addCase(generalActions.getConciseRoles.rejected, (state, payload) => {
        state.concise.roles.loading = false;
        state.concise.roles.error = payload.payload || null;
      })
      .addCase(
        generalActions.getConciseAuditObjectAttributes.pending,
        (state) => {
          state.concise.auditObjectAttributes.loading = true;
          state.concise.auditObjectAttributes.error = null;
        }
      )
      .addCase(
        generalActions.getConciseAuditObjectAttributes.fulfilled,
        (state, { payload }) => {
          attributesAdapter.setAll(
            state.concise.auditObjectAttributes.data,
            payload
          );
          state.concise.auditObjectAttributes.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseAuditObjectAttributes.rejected,
        (state, payload) => {
          state.concise.auditObjectAttributes.loading = false;
          state.concise.auditObjectAttributes.error = payload.payload || null;
        }
      )
      .addCase(generalActions.getConciseActionTemplates.pending, (state) => {
        state.concise.actionTemplates.loading = true;
        state.concise.actionTemplates.error = null;
      })
      .addCase(
        generalActions.getConciseActionTemplates.fulfilled,
        (state, { payload }) => {
          actionTemplatesConciseAdapter.setAll(
            state.concise.actionTemplates.data,
            payload
          );
          state.concise.actionTemplates.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseActionTemplates.rejected,
        (state, payload) => {
          state.concise.actionTemplates.loading = false;
          state.concise.actionTemplates.error = payload.payload || null;

          actionTemplatesConciseAdapter.setAll(
            state.concise.actionTemplates.data,
            [
              {
                id: uuid(),
                name: 'Action Template #1',
              },
              {
                id: uuid(),
                name: 'Action Template #2',
              },
              {
                id: uuid(),
                name: 'Action Template #3',
              },
              {
                id: uuid(),
                name: 'Action Template #4',
              },
            ]
          );
        }
      )
      .addCase(generalActions.getConciseJobTitles.pending, (state) => {
        state.concise.jobTitles.loading = true;
        state.concise.jobTitles.error = null;
      })
      .addCase(
        generalActions.getConciseJobTitles.fulfilled,
        (state, { payload }) => {
          jobTitlesConciseAdapter.setAll(state.concise.jobTitles.data, payload);
          state.concise.jobTitles.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseJobTitles.rejected,
        (state, payload) => {
          state.concise.jobTitles.loading = false;
          state.concise.jobTitles.error = payload.payload || null;
        }
      )
      .addCase(
        generalActions.getConciseAccessibleAuditObjects.pending,
        (state) => {
          state.concise.accessibleAuditObjects.loading = true;
          state.concise.accessibleAuditObjects.error = null;
        }
      )
      .addCase(
        generalActions.getConciseAccessibleAuditObjects.fulfilled,
        (state, { payload }) => {
          accessibleAuditObjectsAdapter.setAll(
            state.concise.accessibleAuditObjects.data,
            payload
          );
          state.concise.accessibleAuditObjects.loading = false;
        }
      )
      .addCase(
        generalActions.getConciseAccessibleAuditObjects.rejected,
        (state, payload) => {
          state.concise.accessibleAuditObjects.loading = false;
          state.concise.accessibleAuditObjects.error = payload.payload || null;
        }
      )
      .addCase(generalActions.resetAccountData, () => {
        return {
          ...initialState,
        };
      })
      .addCase(generalActions.getTimeZones.pending, (state) => {
        state.timeZones.error = null;
        state.timeZones.loading = true;
      })
      .addCase(generalActions.getTimeZones.fulfilled, (state, { payload }) => {
        state.timeZones.data = payload;
        state.timeZones.loading = false;
      })
      .addCase(generalActions.getTimeZones.rejected, (state, { payload }) => {
        state.timeZones.error = payload || null;
        state.timeZones.loading = false;
      })
      .addCase(generalActions.setSharedFilters, (state, { payload }) => {
        state.sharedFilters = {
          ...state.sharedFilters,
          ...payload,
        };
      })
      .addCase(
        generalActions.registerFileUploads,
        (state, { payload: files }) => {
          for (let i = 0; i < files.length; i++) {
            const { file, ...rest } = files[i];

            state.fileUploads[rest.id] = {
              file: rest,
              status: UploadStatus.Pending,
              progress: 0,
              error: null,
            };
          }
        }
      )
      .addCase(
        generalActions.uploadFile.pending,
        (state, { meta: { arg } }) => {
          const { file, ...rest } = arg.file;

          state.fileUploads[rest.id] = {
            file: rest,
            status: UploadStatus.Pending,
            progress: 0,
            error: null,
          };
        }
      )
      .addCase(
        generalActions.uploadFile.fulfilled,
        (state, { payload: { fileId } }) => {
          if (state.fileUploads[fileId] !== undefined) {
            state.fileUploads[fileId].status = UploadStatus.Done;
            state.fileUploads[fileId].progress = 100;
          }
        }
      )
      .addCase(generalActions.uploadFile.rejected, (state, { payload }) => {
        if (payload && state.fileUploads[payload.id] !== undefined) {
          state.fileUploads[payload.id].error = payload.errorText;
          state.fileUploads[payload.id].status = UploadStatus.Error;
        }
      })
      .addCase(generalActions.resetFileUploads, (state) => {
        state.fileUploads = {};
      })
      .addCase(
        generalActions.setUploadProgress,
        (state, { payload: { id, progress } }) => {
          if (state.fileUploads[id] !== undefined) {
            state.fileUploads[id].progress = progress;
          }
        }
      )
      .addCase(generalActions.deleteFileUpload, (state, { payload }) => {
        if (state.fileUploads[payload] !== undefined) {
          delete state.fileUploads[payload];
        }
      })
      .addCase(generalActions.startUpload, (state, { payload }) => {
        if (state.fileUploads[payload] !== undefined) {
          state.fileUploads[payload].status = UploadStatus.Uploading;
        }
      })
      .addCase(generalActions.assigneesModal.toggle, (state, { payload }) => {
        state.assigneesModal.show = payload;
      })
      .addCase(
        generalActions.assigneesModal.getParticipants.pending,
        (state) => {
          state.assigneesModal.participants.loading = true;
        }
      )
      .addCase(
        generalActions.assigneesModal.getParticipants.fulfilled,
        (state, { payload }) => {
          state.assigneesModal.participants.loading = false;

          participantsAdapter.setAll(
            state.assigneesModal.participants.data,
            payload
          );
        }
      )
      .addCase(
        generalActions.assigneesModal.getParticipants.rejected,
        (state, { payload }) => {
          state.assigneesModal.participants.loading = false;
          state.assigneesModal.participants.error = payload || null;
        }
      )
      .addCase(
        generalActions.assigneesModal.setContext,
        (state, { payload }) => {
          state.assigneesModal.context = payload;
        }
      )
      .addCase(generalActions.feedbackModal.setPage, (state, { payload }) => {
        state.feedbackModal.page = payload;
      })
      .addCase(generalActions.feedbackModal.setRating, (state, { payload }) => {
        state.feedbackModal.rating = payload;
      })
      .addCase(generalActions.getAnswerTypes.pending, (state) => {
        state.answerTypes.loading = true;
        state.answerTypes.error = null;
      })
      .addCase(
        generalActions.getAnswerTypes.fulfilled,
        (state, { payload }) => {
          state.answerTypes.loading = false;
          state.answerTypes.data = payload;
        }
      )
      .addCase(generalActions.getAnswerTypes.rejected, (state, { payload }) => {
        state.answerTypes.loading = false;
        state.answerTypes.error = payload || null;
      })
  );
