import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'antd/es/skeleton';

import { Container, DefinitionRow, PeriodContainer } from './styled';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { useAssignmentTypeDefinition } from '@application/SchedulePlans/hooks/useAssignmentTypeDefinition';
import { date } from '@utils';
import { routes } from '@config';
import { config } from '@repo/shared/config';

import Definition from '@components/shared/Definition/Definition';
import Button from '@components/shared/ant/Button';

interface IProps {}

const SchedulePlanDetailsInfo: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { data, loading, error } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );

  const assignmentTypeDefinition = useAssignmentTypeDefinition();

  if (!loading && error) {
    return null;
  }

  return (
    <Container>
      <DefinitionRow>
        <Definition
          horizontal
          loading={loading}
          label={formatMessage({ id: 'PlanningPeriod' })}
          value={`${data?.planningPeriodWeeks} ${formatMessage(
            { id: 'WeeksPlural' },
            { count: data?.planningPeriodWeeks || 1 }
          )}`}
        />
        {!!data?.auditorsCount && (
          <Definition
            horizontal
            loading={loading}
            label={formatMessage({ id: 'Auditors' })}
            value={data.auditorsCount}
          />
        )}
        <Definition
          horizontal
          loading={loading}
          label={formatMessage({ id: 'CurrentPeriod' })}
          value={
            !!data?.currentPeriod ? (
              <PeriodContainer>
                <span>
                  {date(data.currentPeriod.startDate).format(config.dateFormat)}{' '}
                  - {date(data.currentPeriod.endDate).format(config.dateFormat)}
                </span>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(
                      routes.advancedSchedulePeriodDetails
                        .replace(':schedulePlanId', data.id)
                        .replace(':schedulePlanPeriodId', data.currentPeriod.id)
                    );
                  }}
                >
                  {formatMessage({ id: 'Details' }).toLowerCase()}
                </Button>
              </PeriodContainer>
            ) : (
              '-'
            )
          }
        />
      </DefinitionRow>
      <DefinitionRow>
        {loading ? (
          <Skeleton.Input active size="small" style={{ borderRadius: 5 }} />
        ) : (
          <Definition
            horizontal
            loading={loading}
            label={assignmentTypeDefinition?.label || ''}
            value={assignmentTypeDefinition?.value || ''}
          />
        )}
      </DefinitionRow>
    </Container>
  );
};

export default SchedulePlanDetailsInfo;
