import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IScore, IConcise, ITableFilters } from '@repo/shared/types';
import {
  createGetEntitiesThunk,
  createAddOrUpdateEntityThunk,
  createDeleteEntityThunk,
} from '../../../utils/apiHelpers';
import { scoresSelectors } from './scores.selectors';
import { getErrorMessage } from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import { apiUrls } from '@src/config/apiUrls';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

export const scoresActions = {
  getScores: createGetEntitiesThunk<IScore, ITableFilters>({
    entityName: 'score',
    apiUrl: `${apiUrls.scores}/detailed`,
    filtersSelector: scoresSelectors.getFilters,
  }),
  updateScore: createAddOrUpdateEntityThunk<IScore>({
    apiUrl: apiUrls.scores,
    entityName: 'score',
  }),
  deleteScore: createDeleteEntityThunk({
    apiUrl: apiUrls.scores,
    entityName: 'score',
    showErrorNotification: true,
  }),
  getScoresConcise: createAsyncThunk<IConcise[]>(
    'scores/getConcise',
    async (_, { rejectWithValue }) => {
      try {
        return apiService.get({
          url: `${apiUrls.scores}/concise`,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  setFilters: createAction<Partial<ITableFilters>>('scores/getDashboard'),
};
