import React from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@repo/shared/enums';
import { ITag } from '@repo/shared/types';
import { generalActions } from '@store';
import { useAppDispatch } from '@hooks';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  tag: ITag;
}

const TagsActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  tag,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'EditTag' }),
          onClick: () =>
            dispatch(
              generalActions.showModal({ name: Modal.AddEdit, data: tag })
            ),
        },
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () =>
            dispatch(
              generalActions.showModal({ name: Modal.ConfirmDelete, data: tag })
            ),
        },
      ]}
    />
  );
};

export default TagsActionsMenu;
