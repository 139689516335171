import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const CustomPlanDesc = styled.div`
  font-size: 13px;
  color: ${colors.gray8};
`;

export const LimitsHeader = styled.h3`
  margin: 25px 0 16px 0;
  color: ${colors.gray8};
  font-size: 13px;
  font-weight: 400;
`;
