import { GetExpiredAuditDto } from '@infrastructure/Audits/models/GetExpiredAuditDto';
import { Audit } from '@domain/Audits/models/Audit';

export function transformExpiredAuditDtoToAudit(
  dto: GetExpiredAuditDto
): Audit {
  return {
    ...dto,
    startedBy: null,
    startedAtInformation: null,
    pendingActionsCount: null,
    completedActionsCount: null,
    expiredActionsCount: null,
    completedBy: null,
    completedAtInformation: null,
    score: null,
    scoreLabel: null,
    scoreColor: null,
    scoreChange: null,
    dueInDays: 0,
    canStartAudit: false,
  };
}
