import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '@components/shared/ActionsModal/styled';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { generalSelectors } from '@store';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';

import ActionsModal from '@components/shared/ActionsModal/ActionsModal';

const SchedulesActionsModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const schedulesDictionary = useSelector(
    schedulesSelectors.getSchedulesDictionary
  );
  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);

  let schedule =
    selectedTableRowKeys.length === 1
      ? schedulesDictionary[selectedTableRowKeys[0]]
      : null;

  if (!canScheduleAudits) {
    return null;
  }

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                dispatch(
                  schedulesActions.toggleAddEditModal({
                    opened: true,
                    scheduleId: selectedTableRowKeys[0],
                  })
                );
                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          <Action
            onClick={() => {
              dispatch(
                schedulesActions.toggleDeleteModal(
                  selectedTableRowKeys.length > 1 || !schedule
                    ? {
                        multiple: true,
                        data: selectedTableRowKeys,
                      }
                    : {
                        multiple: false,
                        data: schedule,
                      }
                )
              );
            }}
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default SchedulesActionsModal;
