import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 480px;
  margin: 0 20px;
`;

export const Title = styled.h2`
  margin-bottom: 10px;
`;

export const Desc = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  color: ${colors.gray8};
  line-height: 1.45;
`;
