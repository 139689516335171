import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useAppDispatch, usePrevious } from '@hooks';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { actionsActions } from '@application/Actions/store/actionsActions';
import { getRoutesFromActionPage } from '@application/Actions/utils/getRoutesFromActionPage';

interface IProps {
  page: ActionsPage;
}

const ActionDetailsRouteHandler: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const actionId = useSelector(actionsSelectors.getActionDetailsId);

  const prevStateActionId = usePrevious(actionId);
  const prevParamActionId = usePrevious(id);

  useEffect(() => {
    if (!prevStateActionId && !prevParamActionId && !actionId && id) {
      dispatch(actionsActions.toggleActionDetailsModal(id));
    } else if (!actionId && id) {
      navigate(getRoutesFromActionPage(page).list);
    } else if (actionId && !id) {
      navigate(getRoutesFromActionPage(page).single.replace(':id', actionId));
    }
  }, [actionId, id, page]);

  return null;
};

export default ActionDetailsRouteHandler;
