import { forwardRef, SVGAttributes } from 'react';

import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const XlsIcon = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <rect
          x="4"
          y="2"
          width="20"
          height="24"
          rx="4"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.51209 23.0001C7.40542 23.0001 7.32542 22.9547 7.27209 22.8641C7.21875 22.7734 7.21609 22.6827 7.26409 22.5921L8.52009 20.1041C8.53609 20.0614 8.53609 20.0187 8.52009 19.9761L7.30409 17.5681C7.25609 17.4774 7.25875 17.3867 7.31209 17.2961C7.36542 17.2054 7.44542 17.1601 7.55209 17.1601H8.13609C8.27475 17.1601 8.40009 17.1974 8.51209 17.2721C8.62942 17.3467 8.71475 17.4481 8.76809 17.5761L9.46409 19.2481C9.46409 19.2534 9.46675 19.2561 9.47209 19.2561C9.47742 19.2561 9.48009 19.2534 9.48009 19.2481L10.1761 17.5761C10.2294 17.4481 10.3121 17.3467 10.4241 17.2721C10.5414 17.1974 10.6694 17.1601 10.8081 17.1601H11.3601C11.4614 17.1601 11.5388 17.2054 11.5921 17.2961C11.6508 17.3867 11.6561 17.4774 11.6081 17.5681L10.3921 19.9761C10.3761 20.0187 10.3761 20.0614 10.3921 20.1041L11.6481 22.5921C11.6961 22.6827 11.6908 22.7734 11.6321 22.8641C11.5788 22.9547 11.5014 23.0001 11.4001 23.0001H10.7841C10.6454 23.0001 10.5201 22.9627 10.4081 22.8881C10.2961 22.8081 10.2134 22.7041 10.1601 22.5761L9.44809 20.8321C9.44809 20.8267 9.44542 20.8241 9.44009 20.8241C9.43475 20.8241 9.43209 20.8267 9.43209 20.8321L8.72009 22.5761C8.66675 22.7041 8.58142 22.8081 8.46409 22.8881C8.35209 22.9627 8.22942 23.0001 8.09609 23.0001H7.51209Z"
          fill={color}
        />
        <path
          d="M12.8116 23.0001C12.6889 23.0001 12.5823 22.9547 12.4916 22.8641C12.4009 22.7734 12.3556 22.6667 12.3556 22.5441V17.6161C12.3556 17.4934 12.4009 17.3867 12.4916 17.2961C12.5823 17.2054 12.6889 17.1601 12.8116 17.1601H13.3076C13.4303 17.1601 13.5343 17.2054 13.6196 17.2961C13.7103 17.3867 13.7556 17.4934 13.7556 17.6161V21.8081C13.7556 21.8507 13.7796 21.8721 13.8276 21.8721H15.7716C15.8943 21.8721 16.0009 21.9174 16.0916 22.0081C16.1823 22.0987 16.2276 22.2054 16.2276 22.3281V22.5441C16.2276 22.6667 16.1823 22.7734 16.0916 22.8641C16.0009 22.9547 15.8943 23.0001 15.7716 23.0001H12.8116Z"
          fill={color}
        />
        <path
          d="M18.554 20.6641C17.882 20.4507 17.3966 20.1867 17.098 19.8721C16.7993 19.5521 16.65 19.1627 16.65 18.7041C16.65 18.2027 16.8233 17.8081 17.17 17.5201C17.522 17.2267 18.0233 17.0801 18.674 17.0801C19.1646 17.0801 19.61 17.1361 20.01 17.2481C20.138 17.2854 20.2393 17.3627 20.314 17.4801C20.394 17.5921 20.434 17.7174 20.434 17.8561V18.0881C20.434 18.1947 20.3886 18.2774 20.298 18.3361C20.2073 18.3947 20.1113 18.4054 20.01 18.3681C19.5566 18.2081 19.138 18.1281 18.754 18.1281C18.2633 18.1281 18.018 18.3201 18.018 18.7041C18.018 19.0294 18.2313 19.2614 18.658 19.4001C19.394 19.6241 19.9033 19.8854 20.186 20.1841C20.474 20.4827 20.618 20.8747 20.618 21.3601C20.618 22.5067 19.8953 23.0801 18.45 23.0801C17.9913 23.0801 17.5753 23.0081 17.202 22.8641C17.074 22.8161 16.9726 22.7334 16.898 22.6161C16.8286 22.4934 16.794 22.3601 16.794 22.2161V21.9601C16.794 21.8641 16.8366 21.7921 16.922 21.7441C17.0073 21.6907 17.0926 21.6854 17.178 21.7281C17.5673 21.9201 17.9646 22.0161 18.37 22.0161C18.6526 22.0161 18.866 21.9627 19.01 21.8561C19.1593 21.7441 19.234 21.5867 19.234 21.3841C19.234 21.2081 19.1833 21.0641 19.082 20.9521C18.9806 20.8401 18.8046 20.7441 18.554 20.6641Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default XlsIcon;
