import React from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';

import { enumToArray } from '@utils/enums';
import { ActionStatus } from '@repo/shared/enums';

import Select from '@components/shared/ant/Select/Select';
import ActionStatusName from '@src/presentation/Actions/ActionStatusName';

const { Option } = AntSelect;
const statuses = enumToArray(ActionStatus);

interface IProps extends SelectProps<any> {
  width?: string;
}

const PendingActionStatusSelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef((props, _) => {
    const { formatMessage } = useIntl();

    return (
      <Select {...props}>
        <Option value={-1}>{formatMessage({ id: 'All' })}</Option>
        {statuses
          .filter(
            ([, status]) =>
              status !== ActionStatus.Approved &&
              status !== ActionStatus.Expired
          )
          .map(([, status]) => (
            <Option key={status} value={status}>
              <ActionStatusName status={status as ActionStatus} />
            </Option>
          ))}
      </Select>
    );
  });

export default PendingActionStatusSelect;
