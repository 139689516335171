import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { notification } from '@utils';

import Modal from '@components/shared/ant/Modal/Modal';

const StartNewPeriodModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [processing, setProcessing] = useState(false);

  const opened = useSelector(
    schedulePlansSelectors.isStartNewPeriodModalOpened
  );

  const closeModal = useCallback(() => {
    dispatch(schedulePlansActions.toggleStartNewPeriodModal(false));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'RegenerateThePeriod' })}
      open={opened}
      onOk={async () => {
        setProcessing(true);

        const resultAction = await dispatch(
          schedulePlansActions.regenerateSchedulePlanPeriodAudits()
        );

        if (
          schedulePlansActions.regenerateSchedulePlanPeriodAudits.fulfilled.match(
            resultAction
          )
        ) {
          dispatch(schedulePlansActions.getSchedulePlanPeriods());

          closeModal();

          notification.success({
            message: formatMessage({
              id: 'NewPlanningPeriodHasBeenSuccessfullyStarted',
            }),
          });
        } else {
          notification.error({
            message: formatMessage({
              id: 'ErrorWhileStartingNewPlanningPeriod',
            }),
          });
        }

        setProcessing(false);
      }}
      okText={formatMessage({ id: 'Start' })}
      okButtonBackground={colors.gray10}
      confirmLoading={processing}
      onCancel={() => {
        closeModal();
      }}
      width={450}
      closeIcon={<X />}
    >
      {formatMessage({
        id: 'StartNewPeriodModalDesc',
      })}
    </Modal>
  );
};

export default StartNewPeriodModal;
