import React from 'react';
import { useIntl } from 'react-intl';

import { IUserGroup } from '@repo/shared/types';
import { usersActions } from '@store';
import { useAppDispatch } from '@hooks';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';
import { routes } from '@config';
import { useNavigate } from 'react-router-dom';

interface IProps {
  group: IUserGroup;
}

const UserGroupsActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  group,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'View' }),
          onClick: () => {
            navigate(routes.users.groupDetails.replace(':id', group.id));
          },
        },
        {
          name: formatMessage({ id: 'Edit' }),
          onClick: () =>
            dispatch(
              usersActions.groups.toggleAddEditGroupModal({
                visible: true,
                groupId: group.id,
              })
            ),
        },
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () =>
            dispatch(
              usersActions.groups.toggleConfirmDeleteGroupsModal([group.id])
            ),
        },
      ]}
    />
  );
};

export default UserGroupsActionsMenu;
