import React, { useEffect } from 'react';
import { useMatch } from 'react-router';
import { useSelector } from 'react-redux';

import { auditsActions } from '@application/Audits/store/auditsActions';
import { convertQueryParamToAssignment } from '@utils';
import { getQueryStringParams } from '@repo/shared/utils';
import { useAppDispatch } from '@hooks';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { routes } from '@config';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';

import Audits from './Audits';
import AssignUsersToAuditsModal from '@presentation/Audits/AssignUsersToAuditsModal';

const PendingAudits: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  const page = useSelector(auditsSelectors.getPage);

  const myAuditsMatch = useMatch(routes.myAudits);
  const pendingAuditsMatch = useMatch(routes.pendingAudits);
  const pendingAuditMatch = useMatch(routes.pendingAudit);

  useEffect(() => {
    if (myAuditsMatch) {
      dispatch(auditsActions.setAuditsPage(AuditsPage.My));
    } else if (pendingAuditsMatch || (pendingAuditMatch && page === null)) {
      dispatch(auditsActions.setAuditsPage(AuditsPage.Pending));
    }
  }, [myAuditsMatch, pendingAuditsMatch, pendingAuditMatch]);

  useEffect(() => {
    if (page === null) {
      return;
    }

    const searchParams = getQueryStringParams([
      'auditObjectIds',
      'auditObjectGroupIds',
      'templateId',
      'assignment',
    ]);

    if (
      Object.values(searchParams).some((paramValue) => paramValue !== undefined)
    ) {
      const params = {
        ...searchParams,
      };

      if (searchParams.assignment !== undefined) {
        params.assignment = convertQueryParamToAssignment(
          searchParams.assignment
        );
      }

      dispatch(auditsActions.getAudits(params));
    } else {
      dispatch(auditsActions.getAudits());
    }
  }, [dispatch, page]);

  if (page === null) {
    return null;
  }

  return (
    <>
      <Audits page={page} />
      <AssignUsersToAuditsModal />
    </>
  );
};

export default PendingAudits;
