import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { accountSelectors } from '@store';
import { routes } from '@config';

import NavyBlueContainer from '@components/shared/NavyBlueContainer/NavyBlueContainer';

interface IProps {
  children: ReactNode;
  Layout?: React.ElementType | null;
}

const NonProtectedRoute: React.FC<IProps> = ({
  children,
  Layout = NavyBlueContainer,
}) => {
  const authUserId = useSelector(accountSelectors.getAuthUserId);
  const location = useLocation();

  if (authUserId) {
    return (
      <Navigate to={location.state?.referrer || routes.dashboard} replace />
    );
  }

  if (Layout === null) {
    return <>{children}</>;
  }

  return <Layout>{children}</Layout>;
};

export default NonProtectedRoute;
