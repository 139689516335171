import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { colors } from '@repo/shared/config';
import { X } from 'react-feather';
import { useNavigate } from 'react-router';

import { useAppDispatch } from '@hooks';
import {
  issueTypesActions,
  issueTypesSelectors,
} from '@application/IssueTypes/store';
import { notification } from '@utils';
import { generalActions, generalSelectors } from '@store';
import { e2eTestElements, routes } from '@config';

import Modal from '@components/shared/ant/Modal/Modal';

const DeleteIssueTypesModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);

  const modalState = useSelector(
    issueTypesSelectors.getConfirmDeleteIssueTypesModalState
  );
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  let deleteCandidateIds: string[] = [];
  if (modalState !== null) {
    deleteCandidateIds = modalState.multiple
      ? modalState.data
      : [modalState.data.id];
  }
  const itemCount = deleteCandidateIds.length;

  const closeModal = useCallback(() => {
    dispatch(issueTypesActions.toggleConfirmDeleteIssueTypesModal(null));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'DeleteIssueTypes' }, { itemCount })}
      open={modalState !== null}
      onOk={async () => {
        setProcessing(true);

        const resultAction = await dispatch(
          issueTypesActions.deleteIssueTypes(deleteCandidateIds)
        );

        if (issueTypesActions.deleteIssueTypes.fulfilled.match(resultAction)) {
          dispatch(issueTypesActions.getIssueTypes());

          closeModal();

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          navigate(routes.issueTypes);
          dispatch(issueTypesActions.resetIssueType());
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingIssueTypes' },
              { itemCount }
            ),
          });
        }

        setProcessing(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={processing}
      onCancel={() => {
        closeModal();
      }}
      e2eDataConfirmAttr={e2eTestElements.issues.deleteModal.confirmBtn}
      e2eDataCancelAttr={e2eTestElements.issues.deleteModal.cancelBtn}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveIssueTypesFromSystem' },
        { itemCount: deleteCandidateIds.length }
      ).replace(
        '%replaceValue%',
        modalState && !modalState.multiple
          ? modalState.data.name
          : deleteCandidateIds.length.toString()
      )}
    </Modal>
  );
};

export default DeleteIssueTypesModal;
