import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { IScore, IScoreLabel } from '@repo/shared/types';
import { getColumnSortProperties } from '@utils';
import { Label } from './styled';
import { generalActions, scoresActions, scoresSelectors } from '@store';
import { Modal, Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';

import Table from '../../shared/ant/Table/Table';
import EmptyTable from '../../shared/ant/EmptyTable/EmptyTable';
import ScoresActionsMenu from './ScoresActionsMenu';

const ScoresTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, meta, score, error } = useSelector(
    scoresSelectors.getScores,
    shallowEqual
  );
  const filters = useSelector(scoresSelectors.getFilters, shallowEqual);
  const canManageScoreSystems = usePermission(Permission.CanManageScoreSystems);

  useEffect(() => {
    dispatch(scoresActions.getScores());
  }, [dispatch, filters]);

  return (
    <Table
      onPageChange={(update) => dispatch(scoresActions.setFilters(update))}
      canSelectRow={canManageScoreSystems}
      loading={loading}
      meta={meta}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'ScoreSystem' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
        },
        {
          title: formatMessage({ id: 'Levels' }),
          dataIndex: 'labels',
          key: 'labels',
          render(labels: IScoreLabel[]) {
            return (
              <>
                {labels.map(({ id, label, color, min, max }) => (
                  <Label key={id} labelColor={color}>
                    {label} ({min}% - {max}%)
                  </Label>
                ))}
              </>
            );
          },
        },
        {
          title: formatMessage({ id: 'FailIfBelow' }),
          dataIndex: 'passedThreshold',
          key: 'passedThreshold',
          render(passedThreshold: number) {
            return `${passedThreshold}%`;
          },
        },
        {
          title: formatMessage({ id: 'Description' }),
          dataIndex: 'description',
          key: 'description',
        },
        ...(canManageScoreSystems
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (score: IScore) => <ScoresActionsMenu score={score} />,
              },
            ]
          : []),
      ]}
      dataSource={score.map((s) => ({ ...s, key: s.id }))}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              filters.search !== ''
                ? formatMessage(
                    { id: 'NoScoreSearchResults' },
                    { searchStr: filters.search, linebreak: <br /> }
                  )
                : formatMessage({ id: 'NoScore' }, { linebreak: <br /> })
            }
            button={{
              text: `+ ${formatMessage({ id: 'CreateNewScore' })}`,
              type: 'link',
              onClick: () =>
                dispatch(generalActions.showModal({ name: Modal.AddEdit })),
            }}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(scoresActions.setFilters({ orderBy, orderByDirection }))
      }
    />
  );
};

export default ScoresTable;
