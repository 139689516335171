import { BaseApiClient } from '@repo/shared/api';
import { ITableFilters, IPagedResponse } from '@repo/shared/types';
import { IIssueTypeFieldsApiClient } from '@infrastructure/IssueTypes/models/IIssueTypeFieldsApiClient';
import { IssueTypeField } from '@domain/IssueTypes/models/IssueTypeField';

class IssueTypeFieldsApiClient
  extends BaseApiClient
  implements IIssueTypeFieldsApiClient
{
  public async getIssueTypeFields(issueTypeId: string, filters: ITableFilters) {
    return this._apiService.get<IPagedResponse<IssueTypeField>>({
      url: `issue-types/${issueTypeId}/fields`,
      query: filters,
    });
  }

  public async createIssueTypeField(
    issueTypeId: string,
    issueTypeField: Omit<IssueTypeField, 'id'>
  ) {
    const { id } = await this._apiService.post<{ id: string }>({
      url: `issue-types/${issueTypeId}/fields`,
      body: issueTypeField,
    });

    return id;
  }

  public async editIssueTypeField(
    issueTypeId: string,
    issueTypeField: IssueTypeField
  ) {
    await this._apiService.put({
      url: `issue-types/${issueTypeId}/fields/${issueTypeField.id}`,
      body: issueTypeField,
    });
  }

  public async deleteIssueTypeFields(issueTypeId: string, ids: string[]) {
    await this._apiService.delete({
      url: `issue-types/${issueTypeId}/fields`,
      body: {
        ids,
      },
    });
  }
}

export default IssueTypeFieldsApiClient;
