import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const EventsListWrapper = styled.div`
  flex-grow: 1;
`;

export const EventsList = styled.div`
  padding: 20px;
`;

export const EventContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  width: 400px;
  margin: 5px 0 0 5px;
`;
