import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { Modal } from '@repo/shared/enums';
import { IUser } from '@repo/shared/types';
import { accountSelectors, generalActions, usersActions } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  user: IUser;
}

const UsersActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  user,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const loggedInUser = useSelector(accountSelectors.getCurrentUser);
  const isAdmin = useSelector(accountSelectors.isAdmin);
  const isCurrentUser = user.id === loggedInUser.id;

  const menuItems = [
    {
      name: formatMessage({ id: 'View' }),
      onClick: () => {
        dispatch(usersActions.list.updateUserDetails(null));
        navigate(routes.users.userDetails.replace(':id', user.id));
      },
    },
    {
      name: formatMessage({ id: 'Edit' }),
      onClick: () => {
        dispatch(generalActions.showModal({ name: Modal.AddEdit, data: user }));
      },
    },
  ];

  if (isAdmin && !isCurrentUser) {
    menuItems.push({
      name: formatMessage({ id: 'SetPassword' }),
      onClick: () => {
        dispatch(usersActions.list.toggleSetUserPasswordModal(user.id));
      },
    });
  }

  if (loggedInUser && !isCurrentUser) {
    menuItems.push({
      name: formatMessage({ id: 'Delete' }),
      onClick: () => {
        dispatch(
          generalActions.showModal({ name: Modal.ConfirmDelete, data: user })
        );
      },
    });
  }

  return <TableRowActionsMenu menuItems={menuItems} />;
};

export default UsersActionsMenu;
