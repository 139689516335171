import React, { useState } from 'react';
import Space from 'antd/es/space';
import { useIntl } from 'react-intl';

import { Container, Title, Description } from './styled';

import Button from '@ui/components/shared/ant/Button';

interface IProps {
  title: string;
  description: string;
  showDelete: boolean;
  content: React.ReactNode;
  onDelete?: () => Promise<void>;
  onSave?: () => Promise<void>;
  onSaveBtnLabel?: string;
}

const Integration: React.FC<IProps> = ({
  title,
  description,
  showDelete,
  content,
  onDelete,
  onSave,
  onSaveBtnLabel,
}) => {
  const { formatMessage } = useIntl();

  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {content}
      <Space>
        {showDelete && !!onDelete && (
          <Button
            type="default"
            onClick={async () => {
              setDeleting(true);
              await onDelete();
              setDeleting(false);
            }}
            loading={deleting}
            disabled={saving}
          >
            {formatMessage({ id: 'Delete' })}
          </Button>
        )}
        {!!onSave && !!onSaveBtnLabel && (
          <Button
            type="primary"
            onClick={async () => {
              setSaving(true);
              await onSave();
              setSaving(false);
            }}
            loading={saving}
            disabled={deleting}
          >
            {onSaveBtnLabel}
          </Button>
        )}
      </Space>
    </Container>
  );
};

export default Integration;
