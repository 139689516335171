import React from 'react';
import { useIntl } from 'react-intl';

import { XlsIcon } from '@icons';
import { useAppDispatch } from '@hooks';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { notification } from '@utils';

import BigBtnDropdown from '@components/shared/BigBtnDropdown/BigBtnDropdown';

const ExportButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  return (
    <BigBtnDropdown
      width={250}
      triggerText={formatMessage({ id: 'Export' })}
      buttons={[
        {
          content: (
            <>
              <XlsIcon size={28} />
              {formatMessage({ id: 'ExportAsXLS' })}
            </>
          ),
          onClick: async () => {
            const resultAction = await dispatch(auditsActions.exportAudits());

            if (auditsActions.exportAudits.rejected.match(resultAction)) {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileExportingAudits' }),
              });
            }
          },
        },
      ]}
    />
  );
};

export default ExportButton;
