import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  margin-bottom: 20px;
`;

export const Header = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  margin-bottom: 7px;
`;

export const AddBtn = styled.button`
  padding: 0;
  border: 0;
  background: none;
  color: ${colors.blue2};
  font-size: 13px;
  cursor: pointer;
`;

export const ListWrapper = styled.div`
  margin-bottom: 5px;
`;
