import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  accountSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { getTablePageNumberAfterDeletion, notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import { colors } from '@repo/shared/config';

import Modal from '../../../shared/ant/Modal/Modal';

const DeleteAuditObjectGroupsModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const groups = useSelector(auditObjectsSelectors.groups.getGroupsMap);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const { meta } = useSelector(auditObjectsSelectors.groups.getGroups);
  const deleteCandidateIds = useSelector(
    auditObjectsSelectors.groups.getDeleteCandidatesIds
  );
  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const { visible: isAddEditGroupModalVisible } = useSelector(
    auditObjectsSelectors.groups.getAddEditGroupModalState
  );

  const closeModal = useCallback(
    () => dispatch(auditObjectsActions.groups.toggleConfirmDeleteModal([])),
    []
  );

  return (
    <Modal
      title={formatMessage(
        { id: 'DeleteAuditObjectsGroups' },
        {
          itemCount: deleteCandidateIds.length,
          objectName: auditObjectName.plural.toLowerCase(),
        }
      )}
      open={deleteCandidateIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          auditObjectsActions.groups.deleteGroups(deleteCandidateIds)
        );

        if (
          auditObjectsActions.groups.deleteGroups.fulfilled.match(resultAction)
        ) {
          if (isAddEditGroupModalVisible) {
            dispatch(
              auditObjectsActions.groups.toggleAddEditModal({ visible: false })
            );
          }

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          dispatch(
            auditObjectsActions.groups.getData({
              pageNumber: getTablePageNumberAfterDeletion(meta),
            })
          );

          navigate(routes.auditObjects.groupsList);
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingAuditObjectsGroups' },
              {
                itemCount: deleteCandidateIds.length,
                objectName: auditObjectName.single.toLowerCase(),
              }
            ),
            description: resultAction.payload,
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => closeModal()}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveAuditObjectsGroupsFromSystem' },
        {
          itemCount: deleteCandidateIds.length,
          objectName: auditObjectName.single.toLowerCase(),
        }
      ).replace(
        '%replaceValue%',
        deleteCandidateIds.length === 1
          ? groups[deleteCandidateIds[0]]?.name || ''
          : deleteCandidateIds.length
      )}
    </Modal>
  );
};

export default DeleteAuditObjectGroupsModal;
