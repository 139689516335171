import {
  AuditsGenerationMode,
  Schedule,
  ScheduleRepeat,
} from '@domain/Schedules/models/Schedule';
import {
  ScheduleFormValues,
  ScheduleRepeatFormValues,
} from '@application/Schedules/models/ScheduleFormValues';
import { date } from '@utils';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';

function transformRepeatToFormValuesRepeat(
  repeat: ScheduleRepeat
): ScheduleRepeatFormValues {
  const expiration = {
    auditsExpirationType: repeat.auditsExpirationType,
    auditsExpireAfterDays: !repeat.auditsExpireAfterDays
      ? ''
      : repeat.auditsExpireAfterDays!.toString(),
    actionsExpirationType: repeat.actionsExpirationType,
    actionsExpireAfterDays: !repeat.actionsExpireAfterDays
      ? ''
      : repeat.auditsExpireAfterDays!.toString(),
  };

  switch (repeat.repeatPattern) {
    case AuditRepeatPattern.OneTime:
      return {
        repeatPattern: AuditRepeatPattern.OneTime,
        range: [date(repeat.startDate), date(repeat.endDate)],
        ...expiration,
      };
    case AuditRepeatPattern.Daily:
      return {
        repeatPattern: AuditRepeatPattern.Daily,
        repeatEvery: repeat.repeatEvery.toString(),
        ...expiration,
      };
    case AuditRepeatPattern.Weekly:
      return {
        repeatPattern: AuditRepeatPattern.Weekly,
        daysOfWeek: repeat.daysOfWeek,
        ...expiration,
      };
    case AuditRepeatPattern.Monthly:
      return {
        repeatPattern: AuditRepeatPattern.Monthly,
        startDay: repeat.startDay.toString(),
        duration: repeat.duration.toString(),
        repeatEvery: repeat.repeatEvery.toString(),
        ...expiration,
      };
    case AuditRepeatPattern.MultipleWeeks:
      return {
        repeatPattern: AuditRepeatPattern.MultipleWeeks,
        repeatEvery: repeat.repeatEvery.toString(),
        startDay: repeat.startDay,
        duration: repeat.duration.toString(),
        ...expiration,
      };
    default:
      return {
        repeatPattern: AuditRepeatPattern.OneTime,
        range: [date(), date()],
        ...expiration,
      };
  }
}

export function transformScheduleToFormValues({
  auditObjects,
  auditObjectGroups,
  repeat,
  template,
  name,
  auditRepeatPattern,
  auditorHint,
  assignedUsers,
  assignedUserGroups,
  auditsGenerationMode,
  maxAuditsQty,
  auditorAssignmentType,
}: Schedule): ScheduleFormValues {
  return {
    name,
    auditRepeatPattern,
    auditsGenerationMode:
      auditsGenerationMode || AuditsGenerationMode.AuditPerLocation,
    maxAuditsQty: maxAuditsQty || '',
    auditorHint: auditorHint || '',
    templateId: template.id,
    auditorAssignmentType,
    assignedUsersIds: assignedUsers.map(({ id }) => id),
    assignedUserGroupsIds: assignedUserGroups.map(({ id }) => id),
    auditObjectAndGroupIds: {
      entityIds: auditObjects.map(({ id }) => id),
      groupIds: auditObjectGroups.map(({ id }) => id),
    },
    repeat: transformRepeatToFormValuesRepeat(repeat),
  };
}
