import React from 'react';
import { useIntl } from 'react-intl';

import { Container } from './styled';

interface IProps {}

const VideoPlaybackDisabledNotice: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>{formatMessage({ id: 'VideoPlaybackIsUnsupported' })}</Container>
  );
};

export default VideoPlaybackDisabledNotice;
