import React from 'react';
import { AlertCircle } from 'react-feather';
import { useSelector } from 'react-redux';

import { issuesSelectors } from '@application/Issues/store';
import {
  Container,
  PopoverContent,
  Header,
  SubHeader,
  Date,
  Comment,
  User,
  UserName,
} from './styled';
import { config } from '@repo/shared/config';

import IssueCloseReasonName from '@src/presentation/Issues/IssueDetails/IssueCloseReasonName';
import Popover from '@components/shared/ant/Popover';
import ZonedDateTime from '@components/shared/ZonedDateTime';
import Avatar from '@components/shared/ant/Avatar';

interface IProps {}

const CloseReasonPopover: React.FC<React.PropsWithChildren<IProps>> = () => {
  const issueDetailsLoading = useSelector(
    issuesSelectors.getIssueDetailsLoading
  );
  const { event: lastClosedEvent, loading: eventsLoading } = useSelector(
    issuesSelectors.getLastClosedEvent
  );

  if (issueDetailsLoading || eventsLoading || !lastClosedEvent) {
    return null;
  }

  return (
    <Popover
      content={
        <PopoverContent>
          <Header>
            <IssueCloseReasonName reason={lastClosedEvent.data.reason} />
          </Header>
          <SubHeader>
            <div>
              {!!lastClosedEvent.createdBy && (
                <User>
                  <Avatar user={lastClosedEvent.createdBy} size={26} />
                  <UserName>{lastClosedEvent.createdBy.name}</UserName>
                </User>
              )}
            </div>
            <Date>
              <ZonedDateTime
                dateTime={lastClosedEvent.createDate}
                format={config.dateTimeFormat}
              />
            </Date>
          </SubHeader>
          {!!lastClosedEvent.data.comment && (
            <Comment>{lastClosedEvent.data.comment}</Comment>
          )}
        </PopoverContent>
      }
    >
      <Container>
        <AlertCircle />
        <span>
          <IssueCloseReasonName reason={lastClosedEvent.data.reason} />
        </span>
      </Container>
    </Popover>
  );
};

export default CloseReasonPopover;
