import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { GetCompletedAuditDetailsDto } from '@infrastructure/Audits/models/GetCompletedAuditDto';

export function transformCompletedAuditDetailsDtoToAudit(
  dto: GetCompletedAuditDetailsDto
): AuditDetails {
  return {
    ...dto,
    canStartAudit: false,
    dueInDays: 0,
    expiredBy: null,
    expiredAtInformation: null,
  };
}
