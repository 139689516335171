import { BaseApiClient } from '@repo/shared/api';
import { SchedulesFilters } from '@application/Schedules/models/SchedulesFilters';
import { IConcise, IPagedResponse } from '@repo/shared/types';
import { Schedule } from '@domain/Schedules/models/Schedule';
import { ScheduleDto } from '@infrastructure/Schedules/models/ScheduleDto';
import { ChangeStatusDto } from '@application/SchedulePlans/models/ChangeStatusDto';

class SchedulesApiClient extends BaseApiClient {
  public async getSchedules(
    filters: SchedulesFilters,
    schedulePlanId?: string
  ) {
    const schedules = await this._apiService.post<IPagedResponse<Schedule>>({
      url: schedulePlanId
        ? `audit/schedule-plans/${schedulePlanId}/schedules/list`
        : 'audit/schedules/list',
      body: {
        ...filters,
      },
    });

    return {
      ...schedules,
      data: schedules.data.map((s) => ({
        ...s,
        assignedUsers: s.assignedUsers || [],
      })),
    };
  }

  public async getConciseSchedules() {
    return this._apiService.get<IConcise[]>({
      url: 'audit/schedules/concise',
    });
  }

  public async getScheduleDetails(scheduleId: string) {
    return this._apiService.get<Schedule>({
      url: `audit/schedules/${scheduleId}`,
    });
  }

  public async createSchedule(
    schedule: ScheduleDto,
    schedulePlanId?: string
  ): Promise<string> {
    const { id } = await this._apiService.post<{ id: string }>({
      url: schedulePlanId
        ? `audit/schedule-plans/${schedulePlanId}/schedules`
        : 'audit/schedules',
      body: {
        ...schedule,
      },
    });

    return id;
  }

  public async updateSchedule(
    id: string,
    schedule: ScheduleDto,
    schedulePlanId?: string
  ): Promise<void> {
    return await this._apiService.put({
      url: schedulePlanId
        ? `audit/schedule-plans/${schedulePlanId}/schedules/${id}`
        : `audit/schedules/${id}`,
      body: {
        ...schedule,
      },
    });
  }

  public async deleteSchedules(
    ids: string[],
    schedulePlanId?: string
  ): Promise<void> {
    await this._apiService.delete({
      url: schedulePlanId
        ? `audit/schedule-plans/${schedulePlanId}/schedules`
        : 'audit/schedules',
      body: {
        ids,
      },
    });
  }

  public async changeStatus({ id, ...body }: ChangeStatusDto): Promise<void> {
    await this._apiService.post({
      url: `audit/schedules/${id}/start`,
      body,
    });
  }
}

export default SchedulesApiClient;
