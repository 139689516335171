import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const AuditName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonLink = styled.button`
  text-align: left;
  background: none;
  padding: 0;
  border: 0;
  line-height: 1.4;
  color: ${colors.blue2};

  &:disabled {
    color: ${colors.gray10};
  }
`;

export const Mark = styled.div`
  color: ${colors.gray8};
  font-size: 10px;
  line-height: 12px;
  margin: 3px 0 0 0;
`;

export const CountButton = styled.button`
  background: none;
  border: 0;
  color: ${colors.blue2};
`;
