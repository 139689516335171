export const apiUrls = {
  profile: 'profile',
  accounts: 'accounts',
  users: 'users',
  jobTitles: 'job-titles',
  userGroups: 'usergroups',
  tags: 'tags',
  roles: 'roles',
  auditObjects: 'audit/objects',
  auditObjectsGroups: 'auditobjectgroups',
  scheduledAudits: 'audit/schedules',
  auditTemplates: 'audit/templates',
  libraryTemplates: 'library/templates',
  correctiveActions: 'audit/actions',
  actionTemplates: 'library/actions',
  pendingActions: 'audit/actions/unresolved',
  completedActions: 'audit/actions/resolved',
  expiredActions: 'audit/actions/expired',
  audit: 'audit/all',
  myAudits: 'audit/all/my',
  pendingAudits: 'audit/all/upcoming',
  completedAudits: 'audit/all/complete',
  expiredAudits: 'audit/all/expired',
  dashboard: 'dashboard',
  onboarding: 'onboarding',
  company: 'company',
  library: 'library',
  scores: 'scores',
  reports: {
    summary: 'reports',
    custom: 'reports/custom',
    auditPerformance: 'audit-performance-report',
    itemAnalysis: 'questions-analysis-report',
    auditorPerformance: 'auditor-performance-report',
    auditObjectPerformance: 'audit-object-performance-report',
    detailed: 'reports/detailed',
    audit: 'audit-report',
  },
  notifications: 'notification-settings',
  integrations: 'integrations',
  import: 'import',
  adminSignIn: 'adminSignIn',
  billing: {
    price: 'billing/price',
    subscription: 'billing/subscription',
    invoices: 'billing/invoices',
    validateCouponCode: 'billing/coupons/validate',
  },
  metrics: 'metrics',
  preferences: 'preferences',
  files: 'files',
};
