import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CheckCircle, XCircle } from 'react-feather';
import Spin from 'antd/es/spin';

import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import { colors } from '@repo/shared/config';
import { accountActions } from '@store';

import OneColAuthWrapper from '../OneColAuthWrapper';
import SetNewPasswordForm from '@components/account/AuthForms/ResetPassword/SetNewPasswordForm';
import SetNewPasswordResult from '@components/account/AuthForms/ResetPassword/SetNewPasswordResult';

export enum SetNewPasswordPage {
  loading,
  notValid,
  form,
  success,
}

const SetNewPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { userAuthId } = useParams();

  const [page, setPage] = useState<SetNewPasswordPage>(
    SetNewPasswordPage.loading
  );

  useEffect(() => {
    async function checkLinkValidity() {
      if (!userAuthId || !searchParams.get('token')) {
        setPage(SetNewPasswordPage.notValid);
        return;
      }

      const resultAction = await dispatch(
        accountActions.validateResetPasswordLink({
          userAuthId,
          token: searchParams.get('token')!,
        })
      );

      if (
        accountActions.validateResetPasswordLink.fulfilled.match(resultAction)
      ) {
        setPage(SetNewPasswordPage.form);
      } else {
        setPage(SetNewPasswordPage.notValid);
      }
    }

    checkLinkValidity();
  }, []);

  if (page === SetNewPasswordPage.loading) {
    return (
      <OneColAuthWrapper>
        <Spin spinning>
          <div style={{ height: '220px' }} />
        </Spin>
      </OneColAuthWrapper>
    );
  }

  if (page === SetNewPasswordPage.notValid) {
    return (
      <OneColAuthWrapper
        footerAction={() => navigate(routes.signin)}
        footerText={formatMessage({
          id: 'GoToSignInPage',
        })}
      >
        <SetNewPasswordResult
          title={formatMessage({ id: 'TryResettingYourPasswordAgain' })}
          text={formatMessage({
            id: 'YourRequestToResetYourPasswordHasExpiredOrTheLinkHasAlreadyBeenUsed',
          })}
          icon={<XCircle color={colors.red} />}
        />
      </OneColAuthWrapper>
    );
  }

  if (page === SetNewPasswordPage.form) {
    return (
      <OneColAuthWrapper
        title={formatMessage({
          id: 'ResetYourPassword',
        })}
        footerAction={() => navigate(routes.signin)}
        footerText={formatMessage({
          id: 'GoToSignInPage',
        })}
      >
        <SetNewPasswordForm
          onSuccess={() => {
            setPage(SetNewPasswordPage.success);
          }}
        />
      </OneColAuthWrapper>
    );
  }

  return (
    <OneColAuthWrapper>
      <SetNewPasswordResult
        title={formatMessage({ id: 'PasswordChanged' })}
        text={formatMessage({
          id: 'YouCanNowSignInWithYourNewPassword',
        })}
        signInBtnText={formatMessage({ id: 'SignIn' })}
        icon={<CheckCircle color={colors.green} />}
      />
    </OneColAuthWrapper>
  );
};

export default SetNewPassword;
