import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -12px;

  ${({ layout }: { layout: any }) => {
    if (layout === 'vertical') {
      return `
        flex-direction: column;
        align-items: flex-start;
      `;
    }

    return `
      flex-direction: row;
      align-items: center;
    `;
  }}
`;

interface ItemsProps {
  layout: any;
  dotColor: string;
}

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  font-size: 11px;
  line-height: 17px;
  color: ${colors.gray8};

  ${({ layout }: ItemsProps) => {
    if (layout === 'vertical') {
      return `
        margin-bottom: 3px;
        
        &:last-child {
          margin-bottom: 0;
        }       
      `;
    }
  }}

  &:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-right: 8px;
    background: ${({ dotColor }: ItemsProps) => dotColor};
  }
`;

export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ShowMoreBtn = styled.button`
  background: none;
  border: 0;
  padding: 0;
  font-size: 11px;
  color: ${colors.blue2};
  line-height: 17px;
  margin-bottom: 3px;
`;

export const Content = styled.div`
  max-width: 250px;
`;
