import { ActionStatus } from '@repo/shared/enums';
import { routes } from '@config';

export function getRoutesFromActionStatus(status: ActionStatus) {
  switch (status) {
    case ActionStatus.Opened:
    case ActionStatus.Rejected:
    case ActionStatus.Submitted:
      return {
        list: routes.pendingActions,
        single: routes.pendingAction,
      };
    case ActionStatus.Approved:
      return {
        list: routes.completedActions,
        single: routes.completedAction,
      };
    case ActionStatus.Expired:
      return {
        list: routes.expiredActions,
        single: routes.expiredAction,
      };
  }
}
