import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  width: 270px;
  margin-left: 50px;
`;

export const Content = styled.div`
  background: ${colors.gray3};
  border-radius: 5px;
  padding: 16px;
`;

export const Title = styled.h3`
  margin-bottom: 16px;
`;

export const TooltipLine = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 2px;
  }
`;

export const TooltipContent = styled.div`
  font-size: 13px;
`;

export const EditNote = styled.div`
  margin-top: 24px;
  text-align: center;
  font-size: 12px;
  color: ${colors.gray8};
`;
