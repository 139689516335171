import React from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';

import { IssueCloseReason } from '@domain/Issues/models/IssueEvent';

import IssueCloseReasonName from '@src/presentation/Issues/IssueDetails/IssueCloseReasonName';
import Select from '@components/shared/ant/Select/Select';

const { Option } = AntSelect;

interface IBaseProps extends SelectProps<IssueCloseReason> {
  allowAll?: false;
  e2eDataAttr?: string;
}

interface IAllowAllProps extends SelectProps<IssueCloseReason | -1> {
  allowAll: true;
  e2eDataAttr?: string;
}

type IProps = IBaseProps | IAllowAllProps;

const reasons = [
  IssueCloseReason.Fixed,
  IssueCloseReason.NotAnIssue,
  IssueCloseReason.ImpossibleToFix,
  IssueCloseReason.CantReproduce,
  IssueCloseReason.Duplicate,
  IssueCloseReason.Other,
];

const IssueCloseReasonSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  allowAll,
  ...props
}) => {
  const { formatMessage } = useIntl();

  console.log(props)

  return (
    <Select {...props}>
      {allowAll && <Option value={-1}>{formatMessage({ id: 'All' })}</Option>}
      {reasons.map((reason) => (
        <Option key={reason} value={reason}>
          <IssueCloseReasonName reason={reason} />
        </Option>
      ))}
    </Select>
  );
};

export default IssueCloseReasonSelect;
