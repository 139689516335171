import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';

import { useAppDispatch } from '@hooks';
import { Container } from './styled';
import { settingsActions, settingsSelectors, accountSelectors } from '@store';

import PageHeader from '../../shared/ant/PageHeader';
import ApiKey from './ApiKey/ApiKey';
import Webhook from './Webhook/Webhook';
import ImportSettings from './ImportSettings/ImportSettings';

const Integrations: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading } = useSelector(
    settingsSelectors.integrations.getIntegrations
  );
  const company = useSelector(accountSelectors.getCompany);

  useEffect(() => {
    dispatch(settingsActions.integrations.getIntegrations());
  }, [dispatch]);

  return (
    <PageHeader title={formatMessage({ id: 'Integration' })}>
      <Container>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <ApiKey />
            <Webhook />
            {!!company?.displayImportSettings && <ImportSettings />}
          </>
        )}
      </Container>
    </PageHeader>
  );
};

export default Integrations;
