import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountSelectors, auditObjectsActions } from '@store';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import AuditObjectContentWrapper, {
  AuditObjectPage,
} from '@components/auditObjects/single/AuditObjectContentWrapper/AuditObjectContentWrapper';
import Button from '@components/shared/ant/Button';
import GroupsAssignmentsTable from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/GroupsAssignmentsTable/GroupsAssignmentsTable';
import AddToGroupModal from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/AddToGroupModal/AddToGroupModal';
import ConfirmDeleteGroupAssignmentModal from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/ConfirmDeleteGroupAssignmentModal';
import GroupsAssignmentsActionsModal from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/GroupsAssignmentsActionsModal';

interface IProps {}

const AuditObjectGroups: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { id } = useParams();

  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    if (id) {
      dispatch(
        auditObjectsActions.groupAssignments.getData({ auditObjectIds: [id] })
      );
    }

    return () => {
      dispatch(
        auditObjectsActions.groupAssignments.toggleAddToGroupModal(false)
      );
    };
  }, [id]);

  return (
    <>
      <AuditObjectContentWrapper
        page={AuditObjectPage.Groups}
        extra={
          !isLocationsReadonly && canManageAuditsObjects ? (
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  auditObjectsActions.groupAssignments.toggleAddToGroupModal(
                    true
                  )
                );
              }}
              data-e2e={
                e2eTestElements.objects.groupsAssignments.openAddToGroupModal
              }
            >
              {formatMessage({
                id: 'AddToGroups',
              })}
            </Button>
          ) : undefined
        }
      >
        <GroupsAssignmentsTable auditObjectId={id} />
      </AuditObjectContentWrapper>
      <AddToGroupModal auditObjectId={id} />
      <ConfirmDeleteGroupAssignmentModal />
      <GroupsAssignmentsActionsModal />
    </>
  );
};

export default AuditObjectGroups;
