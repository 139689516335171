import React from 'react';
import { useIntl } from 'react-intl';

import { ActionStatus } from '@repo/shared/enums';

interface IProps {
  status?: ActionStatus;
}

const ActionStatusName: React.FC<React.PropsWithChildren<IProps>> = ({
  status,
}) => {
  const { formatMessage } = useIntl();

  let statusName;

  if (status === ActionStatus.Approved) {
    statusName = formatMessage({ id: 'Approved' });
  }

  if (status === ActionStatus.Rejected) {
    statusName = formatMessage({ id: 'Rejected' });
  }

  if (status === ActionStatus.Opened) {
    statusName = formatMessage({ id: 'Opened' });
  }

  if (status === ActionStatus.Submitted) {
    statusName = formatMessage({ id: 'Submitted' });
  }

  if (status === ActionStatus.Expired) {
    statusName = formatMessage({ id: 'Expired' });
  }

  return <>{statusName}</>;
};

export default ActionStatusName;
