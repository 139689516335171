import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormInstance } from 'antd/es/form';
import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { useAppDispatch } from '@hooks';
import {
  accountActions,
  accountSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { getSystemTimeZone, notification } from '@utils';
import { routes } from '@config';
import { AmbassadorParams, ITimeZone } from '@repo/shared/types';
import { AnalyticsEvent } from '@repo/shared/enums';
import { Logger } from '@repo/shared/services';
import { UnexpectedError } from '@repo/shared/errors';

import CompanyForm from '@components/account/CompanyForm';
import Form from '../../../shared/ant/Form';

interface IProps {
  children?: (p: { loading: boolean; form: FormInstance }) => React.ReactNode;
  templateId?: string | null;
}

const SetupCompanyForm: React.FC<IProps> = ({ children, templateId }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [ambassadorParams, setAmbassadorParams] = useState<AmbassadorParams>();

  useEffect(() => {
    function getAmbassadorParamsFromStorage() {
      const mbsy = localStorage.getItem('mbsy');
      const campaignId = localStorage.getItem('campaignId');
      const mbsyExpString = localStorage.getItem('mbsy_exp');

      if (mbsy && campaignId) {
        if (mbsyExpString) {
          const expirationDate = dayjs.utc(mbsyExpString);

          if (
            expirationDate.isValid() &&
            dayjs().utc().isBefore(expirationDate)
          ) {
            return { mbsy, campaignId };
          } else {
            localStorage.removeItem('mbsy');
            localStorage.removeItem('campaignId');
            localStorage.removeItem('mbsy_exp');
            return null;
          }
        } else {
          return { mbsy, campaignId };
        }
      }

      return null;
    }

    function saveAmbassadorParamsToStorage(
      mbsy: string,
      campaignId: string,
      mbsyExpString: string | undefined
    ) {
      localStorage.setItem('mbsy', mbsy);
      localStorage.setItem('campaignId', campaignId);

      if (mbsyExpString) {
        const expirationDate = dayjs.utc(
          mbsyExpString,
          'ddd, D MMM YYYY HH:mm:ss [GMT]',
          true
        );

        if (expirationDate.isValid()) {
          localStorage.setItem('mbsy_exp', expirationDate.toISOString());
        } else {
          Logger.captureException(
            new UnexpectedError(
              `Invalid mbsy_exp date format: ${mbsyExpString}`
            )
          );
        }
      }
    }

    async function getAndSetAmbassadorParams() {
      const mbsyParam = searchParams.get('mbsy');
      const campaignIdParam = searchParams.get('campaignid');
      const mbsyExpParam = searchParams.get('mbsy_exp');

      const mbsyExpString = mbsyExpParam
        ? decodeURIComponent(mbsyExpParam)
        : undefined;

      let mbsy = mbsyParam;
      let campaignId = campaignIdParam;

      if (mbsy && campaignId) {
        saveAmbassadorParamsToStorage(mbsy, campaignId, mbsyExpString);
      } else {
        const storedParams = getAmbassadorParamsFromStorage();

        if (storedParams) {
          mbsy = storedParams.mbsy;
          campaignId = storedParams.campaignId;
        }
      }

      if (mbsy && campaignId) {
        setAmbassadorParams({
          mbsy,
          campaignId,
        });
      }
    }

    getAndSetAmbassadorParams();
  }, [
    searchParams.get('mbsy'),
    searchParams.get('campaignid'),
    searchParams.get('mbsy_exp'),
  ]);

  useEffect(() => {
    dispatch(generalActions.getTimeZones());
  }, [dispatch]);

  const [loading, setLoading] = useState(false);
  const { loading: timezonesLoading, data: timezones } = useSelector(
    generalSelectors.getTimeZones
  );
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const defaultIanaTimeZone = useMemo(() => {
    const systemTimeZone = getSystemTimeZone();

    if (
      !timezonesLoading &&
      timezones.find((tz: ITimeZone) => tz.ianaName === systemTimeZone)
    ) {
      return systemTimeZone;
    }

    return null;
  }, [timezonesLoading]);

  return (
    <>
      <CompanyForm
        form={form}
        showAdminFields
        initialValues={{
          fullName: '',
          phone: '',
          name: '',
          companySize: null,
          industry: null,
          usagePurpose: null,
          usagePurposeObjectName: '',
          defaultIanaTimeZone,
        }}
        onSubmit={async ({
          fullName,
          phone,
          name,
          companySize,
          industry,
          usagePurpose,
          usagePurposeObjectName,
          defaultIanaTimeZone,
        }) => {
          setLoading(true);

          const resultAction = await dispatch(
            accountActions.createCompany({
              admin: {
                fullName,
                phone,
              },
              name,
              companySize,
              industry,
              usagePurpose,
              usagePurposeObjectName,
              defaultIanaTimeZone,
              templateId,
              mbsy: ambassadorParams?.mbsy,
              campaignId: ambassadorParams?.campaignId,
            })
          );

          if (accountActions.createCompany.fulfilled.match(resultAction)) {
            if (currentUser === null) {
              await dispatch(accountActions.getAppData());
              navigate(routes.dashboard, { state: { fromCompanySetup: true } });
            } else {
              const { companyId } = unwrapResult(resultAction);
              dispatch(accountActions.toggleSetupCompanyModal(false));
              await dispatch(
                accountActions.changeAccount({
                  companyId,
                  fromCompanySetup: true,
                })
              );
            }

            dispatch(
              accountActions.sendAnalyticsEvent(AnalyticsEvent.SetupCompany)
            );
          } else {
            notification.error({
              message: formatMessage({ id: 'ErrorWhileSettingUpCompany' }),
              description: formatMessage({
                id: 'PleaseTryAgainOrContactSupport',
              }),
            });

            setLoading(false);
          }
        }}
      />
      {!!children && children({ loading, form })}
    </>
  );
};

export default SetupCompanyForm;
