import React from 'react';
import { useIntl } from 'react-intl';
import ShowMoreText from 'react-show-more-text';

import { ActionDetails } from '@domain/Actions/models/Action';
import { FilesContainer } from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/styled';

import Previews from '@components/shared/Previews/Previews';
import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';

interface IProps {
  action: ActionDetails;
}

const ActionDetailsDescription: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  if (!action.description && action.files.length === 0) {
    return null;
  }

  return (
    <ActionDetailsInfoItem
      label={formatMessage({ id: 'Description' })}
      content={
        <>
          {!!action.description && (
            <ShowMoreText
              lines={5}
              more={formatMessage({ id: 'ShowMore' })}
              less={formatMessage({ id: 'ShowLess' })}
              anchorClass="show-more-link"
              keepNewLines
            >
              {action.description}
            </ShowMoreText>
          )}
          <FilesContainer>
            <Previews files={action.files} />
          </FilesContainer>
        </>
      }
    />
  );
};

export default ActionDetailsDescription;
