import React from 'react';
import { useIntl } from 'react-intl';

import { Container, Text, Icon, Center } from './styled';

interface IProps {
  text?: string | React.ReactNode;
}

export const NoContent: React.FC<React.PropsWithChildren<IProps>> = ({
  text,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Icon />
      <Text>
        {text || formatMessage({ id: 'NoContentAvailableToDisplay' })}
      </Text>
    </Container>
  );
};

export const NoContentCentered: React.FC<React.PropsWithChildren<IProps>> = ({
  text,
}) => {
  return (
    <Center>
      <NoContent text={text} />
    </Center>
  );
};
