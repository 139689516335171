import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { settingsSelectors, settingsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';
import { notification } from '@utils';

import Modal from '../../../shared/ant/Modal/Modal';

interface IProps {}

const ConfirmRegenerateApiKeyModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [regenerating, setRegenerating] = useState(false);

  const visible = useSelector(
    settingsSelectors.integrations.isConfirmRegenerateModalVisible
  );

  async function closeModal() {
    dispatch(settingsActions.integrations.toggleConfirmRegenerateModal(false));
  }

  return (
    <Modal
      title={formatMessage({ id: 'RegenerateApiKey' })}
      open={visible}
      width={390}
      confirmLoading={regenerating}
      onOk={async () => {
        setRegenerating(true);

        const resultAction = await dispatch(
          settingsActions.integrations.generateApiKey({ regenerate: true })
        );

        if (
          settingsActions.integrations.generateApiKey.fulfilled.match(
            resultAction
          )
        ) {
          notification.success({
            message: formatMessage({
              id: 'ApiKeyHasBeenSuccessfullyGenerated',
            }),
          });

          closeModal();
        } else {
          notification.error({
            message: formatMessage({
              id: 'AnErrorOccurredWhileGeneratingApiKey',
            }),
          });
        }

        setRegenerating(false);
      }}
      okText={formatMessage({ id: 'Regenerate' })}
      okButtonBackground={colors.gray10}
      onCancel={() => {
        closeModal();
      }}
    >
      {formatMessage({ id: 'AreYouSureYouWantToRegenerateApiKey' })}
    </Modal>
  );
};

export default ConfirmRegenerateApiKeyModal;
