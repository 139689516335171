import { createReducer } from '@reduxjs/toolkit';

import { AuditTemplatesState } from '@application/AuditTemplates/models/AuditTemplatesState';
import { auditTemplateGroupsAdapter } from '@application/AuditTemplates/store/entityAdapters';
import { auditTemplatesActions as actions } from '@application/AuditTemplates/store/auditTemplatesActions';
import { getInitialAuditTemplatesFilters } from '@application/AuditTemplates/utils/getInitialAuditTemplatesFilters';

const suggestionsModalInitialState = {
  opened: false,
  prompts: {
    loading: false,
    error: null,
    data: [],
  },
  suggestion: {
    loading: false,
    error: null,
    data: null,
  },
};

export const auditTemplatesReducer = createReducer<AuditTemplatesState>(
  {
    groups: {
      data: auditTemplateGroupsAdapter.getInitialState(),
      loading: false,
      meta: null,
      error: null,
      filters: {
        ...getInitialAuditTemplatesFilters(),
      },
    },
    defaultTemplateCandidate: null,
    copyTemplateCandidate: null,
    exportPdfTemplateCandidate: null,
    deleteTemplateModal: null,
    suggestionsModal: {
      ...suggestionsModalInitialState,
    },
  },
  (builder) =>
    builder
      .addCase(
        actions.getTemplateGroups.pending,
        (state, { meta: { arg } }) => {
          state.groups.loading = true;
          state.groups.error = null;
          state.groups.filters =
            arg === null
              ? {
                  ...getInitialAuditTemplatesFilters(),
                }
              : {
                  ...state.groups.filters,
                  ...arg,
                };
        }
      )
      .addCase(actions.getTemplateGroups.fulfilled, (state, { payload }) => {
        state.groups.loading = false;
        state.groups.meta = payload.meta;
        auditTemplateGroupsAdapter.setAll(state.groups.data, payload.data);
      })
      .addCase(actions.getTemplateGroups.rejected, (state, { payload }) => {
        state.groups.loading = false;
        state.groups.error = payload || null;
      })
      .addCase(actions.toggleCopyTemplateModal, (state, { payload }) => {
        state.copyTemplateCandidate = payload;
      })
      .addCase(actions.toggleSetDefaultTemplateModal, (state, { payload }) => {
        state.defaultTemplateCandidate = payload;
      })
      .addCase(actions.toggleExportTemplatePdfModal, (state, { payload }) => {
        state.exportPdfTemplateCandidate = payload;
      })
      .addCase(actions.toggleDeleteModal, (state, { payload }) => {
        state.deleteTemplateModal = payload;
      })
      .addCase(actions.deleteTemplateFromGrid, (state, { payload }) => {
        const groups = auditTemplateGroupsAdapter
          .getSelectors()
          .selectAll(state.groups.data);

        const templateGroup = groups.find(({ versions }) =>
          versions.find(({ id }) => id === payload)
        );

        if (templateGroup && templateGroup.versions.length > 1) {
          auditTemplateGroupsAdapter.updateOne(state.groups.data, {
            id: templateGroup.id,
            changes: {
              versions: [...templateGroup.versions].filter(
                ({ id }) => id !== payload
              ),
            },
          });
        }
      })
      .addCase(actions.toggleSuggestionsModal, (state, { payload }) => {
        if (payload) {
          state.suggestionsModal = {
            ...suggestionsModalInitialState,
            opened: true,
          };
        } else {
          state.suggestionsModal.opened = false;
        }
      })
      .addCase(actions.getPrompts.pending, (state) => {
        if (state.suggestionsModal.opened) {
          state.suggestionsModal.prompts.loading = true;
          state.suggestionsModal.prompts.error = null;
        }
      })
      .addCase(actions.getPrompts.fulfilled, (state, { payload }) => {
        if (state.suggestionsModal.opened) {
          state.suggestionsModal.prompts.loading = false;
          state.suggestionsModal.prompts.data = payload;
        }
      })
      .addCase(actions.getPrompts.rejected, (state, { payload }) => {
        if (state.suggestionsModal.opened) {
          state.suggestionsModal.prompts.loading = false;
          state.suggestionsModal.prompts.error = payload || null;
        }
      })
      .addCase(actions.getSuggestion.pending, (state) => {
        if (state.suggestionsModal.opened) {
          state.suggestionsModal.suggestion.loading = true;
          state.suggestionsModal.suggestion.error = null;
        }
      })
      .addCase(actions.getSuggestion.fulfilled, (state, { payload }) => {
        if (state.suggestionsModal.opened) {
          state.suggestionsModal.suggestion.loading = false;
          state.suggestionsModal.suggestion.data = payload;
        }
      })
      .addCase(actions.getSuggestion.rejected, (state, { payload }) => {
        if (state.suggestionsModal.opened) {
          state.suggestionsModal.suggestion.loading = false;
          state.suggestionsModal.suggestion.error = payload || null;
        }
      })
);
