import React, { useCallback } from 'react';
import { IPagingMeta } from '@repo/shared/types';
import {
  DisplayedRange,
  Divider,
  Footer,
} from '@components/shared/ant/Table/styled';
import Pagination from '@components/shared/ant/Pagination/Pagination';
import { generalActions } from '@store';
import { useAppDispatch } from '@hooks';

interface IProps {
  meta: IPagingMeta;
  onPageChange: (update: { pageNumber: number; pageSize?: number }) => void;
}

const TableFooter: React.FC<React.PropsWithChildren<IProps>> = ({
  meta,
  onPageChange,
}) => {
  const dispatch = useAppDispatch();

  const onPaginationChange = useCallback((page: number, size: number) => {
    dispatch(generalActions.selectTableRows([]));

    if (!onPageChange) {
      return;
    }

    const update: { pageNumber: number; pageSize?: number } = {
      pageNumber: page,
    };

    if (size) {
      update.pageSize = size;
    }

    onPageChange(update);
  }, []);

  return (
    <>
      <Divider />
      <Footer>
        <DisplayedRange>
          {(meta.pageNumber - 1) * meta.pageSize +
            (meta.totalCount === 0 ? 0 : 1)}
          -
          {meta.pageNumber * meta.pageSize < meta.totalCount
            ? meta.pageNumber * meta.pageSize
            : meta.totalCount}{' '}
          of {meta.totalCount}
        </DisplayedRange>
        <Pagination
          showSizeChanger
          current={meta.pageNumber}
          pageSize={meta.pageSize}
          total={meta.totalCount}
          onChange={onPaginationChange}
        />
      </Footer>
    </>
  );
};

export default TableFooter;
