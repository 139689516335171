import { forwardRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { Dayjs } from 'dayjs';
import ScrollContainer from 'react-indiana-drag-scroll';

import {
  AssigneesModalContext,
  FileSourceType,
  PerformAuditErrorCode,
  Priority,
  TagType,
  ThumbSize,
} from '@repo/shared/enums';
import { generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { AttachLabel, FilePreviews, ModalFooter, SubHeader } from './styled';
import { IConcise, IFile, ITagConcise } from '@repo/shared/types';
import { date } from '@utils';
import { PerformAuditAction } from '@domain/Actions/models/Action';
import { e2eTestElements } from '@config';
import { config, colors } from '@repo/shared/config';
import {
  IExposedFormRef,
  useExposedForm,
} from '@application/Issues/hooks/useExposedForm';
import { performAuditActions } from '@application/PerformAudit/store/performAuditActions';

import SlideModal from '@components/shared/SideModal/SideModal';
import Form from '@components/shared/ant/Form';
import Button from '@components/shared/ant/Button';
import { Input } from '@components/shared/ant/Input';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import AssigneesFormItem from './AssigneesFormItem';
import TagSelect from '@components/shared/EntitySelects/TagSelect';
import UploadFilesTrigger from '@components/shared/UploadFiles/UploadFilesTrigger';
import Previews from '@components/shared/Previews/Previews';
import PrioritySelect from '@components/shared/PrioritySelect';

export interface ActionFormValues {
  name: string;
  description: string;
  dueDate: Dayjs;
  priority: Priority;
  tags: string[];
  assignedUsers: IConcise[];
}

interface IProps {
  opened: boolean;
  action?: PerformAuditAction;
  onClose: () => void;
  onSave: (action: {
    name: string;
    description: string;
    dueDate: string;
    assignedUsers: IConcise[];
    priority: Priority;
    files: IFile[];
    tags: ITagConcise[];
  }) => Promise<void>;
  loading?: boolean;
  auditObjectId?: string;
  previewsForceFetch?: boolean;
  getFileUploadPath?: (fileId: string) => string;
  fileSourceType?: FileSourceType;
  onDelete?: (actionId: string) => void;
  assigneesContext: AssigneesModalContext;
  disableFilesAttach?: boolean;
}

const AddEditActionModal = forwardRef<
  IExposedFormRef<ActionFormValues>,
  IProps
>(
  (
    {
      opened,
      action,
      onClose,
      onSave,
      loading,
      previewsForceFetch,
      getFileUploadPath,
      auditObjectId,
      onDelete,
      fileSourceType,
      assigneesContext,
      disableFilesAttach,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();

    const form = useExposedForm<ActionFormValues>(ref);

    const [saving, setSaving] = useState(false);
    const [previews, setPreviews] = useState<IFile[]>([]);

    const tagsMap = useSelector(generalSelectors.getConciseTagsMap);

    function closeModal() {
      if (saving) {
        return;
      }

      setPreviews([]);
      onClose();
    }

    useEffect(() => {
      if (opened) {
        setPreviews(action && Array.isArray(action.files) ? action.files : []);
      }
    }, [opened, JSON.stringify(action?.files || [])]);

    useEffect(() => {
      if (opened) {
        form.resetFields();
      }
    }, [opened]);

    const previewsRef = useRef(previews);
    previewsRef.current = previews;

    return (
      <SlideModal
        opened={opened}
        onModalClose={() => {
          closeModal();
        }}
        title={formatMessage({ id: action ? 'EditAction' : 'CreateAction' })}
        footer={
          <ModalFooter>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
                loading={saving}
                data-e2e={
                  e2eTestElements.audits.performAudit.addEditActionModal
                    .saveEditBtn
                }
              >
                {formatMessage({ id: 'Save' })}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  closeModal();
                }}
                data-e2e={
                  e2eTestElements.audits.performAudit.addEditActionModal
                    .cancelBtn
                }
                disabled={saving}
              >
                {formatMessage({ id: 'Cancel' })}
              </Button>
            </Space>
            {!!onDelete && !!action && (
              <Button
                type="primary"
                onClick={() => {
                  closeModal();
                }}
                disabled={saving}
                style={{ background: colors.red }}
              >
                {formatMessage({ id: 'Delete' })}
              </Button>
            )}
          </ModalFooter>
        }
      >
        <Form<ActionFormValues>
          form={form}
          layout="vertical"
          initialValues={{
            name: action?.name || '',
            description: action?.description || '',
            dueDate: action?.dueDate ? date(action.dueDate) : null,
            assignedUsers: action?.assignedUsers || [],
            priority:
              action?.priority !== undefined ? action.priority : Priority.Low,
            tags: action?.tags.map((tag: ITagConcise) => tag.id) || [],
          }}
          onFinish={async ({
            name,
            description,
            dueDate,
            priority,
            assignedUsers,
            tags,
          }) => {
            setSaving(true);

            await onSave({
              name,
              description,
              dueDate: date(dueDate).format(config.apiDateFormat),
              assignedUsers,
              priority,
              files: previews.map(({ file, ...other }) => ({ ...other })),
              tags: tags.reduce<ITagConcise[]>((acc, tagId) => {
                const a = acc;

                const tag = tagsMap[tagId];

                if (tag) {
                  a.push(tag);
                }

                return a;
              }, []),
            });

            closeModal();

            setSaving(false);
          }}
        >
          {loading ? (
            <Skeleton active />
          ) : (
            <>
              <Form.Item
                name="name"
                label={formatMessage({ id: 'Name' })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'RequiredField' }),
                  },
                ]}
              >
                <Input
                  placeholder={formatMessage({ id: 'EnterName' })}
                  data-e2e={
                    e2eTestElements.audits.performAudit.addEditActionModal
                      .nameInput
                  }
                  disabled={saving}
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={formatMessage({ id: 'Description' })}
              >
                <Input.TextArea
                  rows={3}
                  disabled={saving}
                  data-e2e={
                    e2eTestElements.audits.performAudit.addEditActionModal
                      .descInput
                  }
                  placeholder={formatMessage({ id: 'WhatNeedsToBeDone' })}
                />
              </Form.Item>
              <Form.Item
                name="dueDate"
                label={formatMessage({ id: 'DueDate' })}
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'PleaseSetDueDate' }),
                  },
                ]}
              >
                <DatePicker
                  disabled={saving}
                  data-e2e={
                    e2eTestElements.audits.performAudit.addEditActionModal
                      .dueDate
                  }
                  disabledDate={(current: Dayjs) =>
                    current && current.isBefore(date().startOf('day'))
                  }
                />
              </Form.Item>
              <Form.Item
                name="assignedUsers"
                label={formatMessage({ id: 'AssignedTo' })}
              >
                <AssigneesFormItem
                  auditObjectId={auditObjectId}
                  disabled={saving}
                  context={assigneesContext}
                />
              </Form.Item>
              <Form.Item name="tags" label={formatMessage({ id: 'Tag' })}>
                <TagSelect
                  placeholder={formatMessage({ id: 'SelectTag' })}
                  tagType={TagType.Action}
                  showSearch
                  e2eDataAttr={
                    e2eTestElements.audits.performAudit.addEditActionModal
                      .tagSelect
                  }
                  mode="single"
                />
              </Form.Item>
              <Form.Item
                name="priority"
                label={formatMessage({ id: 'Priority' })}
              >
                <PrioritySelect
                  disabled={saving}
                  e2eDataAttr={
                    e2eTestElements.audits.performAudit.addEditActionModal
                      .prioritySelect
                  }
                />
              </Form.Item>
              {(previews.length > 0 || !disableFilesAttach) && (
                <>
                  <SubHeader>{formatMessage({ id: 'Files' })}</SubHeader>
                  <div>
                    <ScrollContainer>
                      {previews.length > 0 && (
                        <FilePreviews>
                          <Previews
                            files={previews}
                            thumbSize={ThumbSize.md}
                            getUploadPath={getFileUploadPath}
                            fileSourceType={fileSourceType}
                            forceFetch={previewsForceFetch}
                            onUpdate={(updatedFile, index) => {
                              if (!saving) {
                                setPreviews(
                                  previews.map((file, i) =>
                                    i === index ? updatedFile : file
                                  )
                                );
                              }
                            }}
                            onConflictError={(error) => {
                              if (
                                Object.values(PerformAuditErrorCode).includes(
                                  error as PerformAuditErrorCode
                                )
                              ) {
                                dispatch(
                                  performAuditActions.setAuditErrorCode(
                                    error as PerformAuditErrorCode
                                  )
                                );

                                return true;
                              }

                              return false;
                            }}
                            onRemove={(fileId) => {
                              if (!saving) {
                                setPreviews(
                                  previews.filter((file) => file.id !== fileId)
                                );
                              }
                            }}
                          />
                        </FilePreviews>
                      )}
                    </ScrollContainer>
                  </div>
                  {!disableFilesAttach && (
                    <UploadFilesTrigger
                      source={FileSourceType.CorrectiveActionFile}
                      onAddFiles={(files) => {
                        setPreviews([...(previewsRef.current || []), ...files]);
                      }}
                      onRemoveFile={(fileId) => {
                        setPreviews([
                          ...previews.filter(
                            (preview) => preview.id !== fileId
                          ),
                        ]);
                      }}
                      getUploadPath={getFileUploadPath}
                    >
                      <AttachLabel>
                        + {formatMessage({ id: 'Attach' })}
                      </AttachLabel>
                    </UploadFilesTrigger>
                  )}
                </>
              )}
            </>
          )}
        </Form>
      </SlideModal>
    );
  }
);

export default AddEditActionModal;
