import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  padding: 5px 20px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
`;
