import React from 'react';

import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { Container, Content, Title, Desc } from './styled';
import { Logger } from '@repo/shared/services';

interface IProps {
  onChunkError: () => void;
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidMount() {
    window.addEventListener('vite:preloadError', () => {
      this.props.onChunkError();
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    Logger.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Content>
            <Title>
              {intl.formatMessage({ id: 'EncounteredUnexpectedGlitch' })}
            </Title>
            <Desc>
              {intl.formatMessage({ id: 'EncounteredUnexpectedGlitchDesc' })}
            </Desc>
          </Content>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
