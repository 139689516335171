import styled from 'styled-components';
import Typography from 'antd/es/typography';

import { colors } from '@repo/shared/config';

const { Text } = Typography;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 176px;
`;

export const Label = styled(Text)`
  color: ${colors.gray8};
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 3px !important;
`;
