import styled from 'styled-components';

import check from './check.svg';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  width: 400px;
  margin: 40px auto;
`;

export const Content = styled.div`
  background: ${colors.gray3};
  border-radius: 5px;
  padding: 16px;
  text-align: center;
`;

export const CheckIcon = styled.div`
  width: 42px;
  height: 36px;
  margin: 16px auto;
  background: url(${check}) no-repeat center center;
`;

export const Title = styled.h3`
  margin-bottom: 36px;
`;
