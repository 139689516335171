import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { accountSelectors } from '@store';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { routes } from '@config';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { SchedulePlan } from '@domain/SchedulePlans/models/SchedulePlan';

import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import SchedulePlansActionsMenu from './SchedulePlansActionsMenu';
import SchedulePlanChangeState from '@presentation/SchedulePlans/SchedulePlanChangeState/SchedulePlanChangeState';

const SchedulePlansTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const keepDataRef = useRef<boolean>(false);

  const { data, loading, error, meta } = useSelector(
    schedulePlansSelectors.getSchedulePlans
  );
  const filters = useSelector(schedulesSelectors.getFilters);
  const objectName = useSelector(accountSelectors.getObjectName);
  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);

  return (
    <Table<SchedulePlan>
      onPageChange={(update) => {
        dispatch(schedulePlansActions.getSchedulePlans(update));
      }}
      onUnmount={() => {
        if (!keepDataRef.current) {
          dispatch(schedulePlansActions.resetListData());
        }

        dispatch(schedulePlansActions.toggleAddEditModal({ show: false }));
      }}
      loading={loading}
      meta={meta}
      canSelectRow={canScheduleAudits}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          onCell: ({ id }) => ({
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();

              if (keepDataRef) {
                keepDataRef.current = true;
              }

              navigate(routes.advancedScheduleDetails.replace(':id', id));
            },
          }),
          render(_, schedulePlan) {
            return schedulePlan.name;
          },
          className: 'cell-text-link',
        },
        {
          title: formatMessage({ id: 'SchedulingRules' }),
          dataIndex: 'schedulesCount',
          key: 'schedulesCount',
          render(_, schedulePlan) {
            return schedulePlan.schedulesCount;
          },
        },
        {
          title: objectName.plural,
          dataIndex: 'auditObjectsCount',
          key: 'auditObjectsCount',
          render(_, schedulePlan) {
            return schedulePlan.auditObjectsCount;
          },
        },
        {
          title: formatMessage({ id: 'Auditors' }),
          dataIndex: 'auditorsCount',
          key: 'auditorsCount',
          render(_, schedulePlan) {
            return schedulePlan.auditorsCount;
          },
        },
        {
          title: formatMessage({ id: 'State' }),
          dataIndex: 'state',
          key: 'state',
          render(_, schedulePlan) {
            return (
              <SchedulePlanChangeState
                schedulePlan={schedulePlan}
                disabled={schedulePlan.schedulesCount === 0}
              />
            );
          },
        },
        {
          title: formatMessage({ id: 'Action' }),
          key: 'action',
          align: 'center',
          width: 70,
          render: (_, schedule) => (
            <SchedulePlansActionsMenu schedulePlan={schedule} />
          ),
        },
      ]}
      dataSource={data.map((schedulePlan: SchedulePlan) => ({
        ...schedulePlan,
        key: schedulePlan.id,
      }))}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              filters.search !== ''
                ? formatMessage(
                    { id: 'NoAdvancedSchedulesSearchResults' },
                    { searchStr: filters.search }
                  )
                : formatMessage({ id: 'NoAdvancedSchedules' })
            }
            button={
              canScheduleAudits
                ? {
                    text: `+ ${formatMessage({
                      id: 'CreateAdvancedSchedule',
                    })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        schedulePlansActions.toggleAddEditModal({
                          show: true,
                        })
                      );
                    },
                  }
                : undefined
            }
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          schedulePlansActions.getSchedulePlans({ orderBy, orderByDirection })
        )
      }
    />
  );
};

export default SchedulePlansTable;
