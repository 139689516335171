import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '../../shared/ActionsModal/styled';
import { generalSelectors, usersSelectors, usersActions } from '@store';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../../shared/ActionsModal/ActionsModal';

const JobTitlesActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const jobTitlesMap = useSelector(usersSelectors.jobTitles.getMap);
  let jobTitle =
    selectedTableRowKeys.length === 1
      ? jobTitlesMap[selectedTableRowKeys[0]]
      : null;

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                if (jobTitle) {
                  dispatch(
                    usersActions.jobTitles.toggleAddEditModal({
                      show: true,
                      jobTitleId: jobTitle.id,
                    })
                  );
                }

                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          <Action
            onClick={() => {
              dispatch(
                usersActions.jobTitles.toggleConfirmDeleteModal(
                  selectedTableRowKeys
                )
              );
            }}
          >
            {formatMessage({ id: 'Delete' })}
            {selectedTableRowKeys.length > 1
              ? ` (${selectedTableRowKeys.length})`
              : ''}
          </Action>
        </>
      )}
    </ActionsModal>
  );
};

export default JobTitlesActionsModal;
