import React from 'react';
import { useIntl } from 'react-intl';

import { ActionDetails } from '@domain/Actions/models/Action';
import { Name, Status, Description, PreviewsContainer } from './styled';
import { actionStatuses, routes } from '@config';
import { FileSourceType, Permission } from '@repo/shared/enums';
import { usePermission } from '@hooks';

import Previews from '@components/shared/Previews/Previews';
import TagList from '@components/shared/TagList/TagList';
import PriorityLabel from '@components/shared/PriorityLabel/PriorityLabel';
import ZonedDateTime from '@components/shared/ZonedDateTime';

interface IProps {
  action: ActionDetails;
}

const DetailsModalAction: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  const [canViewCorrectiveActions, canDoCorrectiveActions] = usePermission([
    Permission.CanDoAudits,
    Permission.CanAssignAudits,
    Permission.CanManageAudits,
    Permission.CanScheduleAudits,
    Permission.CanViewAuditResults,
    Permission.CanManageAuditTemplates,
    Permission.CanManageBilling,
    Permission.CanViewCorrectiveActions,
    Permission.CanViewAnalytics,
    Permission.CanManageGeneralSetup,
    Permission.CanDoCorrectiveActions,
  ]);

  return (
    <>
      <Name
        disabled={!canViewCorrectiveActions && !canDoCorrectiveActions}
        onClick={() => {
          window.open(routes.pendingAction.replace(':id', action.id), '_blank');
        }}
      >
        {action.name}
      </Name>
      <Status>
        {actionStatuses[action.status]}
        {!!action.approvedBy &&
          ` ${formatMessage(
            { id: 'byName' },
            { personName: action.approvedBy.name }
          )}`}
        {!!action.dueDateInformation && (
          <>
            <br />
            {formatMessage({ id: 'DueDate' })}:{' '}
            <ZonedDateTime dateTime={action.dueDateInformation} />
          </>
        )}
        {action.priority !== undefined && (
          <>
            <br />
            {formatMessage({ id: 'Priority' })}:{' '}
            <PriorityLabel priority={action.priority} />
          </>
        )}
        <br />
        {action.assignedUsers.length > 0
          ? ` ${formatMessage({ id: 'AssignedTo' })}: ${action.assignedUsers
              .map((user) => user.name)
              .join(', ')}`
          : ` ${formatMessage({ id: 'Unassigned' })}`}
      </Status>
      {!!action.description && <Description>{action.description}</Description>}
      {action.tags?.length > 0 && <TagList tags={action.tags} />}
      {action.files.length > 0 && (
        <PreviewsContainer>
          <Previews
            files={action.files}
            fileSourceType={FileSourceType.CorrectiveActionFile}
            containerStyle={{
              margin: '8px 0',
            }}
          />
        </PreviewsContainer>
      )}
    </>
  );
};

export default DetailsModalAction;
