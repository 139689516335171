import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import { AuditTemplateDetails } from '@repo/shared/types';
import { Logger } from '@repo/shared/services';
import AuditTemplateBuilderApiClient from '@infrastructure/AuditTemplateBuilder/api/AuditTemplateBuilderApiClient';
import {
  accountActions,
  generalActions,
  generalSelectors,
  scoresActions,
  scoresSelectors,
} from '@store';
import {
  getAncestorsSections,
  getAnswerTypeData,
  notification,
  validations,
} from '@utils';
import { delay } from '@repo/shared/utils';
import { getErrorMessage } from '@repo/shared/utils';
import { getPristineAuditTemplateDetails } from '@application/AuditTemplateBuilder/utils/getPristineAuditTemplateDetails';
import { hasTemplatesCreatedByUser } from '@application/AuditTemplateBuilder/utils/hasTemplatesCreatedByUser';
import { findRootItemId } from '@application/AuditTemplateBuilder/utils/findRootItemId';
import { IRootState } from '@src/core/frameworks/redux';
import {
  IAllowedRange,
  IDropdownAnswerData,
  ITemplateItem,
} from '@repo/shared/types';
import {
  AnalyticsEvent,
  AnswerType,
  AuditTemplateType,
  ItemType,
} from '@repo/shared/enums';
import { UnexpectedError } from '@repo/shared/errors';
import {
  AuditTemplateBuilderMode,
  SaveChangesModalState,
  SetupFormValidationStatus,
} from '@application/AuditTemplateBuilder/models/AuditTemplateBuilderState';
import { IDragItem } from '@application/AuditTemplateBuilder/models/DragItem';
import { auditTemplateBuilderSelectors } from '@application/AuditTemplateBuilder/store/auditTemplateBuilderSelectors';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { getPristineAuditTemplateItem } from '@application/AuditTemplateBuilder/utils/getPristineAuditTemplateItem';
import { ZERO_UUID } from '@config';
import { createTemplateFromSuggestion } from '@application/AuditTemplateBuilder/utils/createTemplateFromSuggestion';
import { auditTemplatesSelectors } from '@application/AuditTemplates/store/auditTemplatesSelectors';

const apiClient = new AuditTemplateBuilderApiClient();

const loadBuilder = createAsyncThunk<
  { template: AuditTemplateDetails; rootItemId: string },
  {
    templateId: string | undefined;
    templateType: AuditTemplateType;
    isDraft: boolean;
    useSuggestion: boolean;
  },
  { rejectValue: string; state: IRootState }
>(
  'auditTemplateBuilder/loadBuilder',
  async (
    { templateId, templateType, isDraft, useSuggestion },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      await Promise.all([
        dispatch(generalActions.getAnswerTypes()),
        dispatch(generalActions.getConciseAuditObjectAttributes()),
        dispatch(generalActions.getConciseActionTemplates()),
        dispatch(scoresActions.getScores()),
        dispatch(scoresActions.getScoresConcise()),
      ]);

      const answerTypes = generalSelectors.getAnswerTypes(getState());

      const scoresDictionary = scoresSelectors.getScoresLookup(getState());
      const defaultScore = scoresDictionary[Object.keys(scoresDictionary)[0]];

      let template: AuditTemplateDetails;

      if (useSuggestion) {
        const { data } = auditTemplatesSelectors.getSuggestion(getState());

        if (!data) {
          throw new UnexpectedError(
            'loadBuilder is called with useSuggestion but suggestion is not set'
          );
        }

        template = createTemplateFromSuggestion({
          templateType,
          answerTypes,
          suggestion: data,
          scoreSystem: defaultScore,
        });
      } else if (templateId) {
        template = await apiClient.getTemplateDetails(templateId, isDraft);
      } else {
        template = getPristineAuditTemplateDetails({
          templateType,
          answerTypes: generalSelectors.getAnswerTypes(getState()),
          scoreSystem: defaultScore,
        });
      }

      const rootItemId = findRootItemId(template.data);

      if (!rootItemId) {
        throw new UnexpectedError('Root item is not found');
      }

      if (!(await hasTemplatesCreatedByUser(dispatch, getState))) {
        dispatch(accountActions.sendAnalyticsEvent(AnalyticsEvent.AddTemplate));
      }

      return {
        template: {
          ...template,
          isDraft,
        },
        rootItemId,
      };
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const startDrag = createAction<IDragItem>('auditTemplateBuilder/startDrag');

const stopDrag = createAction('auditTemplateBuilder/endDrag');

const dragTemplateItem = createAction<{
  nextParentId: string;
  nextIndex: number;
}>('auditTemplateBuilder/dragTemplateItem');

const setInformationPhotosUploading = createAction<boolean>(
  'auditTemplateBuilder/setInformationPhotosUploading'
);

const validateOpenedAnswer = createAsyncThunk<
  void,
  void,
  { state: IRootState; rejectValue: Record<string, Record<string, string>> }
>(
  'auditTemplateBuilder/validateOpenedAnswer',
  async (_, { rejectWithValue, getState }) => {
    const state = getState();

    const template = auditTemplateBuilderSelectors.getTemplateDetails(state);

    if (!template) {
      throw new UnexpectedError(
        'validateOpenedAnswer: template is not set in the builder'
      );
    }

    const expandedAnswersIds = Object.keys(
      auditTemplateBuilderSelectors.getExpandedAnswers(state)
    );

    if (expandedAnswersIds.length === 0) {
      return;
    }

    const { data: itemsDictionary } = template;

    const errors: Record<string, Record<string, string>> = {};

    let hasErrors = false;

    for (let i = 0; i < expandedAnswersIds.length; i++) {
      const answerId = expandedAnswersIds[i];
      const answer = itemsDictionary[answerId];

      if (!answer) {
        continue;
      }

      if (errors[answerId] === undefined) {
        errors[answerId] = {};
      }

      if (
        answer?.hasInformation &&
        (answer.information === null ||
          (answer.information.text === '' &&
            answer.information.files.length === 0))
      ) {
        hasErrors = true;
        errors[answerId].information = intl?.formatMessage({
          id: 'RequiredField',
        });
      }

      switch (answer.answerType) {
        case AnswerType.Dropdown: {
          const data = answer.data as IDropdownAnswerData;

          for (let i = 0; i < data.conditions.length; i++) {
            const condition = data.conditions[i];

            if (
              condition.name === '' ||
              condition.points === null ||
              !validations.decimal4.test(condition.points.toString())
            ) {
              hasErrors = true;

              errors[answerId].conditions = intl?.formatMessage({
                id: 'RequiredField',
              });
            }
          }

          break;
        }
        default:
          break;
      }
    }

    if (hasErrors) {
      notification.error({
        message: intl?.formatMessage({ id: 'AnswerValidationErrors' }),
        description: intl?.formatMessage({
          id: 'AnswerValidationErrorsModalDescription',
        }),
      });

      return rejectWithValue(errors);
    }
  }
);

const saveTemplate = createAsyncThunk<
  string,
  void,
  { rejectValue: string | null; state: IRootState }
>(
  'auditTemplateBuilder/saveTemplate',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      if (
        !validateOpenedAnswer.fulfilled.match(
          await dispatch(validateOpenedAnswer())
        )
      ) {
        return rejectWithValue(null);
      }

      dispatch(changeBuilderMode(AuditTemplateBuilderMode.Setup));

      await delay(100);

      dispatch(
        setSetupFormValidationStatus(SetupFormValidationStatus.Validate)
      );

      await delay(100);

      if (
        auditTemplateBuilderSelectors.getSetupFormValidationStatus(
          getState()
        ) !== SetupFormValidationStatus.Passed
      ) {
        return rejectWithValue('setup-form-validation-failed');
      }

      const template =
        auditTemplateBuilderSelectors.getTemplateDetails(getState());

      if (!template) {
        throw new UnexpectedError(
          'saveChanges: template is not set in the builder'
        );
      }

      if (template.id === ZERO_UUID) {
        return await apiClient.createDraft(template);
      }

      await apiClient.saveTemplate(template);

      return template.id;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const publishDraft = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>(
  'auditTemplateBuilder/publishChanges',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const resultAction = await dispatch(saveTemplate());

      if (saveTemplate.rejected.match(resultAction)) {
        return rejectWithValue(resultAction.payload || '');
      }

      return await apiClient.publishDraft(unwrapResult(resultAction));
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const createDraft = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>(
  'auditTemplateBuilder/createDraft',
  async (_, { rejectWithValue, getState }) => {
    try {
      const template =
        auditTemplateBuilderSelectors.getTemplateDetails(getState());

      if (!template) {
        throw new UnexpectedError(
          'createDraft: template is not set in the builder'
        );
      }

      return await apiClient.createDraft(template);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const saveAsDraft = createAsyncThunk<
  string,
  void,
  { rejectValue: string; state: IRootState }
>(
  'auditTemplateBuilder/saveAsDraft',
  async (_, { rejectWithValue, getState }) => {
    try {
      const template =
        auditTemplateBuilderSelectors.getTemplateDetails(getState());

      if (!template) {
        throw new UnexpectedError(
          'createDraft: template is not set in the builder'
        );
      }

      return await apiClient.saveAsDraft(template.id);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const addTemplateItem = createAction<ITemplateItem>(
  'auditTemplateBuilder/addTemplateItem'
);

const updateTemplate = createAction<Partial<AuditTemplateDetails>>(
  'auditTemplateBuilder/updateTemplate'
);

const addSection = createAsyncThunk<
  void,
  {
    parentId: string;
    index: number;
  },
  { state: IRootState; rejectValue: string }
>(
  'auditTemplateBuilder/addSection',
  async ({ parentId, index }, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState();

      const template = auditTemplateBuilderSelectors.getTemplateDetails(state);
      const rootItemId = auditTemplateBuilderSelectors.getRootItemId(state);

      if (!template || !rootItemId) {
        throw new UnexpectedError(
          'addSection: template is not set in the builder'
        );
      }

      const { data } = template;
      const answerTypes = generalSelectors.getAnswerTypes(state);

      const templateType = template?.templateType;
      const parent = data[parentId];

      // if parent is sub-section take grandparent (section) instead
      // to prevent sub-sections creation inside other sub-sections:
      if (
        parent?.itemType === ItemType.Section &&
        parent.parentId !== rootItemId
      ) {
        parentId = parent.id;
        // find index of parent inside grandparent's children ids
        index = data[parentId].childrenIds.indexOf(parent.id) + 1;
      }

      const section = getPristineAuditTemplateItem({
        parentId,
        itemType: ItemType.Section,
        text: intl.formatMessage({
          id: parent.id === rootItemId ? 'Section' : 'SubSection',
        }),
        index,
      });

      // prepare array of items to create — section and two answers
      const itemsToCreate: ITemplateItem[] = [section];

      for (let i = 0; i < 2; i++) {
        const answerType =
          templateType === AuditTemplateType.Audit
            ? AnswerType.PassFailButtons
            : AnswerType.Checklist;

        itemsToCreate.push(
          getPristineAuditTemplateItem({
            text: intl.formatMessage({ id: 'Item' }),
            parentId: section.id,
            itemType: ItemType.Item,
            answerType,
            data: getAnswerTypeData(answerType, answerTypes),
            index: i,
          })
        );
      }

      itemsToCreate.forEach((item) => dispatch(addTemplateItem(item)));
    } catch (e) {
      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const openSection = createAsyncThunk<
  string,
  string,
  { state: IRootState; rejectValue: null }
>(
  'auditTemplateBuilder/openSection',
  async (sectionId, { rejectWithValue, dispatch }) => {
    if (
      !validateOpenedAnswer.fulfilled.match(
        await dispatch(validateOpenedAnswer())
      )
    ) {
      return rejectWithValue(null);
    }

    dispatch(changeBuilderMode(AuditTemplateBuilderMode.EditSection));

    return sectionId;
  }
);
const toggleAnswerSettings = createAction<{ ids: string[] }>(
  'auditTemplateBuilder/toggleAnswerSettings'
);
const changeBuilderMode = createAction<AuditTemplateBuilderMode>(
  'auditTemplateBuilder/changeBuilderMode'
);

const deleteTemplateItem = createAction<string>(
  'auditTemplateBuilder/deleteTemplateItem'
);

const updateTemplateItem = createAction<{
  entityId: string;
  update: Partial<ITemplateItem & { index: number }>;
}>('auditTemplateBuilder/updateTemplateItem');

const toggleSection = createAction<{ id: string; show: boolean }>(
  'auditTemplateBuilder/toggleSection'
);

const moveTemplateSection = createAction<{
  sectionId: string;
  nextIndex: number;
}>('auditTemplateBuilder/moveTemplateSection');

const showDeleteEntityConfirmModal = createAction<{
  entityId: string | null;
  itemType: ItemType | null;
}>('auditTemplateBuilder/showDeleteEntityConfirmModal');

const toggleSections = createAction<{ ids: string[]; show: boolean }>(
  'auditTemplateBuilder/toggleSections'
);

const openSectionsList = createAsyncThunk<
  void,
  undefined,
  { state: IRootState; rejectValue: null }
>(
  'auditTemplateBuilder/openSectionList',
  async (_, { rejectWithValue, dispatch }) => {
    if (
      !validateOpenedAnswer.fulfilled.match(
        await dispatch(validateOpenedAnswer())
      )
    ) {
      return rejectWithValue(null);
    }

    dispatch(changeBuilderMode(AuditTemplateBuilderMode.Sections));

    await delay(200);
  }
);

const toggleSectionSettingsModal = createAction<string | null>(
  'auditTemplateBuilder/toggleSectionSettingsModal'
);

const toggleSaveChangesModal = createAction<SaveChangesModalState>(
  'auditTemplateBuilder/toggleSaveChangesModal'
);

const togglePublishChangesModal = createAction<boolean>(
  'auditTemplateBuilder/togglePublishChangesModal'
);

const toggleCreateDraftCopyModal = createAction<boolean>(
  'auditTemplateBuilder/toggleCreateDraftCopyModal'
);

const toggleEditIntervalsModal = createAction<{
  itemId: string;
  intervalPrefix: string;
  allowedRange?: IAllowedRange;
} | null>('auditTemplateBuilder/toggleEditIntervalsModal');

const setSetupFormValidationStatus = createAction<SetupFormValidationStatus>(
  'auditTemplateBuilder/setSetupFormValidationStatus'
);

const toggleItemsByActionTemplateModal = createAction<string | null>(
  'auditTemplateBuilder/toggleItemsByActionTemplateModal'
);

const goToItem = createAsyncThunk<string | void, string, { state: any }>(
  'auditTemplateBuilder/goToItem',
  async (itemId, { dispatch, getState }) => {
    const state = getState();
    const itemsMap = auditTemplateBuilderSelectors.getItemsDictionary(state);
    const rootItemId = auditTemplateBuilderSelectors.getRootItemId(state);
    const item = itemsMap?.[itemId];

    if (
      !item ||
      !rootItemId ||
      item.itemType === ItemType.Root ||
      item.itemType === ItemType.Condition
    ) {
      return;
    }

    dispatch(changeBuilderMode(AuditTemplateBuilderMode.Sections));

    if (item.itemType === ItemType.Section) {
      await dispatch(openSection(item.id));
      return;
    }

    const { section, subSection, conditionalItem } = getAncestorsSections(
      itemsMap,
      itemId,
      rootItemId
    );

    if (!section) {
      return;
    }

    await dispatch(openSection(section.id));

    if (!!subSection) {
      await dispatch(openSection(subSection.id));
    }

    const ids = [item.id];
    if (!!conditionalItem) {
      ids.push(conditionalItem.id);
    }

    dispatch(toggleAnswerSettings({ ids: ids }));

    return itemId;
  }
);

const resetScrollItem = createAction('auditTemplateBuilder/resetScrollItem');

const setErrors = createAction<{
  answerId: string;
  errors: Record<string, string>;
}>('auditTemplateBuilder/answerValidation/setErrors');

const unsetError = createAction<{
  answerId: string;
  field: string;
}>('auditTemplateBuilder/answerValidation/unsetError');

const resetData = createAction('auditTemplateBuilder/resetData');

export const auditTemplateBuilderActions = {
  loadBuilder,
  startDrag,
  stopDrag,
  dragTemplateItem,
  setInformationPhotosUploading,
  saveTemplate,
  publishDraft,
  addTemplateItem,
  updateTemplate,
  addSection,
  openSection,
  toggleAnswerSettings,
  changeBuilderMode,
  toggleSection,
  deleteTemplateItem,
  updateTemplateItem,
  moveTemplateSection,
  showDeleteEntityConfirmModal,
  toggleSections,
  openSectionsList,
  createDraft,
  toggleSectionSettingsModal,
  toggleSaveChangesModal,
  togglePublishChangesModal,
  answerValidation: {
    validateOpenedAnswer,
    setErrors,
    unsetError,
  },
  toggleEditIntervalsModal,
  setSetupFormValidationStatus,
  toggleItemsByActionTemplateModal,
  goToItem,
  resetScrollItem,
  resetData,
  toggleCreateDraftCopyModal,
  saveAsDraft,
};
