import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { IAuditObjectGroup } from '@repo/shared/types';
import { accountSelectors, auditObjectsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  group: IAuditObjectGroup;
}

const AuditObjectsGroupsActionsMenu: React.FC<
  React.PropsWithChildren<IProps>
> = ({ group }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'View' }),
          onClick: () => {
            navigate(routes.auditObjects.groupDetails.replace(':id', group.id));
          },
        },
        ...(!isLocationsReadonly
          ? [
              {
                name: formatMessage({ id: 'Edit' }),
                onClick: () =>
                  dispatch(
                    auditObjectsActions.groups.toggleAddEditModal({
                      visible: true,
                      groupId: group.id,
                    })
                  ),
              },
              {
                name: formatMessage({ id: 'Delete' }),
                onClick: () =>
                  dispatch(
                    auditObjectsActions.groups.toggleConfirmDeleteModal([
                      group.id,
                    ])
                  ),
              },
            ]
          : []),
      ]}
    />
  );
};

export default AuditObjectsGroupsActionsMenu;
