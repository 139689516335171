import {
  IAnswerData,
  IAuditTotals,
  IButtonsAnswerData,
  IChecklistAnswerData,
  IDropdownAnswerData,
  IFile,
  IInterval,
  IIntervals,
  IItem,
  IItemTree,
  INumericAnswerData,
  IScore,
  ISectionStats,
  ISliderAnswerData,
  ITemperatureAnswerData,
  ITemplateItem,
  ITextAnswerData,
} from '@repo/shared/types';
import {
  ActionType,
  AnswerType,
  ItemType,
  PerformAuditErrorCode,
  ScoreSystemType,
  TemperatureUnit,
  TriggerType,
} from '@repo/shared/enums';
import { isObject, round } from './misc';
import { hasOwnProperty } from '@repo/shared/utils';
import { findScoreSystemLabelByScore } from './scores';
import { colors } from '@repo/shared/config';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { ApiConflictError } from '@repo/shared/errors';
import { Action } from '@domain/Actions/models/Action';
import { PerformAuditItem } from '@domain/PerformAudit/PerformAuditItem';
import { UpdatePerformAuditItemDto } from '@infrastructure/PerformAudit/dto/UpdatePerformAuditItemDto';

const emptyTotals = {
  items: 0,
  subSections: 0,
  sections: 0,
  points: 0,
  selectedPoints: 0,
  score: null,
  previousScore: null,
};

export function getAuditReportTotals(
  entities: Record<string, PerformAuditItem>,
  rootItemId: string
) {
  const root = entities[rootItemId];

  return Object.values(entities).reduce(
    (acc: IAuditTotals, entity: PerformAuditItem) => {
      const totals = acc;

      if (entity.itemType === ItemType.Section) {
        const { parentId, points, itemsCount, id } = entity;

        if (!totals[id]) {
          totals[id] = { ...emptyTotals };
        }

        if (parentId === rootItemId) {
          totals[parentId].sections += 1;
        }

        totals[id].points = points?.total || 0;
        totals[id].selectedPoints = points?.selected || 0;
        totals[id].items = itemsCount || 0;
      }

      return totals;
    },
    {
      [rootItemId]: {
        sections: root.childrenIds.length,
        subSections: 0,
        items: root.itemsCount || 0,
        selectedPoints: root.points?.selected || 0,
        points: root.points?.total || 0,
      },
    }
  );
}

export function findRootItemId(items: PerformAuditItem[]): string {
  const root = items.find((item) => item.itemType === ItemType.Root);

  if (!root) {
    throw new Error(`Root item has not been found`);
  }

  return root.id;
}

export const calculateAnswerTotals = (data: IAnswerData): [number, number] => {
  let points = 0;
  let selectedPoints = 0;

  if (hasOwnProperty(data, 'conditions') && Array.isArray(data.conditions)) {
    for (let i = 0; i < data.conditions.length; i++) {
      const dataItemPoints = data.conditions[i].points || 0;

      if (dataItemPoints > points) {
        points = dataItemPoints;
      }

      if (data.conditions[i].selected) {
        selectedPoints += dataItemPoints;
      }
    }
  }

  return [points, selectedPoints];
};

export function calculateTemplatePreviewTotals<T extends ITemplateItem>(
  items: Record<string, T>,
  rootEntityId: string
) {
  const emptyTotals = {
    items: 0,
    subSections: 0,
    sections: 0,
    points: 0,
    selectedPoints: 0,
  };

  return Object.values(items).reduce(
    (acc: IAuditTotals, entity: T) => {
      const totals = acc;

      const { parentId, itemType, data } = entity;

      if (parentId === null) {
        return acc;
      }

      if (!totals[parentId]) {
        totals[parentId] = { ...emptyTotals };
      }

      if (itemType === ItemType.Section) {
        if (parentId === rootEntityId) {
          totals[parentId].sections += 1;
        } else {
          totals[parentId].subSections += 1;
        }
      }

      if (itemType === ItemType.Item) {
        const notApplicableItem =
          hasOwnProperty(entity, 'notApplicable') && entity.notApplicable;

        if (!notApplicableItem) {
          const [totalPoints, selectedPoints] = calculateAnswerTotals(
            (data || []) as IButtonsAnswerData | INumericAnswerData
          );

          let currentParent = items[parentId];

          do {
            if (currentParent) {
              if (!totals[currentParent.id]) {
                totals[currentParent.id] = { ...emptyTotals };
              }

              totals[currentParent.id].items += 1;
              totals[currentParent.id].selectedPoints += selectedPoints;
              totals[currentParent.id].points += totalPoints;

              currentParent = items[items[currentParent.id].parentId as string];
            }
          } while (currentParent && currentParent.itemType !== ItemType.Root);

          totals[rootEntityId].items += 1;
          totals[rootEntityId].selectedPoints += selectedPoints;
          totals[rootEntityId].points += totalPoints;
        }
      }

      return totals;
    },
    {
      [rootEntityId]: { ...emptyTotals },
    }
  );
}

export function convertTemplateItemsMapToTree(
  tree: Record<string, ITemplateItem | PerformAuditItem> | null,
  rootEntityId: string | undefined | null,
  sortByIndex?: boolean
) {
  if (tree === null || !rootEntityId || !tree[rootEntityId]) {
    return null;
  }

  const { childrenIds, ...root } = tree[rootEntityId];

  function process(ids: string[]): any {
    const children = [];

    if (tree !== null) {
      const idsToProcess = sortByIndex
        ? ids.slice().sort((id1, id2) => tree[id1].index - tree[id2].index)
        : ids;

      for (let i = 0; i < idsToProcess.length; i++) {
        const { childrenIds, ...rest } = tree[idsToProcess[i]];

        children.push({
          ...rest,
          children: process(childrenIds || []),
        });
      }
    }

    return children;
  }

  return {
    ...root,
    children: process(childrenIds || []),
  };
}

export function normalizeAuditItems(
  items: PerformAuditItem[],
  templateId: string
): Record<string, PerformAuditItem> {
  return items.reduce<Record<string, PerformAuditItem>>((tree, item) => {
    const acc = tree;
    const childrenIds = item.childrenIds || [];
    const { id, ...rest } = item;

    if (!acc[id]) {
      acc[id] = {
        ...rest,
        id,
        childrenIds,
        parentId: templateId,
      };
    }

    for (let i = 0; i < childrenIds.length; i++) {
      const childrenId = childrenIds[i];
      const entity = items.find((entity) => entity.id === childrenId);

      if (entity) {
        acc[childrenId] = {
          ...entity,
          childrenIds: entity.childrenIds || [],
          parentId: id,
        };
      }
    }

    return acc;
  }, {});
}

export const convertArrayToHashMap = (ids: string[]) =>
  ids.reduce((acc: { [id: string]: true }, id) => {
    acc[id] = true;
    return acc;
  }, {});

export function isButtonsAnswerData(
  data?: IAnswerData | null
): data is IButtonsAnswerData {
  return (
    isObject(data) &&
    hasOwnProperty(data, 'conditions') &&
    Array.isArray(data.conditions) &&
    (data.conditions as IIntervals).every(
      (option: any) =>
        hasOwnProperty(option, 'points') &&
        hasOwnProperty(option, 'name') &&
        hasOwnProperty(option, 'color') &&
        hasOwnProperty(option, 'markAsFailed') &&
        (typeof option.selected === 'boolean' ||
          typeof option.selected === 'undefined')
    )
  );
}

function isIntervalData(data?: IIntervals | null): data is IIntervals {
  return (
    Array.isArray(data) &&
    (data as IIntervals).every(
      (option) =>
        hasOwnProperty(option, 'fromNumber') &&
        hasOwnProperty(option, 'toNumber') &&
        hasOwnProperty(option, 'points') &&
        hasOwnProperty(option, 'markAsFailed')
    )
  );
}

export function isNumericAnswerData(
  data?: IAnswerData | null
): data is INumericAnswerData {
  return (
    isObject(data) &&
    hasOwnProperty(data, 'conditions') &&
    isIntervalData(data.conditions as IIntervals)
  );
}

export function isChecklistAnswerData(
  data?: IAnswerData | null
): data is IChecklistAnswerData {
  return (
    isObject(data) &&
    hasOwnProperty(data, 'conditions') &&
    Array.isArray(data.conditions) &&
    (data.conditions as IIntervals).every(
      (option: any) =>
        hasOwnProperty(option, 'name') &&
        hasOwnProperty(option, 'points') &&
        !hasOwnProperty(option, 'color')
    )
  );
}

export function isTextAnswerData(
  data?: IAnswerData | null
): data is ITextAnswerData {
  return isObject(data) && hasOwnProperty(data, 'text');
}

export function isTemperatureAnswerData(
  data?: IAnswerData | null
): data is ITemperatureAnswerData {
  return (
    isObject(data) &&
    hasOwnProperty(data, 'units') &&
    hasOwnProperty(data, 'conditions') &&
    // @ts-ignore
    isIntervalData(data.conditions)
  );
}

export function isSliderAnswerData(
  data?: IAnswerData | null
): data is ISliderAnswerData {
  return (
    isObject(data) &&
    hasOwnProperty(data, 'min') &&
    hasOwnProperty(data, 'max') &&
    hasOwnProperty(data, 'increment') &&
    hasOwnProperty<IAnswerData, 'conditions', IInterval[]>(
      data,
      'conditions'
    ) &&
    isIntervalData(data.conditions)
  );
}

export function isDropdownAnswerData(
  data?: IAnswerData | null
): data is IDropdownAnswerData {
  return (
    isObject(data) &&
    hasOwnProperty(data, 'conditions') &&
    Array.isArray(data.conditions) &&
    (data.conditions as IIntervals).every(
      (option: any) =>
        hasOwnProperty(option, 'name') &&
        hasOwnProperty(option, 'points') &&
        hasOwnProperty(option, 'markAsFailed')
    )
  );
}

export function getAnswerTypeData(
  answerType: AnswerType | null | undefined,
  answerTypes: { answerType: AnswerType; data: IAnswerData }[]
): IAnswerData {
  const answerTypeData = answerTypes.find(
    (item) => item.answerType === answerType
  );

  if (!answerTypeData) {
    throw new Error('Answer type data is not found');
  }

  return answerTypeData.data;
}

export function enumToTemperatureUnit(unit: TemperatureUnit) {
  if (unit === TemperatureUnit.F) {
    return <>&deg;F</>;
  }

  return <>&deg;C</>;
}

function getConditionalChildrenIds(
  items: Record<string, PerformAuditItem | ITemplateItem>,
  id: string,
  includeCondition?: boolean
): string[] {
  const { answerType, childrenIds, data } = items[id];

  if (items[childrenIds[0]]?.itemType === ItemType.Condition) {
    const condition = items[childrenIds[0]];

    if (
      (answerType === AnswerType.PassFailButtons ||
        answerType === AnswerType.YesNoButtons) &&
      isButtonsAnswerData(data)
    ) {
      const selected = data.conditions.find(({ selected }) => selected);

      if (
        selected &&
        condition &&
        hasOwnProperty(condition, 'conditionValue') &&
        selected.name === condition?.conditionValue
      ) {
        return [...condition.childrenIds];
      }
    }

    if (includeCondition) {
      return [condition.id];
    }

    return [];
  }

  return childrenIds;
}

export function convertTemplateItemToAuditItemPreview(
  item: ITemplateItem
): PerformAuditItem {
  return {
    ...item,
    templateItemId: item.id,
    failedInRowCount: 0,
    notApplicable: false,
    notApplicableInRowCount: 0,
    groupIndex: null,
    points: {
      total: 0,
      selected: 0,
      score: 0,
      previousScore: null,
      color: colors.gray8,
    },
    itemsCount: null,
    actions: [],
    files: [],
    signature: null,
    flags: [],
    flagsInRow: [],
    tags: [],
    note: null,
    conditionValue: null,
  };
}

export function convertItemsMapToTree({
  itemsMap,
  rootItemId,
  includeCondition,
}: {
  itemsMap: Record<string, PerformAuditItem | ITemplateItem>;
  rootItemId: string;
  includeCondition?: boolean;
}): IItemTree {
  const { childrenIds, id, itemType, disableNotApplicable } =
    itemsMap[rootItemId];

  function process(ids: string[]): IItemTree[] {
    const children = [];

    if (itemsMap !== null) {
      const idsToProcess = [...ids];

      for (let i = 0; i < idsToProcess.length; i++) {
        if (!itemsMap[idsToProcess[i]]) {
          continue;
        }

        const { id, itemType, disableNotApplicable } =
          itemsMap[idsToProcess[i]];

        const childrenIds =
          itemType === ItemType.ConditionalItem
            ? getConditionalChildrenIds(itemsMap, id, includeCondition)
            : itemsMap[idsToProcess[i]].childrenIds;

        children.push({
          id,
          itemType,
          children: process(childrenIds || []),
          disableNotApplicable,
        });
      }
    }

    return children;
  }

  return {
    id,
    itemType,
    children: process(childrenIds || []),
    disableNotApplicable,
  };
}

export function calculateItemTreeStats({
  itemsMap,
  rootItemId,
  templateOrAuditRootItemId,
  auditScoreSystem,
}: {
  itemsMap: Record<string, PerformAuditItem | ITemplateItem> | null;
  rootItemId?: string | null;
  templateOrAuditRootItemId?: string | null;
  auditScoreSystem?: IScore | null;
}): ISectionStats | null {
  if (
    !itemsMap ||
    !rootItemId ||
    !itemsMap[rootItemId] ||
    !templateOrAuditRootItemId
  ) {
    return null;
  }

  const countableIds = getCountableItemsIds(
    itemsMap,
    itemsMap[rootItemId].childrenIds
  );

  const totals = {
    sections: 0,
    subSections: 0,
    items: 0,
    points: 0,
    selectedPoints: 0,
    completedItems: 0,
    completedSubSections: 0,
    completedSections: 0,
  };

  const sectionCompletion: Record<
    string,
    { totalItems: number; completedItems: number }
  > = {};

  const countPointsForNAItems =
    auditScoreSystem?.scoreSystemType === ScoreSystemType.CountPointsForNAItems;

  for (let i = 0; i < countableIds.length; i++) {
    const id = countableIds[i];

    const item = itemsMap[id];

    const { itemType, parentId } = item;
    const notApplicable =
      hasOwnProperty(item, 'notApplicable') && item.notApplicable;
    const countItems = !notApplicable || countPointsForNAItems;

    switch (itemType) {
      case ItemType.Section:
        if (countItems) {
          if (parentId === templateOrAuditRootItemId) {
            totals.sections++;
          } else {
            totals.subSections++;
          }
        }

        sectionCompletion[id] = {
          totalItems: 0,
          completedItems: 0,
        };

        break;
      case ItemType.Item:
      case ItemType.ConditionalItem:
        const { section, subSection } = getAncestorsSections<
          PerformAuditItem | ITemplateItem
        >(itemsMap, id, templateOrAuditRootItemId);

        const { points, selectedPoints, completed } = getAnswerStats(
          item,
          countPointsForNAItems
        );

        if (countItems) {
          totals.items++;
        }

        if (completed && countItems) {
          if (totals.completedItems === undefined) {
            totals.completedItems = 0;
          }

          totals.completedItems++;
        }

        if (subSection) {
          if (!sectionCompletion[subSection.id]) {
            sectionCompletion[subSection.id] = {
              totalItems: 0,
              completedItems: 0,
            };
          }

          if (countItems) {
            sectionCompletion[subSection.id].totalItems++;

            if (completed) {
              sectionCompletion[subSection.id].completedItems++;
            }
          }
        }

        if (section) {
          if (!sectionCompletion[section.id]) {
            sectionCompletion[section.id] = {
              totalItems: 0,
              completedItems: 0,
            };
          }

          if (countItems) {
            sectionCompletion[section.id].totalItems++;

            if (completed) {
              sectionCompletion[section.id].completedItems++;
            }
          }
        }

        if (countItems) {
          totals.points += points;
          totals.selectedPoints += selectedPoints;
        }

        break;
      default:
        break;
    }
  }

  Object.entries(sectionCompletion).forEach(
    ([sectionId, { totalItems, completedItems }]) => {
      const isCompleted = totalItems === completedItems && totalItems > 0;
      const isSection =
        itemsMap[sectionId].parentId === templateOrAuditRootItemId;

      if (isCompleted) {
        if (isSection) {
          totals.completedSections++;
        } else {
          totals.completedSubSections++;
        }
      }
    }
  );

  let score = 0;
  if (totals.points > 0) {
    score = round((totals.selectedPoints / totals.points) * 100, 0);
  } else if (totals.points === 0 && totals.selectedPoints === 0) {
    score = 100;
  }

  const selectedScoreLabel = findScoreSystemLabelByScore(
    auditScoreSystem?.labels || [],
    score
  );

  return {
    totals,
    score: {
      score,
      color: selectedScoreLabel?.color || colors.gray8,
      previousScore: null,
    },
  };
}

function validateCondition({
  triggerType,
  isAnswered,
  isFailed,
  hasData,
}: {
  triggerType: TriggerType;
  isAnswered: boolean;
  isFailed: boolean;
  hasData: boolean;
}): boolean {
  let required = false;

  switch (triggerType) {
    case TriggerType.IsAnswered:
      required = !hasData;
      break;
    case TriggerType.Failed:
      required = isAnswered && isFailed && !hasData;
      break;
    case TriggerType.Passed:
      required = isAnswered && !isFailed && !hasData;
      break;
    default:
      break;
  }

  return required;
}

export function validateAnswer(answer: PerformAuditItem | ITemplateItem): {
  isPhotoRequired: boolean;
  isActionRequired: boolean;
  isSignatureRequired: boolean;
  isAnswered: boolean;
  isFailed: boolean;
} {
  const hasAttachedFiles =
    hasOwnProperty<PerformAuditItem | ITemplateItem, 'files', IFile[]>(
      answer,
      'files'
    ) && answer.files.length > 0;
  const hasAttachedActions =
    hasOwnProperty<PerformAuditItem | ITemplateItem, 'actions', Action[]>(
      answer,
      'actions'
    ) && answer.actions.length > 0;
  const conditions = answer.conditions || [];

  let isFailed = isAnswerFailed(answer.answerType, answer.data);
  let isPhotoRequired = false;
  let isActionRequired = false;
  const isAnswered = isFailed !== null;

  for (let i = 0; i < conditions.length; i++) {
    const { triggerType, actionType } = conditions[i];

    switch (actionType) {
      case ActionType.RequireAction:
        isActionRequired = validateCondition({
          triggerType,
          isAnswered,
          isFailed: isFailed === true,
          hasData: hasAttachedActions,
        });
        break;
      case ActionType.RequirePhoto:
        isPhotoRequired = validateCondition({
          triggerType,
          isAnswered,
          isFailed: isFailed === true,
          hasData: hasAttachedFiles,
        });
        break;
      default:
        break;
    }
  }

  return {
    isPhotoRequired,
    isActionRequired,
    isSignatureRequired:
      answer.isSignatureRequired &&
      hasOwnProperty(answer, 'signature') &&
      !answer.signature,
    isAnswered,
    isFailed: isFailed === true,
  };
}

export function getAnswerStats(
  answer: PerformAuditItem | ITemplateItem,
  countPointsForNAItems: boolean
) {
  const stats = {
    points: 0,
    selectedPoints: 0,
    completed: false,
  };

  const isNonApplicable =
    hasOwnProperty<PerformAuditItem | ITemplateItem, 'notApplicable', boolean>(
      answer,
      'notApplicable'
    ) && answer.notApplicable;
  const { answerType, data } = answer;

  if (answerType === undefined || answerType === null || !data) {
    return stats;
  }

  const { isPhotoRequired, isActionRequired, isAnswered, isSignatureRequired } =
    validateAnswer(answer);
  const completed =
    isNonApplicable ||
    (isAnswered &&
      !isPhotoRequired &&
      !isActionRequired &&
      !isSignatureRequired);

  switch (answerType) {
    case AnswerType.Checklist:
    case AnswerType.Slider:
    case AnswerType.Numeric:
    case AnswerType.Dropdown:
    case AnswerType.Temperature:
    case AnswerType.PassFailButtons:
    case AnswerType.YesNoButtons: {
      const { conditions } = data as
        | IButtonsAnswerData
        | IDropdownAnswerData
        | ITemperatureAnswerData
        | INumericAnswerData
        | ISliderAnswerData
        | IChecklistAnswerData;

      let maxPoints = conditions[0]?.points || 0;

      for (let i = 0; i < conditions.length; i++) {
        const points = conditions[i].points || 0;

        if (points > maxPoints) {
          maxPoints = points;
        }

        if (conditions[i].selected) {
          stats.selectedPoints = points;
          stats.completed = completed;
        }
      }

      stats.points = maxPoints;

      if (isNonApplicable) {
        if (countPointsForNAItems) {
          stats.selectedPoints = maxPoints;
        }

        stats.completed = completed;
      }

      break;
    }
    case AnswerType.Text: {
      stats.completed = completed;
      break;
    }
    default:
      break;
  }

  return stats;
}

export function getChildrenIds(
  items: Record<string, PerformAuditItem | ITemplateItem>,
  ids: string[]
): string[] {
  let result: string[] = [];

  for (let i = 0; i < ids.length; i++) {
    if (!items[ids[i]]) {
      continue;
    }

    const { childrenIds, id } = items[ids[i]];

    result.push(id);

    if (Array.isArray(childrenIds) && childrenIds.length > 0) {
      result = [...result, ...getChildrenIds(items, childrenIds)];
    }
  }

  return result;
}

export function getCountableItemsIds(
  items: Record<string, PerformAuditItem | ITemplateItem>,
  ids: string[]
): string[] {
  let allIds: string[] = [];

  for (let i = 0; i < ids.length; i++) {
    if (!items[ids[i]]) {
      continue;
    }

    const { childrenIds, itemType, id } = items[ids[i]];

    allIds.push(id);

    if (itemType === ItemType.Section && childrenIds.length > 0) {
      allIds = [...allIds, ...getCountableItemsIds(items, childrenIds)];
    } else if (itemType === ItemType.ConditionalItem) {
      allIds = [...allIds, ...getConditionalChildrenIds(items, id)];
    }
  }

  return allIds;
}

export function getAncestorsSections<T extends IItem>(
  items: Record<string, T>,
  itemId: string,
  rootItemId: string
): { subSection: T | null; section: T | null; conditionalItem: T | null } {
  let id: string | null = itemId;

  let section: T | null = null;
  let subSection: T | null = null;
  let conditionalItem: T | null = null;

  while (id && id !== rootItemId) {
    if (items[id].itemType === ItemType.Section) {
      if (items[id].parentId === rootItemId) {
        section = items[id];
      } else {
        subSection = items[id];
      }
    } else if (items[id].itemType === ItemType.ConditionalItem) {
      conditionalItem = items[id];
    }

    id = items[id].parentId;
  }

  return { section, subSection, conditionalItem };
}

export function getAuditDurationLSItemName(auditId: string): string {
  return `audit_duration_${auditId}`;
}

export function checkIsParentNADisabled(
  items: Record<string, ITemplateItem | PerformAuditItem>,
  itemId: string,
  includeItem?: boolean
): boolean {
  let id = includeItem ? itemId : items[itemId].parentId;

  while (id) {
    if (items[id].disableNotApplicable) {
      return true;
    }

    id = items[id].itemType === ItemType.Root ? null : items[id].parentId;
  }

  return false;
}

// export function checkIsChildNADisabled(
//   items: Record<string, ITemplateItem | IAuditItem>,
//   itemId: string
// ): boolean {
//   const item = items[itemId];
//
//   if (item.disableNotApplicable) {
//     return true;
//   } else if (item.childrenIds.length > 0) {
//     let disableNotApplicable = false;
//
//     for (let i = 0; !disableNotApplicable && i < item.childrenIds.length; i++) {
//       disableNotApplicable = checkIsChildNADisabled(items, item.childrenIds[i]);
//     }
//
//     return disableNotApplicable;
//   }
//
//   return false;
// }

export function checkIsChildNADisabledInTree(tree: IItemTree): boolean {
  if (tree.disableNotApplicable) {
    return true;
  } else if (
    tree.children.length > 0 &&
    (tree.itemType === ItemType.Root || tree.itemType === ItemType.Section)
  ) {
    let disableNotApplicable = false;

    for (let i = 0; !disableNotApplicable && i < tree.children.length; i++) {
      disableNotApplicable = checkIsChildNADisabledInTree(tree.children[i]);
    }

    return disableNotApplicable;
  }

  return false;
}

export function resetAnswerDataAndSetNA(
  answer: PerformAuditItem,
  notApplicable: boolean
): PerformAuditItem {
  const pristineAnswer: PerformAuditItem = {
    ...answer,
    notApplicable,
    note: answer.note,
    signature: null,
    files: [],
    flags: [],
    actions: [],
  };

  if (answer.data) {
    switch (answer.answerType) {
      case AnswerType.PassFailButtons:
      case AnswerType.YesNoButtons:
      case AnswerType.Slider:
      case AnswerType.Checklist:
      case AnswerType.Dropdown:
      case AnswerType.Numeric:
      case AnswerType.Temperature: {
        const intervalData = answer.data as
          | IButtonsAnswerData
          | IChecklistAnswerData
          | ISliderAnswerData
          | IDropdownAnswerData
          | INumericAnswerData
          | ITemperatureAnswerData;

        pristineAnswer.data = {
          ...answer.data,
          // @ts-ignore
          conditions: intervalData.conditions.map((condition) => ({
            ...condition,
            selected: false,
          })),
        };

        break;
      }
      case AnswerType.Text: {
        pristineAnswer.data = {
          text: '',
        };
        break;
      }
      default:
        break;
    }
  }

  return pristineAnswer;
}

export function normalizeAuditItemForApi(
  item: PerformAuditItem
): UpdatePerformAuditItemDto {
  return {
    ...item,
    actions: (item.actions || []).map(({ assignedUsers, ...action }) => {
      if (hasOwnProperty(action, 'assignees')) {
        delete action.assignees;
      }

      return {
        ...action,
        tagsIds: (action.tags || []).map(({ id }) => id),
        assignedUsersIds: (assignedUsers || []).map((assignee) =>
          typeof assignee === 'string' ? assignee : assignee.id
        ),
      };
    }),
  };
}

export function extractPerformAuditErrorCode(
  e: any
): PerformAuditErrorCode | null {
  if (
    e instanceof ApiConflictError &&
    Object.values(PerformAuditErrorCode).includes(
      e.message as PerformAuditErrorCode
    )
  ) {
    return e.message as PerformAuditErrorCode;
  }

  return null;
}

// Returns null if answer is not answered
export function isAnswerFailed(
  answerType: AnswerType | null,
  data: IAnswerData | null
): boolean | null {
  if (answerType === AnswerType.Checklist && isChecklistAnswerData(data)) {
    // @ts-ignore
    const selectedOption = data.conditions.find(({ selected }) => selected);

    if (selectedOption) {
      return false;
    }
  }

  if (
    ((answerType === AnswerType.PassFailButtons ||
      answerType === AnswerType.YesNoButtons) &&
      isButtonsAnswerData(data)) ||
    (answerType === AnswerType.Numeric && isNumericAnswerData(data)) ||
    (answerType === AnswerType.Dropdown && isDropdownAnswerData(data)) ||
    (answerType === AnswerType.Slider && isSliderAnswerData(data)) ||
    (answerType === AnswerType.Temperature && isTemperatureAnswerData(data))
  ) {
    // @ts-ignore
    const selectedOption = data.conditions.find(({ selected }) => selected);

    if (selectedOption) {
      return selectedOption.markAsFailed;
    }
  }

  if (answerType === AnswerType.Text && isTextAnswerData(data) && data.text) {
    return false;
  }

  return null;
}

export function getButtonName(name: string): string {
  if (/(Yes|No|Passed|Failed)/.test(name)) {
    return intl.formatMessage({ id: name });
  }

  return name;
}
