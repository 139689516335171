import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

import { AnyObject } from '../types';

const SITE = 'datadoghq.com';

interface IUser {
  id?: string;
  email?: string;
  companyId?: string;
  companyName?: string;
}

interface IInitConfig {
  version: string;
  service: string;
  applicationId: string;
  clientToken: string;
}

export class Logger {
  private static createConfig({
    version,
    service,
    applicationId,
    clientToken,
  }: IInitConfig): RumInitConfiguration {
    const config: RumInitConfiguration = {
      version,
      service,
      applicationId,
      clientToken,
      site: SITE,
      env: import.meta.env.VITE_ENV === 'production' ? 'prod' : 'dev',
      sessionSampleRate: 100,
      defaultPrivacyLevel: 'mask-user-input',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      beforeSend: (event) => {
        if (
          event.type === 'error' &&
          (event.error.message.includes('ResizeObserver loop limit exceeded') ||
            event.error.message.includes('Object Not Found Matching') ||
            event.error.message.toLowerCase().includes('illegal invocation'))
        ) {
          return false;
        }
      },
    };

    if (import.meta.env.VITE_API_BASE_URL) {
      config.allowedTracingUrls = [import.meta.env.VITE_API_BASE_URL];
    }

    return config;
  }

  static init(config: IInitConfig) {
    datadogRum.init(this.createConfig(config));
    datadogRum.startSessionReplayRecording();
  }

  static setUser({ id, email, companyId, companyName, deviceId }: Partial<IUser & { deviceId: string }>) {
    datadogRum.setUser({
      id,
      email,
      companyId,
      companyName,
      deviceId,
    });
  }

  static removeUser() {
    datadogRum.removeUser();
  }

  static captureAction(name: string, context?: AnyObject) {
    if (import.meta.env.VITE_ENV === 'development') {
      console.info('captured action: ', name, context);
    }

    datadogRum.addAction(name, context);
  }

  static captureException(e: any, context?: AnyObject) {
    if (import.meta.env.VITE_ENV === 'development') {
      console.error(e);
    }

    datadogRum.addError(e, context);
  }
}
