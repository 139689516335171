import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FileText } from 'react-feather';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { Permission, SelfGuidedTourStep } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { e2eTestElements } from '@config';
import { colors, config } from '@repo/shared/config';
import { date } from '@utils';
import { performAuditActions } from '@application/PerformAudit/store/performAuditActions';
import { AuditsPage } from '@application/Audits/models/AuditsPage';

import AuditStatusName from '../AuditStatusName';
import AuditsActionsMenu from '../AuditsTable/AuditsActionsMenu';
import Details from './Details/Details';
import AllAuditActions from './AllAuditActions/AllAuditActions';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';
import ViewModalLayout, {
  FooterAction,
} from '@components/shared/ViewModalLayout/ViewModalLayout';

interface IProps {
  page?: AuditsPage;
}

const ViewAuditModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const modalState = useSelector(auditsSelectors.getViewAuditModalState);

  const [canDoAudits, canViewAuditResults, canViewActions] = usePermission([
    Permission.CanDoAudits,
    Permission.CanViewAuditResults,
    Permission.CanViewCorrectiveActions,
  ]);

  const closeModal = useCallback(() => {
    dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
  }, [dispatch]);

  const {
    data: audit,
    loading,
    error,
  } = useSelector(auditsSelectors.getAuditDetails);

  useEffect(() => {
    if (modalState.opened) {
      dispatch(auditsActions.getAuditDetails(modalState));
    }
  }, [modalState.opened]);

  const footerActions = useMemo<FooterAction[]>(() => {
    if (audit?.completedAtInformation && canViewAuditResults) {
      return [
        {
          e2eDataAttr:
            e2eTestElements.audits.viewAuditDetailsModal.showReportBtn,
          onFooterButtonClick: () => {
            dispatch(
              auditsActions.toggleAuditReportModal({
                opened: true,
                audit,
              })
            );
          },
          title: formatMessage({ id: 'ShowReport' }),
          icon: <FileText />,
          color: colors.green,
        },
      ];
    }

    if (!audit?.expiredAtInformation && canDoAudits) {
      const disabled = !audit?.canStartAudit;

      return [
        {
          e2eDataAttr:
            e2eTestElements.audits.viewAuditDetailsModal.startAuditBtn,
          disabled,
          onFooterButtonClick: () => {
            if (!audit) {
              return;
            }

            closeModal();
            dispatch(performAuditActions.openModal(audit.id));
          },
          title:
            disabled &&
            date(audit?.startDateInformation.localTime).isAfter(date())
              ? formatMessage(
                  { id: 'StartsOnDate' },
                  {
                    date: date(audit?.startDateInformation.localTime).format(
                      config.dateFormat
                    ),
                  }
                )
              : formatMessage({
                  id: audit?.startedAtInformation ? 'Continue' : 'Start',
                }),
          color: colors.green,
        },
      ];
    }

    return [];
  }, [audit, closeModal, dispatch, formatMessage, loading, error]);

  return (
    <ViewModalLayout
      loading={loading}
      opened={modalState.opened}
      closeModal={closeModal}
      tabs={[
        {
          title: formatMessage({ id: 'Details' }),
          content: <Details audit={audit} />,
        },
        ...(audit?.completedAtInformation && canViewActions
          ? [
              {
                title: formatMessage({ id: 'Actions' }),
                content: <AllAuditActions audit={audit} />,
              },
            ]
          : []),
      ]}
      topBarLeadingContent={
        page !== null && !loading && !error ? (
          <AuditsActionsMenu audit={audit} context="modal" />
        ) : null
      }
      title={
        loading || error ? null : (
          <TourPopover
            step={SelfGuidedTourStep.ViewAuditsReports}
            placement="left"
            overlayStyle={{ height: 450, width: 340 }}
            measureDelay={300}
          >
            <div>
              {`${audit?.number ? `${audit?.number} - ` : ''}${audit?.name}`}
            </div>
          </TourPopover>
        )
      }
      error={error}
      subTitle={loading || error ? null : <AuditStatusName audit={audit} />}
      footerActions={footerActions}
    />
  );
};

export default ViewAuditModal;
