import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { routes } from '@config';

const pageToRouteMap = {
  [AuditsPage.Pending]: {
    list: routes.pendingAudits,
    single: routes.pendingAudit,
  },
  [AuditsPage.Completed]: {
    list: routes.completedAudits,
    single: routes.completedAudit,
  },
  [AuditsPage.Expired]: {
    list: routes.expiredAudits,
    single: routes.expiredAudit,
  },
  [AuditsPage.My]: {
    list: routes.myAudits,
    single: routes.pendingAudit,
  },
};

export function getRoutesFromAuditPage(page: AuditsPage) {
  return pageToRouteMap[page];
}
