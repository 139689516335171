import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Logger } from '@repo/shared/services';
import { Container } from './styled';
import { dateUTC, getFirstCharacter, notification } from '@utils';
import { accountActions, accountSelectors, generalActions } from '@store';
import { useAppDispatch } from '@hooks';
import { allowedLogoMimeTypes, config } from '@repo/shared/config';
import { InternalApiService } from '@repo/shared/api';

import UploadCircle from './UploadCircle/UploadCircle';
import { FileSourceType, MimeType } from '@repo/shared/enums';

const apiService = InternalApiService.getInstance();

const UploadIcon: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [iconUrl, setIconUrl] = useState<string | null>();

  const company = useSelector(accountSelectors.getCompany);
  const currentAccount = useSelector(accountSelectors.getCurrentAccount);
  const existingIconId = currentAccount?.company?.iconId;

  const getAndSetIconUrl = useCallback(
    async (iconId: string): Promise<boolean> => {
      setLoading(true);

      try {
        const blob = await apiService.getFile<Blob>({
          id: iconId,
          responseType: 'blob',
        });

        setIconUrl(URL.createObjectURL(blob));
        setLoading(false);

        return true;
      } catch (e) {
        console.error(e);

        setIconUrl(null);
        setLoading(false);

        return false;
      }
    },
    [JSON.stringify(currentAccount || {})]
  );

  useEffect(() => {
    if (existingIconId) {
      getAndSetIconUrl(existingIconId);
    }
  }, [existingIconId]);

  if (!currentAccount) {
    return null;
  }

  return (
    <Container>
      <UploadCircle
        id="logo-icon"
        loading={loading}
        imageUrl={iconUrl || null}
        hoverText={formatMessage({ id: 'UploadIcon' })}
        defaultBg={loading ? undefined : currentAccount?.color}
        defaultText={getFirstCharacter(company.name)}
        onImageLoad={() => {
          setLoading(false);
        }}
        onChange={async (file) => {
          setLoading(true);

          let hasError = false;
          let iconId;

          if (file) {
            iconId = uuid();

            if (!allowedLogoMimeTypes.includes(file.type as MimeType)) {
              notification.error({
                message: formatMessage({
                  id: 'ThereIsProblemUploadingYourImage',
                }),
                description: formatMessage({
                  id: 'TrySavingItInDifferentFormatAndUploadAgain',
                }),
              });

              setLoading(false);

              return;
            }

            const resultAction = await dispatch(
              generalActions.uploadFile({
                file: {
                  id: `identity/icon/${iconId}`,
                  name: 'company-icon',
                  file,
                  updatedAt: dateUTC().format(config.apiDateFormat),
                  contentType: file.type,
                },
                source: FileSourceType.CompanyFile,
              })
            );

            if (generalActions.uploadFile.fulfilled.match(resultAction)) {
              const success = await getAndSetIconUrl(iconId);
              hasError = !success;
            } else {
              hasError = true;
            }
          } else {
            iconId = null;

            if (currentAccount.company.iconId) {
              const resultAction = await dispatch(
                generalActions.deleteFile('identity/icon')
              );

              if (generalActions.deleteFile.rejected.match(resultAction)) {
                hasError = true;
              }
            } else {
              hasError = true;

              Logger.captureException(
                new Error('icon deletion: iconId is null in the account')
              );
            }

            setIconUrl(null);
          }

          if (hasError) {
            notification.error({
              message: formatMessage({
                id: file
                  ? 'ErrorWhileUploadingImage'
                  : 'ErrorWhileDeletingImage',
              }),
            });
          } else {
            dispatch(
              accountActions.updateAccount({
                accountId: currentAccount.id,
                localOnly: true,
                update: {
                  company: {
                    ...currentAccount?.company,
                    iconId,
                  },
                },
              })
            );
          }

          setLoading(false);
        }}
      />
    </Container>
  );
};

export default UploadIcon;
