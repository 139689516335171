import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { delay } from '@repo/shared/utils';
import { Modal } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';

import LibraryModal from '@components/shared/ant/Modal/Modal';

interface IProps {
  onExpireButtonClick: (ids: string[]) => Promise<boolean>;
  descLangId: string;
}

const ConfirmExpireModal: React.FC<IProps> = ({
  onExpireButtonClick,
  descLangId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const modalData = useSelector(generalSelectors.getModal(Modal.ConfirmExpire));

  function closeModal() {
    dispatch(generalActions.hideModal(Modal.ConfirmExpire));
  }

  let ids: string[] = [];

  if (selectedTableRowKeys.length > 0) {
    ids = selectedTableRowKeys;
  } else if (Array.isArray(modalData.data)) {
    ids = modalData.data;
  }

  return (
    <LibraryModal
      title={formatMessage({ id: 'MarkAsExpired' })}
      open={modalData.opened}
      width={390}
      confirmLoading={loading}
      onOk={async () => {
        setLoading(true);

        const success = await onExpireButtonClick(ids);

        if (success) {
          if (selectedTableRowKeys.length > 0) {
            await delay(250);
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        }

        setLoading(false);
      }}
      okText={formatMessage({ id: 'Expire' })}
      okButtonBackground={colors.gray10}
      onCancel={() => {
        closeModal();
      }}
    >
      {formatMessage({ id: descLangId }, { count: ids.length })}
    </LibraryModal>
  );
};

export default ConfirmExpireModal;
