import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { getAuditorAssignmentTypeLangId } from '@application/SchedulePlans/utils/getAuditorAssignmentTypeLangId';
import { colors } from '@repo/shared/config';
import { IConcise } from '@repo/shared/types';
import { TagType } from '@repo/shared/enums';

import TagList from '@components/shared/TagList/TagList';
import { AuditorAssignmentType } from '@domain/Schedules/enums/AuditorAssignmentType';

export function useAssignmentTypeDefinition() {
  const { formatMessage } = useIntl();

  const { data, loading } = useSelector(
    schedulePlansSelectors.getSchedulePlanDetails
  );

  return useMemo(() => {
    if (loading || !data) {
      return null;
    }

    const label = formatMessage({
      id: getAuditorAssignmentTypeLangId(data.auditorAssignmentType),
    });
    let value: IConcise[] = [];

    switch (data.auditorAssignmentType) {
      case AuditorAssignmentType.AssignToSpecificAuditor:
        value = data.assignedUsers;
        break;
      case AuditorAssignmentType.EvenlyDistributedAuditorFromGroup:
      case AuditorAssignmentType.RandomAssignedAuditorFromGroup:
        value = data.assignedUserGroups;
        break;
      case AuditorAssignmentType.NoAuditorAssigned:
      default:
        value = [];
        break;
    }

    return {
      label,
      value: (
        <TagList
          tags={value.map((userOrGroup) => ({
            ...userOrGroup,
            color: colors.gray7,
            tagType: TagType.User,
          }))}
        />
      ),
    };
  }, [loading, JSON.stringify(data)]);
}
