import React from 'react';

import { IAssignee, IConcise } from '@repo/shared/types';
import { Container } from './styled';
import { hasOwnProperty } from '@repo/shared/utils';

interface IProps {
  show?: boolean;
  assignee: IAssignee;
}

const JobTitlesList: React.FC<React.PropsWithChildren<IProps>> = ({
  show,
  assignee,
}) => {
  if (
    !show ||
    !hasOwnProperty<IAssignee, 'jobTitles', IConcise[]>(
      assignee,
      'jobTitles'
    ) ||
    assignee.jobTitles.length === 0
  ) {
    return null;
  }

  return (
    <Container>
      {assignee.jobTitles.map(({ name }) => name).join(', ')}
    </Container>
  );
};

export default JobTitlesList;
