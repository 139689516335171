import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountSelectors } from '@store';
import { colors } from '@repo/shared/config';

import LibraryModal from '@components/shared/ant/Modal/Modal';

interface IProps {}

const NewVersionReleasedModal: React.FC<IProps> = () => {
  const { formatMessage } = useIntl();

  const opened = useSelector(accountSelectors.isNewVersionReleaseModalOpened);

  return (
    <LibraryModal
      title={formatMessage({ id: 'NewVersionReleasedModalTitle' })}
      open={opened}
      width={390}
      closable={false}
      onOk={() => {
        window.location.reload();
      }}
      okText={formatMessage({ id: 'Reload' })}
      okButtonBackground={colors.gray10}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.95)',
          transition: 'none',
        },
      }}
    >
      {formatMessage({ id: 'NewVersionReleasedModalBody' })}
    </LibraryModal>
  );
};

export default NewVersionReleasedModal;
