import styled from 'styled-components';
import AntProgress from 'antd/es/progress';

import { UploadStatus } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  margin-bottom: 4px;

  ${({ status }: { status: UploadStatus }) => {
    if (status === UploadStatus.Error) {
      return `
          color: ${colors.red};
          
          svg {
            color: ${colors.red};
          }
          
          .ant-progress-status-error .ant-progress-bg {
              background-color: ${colors.red}!important;
          }
      `;
    }

    if (status === UploadStatus.Done) {
      return `
          color: ${colors.green};
          
          svg {
            color: ${colors.green};
          }
          
          .ant-progress-status-success .ant-progress-bg {
              background-color: ${colors.green}!important;
          }
      `;
    }

    if (status === UploadStatus.Pending) {
      return `
          color: ${colors.gray8};
          
          svg {
            color: ${colors.gray8};
          }
          
          .ant-progress-status-success .ant-progress-bg {
              background-color: ${colors.gray8}!important;
          }
      `;
    }

    return '';
  }}

  .ant-progress-bg {
    height: 1px !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  transition: all 0.2s ease-in-out;
  margin: 0 10px;
  font-size: 14px;
  line-height: 1.3;
  word-break: break-all;
  flex-grow: 1;
`;

export const Footer = styled.div``;

export const Progress = styled(AntProgress)`
  &.ant-progress-line {
    display: block;
    font-size: 6px;
  }
`;

export const RemoveBtn = styled.button`
  transition: all 0.2s ease-in-out;
  padding: 0;
  background: none;
  border: none;
  line-height: 22px;

  svg {
    display: block;
    width: 14px;
    height: 14px;
    color: ${colors.gray10};
  }
`;

export const Error = styled.div`
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 10px;
  line-height: 1;
  margin-left: 25px;
  color: #ff4d4f;
`;
