import { getInitialFilters } from '@utils';

import { AuditTemplateType } from '@repo/shared/enums';

export function getInitialAuditTemplatesFilters() {
  return {
    ...getInitialFilters(),
    templateType: AuditTemplateType.Audit,
  };
}
