import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { useAppDispatch, usePrevious } from '@hooks';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { getAuditStatusFromAuditsPage } from '@application/Audits/utils/getAuditStatusFromAuditsPage';
import { getRoutesFromAuditPage } from '@application/Audits/utils/getRoutesFromAuditPage';

interface IProps {
  page: AuditsPage;
}

const ViewAuditModalRouteHandler: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const modalState = useSelector(auditsSelectors.getViewAuditModalState);

  const prevStateAuditId = usePrevious(
    modalState.opened ? modalState.auditId : null
  );
  const prevParamAuditId = usePrevious(id);

  useEffect(() => {
    if (!prevStateAuditId && !prevParamAuditId && !modalState.opened && id) {
      dispatch(
        auditsActions.toggleViewAuditModal({
          opened: true,
          auditId: id,
          status: getAuditStatusFromAuditsPage(page),
        })
      );
    } else if (modalState.opened && !id) {
      navigate(
        getRoutesFromAuditPage(page).single.replace(':id', modalState.auditId)
      );
    } else if (!modalState.opened && id) {
      navigate(getRoutesFromAuditPage(page).list);
    }
  }, [modalState.opened, id, page]);

  return null;
};

export default ViewAuditModalRouteHandler;
