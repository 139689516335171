import React from 'react';
import { useIntl } from 'react-intl';

import { Badge, PopoverContent } from './styled';

import Popover from '@components/shared/ant/Popover';

interface IProps {
  userName?: string;
  auditObjectName: string;
}

const NonParticipantBadge: React.FC<React.PropsWithChildren<IProps>> = ({
  userName,
  auditObjectName,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Popover
      content={
        <PopoverContent>
          {formatMessage(
            {
              id: 'TheUserIsNotListedAParticipantForThisAuditObject',
            },
            {
              userName: userName || formatMessage({ id: 'ThisUser' }),
              auditObjectName,
            }
          )}
        </PopoverContent>
      }
    >
      <Badge>{formatMessage({ id: 'NonParticipant' })}</Badge>
    </Popover>
  );
};

export default NonParticipantBadge;
