import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { auditsActions } from '@application/Audits/store/auditsActions';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { delay } from '@repo/shared/utils';
import { routes } from '@config';
import { AssigneesModalContext } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { AuditsPage } from '@application/Audits/models/AuditsPage';
import { getAuditStatusFromAuditsPage } from '@application/Audits/utils/getAuditStatusFromAuditsPage';

import AddEditAuditModal from '@presentation/Audits/CreateOneTimeAuditModal/AddEditAuditModal';

interface IProps {
  page: AuditsPage.My | AuditsPage.Pending;
}

const CreateOneTimeAuditModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const opened = useSelector(auditsSelectors.isCreateOneTimeAuditModalOpened);

  return (
    <AddEditAuditModal
      opened={opened}
      onCancel={() => {
        dispatch(auditsActions.toggleCreateOneTimeAuditModal(false));
      }}
      onSave={async ({ auditId }) => {
        if (!auditId) {
          return;
        }

        dispatch(auditsActions.toggleCreateOneTimeAuditModal(false));
        dispatch(auditsActions.getAudits());

        const route =
          page === AuditsPage.My ? routes.myAudit : routes.pendingAudit;

        navigate(route.replace(':id', auditId));
      }}
      assigneesContext={AssigneesModalContext.Audit}
    />
  );
};

export default CreateOneTimeAuditModal;
