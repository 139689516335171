import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import { usersActions, usersSelectors, generalActions } from '@store';
import { Footer } from './styled';
import { disallowURL, notification } from '@utils';

import { useAppDispatch } from '@hooks';

import SlideModal from '../../../../shared/SideModal/SideModal';
import Form from '../../../../shared/ant/Form';
import { Input } from '../../../../shared/ant/Input';
import Button from '../../../../shared/ant/Button';
import { colors } from '@repo/shared/config';

const AddEditUserGroupModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { visible, groupId } = useSelector(
    usersSelectors.groups.getAddEditGroupModalState,
    shallowEqual
  );
  const groups = useSelector(usersSelectors.groups.getMap);
  const closeModal = useCallback(
    () =>
      dispatch(usersActions.groups.toggleAddEditGroupModal({ visible: false })),
    []
  );

  const isEdit = groupId !== null;
  const group = (groupId && groups[groupId]) || null;

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, group]);

  const itemName = formatMessage({ id: 'UserGroup' }).toLowerCase();

  return (
    <SlideModal
      opened={visible}
      onModalClose={() => closeModal()}
      title={formatMessage({
        id: isEdit ? 'EditGroup' : 'CreateGroup',
      })}
      footer={
        <Footer>
          <Space size={10}>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form?.submit()}
            >
              {isEdit
                ? formatMessage({ id: 'Save' })
                : formatMessage({ id: 'CreateGroup' })}
            </Button>
            <Button
              type="default"
              onClick={() => {
                form?.resetFields();
                setTimeout(() => {
                  closeModal();
                }, 50);
              }}
            >
              {formatMessage({ id: 'Cancel' })}
            </Button>
          </Space>
          {isEdit && (
            <Button
              type="primary"
              style={{ background: colors.red }}
              onClick={() => {
                dispatch(
                  usersActions.groups.toggleConfirmDeleteGroupsModal([groupId])
                );
              }}
            >
              {formatMessage({ id: 'DeleteGroup' })}
            </Button>
          )}
        </Footer>
      }
    >
      <Form
        layout="vertical"
        onFinish={async ({ userIds, name }: any) => {
          setLoading(true);

          const resultAction = await dispatch(
            usersActions.groups.addEditUserGroup({
              id: group?.id,
              name,
              userIds,
            })
          );

          if (
            usersActions.groups.addEditUserGroup.fulfilled.match(resultAction)
          ) {
            closeModal();
            dispatch(usersActions.groups.getData());
            dispatch(generalActions.getConciseUserGroups());

            notification.success({
              message: isEdit
                ? formatMessage(
                    { id: 'YouHaveSuccessfullyEditedItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'YouHaveSuccessfullyCreatedItem' },
                    { item: itemName }
                  ),
            });
          } else {
            notification.error({
              message: isEdit
                ? formatMessage(
                    { id: 'ErrorWhileCreatingItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'ErrorWhileEditingItem' },
                    { item: itemName }
                  ),
              description: resultAction.payload as string,
            });
          }

          setLoading(false);
        }}
        form={form}
        initialValues={{
          name: group?.name || '',
          userIds: group?.userIds || [],
        }}
      >
        <Form.Item
          name="name"
          label={formatMessage({ id: 'GroupTitle' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
            {
              max: 300,
              message: formatMessage(
                {
                  id: 'NameMustNotExceedXCharacters',
                },
                { count: 300 }
              ),
            },
            disallowURL(),
          ]}
        >
          <Input
            disabled={loading}
            placeholder={formatMessage({ id: 'EnterTitle' })}
          />
        </Form.Item>
      </Form>
    </SlideModal>
  );
};

export default AddEditUserGroupModal;
