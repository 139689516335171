import Space from 'antd/es/space';
import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const FilterGroupHeader = styled.div`
  color: ${colors.gray8};
  margin: 25px 0 12px;
`;

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const ClearBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  color: ${colors.blue2};
  background: none;
`;

export const FilterQty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: ${colors.white};
  border-radius: 100%;
  font-weight: 600;
  margin-left: 6px;
  background: ${colors.blue2};
`;
