import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

import LibraryTabs from '@components/shared/ant/Tabs';

export const Status = styled.div`
  font-size: 14px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > * {
    flex-basis: auto;
  }
`;

interface FooterButtonProps {
  color: string;
}

const footerButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0;
  color: ${colors.white};
  height: 44px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 44px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background: ${({ color }: FooterButtonProps) => color};

  &:hover {
    color: ${colors.white};
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: ${colors.white};
  }

  &:disabled {
    background: ${({ color }: FooterButtonProps) => hexToRGB(color, 0.6)};
  }
`;

export const FooterButton = styled.button`
  ${footerButtonStyles}
`;

export const FooterLink = styled(Link)`
  ${footerButtonStyles}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const ErrorText = styled.div`
  font-size: 15px;
  line-height: 21px;
  width: 250px;
  text-align: center;
  margin: 0 auto;

  svg {
    width: 30px;
    height: 30px;
    color: ${colors.red};
    margin-bottom: 10px;
  }
`;

export const Tabs = styled(LibraryTabs)`
  .ant-tabs-tab {
    width: 50%;
  }
`
