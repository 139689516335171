import React from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountActions, accountSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { notification } from '@utils';
import { colors } from '@repo/shared/config';
import { Note } from './styled';

import Modal from '../../shared/ant/Modal/Modal';

const SampleDataModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { visible, operation } = useSelector(
    accountSelectors.getSampleDataModal
  );

  function closeModal() {
    dispatch(accountActions.toggleSampleDataModal({ visible: false }));
  }

  const isAddOperation = operation === 'add';

  return (
    <Modal
      title={formatMessage({
        id: isAddOperation ? 'AddSampleData' : 'DeleteSampleData',
      })}
      open={visible}
      width={390}
      onOk={async () => {
        dispatch(accountActions.toggleFullScreenLoader(true));

        const action = isAddOperation
          ? accountActions.addSampleData
          : accountActions.deleteSampleData;

        const resultAction = await dispatch(action());

        if (action.fulfilled.match(resultAction)) {
          closeModal();
          await dispatch(accountActions.getAppData());

          notification.success({
            message: formatMessage({
              id: isAddOperation
                ? 'SampleDataHasBeenSuccessfullyAdded'
                : 'SampleDataHasBeenSuccessfullyDeleted',
            }),
          });
        } else {
          notification.error({
            message: formatMessage({
              id: isAddOperation
                ? 'ErrorWhileAddingSampleData'
                : 'ErrorWhileDeletingSampleData',
            }),
          });
        }

        dispatch(accountActions.toggleFullScreenLoader(false));
      }}
      okText={formatMessage({ id: isAddOperation ? 'Add' : 'Delete' })}
      okButtonBackground={colors.gray10}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage({
        id: isAddOperation
          ? 'AreYouSureYouWantToAddSampleData'
          : 'AreYouSureYouWantToDeleteAllSampleData',
      })}
      {!isAddOperation && (
        <Note>
          {formatMessage({ id: 'YouCanReCreateItInGettingStartedMenu' })}
        </Note>
      )}
    </Modal>
  );
};

export default SampleDataModal;
