import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'antd/es/modal';
import { X } from 'react-feather';

import { useAppDispatch } from '@hooks';
import { generalActions } from '@store';
import { Container } from './styled';
import { IFile } from '@repo/shared/types';
import { delay } from '@repo/shared/utils';
import { FileSourceType } from '@repo/shared/enums';

import UploadFiles, {
  IUploadsContainerRef,
} from '@components/shared/UploadFiles/UploadFiles';

interface IProps {
  disabled?: boolean;
  onAddFiles: (files: IFile[]) => void;
  onRemoveFile?: (fileId: string) => void;
  e2eDataAttr?: string;
  getUploadPath?: (fileId: string) => string;
  skipCompanyId?: boolean;
  source: FileSourceType;
}

const UploadFilesTrigger: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  onAddFiles,
  onRemoveFile,
  children,
  e2eDataAttr,
  getUploadPath,
  skipCompanyId,
  source,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const uploadsContainerRef = useRef<IUploadsContainerRef | null>(null);

  const [open, setOpen] = useState(false);

  async function closeModal() {
    setOpen(false);
    await delay(100);
    dispatch(generalActions.resetFileUploads());
  }

  return (
    <>
      <div
        onClick={() => {
          if (disabled) {
            return;
          }

          dispatch(generalActions.resetFileUploads());
          setOpen(true);
        }}
      >
        {children}
      </div>
      <Modal
        open={open}
        closeIcon={<X />}
        footer={null}
        title={formatMessage({ id: 'UploadFiles' })}
        onCancel={async () => {
          if (uploadsContainerRef.current) {
            await uploadsContainerRef.current?.cancel({
              onFinishCancel: async () => {
                await closeModal();
              },
            });
          }
        }}
        className="file-uploader-modal"
        width={650}
        closable
        maskClosable
        destroyOnClose
        styles={{
          body: { marginBottom: 0 },
        }}
      >
        <Container>
          <UploadFiles
            ref={uploadsContainerRef}
            e2eDataAttr={e2eDataAttr}
            onUploadFinished={onAddFiles}
            source={source}
            active={open}
            onRemoveFile={onRemoveFile}
            disabled={disabled}
            getUploadPath={getUploadPath}
            skipCompanyId={skipCompanyId}
          />
        </Container>
      </Modal>
    </>
  );
};

export default UploadFilesTrigger;
