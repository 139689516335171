import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const AuditObject = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M19 10.605H18.5H19ZM14.3708 20L14.716 20.3618L14.3708 20ZM9.62949 20L9.28437 20.3618L9.62949 20ZM12 21L12 20.5L12 21ZM13.8333 10C13.8333 11.0125 13.0125 11.8333 12 11.8333V12.8333C13.5648 12.8333 14.8333 11.5648 14.8333 10H13.8333ZM12 11.8333C10.9875 11.8333 10.1667 11.0125 10.1667 10H9.16667C9.16667 11.5648 10.4352 12.8333 12 12.8333V11.8333ZM10.1667 10C10.1667 8.98748 10.9875 8.16667 12 8.16667V7.16667C10.4352 7.16667 9.16667 8.43519 9.16667 10H10.1667ZM12 8.16667C13.0125 8.16667 13.8333 8.98748 13.8333 10H14.8333C14.8333 8.43519 13.5648 7.16667 12 7.16667V8.16667ZM18.5 10.605C18.5 14.3329 16.0195 17.7361 14.0257 19.6382L14.716 20.3618C16.7834 18.3895 19.5 14.7402 19.5 10.605H18.5ZM9.97462 19.6382C7.98072 17.7361 5.5 14.3329 5.5 10.605H4.5C4.5 14.7402 7.21687 18.3895 9.28437 20.3618L9.97462 19.6382ZM5.5 10.605C5.5 8.19184 6.32937 6.42502 7.53483 5.2609C8.74633 4.09095 10.3683 3.5 12 3.5C13.6317 3.5 15.2537 4.09096 16.4652 5.26092C17.6706 6.42504 18.5 8.19186 18.5 10.605H19.5C19.5 7.94811 18.5794 5.91244 17.1598 4.54158C15.7463 3.17655 13.8683 2.50001 12 2.5C10.1317 2.5 8.25367 3.17653 6.84017 4.54156C5.42063 5.91242 4.5 7.94809 4.5 10.605H5.5ZM14.0257 19.6382C13.4346 20.2021 12.707 20.5 12 20.5L12 21.5C12.9892 21.5001 13.9579 21.085 14.716 20.3618L14.0257 19.6382ZM12 20.5C11.2931 20.5 10.5657 20.202 9.97462 19.6382L9.28437 20.3618C10.0423 21.0849 11.0109 21.4999 12 21.5L12 20.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default AuditObject;
