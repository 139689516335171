import { useMemo } from 'react';

import {
  AuditsGenerationMode,
  Schedule,
} from '@domain/Schedules/models/Schedule';
import { ScheduleFormValues } from '@application/Schedules/models/ScheduleFormValues';
import { transformScheduleToFormValues } from '@application/Schedules/utils/transformScheduleToFormValues';
import { getDefaultFormRepeatValues } from '@application/Schedules/utils/getDefaultFormRepeatValues';
import { SchedulePlan } from '@domain/SchedulePlans/models/SchedulePlan';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';
import { AuditorAssignmentType } from '@domain/Schedules/enums/AuditorAssignmentType';

export function useInitialScheduleFormValues(
  schedule?: Schedule | null,
  schedulePlan?: SchedulePlan | null
): Omit<ScheduleFormValues, 'templateId'> & { templateId: string | null } {
  return useMemo(() => {
    if (schedule) {
      return transformScheduleToFormValues(schedule);
    }

    const auditRepeatPattern = AuditRepeatPattern.Daily;

    return {
      name: '',
      templateId: null,
      auditObjectAndGroupIds: {
        entityIds: [],
        groupIds: [],
      },
      auditsGenerationMode: AuditsGenerationMode.AuditPerLocation,
      auditorAssignmentType: AuditorAssignmentType.NoAuditorAssigned,
      maxAuditsQty: '',
      assignedUsersIds: [],
      assignedUserGroupsIds: [],
      auditRepeatPattern,
      evenlyDistributed: false,
      auditorHint: '',
      repeat: {
        ...getDefaultFormRepeatValues(auditRepeatPattern),
      },
    };
  }, [JSON.stringify(schedule || {}), JSON.stringify(schedulePlan || {})]);
}
