import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'antd/es/skeleton';
import { useIntl } from 'react-intl';

import {
  Container,
  IssueName,
  IssueType,
  DefinitionsRow,
  DefinitionValueHolder,
} from './styled';
import { issuesSelectors } from '@application/Issues/store';
import { config, colors } from '@repo/shared/config';
import { accountSelectors } from '@store';
import { e2eTestElements } from '@config';

import Definition from '@components/shared/Definition/Definition';
import ZonedDateTime from '@components/shared/ZonedDateTime';
import IssueStatusName from '@src/presentation/Issues/IssueStatusName';

interface IProps {}

const IssueDetailsTagline: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();

  const loading = useSelector(issuesSelectors.getIssueDetailsLoading);
  const issue = useSelector(issuesSelectors.getDetailsIssue);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const createdByFromContactInformation = useSelector(
    issuesSelectors.getIssueCreatedByContactInformation
  );

  if (!loading && !issue) {
    return null;
  }

  return (
    <Container>
      <IssueName data-e2e={e2eTestElements.issues.issues.details.title}>
        {loading ? (
          <Skeleton.Input active style={{ borderRadius: 5 }} />
        ) : (
          issue?.name
        )}
      </IssueName>
      <IssueType data-e2e={e2eTestElements.issues.issues.details.subTitle}>
        {loading ? (
          <Skeleton.Input active size="small" style={{ borderRadius: 5 }} />
        ) : (
          issue?.type.name
        )}
      </IssueType>
      <DefinitionsRow>
        {!!issue?.number && (
          <Definition
            label={formatMessage({ id: 'Number' })}
            e2eDataAttr={e2eTestElements.issues.issues.details.number}
            horizontal
            value={
              <DefinitionValueHolder>
                {loading ? (
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ borderRadius: 5 }}
                  />
                ) : (
                  issue.number
                )}
              </DefinitionValueHolder>
            }
          />
        )}
        <Definition
          label={auditObjectName.single}
          e2eDataAttr={e2eTestElements.issues.issues.details.object}
          horizontal
          value={
            <DefinitionValueHolder>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                <>{issue?.auditObject.name}</>
              )}
            </DefinitionValueHolder>
          }
        />
        <Definition
          label={formatMessage({ id: 'Status' })}
          e2eDataAttr={e2eTestElements.issues.issues.details.status}
          horizontal
          value={
            <DefinitionValueHolder>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                <IssueStatusName status={issue?.status!} />
              )}
            </DefinitionValueHolder>
          }
        />
        <Definition
          label={
            loading || issue?.createdBy.name
              ? formatMessage({ id: 'CreatedBy' })
              : `${formatMessage({
                  id: createdByFromContactInformation
                    ? 'CreatedViaPublicFormBy'
                    : 'CreatedViaPublicForm',
                })}`
          }
          e2eDataAttr={e2eTestElements.issues.issues.details.createdBy}
          horizontal
          value={
            <DefinitionValueHolder>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                <>
                  {(issue?.createdBy.name ||
                    createdByFromContactInformation) && (
                    <div
                      style={{
                        display: 'inline-block',
                        margin: '0 7px 0 -2px',
                      }}
                    >
                      {issue?.createdBy.name || createdByFromContactInformation}
                    </div>
                  )}
                  <span style={{ color: colors.gray8, textIndent: '-2px' }}>
                    {formatMessage({ id: 'on' })}{' '}
                    <ZonedDateTime
                      dateTime={issue?.createDate!}
                      format={config.dateTimeFormat}
                    />
                  </span>
                </>
              )}
            </DefinitionValueHolder>
          }
        />
      </DefinitionsRow>
    </Container>
  );
};

export default IssueDetailsTagline;
