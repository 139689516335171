import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { getInitialAuditObjectsFilters } from '@store/modules/auditObjects/auditObjects.reducer';

import PageHeader from '../../../shared/ant/PageHeader';
import SearchInput from '../../../shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';
import FiltersButton from '@components/shared/FiltersButton/FiltersButton';

const AuditObjectsHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const selectedFiltersQty = useSelector(
    auditObjectsSelectors.getSelectedFiltersQty
  );
  const filters = useSelector(auditObjectsSelectors.getFilters);

  return (
    <PageHeader
      title={auditObjectName.plural}
      extra={
        <Space>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                auditObjectsActions.objects.getData({ search, pageNumber: 1 })
              )
            }
          />
          <FiltersButton
            showModal={() =>
              dispatch(auditObjectsActions.objects.toggleFiltersModal(true))
            }
            resetFilters={() => {
              dispatch(
                auditObjectsActions.objects.getData(
                  getInitialAuditObjectsFilters()
                )
              );
            }}
            selectedFilters={selectedFiltersQty}
          />
          {!isLocationsReadonly && canManageAuditObjects && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(auditObjectsActions.objects.showAddEditModal());
              }}
              data-e2e={e2eTestElements.objects.createObject}
            >
              {formatMessage(
                {
                  id: 'NewAuditObject',
                },
                { auditObjectName: auditObjectName.single }
              )}
            </Button>
          )}
        </Space>
      }
    />
  );
};

export default AuditObjectsHeader;
