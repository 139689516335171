export enum ItemType {
  Root,
  Section,
  Item,
  ConditionalItem,
  Condition,
}

export enum AnswerType {
  PassFailButtons,
  Numeric,
  Checklist,
  YesNoButtons,
  Text,
  Dropdown,
  Temperature,
  Slider,
  Datetime,
}

export enum ChecklistOptionName {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export enum TemperatureUnit {
  C,
  F,
}

export enum IntervalType {
  NotSet,
  LessThan,
  LessThanOrEqualTo,
  EqualTo,
  GreaterThan,
  GreaterThanOrEqualTo,
}

export enum IntervalFieldType {
  FromIntervalType = 'fromIntervalType',
  FromNumber = 'fromNumber',
  ToIntervalType = 'toIntervalType',
  ToNumber = 'toNumber',
  Points = 'points',
  MarkAsFailed = 'markAsFailed',
}

export enum TriggerType {
  Passed = 0,
  Failed = 1,
  IsAnswered = 2,
}

export enum ActionType {
  RequirePhoto = 0,
  RequireAction = 1,
  AddItems = 2,
}
