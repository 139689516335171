import React, { useCallback } from 'react';
import Modal from 'antd/es/modal';
import { useSelector } from 'react-redux';
import { X } from 'react-feather';

import { accountSelectors, generalActions, generalSelectors } from '@store';
import { Container, Content } from './styled';
import { useAppDispatch, useOpacityTransition } from '@hooks';
import { FeedbackEvent, FeedbackModalPage } from '@repo/shared/enums';

import LeaveFeedback from '@components/shared/FeedbackModal/LeaveFeedback';
import Capterra from '@components/shared/FeedbackModal/Capterra';
import ThankYou from '@components/shared/FeedbackModal/ThankYou/ThankYou';

const FeedbackModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  const page = useSelector(generalSelectors.feedbackModal.getPage);
  const opacityTransition = useOpacityTransition(page);
  const rating = useSelector(generalSelectors.feedbackModal.getRating);
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  const closeModal = useCallback(() => {
    if (page === FeedbackModalPage.LeaveFeedback) {
      dispatch(
        generalActions.feedbackModal.saveFeedback({
          event: FeedbackEvent.AskMeLater,
          rating,
        })
      );
    }

    dispatch(generalActions.feedbackModal.setPage(null));
  }, [rating, page]);

  if (!appConfiguration.allowFeedbackModal) {
    return null;
  }

  return (
    <Modal
      open={page !== null}
      closable
      closeIcon={<X />}
      width={375}
      styles={{
        body: { marginBottom: 0 },
      }}
      footer={null}
      onCancel={() => {
        closeModal();
      }}
      className="modal-no-padding"
    >
      <Container>
        {opacityTransition.map(({ item, props, key }) => {
          if (item === FeedbackModalPage.LeaveFeedback) {
            return (
              <Content key={key} style={props}>
                <LeaveFeedback closeModal={closeModal} />
              </Content>
            );
          }

          if (item === FeedbackModalPage.Capterra) {
            return (
              <Content key={key} style={props}>
                <Capterra closeModal={closeModal} />
              </Content>
            );
          }

          if (item === FeedbackModalPage.ThankYou) {
            return (
              <Content key={key} style={props}>
                <ThankYou />
              </Content>
            );
          }

          return null;
        })}
      </Container>
    </Modal>
  );
};

export default FeedbackModal;
