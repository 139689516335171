import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.3;
  margin: -8px 0 16px;

  svg {
    margin-right: 8px;
  }
`;

export const MonthlySettings = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -5px;

  & > * {
    width: 50%;
    margin: 0 5px;
  }
`;
