import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CloseModalButton, Title } from './styled';
import { ArrowLeft } from '@icons';
import { generalSelectors } from '@store';
import { routes } from '@config';

import PageHeader from '@components/shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

export enum AttributePage {
  Details,
  Assignments,
}

interface IProps {
  page: AttributePage;
  extra?: React.ReactNode;
}

const AttributeContentWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  children,
  extra,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const attributesMap = useSelector(
    generalSelectors.getConciseAuditObjectAttributesMap
  );
  const { loading } = useSelector(
    generalSelectors.getConciseAuditObjectAttributes
  );

  useEffect(() => {
    if (!id || (!loading && !attributesMap[id])) {
      navigate(routes.auditObjects.attributesList);
      return;
    }
  }, [id, loading, attributesMap]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            <CloseModalButton
              type="button"
              onClick={() => {
                navigate(routes.auditObjects.attributesList);
              }}
            >
              <ArrowLeft />
            </CloseModalButton>

            {
              <Title>
                {loading ? (
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ borderRadius: 5 }}
                  />
                ) : id ? (
                  attributesMap[id]?.name
                ) : (
                  '-'
                )}
              </Title>
            }
          </Space>
        }
        extra={extra}
        footer={
          <PageTabbedSwitcher<AttributePage>
            pages={[
              {
                onClick() {
                  if (id) {
                    navigate(
                      routes.auditObjects.attributeDetails.replace(':id', id)
                    );
                  }
                },
                name: formatMessage({ id: 'Details' }),
                id: AttributePage.Details,
              },
              {
                onClick() {
                  if (id) {
                    navigate(
                      routes.auditObjects.attributeAssignments.replace(
                        ':id',
                        id
                      )
                    );
                  }
                },
                name: formatMessage({ id: 'Assignments' }),
                id: AttributePage.Assignments,
              },
            ]}
            activePage={page}
          />
        }
      />
      {children}
    </>
  );
};

export default AttributeContentWrapper;
