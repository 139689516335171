import { Priority } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';

export const getActionPriorityColor = (priority: Priority) => {
  if (priority === Priority.High) {
    return colors.red;
  }

  if (priority === Priority.Medium) {
    return colors.yellow;
  }

  return colors.blue2;
};
