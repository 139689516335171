import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getColumnSortProperties } from '@utils';
import { IAuditObjectGroupAssignment } from '@repo/shared/types';
import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
  generalSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission } from '@hooks/account';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';

import Table from '../../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../../shared/ant/EmptyTable/EmptyTable';
import GroupsAssignmentsActionsMenu from '@components/auditObjects/groups/single/AuditObjectGroupAssignments/GroupsAssignmentsTable/GroupsAssignmentsActionsMenu';

interface IProps {
  auditObjectId?: string;
  auditObjectGroupId?: string;
}

const GroupsAssignmentsTable: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  auditObjectGroupId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, error, data, meta } = useSelector(
    auditObjectsSelectors.groupAssignments.getData
  );
  const filters = useSelector(
    auditObjectsSelectors.groupAssignments.getFilters
  );

  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );
  const auditObjectGroupsMap = useSelector(
    generalSelectors.getConciseAuditObjectGroupsMap
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const canManageAuditsObjects = usePermission(
    Permission.CanManageAuditObjects
  );
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    return () => {
      dispatch(auditObjectsActions.groupAssignments.resetData());
    };
  }, []);

  return (
    <Table<any>
      onPageChange={(update) =>
        dispatch(auditObjectsActions.groupAssignments.getData(update))
      }
      canSelectRow={!isLocationsReadonly && canManageAuditsObjects}
      loading={loading}
      meta={meta}
      error={error}
      e2eDataAttr={e2eTestElements.objects.groupsAssignments.table}
      columns={[
        ...(!auditObjectGroupId
          ? [
              {
                title: formatMessage({ id: 'Group' }),
                dataIndex: 'groupName',
                key: 'groupName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'groupName'
                ),
              },
            ]
          : []),
        ...(!auditObjectId
          ? [
              {
                title: auditObjectName.single,
                dataIndex: 'auditObjectName',
                key: 'auditObjectName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'auditObjectName'
                ),
              },
            ]
          : []),
        ...(!isLocationsReadonly && canManageAuditsObjects
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (_: any, assignment: IAuditObjectGroupAssignment) => (
                  <GroupsAssignmentsActionsMenu assignmentId={assignment.id} />
                ),
              },
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((assignment: IAuditObjectGroupAssignment) => {
              return {
                key: assignment.id,
                id: assignment.id,
                groupName: auditObjectGroupsMap[assignment.groupId]?.name || '',
                auditObjectName:
                  auditObjectsMap[assignment.auditObjectId]?.name || '',
              };
            })
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {filters.search
                  ? formatMessage(
                      {
                        id: 'NoAuditObjectsGroupsAssignmentsSearchResults',
                      },
                      {
                        objectName: auditObjectName.plural.toLowerCase(),
                        searchStr: filters.search,
                      }
                    )
                  : formatMessage(
                      {
                        id: 'NoAuditObjectsGroupsAssignments',
                      },
                      { objectName: auditObjectName.plural.toLowerCase() }
                    )}
              </>
            }
            button={
              !isLocationsReadonly && canManageAuditsObjects
                ? {
                    text: `+ ${formatMessage({ id: 'AddToGroup' })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        auditObjectsActions.groupAssignments.toggleAddToGroupModal(
                          true
                        )
                      );
                    },
                  }
                : null
            }
            howItWorksUrl={config.urls.auditObjectGroupsSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          auditObjectsActions.groupAssignments.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default GroupsAssignmentsTable;
