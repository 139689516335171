import React, { useCallback, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { notification } from '@utils/notifications';
import { actionsActions, actionsSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '@components/shared/ant/Modal/Modal';

const DeleteActionCommentModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const modalState = useSelector(actionsSelectors.getDeleteActionCommentState);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    dispatch(actionsActions.toggleDeleteActionCommentModal(null));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'DeleteComment' })}
      open={modalState !== null}
      onOk={async () => {
        if (modalState === null) {
          return;
        }

        setLoading(true);

        const resultAction = await dispatch(
          actionsActions.deleteActionComment({
            actionId: modalState.actionId,
            commentId: modalState.commentId,
          })
        );

        if (!actionsActions.deleteActionComment.fulfilled.match(resultAction)) {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeleting' }),
          });
        }

        setLoading(false);

        closeModal();
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage({
        id: 'AreYouSureYouWantToDeleteThisCommentFromTheAction',
      })}
    </Modal>
  );
};

export default DeleteActionCommentModal;
