import {
  Modal,
  SelfGuidedTourStep,
  AuthEvent,
  Industry,
} from '@repo/shared/enums';
import { actionsActions, auditObjectsActions, generalActions } from '@store';
import { IAppDispatch } from '@src/core/frameworks/redux';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import { BroadcastChannel } from 'broadcast-channel';

export function cleanUpSelfGuidedTourStepChanges(
  dispatch: IAppDispatch,
  step: SelfGuidedTourStep | null
): void {
  switch (step) {
    case SelfGuidedTourStep.AddObjectForm:
      dispatch(auditObjectsActions.objects.hideAddEditModal());
      break;
    case SelfGuidedTourStep.AddUserFormRoles:
    case SelfGuidedTourStep.AddScheduleForm:
      dispatch(generalActions.hideModal(Modal.AddEdit));
      break;
    case SelfGuidedTourStep.ExportAuditReportPDF: {
      dispatch(auditsActions.toggleAuditReportModal({ opened: false }));
      break;
    }
    case SelfGuidedTourStep.ActionDetails:
      dispatch(actionsActions.resetActionDetailsData());
      break;
    case SelfGuidedTourStep.ViewAuditsReports: {
      dispatch(auditsActions.toggleViewAuditModal({ opened: false }));
      break;
    }
    default:
      break;
  }
}

export async function broadcastAuthEvent(event: AuthEvent) {
  const channel = new BroadcastChannel('auth-channel');
  await channel.postMessage(event);
}

const industryLangIds = {
  [Industry.Agriculture]: 'Agriculture',
  [Industry.Construction]: 'Construction',
  [Industry.Education]: 'Education',
  [Industry.Healthcare]: 'Healthcare',
  [Industry.Hotel]: 'Hotel',
  [Industry.Manufacturing]: 'Manufacturing',
  [Industry.Mining]: 'Mining',
  [Industry.OilAndGas]: 'OilAndGas',
  [Industry.PropertyManagement]: 'PropertyManagement',
  [Industry.Restaurant]: 'Restaurant',
  [Industry.Retail]: 'Retail',
  [Industry.SeniorCare]: 'SeniorCare',
  [Industry.Transportation]: 'Transportation',
  [Industry.Travel]: 'Travel',
  [Industry.IndustryNotListed]: 'IndustryNotListed',
};

export function getIndustryName(industry: Industry) {
  return intl.formatMessage({ id: industryLangIds[industry] });
}
