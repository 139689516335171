import xorWith from 'lodash/xorWith';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { AnyObject } from '@repo/shared/types';
import { Locale } from '@repo/shared/enums';

export const round = (value: number, decimals = 2) => {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
};

export function convertSecondsToHMS(seconds: number) {
  seconds = round(Number(seconds), 0);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + 'd ' : '';
  const hDisplay = h > 0 ? h + 'h ' : '';
  const mDisplay = m > 0 ? m + 'm ' : '';
  const sDisplay = d === 0 && h === 0 && m === 0 && s >= 0 ? s + 's ' : '';

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export const isArrayEqual = (x: AnyObject[], y: AnyObject[]) =>
  isEmpty(xorWith(x, y, isEqual));

export function hasOwnProperty<X extends {}, Y extends PropertyKey, Z>(
  obj: X,
  prop: Y
): obj is X & Record<Y, Z> {
  return obj.hasOwnProperty(prop);
}

export function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}${alpha !== undefined ? `, ${alpha}` : ''})`;
}

export function delay(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export const getLocaleFromString = (
  value?: string | null
): Locale | undefined => {
  switch (value?.toLowerCase()) {
    case 'en':
      return Locale.en;
    case 'es':
      return Locale.es;
    case 'fr':
      return Locale.fr;
    default:
      return undefined;
  }
};
