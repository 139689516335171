import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { routes } from '@config';

import { notification } from '@utils/notifications';
import {
  accountSelectors,
  usersActions,
  usersSelectors,
  rolesActions,
  generalActions,
  generalSelectors,
} from '@store';
import { useAppDispatch } from '@hooks';
import { getTablePageNumberAfterDeletion } from '@utils';

import ConfirmDeleteModal from '../../shared/ConfirmDeleteModal/ConfirmDeleteModal';

const DeleteUserModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const users = useSelector(usersSelectors.list.getMap);
  const { meta } = useSelector(usersSelectors.list.getData);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const loggedInUser = useSelector(accountSelectors.getCurrentUser);

  return (
    <ConfirmDeleteModal
      text={{
        title: formatMessage(
          { id: 'DeleteUsers' },
          { itemCount: selectedTableRowKeys.length }
        ),
        error: formatMessage(
          { id: 'ErrorWhileDeletingUsersGroups' },
          { itemCount: selectedTableRowKeys.length }
        ),
        desc: formatMessage(
          { id: 'YouAreAboutToRemoveUsersFromSystem' },
          { itemCount: selectedTableRowKeys.length }
        ),
      }}
      onSuccess={() => {
        dispatch(
          usersActions.list.getData({
            pageNumber: getTablePageNumberAfterDeletion(meta),
          })
        );
        dispatch(generalActions.getConciseUsers());
        dispatch(rolesActions.getRoles());
        navigate(routes.users.list);
      }}
      onSubmit={async (ids) => {
        if (loggedInUser?.id) {
          if (ids.includes(loggedInUser.id)) {
            notification.error({
              message: formatMessage({ id: 'ErrorWhileUserDeletion' }),
              description: formatMessage({ id: 'CantDeleteYourOwnUser' }),
            });
          }

          await dispatch(
            usersActions.list.deleteUsers(
              ids.filter((id) => id !== loggedInUser?.id)
            )
          );
        }
      }}
      data={users}
    />
  );
};

export default DeleteUserModal;
