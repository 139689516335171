import { createAsyncThunk } from '@reduxjs/toolkit';
import { IRootState } from '../frameworks/redux';
import { removeUndefinedAndNullProps } from './misc';
import { getErrorMessage } from '@repo/shared/utils';
import { Logger } from '@repo/shared/services';
import {
  IConfirmResponse,
  ICreateOrUpdateEntityRequest,
  IPagedResponse,
  RequestApiParams,
} from '@repo/shared/types';
import { HttpMethods } from '@repo/shared/enums';
import { notification } from './notifications';
import { InternalApiService } from '@repo/shared/api';

const apiService = InternalApiService.getInstance();

export function createGetEntitiesThunk<
  T,
  AnyFilters,
  Response = IPagedResponse<T>,
>(params: {
  apiUrl: string;
  entityName: string;
  method?: HttpMethods.get | HttpMethods.post;
  filtersSelector?: (state: IRootState) => AnyFilters;
}) {
  const { apiUrl, entityName, filtersSelector } = params;

  return createAsyncThunk<
    Response,
    Partial<AnyFilters> | undefined,
    { state: IRootState; rejectValue: string }
  >(`${entityName}/get`, async (update = {}, { rejectWithValue, getState }) => {
    const state = getState();

    const requestParams: RequestApiParams = {
      url: apiUrl,
    };

    const filters = filtersSelector ? filtersSelector(state) : {};
    const request = removeUndefinedAndNullProps({
      ...filters,
      ...update,
    });

    if (params.method === HttpMethods.post) {
      requestParams.body = request;
    } else {
      requestParams.query = request;
    }

    try {
      switch (params.method) {
        case HttpMethods.post:
          return apiService.post<Response>(requestParams);
        default:
          return apiService.get<Response>(requestParams);
      }
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  });
}

export function createAddOrUpdateEntityThunk<
  T extends ICreateOrUpdateEntityRequest,
>({ apiUrl, entityName }: { apiUrl: string; entityName: string }) {
  return createAsyncThunk<
    IConfirmResponse,
    Partial<T>,
    { rejectValue: string }
  >(`${entityName}/createOrUpdate`, async (payload, { rejectWithValue }) => {
    const createNew = !payload.id;

    try {
      const response = await apiService[createNew ? 'post' : 'put']({
        url: `${apiUrl}${createNew ? '' : `/${payload.id}`}`,
        body: payload,
      });

      return response as IConfirmResponse;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  });
}

export function createDeleteEntityThunk({
  apiUrl,
  entityName,
  showErrorNotification,
}: {
  apiUrl: string;
  entityName: string;
  showErrorNotification?: boolean;
}) {
  return createAsyncThunk<string[], string[], { rejectValue: string }>(
    `${entityName}/delete`,
    async (ids, { rejectWithValue }) => {
      try {
        await apiService.delete({
          url: apiUrl,
          body: {
            ids,
          },
        });

        return ids;
      } catch (e) {
        Logger.captureException(e);

        const error = getErrorMessage(e);

        if (showErrorNotification) {
          notification.error({
            message: error,
          });
        }

        return rejectWithValue(getErrorMessage(e));
      }
    }
  );
}
