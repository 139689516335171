import { Container, TabButton, Count } from './styled';

interface IProps<T> {
  pages: {
    onClick: () => void;
    id: T;
    name: string;
    e2eDataAttr?: string;
    count?: number;
  }[];
  activePage: T;
}

function PageTabbedSwitcher<T>({ pages, activePage }: IProps<T>) {
  return (
    <Container>
      {pages.map(({ id, name, onClick, count, e2eDataAttr }) => (
        <TabButton
          key={name}
          active={activePage === id}
          onClick={onClick}
          data-e2e={e2eDataAttr}
        >
          {name}
          {count !== undefined && <Count>{count}</Count>}
        </TabButton>
      ))}
    </Container>
  );
}

export default PageTabbedSwitcher;
