import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { auditObjectsActions, generalActions } from '@store';

import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import AddEditAuditObjectGroupModal from '@components/auditObjects/groups/list/AddEditAuditObjectGroupModal/AddEditAuditObjectGroupModal';
import DeleteAuditObjectGroupsModal from '@components/auditObjects/groups/list/DeleteAuditObjectGroupsModal';
import AuditObjectsGroupsTable from '@components/auditObjects/groups/list/AuditObjectsGroupsTable/AuditObjectsGroupsTable';
import AuditObjectsGroupsActionsModal from '@components/auditObjects/groups/list/AuditObjectsGroupsActionsModal';
import AuditObjectsGroupsHeader from '@components/auditObjects/groups/list/AuditObjectsGroupsHeader/AuditObjectsGroupsHeader';

interface IProps {}

const AuditObjectsGroups: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(auditObjectsActions.groups.getData());
    dispatch(generalActions.getConciseAuditObjectGroups());
  }, []);

  return (
    <>
      <SampleDataAlert />
      <AuditObjectsGroupsHeader />
      <AuditObjectsGroupsTable />
      <AuditObjectsGroupsActionsModal />
      <AddEditAuditObjectGroupModal />
      <DeleteAuditObjectGroupsModal />
    </>
  );
};

export default AuditObjectsGroups;
