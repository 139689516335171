import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import {
  auditObjectsActions,
  auditObjectsSelectors,
  generalSelectors,
  generalActions,
  accountSelectors,
} from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';
import { getTablePageNumberAfterDeletion } from '@utils';

import ConfirmDeleteModal from '../../shared/ConfirmDeleteModal/ConfirmDeleteModal';

const DeleteAuditObjectModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const auditObjects = useSelector(auditObjectsSelectors.getAuditObjectsLookUp);
  const { meta } = useSelector(auditObjectsSelectors.getAuditObjects);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  return (
    <ConfirmDeleteModal
      text={{
        title: formatMessage(
          { id: 'DeleteAuditObjects' },
          {
            objectName:
              selectedTableRowKeys.length > 1
                ? auditObjectName.plural.toLowerCase()
                : auditObjectName.single.toLowerCase(),
          }
        ),
        error: formatMessage(
          { id: 'ErrorWhileDeletingAuditObjects' },
          {
            objectName:
              selectedTableRowKeys.length > 1
                ? auditObjectName.plural.toLowerCase()
                : auditObjectName.single.toLowerCase(),
          }
        ),
        desc: formatMessage(
          { id: 'YouAreAboutToRemoveAuditObjectsFromSystem' },
          {
            objectName:
              selectedTableRowKeys.length > 1
                ? auditObjectName.plural.toLowerCase()
                : auditObjectName.single.toLowerCase(),
          }
        ),
      }}
      onSuccess={() => {
        dispatch(
          auditObjectsActions.objects.getData({
            pageNumber: getTablePageNumberAfterDeletion(meta),
          })
        );
        dispatch(generalActions.getConciseAuditObjects());
        dispatch(generalActions.getConciseAccessibleAuditObjects());
        navigate(routes.auditObjects.list);
      }}
      onSubmit={async (ids) => {
        const resultAction = await dispatch(
          auditObjectsActions.objects.deleteObjects(ids)
        );

        if (
          !auditObjectsActions.objects.deleteObjects.fulfilled.match(
            resultAction
          )
        ) {
          throw new Error(resultAction.payload as string);
        }
      }}
      data={auditObjects}
    />
  );
};

export default DeleteAuditObjectModal;
