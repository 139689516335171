import { createReducer, Reducer } from '@reduxjs/toolkit';
import range from 'lodash/range';

import { generalActions } from '../general/general.actions';
import { summaryReportActions as actions } from './summary-report.actions';
import {
  processAuditCompletionChartItems,
  processAuditPerformanceChartItems,
  round,
  date,
} from '@utils';
import {
  IAuditCompletionChartItem,
  ISectionPerformanceChartItem,
} from '@repo/shared/types';
import { ISummaryReportState } from '@types';
import {
  ComparableChart,
  CompareType,
  DateRangeType,
} from '@repo/shared/enums';
import { config, colors } from '@repo/shared/config';

const initialWidgetData = {
  data: null,
  error: null,
  loading: false,
};

const initialState = {
  filters: {
    templateId: null,
    auditObjectId: null,
    dateRangeType: DateRangeType.PriorMonth,
    startDate: date()
      .startOf('day')
      .subtract(1, 'month')
      .format(config.apiDateFormat),
    endDate: date().endOf('day').format(config.apiDateFormat),
    userIds: [],
    userGroupIds: [],
    tagsIds: [],
  },
  showShareViaEmailModal: false,
  compareModal: {
    chart: null,
    activeTab: CompareType.User,
  },
  totals: {
    ...initialWidgetData,
  },
  auditCompliance: {
    ...initialWidgetData,
  },
  scoreBreakdown: {
    ...initialWidgetData,
  },
  sectionPerformance: {
    comparables: {},
    nonZeroDataChartComparablesIds: [],
    page: 0,
    data: [],
    error: null,
    loading: false,
  },
  actionsAnalytics: {
    ...initialWidgetData,
  },
  auditPerformance: {
    comparables: {},
    ...initialWidgetData,
  },
  auditCompletion: {
    comparables: {},
    ...initialWidgetData,
  },
  auditDayOfWeekBreakdown: {
    data: [],
    error: null,
    loading: false,
  },
  auditCompletionTime: {
    data: {
      chart: [],
      ticks: [],
      averageTime: 0,
    },
    error: null,
    loading: false,
  },
};

export const summaryReportReducer: Reducer<ISummaryReportState> =
  createReducer<ISummaryReportState>(initialState, (builder) =>
    builder
      .addCase(actions.compareModal.show, (state, { payload }) => {
        state.compareModal = {
          chart: payload,
          activeTab: CompareType.User,
        };
      })
      .addCase(actions.compareModal.setActiveTab, (state, { payload }) => {
        state.compareModal.activeTab = payload;
      })
      .addCase(actions.compareModal.compare, (state, { payload }) => {
        switch (state.compareModal.chart) {
          case ComparableChart.AuditCompletion:
            state.auditCompletion.comparables = payload;
            break;
          case ComparableChart.SectionPerformance:
            state.sectionPerformance.comparables = payload;
            break;
          case ComparableChart.AuditPerformance:
            state.auditPerformance.comparables = payload;
            break;
          default:
            break;
        }
      })
      .addCase(
        actions.removeComparable,
        (state, { payload: { chart, id } }) => {
          switch (chart) {
            case ComparableChart.AuditCompletion:
              delete state.auditCompletion.comparables[id];
              break;
            case ComparableChart.SectionPerformance:
              delete state.sectionPerformance.comparables[id];
              break;
            case ComparableChart.AuditPerformance:
              delete state.auditPerformance.comparables[id];
              break;
            default:
              break;
          }
        }
      )
      .addCase(actions.compareModal.reset, (state) => {
        switch (state.compareModal.chart) {
          case ComparableChart.AuditCompletion:
            state.auditCompletion.comparables = {};
            break;
          case ComparableChart.SectionPerformance:
            state.sectionPerformance.comparables = {};
            break;
          case ComparableChart.AuditPerformance:
            state.auditPerformance.comparables = {};
            break;
          default:
            break;
        }
      })
      .addCase(actions.getTotals.pending, (state) => {
        state.totals.error = null;
        state.totals.data = null;
        state.totals.loading = true;
      })
      .addCase(actions.getTotals.fulfilled, (state, { payload }) => {
        state.totals.data = payload;
        state.totals.loading = false;
      })
      .addCase(actions.getTotals.rejected, (state, { payload }) => {
        state.totals.error = payload || null;
        state.totals.loading = false;
      })
      .addCase(actions.getAuditCompliance.pending, (state) => {
        state.auditCompliance.error = null;
        state.auditCompliance.data = null;
        state.auditCompliance.loading = true;
      })
      .addCase(actions.getAuditCompliance.fulfilled, (state, { payload }) => {
        state.auditCompliance.data = payload;
        state.auditCompliance.loading = false;
      })
      .addCase(actions.getAuditCompliance.rejected, (state, { payload }) => {
        // TODO clean up
        state.auditCompliance.data = {
          score: 80,
          previousScore: 65,
          scoreColor: colors.orange,
        };

        state.auditCompliance.error = payload || null;
        state.auditCompliance.loading = false;
      })
      .addCase(actions.getScoreBreakdown.pending, (state) => {
        state.scoreBreakdown.error = null;
        state.scoreBreakdown.data = null;
        state.scoreBreakdown.loading = true;
      })
      .addCase(actions.getScoreBreakdown.fulfilled, (state, { payload }) => {
        state.scoreBreakdown.data = payload;
        state.scoreBreakdown.loading = false;
      })
      .addCase(actions.getScoreBreakdown.rejected, (state, { payload }) => {
        state.scoreBreakdown.error = payload || null;
        state.scoreBreakdown.loading = false;
      })
      .addCase(actions.getActionsAnalytics.pending, (state) => {
        state.actionsAnalytics.error = null;
        state.actionsAnalytics.data = null;
        state.actionsAnalytics.loading = true;
      })
      .addCase(actions.getActionsAnalytics.fulfilled, (state, { payload }) => {
        state.actionsAnalytics.data = payload;
        state.actionsAnalytics.loading = false;
      })
      .addCase(actions.getActionsAnalytics.rejected, (state, { payload }) => {
        state.actionsAnalytics.error = payload || null;
        state.actionsAnalytics.loading = false;
      })
      .addCase(actions.getSectionPerformance.pending, (state) => {
        state.sectionPerformance.error = null;
        state.sectionPerformance.data = [];
        state.sectionPerformance.loading = true;
        state.sectionPerformance.nonZeroDataChartComparablesIds = [];
      })
      .addCase(
        actions.getSectionPerformance.fulfilled,
        (state, { payload }) => {
          const {
            items,
            compare,
          }: {
            items: ISectionPerformanceChartItem[];
            compare: any;
          } = payload;

          for (let i = 0; i < compare.length; i++) {
            const comparable = compare[i];

            state.sectionPerformance.nonZeroDataChartComparablesIds.push(
              comparable.id
            );

            for (let j = 0; j < comparable.items.length; j++) {
              items[j][`averageScore-${comparable.id}`] =
                comparable.items[j].averageScore !== null
                  ? round(comparable.items[j].averageScore, 0)
                  : null;
              items[j][`previousScore-${comparable.id}`] =
                comparable.items[j].previousAverageScore !== null
                  ? round(comparable.items[j].previousAverageScore, 0)
                  : null;
            }
          }

          state.sectionPerformance.data = items.map((item) => ({
            ...item,
            averageScore:
              item.averageScore !== null ? round(item.averageScore, 0) : null,
            previousAverageScore:
              item.previousAverageScore !== null
                ? round(item.previousAverageScore, 0)
                : null,
          }));
          state.sectionPerformance.loading = false;
        }
      )
      .addCase(actions.getSectionPerformance.rejected, (state, { payload }) => {
        state.sectionPerformance.error = payload || null;
        state.sectionPerformance.loading = false;
      })
      .addCase(actions.setSectionsPerformancePage, (state, { payload }) => {
        state.sectionPerformance.page = payload;
      })
      .addCase(actions.getAuditPerformance.pending, (state) => {
        state.auditPerformance.error = null;
        state.auditPerformance.data = null;
        state.auditPerformance.loading = true;
      })
      .addCase(actions.getAuditPerformance.fulfilled, (state, { payload }) => {
        const comparables = state.auditPerformance.comparables;
        const compare: any = payload.compare;
        const items = processAuditPerformanceChartItems(payload.items);

        for (let i = 0; i < compare.length; i++) {
          const comparable = compare[i];
          const comparableColor = comparables[comparable.id]?.color;

          const compareItems = processAuditPerformanceChartItems(
            compare[i].items,
            comparable.id,
            comparableColor
          );

          for (let j = 0; j < compareItems.length; j++) {
            items.push(compareItems[j]);
          }
        }

        state.auditPerformance.data = items;

        state.auditPerformance.loading = false;
      })
      .addCase(actions.getAuditPerformance.rejected, (state, { payload }) => {
        state.auditPerformance.error = payload || null;
        state.auditPerformance.loading = false;
      })
      .addCase(actions.getAuditCompletion.pending, (state) => {
        state.auditCompletion.error = null;
        state.auditCompletion.data = null;
        state.auditCompletion.loading = true;
      })
      .addCase(actions.getAuditCompletion.fulfilled, (state, { payload }) => {
        const compare: any = payload.compare;

        const items = processAuditCompletionChartItems(payload.items);

        for (let i = 0; i < compare.length; i++) {
          const compareItems: IAuditCompletionChartItem[] =
            processAuditCompletionChartItems(compare[i].items, compare[i].id);

          for (let j = 0; j < items.length; j++) {
            items[j] = {
              ...items[j],
              ...compareItems[j],
            };
          }
        }

        state.auditCompletion.data = items;

        state.auditCompletion.loading = false;
      })
      .addCase(actions.getAuditCompletion.rejected, (state, { payload }) => {
        state.auditCompletion.error = payload || null;
        state.auditCompletion.loading = false;
      })
      .addCase(actions.getAuditDayOfWeekBreakdown.pending, (state) => {
        state.auditDayOfWeekBreakdown.error = null;
        state.auditDayOfWeekBreakdown.data = [];
        state.auditDayOfWeekBreakdown.loading = true;
      })
      .addCase(
        actions.getAuditDayOfWeekBreakdown.fulfilled,
        (state, { payload }) => {
          let totalAuditsCount = 0;

          for (let i = 0; i < payload.length; i++) {
            totalAuditsCount += payload[i].count;
          }

          state.auditDayOfWeekBreakdown.data = payload.map(
            ({ dayOfWeek, count }, i) => ({
              countPercent: round((count / totalAuditsCount) * 100, 0),
              baseColorOpacity: 1 - i * 0.13,
              dayOfWeek,
            })
          );
          state.auditDayOfWeekBreakdown.loading = false;
        }
      )
      .addCase(
        actions.getAuditDayOfWeekBreakdown.rejected,
        (state, { payload }) => {
          state.auditDayOfWeekBreakdown.error = payload || null;
          state.auditDayOfWeekBreakdown.loading = false;
        }
      )
      .addCase(actions.getAuditCompletionTime.pending, (state) => {
        state.auditCompletionTime.error = null;
        state.auditCompletionTime.data = {
          chart: [],
          ticks: [],
          averageTime: 0,
        };
        state.auditCompletionTime.loading = true;
      })
      .addCase(
        actions.getAuditCompletionTime.fulfilled,
        (state, { payload }) => {
          const normalY = (x: number, mean: number, stdDev: number) =>
            Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
          const getMean = (lowerBound: number, upperBound: number) =>
            (upperBound + lowerBound) / 2;
          const getStdDeviation = (lowerBound: number, upperBound: number) =>
            (upperBound - lowerBound) / 4;
          const generatePoints = (lowerBound: number, upperBound: number) => {
            let stdDev = getStdDeviation(lowerBound, upperBound);
            let min = lowerBound - 2 * stdDev;
            let max = upperBound + 2 * stdDev;
            let unit = (max - min) / 100;
            return range(min, max, unit);
          };
          const durations = payload.sort((a: number, b: number) => a - b);
          const lowerBound = durations[0];
          const upperBound = durations[durations.length - 1];

          let mean = getMean(lowerBound, upperBound);
          let stdDev = getStdDeviation(lowerBound, upperBound);
          let points = generatePoints(lowerBound, upperBound);

          let seriesData = points.map((x) => ({
            x,
            y: normalY(x, mean, stdDev),
          }));

          const ticks: number[] = [];

          if (durations.length > 1 && upperBound !== lowerBound) {
            const tickDuration = (upperBound - lowerBound) / 2;

            for (
              let i = durations[0];
              i <= durations[durations.length - 1];
              i += tickDuration
            ) {
              ticks.push(i);
            }
          } else {
            ticks.push(durations[0]);
          }

          state.auditCompletionTime.data = {
            chart: seriesData,
            ticks,
            averageTime:
              durations.reduce((a: number, b: number) => a + b, 0) /
              durations.length,
          };
          state.auditCompletionTime.loading = false;
        }
      )
      .addCase(
        actions.getAuditCompletionTime.rejected,
        (state, { payload }) => {
          state.auditCompletionTime.error = payload || null;
          state.auditCompletionTime.loading = false;
        }
      )
      .addCase(
        actions.setReportLoadingStatus,
        (state, { payload: loading }) => {
          state.scoreBreakdown.loading = loading;
          state.auditCompliance.loading = loading;
          state.totals.loading = loading;
          state.actionsAnalytics.loading = loading;
          state.sectionPerformance.loading = loading;
        }
      )
      .addCase(actions.toggleShareViaEmailModal, (state, { payload }) => {
        state.showShareViaEmailModal = payload;
      })
      .addCase(generalActions.resetAccountData, () => ({ ...initialState }))
  );
