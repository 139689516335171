import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { settingsSelectors, settingsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { Content } from './styled';
import { notification } from '@utils';
import { config } from '@repo/shared/config';

import Integration from '../Integration/Integration';
import { Input } from '@ui/components/shared/ant/Input';
import Form from '@ui/components/shared/ant/Form';

const Webbook: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const url = useSelector(settingsSelectors.integrations.getWebhookUrl);

  useEffect(() => {
    if (form.getFieldValue('url') !== url) {
      form.setFieldValue('url', url);
    }
  }, [url]);

  return (
    <Form form={form} initialValues={{ url }}>
      <Integration
        title={formatMessage({ id: 'Webhook' })}
        description={
          // @ts-ignore
          formatMessage(
            {
              id: 'WebhookDescription',
            },
            {
              a: (chunks) => (
                <a
                  href={config.urls.dev}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }
          )
        }
        showDelete={url !== null}
        content={
          <Content>
            <Form.Item
              name="url"
              rules={[
                {
                  required: true,
                  message: formatMessage({ id: 'RequiredField' }),
                },
                {
                  type: 'url',
                  message: formatMessage({ id: 'EnterValidURL' }),
                },
              ]}
            >
              <Input placeholder="https://" />
            </Form.Item>
          </Content>
        }
        onDelete={async () => {
          const resultAction = await dispatch(
            settingsActions.integrations.deleteWebhook()
          );

          if (
            settingsActions.integrations.deleteWebhook.fulfilled.match(
              resultAction
            )
          ) {
            form.resetFields();

            notification.success({
              message: formatMessage({
                id: 'WebhookHasBeenSuccessfullyDeleted',
              }),
            });
          } else {
            notification.success({
              message: formatMessage({
                id: 'AnErrorOccurredWhileDeletingWebhook',
              }),
            });
          }
        }}
        onSave={async () => {
          try {
            await form.validateFields();

            const resultAction = await dispatch(
              settingsActions.integrations.createWebhook(
                form.getFieldValue('url')
              )
            );

            if (
              settingsActions.integrations.createWebhook.fulfilled.match(
                resultAction
              )
            ) {
              notification.success({
                message: formatMessage({
                  id: 'WebhookHasBeenSuccessfullySaved',
                }),
              });
            } else {
              notification.error({
                message: formatMessage({
                  id: 'AnErrorOccurredWhileSavingWebhook',
                }),
                description: resultAction.payload,
              });
            }
          } catch (_) {}
        }}
        onSaveBtnLabel={formatMessage({
          id: 'Save',
        })}
      />
    </Form>
  );
};

export default Webbook;
