import styled from 'styled-components';
import { AlertCircle, CheckCircle, Clock } from 'react-feather';

import { colors } from '@repo/shared/config';

export const ErrorIcon = styled(AlertCircle)`
  width: 15px;
  height: 15px;
  color: ${colors.red};
`;

export const SuccessIcon = styled(CheckCircle)`
  width: 15px;
  height: 15px;
  color: ${colors.green};
`;

export const PendingIcon = styled(Clock)`
  width: 15px;
  height: 15px;
  color: ${colors.gray8};
`;
