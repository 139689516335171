import React from 'react';
import { useIntl } from 'react-intl';

import { Container } from './styled';
import { IssueDetailsAction } from '@domain/Issues/models/Issue';
import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';
import { ActionStatus } from '@repo/shared/enums';

interface IProps {
  action: IssueDetailsAction;
}

const LinkedActionBadge: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const { formatMessage } = useIntl();

  switch (action.status) {
    case ActionStatus.Expired:
      return (
        <Container color={colors.orange} bgColor={hexToRGB(colors.orange, 0.2)}>
          {formatMessage({ id: 'Expired' })}
        </Container>
      );
    case ActionStatus.Approved:
      return (
        <Container color={colors.green} bgColor={hexToRGB(colors.green, 0.2)}>
          {formatMessage({ id: 'Approved' })}
        </Container>
      );
    case ActionStatus.Rejected:
      return (
        <Container color={colors.red} bgColor={hexToRGB(colors.red, 0.2)}>
          {formatMessage({ id: 'Rejected' })}
        </Container>
      );
    case ActionStatus.Opened:
      return (
        <Container color={colors.blue2} bgColor={hexToRGB(colors.blue2, 0.2)}>
          {formatMessage({ id: 'Opened' })}
        </Container>
      );
    case ActionStatus.Submitted:
      return (
        <Container color={colors.blue2} bgColor={hexToRGB(colors.blue2, 0.2)}>
          {formatMessage({ id: 'Submitted' })}
        </Container>
      );
  }

  return null;
};

export default LinkedActionBadge;
