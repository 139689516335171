import { IssueType } from '@domain/IssueTypes/models/IssueType';

export function transformIssueTypeToDto({
  autoAssignJobTitles,
  ...issueType
}: IssueType | Omit<IssueType, 'id' | 'fields'>) {
  return {
    ...issueType,
    autoAssignJobTitlesIds: autoAssignJobTitles.map(({ id }) => id),
  };
}
