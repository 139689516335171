export enum NotificationsGroupType {
  AuditsAssignedToMe,
  AuditsAssignedToSomeoneElse,
  ActionsAssignedToMe,
  ActionsAssignedToSomeoneElse,
  ActionsCreatedByMe,
  IssuesAssignedToMe,
  IssuesAssignedToParticipants,
}

export enum MqaNotification {
  // Audits assigned To Me:
  AuditAssignedAssigneesNotification = 1,
  AuditCompletedAssigneesNotification = 3,
  AuditDeletedAssigneesNotification = 5,
  AuditDueSoonAssigneesNotification = 7,
  AuditRescheduledAssigneesNotification = 9,
  AuditOverdueAssigneesNotification = 11,
  AuditExpiredAssigneesNotification = 13,
  // Audits assigned to someone else:
  AuditCompletedParticipantsNotification = 4,
  // Corrective actions assigned to me:
  CorrectiveActionAssignedAssigneesNotification = 17,
  CorrectiveActionApprovedAssigneesNotification = 18,
  CorrectiveActionRejectedAssigneesNotification = 19,
  CorrectiveActionDeletedAssigneesNotification = 20,
  CorrectiveActionCommentAddedAssigneesNotification = 21,
  CorrectiveActionDueSoonAssigneesNotification = 22,
  CorrectiveActionOverDueAssigneesNotification = 23,
  CorrectiveActionExpiredAssigneesNotification = 24,
  // Corrective actions Created by me:
  CorrectiveActionSubmittedCreatedByNotification = 25,
  CorrectiveActionCommentAddedCreatedByNotification = 26,
  // Corrective actions assigned to someone else:
  CorrectiveActionApprovedParticipantsNotification = 27,
  CorrectiveActionSubmittedParticipantsNotification = 28,
  CorrectiveActionCommentAddedParticipantNotification = 29,
  CorrectiveActionOverDueParticipantNotification = 30,
  CorrectiveActionRejectedParticipantNotification = 31,
  // Issue Assignees
  IssueAssignedAssigneesNotification = 32,
  IssueClosedAssigneesNotification = 33,
  IssueReopenedAssigneesNotification = 34,
  IssueCommentAddedAssigneesNotification = 35,
  IssueDueSoonAssigneesNotification = 36,
  IssueOverDueAssigneesNotification = 37,
  // Issue Participants
  IssueClosedParticipantsNotification = 38,
  IssueSubmittedParticipantsNotification = 39,
  IssueCommentAddedParticipantNotification = 40,
  IssueOverDueParticipantNotification = 41,
  IssueReopenedParticipantNotification = 42,
}

export enum NotificationsTab {
  Audit,
  Action,
  Issue,
}
