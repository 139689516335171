import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import girlOnBikeSVG from './girl-on-bike.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  text-align: center;
`;

export const GirlOnBike = styled.div`
  width: 216px;
  height: 106px;
  background: url(${girlOnBikeSVG}) center no-repeat;
  margin-bottom: 25px;
`;

export const Title = styled.h3`
  margin-bottom: 6px;
`;

export const Description = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
`;
