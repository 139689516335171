import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Badge = styled.div`
  margin-left: 7px;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: ${colors.red};
  background: ${hexToRGB(colors.red, 0.2)};
`;
