import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const ContainerButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  font-size: 14px;

  &:disabled {
    opacity: 0.5;
  }
`;

export const AssigneesListContainer = styled.div`
  max-width: calc(100% - 30px);
`;

export const AssigneesList = styled.div`
  margin: 0 -3px 0;
  display: flex;
  flex-direction: column;

  & > * {
    margin: 4px 0 4px 2px;
  }
`;

export const More = styled.div`
  font-size: 13px;
  padding: 2px 0 0 2px;
  color: ${colors.blue2};
  text-align: left;
`;

export const Unassigned = styled.span`
  display: inline-flex;
  align-items: center;
  height: 41px;
`;

export const PlusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: ${colors.gray3};
  margin: 4px 0 0 0;

  svg {
    width: 18px;
    height: 18px;
    color: ${colors.gray8};
  }
`;

export const PopoverContent = styled.div`
  max-width: 300px;
  font-size: 12px;
  line-height: 1.4;
`;
