import React from 'react';

import {
  Container,
  Content,
  ValueContainer,
  Value,
  Change,
  Label,
  Skeleton,
  Icon,
} from './styled';

interface IProps {
  value?: number;
  change?: number;
  changeUnit?: string;
  label: string;
  loading: boolean;
  onClick?: () => void;
  e2eDataAttr?: string;
}

const Total: React.FC<React.PropsWithChildren<IProps>> = ({
  value = 0,
  label,
  loading,
  change,
  changeUnit,
  onClick,
  e2eDataAttr,
}) => {
  const isActive = !loading && !!onClick && !!value;

  return (
    <Container
      onClick={isActive ? onClick : undefined}
      active={isActive}
      data-e2e={e2eDataAttr}
    >
      <Content>
        {loading ? (
          <Skeleton />
        ) : (
          <ValueContainer>
            <Value>{value}</Value>
            {change !== undefined && change !== 0 && (
              <Change>
                {change}
                {changeUnit}
              </Change>
            )}
          </ValueContainer>
        )}
      </Content>
      <Label>
        {label}
        {isActive && <Icon />}
      </Label>
    </Container>
  );
};

export default Total;
