export enum ColumnDataType {
  AssignedTo,
  AuditObject,
}

export enum DataCategoryType {
  Audits,
  CorrectiveActions,
  AuditItems,
  Sections,
}

export enum AuditColumns {
  Name = 0,
  Object = 1,
  Status = 2,
  DueDate = 3,
  AssignedTo = 4,
  Score = 5,
  ScoreLabel = 6,
  CreatedDate = 7,
  StartedDate = 8,
  CompletedDate = 9,
  CompletedBy = 10,
  PendingActions = 11,
  CompletedActions = 12,
  PastDue = 13,
  FailedItemCount = 14,
  Number = 15,
  TemplateName = 16,
  AuditObjectGroup = 17,
}

export enum ActionsColumns {
  Name = 0,
  Description = 1,
  Question = 2,
  Priority = 3,
  AuditName = 4,
  CreatedBy = 5,
  AssignedTo = 6,
  Status = 7,
  CreatedDate = 8,
  OriginalDueDate = 9,
  CurrentDueDate = 10,
  CompletedDate = 11,
  PastDue = 12,
  LastActivityDate = 13,
  Critical = 14,
  Number = 15,
  AuditObject = 16,
  AuditObjectGroup = 17,
  ApprovedBy = 18,
  TemplateName = 19,
  Tags = 20,
  SubmitDate = 21,
}

export enum AuditItemsColumns {
  Number = 0,
  Question = 1,
  QuestionResponse = 2,
  Comment = 3,
  Flags = 4,
  RepeatFailureCount = 5,
  HasCorrectiveActions = 6,
  Audit = 7,
  AuditDate = 8,
  AuditCompletedBy = 9,
  PointsEarned = 10,
  AuditObject = 11,
  AuditObjectGroup = 12,
  IsCritical = 13,
  Section = 14,
  Subsection = 15,
  IsFailed = 16,
  QuestionValue = 17,
  IsNotApplicable = 18,
}

export enum SectionColumns {
  Number = 0,
  Section = 1,
  Audit = 2,
  AuditDate = 3,
  AuditCompletedBy = 4,
  Score = 5,
  AuditObject = 6,
  AuditObjectGroup = 7,
  IsPassed = 8,
  IsNotApplicable = 9,
}

export enum CompareType {
  User,
  UserGroups,
  AuditObject,
  AuditObjectGroups,
}

export enum DayOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0,
}

export enum ComparableChart {
  SectionPerformance,
  AuditPerformance,
  AuditCompletion,
}

export enum ShareViaEmailModalTab {
  Emails,
  Users,
}

export enum DateRangeType {
  PriorWeek,
  PriorMonth,
  Prior3Months,
  Prior6Months,
  Prior9Months,
  Prior12Months,
  Custom,
}

export enum ParticipationType {
  HasAccess,
  DirectInvolvement,
}

export enum ExportTable {
  CriticalFailedItems,
  MostFailedItems,
  CompletedAuditDetails,
}

export enum AuditorPerformanceReportChartExport {
  ActionParticipationRate,
  AuditParticipationRate,
  ActionBurndown,
  AuditBurndown,
  ActionsByTags,
  FailedItemsByTags,
  CompletedAuditsOverTime,
  FailedItemsOverTime,
  ResolvedActionsOverTime,
  ActionsOverTime,
  OpenActions,
}

export enum PerformanceReportType {
  Auditor,
  Object,
}

export enum PerformanceReportPage {
  RealTime,
  Historical,
}

export enum FailedItemStatus {
  Total = 0,
  Critical = 1,
}

export enum PerformanceReportBarChartType {
  Actions,
  FailedItems,
}

export enum DetailsModalType {
  Audits,
  Items,
}

export enum AuditReportType {
  Internal,
  Public,
  SampleData,
}
