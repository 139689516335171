import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../../frameworks/redux';

import { tagsAdapter } from './tags.reducer';
import { TagsPage } from '@types';
import { ITagsFilters } from '@repo/shared/types';

const getPage = (state: IRootState): TagsPage => state.tags.page;

export const tagsSelectors = {
  getTags: createSelector(
    (state: IRootState) => state.tags,
    (tags) => ({
      loading: tags.loading,
      error: tags.error,
      meta: tags.meta,
      tags: tagsAdapter.getSelectors().selectAll(tags.entities),
    })
  ),
  getTagsLookUp: (state: IRootState) =>
    tagsAdapter.getSelectors().selectEntities(state.tags.entities),
  getFilters: createSelector(
    [
      getPage,
      (state: IRootState): Record<TagsPage, ITagsFilters> => state.tags.filters,
    ],
    (page, filters) => filters[page]
  ),
};
