import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getColumnSortProperties } from '@utils';
import { IJobTitle } from '@repo/shared/types';
import { accountSelectors, usersActions, usersSelectors } from '@store';
import { Permission } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';

import Table from '../../../shared/ant/Table/Table';
import EmptyTable from '../../../shared/ant/EmptyTable/EmptyTable';
import JobTitlesActionsMenu from '@components/users/jobTitles/JobTitlesTable/JobTitlesActionsMenu';

const JobTitlesTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, error, data, meta } = useSelector(
    usersSelectors.jobTitles.getData
  );
  const filters = useSelector(usersSelectors.jobTitles.getFilters);
  const [canManageUsers, canManageAuditObjects] = usePermission([
    Permission.CanManageUsers,
    Permission.CanManageAuditObjects,
  ]);
  const { isJobTitlesAndAccessReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  const canManage =
    !isJobTitlesAndAccessReadonly && canManageUsers && canManageAuditObjects;

  return (
    <Table
      onPageChange={(update) =>
        dispatch(usersActions.jobTitles.getData(update))
      }
      loading={loading}
      canSelectRow={canManage}
      meta={meta}
      error={error}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
        },
        {
          title: formatMessage({ id: 'Description' }),
          dataIndex: 'description',
          key: 'description',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'description'
          ),
          className: 'break-all',
        },
        ...(canManage
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (_: any, jobTitle: IJobTitle) => {
                  return !jobTitle?.isSystem ? (
                    <JobTitlesActionsMenu jobTitleId={jobTitle.id} />
                  ) : null;
                },
              },
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((jobTitle: IJobTitle) => ({
              key: jobTitle.id,
              disableRowSelection: jobTitle.isSystem,
              ...jobTitle,
            }))
          : []
      }
      e2eDataAttr={e2eTestElements.users.jobTitles.table}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage({
                  id: 'NoJobTitles',
                })}
              </>
            }
            button={
              canManage
                ? {
                    text: `+ ${formatMessage({ id: 'NewJobTitle' })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        usersActions.jobTitles.toggleAddEditModal({
                          show: true,
                        })
                      );
                    },
                  }
                : null
            }
            howItWorksUrl={config.urls.jobTitlesSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          usersActions.jobTitles.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default JobTitlesTable;
