import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { PopoverContent } from './styled';
import { SchedulePlan } from '@domain/SchedulePlans/models/SchedulePlan';
import { useAppDispatch } from '@hooks';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { notification } from '@utils';
import { SchedulePlanDetailsPage } from '@application/SchedulePlans/models/SchedulePlanDetailsPage';

import ChangeScheduleStateModalAndButton from '@presentation/SchedulePlans/ChangeScheduleStateModalAndButton/ChangeScheduleStateModalAndButton';

interface IProps {
  schedulePlan: SchedulePlan;
  disabled: boolean;
}

const SchedulePlanChangeState: React.FC<React.PropsWithChildren<IProps>> = ({
  schedulePlan,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const page = useSelector(
    schedulePlansSelectors.getSchedulePlanDetailsCurrentPage
  );

  return (
    <ChangeScheduleStateModalAndButton
      active={schedulePlan.active}
      startFromDate={schedulePlan.startFromDate}
      stopByDate={schedulePlan.stopByDate}
      disabled={disabled}
      disabledPopoverContent={
        !schedulePlan.schedulesCount ? (
          <PopoverContent>
            {formatMessage({
              id: 'PleaseAddRuleToStartSchedule',
            })}
          </PopoverContent>
        ) : null
      }
      onChange={async (params) => {
        const resultAction = await dispatch(
          schedulePlansActions.changeStatus({
            id: schedulePlan.id,
            ...params,
          })
        );

        if (schedulePlansActions.changeStatus.fulfilled.match(resultAction)) {
          notification.success({
            message: formatMessage({
              id: !schedulePlan.active
                ? 'ScheduleHasBeenStartedSuccessfully'
                : 'ScheduleHasBeenStoppedSuccessfully',
            }),
          });

          if (page === SchedulePlanDetailsPage.PlanningPeriods) {
            dispatch(schedulePlansActions.getSchedulePlanPeriods());
          }

          return true;
        } else {
          notification.error({
            message: formatMessage({
              id: !schedulePlan.active
                ? 'ErrorWhileStartingSchedule'
                : 'ErrorWhileStoppingSchedule',
            }),
          });

          return false;
        }
      }}
    />
  );
};

export default SchedulePlanChangeState;
