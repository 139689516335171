import React from 'react';
import { useIntl } from 'react-intl';

import { usersActions } from '@store';
import { useAppDispatch } from '@hooks';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  assignmentId: string;
}

const UserGroupsAssignmentsActionsMenu: React.FC<
  React.PropsWithChildren<IProps>
> = ({ assignmentId }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'Delete' }),
          onClick: () => {
            dispatch(
              usersActions.groupAssignments.toggleConfirmDeleteModal([
                assignmentId,
              ])
            );
          },
        },
      ]}
    />
  );
};

export default UserGroupsAssignmentsActionsMenu;
