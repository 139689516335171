import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const Label = styled.div`
  margin-right: 15px;
`;
