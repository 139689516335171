import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { Action } from '../../shared/ActionsModal/styled';
import { Modal } from '@repo/shared/enums';
import {
  accountSelectors,
  usersSelectors,
  generalSelectors,
  generalActions,
  usersActions,
} from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import ActionsModal from '../../shared/ActionsModal/ActionsModal';

const UserActionsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const users = useSelector(usersSelectors.list.getMap);
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const user =
    selectedTableRowKeys.length === 1 ? users[selectedTableRowKeys[0]] : null;
  const isAdmin = useSelector(accountSelectors.isAdmin);

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <>
              <Action
                onClick={() => {
                  dispatch(usersActions.list.updateUserDetails(null));
                  navigate(
                    routes.users.userDetails.replace(
                      ':id',
                      selectedTableRowKeys[0]
                    )
                  );
                  closeModal();
                }}
              >
                {formatMessage({ id: 'View' })}
              </Action>
              <Action
                onClick={() => {
                  dispatch(
                    generalActions.showModal({
                      name: Modal.AddEdit,
                      data: users?.[selectedTableRowKeys[0]],
                    })
                  );
                  closeModal();
                }}
              >
                {formatMessage({ id: 'Edit' })}
              </Action>
              {isAdmin && selectedTableRowKeys[0] !== currentUser?.id && (
                <Action
                  onClick={() => {
                    dispatch(
                      usersActions.list.toggleSetUserPasswordModal(
                        selectedTableRowKeys[0]
                      )
                    );
                    closeModal();
                  }}
                >
                  {formatMessage({ id: 'SetPassword' })}
                </Action>
              )}
            </>
          )}
          {(selectedTableRowKeys.length > 1 ||
            (selectedTableRowKeys.length === 1 &&
              selectedTableRowKeys[0] !== currentUser?.id)) && (
            <Action
              onClick={() =>
                dispatch(
                  generalActions.showModal({
                    name: Modal.ConfirmDelete,
                    data: user,
                  })
                )
              }
            >
              {formatMessage({ id: 'Delete' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
        </>
      )}
    </ActionsModal>
  );
};

export default UserActionsModal;
