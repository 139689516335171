import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountSelectors, usersActions } from '@store';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { e2eTestElements } from '@config';

import UserContentWrapper, {
  UserPage,
} from '@components/users/single/UserContentWrapper/UserContentWrapper';
import { useParams } from 'react-router';
import UsersAccessTable from '@components/users/single/UserAccess/UsersAccessTable/UsersAccessTable';
import AssignUsersAccessModal from '@components/users/single/UserAccess/AssignUsersAccessModal/AssignUsersAccessModal';
import ConfirmDeleteUsersAccessModal from '@components/users/single/UserAccess/ConfirmDeleteUsersAccessModal';
import UsersAccessActionsModal from '@components/users/single/UserAccess/UsersAccessActionsModal';
import Button from '@components/shared/ant/Button';

interface IProps {}

const UserAccess: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const [canManageUsers, canManageAuditObjects] = usePermission([
    Permission.CanManageUsers,
    Permission.CanManageAuditObjects,
  ]);
  const { isJobTitlesAndAccessReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    dispatch(usersActions.access.getData({ userId: id }));

    return () => {
      dispatch(usersActions.access.toggleAssignAccessModal(false));
      dispatch(usersActions.access.toggleFiltersModal(false));
    };
  }, [id]);

  return (
    <UserContentWrapper
      page={UserPage.Access}
      extra={
        !isJobTitlesAndAccessReadonly &&
        canManageUsers &&
        canManageAuditObjects ? (
          <Button
            type="primary"
            onClick={() => {
              dispatch(usersActions.access.toggleAssignAccessModal(true));
            }}
            data-e2e={e2eTestElements.users.access.openAssignModal}
          >
            {formatMessage({
              id: 'AssignAccess',
            })}
          </Button>
        ) : undefined
      }
    >
      <UsersAccessTable userId={id} />
      <AssignUsersAccessModal userId={id} />
      <ConfirmDeleteUsersAccessModal userId={id} />
      <UsersAccessActionsModal />
    </UserContentWrapper>
  );
};

export default UserAccess;
