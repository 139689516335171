import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { concatNames, getColumnSortProperties } from '@utils';
import { delay } from '@repo/shared/utils';
import { Mark } from './styled';
import { accountSelectors } from '@store';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { AuditName, ButtonLink } from '@presentation/Audits/AuditsTable/styled';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';
import { SampleBadge } from '@components/shared/SampleBadgedName';
import { Schedule } from '@domain/Schedules/models/Schedule';
import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';

import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import SchedulesActionsMenu from './SchedulesActionsMenu';
import SchedulesTableChangeStatusCell from '@presentation/Schedules/SchedulesTable/SchedulesTableChangeStatusCell';
import SchedulesActionsModal from '@presentation/Schedules/SchedulesActionsModal';
import DeleteSchedulesModal from '@presentation/Schedules/DeleteSchedulesModal';
import AddEditScheduleModal from '@presentation/Schedules/AddEditScheduleModal/AddEditScheduleModal';

interface IProps {
  disabled?: boolean;
}

const SchedulesTable: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const {
    data,
    loading: schedulesLoading,
    error,
    meta,
  } = useSelector(schedulesSelectors.getSchedules);
  const { loading: schedulePlanDetailsLoading, data: schedulePlanDetails } =
    useSelector(schedulePlansSelectors.getSchedulePlanDetails);
  const filters = useSelector(schedulesSelectors.getFilters);
  const objectName = useSelector(accountSelectors.getObjectName);
  const addEditScheduleModalState = useSelector(
    schedulesSelectors.getAddEditModalState
  );

  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);

  return (
    <>
      <Table<Schedule>
        onPageChange={(update) => {
          dispatch(schedulesActions.getSchedules(update));
        }}
        onUnmount={() => {
          dispatch(schedulesActions.resetTableData());
        }}
        loading={schedulePlanDetailsLoading || schedulesLoading}
        meta={meta}
        onSelectRow={() => {
          dispatch(schedulesActions.toggleFiltersModal(false));
        }}
        canSelectRow={!disabled && canScheduleAudits}
        e2eDataAttr={e2eTestElements.schedules.table}
        error={error}
        columns={[
          {
            title: formatMessage({ id: 'Name' }),
            dataIndex: 'name',
            key: 'name',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'name'
            ),
            render(_, schedule) {
              return (
                <AuditName>
                  <ButtonLink
                    onClick={async () => {
                      dispatch(schedulesActions.toggleFiltersModal(false));

                      if (addEditScheduleModalState.opened) {
                        dispatch(
                          schedulesActions.toggleAddEditModal({ opened: false })
                        );
                        await delay(250);
                      }

                      dispatch(
                        schedulesActions.toggleAddEditModal({
                          opened: true,
                          scheduleId: schedule.id,
                        })
                      );
                    }}
                  >
                    {schedule.name}
                    {schedule.repeat.repeatPattern !==
                      AuditRepeatPattern.OneTime && (
                      <Mark>{formatMessage({ id: 'Recurring' })}</Mark>
                    )}
                  </ButtonLink>
                  {schedule.isSample && (
                    <SampleBadge>{formatMessage({ id: 'Sample' })}</SampleBadge>
                  )}
                </AuditName>
              );
            },
          },
          {
            title: formatMessage(
              { id: 'AuditObjectsAndGroups' },
              { objectName: objectName.plural }
            ),
            dataIndex: 'auditObject',
            key: 'auditObject',
            ...getColumnSortProperties(
              filters.orderByDirection,
              filters.orderBy === 'auditObject'
            ),
            render: (_, schedule) => {
              return concatNames([
                ...schedule.auditObjects,
                ...schedule.auditObjectGroups,
              ]);
            },
          },
          ...(!schedulePlanDetails
            ? [
                {
                  title: formatMessage({ id: 'State' }),
                  dataIndex: 'status',
                  key: 'status',
                  render(_: any, schedule: Schedule) {
                    if (
                      schedule.repeat.repeatPattern ===
                      AuditRepeatPattern.OneTime
                    ) {
                      return '';
                    }

                    return (
                      <SchedulesTableChangeStatusCell schedule={schedule} />
                    );
                  },
                },
              ]
            : []),
          ...(!disabled
            ? [
                {
                  title: formatMessage({ id: 'Action' }),
                  key: 'action',
                  width: 70,
                  render: (_: any, schedule: Schedule) => (
                    <SchedulesActionsMenu schedule={schedule} />
                  ),
                },
              ]
            : []),
        ]}
        dataSource={data.map((schedule: Schedule) => ({
          ...schedule,
          key: schedule.id,
        }))}
        locale={{
          emptyText: (
            <EmptyTable
              text={
                filters.search !== ''
                  ? formatMessage(
                      { id: 'NoScheduleSearchResults' },
                      { searchStr: filters.search, linebreak: <br /> }
                    )
                  : formatMessage({ id: 'NoSchedule' }, { linebreak: <br /> })
              }
              button={
                canScheduleAudits
                  ? {
                      text: `+ ${formatMessage({
                        id: 'CreateSchedule',
                      })}`,
                      type: 'link',
                      onClick: () => {
                        dispatch(
                          schedulesActions.toggleAddEditModal({ opened: true })
                        );
                      },
                    }
                  : undefined
              }
              howItWorksUrl={config.urls.schedulesSupport}
            />
          ),
        }}
        onSort={(orderBy, orderByDirection) =>
          dispatch(schedulesActions.getSchedules({ orderBy, orderByDirection }))
        }
      />
      <SchedulesActionsModal />
      <DeleteSchedulesModal />
      <AddEditScheduleModal disabled={disabled} />
    </>
  );
};

export default SchedulesTable;
