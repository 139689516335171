import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

export const More = styled.span`
  font-size: 12px;
  color: ${colors.gray8};
`;

export const ExclamationMarkPlaceholder = styled.div`
  display: flex;
  align-items: center;

  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: '!';
    width: 20px;
    height: 20px;
    margin-left: 4px;
    font-size: 12px;
    border-radius: 100%;
    color: ${colors.red};
    background: ${hexToRGB(colors.red, 0.2)};
  }
`;

export const EmptyPlaceholder = styled.div`
  color: ${colors.gray7};
`;
