import styled from 'styled-components';

import mqaIcon from './mqa-icon.svg';

export const MQAIcon = styled.div`
  width: 33px;
  height: 48px;
  background: url(${mqaIcon}) no-repeat center center;
  background-size: contain;
`;
