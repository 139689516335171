import React, { useMemo, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { notification } from '@utils';
import {
  actionsActions,
  actionsSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';
import { colors } from '@repo/shared/config';
import { ActionStatus } from '@repo/shared/enums';

import Modal from '@components/shared/ant/Modal/Modal';
import { ActionsPage } from '@application/Actions/enums/ActionsPage';

const ChangeActionStatusModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const modalState = useSelector(actionsSelectors.getChangeStatusModalState);
  const page = useSelector(actionsSelectors.getPage);
  const ids =
    modalState.status !== null ? modalState.ids : selectedTableRowKeys;

  function closeModal() {
    dispatch(actionsActions.toggleChangeStatusModal({ status: null }));
  }

  const texts = useMemo(() => {
    switch (modalState.status) {
      case null:
        return {
          button: '',
          body: '',
        };
      case ActionStatus.Approved:
      case ActionStatus.Rejected: {
        const buttonText = formatMessage({
          id:
            modalState.status === ActionStatus.Approved ? 'Approve' : 'Reject',
        });

        return {
          button: buttonText,
          body: formatMessage(
            {
              id: 'YouAreAboutToChangeStatus',
            },
            {
              itemCount: ids.length,
              approveFail: buttonText.toLowerCase(),
              replaceValue: ids.length > 1 ? ids.length : '',
            }
          ),
        };
      }
      case ActionStatus.Submitted:
        return {
          button: formatMessage({ id: 'Submit' }),
          body: formatMessage({
            id: 'YouAreAboutToMarkAsDoneCorrectiveAction',
          }),
        };
    }
  }, [modalState.status]);

  return (
    <Modal
      title={formatMessage(
        { id: 'SetCorrectiveActionsStatus' },
        { itemCount: ids.length }
      )}
      e2eDataConfirmAttr={
        e2eTestElements.actions.detailsModal.setStatusModal.submitButton
      }
      open={modalState.status !== null}
      onOk={async () => {
        if (modalState.status === null) {
          return;
        }

        setLoading(true);

        const resultAction = await dispatch(
          actionsActions.changeActionsStatuses({
            status: modalState.status,
            ids,
          })
        );

        if (
          actionsActions.changeActionsStatuses.fulfilled.match(resultAction)
        ) {
          if (page === ActionsPage.My) {
            ids.forEach((id) => {
              dispatch(
                actionsActions.updateActionLocally({
                  id,
                  changes: {
                    status: modalState.status,
                  },
                })
              );
            });
          } else {
            dispatch(actionsActions.getActions());
          }

          if (selectedTableRowKeys.length > 0) {
            setTimeout(() => {
              dispatch(generalActions.selectTableRows([]));
            }, 200);
          }
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileSettingStatus' }),
            description: resultAction.payload,
          });
        }

        setLoading(false);

        closeModal();
      }}
      okText={texts.button}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => closeModal()}
      closeIcon={<X />}
    >
      {texts.body}
    </Modal>
  );
};

export default ChangeActionStatusModal;
