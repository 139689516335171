import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const AuditName = styled.div`
  display: inline-block;
  word-wrap: break-word;
  word-break: break-word;
  max-width: 220px;
`;

export const IssueAnswer = styled.div`
  font-size: 12px;
  line-height: 1.3;
  color: ${colors.gray8};
`;
