import React from 'react';

import { Container } from './styled';
import { ActionDetails } from '@domain/Actions/models/Action';

import ActionsDetailsQuestion from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionsDetailsQuestion';
import ActionsDetailsAnswer from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionsDetailsAnswer';
import ActionDetailsBestPractice from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsBestPractice';
import ActionDetailsDescription from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsDescription';
import ActionDetailsLinkedIssue from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsLinkedIssue';
import ActionsDetailsAudit from '@presentation/Actions/ActionDetails/ActionDetailsInfo/ActionsDetailsAudit';
import ActionsDetailsAuditObject from '@presentation/Actions/ActionDetails/ActionDetailsInfo/ActionsDetailsAuditObject';

interface IProps {
  action: ActionDetails | null;
}

const ActionDetailsInfo: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  if (!action) {
    return null;
  }

  return (
    <Container>
      <ActionDetailsDescription action={action} />
      <ActionDetailsLinkedIssue action={action} />
      <ActionsDetailsAuditObject action={action} />
      <ActionsDetailsAudit action={action} />
      <ActionsDetailsQuestion action={action} />
      <ActionsDetailsAnswer action={action} />
      <ActionDetailsBestPractice action={action} />
    </Container>
  );
};

export default ActionDetailsInfo;
