import styled from 'styled-components';
import { animated } from 'react-spring';
import Typography from 'antd/es/typography';

import { colors } from '@repo/shared/config';

const { Title: AntTitle } = Typography;

export const Container = styled(animated.div)`
  position: fixed;
  right: -400px;
  top: 0;
  bottom: 0;
  width: 400px;
  z-index: 2000;
  padding-right: 30px;
  background: ${colors.white};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.04);
`;

export const Content = styled.div`
  position: relative;
  padding: 85px 25px 48px 25px;
`;

export const TopBar = styled.div`
  position: absolute;
  right: 25px;
  left: 25px;
  top: 20px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseModalButton = styled.button`
  position: relative;
  border-radius: 100%;
  border: 0;
  background: none;
  cursor: pointer;
  transition: background-color 0.15s ease-in;
  padding: 0;
  will-change: right;

  &:hover {
    background: ${colors.gray3};
  }

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
  }
`;

export const Header = styled.div`
  margin-bottom: 25px;
`;

export const Title = styled(AntTitle)`
  &.ant-typography {
    margin-bottom: 3px;
  }
`;

export const SubTitle = styled.div`
  font-size: 14px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 370px;
  z-index: 1;
`;
