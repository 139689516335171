import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Day = styled.button`
  display: block;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 0;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;

  ${({ selected }: { selected: boolean }) =>
    selected
      ? `
    background: ${colors.blue2};
    color: ${colors.white};
  `
      : `
    background: ${colors.gray3};
    color: ${colors.gray10};
  `}
`;
