import React, { useRef, useMemo, useState } from 'react';
import Scroller from 'react-indiana-drag-scroll';
import { useIntl } from 'react-intl';

import { ImagesContainer, ScrollContainer, FilesContainer } from './styled';
import { FileMetadata, IApiService } from '@repo/shared/types';
import { FileSourceType, ThumbSize } from '@repo/shared/enums';
import { InternalApiService } from '@repo/shared/api';

import ImagePreview from '@repo/shared/components/Previews/ImagePreview/ImagePreview';
import FilePreview from './FilePreview/FilePreview';
import FileGallery from './FileGallery/FileGallery';
import Button from '@repo/shared/components/Button';

interface IProps {
  files: FileMetadata[];
  getFilePath?: (file: FileMetadata, size?: number) => string;
  getMetadataPath?: (fileId: string) => string;
  thumbSize?: ThumbSize;
  onUpdate?: (file: FileMetadata, index: number) => Promise<boolean>;
  onRemove?: (id: string) => void;
  mode?: 'grid' | 'row';
  gridMaxItems?: number;
  id?: string;
  fileSourceType?: FileSourceType;
  fileUrlPrefix?: string;
  containerStyle?: React.CSSProperties;
  imagePreviewStyle?: React.CSSProperties;
  imagePreviewContainerStyle?: React.CSSProperties;
  apiService?: IApiService;
  skipCompanyId?: boolean;
  onConflictError?: (error: string) => boolean;
  disableVideoPlayback?: boolean;
}

const Previews: React.FC<React.PropsWithChildren<IProps>> = ({
  files,
  getFilePath,
  getMetadataPath,
  thumbSize = ThumbSize.md,
  onRemove,
  mode = 'row',
  gridMaxItems,
  id,
  containerStyle,
  imagePreviewStyle,
  imagePreviewContainerStyle,
  apiService = InternalApiService.getInstance(),
  skipCompanyId,
  onUpdate,
  disableVideoPlayback,
}) => {
  const { formatMessage } = useIntl();
  const container = useRef<HTMLDivElement | null>(null);

  const [showAllItems, setShowAllItems] = useState(false);
  const [showGalleryId, setShowGalleryId] = useState<string | null>(null);

  const { media, other } = useMemo(
    () =>
      files.reduce<{ media: FileMetadata[]; other: FileMetadata[] }>(
        (acc, file) => {
          if (
            file.contentType === null ||
            file.contentType.includes('image') ||
            file.contentType.includes('video')
          ) {
            acc.media.push(file);
          } else {
            acc.other.push(file);
          }

          return acc;
        },
        { media: [], other: [] }
      ),
    [JSON.stringify(files), id]
  );

  const imagePreviews = useMemo(() => {
    const hasMoreItems = !!gridMaxItems && media.length > gridMaxItems;
    let displayedMedia = media;

    if (hasMoreItems && !showAllItems) {
      displayedMedia = media.slice(0, gridMaxItems - 1);
    }

    return (
      <div>
        <ImagesContainer mode={mode}>
          {displayedMedia.map((file) => {
            return (
              <ImagePreview
                key={file.id}
                file={file}
                size={thumbSize * 2}
                getFilePath={getFilePath}
                onClick={(id) => {
                  setShowGalleryId(id);
                }}
                skipCompanyId={skipCompanyId}
                apiService={apiService}
                imagePreviewStyle={{
                  height: `${thumbSize}px`,
                  width: `${thumbSize}px`,
                  borderRadius: '5px',
                  ...(imagePreviewStyle || {}),
                }}
                imagePreviewContainerStyle={imagePreviewContainerStyle}
                getMetadataPath={getMetadataPath}
                onRemove={
                  onRemove
                    ? () => {
                        onRemove(file.id);
                      }
                    : undefined
                }
              />
            );
          })}
        </ImagesContainer>
        {hasMoreItems && (
          <Button
            type="link"
            onClick={() => {
              setShowAllItems(!showAllItems);
            }}
            style={{ fontSize: '12px', margin: '-5px 0 5px' }}
          >
            {!showAllItems ? (
              <>{formatMessage({ id: 'ShowMore' })} &darr;</>
            ) : (
              <>{formatMessage({ id: 'ShowLess' })} &uarr;</>
            )}
          </Button>
        )}
      </div>
    );
  }, [mode, JSON.stringify(media), id, showAllItems]);

  if (files.length === 0) {
    return null;
  }

  return (
    <div style={containerStyle}>
      {mode === 'row' ? (
        <ScrollContainer visibleShadow={files.length > 7}>
          <Scroller innerRef={container}>{imagePreviews}</Scroller>
        </ScrollContainer>
      ) : (
        imagePreviews
      )}
      {other.length > 0 && (
        <FilesContainer style={{ paddingTop: media.length > 0 ? '10px' : 0 }}>
          {other.map((file) => (
            <FilePreview
              key={file.id}
              file={file}
              skipCompanyId={skipCompanyId}
              onRemove={onRemove}
              apiService={apiService}
            />
          ))}
        </FilesContainer>
      )}
      <FileGallery
        files={media}
        apiService={apiService}
        onClose={() => {
          setShowGalleryId(null);
        }}
        getFilePath={getFilePath}
        onUpdate={onUpdate}
        initialFileId={showGalleryId}
        skipCompanyId={skipCompanyId}
        disableVideoPlayback={disableVideoPlayback}
      />
    </div>
  );
};

export default Previews;
