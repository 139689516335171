import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../../frameworks/redux';

const getFilters = createSelector(
  (state: IRootState) => state.reports.custom.filters,
  (filters) => filters
);

export const customReportSelectors = {
  getFilters,
  getTotalCount: createSelector(
    (state: IRootState) => state.reports.custom.totalCount,
    (meta) => meta
  ),
  getLoading: createSelector(
    (state: IRootState) => state.reports.custom.loading,
    (loading) => loading
  ),
  isDataCategoryFetch: createSelector(
    (state: IRootState) => state.reports.custom.isDataCategoryFetch,
    (loading) => loading
  ),
  getError: createSelector(
    (state: IRootState) => state.reports.custom.error,
    (error) => error
  ),
  getColumns: createSelector(
    (state: IRootState) => state.reports.custom.columns,
    (columns) => columns.ids.map((id: string) => columns.entities[id])
  ),
  getColumnsMap: createSelector(
    (state: IRootState) => state.reports.custom.columns.entities,
    (columns) => columns
  ),
  getTableRows: createSelector(
    [(state: IRootState) => state.reports.custom.rows, getFilters],
    (rows, { selectedColumns }) => {
      return (rows || []).map((cols: string[], j: number) =>
        cols.reduce((acc: any, col: string, i: number) => {
          if (i === 0) {
            acc.key = j;
          }

          acc[selectedColumns[i]] = col;

          return acc;
        }, {})
      );
    }
  ),
  getExportModalType: (state: IRootState) =>
    state.reports.custom.exportModalType,
};
