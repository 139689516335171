import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { MQAIcon } from '@components/shared/MQAIcon/MQAIcon';

export const Icon = styled(MQAIcon)`
  margin: 0 auto 32px;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 0;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  padding: 162px 60px;
`;
