import { MessageEvent } from 'pubnub';
import { usePubNub } from 'pubnub-react';
import { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-async-hook';

import { useInterval } from '@hooks/misc';

interface RealtimeEvent<T> extends Omit<MessageEvent, 'message'> {
  message: T;
}

export function useRealtimeSync<T = any>({
  channelId,
  active,
  syncInbound,
  hasChangesForInboundSync,
  registerRealtimeClient,
  syncOutbound,
  outboundSyncRequired,
}: {
  channelId: string;
  active: boolean;
  syncInbound: (data: T[]) => Promise<void>;
  syncOutbound?: () => Promise<void>;
  outboundSyncRequired?: boolean;
  registerRealtimeClient: () => Promise<string>;
  hasChangesForInboundSync: (event: RealtimeEvent<T>) => boolean;
}) {
  const pubnub = usePubNub();

  const [outboundSyncInProgress, setOutboundSyncInProgress] = useState(false);
  const [inboundSyncRequired, setInboundSyncRequired] = useState(false);
  const [messages, setMessages] = useState<T[]>([]);

  const { result: token } = useAsync(
    async () => (active ? registerRealtimeClient() : null),
    [active]
  );

  const messagesRef = useRef(messages);
  messagesRef.current = messages;

  const pushMessageToQueue = (message: T) => {
    setMessages([...messagesRef.current, message]);
  };

  useEffect(() => {
    const listener = {
      message: (event: RealtimeEvent<T>) => {
        if (hasChangesForInboundSync(event)) {
          pushMessageToQueue(event.message);
          setInboundSyncRequired(true);
        }
      },
    };

    if (token) {
      pubnub.setToken(token);
      pubnub.addListener(listener);
      pubnub.subscribe({ channels: [channelId] });
      console.log('subscribed to ', channelId);
    }

    return () => {
      if (token) {
        pubnub.removeListener(listener);
        pubnub.unsubscribe({ channels: [channelId] });
        console.log('unsubscribed from ', channelId);
      }
    };
  }, [pubnub, token]);

  useEffect(() => {
    const handleOnlineChange = () => {
      setInboundSyncRequired(true);
    };

    window.addEventListener('online', handleOnlineChange);

    return () => {
      window.removeEventListener('online', handleOnlineChange);
    };
  }, []);

  useInterval(
    async () => {
      if (!window.navigator.onLine) {
        return;
      }

      if (outboundSyncRequired && !outboundSyncInProgress && !!syncOutbound) {
        setOutboundSyncInProgress(true);

        await syncOutbound();

        setOutboundSyncInProgress(false);
      }

      if (inboundSyncRequired) {
        await syncInbound(messages);
        setMessages([]);
        setInboundSyncRequired(false);
      }
    },
    active ? 4000 : null
  );
}
