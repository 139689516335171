import { AuthService } from '@repo/shared/auth';
import { Logger } from '@repo/shared/services';

const USER_PREFS_LS_KEY = 'mqa_user_prefs';

const _createAbsentAuthUserIdError = (funcName: string) =>
  new Error(
    `userPreferences ${funcName} function was called without authUserId`
  );

export async function getItem<T = string | number | boolean>(
  name: string
): Promise<T | undefined> {
  const authUserId = await AuthService.getAuthUserId();

  if (!authUserId) {
    Logger.captureException(_createAbsentAuthUserIdError('getItem'));
    return undefined;
  }

  try {
    const userPreferences = JSON.parse(
      localStorage.getItem(USER_PREFS_LS_KEY) || '{}'
    );

    return userPreferences[authUserId]?.[name];
  } catch (e) {
    Logger.captureException(e);
    return undefined;
  }
}

export function getItemSync<T = string | number | boolean>(
  authUserId: string,
  name: string
): T | undefined {
  try {
    const userPreferences = JSON.parse(
      localStorage.getItem(USER_PREFS_LS_KEY) || '{}'
    );

    return userPreferences[authUserId]?.[name];
  } catch (e) {
    Logger.captureException(e);
    return undefined;
  }
}

export async function setItem<T = string | number | boolean>(
  name: string,
  value: T | undefined
): Promise<boolean> {
  const authUserId = await AuthService.getAuthUserId();

  if (!authUserId) {
    Logger.captureException(_createAbsentAuthUserIdError('setItem'));
    return false;
  }

  try {
    const userPreferences = JSON.parse(
      localStorage.getItem(USER_PREFS_LS_KEY) || '{}'
    );

    if (userPreferences[authUserId] === undefined) {
      userPreferences[authUserId] = {};
    }

    userPreferences[authUserId][name] = value;

    localStorage.setItem(USER_PREFS_LS_KEY, JSON.stringify(userPreferences));

    return true;
  } catch (e) {
    Logger.captureException(e);
    return false;
  }
}

export async function deleteItem(name: string): Promise<boolean> {
  const authUserId = await AuthService.getAuthUserId();

  if (!authUserId) {
    Logger.captureException(_createAbsentAuthUserIdError('deleteItem'));
    return false;
  }

  try {
    const userPreferences = JSON.parse(
      localStorage.getItem(USER_PREFS_LS_KEY) || '{}'
    );

    if (
      userPreferences[authUserId] === undefined ||
      userPreferences[authUserId][name] === undefined
    ) {
      return false;
    }

    delete userPreferences[authUserId][name];

    localStorage.setItem(USER_PREFS_LS_KEY, JSON.stringify(userPreferences));

    return true;
  } catch (e) {
    Logger.captureException(e);
    return false;
  }
}
