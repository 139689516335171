import styled from 'styled-components';
import { animated } from 'react-spring';

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  line-height: 26px;
`;

export const BackButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-areas: 'step';
  flex-grow: 1;
`;

export const StepWrapper = styled(animated.div)`
  grid-area: step;
  will-change: opacity;
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 1;
  }
`;
