import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

export const Container = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.gray3};

  &:last-child {
    border-bottom: none;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const Name = styled.button`
  background: none;
  border: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-align: left;

  &:disabled {
    text-decoration: none;
  }
`;

export const AuditObjectName = styled.div`
  margin-bottom: 8px;
`;

export const LateStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 8px;
  white-space: nowrap;

  &:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${({ isLate }: { isLate: boolean | null }) =>
      isLate ? colors.red : colors.green};
    margin-right: 5px;
  }
`;

export const ExpiredStatus = styled.div`
  margin-left: 7px;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: ${colors.red};
  background: ${hexToRGB(colors.red, 0.2)};
`;
