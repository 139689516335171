import { v4 as uuid } from 'uuid';

export function isProduction() {
  return import.meta.env.VITE_ENV === 'production';
}

export function isLocal() {
  return import.meta.env.VITE_ENV === 'development';
}

export function setBrowserTabId() {
  const tabId =
    sessionStorage.tabId && sessionStorage.closedLastTab !== '2'
      ? sessionStorage.tabId
      : (sessionStorage.tabId = uuid());

  sessionStorage.closedLastTab = '2';

  'beforeunload unload'.split(' ').forEach((event) => {
    window.addEventListener(event, () => {
      sessionStorage.closedLastTab = '1';
    });
  });

  return tabId;
}

export function resetBrowserTabId() {
  delete sessionStorage.tabId;
  delete sessionStorage.closedLastTab;
}
