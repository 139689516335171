import React, { useState } from 'react';
import Dropdown from 'antd/es/dropdown';
import Spin from 'antd/es/spin';
import { ChevronDown } from 'react-feather';
import { ButtonType } from 'antd/es/button';

import { Button, ItemLabel } from './styled';

interface IProps {
  type?: ButtonType;
  triggerText: string;
  buttons: {
    content: React.ReactNode | string;
    onClick: () => Promise<any>;
    e2eDataAttr?: string;
  }[];
  width?: number;
  e2eDataAttr?: string;
}

const BigBtnDropdown: React.FC<React.PropsWithChildren<IProps>> = ({
  type,
  buttons,
  triggerText,
  width,
  e2eDataAttr,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dropdown
      menu={{
        items: buttons.map(({ content, onClick, e2eDataAttr }, i) => ({
          key: i,
          'data-e2e': e2eDataAttr,
          onClick: async () => {
            setLoading(true);
            await onClick();
            setLoading(false);
          },
          label: <ItemLabel>{content}</ItemLabel>,
        })),
      }}
      overlayStyle={{
        borderRadius: 10,
        width: width ? `${width}px` : 'auto',
        zIndex: 5000,
      }}
    >
      <Spin spinning={loading}>
        <Button type={type || 'primary'} data-e2e={e2eDataAttr}>
          {triggerText}
          <ChevronDown />
        </Button>
      </Spin>
    </Dropdown>
  );
};

export default BigBtnDropdown;
