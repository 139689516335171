import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Title, CloseModalButton } from './styled';
import { ArrowLeft } from '@icons';
import { useAppDispatch } from '@hooks';
import { usersActions, usersSelectors } from '@store';
import { routes } from '@config';
import { e2eTestElements } from '@config';

import PageHeader from '@components/shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

export enum UserPage {
  Details,
  Groups,
  Access,
}

interface IProps {
  page: UserPage;
  extra?: React.ReactNode;
}

const UserContentWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  children,
  extra,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const { loading, data } = useSelector(usersSelectors.list.getUserDetails);

  useEffect(() => {
    if (!id) {
      navigate(routes.users.list);
      return;
    }

    if (!data || data.id !== id) {
      dispatch(usersActions.list.getUserDetails(id));
    }
  }, [id, data]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            <CloseModalButton
              type="button"
              onClick={() => {
                navigate(routes.users.list);
              }}
            >
              <ArrowLeft />
            </CloseModalButton>
            <Title>
              {loading ? (
                <Skeleton.Input
                  active
                  size="small"
                  style={{ borderRadius: 5 }}
                />
              ) : (
                data?.name
              )}
            </Title>
          </Space>
        }
        extra={extra}
        footer={
          <PageTabbedSwitcher<UserPage>
            pages={[
              {
                onClick() {
                  if (data) {
                    navigate(routes.users.userDetails.replace(':id', data.id));
                  }
                },
                name: formatMessage({ id: 'Profile' }),
                id: UserPage.Details,
                e2eDataAttr: e2eTestElements.users.pages.profile,
              },
              {
                onClick() {
                  if (data) {
                    navigate(routes.users.userGroups.replace(':id', data.id));
                  }
                },
                name: formatMessage({ id: 'Groups' }),
                id: UserPage.Groups,
                e2eDataAttr: e2eTestElements.users.pages.groups,
              },
              {
                onClick() {
                  if (data) {
                    navigate(routes.users.userAccess.replace(':id', data.id));
                  }
                },
                name: formatMessage({ id: 'Access' }),
                id: UserPage.Access,
                e2eDataAttr: e2eTestElements.users.pages.access,
              },
            ]}
            activePage={page}
          />
        }
      />
      {children}
    </>
  );
};

export default UserContentWrapper;
