import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  padding: 25px;
`;

export const AssigneesList = styled.div`
  margin-bottom: 20px;
`;

export const AssigneesListLabel = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  margin-bottom: 5px;
`;
