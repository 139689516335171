import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const HeaderActionButton = styled.button`
  display: inline-block;
  background: ${colors.white};
  padding: 0;
  border: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 8px;
  border-radius: 100%;
  transition: background-color 0.2s ease-in;
  text-align: center;

  &:hover {
    background: ${colors.gray5};
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    width: 19.2px;
    height: 19.2px;
  }
`;
