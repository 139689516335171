import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';

import { config } from '@repo/shared/config';

export const withMapsApiProvider =
  <P extends object>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  ({ ...props }) => {
    return (
      <APIProvider apiKey={config.googleApiKey} language="en">
        <WrappedComponent {...(props as P)} />
      </APIProvider>
    );
  };
