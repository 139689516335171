import { InternalApiService } from './InternalApiService';
import { IApiService } from '../types';

export class BaseApiClient {
  protected _apiService: IApiService;

  constructor(apiService?: IApiService) {
    this._apiService = apiService || InternalApiService.getInstance();
  }
}
