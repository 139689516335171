import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { IUser } from '@repo/shared/types';
import { Buttons } from './styled';
import { useAppDispatch } from '@hooks';
import {
  accountActions,
  accountSelectors,
  generalActions,
  generalSelectors,
  rolesActions,
  usersActions,
  usersSelectors,
} from '@store';
import { disallowURL, validations, notification, disallowEmail } from '@utils';
import {
  AnalyticsEvent,
  RoleType,
  SelfGuidedTourStep,
} from '@repo/shared/enums';
import { delay } from '@repo/shared/utils';
import { config } from '@repo/shared/config';
import { e2eTestElements } from '@config';

import Form from '../../../shared/ant/Form';
import { Input } from '../../../shared/ant/Input';
import RolesSelect from './RolesSelect';
import Button from '../../../shared/ant/Button';
import TourPopover from '@components/account/SelfGuidedTour/TourPopover/TourPopover';

interface IProps {
  data?: IUser;
  closeModal: () => void;
}

const AddEditUserModalForm: React.FC<React.PropsWithChildren<IProps>> = ({
  data: user,
  closeModal,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const { step } = useSelector(accountSelectors.getSelfGuidedTour);
  const { users } = useSelector(usersSelectors.list.getData);
  const { data: userDetails } = useSelector(usersSelectors.list.getUserDetails);
  const rolesMap = useSelector(generalSelectors.getConciseRolesMap);

  const isEditMode = !!user;
  const isCurrentUserAdmin = currentUser?.role.roleType === RoleType.Admin;
  const itemName = formatMessage({ id: 'User' }).toLowerCase();
  const disabled = loading || (!isCurrentUserAdmin && isEditMode);

  return (
    <Form
      onFinish={async (values: any) => {
        setLoading(true);

        const update = {
          id: user?.id,
          ...values,
        };

        const resultAction = await dispatch(
          usersActions.list.updateUser(update)
        );

        if (usersActions.list.updateUser.fulfilled.match(resultAction)) {
          const hasUsersCreatedByCurrentUser = users.some(
            ({ isSample, id }) => !isSample && id !== currentUser.id
          );

          if (!hasUsersCreatedByCurrentUser) {
            dispatch(
              accountActions.sendAnalyticsEvent(AnalyticsEvent.AddUsers)
            );
          }

          update.name = update.fullName;

          if (!!userDetails && userDetails.id === user?.id) {
            const userDetailsUpdate = {
              ...userDetails,
              ...update,
            };

            userDetailsUpdate.role = {
              ...rolesMap[values.roleId],
            };

            delete userDetailsUpdate.roleId;

            dispatch(usersActions.list.updateUserDetails(userDetailsUpdate));
          }

          closeModal();
          dispatch(usersActions.list.getData());
          dispatch(rolesActions.getRoles());
          dispatch(generalActions.getConciseUsers());

          notification.success({
            message: isEditMode
              ? formatMessage(
                  { id: 'YouHaveSuccessfullyEditedItem' },
                  { item: itemName }
                )
              : formatMessage(
                  { id: 'YouHaveSuccessfullyCreatedItem' },
                  { item: itemName }
                ),
          });
        } else {
          if (resultAction.payload === 'subscription/limit') {
            notification.error({
              message: formatMessage({
                id: 'YouHaveExceededTheNumberOfPaidUsers',
              }),
              description: (
                <>
                  {formatMessage(
                    {
                      id: 'PleaseContactSalesToPurchaseAdditionalUserLicenses',
                    },
                    {
                      email: (
                        <a href={`mailto:${config.urls.salesEmail}`}>
                          {config.urls.salesEmail}
                        </a>
                      ),
                    }
                  )}{' '}
                  {formatMessage({
                    id: 'AlternativelyYouMayDeleteExistingUsersInOrderToFreeUpLicenses',
                  })}
                </>
              ),
              duration: 5,
            });
          } else {
            notification.error({
              message: isEditMode
                ? formatMessage(
                    { id: 'ErrorWhileCreatingItem' },
                    { item: itemName }
                  )
                : formatMessage(
                    { id: 'ErrorWhileEditingItem' },
                    { item: itemName }
                  ),
              description: resultAction.payload as string,
            });
          }
        }

        await delay(200);

        setLoading(false);
      }}
      name="add-new-user-form"
      layout="vertical"
      initialValues={{
        fullName: user?.name || '',
        email: user?.email || '',
        roleId: user?.role?.id || null,
        phone: user?.phone || '',
        tagsIds: user?.tags?.map(({ id }) => id) || [],
      }}
    >
      <TourPopover
        step={SelfGuidedTourStep.AddUserFormNameEmail}
        placement="left"
        overlayStyle={{
          height: 200,
          marginTop: -50,
        }}
      >
        <div />
      </TourPopover>
      <Form.Item
        name="fullName"
        label={formatMessage({ id: 'FullName' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseEnterYourName' }),
          },
          {
            max: 300,
            message: formatMessage(
              {
                id: 'NameMustNotExceedXCharacters',
              },
              { count: 300 }
            ),
          },
          disallowEmail(),
          disallowURL(),
        ]}
      >
        <Input
          disabled={disabled}
          placeholder="e.g. John Doe"
          data-e2e={e2eTestElements.users.addEditUserForm.fullNameInput}
        />
      </Form.Item>
      <Form.Item
        name="email"
        label={formatMessage({ id: 'EmailAddress' })}
        rules={[
          {
            type: 'email',
            message: formatMessage({ id: 'PleaseEnterValidEmail' }),
          },
          {
            required: true,
            message: formatMessage({ id: 'PleaseEnterYourEmail' }),
          },
          {
            max: 450,
            message: formatMessage(
              {
                id: 'NameMustNotExceedXCharacters',
              },
              { count: 450 }
            ),
          },
        ]}
      >
        <Input
          maxLength={450}
          disabled={loading || isEditMode}
          placeholder="e.g. mail@monitorqa.com"
          data-e2e={e2eTestElements.users.addEditUserForm.emailInput}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        label={formatMessage({ id: 'Phone' })}
        rules={[
          {
            pattern: validations.phone,
            message: formatMessage({
              id: 'PhoneNumberMustContainFrom10To12Chars',
            }),
          },
          {
            max: 50,
            message: formatMessage(
              {
                id: 'NameMustNotExceedXCharacters',
              },
              { count: 50 }
            ),
          },
        ]}
      >
        <Input
          disabled={disabled}
          maxLength={50}
          data-e2e={e2eTestElements.users.addEditUserForm.phoneInput}
          placeholder={formatMessage({ id: 'EnterPhoneNumber' })}
        />
      </Form.Item>
      <TourPopover
        step={SelfGuidedTourStep.AddUserFormRoles}
        placement="left"
        overlayStyle={{
          height: 270,
        }}
        measureDelay={300}
      >
        <Form.Item
          name="roleId"
          label={formatMessage({ id: 'Role' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'PleaseSelectUserRole' }),
            },
          ]}
        >
          <RolesSelect
            open={step === SelfGuidedTourStep.AddUserFormRoles || undefined}
            placeholder={formatMessage({ id: 'SelectRole' })}
            disabled={loading || (!isCurrentUserAdmin && isEditMode)}
            e2eDataAttr={e2eTestElements.users.addEditUserForm.roleSelect}
          />
        </Form.Item>
      </TourPopover>
      <Buttons>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          data-e2e={e2eTestElements.users.addEditUserForm.addEditButton}
        >
          {isEditMode
            ? formatMessage({ id: 'Save' })
            : formatMessage({ id: 'AddAndInviteUser' })}
        </Button>
        <Button
          type="default"
          data-e2e={e2eTestElements.users.addEditUserForm.cancelButton}
          onClick={() => {
            form.resetFields();
            setTimeout(() => {
              closeModal();
            }, 50);
          }}
        >
          {formatMessage({ id: 'Cancel' })}
        </Button>
      </Buttons>
    </Form>
  );
};

export default AddEditUserModalForm;
