import React from 'react';
import { useSelector } from 'react-redux';

import { ActionDetails } from '@domain/Actions/models/Action';
import { e2eTestElements, routes } from '@config';
import { accountSelectors } from '@store';
import { usePermission } from '@hooks';
import { Permission, RoleType } from '@repo/shared/enums';

import ActionDetailsInfoItem from '@src/presentation/Actions/ActionDetails/ActionDetailsInfo/ActionDetailsInfoItem/ActionDetailsInfoItem';
import ExternalLink from '@components/shared/ExternalLink/ExternalLink';

interface IProps {
  action: ActionDetails;
}

const ActionsDetailsAudit: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
}) => {
  const objectName = useSelector(accountSelectors.getObjectName);

  const isAdmin = useSelector(accountSelectors.isAdmin);

  if (!action.auditObject) {
    return null;
  }

  return (
    <ActionDetailsInfoItem
      label={objectName.single}
      content={
        <ExternalLink
          disabled={!isAdmin}
          data-e2e={e2eTestElements.actions.detailsModal.auditObject}
          to={routes.auditObjects.auditObjectDetails.replace(
            ':id',
            action.auditObject.id
          )}
        >
          {action.auditObject.name}
        </ExternalLink>
      }
    />
  );
};

export default ActionsDetailsAudit;
