import React, { lazy, Suspense } from 'react';

import { PieChartProps } from './PieChartProps';

import Loader from '../Loader';
const PieChartInner = lazy(() => import('./PieChartInner'));

const PieChart: React.FC<React.PropsWithChildren<PieChartProps>> = (props) => {
  return (
    <Suspense
      fallback={
        <Loader
          containerStyles={{
            width: `${props.width}px`,
            height: `${props.height}px`,
          }}
        />
      }
    >
      <PieChartInner {...props} />
    </Suspense>
  );
};

export default PieChart;
