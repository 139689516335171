export const objects = {
  createDropdown: 'objects-header-create-dropdown',
  createObject: 'objects-header-create-object',
  createObjectGroup: 'objects-header-create-object-group',
  addEditObject: {
    closeModal: 'objects-add-edit-close-modal',
    nameInput: 'objects-add-edit-name-input',
    participantsSelect: 'objects-add-edit-participants-select',
    tagsSelect: 'objects-add-edit-tags-select',
    timeZoneSelect: 'objects-add-edit-tz-select',
    locationSwitch: 'objects-add-edit-location-switch',
    locationInput: 'objects-add-edit-location-input',
    addEditButton: 'objects-add-edit-submit-button',
    cancelButton: 'objects-add-edit-cancel-button',
    notes: 'objects-add-edit-notes',
  },
  table: 'objects-table',
  groupsTable: 'audit-objects-groups-table',
  attributes: {
    openAddEditModal: 'objects-attributes-openAddEditModal',
    closeAddEditModal: 'objects-attributes-closeAddEditModal',
    nameInput: 'objects-attributes-nameInput',
    fieldTypeSelect: 'objects-attributes-fieldTypeSelect',
    defaultOptionSelect: 'objects-attributes-defaultOptionSelect',
    dropdownTypeOptions: 'objects-attributes-dropdownTypeOptions',
    createBtn: 'objects-attributes-createBtn',
    cancelBtn: 'objects-attributes-cancelBtn',
    closeBtn: 'objects-attributes-closeBtn',
  },
  attributesAssignments: {
    openAssignAttributeModal:
      'objects-attributesAssignments-openAssignAttributeModal',
    closeAssignAttributeModal:
      'objects-attributesAssignments-closeAssignAttributeModal',
    selectAttributeDropdown:
      'objects-attributesAssignments-selectAttributeDropdown',
    selectDropdownAttributeValueDropdown:
      'objects-attributesAssignments-selectDropdownAttributeValueDropdown',
    selectInputAttributeValue:
      'objects-attributesAssignments-selectInputAttributeValue',
    selectObjectDropdown: 'objects-attributesAssignments-selectObjectDropdown',
    assignBtn: 'objects-attributesAssignments-assignBtn',
    cancelBtn: 'objects-attributesAssignments-cancelBtn',
  },
  groupsAssignments: {
    table: 'objects-groupsAssignments-table',
    openAddToGroupModal: 'objects-groupsAssignments-openAddToGroupModal',
    closeAddToGroupModal: 'objects-groupsAssignments-closeAddToGroupModal',
    selectGroupDropdown: 'objects-groupsAssignments-selectGroupDropdown',
    selectObjectsDropdown: 'objects-groupsAssignments-selectObjectsDropdown',
    addToGroupBtn: 'objects-groupsAssignments-addToGroupBtn',
    cancelBtn: 'objects-groupsAssignments-cancelBtn',
  },
};
