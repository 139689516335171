import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IConcise, IPagedResponse, ITableFilters } from '@repo/shared/types';
import {
  IssueType,
  IssueTypeDetails,
} from '@domain/IssueTypes/models/IssueType';
import IssueTypesApiClient from '@infrastructure/IssueTypes/api/IssueTypesApiClient';
import { Logger } from '@repo/shared/services';
import { getErrorMessage } from '@repo/shared/utils';
import { issueTypesSelectors } from '@application/IssueTypes/store/issueTypesSelectors';
import { IRootState } from '@src/core/frameworks/redux';
import IssueTypeFieldsApiClient from '@infrastructure/IssueTypes/api/IssueTypeFieldsApiClient';
import { IssueTypeField } from '@domain/IssueTypes/models/IssueTypeField';
import { UnexpectedError } from '@repo/shared/errors';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';

const issueTypesApiClient = new IssueTypesApiClient();
const issueTypeFieldsApiClient = new IssueTypeFieldsApiClient();

const getIssueTypes = createAsyncThunk<
  IPagedResponse<IssueType>,
  Partial<ITableFilters> | undefined,
  { state: IRootState; rejectValue: string }
>('issueTypes/get', async (_, { rejectWithValue, getState }) => {
  try {
    return await issueTypesApiClient.getIssueTypes(
      issueTypesSelectors.getIssueTypesFilters(getState())
    );
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const getConciseIssueTypes = createAsyncThunk<
  IConcise[],
  void,
  { rejectValue: string }
>('issueTypes/getConciseIssueTypes', async (_, { rejectWithValue }) => {
  try {
    return await issueTypesApiClient.getConciseIssueTypes();
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const resetIssueTypes = createAction('issueTypes/resetIssueTypes');

const createIssueType = createAsyncThunk<string, Omit<IssueType, 'id'>>(
  'issueTypes/create',
  async (issueType, { rejectWithValue }) => {
    try {
      return await issueTypesApiClient.createIssueType(issueType);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const editIssueType = createAsyncThunk<string, IssueType>(
  'issueTypes/edit',
  async (issueType, { rejectWithValue }) => {
    try {
      await issueTypesApiClient.editIssueType(issueType);
      return issueType.id;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteIssueTypes = createAsyncThunk<void, string[]>(
  'issueTypes/delete',
  async (ids, { rejectWithValue }) => {
    try {
      await issueTypesApiClient.deleteIssueTypes(ids);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const getIssueTypeFields = createAsyncThunk<
  IPagedResponse<IssueTypeField>,
  Partial<ITableFilters> | undefined,
  { state: IRootState; rejectValue: string }
>('issueTypeFields/get', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const issueTypeDetails = issueTypesSelectors.getIssueTypeDetails(state);

    if (!issueTypeDetails.data) {
      throw new UnexpectedError('Issue type details is not set');
    }

    return await issueTypeFieldsApiClient.getIssueTypeFields(
      issueTypeDetails.data.id,
      issueTypesSelectors.getIssueTypeFieldsFilters(state)
    );
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const resetIssueType = createAction('issueTypes/resetIssueType');

const getIssueTypeDetails = createAsyncThunk<
  IssueTypeDetails,
  { issueTypeId: string; companyId?: string },
  { rejectValue: string }
>(
  'issueTypes/getDetails',
  async ({ issueTypeId, companyId }, { rejectWithValue }) => {
    try {
      return await (companyId
        ? issueTypesApiClient.getIssueTypeDetailsPublic(companyId, issueTypeId)
        : issueTypesApiClient.getIssueTypeDetails(issueTypeId));
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const createIssueTypeField = createAsyncThunk<
  void,
  {
    issueTypeId: string;
    issueTypeField: Omit<IssueTypeField, 'id'>;
  },
  {
    rejectValue: string;
  }
>(
  'issueTypes/create',
  async ({ issueTypeId, issueTypeField }, { rejectWithValue }) => {
    try {
      await issueTypeFieldsApiClient.createIssueTypeField(
        issueTypeId,
        issueTypeField
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const editIssueTypeField = createAsyncThunk<void, IssueTypeField>(
  'issueTypeFields/edit',
  async (issueTypeField, { rejectWithValue, getState }) => {
    try {
      const state = getState();

      const issueTypeDetails = issueTypesSelectors.getIssueTypeDetails(state);

      if (!issueTypeDetails.data) {
        throw new UnexpectedError(
          'Issue type details are not in the configureStore'
        );
      }

      await issueTypeFieldsApiClient.editIssueTypeField(
        issueTypeDetails.data.id,
        issueTypeField
      );
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const deleteIssueTypeFields = createAsyncThunk<
  void,
  string[],
  { rejectValue: string }
>('issueTypeFields/delete', async (ids, { rejectWithValue, getState }) => {
  try {
    const state = getState();

    const issueTypeDetails = issueTypesSelectors.getIssueTypeDetails(state);

    if (!issueTypeDetails.data) {
      throw new UnexpectedError(
        'Issue type details are not in the configureStore'
      );
    }

    await issueTypeFieldsApiClient.deleteIssueTypeFields(
      issueTypeDetails.data.id,
      ids
    );
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const toggleAddEditIssueTypeFieldsModal = createAction<{
  show: boolean;
  issueTypeFieldId?: string;
}>('issueTypes/toggleAddEditIssueTypeFieldsModal');

const toggleConfirmDeleteIssueTypesModal = createAction<
  DeleteModalState<IssueType>
>('issueTypes/toggleConfirmDeleteIssueTypesModal');

const toggleConfirmDeleteIssueTypeFieldsModal = createAction<
  DeleteModalState<IssueTypeField>
>('issueTypes/toggleConfirmDeleteIssueTypeFieldsModal');

const issueTypesActions = {
  getIssueTypes,
  getConciseIssueTypes,
  resetIssueTypes,
  createIssueType,
  editIssueType,
  deleteIssueTypes,
  getIssueTypeDetails,
  getIssueTypeFields,
  resetIssueType,
  createIssueTypeField,
  editIssueTypeField,
  deleteIssueTypeFields,
  toggleAddEditIssueTypeFieldsModal,
  toggleConfirmDeleteIssueTypesModal,
  toggleConfirmDeleteIssueTypeFieldsModal,
};

export { issueTypesActions };
