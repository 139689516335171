import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';

import SchedulePlansHeader from '@presentation/SchedulePlans/SchedulePlansHeader';
import DeleteSchedulePlansModal from '@presentation/SchedulePlans/DeleteSchedulePlansModal';
import SchedulePlansActionsModal from '@presentation/SchedulePlans/SchedulePlansActionsModal';
import AddEditSchedulePlanModal from '@presentation/SchedulePlans/AddEditSchedulePlanModal';
import SchedulePlansTable from '@presentation/SchedulePlans/SchedulePlansTable/SchedulePlansTable';

interface IProps {}

const SchedulePlans: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(schedulePlansActions.getSchedulePlans());
  }, []);

  return (
    <>
      <SchedulePlansHeader />
      <SchedulePlansTable />
      <DeleteSchedulePlansModal />
      <SchedulePlansActionsModal />
      <AddEditSchedulePlanModal />
    </>
  );
};

export default SchedulePlans;
