import React from 'react';

import RolesHeader from './RolesHeader/RolesHeader';
import RolesTable from './RolesTable/RolesTable';
import RolesActionsModal from './RolesActionsModal';
import DeleteRolesModal from './DeleteRolesModal';
import AddEditRoleModal from './AddEditRoleModal/AddEditRoleModal';
import ViewRoleModal from './ViewRoleModal/ViewRoleModal';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';

const Roles: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SampleDataAlert />
      <RolesHeader />
      <RolesTable />
      <RolesActionsModal />
      <DeleteRolesModal />
      <AddEditRoleModal />
      <ViewRoleModal />
    </>
  );
};

export default Roles;
