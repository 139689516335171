export const audits = {
  table: 'audits-table',
  viewAuditDetailsModal: {
    startAuditBtn: 'audits-view-details-modal-start-btn',
    showReportBtn: 'audits-view-details-modal-show-report-btn',
  },
  createAdhocAuditModal: {
    auditTemplateSelect: 'create-adhoc-audit-modal-auditTemplateSelect',
    auditName: 'create-adhoc-audit-modal-auditName',
    auditObjectSelect: 'create-adhoc-audit-modal-auditObjectSelect',
    dueDate: 'create-adhoc-audit-modal-dueDate',
    createBtn: 'create-adhoc-audit-modal-createBtn',
    cancelBtn: 'create-adhoc-audit-modal-cancelBtn',
    auditorHint: 'create-adhoc-audit-modal-auditor-hint',
    assignees: 'create-adhoc-audit-modal-assigned-to',
  },
  header: {
    createAdhocAudit: 'audits-createAdhocAudit',
  },
  performAudit: {
    sectionStats: 'perform-audit-section-stats',
    summarySection: 'perform-audit-summary-section-',
    sectionNABtn: 'perform-audit-section-na-btn',
    answer: {
      noteBtn: 'audit-answer-note-btn',
      photosBtn: 'audit-answer-photos-btn',
      actionBtn: 'audit-answer-action-btn',
      flagBtn: 'audit-answer-flag-btn',
      signBtn: 'audit-answer-sign-btn',
      buttons: {
        btn: 'audit-answer-buttons-btn',
      },
    },
    naCheckbox: 'audit-answer-na-checkbox',
    finishAuditBtn: 'perform-audit-finish-audit-btn',
    footer: {
      prevSection: 'perform-audit-prev-section-btn',
      nextSection: 'perform-audit-next-section-btn',
    },
    addEditActionModal: {
      nameInput: 'add-edit-action-modal-name-input',
      descInput: 'add-edit-action-modal-desc-input',
      dueDate: 'add-edit-action-modal-due-date-input',
      assignedTo: 'add-edit-action-modal-assigned-to',
      tagSelect: 'add-edit-action-modal-tag-select',
      prioritySelect: 'add-edit-action-modal-priority-select',
      attachPhotoInput: 'add-edit-action-modal-attach-photo-input',
      saveEditBtn: 'add-edit-action-modal-attach-save-edit-btn',
      cancelBtn: 'add-edit-action-modal-attach-cancel-btn',
    },
    naCheckConfirmModal: {
      yesBtn: 'na-check-confirm-modal-yes-btn',
      cancelBtn: 'na-check-confirm-modal-cancel-btn',
    },
  },
};
