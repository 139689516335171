import { createReducer, Reducer } from '@reduxjs/toolkit';

import { rolesActions as actions } from './roles.actions';
import { getInitialFilters } from '../../../utils/misc';
import { IRolesState } from '@types';
import { rolesAdapter } from '@store/entityAdapters';

export const rolesReducer: Reducer<IRolesState> = createReducer<IRolesState>(
  {
    entities: rolesAdapter.getInitialState(),
    loading: false,
    meta: null,
    error: null,
    filters: getInitialFilters(),
    addEditRoleModal: {
      show: false,
      role: null,
      rolePermissionTypes: {
        loading: false,
        types: [],
        error: null,
      },
    },
  },
  (builder) =>
    builder
      .addCase(actions.setFilters, (state, { payload }) => {
        state.filters = {
          ...state.filters,
          ...payload,
        };
      })
      .addCase(actions.getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.getRoles.fulfilled, (state, { payload }) => {
        rolesAdapter.setAll(state.entities, payload.data);
        state.meta = payload.meta;
        state.loading = false;
      })
      .addCase(actions.getRoles.rejected, (state) => {
        state.loading = false;
      })
      .addCase(actions.getRolePermissionTypes.pending, (state) => {
        state.addEditRoleModal.rolePermissionTypes.loading = true;
        state.addEditRoleModal.rolePermissionTypes.error = null;
      })
      .addCase(
        actions.getRolePermissionTypes.fulfilled,
        (state, { payload }) => {
          state.addEditRoleModal.rolePermissionTypes.loading = false;
          state.addEditRoleModal.rolePermissionTypes.types = payload;
        }
      )
      .addCase(
        actions.getRolePermissionTypes.rejected,
        (state, { payload }) => {
          state.addEditRoleModal.rolePermissionTypes.loading = false;
          state.addEditRoleModal.rolePermissionTypes.error = payload || null;
        }
      )
      .addCase(actions.showAddEditRoleModal, (state, { payload }) => {
        state.addEditRoleModal.show = true;
        state.addEditRoleModal.role = payload || null;
      })
      .addCase(actions.hideAddEditRoleModal, (state) => {
        state.addEditRoleModal = {
          show: false,
          role: null,
          rolePermissionTypes: {
            loading: false,
            types: [],
            error: null,
          },
        };
      })
);
