import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '@repo/shared/enums';
import { generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';

import SlideModal from '../SideModal/SideModal';
import { IAnyObject } from '@repo/shared/types';

interface IProps {
  children: ({
    data,
    closeModal,
  }: {
    data?: IAnyObject;
    closeModal: () => void;
    opened: boolean;
  }) => React.ReactNode;
  onClose?: () => void;
}

const ViewModal: React.FC<IProps> = ({ children, onClose }) => {
  const dispatch = useAppDispatch();
  const modalState = useSelector(generalSelectors.getModal(Modal.View));

  const closeModal = useCallback(() => {
    dispatch(generalActions.hideModal(Modal.View));

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [dispatch, onClose]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, [closeModal]);

  const { data, opened } = modalState;

  return (
    <SlideModal
      opened={opened}
      onModalClose={() => closeModal()}
      title={data?.title}
      headerAlign="center"
      subTitle={data?.subTitle}
    >
      {children({
        data,
        closeModal,
        opened,
      })}
    </SlideModal>
  );
};

export default ViewModal;
