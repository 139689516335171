import { Button } from './styled';

interface IProps {
  label: string;
  onClick?: () => void;
  active: boolean;
  e2eDataAttr?: string;
}

function ActionButton({ label, onClick, active, e2eDataAttr }: IProps) {
  return (
    <Button
      type="button"
      data-e2e={e2eDataAttr}
      onClick={onClick || (() => {})}
      active={active}
    >
      {label}
    </Button>
  );
}

export default ActionButton;
