import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

export const Container = styled.div`
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background: ${({ isEditing }: { isEditing?: boolean }) =>
    isEditing ? hexToRGB(colors.blue2, 0.1) : colors.gray1};
  transition: background-color 0.2s ease-in-out;
  width: 560px;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const Extra = styled.div`
  display: flex;
  align-items: center;

  & > * {
    &:last-child {
      //margin-left: 10px;
    }
  }
`;

export const CommentDate = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: ${colors.gray8};
`;

export const Body = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.gray10};

  span {
    font-size: 14px;
    line-height: 1;
  }

  & > * {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

export const Text = styled.div`
  font-size: 14px;
`;

export const PreviewsWrapper = styled.div`
  margin-top: 16px;
`;
