/**
 * Converts the given enum to a map of the keys to the values.
 * @param enumeration The enum to convert to a map.
 */
export function enumToMap<T extends string | number = string | number>(
  enumeration: any
): Map<string, T> {
  const map = new Map<string, T>();
  for (const key in enumeration) {
    //TypeScript does not allow enum keys to be numeric
    if (!isNaN(Number(key))) continue;

    const val = enumeration[key] as T;

    //TypeScript does not allow enum value to be null or undefined
    if (val !== undefined && val !== null) map.set(key, val);
  }

  return map;
}

export function enumToArray<T extends string | number = string | number>(
  enumeration: any
): [string, T][] {
  return Array.from(enumToMap<T>(enumeration).entries());
}

export function enumToSelectOptions<
  T extends string | number = string | number,
>(enumeration: any): { label: string; value: T }[] {
  return enumToArray<T>(enumeration).map(([label, value]) => ({
    label,
    value,
  }));
}

export function getEnumKeyByValue(
  myEnum: any,
  enumValue: number | string
): string {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : '';
}
