import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import Button from '@components/shared/ant/Button';
import { hexToRGB } from '@utils';

export const Container = styled.div`
  border-radius: 5px;
  background: ${colors.gray3};
  margin-bottom: 10px;
  width: 740px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px 12px;
  border-bottom: 1px solid ${colors.gray4};
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const Status = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px 16px;
  margin: 0 -20px;
  line-height: 1.3;

  & > * {
    margin: 0 20px;

    &:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

export const DetailLabel = styled.div`
  color: ${colors.gray8};
  margin-right: 12px;
`;

export const DetailValue = styled.div``;

export const DetailsButton = styled(Button)`
  background: ${colors.white}!important;
  width: 95px;
`;

export const ValueContainer = styled.div`
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
