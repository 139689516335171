import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ExternalLink as Icon } from 'react-feather';

import { Container } from './styled';

interface IProps extends LinkProps {
  disabled?: boolean;
}

const ExternalLink: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  ...props
}) => {
  return (
    <Container>
      {disabled ? (
        <span>{props.children}</span>
      ) : (
        <>
          <Link {...props} target="_blank" />
          <Icon />
        </>
      )}
    </Container>
  );
};

export default ExternalLink;
