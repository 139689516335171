import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div``;

export const DefinitionRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -16px 10px;

  & > * {
    padding: 0 16px;
    border-right: 1px solid ${colors.gray5};

    &:last-child {
      border-right: 0;
    }
  }
`;

export const PeriodContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -4px;

  & > * {
    margin: 0 4px;
  }

  button {
    height: auto;
    line-height: inherit;
    margin-top: -2px;
  }
`;
