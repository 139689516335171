import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { useAppDispatch, usePrevious } from '@hooks';
import { getRoutesFromIssuesPage } from '@application/Issues/utils/getRoutesFromIssuesPage';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { issuesSelectors } from '@application/Issues/store';
import { issuesActions } from '@application/Issues/store';

interface IProps {
  page: IssuesPage;
}

const IssueDetailsRouteHandler: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const issueId = useSelector(issuesSelectors.getIssueDetailsId);

  const prevStateIssueId = usePrevious(issueId);
  const prevParamIssueId = usePrevious(id);

  useEffect(() => {
    if (!prevStateIssueId && !prevParamIssueId && !issueId && id) {
      dispatch(issuesActions.toggleIssueDetailsModal(id));
    } else if (!issueId && id) {
      navigate(getRoutesFromIssuesPage(page).list);
    } else if (issueId && !id) {
      navigate(getRoutesFromIssuesPage(page).single.replace(':id', issueId));
    }
  }, [issueId, id, page]);

  return null;
};

export default IssueDetailsRouteHandler;
