import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '../../../../core/utils/misc';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export enum DueDateLabelType {
  Warning,
  Error,
}

export const Label = styled.div`
  margin-left: 7px;
  padding: 6px 12px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;

  ${({ type }: { type: DueDateLabelType }) => {
    if (type === DueDateLabelType.Warning) {
      return `
         color: ${colors.yellow};
         background: ${hexToRGB(colors.yellow, 0.2)};
      `;
    }

    if (type === DueDateLabelType.Error) {
      return `
         color: ${colors.orange};
         background: ${hexToRGB(colors.orange, 0.2)};
      `;
    }
  }}
`;
