import React, { Suspense, lazy, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { accountActions, accountSelectors } from '@store';
import { ActionButtonWrapper } from './styled';
import { AuditReportType, SelfGuidedTourStep } from '@repo/shared/enums';
import { routes } from '@config';
import { useAppDispatch } from '@hooks';

import ActionButton from '@repo/shared/components/FullScreenModal/ActionButton/ActionButton';
import Loader from '@repo/shared/components/Loader';
import TourPopoverInner from '@components/account/SelfGuidedTour/TourPopover/TourPopoverInner';
const AuditReport = lazy(() => import('@repo/audit-report/full'));

interface IProps {
  opened?: boolean;
  onClose?: () => void;
  auditId?: string | null;
}

const InternalAuditReport: React.FC<React.PropsWithChildren<IProps>> = ({
  opened,
  onClose,
  auditId,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const loading = useSelector(accountSelectors.getSelfGuidedTourLoading);
  const tourStep = useSelector(accountSelectors.getSelfGuidedTourStep);

  const company = useSelector(accountSelectors.getCompany);
  const locale = useSelector(accountSelectors.getLocale);

  const TourPopover = useCallback(
    (
      step: SelfGuidedTourStep,
      children: React.ReactNode,
      overlayStyle?: {
        marginTop?: number;
        marginLeft?: number;
        height?: number;
        width?: number;
      }
    ) => {
      return (
        <TourPopoverInner
          step={step}
          placement="left"
          overlayStyle={overlayStyle}
          visible={!loading && tourStep === step}
          loading={loading}
          setSpotlightBoundsInStore={(bounds) => {
            dispatch(accountActions.selfGuidedTour.setSpotlightBounds(bounds));
          }}
          onCancel={() => {
            dispatch(accountActions.selfGuidedTour.stop());
          }}
          onNextStep={() => {
            dispatch(accountActions.selfGuidedTour.nextStep());
          }}
        >
          {children}
        </TourPopoverInner>
      );
    },
    [loading, tourStep, opened]
  );

  if (!auditId) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <AuditReport
        opened={opened}
        auditId={auditId}
        locale={locale}
        type={AuditReportType.Internal}
        company={company}
        showShareButton
        showExportButton
        showHeaderTitle
        onClose={() => {
          if (onClose) {
            onClose();
          } else {
            navigate(routes.completedAudit.replace(':id', auditId));
          }
        }}
        header={
          <ActionButtonWrapper>
            <ActionButton active label={formatMessage({ id: 'Report' })} />
          </ActionButtonWrapper>
        }
        ReportContentWrapper={({ children }) =>
          TourPopover(SelfGuidedTourStep.AuditReport, children, {
            height: 720,
          })
        }
        ExportReportButtonWrapper={({ children }) =>
          TourPopover(SelfGuidedTourStep.ExportAuditReportPDF, children)
        }
        ReportSettingsWrapper={({ children }) =>
          TourPopover(SelfGuidedTourStep.CustomizeAuditReport, children)
        }
      />
    </Suspense>
  );
};

export default InternalAuditReport;
