import React from 'react';
import { SelectProps } from 'antd/es/select';
import { useSelector } from 'react-redux';

import { issueTypesSelectors } from '@application/IssueTypes/store';

import EntitySelect from './EntitySelect';

interface IProps extends SelectProps<any> {
  width?: string;
  extraOptions?: { id: string | number; name: string }[];
  e2eDataAttr?: string;
}

const IssueTypesSelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef((props, _) => {
    const { data, loading } = useSelector(
      issueTypesSelectors.getConciseIssueTypes
    );

    return <EntitySelect {...props} options={data} loading={loading} />;
  });

export default IssueTypesSelect;
