import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export interface IProps {
  mode?: 'grid' | 'row';
}

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: ${({ mode }: IProps) => (mode === 'grid' ? 'wrap' : 'nowrap')};
  margin: 0 -4px;

  & > * {
    margin: 0 4px ${({ mode }: IProps) => (mode === 'grid' ? '4px' : '0px')};
  }
`;

export const ScrollContainer = styled.div`
  position: relative;

  &:after {
    display: ${({ visibleShadow }: { visibleShadow: boolean }) =>
      visibleShadow ? 'block' : 'none'};
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 10px;
    background: radial-gradient(
      50% 50% at 100% 50%,
      ${colors.gray10} 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

export const FilesContainer = styled.div``;

export const Container = styled.div`
  position: relative;
`;
