import React, { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { Dayjs } from 'dayjs';

import { date } from '@utils';
import { config, colors } from '@repo/shared/config';
import { Desc } from './styled';

import Form from '@components/shared/ant/Form';
import Modal from '@components/shared/ant/Modal/Modal';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import RadioGroup from '@components/shared/ant/RadioGroup';
import LabeledSwitch from '@components/shared/LabeledSwitch/LabeledSwitch';
import Alert from '@repo/shared/components/Alert';

interface IProps {
  active: boolean;
  startFromDate: string | null;
  stopByDate: string | null;
  disabled?: boolean;
  onChange: (params: {
    active: boolean;
    startFromDate: string | null;
    stopByDate: string | null;
  }) => Promise<boolean>;
  disabledPopoverContent?: React.ReactNode;
}

enum Action {
  Enable,
  Disable,
}

enum Timing {
  Immediately,
  OnDate,
}

interface FormValues {
  timing: Timing;
  onDate: Dayjs | null;
}

const ChangeScheduleStateModalAndButton: React.FC<
  React.PropsWithChildren<IProps>
> = ({
  active,
  startFromDate,
  stopByDate,
  disabled,
  onChange,
  disabledPopoverContent,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<FormValues>();

  const [action, setAction] = useState<Action | null>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (action !== null) {
      if (startFromDate || stopByDate) {
        form.setFieldsValue({
          timing: Timing.OnDate,
          onDate: startFromDate ? date(startFromDate) : date(stopByDate),
        });
      } else {
        form.setFieldsValue({
          timing: Timing.Immediately,
          onDate: null,
        });
      }
    }
  }, [action]);

  const infoText = useMemo(() => {
    const startFrom = startFromDate ? date(startFromDate) : null;
    const stopBy = stopByDate ? date(stopByDate) : null;

    if (startFrom && stopBy) {
      if (stopBy.isAfter(startFrom)) {
        return formatMessage(
          {
            id: 'EnabledFromTillDates',
          },
          {
            fromDate: startFrom.format(config.dateFormat),
            tillDate: stopBy.format(config.dateFormat),
          }
        );
      } else {
        return formatMessage(
          {
            id: 'DisabledFromTillDates',
          },
          {
            fromDate: stopBy.format(config.dateFormat),
            tillDate: startFrom.format(config.dateFormat),
          }
        );
      }
    }

    if (startFrom && startFrom.isAfter(date().startOf('day'), 'day')) {
      return formatMessage(
        { id: 'DisabledUntil' },
        { date: startFrom.format(config.dateFormat) }
      );
    }

    if (stopBy) {
      return formatMessage(
        { id: 'EnabledUntil' },
        { date: stopBy.format(config.dateFormat) }
      );
    }

    return formatMessage({ id: active ? 'Enabled' : 'Disabled' });
  }, [active, startFromDate, stopByDate]);

  return (
    <>
      <LabeledSwitch
        value={active}
        title={infoText}
        onChange={(enabled) => {
          setAction(enabled ? Action.Enable : Action.Disable);
        }}
        disabled={disabled}
        switchPosition="end"
        containerStyle={{
          justifyContent: 'flex-end',
        }}
        titleStyle={{
          fontSize: '13px',
        }}
        disabledPopoverContent={disabledPopoverContent}
      />
      <Modal
        title={`${formatMessage({
          id: active ? 'DisableSchedule' : 'EnableSchedule',
        })}?`}
        open={action !== null}
        onOk={() => {
          form.submit();
        }}
        okText={formatMessage({
          id: 'Save',
        })}
        okButtonBackground={colors.gray10}
        confirmLoading={saving}
        onCancel={() => {
          setAction(null);
        }}
        width={420}
        closeIcon={<X />}
        styles={{
          body: {
            marginBottom: '20px 0 25px',
          },
        }}
      >
        <Form<FormValues>
          form={form}
          layout="vertical"
          onFinish={async ({ timing, onDate }) => {
            setSaving(true);

            let params;

            if (timing === Timing.Immediately) {
              params = {
                active: action === Action.Enable,
                stopByDate:
                  action === Action.Enable && !!onDate
                    ? onDate.format(config.apiDateFormat)
                    : null,
                startFromDate:
                  action === Action.Disable && !!onDate
                    ? onDate.format(config.apiDateFormat)
                    : null,
              };
            } else {
              params = {
                active,
                stopByDate:
                  active && !!onDate
                    ? onDate.format(config.apiDateFormat)
                    : null,
                startFromDate:
                  !active && !!onDate
                    ? onDate.format(config.apiDateFormat)
                    : null,
              };
            }

            const success = await onChange(params);

            if (success) {
              setAction(null);
            }

            setSaving(false);
          }}
        >
          <Form.Item name="timing">
            <RadioGroup<Timing>
              reverse
              options={[
                {
                  value: Timing.Immediately,
                  label: (
                    <>
                      {formatMessage({ id: 'Immediately' })}
                      <Desc>
                        {formatMessage({
                          id:
                            action === Action.Enable
                              ? 'TheCreationOfNewAuditsWillBeStartedImmediately'
                              : 'TheCreationOfNewAuditsWillBeStoppedImmediately',
                        })}
                      </Desc>
                    </>
                  ),
                },
                {
                  value: Timing.OnDate,
                  label: (
                    <>
                      {formatMessage({ id: 'OnDate' })}
                      <Desc>
                        {formatMessage({
                          id:
                            action === Action.Enable
                              ? 'TheCreationOfNewAuditsWillBeStartedOnDate'
                              : 'TheCreationOfNewAuditsWillBeStoppedOnDate',
                        })}
                      </Desc>
                    </>
                  ),
                },
              ]}
            />
          </Form.Item>
          <Form.Item<FormValues>
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.timing !== nextValues.timing
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue('timing') === Timing.OnDate) {
                return (
                  <Form.Item
                    name="onDate"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({ id: 'RequiredField' }),
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={(currentDate: Dayjs) =>
                        currentDate.isBefore(date())
                      }
                    />
                  </Form.Item>
                );
              }

              return null;
            }}
          </Form.Item>
        </Form>
        {action === Action.Disable && (
          <Alert
            showIcon
            description={formatMessage({
              id: 'AllNonStartedAuditsWillBeRemoved',
            })}
            style={{ marginTop: '20px' }}
            type="warning"
          />
        )}
      </Modal>
    </>
  );
};

export default ChangeScheduleStateModalAndButton;
