import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Button = styled.button`
  border: 0;
  background: rgba(49, 187, 231, 0.1);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 21px 21px;
  border-radius: 5px;
  color: ${colors.blue1};

  svg {
    width: 22px;
    height: 22px;
    margin-right: 17px;
    color: ${colors.blue2};
  }
`;

export const Description = styled.div`
  font-size: 9px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;
  margin-bottom: 4px;
`;

export const Text = styled.div`
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.blue2};
`;
