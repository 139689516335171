import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { AlertTriangle } from 'react-feather';
import { useSelector } from 'react-redux';

import { Container, LogoWrapper, Content, Title, Text } from './styled';
import { routes } from '@config';
import { accountSelectors } from '@store';

import NavyBlueContainer from '@components/shared/NavyBlueContainer/NavyBlueContainer';
import AppLogo from '@components/shared/AppLogo';
import Button from '@components/shared/ant/Button';

interface IProps {
  error?: string;
}

const PublicAuditReportError: React.FC<React.PropsWithChildren<IProps>> = ({
  error,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const authUserId = useSelector(accountSelectors.getAuthUserId);

  return (
    <NavyBlueContainer>
      <Container>
        <LogoWrapper>
          <AppLogo width={200} height={51} align="center" />
        </LogoWrapper>
        <Content>
          <AlertTriangle />
          {error === 'access-denied' ? (
            <>
              <Title>{formatMessage({ id: 'AccessDenied' })}</Title>
              <Text>
                {formatMessage({ id: 'TheRequestedAuditReportIsNotAvailable' })}
              </Text>
            </>
          ) : (
            <>
              <Title>{formatMessage({ id: 'OopsSomethingWentWrong' })}</Title>
              {!!error && <Text>{error}</Text>}
            </>
          )}
          <Button
            type="primary"
            onClick={() => {
              navigate(authUserId ? routes.dashboard : routes.signin);
            }}
          >
            {formatMessage({ id: authUserId ? 'GoToDashboard' : 'GoToSignIn' })}
          </Button>
        </Content>
      </Container>
    </NavyBlueContainer>
  );
};

export default PublicAuditReportError;
