import { CancelToken, ResponseType } from 'axios';

import { AnyObject } from './misc';

export type Param = string | number | (string | number)[] | boolean;

export enum HttpMethods {
  get = 'GET',
  post = 'POST',
  delete = 'DELETE',
  put = 'PUT',
}

export interface RequestApiParams {
  url: string;
  host?: string;
  method?: HttpMethods;
  body?: AnyObject | string;
  query?: AnyObject;
  responseType?: ResponseType;
  withHeaders?: boolean;
  skipCompanyId?: boolean;
  companyId?: string;
  cancelToken?: CancelToken;
  headers?: Record<string, string>;
  onUploadProgress?: (progressEvent: any) => void;
  token?: string;
  requestId?: string;
  cacheResponse?: boolean;
  deviceId?: string;
  onAuthError?: () => void;
}

export interface RequestFileParams {
  id: string;
  path?: string;
  responseType: 'blob' | 'arraybuffer';
  companyId?: string;
  skipCompanyId?: boolean;
  cacheResponse?: boolean;
}

export interface IApiService {
  post<T = void>(params: RequestApiParams): Promise<T>;

  get<T = void>(params: RequestApiParams): Promise<T>;

  put<T = void>(params: RequestApiParams): Promise<T>;

  delete<T = void>(params: RequestApiParams): Promise<T>;

  getFile<T = void>(params: RequestFileParams): Promise<T>;

  getApiHost(): string;

  getFileApiHost(): string;

  getAuthHeaders(): Promise<Record<string, string>>;

  getCurrentCompanyId(): string | undefined;

  removeFileCache(requestId: string): Promise<void>;
}
