import React, { useEffect, useRef } from 'react';
import { Trash } from 'react-feather';

import {
  Container,
  Content,
  Error,
  Footer,
  Name,
  Progress,
  RemoveBtn,
} from './styled';
import { UploadStatus } from '@repo/shared/enums';
import { IAbortRequest, IFileUpload } from '@repo/shared/types';
import { useAppDispatch } from '@hooks';
import { generalActions } from '@store';

import UploadStatusIcon from '@components/shared/UploadFiles/UploadList/UploadStatusIcon/UploadStatusIcon';

interface IProps {
  upload: IFileUpload;
  onRemove: (fileId: string) => void;
  cancelToken: IAbortRequest;
}

const UploadItem: React.FC<React.PropsWithChildren<IProps>> = ({
  upload,
  onRemove,
  cancelToken,
}) => {
  const dispatch = useAppDispatch();

  const removeBtnRef = useRef<IAbortRequest | null>();

  useEffect(() => {
    removeBtnRef.current = cancelToken;
  }, []);

  return (
    <Container key={upload.file.id} status={upload.status}>
      <Content>
        <div style={{ width: '15px' }}>
          <UploadStatusIcon status={upload.status} />
        </div>
        <Name>{upload.file.name}</Name>
        <RemoveBtn
          type="button"
          onClick={() => {
            if (upload.status === UploadStatus.Done) {
              onRemove(upload.file.id);
            } else if (removeBtnRef?.current) {
              removeBtnRef.current();
            }

            dispatch(generalActions.deleteFileUpload(upload.file.id));
          }}
        >
          <Trash />
        </RemoveBtn>
      </Content>
      <Footer>
        {upload.status === UploadStatus.Error && upload.error !== null ? (
          <Error>{upload.error}</Error>
        ) : (
          <Progress percent={upload.progress} showInfo={false} />
        )}
      </Footer>
    </Container>
  );
};

export default UploadItem;
