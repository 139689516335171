import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { notification, validations } from '@utils';
import { useAppDispatch } from '@hooks';
import { accountActions, accountSelectors } from '@store';
import {
  routes,
  HAS_RESET_PASSWORD_TEMP,
  REQUIRE_RECENT_LOGIN_ERR,
} from '@config';
import { e2eTestElements } from '@config';
import * as UserPreferencesService from '@services/userPreferences.service';

import OneColAuthWrapper from './OneColAuthWrapper';
import Form from '../../shared/ant/Form';
import { InputPassword } from '../../shared/ant/Input';
import Button from '../../shared/ant/Button';

const ChangeDefaultPassword: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const user = useSelector(accountSelectors.getCurrentUser);

  return (
    <OneColAuthWrapper
      title={formatMessage({
        id: 'ChangePassword',
      })}
      tagline={formatMessage({
        id: 'ToMakeYourMonitorQAMoreSecurePleaseChangeTheDefaultPassword',
      })}
      footerText={formatMessage({ id: 'Skip' })}
      footerAction={async () => {
        await UserPreferencesService.setItem(HAS_RESET_PASSWORD_TEMP, true);
        navigate(routes.dashboard);
      }}
      e2eDataAttrFooterBtn={e2eTestElements.account.changePassword.cancelBtn}
    >
      <Form
        name="change-default-password-form"
        layout="vertical"
        onFinish={async ({ password }: any) => {
          if (user === null) {
            return;
          }

          setLoading(true);

          const resultAction = await dispatch(
            accountActions.changePassword({
              email: user.email,
              password,
            })
          );

          if (accountActions.changePassword.fulfilled.match(resultAction)) {
            dispatch(
              accountActions.updateCurrentUserLocally({
                hasResetPassword: true,
              })
            );
            dispatch(accountActions.setResetPassword());
            await UserPreferencesService.setItem(HAS_RESET_PASSWORD_TEMP, true);

            navigate(routes.dashboard);
          } else {
            if (resultAction.payload === REQUIRE_RECENT_LOGIN_ERR) {
              notification.warning({
                message: formatMessage({ id: 'PleaseLogInAgainFirst' }),
              });

              dispatch(accountActions.signOut());
              navigate(routes.signin);
            } else {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileSettingUpPassword' }),
              });
            }
          }

          setLoading(false);
        }}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'PleaseEnterYourPassword' }),
            },
            {
              pattern: validations.password,
              message: formatMessage({
                id: 'PasswordShouldContainMinimumEightCharacters',
              }),
            },
          ]}
          label={formatMessage({ id: 'NewPassword' })}
        >
          <InputPassword
            disabled={loading}
            data-e2e={e2eTestElements.account.changePassword.passwordInput}
          />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !getFieldValue('password') ||
                  getFieldValue('password') === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  formatMessage({ id: 'PasswordsDontMatch' })
                );
              },
            }),
          ]}
          label={formatMessage({ id: 'ConfirmPassword' })}
        >
          <InputPassword
            disabled={loading}
            data-e2e={
              e2eTestElements.account.changePassword.passwordConfirmInput
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%', marginTop: '16px' }}
            loading={loading}
            data-e2e={e2eTestElements.account.changePassword.saveBtn}
          >
            {formatMessage({ id: 'SaveAndLogin' })}
          </Button>
        </Form.Item>
      </Form>
    </OneColAuthWrapper>
  );
};

export default ChangeDefaultPassword;
