import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const UserGroup = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M11.7172 13.3548C11.1566 12.9731 10.4794 12.75 9.75 12.75H4.5C2.567 12.75 1 14.317 1 16.25C1 17.2165 1.7835 18 2.75 18H9M10.625 7.5C10.625 9.433 9.058 11 7.125 11C5.192 11 3.625 9.433 3.625 7.5C3.625 5.567 5.192 4 7.125 4C9.058 4 10.625 5.567 10.625 7.5ZM20.375 9.5C20.375 11.433 18.808 13 16.875 13C14.942 13 13.375 11.433 13.375 9.5C13.375 7.567 14.942 6 16.875 6C18.808 6 20.375 7.567 20.375 9.5ZM14.25 14.75H19.5C21.433 14.75 23 16.317 23 18.25C23 19.2165 22.2165 20 21.25 20H12.5C11.5335 20 10.75 19.2165 10.75 18.25C10.75 16.317 12.317 14.75 14.25 14.75Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default UserGroup;
