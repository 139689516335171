import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { schedulePlansSelectors } from '@application/SchedulePlans/store/schedulePlansSelectors';
import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { SchedulePlanDetailsPage } from '@application/SchedulePlans/models/SchedulePlanDetailsPage';
import { useAppDispatch } from '@hooks';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { Container } from './styled';

import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

interface IProps {}

const SchedulePlanDetailsPages: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const schedulesCount = useSelector(
    schedulePlansSelectors.getSchedulePlanSchedulesCount
  );
  const page = useSelector(
    schedulePlansSelectors.getSchedulePlanDetailsCurrentPage
  );

  return (
    <Container>
      <PageTabbedSwitcher<SchedulePlanDetailsPage>
        pages={[
          {
            onClick: () => {
              dispatch(
                schedulePlansActions.setSchedulePlanDetailsPage(
                  SchedulePlanDetailsPage.SchedulingRules
                )
              );
              dispatch(schedulesActions.getSchedules());
            },
            id: SchedulePlanDetailsPage.SchedulingRules,
            name: formatMessage({ id: 'SchedulingRules' }),
            count: schedulesCount || undefined,
          },
          {
            onClick: () => {
              dispatch(
                schedulePlansActions.setSchedulePlanDetailsPage(
                  SchedulePlanDetailsPage.PlanningPeriods
                )
              );
              dispatch(schedulePlansActions.getSchedulePlanPeriods());
            },
            id: SchedulePlanDetailsPage.PlanningPeriods,
            name: formatMessage({ id: 'PlanningPeriods' }),
          },
        ]}
        activePage={page}
      />
    </Container>
  );
};

export default SchedulePlanDetailsPages;
