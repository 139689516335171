import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { notification } from '@utils';
import { generalActions, generalSelectors } from '@store';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';

import Modal from '@components/shared/ant/Modal/Modal';

interface IProps {
  page: IssuesPage;
}

const DeleteIssueModal: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [processing, setProcessing] = useState(false);

  const modalState = useSelector(
    issuesSelectors.getConfirmDeleteIssueTypeFieldsModalState
  );
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  let deleteCandidateIds: string[] = [];
  if (modalState !== null) {
    deleteCandidateIds = modalState.multiple
      ? modalState.data
      : [modalState.data.id];
  }
  const itemCount = deleteCandidateIds.length;

  const closeModal = useCallback(() => {
    dispatch(issuesActions.toggleConfirmDeleteIssuesModal(null));
  }, []);

  return (
    <Modal
      title={formatMessage({ id: 'DeleteIssues' }, { itemCount })}
      open={modalState !== null}
      onOk={async () => {
        setProcessing(true);

        const resultAction = await dispatch(
          issuesActions.deleteIssues(deleteCandidateIds)
        );

        if (issuesActions.deleteIssues.fulfilled.match(resultAction)) {
          if (page === IssuesPage.My) {
            deleteCandidateIds.forEach((id) =>
              dispatch(issuesActions.deleteIssueLocally(id))
            );
          } else {
            await dispatch(issuesActions.getIssues());
          }

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();

          dispatch(issuesActions.toggleIssueDetailsModal(null));
        } else {
          notification.error({
            message: formatMessage(
              { id: 'ErrorWhileDeletingIssues' },
              { itemCount }
            ),
          });
        }

        setProcessing(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={processing}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {formatMessage(
        { id: 'YouAreAboutToRemoveIssuesFromSystem' },
        { itemCount: 1 }
      ).replace(
        '%replaceValue%',
        modalState && !modalState.multiple && modalState.data.name
          ? modalState.data.name
          : ''
      )}
    </Modal>
  );
};

export default DeleteIssueModal;
