import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { Content, Icon, Text, Header } from './styled';
import { routes } from '@config';
import { config } from '@repo/shared/config';

import OneColAuthWrapper from '@components/account/AuthForms/OneColAuthWrapper';
import NavyBlueContainer from '@components/shared/NavyBlueContainer/NavyBlueContainer';
import Button from '@components/shared/ant/Button';

interface IProps {}

const CreateIssuePublicPageError: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <NavyBlueContainer>
      <OneColAuthWrapper containerMaxWidth={400}>
        <Content>
          <Icon />
          <Header>{formatMessage({ id: 'IssueCreationUnavailable' })}</Header>
          <Text>
            {formatMessage({ id: 'ForAssistancePleaseContactSupportAt' })}{' '}
            <Link to={`mailto:${config.urls.supportEmail}`}>
              {config.urls.supportEmail}
            </Link>
          </Text>
          <Button
            type="primary"
            onClick={() => {
              navigate(routes.signin);
            }}
          >
            {formatMessage({ id: 'GoToSignIn' })}
          </Button>
        </Content>
      </OneColAuthWrapper>
    </NavyBlueContainer>
  );
};

export default CreateIssuePublicPageError;
