import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { Modal } from '@repo/shared/enums';
import { IAuditObject } from '@repo/shared/types';
import { accountSelectors, auditObjectsActions, generalActions } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  auditObject: IAuditObject;
}

const AuditObjectsActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObject,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'View' }),
          onClick: () => {
            dispatch(auditObjectsActions.objects.updateObjectDetails(null));
            navigate(
              routes.auditObjects.auditObjectDetails.replace(
                ':id',
                auditObject.id
              )
            );
          },
        },
        ...(!isLocationsReadonly
          ? [
              {
                name: formatMessage({ id: 'Edit' }),
                onClick: () =>
                  dispatch(
                    auditObjectsActions.objects.showAddEditModal(auditObject)
                  ),
              },
              {
                name: formatMessage({ id: 'Delete' }),
                onClick: () =>
                  dispatch(
                    generalActions.showModal({
                      name: Modal.ConfirmDelete,
                      data: auditObject,
                    })
                  ),
              },
            ]
          : []),
      ]}
    />
  );
};

export default AuditObjectsActionsMenu;
