import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from '@repo/shared/config';

export const Container = styled(animated.button)`
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 4px;
  border: 0;
  line-height: 1;
  padding: 0;
`;

interface IContentProps {
  expanded: boolean;
  single: boolean;
}

export const Content = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  text-transform: uppercase;
  padding: ${({ single }: IContentProps) =>
    single ? '9px 8px 7px' : '3px 8px 2px'};
  font-weight: 600;
  white-space: nowrap;
  color: ${({ expanded }: IContentProps) =>
    expanded ? colors.white : 'transparent'};
  transition: color 0.2s ease-in-out;
`;

export const Text = styled.div`
  margin-top: -1px;
`;
