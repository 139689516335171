import styled from 'styled-components';

import emptyImg from './empty.svg';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Icon = styled.div`
  width: 101px;
  height: 64px;
  background: url(${emptyImg}) no-repeat center center;
  background-size: contain;
  margin-bottom: 15px;
`;

export const Text = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray8};
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;
