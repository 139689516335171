import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Content = styled.div`
  width: 360px;
  margin: 20px auto;
  text-align: center;
`;

export const Title = styled.h3`
  font-size: 20px;
  line-height: 1;
  margin-bottom: 8px;
`;

export const SubTitle = styled.div`
  font-size: 14px;
  color: ${colors.gray8};
  margin-bottom: 20px;
`;

export const StoreLinks = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -12px;
`;

export const StoreLink = styled.div`
  margin: 0 12px;
  border: 1px solid ${colors.gray3};
  border-radius: 5px;
  padding: 20px;
`;

export const StoreLabel = styled.img`
  width: 120px;
  height: 36px;
  margin-bottom: 16px;
`;

export const QRCode = styled.img`
  width: 120px;
  height: 120px;
`;
