import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  rolesActions,
  rolesSelectors,
  generalActions,
  generalSelectors,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { getTablePageNumberAfterDeletion } from '@utils';

import ConfirmDeleteModal from '../shared/ConfirmDeleteModal/ConfirmDeleteModal';

const DeleteRolesModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const roles = useSelector(rolesSelectors.getRolesLookup);
  const { meta } = useSelector(rolesSelectors.getRoles);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const { formatMessage } = useIntl();

  const itemCount = selectedTableRowKeys.length || 1;

  return (
    <ConfirmDeleteModal
      text={{
        title: formatMessage({ id: 'DeleteRoles' }, { itemCount }),
        error: formatMessage({ id: 'ErrorWhileDeletingRoles' }, { itemCount }),
        desc: formatMessage(
          { id: 'YouAreAboutToRemoveRolesFromSystem' },
          { itemCount }
        ),
      }}
      onSuccess={() => {
        dispatch(
          rolesActions.getRoles({
            pageNumber: getTablePageNumberAfterDeletion(meta),
          })
        );
        dispatch(generalActions.getConciseRoles());
      }}
      onSubmit={async (ids) => {
        const nonDefaultRoles = ids.filter((id) => !roles[id]?.isDefault);

        if (ids.length !== nonDefaultRoles.length) {
          notification.error({
            message: 'Error while role deletion',
            description: formatMessage({ id: 'role/cant-delete-default-role' }),
          });
        }

        const resultAction = await dispatch(
          rolesActions.deleteRoles(nonDefaultRoles)
        );

        if (rolesActions.deleteRoles.rejected.match(resultAction)) {
          const error = resultAction.payload?.includes('role/has-users')
            ? formatMessage({ id: 'role/has-users' })
            : undefined;

          throw new Error(error);
        }
      }}
      data={roles}
    />
  );
};

export default DeleteRolesModal;
