const EmptyWidgetPlaceholder = () => {
  return (
    <svg
      width="210"
      height="153"
      viewBox="0 0 210 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M210 84.9159C210 107.735 193.319 127.923 167.748 140.213C151.352 148.093 131.3 152.727 109.648 152.727C80.8046 152.727 58.8023 143.984 42.2517 130.591C26.9966 118.25 16.3744 101.956 9.29672 84.9159C-22.2999 8.83418 30.4826 -11.9219 109.648 17.1045C160.054 35.5879 210 47.4658 210 84.9159Z"
        fill="#EBEBEB"
      />
      <path
        d="M170.909 74.5455C170.909 87.3113 167.123 99.7905 160.031 110.405C152.939 121.019 142.858 129.292 131.064 134.178C119.27 139.063 106.292 140.341 93.7711 137.851C81.2505 135.36 69.7496 129.213 60.7228 120.186C51.696 111.159 45.5486 99.6582 43.0581 87.1376C40.5676 74.6171 41.8458 61.6391 46.7311 49.845C51.6164 38.0508 59.8893 27.9702 70.5038 20.8779C81.1182 13.7855 93.5974 10 106.363 10V74.5455H170.909Z"
        fill="#D5D5D7"
      />
      <path
        d="M117.273 0C125.749 1.01078e-07 134.142 1.66952 141.973 4.91323C149.804 8.15695 156.919 12.9113 162.913 18.9049C168.907 24.8985 173.661 32.014 176.905 39.845C180.148 47.676 181.818 56.0692 181.818 64.5455L117.273 64.5455V0Z"
        fill="#E2E2E3"
      />
    </svg>
  );
};

export default EmptyWidgetPlaceholder;
