import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { useAppDispatch } from '@hooks';
import { accountSelectors, accountActions } from '@store';
import { notification, validations } from '@utils';
import { ChangePasswordContent } from './styled';
import { CURRENT_PASS_NOT_CORRECT_ERR, e2eTestElements } from '@config';

import Modal from '../../shared/ant/Modal/Modal';
import Form from '../../shared/ant/Form';
import { InputPassword } from '../../shared/ant/Input';

interface IProps {
  visible: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<React.PropsWithChildren<IProps>> = ({
  visible,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const currentUser = useSelector(accountSelectors.getCurrentUser);

  return (
    <Modal
      open={visible}
      width={375}
      closeIcon={<X />}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        try {
          await form.validateFields();

          const { password, currentPassword } = form.getFieldsValue();

          setLoading(true);

          const resultAction = await dispatch(
            accountActions.changePassword({
              password,
              currentPassword,
              email: currentUser.email,
            })
          );

          if (accountActions.changePassword.fulfilled.match(resultAction)) {
            notification.success({
              message: formatMessage({
                id: 'PasswordHasBeenSuccessfullyUpdated',
              }),
            });

            form.resetFields();
            onClose();
          } else {
            const error = resultAction.payload;

            if (error === CURRENT_PASS_NOT_CORRECT_ERR) {
              notification.error({
                message: formatMessage({ id: 'CurrentPasswordIsNotCorrect' }),
              });

              form.resetFields(['currentPassword']);
            } else {
              notification.error({
                message: error,
              });
            }
          }

          setLoading(false);
        } catch (e) {}
      }}
      confirmLoading={loading}
      okText={formatMessage({ id: 'Change' })}
      cancelText={formatMessage({ id: 'Cancel' })}
      title={formatMessage({ id: 'ChangePassword' })}
      e2eDataConfirmAttr={e2eTestElements.account.changePassword.saveBtn}
      e2eDataCancelAttr={e2eTestElements.account.changePassword.cancelBtn}
    >
      <ChangePasswordContent>
        <Form
          layout="vertical"
          name="change-password-form"
          form={form}
          initialValues={{
            currentPassword: '',
            password: '',
            passwordConfirm: '',
          }}
        >
          <Form.Item
            name="currentPassword"
            label={formatMessage({ id: 'CurrentPassword' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'EnterCurrentPassword' }),
              },
            ]}
          >
            <InputPassword
              disabled={loading}
              data-e2e={e2eTestElements.account.changePassword.passwordInput}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'PleaseEnterNewPassword' }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    getFieldValue('currentPassword') &&
                    getFieldValue('currentPassword') === value
                  ) {
                    return Promise.reject(
                      formatMessage({ id: 'NewPasswordMustBeDifferent' })
                    );
                  }

                  return Promise.resolve();
                },
              }),
              {
                pattern: validations.password,
                message: formatMessage({
                  id: 'PasswordShouldContainMinimumEightCharacters',
                }),
              },
            ]}
            label={formatMessage({ id: 'NewPassword' })}
          >
            <InputPassword disabled={loading} />
          </Form.Item>
          <Form.Item
            name="passwordConfirm"
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'PleaseEnterPasswordConfirmation',
                }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !getFieldValue('password') ||
                    getFieldValue('password') === value
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    formatMessage({ id: 'PasswordsDontMatch' })
                  );
                },
              }),
            ]}
            label={formatMessage({ id: 'ConfirmPassword' })}
          >
            <InputPassword
              disabled={loading}
              data-e2e={
                e2eTestElements.account.changePassword.passwordConfirmInput
              }
            />
          </Form.Item>
        </Form>
      </ChangePasswordContent>
    </Modal>
  );
};

export default ChangePasswordModal;
