import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Name = styled.button`
  background: none;
  border: 0;
  padding: 0;
  text-decoration: underline;

  &:disabled {
    text-decoration: none;
  }
`;

export const Status = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  margin: 5px 0 10px;
`;

export const Description = styled.div`
  margin-bottom: 15px;
`;

export const PreviewsContainer = styled.div``;
