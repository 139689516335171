import React from 'react';

import { Container, Main } from './styled';

import Footer from '../Footer/Footer';

interface IProps {
  minWidth?: number;
}

const NavyBlueContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  minWidth,
}) => {
  return (
    <Container minWidth={minWidth}>
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};

export default NavyBlueContainer;
