export const common = {
  logo: 'company-logo',
  header: {
    userMenu: 'header-user-menu',
    signOutButton: 'header-sign-out-button',
  },
  dashboardReportFilters: {
    templateSelect: 'dashboard-report-filters-template-select',
  },
  sidebar: {
    library: {
      root: 'sidebar-library',
      templates: 'sidebar-templates',
    },
    audits: {
      root: 'sidebar-audits',
      allAudits: 'sidebar-all-audits',
      actions: 'sidebar-actions',
      schedule: 'sidebar-schedule',
    },
    settings: {
      root: 'sidebar-settings',
      general: 'sidebar-settings-general',
      objects: 'sidebar-settings-objects',
      groups: 'sidebar-settings-groups',
      attributes: 'sidebar-settings-attributes',
      users: 'sidebar-settings-users',
      roles: 'sidebar-settings-roles',
      scoring: 'sidebar-settings-scoring',
      tags: 'sidebar-settings-tags',
      notifications: 'sidebar-settings-notifications',
      billing: 'sidebar-settings-billing',
      integration: 'sidebar-settings-integration',
    },
    languageChange: {
      trigger: 'sidebar-language-change-trigger',
      dropdown: 'sidebar-language-change-dropdown',
    },
  },
  table: {
    xRow: 'table-row-',
  },
  assigneesModal: {
    searchInput: 'assignees-modal-search-input',
    userListWrapper: 'assignees-modal-user-list-wrapper',
    saveBtn: 'assignees-modal-save-btn',
    cancelBtn: 'assignees-modal-cancel-btn',
    resetToDefaultBtn: 'assignees-modal-reset-to-default-btn',
  },
  photosList: {
    removePhotoBtn: 'photos-list-remove-photo-btn',
    openPhotoBtn: 'photos-list-open-photo-btn',
  },
  selects: {
    auditObjectsAndGroups: 'audit-objects-and-groups-select',
  },
};
