import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountSelectors } from '@store';
import Alert from '@repo/shared/components/Alert';
import { Container } from './styled';

const SampleDataAlert: React.FC = () => {
  const { formatMessage } = useIntl();

  const isAdmin = useSelector(accountSelectors.isAdmin);
  const company = useSelector(accountSelectors.getCompany);

  if (isAdmin && company.hasSampleData) {
    return (
      <Container>
        <Alert
          showIcon
          description={formatMessage({
            id: 'YourAccountIsPreFilledWithSampleDataAlert',
          })}
          type="warning"
        />
      </Container>
    );
  }

  return null;
};

export default SampleDataAlert;
