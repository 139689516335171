import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  position: relative;
  z-index: 10;
  background: ${colors.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  min-height: 700px;
  max-height: 1200px;
`;

export const TwoColsContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  border-top: 1px solid ${colors.gray4};
`;

export const LeftCol = styled.div`
  width: 380px;
  border-right: 1px solid ${colors.gray4};
`;

export const LeftColContent = styled.div``;

export const RightCol = styled.div`
  display: flex;
  flex-grow: 1;
`;
