import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Plus } from 'react-feather';

import {
  ContainerButton,
  More,
  Unassigned,
  PlusContainer,
  AssigneesList,
  AssigneesListContainer,
  PopoverContent,
} from './styled';
import { IConcise, IAssignee } from '@repo/shared/types';
import { AssigneesModalContext } from '@repo/shared/enums';
import { useAppDispatch } from '@hooks';
import { accountSelectors, generalActions, generalSelectors } from '@store';

import SelectAssigneeModal from './SelectAssigneeModal/SelectAssigneeModal';
import User from '../User/User';
import JobTitlesList from '@components/shared/Assignees/JobTitlesList/JobTitlesList';
import Popover from '@components/shared/ant/Popover';

interface IProps {
  placeholder?: React.ReactNode;
  disabled?: boolean;
  auditObjectId?: string;
  onChange?: (users: IConcise[]) => Promise<any>;
  users?: IConcise[];
  context: AssigneesModalContext;
  e2eDataAttr?: string;
  showJobTitles?: boolean;
}

const Assignees: React.FC<React.PropsWithChildren<IProps>> = ({
  onChange,
  users,
  auditObjectId,
  disabled,
  context,
  e2eDataAttr,
  showJobTitles = true,
  placeholder,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState<IAssignee[]>([]);

  const { loading: participantsLoading, participantsMap } = useSelector(
    generalSelectors.assigneesModal.getData
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  useEffect(() => {
    if (auditObjectId) {
      dispatch(generalActions.assigneesModal.getParticipants(auditObjectId));
    }
  }, [auditObjectId]);

  useEffect(() => {
    dispatch(generalActions.assigneesModal.setContext(context));
  }, [context]);

  useEffect(() => {
    if (!participantsLoading) {
      setSelected(
        (users || []).map((user) => participantsMap[user.id] || user)
      );
    }
  }, [participantsLoading]);

  useEffect(() => {
    setSelected((users || []).map((user) => participantsMap[user.id] || user));
  }, [JSON.stringify(users || [])]);

  return (
    <Popover
      open={!auditObjectId ? undefined : false}
      content={
        <PopoverContent>
          {formatMessage(
            { id: 'PleaseSelectAuditObjectBeforeProceeding' },
            { objectName: auditObjectName.single.toLowerCase() }
          )}
        </PopoverContent>
      }
    >
      <ContainerButton
        type="button"
        onClick={() => {
          if (!auditObjectId) return;

          dispatch(generalActions.assigneesModal.toggle(true));
        }}
        disabled={disabled || !auditObjectId}
        data-e2e={e2eDataAttr}
      >
        <AssigneesListContainer>
          {selected.length > 0 ? (
            <AssigneesList>
              {selected.map((user, i) => {
                if (i < 3) {
                  return (
                    <User
                      key={user.id}
                      extraName={
                        <JobTitlesList show={showJobTitles} assignee={user} />
                      }
                    >
                      {user.name}
                    </User>
                  );
                }

                if (i === selected.length - 1 && i >= 3) {
                  return (
                    <More key={user.id}>
                      + {selected.length - 3} {formatMessage({ id: 'more' })}
                    </More>
                  );
                }

                return null;
              })}
            </AssigneesList>
          ) : (
            <Unassigned>
              {placeholder || formatMessage({ id: 'Unassigned' })}
            </Unassigned>
          )}
        </AssigneesListContainer>
        {!disabled && (
          <PlusContainer>
            <Plus />
          </PlusContainer>
        )}
      </ContainerButton>
      <SelectAssigneeModal
        initial={selected}
        onSave={onChange}
        showJobTitles={showJobTitles}
      />
    </Popover>
  );
};

export default Assignees;
