import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { accountSelectors, auditObjectsActions } from '@store';
import { useAppDispatch } from '@hooks';
import { routes } from '@config';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  attributeId: string;
}

const AttributesActionsMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  attributeId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  return (
    <TableRowActionsMenu
      menuItems={[
        {
          name: formatMessage({ id: 'View' }),
          onClick: () => {
            navigate(
              routes.auditObjects.attributeDetails.replace(':id', attributeId)
            );
          },
        },
        ...(!isLocationsReadonly
          ? [
              {
                name: formatMessage({ id: 'Edit' }),
                onClick: () =>
                  dispatch(
                    auditObjectsActions.attributes.toggleAddEditModal({
                      show: true,
                      attributeId,
                    })
                  ),
              },
              {
                name: formatMessage({ id: 'Delete' }),
                onClick: () => {
                  dispatch(
                    auditObjectsActions.attributes.toggleConfirmDeleteModal([
                      attributeId,
                    ])
                  );
                },
              },
            ]
          : []),
      ]}
    />
  );
};

export default AttributesActionsMenu;
