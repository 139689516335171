import { UpdateAuditPayload } from '@application/Audits/models/UpdateAuditPayload';
import { UpdateAuditDto } from '@infrastructure/Audits/models/UpdateAuditDto';

export function transformUpdateAuditDto(
  payload: UpdateAuditPayload
): UpdateAuditDto {
  return {
    ...payload,
    assignees: payload.assignees.map(({ id }) => id),
  };
}
