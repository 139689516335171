import { IConcise } from '@repo/shared/types';
import { IssueTypeField } from '@domain/IssueTypes/models/IssueTypeField';
import { Priority } from '@repo/shared/enums';

export interface IssueType {
  id: string;
  name: string;
  autoAssignJobTitles: IConcise[];
  description: string | null;
}

export enum IssueTypeContactFieldType {
  Name,
  Email,
  Phone,
}

export enum DefaultIssueTypeDueDatePeriod {
  Day,
  Week,
  Month,
}

export interface IssueTypeContactInformationField {
  id: string;
  type: IssueTypeContactFieldType;
  required: boolean;
}

export interface IssueTypeDetails extends IssueType {
  fields: IssueTypeField[];
  contactInformationFields: IssueTypeContactInformationField[];
  contactInformationInfo: string | null;
  defaultDueDatePeriod: DefaultIssueTypeDueDatePeriod;
  defaultDueDatePeriodValue: number | null;
  defaultPriority: Priority;
}
