import React, { useEffect } from 'react';
import Skeleton from 'antd/es/skeleton';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { auditObjectsSelectors } from '@store';
import { ProfileSection } from '@components/account/EditProfile/styled';
import { useAppDispatch } from '@hooks';
import { auditObjectsActions } from '@store';

import AuditObjectContentWrapper, {
  AuditObjectPage,
} from '../AuditObjectContentWrapper/AuditObjectContentWrapper';
import LocationMap from '@repo/shared/components/Location/LocationMap/LocationMap';
import HeaderActionMenu from './HeaderActionMenu/HeaderActionMenu';
import AddEditAuditObjectModal from '@components/auditObjects/list/AddEditAuditObjectModal/AddEditAuditObjectModal';
import DeleteAuditObjectModal from '@components/auditObjects/list/DeleteAuditObjectModal';

const AuditObjectDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { loading, data } = useSelector(auditObjectsSelectors.getObjectDetails);

  useEffect(() => {
    return () => {
      dispatch(auditObjectsActions.objects.hideAddEditModal());
    };
  }, []);

  return (
    <AuditObjectContentWrapper
      page={AuditObjectPage.Details}
      extra={<HeaderActionMenu auditObject={data || undefined} />}
    >
      {loading ? (
        <Skeleton active style={{ width: '500px', marginTop: '20px' }} />
      ) : (
        <>
          <ProfileSection>
            <h3>{formatMessage({ id: 'Name' })}</h3>
            <span>{data?.name}</span>
          </ProfileSection>
          {!!data?.ianaTimeZone && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Timezone' })}</h3>
              <span>{data?.ianaTimeZone}</span>
            </ProfileSection>
          )}
          {(!!data?.geoAddress?.address ||
            (!!data?.geoAddress?.lat && !!data?.geoAddress?.lng)) && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Location' })}</h3>
              {!!data?.geoAddress?.address && (
                <div style={{ marginBottom: 20 }}>
                  {data.geoAddress.address}
                </div>
              )}
              <LocationMap location={data.geoAddress} />
            </ProfileSection>
          )}
          {!!data?.notes && (
            <ProfileSection>
              <h3>{formatMessage({ id: 'Notes' })}</h3>
              <span>{data?.notes}</span>
            </ProfileSection>
          )}
        </>
      )}
      <AddEditAuditObjectModal />
      <DeleteAuditObjectModal />
    </AuditObjectContentWrapper>
  );
};

export default AuditObjectDetails;
