import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Buttons, ClearBtn, FilterGroupHeader, FilterQty } from './styled';
import { accountSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { issueTypeQrCodesSelectors } from '@application/IssueTypes/store/issueTypeQrCodesSelectors';
import { issueTypeQrCodesActions } from '@application/IssueTypes/store/issueTypeQrCodesActions';
import { IssueTypeQrCodesFilters } from '@application/IssueTypes/models/IssueTypeQrCodesFilters';

import SlideModal from '@components/shared/SideModal/SideModal';
import Form from '@components/shared/ant/Form';
import IssueTypesSelect from '@components/shared/EntitySelects/IssueTypesSelect';
import LabeledSwitch from '@components/shared/LabeledSwitch/LabeledSwitch';
import AuditObjectsAndAuditObjectGroupsSelect from '@components/shared/EntitySelects/AuditObjectsAndAuditObjectGroupsSelect';

interface IProps {
  issueTypeId?: string;
  auditObjectId?: string;
  auditObjectGroupId?: string;
}

const IssueTypeQrCodesFiltersModal: React.FC<
  React.PropsWithChildren<IProps>
> = ({ issueTypeId, auditObjectId, auditObjectGroupId }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const opened = useSelector(issueTypeQrCodesSelectors.isFiltersModalOpened);
  const filters = useSelector(issueTypeQrCodesSelectors.getFilters);
  const selectedFiltersQty = useSelector(
    issueTypeQrCodesSelectors.getSelectedFiltersQty
  );
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  useEffect(() => {
    if (opened) {
      form.resetFields();
    }
  }, [opened]);

  const getIssueTypeQrCodes = useCallback(
    (update: Partial<IssueTypeQrCodesFilters> | null) =>
      dispatch(issueTypeQrCodesActions.getQrCodes(update)),
    [dispatch]
  );

  const closeModal = useCallback(
    () => dispatch(issueTypeQrCodesActions.toggleFiltersModal(false)),
    [dispatch]
  );

  return (
    <SlideModal
      opened={opened}
      onModalClose={() => {
        closeModal();
      }}
    >
      <Form<{
        auditObject: {
          auditObjectIds: string[];
          auditObjectGroupIds: string[];
        };
        issueTypesIds: string[];
        allowAnyAuditObject: boolean;
      }>
        form={form}
        name="issue-type-qr-codes-filters-form"
        initialValues={{
          auditObjectsIds: filters.auditObjectsIds,
          order: {
            orderBy: filters.orderBy,
            orderByDirection: filters.orderByDirection,
          },
          issueTypeIds: filters.issueTypeIds,
          allowAnyAuditObject: filters.allowAnyAuditObject,
        }}
      >
        {!issueTypeId && (
          <>
            <FilterGroupHeader>
              {formatMessage({ id: 'IssueType' })}
            </FilterGroupHeader>
            <Form.Item name="issueTypeIds">
              <IssueTypesSelect
                allowClear
                mode="multiple"
                onChange={(issueTypeIds: string[]) =>
                  getIssueTypeQrCodes({
                    issueTypeIds,
                    pageNumber: 1,
                  })
                }
                placeholder={formatMessage({ id: 'SelectIssueType' })}
              />
            </Form.Item>
          </>
        )}
        {!auditObjectId && !auditObjectGroupId && (
          <>
            <FilterGroupHeader>{`${auditObjectName.plural} ${formatMessage({
              id: 'or',
            })} ${formatMessage({ id: 'Groups' })}`}</FilterGroupHeader>
            <Form.Item name="auditObject">
              <AuditObjectsAndAuditObjectGroupsSelect
                allowClear
                onChange={({ auditObjectGroupIds, auditObjectIds }) =>
                  getIssueTypeQrCodes({
                    auditObjectGroupsIds: auditObjectGroupIds,
                    auditObjectsIds: auditObjectIds,
                    pageNumber: 1,
                  })
                }
              />
            </Form.Item>
            <Form.Item name="allowAnyAuditObject" style={{ marginTop: '25px' }}>
              <LabeledSwitch
                title={formatMessage(
                  { id: 'QrCodeForAllAuditObjects' },
                  { auditObjectName: auditObjectName.plural.toLowerCase() }
                )}
                onChange={(checked) => {
                  getIssueTypeQrCodes({
                    allowAnyAuditObject: checked,
                  });
                }}
              />
            </Form.Item>
          </>
        )}
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                getIssueTypeQrCodes(null);
                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default IssueTypeQrCodesFiltersModal;
