import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spin from 'antd/es/spin';
import { unwrapResult } from '@reduxjs/toolkit';

import { date, isUUID, notification } from '@utils';
import { routes } from '@config';
import { config } from '@repo/shared/config';
import {
  issueTypesActions,
  issueTypesSelectors,
} from '@application/IssueTypes/store';
import { useAppDispatch } from '@hooks';
import { IExposedFormRef } from '@application/Issues/hooks/useExposedForm';
import {
  Content,
  Definitions,
  Thanks,
  ThankYouTitle,
  ThankYouDesc,
} from './styled';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useOpacityTransition } from '@hooks';
import { InternalApiService } from '@repo/shared/api';

import NavyBlueContainer from '@components/shared/NavyBlueContainer/NavyBlueContainer';
import OneColAuthWrapper from '@components/account/AuthForms/OneColAuthWrapper';
import IssueQuestionsForm from '@src/presentation/Issues/IssueQuestionsForm/IssueQuestionsForm';
import Definition from '@components/shared/Definition/Definition';
import Button from '@components/shared/ant/Button';
import Loader from '@components/shared/Loader/Loader';
import AppLogo from '@components/shared/AppLogo';
import IssueContactInformationForm from '@src/presentation/Issues/CreateIssuePublicPage/IssueContactInformationForm/IssueContactInformationForm';

interface IProps {}

const CreateIssuePublicPage: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const questionsFormRef = useRef<IExposedFormRef>(null);
  const contactInfoFormRef = useRef<IExposedFormRef>(null);

  const [issueTypeId, setIssueTypeId] = useState<string>();
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [processing, setProcessing] = useState(false);
  const [issueNumber, setIssueNumber] = useState<number | null>(null);

  const transition = useOpacityTransition<number | null>(issueNumber);

  const { loading: issueTypeDetailsLoading, data: issueTypeDetails } =
    useSelector(issueTypesSelectors.getIssueTypeDetails);
  const {
    loading: issueContextLoading,
    contextAuditObjects,
    auditObjectName,
  } = useSelector(issuesSelectors.getIssueContext);

  useEffect(() => {
    const _companyId = searchParams.get('companyId');
    const _issueTypeQrCodeId = searchParams.get('issueTypeQrCodeId');

    if (!isUUID(_companyId) || !isUUID(_issueTypeQrCodeId)) {
      navigate(routes.createIssueError);
      return;
    }

    setCompanyId(_companyId!);
    InternalApiService.configure(_companyId!);

    async function loadData() {
      const resultContextAction = await dispatch(
        issuesActions.getIssueContext({
          issueTypeQrCodeId: _issueTypeQrCodeId!,
          companyId: _companyId!,
        })
      );

      let hasFetchError = false;

      if (issuesActions.getIssueContext.fulfilled.match(resultContextAction)) {
        const result = unwrapResult(resultContextAction);

        const resultDetailsAction = await dispatch(
          issueTypesActions.getIssueTypeDetails({
            issueTypeId: result.issueTypeId,
            companyId: _companyId!,
          })
        );

        if (
          issueTypesActions.getIssueTypeDetails.fulfilled.match(
            resultDetailsAction
          )
        ) {
          setIssueTypeId(result.issueTypeId);
        } else {
          hasFetchError = true;
        }
      } else {
        hasFetchError = true;
      }

      if (hasFetchError) {
        navigate(routes.createIssueError);
      }
    }

    loadData();
  }, []);

  if (issueTypeDetailsLoading || issueContextLoading) {
    return <Loader />;
  }

  return (
    <NavyBlueContainer>
      <Content>
        {transition.map(({ item, key, props }) => {
          if (item) {
            return (
              <Thanks key={key} style={props}>
                <AppLogo
                  version="icon"
                  align="center"
                  width={200}
                  height={51}
                />
                <ThankYouTitle>
                  {formatMessage({ id: 'ThankYouForYourContribution' })}
                </ThankYouTitle>
                <ThankYouDesc>
                  {formatMessage(
                    { id: 'IssueHasBeenSubmitted' },
                    {
                      issueNumber,
                      createDate: date().format(config.dateTimeFormat),
                    }
                  )}
                </ThankYouDesc>
              </Thanks>
            );
          }

          return null;
        })}
        <OneColAuthWrapper
          title={formatMessage({ id: 'CreateIssue' })}
          titleStyle={{ textAlign: 'center', fontSize: '22px' }}
          containerMaxWidth={400}
          logo={
            <AppLogo version="icon" align="center" width={200} height={51} />
          }
        >
          <Spin spinning={processing}>
            <Definitions>
              {!!auditObjectName && contextAuditObjects.length === 1 && (
                <Definition
                  label={auditObjectName}
                  value={contextAuditObjects[0].name}
                  horizontal
                />
              )}
              <Definition
                label={formatMessage({ id: 'IssueType' })}
                horizontal
                value={issueTypeDetails?.name}
              />
            </Definitions>
            <IssueQuestionsForm
              ref={questionsFormRef}
              getFilePath={(file, size) =>
                file.contentType?.includes('video')
                  ? `issues/public/files/${file.id}/video-preview`
                  : `issues/public/files/${file.id}${size ? `/resized?size=${size}` : ''}`
              }
              getFileUploadPath={(fileId) => `issues/public/files/${fileId}`}
              getFileMetadataPath={(fileId) =>
                `issues/public/files/${fileId}/metadata`
              }
            />
            <IssueContactInformationForm ref={contactInfoFormRef} />
            <Button
              type="primary"
              style={{ width: '100%', marginTop: '16px' }}
              onClick={async () => {
                if (!companyId || !issueTypeDetails) {
                  return;
                }

                setProcessing(true);

                const questionsFieldsValues =
                  await questionsFormRef?.current?.submitForm();
                const contactInformationFieldsValues =
                  await contactInfoFormRef?.current?.submitForm();

                if (
                  !!questionsFieldsValues &&
                  (issueTypeDetails.contactInformationFields.length > 0
                    ? contactInformationFieldsValues
                    : true)
                ) {
                  const updatedValues = {
                    issueTypeId,
                    ...questionsFieldsValues,
                    ...(contactInformationFieldsValues || {}),
                  };

                  if (contextAuditObjects.length === 1) {
                    updatedValues.auditObjectId = contextAuditObjects[0].id;
                  }

                  const resultAction = await dispatch(
                    issuesActions.createIssuePublic({
                      createIssueDto: updatedValues,
                      companyId,
                    })
                  );

                  if (issuesActions.createIssue.fulfilled.match(resultAction)) {
                    const { number } = unwrapResult(resultAction);

                    setIssueNumber(number);
                  } else {
                    notification.error({
                      message: formatMessage({
                        id: 'ErrorWhileCreatingIssue',
                      }),
                    });
                  }
                }

                setProcessing(false);
              }}
            >
              {formatMessage({ id: 'Create' })}
            </Button>
          </Spin>
        </OneColAuthWrapper>
      </Content>
    </NavyBlueContainer>
  );
};

export default CreateIssuePublicPage;
