import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const ItemContainer = styled.div`
  margin-bottom: 18px;
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  margin-bottom: 8px;
`;

export const Content = styled.div``;
