import React, { useCallback, useState } from 'react';
import Spin from 'antd/es/spin';

import { AssigneesModalContext } from '@repo/shared/enums';
import Assignees from '@components/shared/Assignees/Assignees';
import { IConcise } from '@repo/shared/types';
import { Action } from '@domain/Actions/models/Action';
import { actionsActions } from '@store';
import { notification } from '@utils';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '@hooks';

interface IProps {
  action: Action;
  disabled?: boolean;
  e2eDataAttr?: string;
}

const ActionAssignees: React.FC<React.PropsWithChildren<IProps>> = ({
  action,
  disabled,
  e2eDataAttr,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [updating, setUpdating] = useState(false);

  const updateAssignees = useCallback(async (assignees: IConcise[]) => {
    setUpdating(true);

    const resultAction = await dispatch(
      actionsActions.assignUsersToActions({
        actionsIds: [action.id],
        usersIds: assignees.map(({ id }) => id),
      })
    );

    if (actionsActions.assignUsersToActions.fulfilled.match(resultAction)) {
      dispatch(
        actionsActions.updateActionDetailsAssignees({
          actionId: action.id,
          assignees,
        })
      );
    } else {
      notification.error({
        message: formatMessage(
          { id: 'ErrorWhileEditingItem' },
          {
            item: formatMessage({
              id: 'CorrectiveAction',
            }).toLowerCase(),
          }
        ),
        description: resultAction.payload as string,
      });
    }

    setUpdating(false);
  }, []);

  return (
    <Spin spinning={updating}>
      <Assignees
        auditObjectId={action.auditObject?.id}
        users={action.assignedUsers}
        context={AssigneesModalContext.Action}
        onChange={updateAssignees}
        showJobTitles
        disabled={disabled}
        e2eDataAttr={e2eDataAttr}
      />
    </Spin>
  );
};

export default ActionAssignees;
