import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { routes } from '@config';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { schedulesSelectors } from '@application/Schedules/store/schedulesSelectors';
import { useAppDispatch, usePrevious } from '@hooks';

interface IProps {}

const AddEditScheduleModalRouteHandler: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const modalState = useSelector(schedulesSelectors.getAddEditModalState);

  const prevOpened = usePrevious(modalState.opened);
  const prevId = usePrevious(id);

  useEffect(() => {
    if (modalState.opened && modalState.scheduleId && (!id || id !== prevId)) {
      navigate(routes.scheduleDetails.replace(':id', modalState.scheduleId));
    } else if (!prevOpened && !modalState.opened && id) {
      dispatch(
        schedulesActions.toggleAddEditModal({ opened: true, scheduleId: id })
      );
    } else if (!modalState.opened && id) {
      navigate(routes.schedules);
    }
  }, [modalState.opened, prevOpened, prevId, id]);

  return null;
};

export default AddEditScheduleModalRouteHandler;
