import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import folder from './folder.svg';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 34px;
  margin-bottom: 14px;
  align-items: center;
  padding: 0 12px;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: ${colors.blue2};
    opacity: 0.1;
  }
`;

export const FolderIcon = styled.div`
  width: 20px;
  height: 18px;
  margin-right: 6px;
  background: url(${folder}) no-repeat left center;
  background-size: 18px 18px;
  padding-left: 2px;
`;

export const TemplateName = styled.div`
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  color: ${colors.blue2};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
