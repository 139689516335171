import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  font-weight: normal;
  display: flex;
  align-items: center;

  ${({ switchPosition }: { switchPosition?: 'start' | 'end' }) => {
    if (switchPosition === 'start') {
      return `
        flex-direction: row-reverse;
        justify-content: flex-end;
        
        & > * {
          &:last-child {
            margin-right: 20px;
          }
        }
      `;
    }

    return `
      justify-content: space-between;
      
      & > * {
        &:first-child {
          margin-right: 10px;
        }
      }
    `;
  }}
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

export const Description = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
`;
