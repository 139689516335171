import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    position: relative;
    top: -1px;
    width: 15px;
    height: 15px;
    line-height: 1;
    color: ${colors.gray7};
    margin-left: 6px;
  }
`;
