export const schedules = {
  table: 'schedules-table',
  addScheduleButton: 'schedules-add-button',
  addEditSchedule: {
    templateSelect: 'schedule-add-edit-template-select',
    name: 'schedule-add-edit-name',
    objectOrObjectGroupSelect: 'schedule-add-edit-object-object-group-select',
    auditorAssignmentSelect: 'schedule-add-edit-auditor-assignment-select',
    auditorSelect: 'schedule-add-edit-auditor-select',
    tagsSelect: 'schedule-add-edit-tags-select',
    repeatTypeSelect: 'schedule-add-edit-repeat-type-select',
    repeatRange: 'schedule-add-edit-repeat-range',
    scheduleButton: 'schedule-add-edit-submit-button',
    cancelButton: 'schedule-add-edit-cancel-button',
    auditorGroupsSelect: 'schedule-add-edit-auditor-groups-select',
    repeatEverySelect: 'schedule-add-edit-repeat-every-select',
    firstAuditDaySelect: 'schedule-add-edit-first-audit-day-select',
    auditDurationDaysSelect: 'schedule-add-edit-audit-duration-select',
  },
};
