import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { notification } from '@utils';
import { Issue } from '@domain/Issues/models/Issue';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { generalActions, generalSelectors } from '@store';
import { constructIssueName } from '@application/Issues/utils/constructIssueName';
import { AssigneesModalContext } from '@repo/shared/enums';

import BulkUsersAssignmentModal from '@components/shared/BulkUsersAssignmentModal/BulkUsersAssignmentModal';

interface IProps {}

const AssignUsersToIssuesModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  const modalState = useSelector(
    issuesSelectors.getAssignUsersToIssuesModalState
  );
  const issuesDictionary = useSelector(issuesSelectors.getIssuesDictionary);

  const closeModal = useCallback(
    () => dispatch(issuesActions.toggleAssignUsersToIssuesModal(null)),
    []
  );

  return (
    <BulkUsersAssignmentModal<Issue>
      opened={modalState !== null}
      title={formatMessage({
        id: 'UsersToIssuesAssignment',
      })}
      auditObjectId={modalState ? modalState.auditObjectId : undefined}
      description={formatMessage(
        {
          id: 'PleaseSelectUsersFromDropdownToAssignTheFollowingIssues',
        },
        { count: modalState ? modalState.issuesIds.length : 1 }
      )}
      entitiesIds={modalState ? modalState.issuesIds : []}
      entitiesMap={issuesDictionary}
      formatEntityName={constructIssueName}
      assigneesContext={AssigneesModalContext.Issue}
      onAssignClick={async (usersIds) => {
        if (!modalState) {
          return;
        }

        const resultAction = await dispatch(
          issuesActions.assignUsersToIssues({
            issuesIds: modalState.issuesIds,
            usersIds,
          })
        );

        if (issuesActions.assignUsersToIssues.fulfilled.match(resultAction)) {
          dispatch(issuesActions.getIssues());

          notification.success({
            message: formatMessage({ id: 'IssuesHaveBeenAssigned' }),
          });

          if (selectedTableRowKeys.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }

          closeModal();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileAssigningIssues' }),
          });
        }
      }}
      onCancel={() => {
        closeModal();
      }}
    />
  );
};

export default AssignUsersToIssuesModal;
