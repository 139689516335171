import { IssueDetails } from '@domain/Issues/models/Issue';
import { EditIssueDto } from '@infrastructure/Issues/models/EditIssueDto';

export function transformIssueDetailsToEditIssueDto({
  audits,
  correctiveActions,
  name,
  auditObject,
  status,
  dueDate,
  assignedUsers,
  priority,
}: IssueDetails): EditIssueDto {
  return {
    name,
    status,
    priority,
    auditObjectId: auditObject.id,
    dueDate: dueDate.localTime,
    auditsIds: audits.map(({ id }) => id),
    correctiveActionsIds: correctiveActions.map(({ id }) => id),
    assignedUsersIds: assignedUsers.map(({ id }) => id),
  };
}
