import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Badge = styled.div`
  margin-left: 7px;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: ${colors.gray9};
  background: ${colors.gray4};
  cursor: pointer;
`;

export const PopoverContent = styled.div`
  max-width: 160px;
  font-size: 12px;
`;
