import { ITableFilters } from '@repo/shared/types';
import { AssignmentType, Priority } from '@repo/shared/enums';
import { IssueCloseReason } from '@domain/Issues/models/IssueEvent';

export enum PendingIssuesDueDateStatus {
  All,
  DueSoon,
  PastDue,
}

export enum CompletedIssuesLateStatus {
  All,
  OnTime,
  Late,
}

export interface IssuesFilters extends ITableFilters {
  priority: Priority | null;
  startDate: string | null;
  endDate: string | null;
  auditObjectIds: string[];
  assignment: AssignmentType | null;
  assignedTo: string | null;
  assignedToGroup: string | null;
  reason: IssueCloseReason | null;
  issueTypeIds: string[];
  dueDateStatus: PendingIssuesDueDateStatus;
  lateStatus: CompletedIssuesLateStatus;
}
