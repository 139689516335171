import React from 'react';

import { IssueEvent, IssueEventType } from '@domain/Issues/models/IssueEvent';

import IssueDetailsEventComment from './IssueDetailsEventComment/IssueDetailsEventComment';
import IssueDetailsEventAction from './IssueDetailsEventActionAudit/IssueDetailsEventAction';
import IssueDetailsEventAudit from './IssueDetailsEventActionAudit/IssueDetailsEventAudit';
import IssueDetailsOpenedEvent from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsOpenedEvent/IssueDetailsOpenedEvent';
import IssueDetailsClosedEvent from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsClosedEvent/IssueDetailsClosedEvent';
import IssueDetailsChangedEvent from '@src/presentation/Issues/IssueDetails/IssueDetailsEvents/IssueDetailsEvent/IssueDetailsEventData/IssueDetailsChangedEvent/IssueDetailsChangedEvent';

interface IProps {
  event: IssueEvent;
}

const IssueDetailsEventData: React.FC<React.PropsWithChildren<IProps>> = ({
  event,
}) => {
  switch (event.type) {
    case IssueEventType.IssueOpened:
      return <IssueDetailsOpenedEvent event={event} />;
    case IssueEventType.IssueClosed:
      return <IssueDetailsClosedEvent event={event} />;
    case IssueEventType.Comment:
      return <IssueDetailsEventComment event={event} />;
    case IssueEventType.ActionEvent:
      return <IssueDetailsEventAction event={event} />;
    case IssueEventType.AuditEvent:
      return <IssueDetailsEventAudit event={event} />;
    case IssueEventType.IssueChanged:
      return <IssueDetailsChangedEvent event={event} />;
  }
};

export default IssueDetailsEventData;
