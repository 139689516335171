import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Phone = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <g clipPath="url(#clip0_6_2186)">
          <path
            d="M5 7.8C5 6.11984 5 5.27976 5.32698 4.63803C5.6146 4.07354 6.07354 3.6146 6.63803 3.32698C7.27976 3 8.11984 3 9.8 3H14.2C15.8802 3 16.7202 3 17.362 3.32698C17.9265 3.6146 18.3854 4.07354 18.673 4.63803C19 5.27976 19 6.11984 19 7.8V16.2C19 17.8802 19 18.7202 18.673 19.362C18.3854 19.9265 17.9265 20.3854 17.362 20.673C16.7202 21 15.8802 21 14.2 21H9.8C8.11984 21 7.27976 21 6.63803 20.673C6.07354 20.3854 5.6146 19.9265 5.32698 19.362C5 18.7202 5 17.8802 5 16.2V7.8Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 18H10"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_6_2186">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

export default Phone;
