import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Users } from 'react-feather';
import { ColumnType } from 'antd/es/table';

import { getColumnSortProperties } from '@utils';
import { IUserAccess } from '@repo/shared/types';
import {
  accountSelectors,
  generalSelectors,
  usersActions,
  usersSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { UserGroupRow, JobTitleRow, GroupName } from './styled';
import { e2eTestElements } from '@config';

import Table from '../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../shared/ant/EmptyTable/EmptyTable';
import UsersAccessActionsMenu from '@components/users/single/UserAccess/UsersAccessTable/UsersAccessActionsMenu';
import NonParticipantBadge from '@components/users/single/UserAccess/UsersAccessTable/NonPartipantBadge/NonParticipantBadge';

interface IProps {
  auditObjectId?: string;
  userId?: string;
}

const UsersAccessTable: React.FC<React.PropsWithChildren<IProps>> = ({
  auditObjectId,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, error, data, meta } = useSelector(
    usersSelectors.access.getData
  );
  const filters = useSelector(usersSelectors.access.getFilters);

  const auditObjectsMap = useSelector(
    generalSelectors.getConciseAuditObjectsMap
  );
  const usersMap = useSelector(generalSelectors.getConciseUsersMap);
  const userGroupsMap = useSelector(generalSelectors.getConciseUserGroupsMap);
  const jobTitlesMap = useSelector(generalSelectors.getConciseJobTitlesMap);

  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const [canManageAuditsObjects, canManageUsers] = usePermission([
    Permission.CanManageAuditObjects,
    Permission.CanManageUsers,
  ]);
  const { isJobTitlesAndAccessReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const canManage =
    !isJobTitlesAndAccessReadonly && canManageUsers && canManageAuditsObjects;

  useEffect(() => {
    return () => {
      dispatch(usersActions.access.resetData());
    };
  }, []);

  return (
    <Table<IUserAccess>
      onPageChange={(update) => {
        dispatch(usersActions.access.getData(update));
      }}
      loading={loading}
      meta={meta}
      canSelectRow={canManage}
      e2eDataAttr={e2eTestElements.users.access.table}
      error={error}
      columns={[
        ...(!auditObjectId
          ? [
              {
                title: auditObjectName.single,
                dataIndex: 'auditObjectName',
                key: 'auditObjectName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'auditObjectName'
                ),
                render: (_, access) => {
                  const name = auditObjectsMap[access.auditObjectId]?.name;

                  if (!name) {
                    return null;
                  }

                  return (
                    <UserGroupRow>
                      {auditObjectsMap[access.auditObjectId]?.name}
                      {access.notParticipant && (
                        <NonParticipantBadge
                          auditObjectName={name}
                          userName={userId && usersMap[userId]?.name}
                        />
                      )}
                    </UserGroupRow>
                  );
                },
              } as ColumnType<IUserAccess>,
            ]
          : []),
        ...(!userId
          ? [
              {
                title: formatMessage({ id: 'Users/Groups' }),
                dataIndex: 'userOrGroupName',
                key: 'userOrGroupName',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'userOrGroupName'
                ),
                render: (_, access) => {
                  let name = '';

                  if (access.userId) {
                    name = usersMap[access.userId]?.name || '';
                  } else if (access.userGroupId) {
                    name = userGroupsMap[access.userGroupId]?.name || '';
                  }

                  return (
                    <UserGroupRow>
                      {access.userGroupId !== null && <Users />}
                      {name}
                      {access.notParticipant && (
                        <NonParticipantBadge
                          userName={name}
                          auditObjectName={
                            auditObjectId && auditObjectsMap[auditObjectId]
                              ? auditObjectsMap[auditObjectId]!.name
                              : auditObjectName.single.toLowerCase()
                          }
                        />
                      )}
                    </UserGroupRow>
                  );
                },
              } as ColumnType<IUserAccess>,
            ]
          : []),
        {
          title: formatMessage({ id: 'JobTitle' }),
          dataIndex: 'jobTitleName',
          key: 'jobTitleName',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'jobTitleName'
          ),
          render(_, userAccess) {
            const jobTitleName =
              userAccess.jobTitleId &&
              jobTitlesMap[userAccess.jobTitleId]?.name;

            if (!jobTitleName) {
              return null;
            }

            const userGroupName =
              userAccess.userId &&
              userAccess.userGroupId &&
              userGroupsMap[userAccess.userGroupId]?.name;

            return (
              <JobTitleRow>
                {jobTitleName}
                {!!userGroupName && <GroupName>{userGroupName}</GroupName>}
              </JobTitleRow>
            );
          },
        },
        ...(canManage
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center',
                width: 70,
                render: (_, access) =>
                  <UsersAccessActionsMenu access={access} />,
              } as ColumnType<IUserAccess>,
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((access: IUserAccess) => {
              return {
                key: access.id,
                ...access,
                disableRowSelection: access.notParticipant,
              };
            })
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage({
                  id: 'NoAccessAssignmentsHaveBeenFound',
                })}
              </>
            }
            button={
              canManage
                ? {
                    text: `+ ${formatMessage({ id: 'AssignAccess' })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        usersActions.access.toggleAssignAccessModal(true)
                      );
                    },
                  }
                : null
            }
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          usersActions.access.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default UsersAccessTable;
