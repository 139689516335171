import styled from 'styled-components';
import AntSwitch from 'antd/es/switch';

import { colors } from '@repo/shared/config';

const Switch = styled(AntSwitch)`
  &.ant-switch-checked {
    background-color: ${colors.green}!important;
  }

  .ant-switch-handle {
    &:before {
      box-shadow: 0 2px 4px rgba(0, 35, 11, 0.2);
    }
  }
`;

export default Switch;
