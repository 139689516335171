import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Buttons, FilterGroupHeader } from './styled';
import { usersActions, usersSelectors } from '@store';
import { getInitialUsersFilters } from '@store/modules/users/users.reducer';
import { IUsersFilters } from '@repo/shared/types';
import { useAppDispatch } from '@hooks';
import {
  ClearBtn,
  FilterQty,
} from '@src/presentation/Actions/ActionsFiltersModal/styled';

import SlideModal from '@components/shared/SideModal/SideModal';
import Form from '@components/shared/ant/Form';
import GroupingStatusButtons from '@components/users/list/UsersFiltersModal/GroupingStatusButtons/GroupingStatusButtons';
import UserGroupsSelect from '@components/shared/EntitySelects/UserGroupsSelect';
import RolesSelect from '@components/users/list/AddEditUserModal/RolesSelect';

interface IProps {}

const UsersFiltersModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const visible = useSelector(usersSelectors.list.isFiltersModalVisible);
  const filters = useSelector(usersSelectors.list.getFilters);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  const getUsers = useCallback(
    (update: Partial<IUsersFilters>) =>
      dispatch(usersActions.list.getData(update)),
    [dispatch]
  );
  const selectedFiltersQty = useSelector(
    usersSelectors.list.getSelectedFiltersQty
  );

  const closeModal = useCallback(
    () => dispatch(usersActions.list.toggleFiltersModal(false)),
    [dispatch]
  );

  return (
    <SlideModal opened={visible} onModalClose={() => closeModal()}>
      <Form
        layout="vertical"
        form={form}
        name="users-filters-form"
        onFinish={({ userGroupsIds, userRolesIds, ungrouped }) => {
          getUsers({
            userGroupIds: userGroupsIds,
            userRoleIds: userRolesIds,
            ungrouped,
          });

          closeModal();
        }}
        initialValues={{
          ungrouped: filters.ungrouped,
          userGroupsIds: filters.userGroupIds,
          userRolesIds: filters.userRoleIds,
        }}
      >
        <Form.Item name="ungrouped">
          <GroupingStatusButtons
            onChange={(ungrouped) => {
              form.setFieldsValue({ userGroupsIds: [] });

              getUsers({
                ungrouped,
                userGroupIds: [],
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'OrInGroups' })}
        </FilterGroupHeader>
        <Form.Item name="userGroupsIds">
          <UserGroupsSelect
            allowClear
            placeholder={formatMessage({ id: 'SelectUserGroups' })}
            mode="multiple"
            onChange={(userGroupsIds) => {
              form.setFieldsValue({ ungrouped: false });

              getUsers({
                ungrouped: false,
                userGroupIds: userGroupsIds,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <FilterGroupHeader>
          {formatMessage({ id: 'UserRoles' })}
        </FilterGroupHeader>
        <Form.Item name="userRolesIds">
          <RolesSelect
            allowClear
            mode="multiple"
            placeholder={formatMessage({ id: 'SelectRole' })}
            onChange={(userRolesIds) => {
              getUsers({
                userRoleIds: userRolesIds,
                pageNumber: 1,
              });
            }}
          />
        </Form.Item>
        <Buttons>
          {selectedFiltersQty > 0 && (
            <ClearBtn
              type="button"
              onClick={() => {
                const { search: _, ...initialModalFilters } =
                  getInitialUsersFilters();

                getUsers(initialModalFilters);

                closeModal();
              }}
            >
              {formatMessage(
                { id: 'ClearFilter' },
                { filtersCount: selectedFiltersQty }
              )}
              <FilterQty>{selectedFiltersQty}</FilterQty>
            </ClearBtn>
          )}
        </Buttons>
      </Form>
    </SlideModal>
  );
};

export default UsersFiltersModal;
