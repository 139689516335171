import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CommentsList = styled.div`
  padding: 20px;
`;

export const CommentContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const SkeletonContainer = styled.div`
  width: 400px;
  margin: 25px 0 0 25px;
`;
