import React from 'react';
import { useIntl } from 'react-intl';
import Space from 'antd/es/space';

import { schedulePlansActions } from '@application/SchedulePlans/store/schedulePlansActions';
import { Permission } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { getInitialFilters } from '@utils';

import SearchInput from '@components/shared/SeachInput/SearchInput';
import PageHeader from '@components/shared/ant/PageHeader';
import Button from '@components/shared/ant/Button';

const SchedulePlansHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canScheduleAudits = usePermission(Permission.CanScheduleAudits);

  return (
    <PageHeader
      title={formatMessage({ id: 'AdvancedSchedules' })}
      extra={
        <Space>
          <SearchInput
            onEnter={(search: string) => {
              dispatch(
                schedulePlansActions.getSchedulePlans({
                  ...getInitialFilters(),
                  search,
                })
              );
            }}
          />
          {canScheduleAudits && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  schedulePlansActions.toggleAddEditModal({ show: true })
                );
              }}
            >
              {formatMessage({
                id: 'Create',
              })}
            </Button>
          )}
        </Space>
      }
    />
  );
};

export default SchedulePlansHeader;
