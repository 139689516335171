import { IRootState } from '@src/core/frameworks/redux';
import { createSelector } from '@reduxjs/toolkit';
import { IIntervals, IScore } from '@repo/shared/types';
import { ActionType, AnswerType, ItemType } from '@repo/shared/enums';
import {
  calculateItemTreeStats,
  checkIsChildNADisabledInTree,
  checkIsParentNADisabled,
  convertItemsMapToTree,
  getAncestorsSections,
  getAuditReportTotals,
  getCountableItemsIds,
  isAnswerFailed,
  validateAnswer,
} from '@utils';
import { hasOwnProperty } from '@repo/shared/utils';
import { ItemFlag } from '@repo/shared/enums';
import { AuditDetails } from '@domain/Audits/models/AuditDetails';
import { PerformAuditItem } from '@domain/PerformAudit/PerformAuditItem';
import { NotUploadedFile } from '@domain/PerformAudit/NotUploadedFile';
import { DeleteNotUploadedFileModalState } from '@application/PerformAudit/models/DeleteNotUploadedFileModalState';

const getPerformAuditItems = (
  state: IRootState
): Record<string, PerformAuditItem> | null => state.performAudit.items;

const getPerformAuditRootItemId = (state: IRootState): string | null =>
  state.performAudit.rootItemId;

const getPerformAuditFilters = (state: IRootState) =>
  state.performAudit.filters;

const getAudit = (state: IRootState): AuditDetails | null =>
  state.performAudit.audit;

const getAuditId = createSelector(getAudit, (audit) => audit?.id || null);

const getPerformAuditSignature = (state: IRootState) =>
  state.performAudit.signAudit;

const getPerformAuditSections = createSelector(
  [getPerformAuditItems, getPerformAuditRootItemId],
  (items, rootItemId) => {
    return items && rootItemId && items[rootItemId]
      ? items[rootItemId].childrenIds
          .filter((id) => items[id])
          .map((id: string) => items[id])
      : [];
  }
);

export const performAuditSelectors = {
  isVisible: (state: IRootState) => state.performAudit.visible,
  getRootItemId: getPerformAuditRootItemId,
  getRootItem: createSelector(
    [getPerformAuditItems, getPerformAuditRootItemId],
    (items, rootItemId) => (rootItemId && items ? items[rootItemId] : null)
  ),
  isGalleryUsageAllowed: (state: IRootState) =>
    !state.performAudit.audit?.template?.restrictGalleryUsage,
  getPerformAuditItems: getPerformAuditItems,
  getOpenedSectionId: (state: IRootState) => state.performAudit.openedSectionId,
  getPubnubToken: (state: IRootState) => state.performAudit.pubnubAuthToken,
  isLoading: (state: IRootState) => state.performAudit.loading,
  getAuditId,
  getAudit,
  getAllAuditSections: getPerformAuditSections,
  getSectionIndexInParent: createSelector(
    [
      getPerformAuditItems,
      (state: IRootState) => state.performAudit.openedSectionId,
    ],
    (items, sectionId) => {
      if (
        !items ||
        typeof items[sectionId]?.parentId !== 'string' ||
        !items[items[sectionId]?.parentId as string]
      ) {
        return null;
      }

      const { childrenIds } = items[items[sectionId]?.parentId as string];

      return childrenIds.findIndex((id) => id === sectionId);
    }
  ),
  getEditableAuditSections: createSelector(
    getPerformAuditSections,
    (allSections) => allSections.filter((section) => !section?.notApplicable)
  ),
  makeGetItemTreeByParentId: () =>
    createSelector(
      [
        getPerformAuditItems,
        (_: IRootState, parentId?: string) => parentId,
        getPerformAuditFilters,
      ],
      (
        items,
        parentId,
        {
          search,
          onlyFailedItems,
          onlyCriticalItems,
          onlyRepeats,
          onlyNotApplicable,
          onlyItemsWithNotes,
          onlyItemsWithPhotos,
          onlySignedItems,
          onlyActionsWithStatus,
          onlyItemsWithFlags,
          onlyUnansweredItems,
        }
      ) => {
        if (!items || !parentId) {
          return {
            tree: null,
            isParentNADisabled: false,
            isChildNADisabled: false,
          };
        }

        const filtered = Object.entries(items).reduce<
          Record<string, PerformAuditItem>
        >((acc, [itemId, item]) => {
          if (
            item.itemType !== ItemType.Item &&
            item.itemType !== ItemType.ConditionalItem
          ) {
            acc[itemId] = item;

            return acc;
          }

          let includeItem = true;

          if (
            search !== '' &&
            item.text &&
            item.text.toLowerCase().indexOf(search.toLowerCase()) === -1
          ) {
            includeItem = false;
          }

          if (onlyUnansweredItems) {
            switch (item.answerType) {
              case AnswerType.Text:
                includeItem = item.text === '';
                break;
              case AnswerType.YesNoButtons:
              case AnswerType.PassFailButtons:
              case AnswerType.Temperature:
              case AnswerType.Numeric:
              case AnswerType.Checklist:
              case AnswerType.Slider:
              case AnswerType.Dropdown:
                if (
                  item.data &&
                  hasOwnProperty(item.data, 'conditions') &&
                  Array.isArray(item.data.conditions)
                ) {
                  includeItem = (item.data.conditions as IIntervals).every(
                    (condition: any) => !condition.selected
                  );
                }
                break;
              default:
                break;
            }

            if (
              (item.conditions || []).find(
                ({ actionType }) => actionType === ActionType.RequirePhoto
              ) !== undefined &&
              (!Array.isArray(item.files) || item.files.length === 0)
            ) {
              includeItem = true;
            }
          }

          if (onlyFailedItems && !isAnswerFailed(item.answerType, item.data)) {
            includeItem = false;
          }

          if (onlyCriticalItems && !item.isCritical) {
            includeItem = false;
          }

          if (
            onlyRepeats &&
            item.notApplicableInRowCount <= 1 &&
            item.failedInRowCount <= 1 &&
            Array.isArray(item.flags) &&
            item.flagsInRow.length > 0 &&
            item.flagsInRow.every(({ count }) => count <= 1)
          ) {
            includeItem = false;
          }

          if (onlyNotApplicable && !item.notApplicable) {
            includeItem = false;
          }

          if (onlyItemsWithNotes && !item.note) {
            includeItem = false;
          }

          if (
            onlyItemsWithPhotos &&
            (!Array.isArray(item.files) || item.files.length === 0)
          ) {
            includeItem = false;
          }

          if (onlySignedItems && !item.signature) {
            includeItem = false;
          }

          if (onlyActionsWithStatus.length > 0) {
            includeItem =
              Array.isArray(item.actions) &&
              item.actions.length > 0 &&
              item.actions.some(({ status }) =>
                onlyActionsWithStatus.includes(status)
              );
          }

          if (onlyItemsWithFlags.length > 0) {
            includeItem =
              Array.isArray(item.flags) &&
              item.flags.length > 0 &&
              item.flags.some(
                (flag) =>
                  onlyItemsWithFlags.find(
                    (selected: ItemFlag) => selected === flag
                  ) !== undefined
              );
          }

          if (includeItem) {
            acc[itemId] = item;
          }

          return acc;
        }, {});

        const isParentNADisabled = checkIsParentNADisabled(
          filtered,
          parentId,
          true
        );

        const itemsTree = convertItemsMapToTree({
          itemsMap: filtered,
          rootItemId: parentId,
        });

        const isChildNADisabled = checkIsChildNADisabledInTree(itemsTree);

        return {
          isParentNADisabled,
          tree: itemsTree,
          isChildNADisabled,
        };
      }
    ),
  makeGetItemById: () =>
    createSelector(
      [getPerformAuditItems, (_: IRootState, id: string | null) => id],
      (items, id) => (id && items?.[id] ? items[id] : null)
    ),
  makeGetStatsById: () =>
    createSelector(
      [
        (state: IRootState): IScore | undefined =>
          state.performAudit.auditScoreSystem,
        getPerformAuditItems,
        getPerformAuditRootItemId,
        (_: IRootState, rootItemId: string | null) => rootItemId,
      ],
      (auditScoreSystem, itemsMap, auditRootItemId, rootItemId) => {
        if (
          !itemsMap ||
          !rootItemId ||
          !itemsMap[rootItemId] ||
          !auditRootItemId
        ) {
          return null;
        }

        return calculateItemTreeStats({
          itemsMap,
          rootItemId,
          templateOrAuditRootItemId: auditRootItemId,
          auditScoreSystem,
        });
      }
    ),
  getAdditionalValidations: createSelector(
    [
      getPerformAuditItems,
      getPerformAuditRootItemId,
      getAudit,
      getPerformAuditSignature,
    ],
    (items, rootItemId, audit, { auditorSignature, auditeeSignature }) => {
      if (!items || !rootItemId || !audit) {
        return null;
      }

      let missingSignatures = false;
      let missingPhotos = false;
      let missingActions = false;

      const countableIds = getCountableItemsIds(
        items,
        items[rootItemId].childrenIds
      );

      countableIds.forEach((itemId) => {
        const { isPhotoRequired, isActionRequired, isSignatureRequired } =
          validateAnswer(items[itemId]);

        const { id, itemType, notApplicable } = items[itemId];

        if (notApplicable) {
          return;
        }

        if (
          itemType === ItemType.ConditionalItem ||
          itemType === ItemType.Item
        ) {
          const { section, subSection } = getAncestorsSections(
            items,
            id,
            rootItemId
          );

          if (section?.notApplicable || subSection?.notApplicable) {
            return;
          }

          if (isPhotoRequired) {
            missingPhotos = true;
          }

          if (isActionRequired) {
            missingActions = true;
          }

          if (isSignatureRequired) {
            missingSignatures = true;
          }
        }
      });

      return {
        missingSignatures,
        missingPhotos,
        missingActions,
        missingAuditorSignature:
          audit?.template?.isAuditorSignatureRequired && !auditorSignature,
        missingAuditeeSignature:
          audit?.template?.isAuditeeSignatureRequired && !auditeeSignature,
      };
    }
  ),
  getAuditTotals: createSelector(
    [getPerformAuditItems, getPerformAuditRootItemId],
    (items, rootItemId) => {
      return items && rootItemId
        ? getAuditReportTotals(items, rootItemId)
        : null;
    }
  ),
  getAuditScore: createSelector(
    [getPerformAuditItems, getPerformAuditRootItemId],
    (items, rootItemId) =>
      rootItemId && items?.[rootItemId] ? items[rootItemId].points?.score : null
  ),
  getNoteModal: (state: IRootState) => state.performAudit.noteModal,
  getFlagsModal: (state: IRootState) => state.performAudit.flagsModal,
  getActionModal: (state: IRootState) => state.performAudit.actionModal,
  getSignatureModal: (state: IRootState) => state.performAudit.signatureModal,
  getFiltersModalVisibility: (state: IRootState) =>
    state.performAudit.showFiltersModal,
  getFilters: getPerformAuditFilters,
  getSelectedFiltersQty: createSelector(
    getPerformAuditFilters,
    ({
      onlyFailedItems,
      onlyCriticalItems,
      onlyRepeats,
      onlyNotApplicable,
      onlyItemsWithNotes,
      onlyItemsWithPhotos,
      onlySignedItems,
      onlyActionsWithStatus,
      onlyItemsWithFlags,
      onlyUnansweredItems,
    }) => {
      let selectedFilters = 0;

      if (onlyFailedItems) {
        selectedFilters++;
      }

      if (onlyCriticalItems) {
        selectedFilters++;
      }

      if (onlyRepeats) {
        selectedFilters++;
      }

      if (onlyNotApplicable) {
        selectedFilters++;
      }

      if (onlyItemsWithNotes) {
        selectedFilters++;
      }

      if (onlyUnansweredItems) {
        selectedFilters++;
      }

      if (onlyItemsWithPhotos) {
        selectedFilters++;
      }

      if (onlySignedItems) {
        selectedFilters++;
      }

      if (onlyActionsWithStatus.length > 0) {
        selectedFilters++;
      }

      if (onlyItemsWithFlags.length > 0) {
        selectedFilters++;
      }

      return selectedFilters;
    }
  ),
  getAuditSignature: getPerformAuditSignature,
  isLoadingPhotosModalVisible: (state: IRootState) =>
    state.performAudit.showLoadingPhotosModal,
  getNACheckConfirmModal: (state: IRootState) =>
    state.performAudit.naCheckConfirmModal,
  getAuditErrorCode: (state: IRootState) => state.performAudit.auditErrorCode,
  getSectionMarkedAsNABy: (state: IRootState) =>
    state.performAudit.sectionMarkedAsNABy,
  itemSyncIsInProgress: (state: IRootState): boolean =>
    state.performAudit.syncInProgress ||
    state.performAudit.receivingUpdatedItems ||
    state.performAudit.uploadQueue.length > 0,
  getSyncQueue: (state: IRootState): string[] => state.performAudit.uploadQueue,
  isSyncInProgress: (state: IRootState): boolean =>
    state.performAudit.uploadInProgress,
  isUpdatedItemsFetchRequired: (state: IRootState): boolean =>
    state.performAudit.isUpdatedItemsFetchRequired,
  getSelectActionTemplateModalItemId: (state: IRootState) =>
    state.performAudit.selectActionTemplateModalItemId,
  getNotUploadedFiles: (state: IRootState): NotUploadedFile[] =>
    state.performAudit.notUploadedFiles,
  isSyncModalOpened: (state: IRootState) => state.performAudit.showSyncModal,
  getDeleteNotUploadedFileModalState: (
    state: IRootState
  ): DeleteNotUploadedFileModalState =>
    state.performAudit.deleteNotUploadedFileModal,
};
