import styled from 'styled-components';
import { animated } from 'react-spring';

import Button from '@components/shared/ant/Button';
import { colors } from '@repo/shared/config';
import { Input } from '@components/shared/ant/Input';

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'content';
`;

export const Content = styled(animated.div)`
  grid-area: content;
  text-align: center;
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  & > * {
    width: 100%;
  }
`;

export const LeaveFeedbackContainer = styled(FeedbackContainer)`
  padding: 50px 24px 20px;
`;

export const GoodFeedbackContainer = styled(FeedbackContainer)`
  padding: 100px 24px 20px;
  height: 470px;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 0;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: ${colors.gray8};
  margin-top: 3px;
  margin-bottom: 30px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-bottom: 4px;

  span {
    font-size: 14px;
  }
`;

export const BottomBtnsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  ${({ displayDontShowBtn }: { displayDontShowBtn: boolean }) =>
    displayDontShowBtn
      ? `
        justify-content: space-between;
            
        & > * {
          margin: 0!important;
        
          &:first-child {
            padding-left: 0;
            text-align: left;
          }
      
          &:last-child {
            padding-right: 0;
            text-align: right;
          }
        }
      `
      : `
        justify-content: center;
        
        & > * {
          margin: 0 auto;
        }
      `}
`;

export const CancelBtn = styled.button`
  display: block;
  padding: 10px 16px;
  margin: 0 auto;
  border: 0;
  background: none;
  font-size: 13px;
  color: ${colors.gray8};
`;

export const HowCanWeImprove = styled(animated.div)`
  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
`;

export const TextArea = styled(Input.TextArea)`
  margin: 10px 0 10px 0;
  background: ${colors.gray3};
  border-color: transparent;
  border-radius: 5px;
`;
