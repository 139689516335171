import React from 'react';
import { useSelector } from 'react-redux';

import { Overlay } from './styled';
import { useDocumentDimensions } from '@hooks';
import { accountSelectors } from '@store';

interface IProps {
  style: any;
}

const TourOverlay: React.FC<React.PropsWithChildren<IProps>> = ({ style }) => {
  const { step, spotlightBounds } = useSelector(
    accountSelectors.getSelfGuidedTour
  );
  const { height, width } = useDocumentDimensions(step);

  return (
    <Overlay
      style={{ ...style, height, width }}
      bounds={spotlightBounds}
      id="self-guided-tour-overlay"
    />
  );
};

export default TourOverlay;
