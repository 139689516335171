import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  height: 74px;
  margin: -15px -23px 0 -23px;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftArrowButton = styled.button`
  display: block;
  border: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: none;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;
