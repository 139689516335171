import React from 'react';
import { useIntl } from 'react-intl';

import { Container, Header, Title, SecureArea } from './styled';

interface IProps {
  secure?: boolean;
  children: React.ReactNode;
  title: string;
}

const FormSection: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  secure,
  children,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {secure && (
          <SecureArea>{formatMessage({ id: 'SecureArea' })}</SecureArea>
        )}
      </Header>
      {children}
    </Container>
  );
};

export default FormSection;
