import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';
import { X } from 'react-feather';

import { Container, Input, Label, RemoveBtn, ImageHolder } from './styled';
import { colors } from '@repo/shared/config';

interface IProps {
  id: string;
  hoverText?: string;
  defaultText: string;
  defaultBg?: string;
  imageUrl: string | null;
  onImageLoad?: () => void;
  onChange: (file: File | null) => Promise<any>;
  loading: boolean;
}

const UploadCircle: React.FC<React.PropsWithChildren<IProps>> = ({
  loading,
  id,
  hoverText,
  defaultBg = colors.blue2,
  defaultText,
  onImageLoad,
  onChange,
  imageUrl,
}) => {
  const { formatMessage } = useIntl();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const disabled = !!imageUrl;

  return (
    <Spin spinning={loading}>
      <Container defaultBg={imageUrl ? 'transparent' : defaultBg}>
        <Input
          id={`upload-circle-${id}`}
          ref={inputRef}
          type="file"
          onChange={async (e) => {
            await onChange(e.target?.files?.[0] || null);
          }}
          multiple={false}
          accept="image/*"
          disabled={disabled}
        />
        {imageUrl && (
          <>
            <ImageHolder
              src={imageUrl}
              onLoad={() => {
                if (onImageLoad) {
                  onImageLoad();
                }
              }}
            />
            {!loading && (
              <RemoveBtn
                type="button"
                onClick={async () => {
                  await onChange(null);

                  if (inputRef && inputRef.current) {
                    inputRef.current.value = '';
                  }
                }}
              >
                <X />
              </RemoveBtn>
            )}
          </>
        )}
        <Label
          hoverText={hoverText || formatMessage({ id: 'UploadIcon' })}
          htmlFor={`upload-circle-${id}`}
          defaultBg={defaultBg}
          disabled={disabled}
        >
          {!imageUrl ? defaultText : null}
        </Label>
      </Container>
    </Spin>
  );
};

export default UploadCircle;
