import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-top: 10px;
`;

export const CriticalLabel = styled.span`
  color: ${colors.orange};
  font-weight: 400;
  text-indent: 3px;
`;

export const FlagsContainer = styled.div`
  margin-bottom: 10px;
`;

export const PreviewsContainer = styled.div``;
