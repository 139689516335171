import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import { Helmet } from 'react-helmet-async';
import ConfigProvider from 'antd/es/config-provider';

import { useAppDispatch, useBroadcastChannel } from '@hooks';
import { useTranslations } from '@repo/shared/hooks';
import { AuthEvent } from '@repo/shared/enums';
import { accountActions, accountSelectors } from '@store';
import { colors } from '@repo/shared/config';
import IntlGlobalProvider from '@repo/shared/components/IntlGlobalProvider';

import AppRoutes from './account/AppRoutes/AppRoutes';
import Loader from './shared/Loader/Loader';
import ChatWidget from '@components/account/AppLayout/ChatWidget';

function App() {
  const pubnub = usePubNub();
  const dispatch = useAppDispatch();

  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const locale = useSelector(accountSelectors.getLocale);
  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);
  const appLoading = useSelector(accountSelectors.isFullScreenLoaderVisible);
  const [translationsLoading, translations] = useTranslations(locale);

  useBroadcastChannel<AuthEvent>('auth-channel', (event) => {
    if (event === AuthEvent.SignOut && currentUser) {
      dispatch(accountActions.signOut());
    }

    if (event === AuthEvent.SignIn && !currentUser) {
      dispatch(accountActions.loadApp());
    }
  });

  useEffect(() => {
    dispatch(accountActions.loadApp());
  }, []);

  useEffect(() => {
    pubnub.setUUID(currentUser?.id || 'not-authenticated');
  }, [pubnub, currentUser?.id]);

  if (translationsLoading) {
    return <Loader />;
  }

  return (
    <IntlProvider locale={locale} messages={translations}>
      <IntlGlobalProvider>
        {appLoading ? (
          <Loader />
        ) : (
          <>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: colors.blue2,
                },
              }}
            >
              <AppRoutes />
            </ConfigProvider>
            {appConfiguration.appName && (
              <Helmet>
                <title>{appConfiguration.appName}</title>
                {appConfiguration.appFaviconUrl && (
                  <link
                    id="favicon"
                    rel="icon"
                    href={appConfiguration.appFaviconUrl}
                  />
                )}
                <meta name="description" content={appConfiguration.appName} />
              </Helmet>
            )}
            <ChatWidget />
          </>
        )}
      </IntlGlobalProvider>
    </IntlProvider>
  );
}

export default App;
