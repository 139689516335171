import React from 'react';
import { Minus, Plus } from 'react-feather';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';

import Form from '@components/shared/ant/Form';
import { Input } from '@components/shared/ant/Input';
import { ActionBtn, OptionsRows, OptionRow } from './styled';
import { e2eTestElements } from '@config';

interface IProps {
  loading: boolean;
}

const DropdownAttributeOptionList: React.FC<
  React.PropsWithChildren<IProps>
> = ({ loading }) => {
  const { formatMessage } = useIntl();

  return (
    <Form.List name="options">
      {(fields, { add, remove }) => (
        <OptionsRows
          data-e2e={e2eTestElements.objects.attributes.dropdownTypeOptions}
        >
          {fields.map((field, i) => (
            <OptionRow key={field.key}>
              <Form.Item
                {...field}
                name={[field.name, 'name']}
                className="hide-text-error-message"
                style={{ marginBottom: 8 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  placeholder={formatMessage({ id: 'OptionName' })}
                />
              </Form.Item>
              <ActionBtn
                type="button"
                disabled={fields.length <= 1}
                onClick={() => {
                  remove(field.name);
                }}
              >
                <Minus />
              </ActionBtn>
              <ActionBtn
                type="button"
                onClick={() =>
                  add(
                    {
                      id: uuid(),
                      name: '',
                    },
                    i + 1
                  )
                }
              >
                <Plus />
              </ActionBtn>
            </OptionRow>
          ))}
        </OptionsRows>
      )}
    </Form.List>
  );
};

export default DropdownAttributeOptionList;
