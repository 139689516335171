import styled from 'styled-components';
import Typography from 'antd/es/typography';
import AntDivider from 'antd/es/divider';

import { colors, BREAKPOINTS } from '@repo/shared/config';

import Form from '../../shared/ant/Form';
import Button from '@components/shared/ant/Button';

const { Title: AntTitle, Text: AntText } = Typography;

export const AuthLogo = styled.div`
  & > div {
    width: 200px;
    height: 51px;
    margin: 0 auto 32px;

    @media (min-width: ${BREAKPOINTS.MD}px) {
      margin: 0 auto 50px;
    }
  }
`;

export const Highlights = styled.div`
  margin: 0 20px;
  color: ${colors.white};
`;

interface IContainerProps {
  maxWidth?: number;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > div {
    width: 100%;
    padding: 0 20px;
  }

  @media (min-width: 420px) {
    & > div {
      width: ${({ maxWidth }: IContainerProps) =>
        maxWidth ? `${maxWidth}px` : '370px'};
    }
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    flex-direction: row;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    & > div {
      width: ${({ maxWidth }: IContainerProps) =>
        maxWidth ? `${maxWidth}px` : '390px'};
    }
  }
`;

export const Features = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 17px;
  display: none;
  width: 370px;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    display: block;
  }
`;

export const Feature = styled.li`
  position: relative;
  padding-left: 25px;

  &:before {
    position: absolute;
    left: 0;
    top: 10px;
    display: inline-block;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${colors.blue1};
  }
`;

export const Headline = styled.h3`
  color: ${colors.white};
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
`;

export const Desc = styled.p`
  font-size: 12px;
  color: ${colors.gray5};
  padding-bottom: 15px;
`;

export const FormContainer = styled.div`
  position: relative;
  padding: 30px 25px
    ${({ hasFooter }: { hasFooter: boolean }) =>
      hasFooter ? '70px' : '35px'}!important;
  background: ${colors.white};
  border-radius: 10px;
  overflow: hidden;
`;

export const Title = styled(AntTitle)`
  margin-bottom: 16px !important;
`;

const Text = styled(AntText)`
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.gray8};
`;

export const Tagline = styled(Text)`
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 1.4;
`;

export const Disclaimer = styled(Text)`
  margin: 13px 0 0;

  a {
    color: ${colors.blue2};
  }
`;

export const Divider = styled(AntDivider)`
  font-size: 12px !important;
  margin: 20px 0 !important;
`;

export const FooterButton = styled.button`
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 0;
  border: 0;
  right: 0;
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  background: ${colors.gray3};
  color: ${colors.blue2};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background: ${colors.gray4};
    color: ${colors.blue2};
  }
`;

export const SignInPasswordFormItem = styled(Form.Item)`
  label {
    width: 100%;
  }
`;

export const PasswordLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;

  a {
    color: ${colors.blue2};
    font-size: 12px;
  }
`;

export const SignUpSubmitBtn = styled(Button)`
  width: 100%;
`;
