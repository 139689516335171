import styled from 'styled-components';
import Space from 'antd/es/space';

export const Buttons = styled(Space)`
  margin-top: 35px;
`;

export const SubHeader = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 25px 0;
`;

export const PermissionGroupHeader = styled.h4`
  font-size: 14px;
  line-height: 22px;
  margin: 30px 0;
`;
