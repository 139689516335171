import {
  ScheduleFormValues,
  ScheduleRepeatFormValues,
} from '@application/Schedules/models/ScheduleFormValues';
import { ScheduleRepeat } from '@domain/Schedules/models/Schedule';
import { ScheduleDto } from '@infrastructure/Schedules/models/ScheduleDto';
import { date } from '@utils';
import { config } from '@repo/shared/config';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';

function transformRepeatFormValuesToRepeat(
  repeatFormValues: ScheduleRepeatFormValues
): ScheduleRepeat {
  const expiration = {
    auditsExpirationType: repeatFormValues.auditsExpirationType,
    auditsExpireAfterDays:
      repeatFormValues.auditsExpireAfterDays === ''
        ? null
        : parseInt(repeatFormValues.auditsExpireAfterDays),
    actionsExpirationType: repeatFormValues.actionsExpirationType,
    actionsExpireAfterDays:
      repeatFormValues.actionsExpireAfterDays === ''
        ? null
        : parseInt(repeatFormValues.actionsExpireAfterDays),
  };

  switch (repeatFormValues.repeatPattern) {
    case AuditRepeatPattern.OneTime:
      return {
        repeatPattern: AuditRepeatPattern.OneTime,
        startDate: date(repeatFormValues.range[0]).format(config.apiDateFormat),
        endDate: date(repeatFormValues.range[1]).format(config.apiDateFormat),
        ...expiration,
      };
    case AuditRepeatPattern.Daily:
      return {
        repeatPattern: AuditRepeatPattern.Daily,
        repeatEvery: parseInt(repeatFormValues.repeatEvery),
        ...expiration,
      };
    case AuditRepeatPattern.Weekly:
      return {
        repeatPattern: AuditRepeatPattern.Weekly,
        daysOfWeek: repeatFormValues.daysOfWeek,
        ...expiration,
      };
    case AuditRepeatPattern.MultipleWeeks:
      return {
        repeatPattern: AuditRepeatPattern.MultipleWeeks,
        repeatEvery: parseInt(repeatFormValues.repeatEvery),
        startDay: repeatFormValues.startDay,
        duration: parseInt(repeatFormValues.duration),
        ...expiration,
      };
    case AuditRepeatPattern.Monthly:
      return {
        repeatPattern: AuditRepeatPattern.Monthly,
        repeatEvery: parseInt(repeatFormValues.repeatEvery),
        startDay: parseInt(repeatFormValues.startDay),
        duration: parseInt(repeatFormValues.duration),
        ...expiration,
      };
  }
}

export function transformFormValuesToScheduleDto({
  repeat,
  auditObjectAndGroupIds,
  templateId,
  ...values
}: ScheduleFormValues): ScheduleDto {
  return {
    ...values,
    templateId: templateId || '',
    auditObjectIds: auditObjectAndGroupIds.entityIds,
    auditObjectGroupIds: auditObjectAndGroupIds.groupIds,
    repeat: transformRepeatFormValuesToRepeat(repeat),
  };
}
