import React from 'react';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';
import { FormInstance } from 'antd/es/form';

import { MonthlySettings } from './styled';
import { AuditRepeatPattern } from '@domain/Schedules/enums/AuditRepeatPattern';
import { e2eTestElements } from '@config';

import Form from '@components/shared/ant/Form';
import RepeatEvery from '@presentation/Schedules/AddEditScheduleModal/RepeatPatterns/RepeatEvery/RepeatEvery';
import Select from '@components/shared/ant/Select/Select';
import CircleTooltip from '@components/shared/CircleTooltip/CircleTooltip';

const { Option } = AntSelect;

interface IProps {
  disabled?: boolean;
  form: FormInstance;
}

function getSelectOptions(qty: number) {
  return Array(qty)
    .fill('')
    .map((_, num) => (
      <Option key={num} value={num + 1}>
        {num + 1}
      </Option>
    ));
}

const Monthly: React.FC<React.PropsWithChildren<IProps>> = ({
  disabled,
  form,
}) => {
  const { formatMessage } = useIntl();

  const span = 31;

  return (
    <>
      <Form.Item name={['repeat', 'repeatEvery']}>
        <RepeatEvery
          disabled={disabled}
          repeatPattern={AuditRepeatPattern.Monthly}
          onChange={(repeatEvery) => {
            if (
              parseInt(form.getFieldValue(['repeat', 'duration'])) >
              repeatEvery * span
            ) {
              form.setFieldValue(['repeat', 'duration'], '1');
            }
          }}
        />
      </Form.Item>
      <MonthlySettings>
        <Form.Item
          name={['repeat', 'startDay']}
          label={
            <>
              {formatMessage({ id: 'FirstAuditDay' })}
              <CircleTooltip contentMaxWidth={250}>
                <p>{formatMessage({ id: 'FirstAuditDayMonthlyPopover' })}</p>
              </CircleTooltip>
            </>
          }
        >
          <Select
            disabled={disabled}
            showSearch
            e2eDataAttr={
              e2eTestElements.schedules.addEditSchedule.firstAuditDaySelect
            }
          >
            {getSelectOptions(span)}
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const repeatEvery = parseInt(
              getFieldValue(['repeat', 'repeatEvery'])
            );

            return (
              <Form.Item
                label={
                  <>
                    {formatMessage({ id: 'AuditDurationDays' })}
                    <CircleTooltip contentMaxWidth={250}>
                      <p>{formatMessage({ id: 'AuditDurationDaysPopover' })}</p>
                    </CircleTooltip>
                  </>
                }
                name={['repeat', 'duration']}
              >
                <Select
                  disabled={disabled}
                  showSearch
                  e2eDataAttr={
                    e2eTestElements.schedules.addEditSchedule
                      .auditDurationDaysSelect
                  }
                >
                  {getSelectOptions(span * repeatEvery)}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </MonthlySettings>
    </>
  );
};

export default Monthly;
