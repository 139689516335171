import React from 'react';
import { useIntl } from 'react-intl';

import { e2eTestElements } from '@config';

import AuditTemplatesSelect from '../../shared/EntitySelects/AuditTemplatesSelect';
import LabeledFilter from './LabeledFilter/LabeledFilter';

interface IProps<T> {
  selectedValue: string | null;
  onChange: (update: T) => void;
  multi?: boolean;
}

function AuditTemplate<T extends string | string[] | null = string | null>({
  selectedValue,
  onChange,
  multi,
}: React.PropsWithChildren<IProps<T>>) {
  const { formatMessage } = useIntl();

  return (
    <LabeledFilter
      label={formatMessage({ id: multi ? 'AuditTemplates' : 'AuditTemplate' })}
    >
      <AuditTemplatesSelect
        value={selectedValue}
        width="100%"
        data-e2e={e2eTestElements.dashboard.filters.templateSelect}
        placeholder={formatMessage({
          id: 'SelectTemplate',
        })}
        onChange={onChange}
        mode={multi ? 'multiple' : undefined}
        maxTagCount="responsive"
      />
    </LabeledFilter>
  );
}

export default AuditTemplate;
