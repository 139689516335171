import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Content = styled.div`
  display: flex;
`;

export const LeftCol = styled.div`
  width: 320px;
  padding-top: 10px;
`;

export const RightCol = styled.div`
  flex-grow: 1;
  padding: 0 0 0 40px;
`;

export const SectionLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;

  ${({ required }: { required?: boolean }) =>
    required &&
    `
      &:before {
        position: relative;
        top: -2px;
        font-size: 14px;
        content: '*';
        color: ${colors.red};
        margin-right: 3px;
      }    
  `}
`;

export const DefaultDueDateRow = styled.div`
  display: flex;
  margin: 0 -5px;

  & > * {
    width: 50%;
    margin: 0 5px;
  }
`;

export const MandatoryLabel = styled.div`
  &:before {
    display: inline-block;
    margin-inline-end: 4px;
    color: ${colors.red};
    font-size: 12px;
    line-height: 1;
    content: '*';
  }
`;
