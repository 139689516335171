import styled from 'styled-components';

import lockIcon from './lock.svg';

export const Container = styled.div`
  margin-bottom: 18px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h3`
  margin: 17px 0;
`;

export const SecureArea = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  &:before {
    display: block;
    content: '';
    margin-right: 6px;
    width: 12px;
    height: 12px;
    background: url(${lockIcon}) no-repeat center;
  }
`;
