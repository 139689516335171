import React from 'react';
import { SelectProps } from 'antd/es/select';
import { useSelector } from 'react-redux';

import { generalSelectors } from '@store';

import EntitySelect from './EntitySelect';
interface IProps extends SelectProps<any> {
  width?: string;
  extraOptions?: { id: string | number; name: string }[];
  e2eDataAttr?: string;
}

const JobTitlesSelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef((props, _) => {
    const { data, loading } = useSelector(generalSelectors.getConciseJobTitles);

    return <EntitySelect {...props} options={data} loading={loading} />;
  });

export default JobTitlesSelect;
