import React, { ReactNode } from 'react';
import { ButtonType } from 'antd/es/button';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Container,
  CreateButton,
  Footer,
  HowItWorksButton,
  DemoButton,
} from './styled';
import { NewTab } from '@icons';
import { config } from '@repo/shared/config';
import { accountSelectors } from '@store';

import { NoContent } from '@repo/shared/components/NoContent/NoContent';

interface IProps {
  text: number | string | ReactNode;
  button?: {
    text: string;
    type?: ButtonType;
    onClick: () => void;
  } | null;
  howItWorksUrl?: string;
  showDemoBtn?: boolean;
}

const EmptyTable: React.FC<React.PropsWithChildren<IProps>> = ({
  text,
  button,
  howItWorksUrl,
  showDemoBtn = true,
}) => {
  const { formatMessage } = useIntl();

  const appConfiguration = useSelector(accountSelectors.getAppConfiguration);

  return (
    <Container>
      <NoContent text={text} />
      {button && (
        <CreateButton type={button.type} onClick={() => button.onClick()}>
          {button.text}
        </CreateButton>
      )}
      <Footer>
        {!!howItWorksUrl && (
          <HowItWorksButton href={howItWorksUrl} target="_blank">
            {formatMessage({ id: 'HowItWorks' })}
            <NewTab />
          </HowItWorksButton>
        )}
        {showDemoBtn && (
          <DemoButton
            href={appConfiguration.demoUrl || config.urls.interactiveDemo}
            target="_blank"
          >
            {formatMessage({ id: 'BookDemo' })}
            <NewTab />
          </DemoButton>
        )}
      </Footer>
    </Container>
  );
};

export default EmptyTable;
