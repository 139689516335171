import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@hooks';
import { rolesActions, rolesSelectors } from '@store';

import SlideModal from '@components/shared/SideModal/SideModal';
import AddEditRoleModalForm from './AddEditRoleModalForm';

const AddEditRoleModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { show, role } = useSelector(rolesSelectors.getAddEditRoleModalState);

  const closeModal = useCallback(() => {
    dispatch(rolesActions.hideAddEditRoleModal());
  }, [dispatch]);

  return (
    <SlideModal
      opened={show}
      onModalClose={() => {
        closeModal();
      }}
      title={
        !role
          ? formatMessage({ id: 'AddNewAccessRole' })
          : formatMessage({ id: 'EditAccessRole' })
      }
    >
      <AddEditRoleModalForm closeModal={closeModal} opened={show} data={role} />
    </SlideModal>
  );
};

export default AddEditRoleModal;
