import styled from 'styled-components';

import failedInRowIconIcon from './failed-row-count.svg';

interface IProps {
  color: string;
  fontSizePx: number;
}

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 3px;
  width: 19px;
  height: 19px;
  background: url(${failedInRowIconIcon}) no-repeat center center;
  color: ${({ color }: IProps) => color};
  font-size: ${({ fontSizePx }: IProps) => `${fontSizePx}px`};
  line-height: ${({ fontSizePx }: IProps) => `${fontSizePx}px`};
  position: relative;
  background-size: 19px 19px;
  top: -1px;
`;
