import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  GoodFeedbackContainer,
  SubmitButton,
  CancelBtn,
  Title,
} from './styled';
import { config } from '@repo/shared/config';
import { generalActions, generalSelectors } from '@store';
import { useAppDispatch } from '@hooks';
import { FeedbackEvent } from '@repo/shared/enums';

import Stars from '@components/shared/FeedbackModal/Stars/Stars';

interface IProps {
  closeModal: () => void;
}

const Capterra: React.FC<React.PropsWithChildren<IProps>> = ({
  closeModal,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const rating = useSelector(generalSelectors.feedbackModal.getRating);

  return (
    <GoodFeedbackContainer>
      <Stars rating={rating} />
      <Title style={{ marginBottom: '30px' }}>
        {formatMessage({
          id: 'CouldYouPleaseReviewMonitorQAOnCapterra',
        })}
        <br />
        {formatMessage({
          id: 'ThankYou',
        })}
        !
      </Title>
      <div>
        <SubmitButton
          type="primary"
          onClick={() => {
            window.open(config.urls.capterra, '_blank')?.focus();
            dispatch(generalActions.feedbackModal.setPage(null));
            dispatch(
              generalActions.feedbackModal.saveFeedback({
                event: FeedbackEvent.Capterra,
              })
            );
          }}
        >
          {formatMessage({ id: 'WriteReview' })}
        </SubmitButton>
        <CancelBtn type="button" onClick={closeModal}>
          {formatMessage({ id: 'NoThanks' })}
        </CancelBtn>
      </div>
    </GoodFeedbackContainer>
  );
};

export default Capterra;
