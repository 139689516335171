import React from 'react';

import { ITagConcise } from '@repo/shared/types';
import { Container } from './styled';

import Tag from '@components/shared/ant/Tag/Tag';

interface IProps {
  tags: ITagConcise[];
  containerStyle?: React.CSSProperties;
}

const TagList: React.FC<React.PropsWithChildren<IProps>> = ({
  tags,
  containerStyle,
}) => {
  if (tags.length === 0) {
    return null;
  }

  return (
    <Container style={containerStyle}>
      {tags.map(({ id, name, color }) => (
        <Tag key={id} tagcolor={color} closable={false}>
          {name}
        </Tag>
      ))}
    </Container>
  );
};

export default TagList;
