import { AssignmentType } from '@repo/shared/enums';

export const convertQueryParamToAssignment = (param?: string) => {
  let assign = AssignmentType.all;

  if (param !== undefined) {
    if (param === '0') {
      assign = AssignmentType.all;
    }

    if (param === '1') {
      assign = AssignmentType.unassigned;
    }

    if (param === '2') {
      assign = AssignmentType.me;
    }
  }

  return assign;
};
