import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';

import { config, colors } from '@repo/shared/config';
import { Detail, Status } from '../AutoSubscriptionInfo/styled';
import { CustomPlanDesc, LimitsHeader } from './styled';
import { useUserRolesLimitsInfo } from '@utils';
import { generalActions } from '@store';
import { useAppDispatch } from '@hooks';

import TwoColsWithImage from '../TwoColsWithImage/TwoColsWithImage';

const CustomSubscriptionInfo: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(generalActions.getConciseRoles());
  }, []);

  const {
    loading,
    otherRoleNames,
    observerNames,
    otherRolesLimit,
    observersLimit,
    otherRolesCount,
    observersCount,
  } = useUserRolesLimitsInfo();

  return (
    <TwoColsWithImage
      title={
        <>
          {formatMessage({ id: 'CustomPlanIs' })}{' '}
          <Status>
            {formatMessage({
              id: 'active',
            })}
          </Status>
        </>
      }
      imageColor={colors.green}
      leftColContent={
        <>
          <CustomPlanDesc>
            {formatMessage(
              {
                id: 'YouUseCustomPlanWithTheManualMethodOfInvoicingAndPayment',
              },
              {
                email: (
                  <a href={`mailto:${config.urls.supportEmail}`}>
                    {config.urls.supportEmail}
                  </a>
                ),
              }
            )}
          </CustomPlanDesc>
          <LimitsHeader>{formatMessage({ id: 'UserLimits' })}</LimitsHeader>
          {loading ? (
            <Skeleton active paragraph={false} />
          ) : (
            <>
              <Detail>
                <strong>{otherRoleNames}</strong>
                <span>
                  {otherRolesCount}/{otherRolesLimit}
                </span>
              </Detail>
              <Detail>
                <strong>{observerNames}</strong>
                <span>
                  {observersCount}/{observersLimit}
                </span>
              </Detail>
            </>
          )}
        </>
      }
    />
  );
};

export default CustomSubscriptionInfo;
