import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div`
  position: relative;
`;

export const FormContainer = styled(animated.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
`;
