import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { useAppDispatch, usePermission } from '@hooks';
import { accountSelectors, auditObjectsActions } from '@store';
import { Permission } from '@repo/shared/enums';

import AuditObjectContentWrapper, {
  AuditObjectPage,
} from '@components/auditObjects/single/AuditObjectContentWrapper/AuditObjectContentWrapper';
import Button from '@components/shared/ant/Button';
import AttributesAssignmentsTable from '@components/auditObjects/attributes/single/AttributesAssignments/AttributesAssignmentsTable/AttributesAssignmentsTable';
import AddAttributeAssignmentModal from '@components/auditObjects/attributes/single/AttributesAssignments/AddEditAttributeAssignmentModal/AddAttributeAssignmentModal';
import ConfirmDeleteAssignmentModal from '@components/auditObjects/attributes/single/AttributesAssignments/ConfirmDeleteAssignmentModal';

interface IProps {}

const AuditObjectAttributes: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );

  useEffect(() => {
    dispatch(
      auditObjectsActions.attributesAssignments.getData({ auditObjectId: id })
    );

    return () => {
      dispatch(auditObjectsActions.attributesAssignments.toggleAddModal(false));
    };
  }, [id]);

  return (
    <AuditObjectContentWrapper
      page={AuditObjectPage.Attributes}
      extra={
        !isLocationsReadonly && canManageAuditObjects ? (
          <Button
            type="primary"
            onClick={() => {
              dispatch(
                auditObjectsActions.attributesAssignments.toggleAddModal(true)
              );
            }}
          >
            {formatMessage({
              id: 'AssignAttribute',
            })}
          </Button>
        ) : undefined
      }
    >
      <AttributesAssignmentsTable auditObjectId={id} />
      <AddAttributeAssignmentModal auditObjectId={id} />
      <ConfirmDeleteAssignmentModal />
    </AuditObjectContentWrapper>
  );
};

export default AuditObjectAttributes;
