import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import {
  usersActions,
  usersSelectors,
  generalSelectors,
  generalActions,
} from '@store';
import { notification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Modal from '../../../../shared/ant/Modal/Modal';

const ConfirmDeleteGroupAssignmentModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const selectedTableRows = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const deleteCandidatesIds = useSelector(
    usersSelectors.groupAssignments.getDeleteCandidatesIds
  );
  const assignmentsMap = useSelector(usersSelectors.groupAssignments.getMap);
  const groupsMap = useSelector(generalSelectors.getConciseUserGroupsMap);
  const usersMap = useSelector(generalSelectors.getConciseUsersMap);

  const assignment =
    deleteCandidatesIds.length === 1
      ? assignmentsMap[deleteCandidatesIds[0]]
      : null;

  const closeModal = useCallback(
    () => dispatch(usersActions.groupAssignments.toggleConfirmDeleteModal([])),
    []
  );

  return (
    <Modal
      title={formatMessage({ id: 'RemoveFromGroup' })}
      open={deleteCandidatesIds.length > 0}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          usersActions.groupAssignments.deleteAssignments(deleteCandidatesIds)
        );

        if (
          usersActions.groupAssignments.deleteAssignments.fulfilled.match(
            resultAction
          )
        ) {
          dispatch(usersActions.groupAssignments.getData());

          if (selectedTableRows.length > 0) {
            dispatch(generalActions.selectTableRows([]));
          }
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeletingAssignments' }),
          });
        }

        closeModal();
        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
    >
      {assignment
        ? formatMessage(
            { id: 'YouAreAboutToRemoveGroupAssignmentFromSystem' },
            {
              name: usersMap[assignment.userId]?.name,
              groupName: groupsMap[assignment.groupId]?.name,
            }
          )
        : formatMessage(
            { id: 'YouAreAboutToRemoveGroupAssignmentsFromSystem' },
            { count: deleteCandidatesIds.length }
          )}
    </Modal>
  );
};

export default ConfirmDeleteGroupAssignmentModal;
