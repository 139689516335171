import React from 'react';
import { useIntl } from 'react-intl';

import { actionsActions } from '@application/Actions/store/actionsActions';
import { notification } from '@utils';
import { useAppDispatch } from '@hooks';

import ConfirmExpireModal from '@components/shared/ConfirmExpireModal';

interface IProps {}

const ExpireActionsModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  return (
    <ConfirmExpireModal
      onExpireButtonClick={async (ids) => {
        const resultAction = await dispatch(actionsActions.expireActions(ids));

        if (actionsActions.expireActions.fulfilled.match(resultAction)) {
          dispatch(actionsActions.getActions());
          return true;
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileExpiringAction' }),
            description: resultAction.payload,
          });

          return false;
        }
      }}
      descLangId="AreYouSureYouWantToExpireAction"
    />
  );
};

export default ExpireActionsModal;
