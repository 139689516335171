import { useSelector } from 'react-redux';

import { RoleType } from '@repo/shared/enums';
import { accountSelectors, rolesSelectors } from '@store';
import { IRole } from '@repo/shared/types';

export const useUserRolesLimitsInfo = () => {
  const { roles, loading } = useSelector(rolesSelectors.getRoles);
  const company = useSelector(accountSelectors.getCompany);

  const otherRoles: IRole[] = [];
  const observerRoles: IRole[] = [];
  let otherRoleNames = '';
  let observerNames = '';
  let observersCount = 0;
  let otherRolesCount = 0;

  if (!loading && Array.isArray(roles)) {
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      const part = `${role.name}, `;

      if (role.roleType === RoleType.Observer) {
        observerRoles.push(role);
        observerNames += part;
        observersCount += role.usersCount;
      } else {
        otherRoles.push(role);
        otherRoleNames += part;
        otherRolesCount += role.usersCount;
      }

      if (i === roles.length - 1) {
        observerNames = observerNames.slice(0, observerNames.length - 2);
        otherRoleNames = otherRoleNames.slice(0, otherRoleNames.length - 2);
      }
    }
  }

  return {
    loading,
    otherRoles,
    observerRoles,
    observersCount,
    otherRolesCount,
    otherRoleNames,
    observerNames,
    otherRolesLimit: company?.usersLimit,
    observersLimit: company?.observersLimit,
  };
};
