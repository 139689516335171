import React from 'react';
import { X } from 'react-feather';
import AntModal, { ModalProps } from 'antd/es/modal';

import { Footer } from './styled';

import Button from '../Button';

interface IProps extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  okButtonBackground?: string;
  okButtonDisabled?: boolean;
  footerExtra?: React.ReactNode;
  e2eDataConfirmAttr?: string;
  e2eDataCancelAttr?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

const Modal: React.FC<React.PropsWithChildren<IProps>> = ({
  footerExtra,
  e2eDataConfirmAttr,
  e2eDataCancelAttr,
  okButtonDisabled,
  cancelText = 'Cancel',
  width = 340,
  ...props
}) => {
  return (
    <AntModal
      {...props}
      cancelText={cancelText}
      width={width}
      closeIcon={<X data-e2e={e2eDataCancelAttr} />}
      style={{
        transform: 'translateZ(0)', // Safari 16.4 temp fix
        ...(props.style || {}),
      }}
      styles={{
        body: {
          paddingTop: '10px',
        },
      }}
      footer={
        <Footer>
          <div>
            <Button
              type="primary"
              disabled={okButtonDisabled}
              style={
                props.okButtonBackground
                  ? {
                      background: props.okButtonBackground,
                    }
                  : undefined
              }
              onClick={props.onOk}
              loading={props.confirmLoading}
              data-e2e={e2eDataConfirmAttr}
            >
              {props.okText}
            </Button>
            {!!props.onCancel && (
              <Button
                type="default"
                onClick={props.onCancel}
                data-e2e={e2eDataCancelAttr}
                style={{ marginLeft: '10px' }}
              >
                {cancelText}
              </Button>
            )}
          </div>
          {footerExtra && <div>{footerExtra}</div>}
        </Footer>
      }
    >
      {props.children}
    </AntModal>
  );
};

export default Modal;
