import styled from 'styled-components';
import AntSkeleton from 'antd/es/skeleton';
import { ArrowUpRight } from 'react-feather';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
  background: ${colors.gray3};
  border-radius: 5px;
  padding: 12px 9px;
  height: 100%;
  transition: all 0.3s ease-in-out;
  box-shadow: none;

  @media (min-width: 1424px) {
    padding: 12px 18px;
  }

  & > * {
    &:first-child {
      flex-grow: 1;
    }
  }

  ${({ active }: { active: boolean }) =>
    active
      ? `
    cursor: pointer;
  
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0px 10px 20px -14px rgba(0, 0, 0, 0.4);
    }
  `
      : ''}
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 3px;
`;

export const Extra = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
`;

export const Desc = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
`;

export const IconWrapper = styled.div`
  padding-right: 20px;
  height: 31px;

  svg {
    width: 31px;
    height: 31px;
  }
`;

export const Skeleton = styled(AntSkeleton)`
  .ant-skeleton-title {
    margin: 6px 0;
    width: 80px;
    height: 14px;
    background: ${colors.gray6};
  }
`;

export const DescLastWordWrapper = styled.span`
  white-space: nowrap;
`;

export const LinkIcon = styled(ArrowUpRight)`
  display: inline-block;
  width: 13px;
  height: 13px;
  position: relative;
  top: 2.5px;
  left: 2px;
  color: ${colors.gray7};
`;
