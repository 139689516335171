import React from 'react';
import { MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { HeaderActionButton } from './styled';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { useAppDispatch, usePermission } from '@hooks';
import { ActionStatus, Modal, Permission } from '@repo/shared/enums';
import { generalActions } from '@store';

import DropDownMenu from '@components/shared/DropDownMenu/DropDownMenu';
import { e2eTestElements } from '@config';

const ActionDetailsHeaderMenu: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const action = useSelector(actionsSelectors.getActionDetailsAction);

  const [canApproveActions, canManageActions] = usePermission([
    Permission.CanApproveCorrectiveActions,
    Permission.CanManageCorrectiveActions,
  ]);

  if (!action || !canApproveActions || !canManageActions) {
    return null;
  }

  const menuItems = [];

  if (
    action.status !== ActionStatus.Approved &&
    action.status !== ActionStatus.Expired
  ) {
    menuItems.push({
      name: formatMessage({ id: 'Expire' }),
      e2eDataAttr: e2eTestElements.actions.detailsModal.expireBtn,
      onClick: () => {
        dispatch(
          generalActions.showModal({
            name: Modal.ConfirmExpire,
            data: [action.id],
          })
        );
      },
    });
  }

  menuItems.push({
    name: formatMessage({
      id: 'Delete',
    }),
    e2eDataAttr: e2eTestElements.actions.detailsModal.deleteBtn,
    onClick: () => {
      dispatch(
        generalActions.showModal({
          name: Modal.ConfirmDelete,
          data: { id: action.id },
        })
      );
    },
  });

  return (
    <DropDownMenu menuItems={menuItems}>
      <HeaderActionButton
        type="button"
        data-e2e={e2eTestElements.actions.detailsModal.rightTopDropdown}
      >
        <MoreHorizontal />
      </HeaderActionButton>
    </DropDownMenu>
  );
};

export default ActionDetailsHeaderMenu;
