import styled from 'styled-components';
import { colors } from '@repo/shared/config';

export const Container = styled.div`
  padding: 0 25px 15px;
`;

export const IssueName = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 32px;
  min-height: 40px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 3px;
`;

export const IssueType = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  min-height: 24px;
  line-height: 22px;
`;

export const DefinitionsRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;

  & > * {
    padding: 0 16px;
    border-right: 1px solid ${colors.gray5};
    margin-bottom: 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
`;

export const DefinitionValueHolder = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;
