import React from 'react';
import { useIntl } from 'react-intl';

import { Container } from './styled';
import { IssueDetailsAudit } from '@domain/Issues/models/Issue';
import { AuditStatus } from '@repo/shared/enums';
import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

interface IProps {
  audit: IssueDetailsAudit;
}

const langIds = {
  [AuditStatus.Pending]: 'Pending',
  [AuditStatus.InProgress]: 'InProgress',
  [AuditStatus.Reopened]: 'Reopened',
};

const LinkedAuditBadge: React.FC<React.PropsWithChildren<IProps>> = ({
  audit,
}) => {
  const { formatMessage } = useIntl();

  switch (audit.status) {
    case AuditStatus.Completed:
      return (
        <Container
          color={colors.white}
          bgColor={audit.scoreColor || colors.blue2}
        >
          {audit.scoreLabel}
        </Container>
      );
    case AuditStatus.Expired:
      return (
        <Container color={colors.orange} bgColor={hexToRGB(colors.orange, 0.2)}>
          {formatMessage({ id: 'Expired' })}
        </Container>
      );
    case AuditStatus.Pending:
    case AuditStatus.InProgress:
    case AuditStatus.Reopened:
      return (
        <Container color={colors.blue2} bgColor={hexToRGB(colors.blue2, 0.2)}>
          {formatMessage({ id: langIds[audit.status] })}
        </Container>
      );
  }
};

export default LinkedAuditBadge;
