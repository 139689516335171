import { IConcise, ZonedDateTime } from '@repo/shared/types';
import { Comment } from '@domain/common/Comment';
import { ActionStatus, Priority } from '@repo/shared/enums';
import { AuditStatus } from '@domain/Audits/enums/AuditStatus';

export enum IssueEventType {
  IssueOpened,
  IssueClosed,
  Comment,
  AuditEvent,
  ActionEvent,
  IssueChanged,
}

export enum IssueCloseReason {
  Fixed,
  NotAnIssue,
  ImpossibleToFix,
  CantReproduce,
  Duplicate,
  Other,
}

interface IssueEventBase {
  id: string;
  createdBy: IConcise | null;
  createDate: ZonedDateTime;
}

export interface IssueOpenedEvent extends IssueEventBase {
  type: IssueEventType.IssueOpened;
  data: {
    isReopen?: boolean;
    comment?: string;
  };
}

export interface IssueClosedEvent extends IssueEventBase {
  type: IssueEventType.IssueClosed;
  data: {
    reason: IssueCloseReason;
    comment?: string;
  };
}

export interface IssueEventComment extends IssueEventBase {
  type: IssueEventType.Comment;
  data: Comment;
}

export enum IssueEventActionType {
  Created,
  Deleted,
  Expired,
  MarkedAsDone,
  Rejected,
  Approved,
}

export interface IssueEventAction extends IssueEventBase {
  type: IssueEventType.ActionEvent;
  data: {
    action: IConcise & { status: ActionStatus };
    event: IssueEventActionType;
  };
}

export enum IssueEventAuditType {
  Created,
  Deleted,
  Expired,
  Completed,
}

export interface IssueEventAudit extends IssueEventBase {
  type: IssueEventType.AuditEvent;
  data: {
    audit: {
      id: string;
      scheduledAuditEventId: string;
      name: string;
      status: AuditStatus;
    };
    event: IssueEventAuditType;
  };
}

export enum IssueChangeEventField {
  DueDate,
  Priority,
  Assignees,
}

interface IssueChangeEventDueDate {
  fieldType: IssueChangeEventField.DueDate;
  value: {
    dueDate: ZonedDateTime;
  };
}

interface IssueChangeEventPriority {
  fieldType: IssueChangeEventField.Priority;
  value: {
    priority: Priority;
  };
}

interface IssueChangeEventAssignees {
  fieldType: IssueChangeEventField.Assignees;
  value: {
    assignedUsers: IConcise[];
  };
}

export interface IssueChangeEvent extends IssueEventBase {
  type: IssueEventType.IssueChanged;
  data:
    | IssueChangeEventDueDate
    | IssueChangeEventPriority
    | IssueChangeEventAssignees;
}

export type IssueEvent =
  | IssueEventComment
  | IssueEventAction
  | IssueEventAudit
  | IssueClosedEvent
  | IssueOpenedEvent
  | IssueChangeEvent;
