import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CloseModalButton, Title } from './styled';
import { ArrowLeft } from '@icons';
import { generalSelectors } from '@store';
import { routes } from '@config';
import { usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';

import PageHeader from '@components/shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

export enum AuditObjectGroupPage {
  Details,
  Assignments,
  QRCodes,
}

interface IProps {
  page: AuditObjectGroupPage;
  extra?: React.ReactNode;
}

const AuditObjectGroupContentWrapper: React.FC<
  React.PropsWithChildren<IProps>
> = ({ page, children, extra }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const auditObjectGroupsMap = useSelector(
    generalSelectors.getConciseAuditObjectGroupsMap
  );
  const { loading } = useSelector(generalSelectors.getConciseAuditObjectGroups);
  const canManageIssueTypes = usePermission(Permission.CanManageIssueTypes);

  useEffect(() => {
    if (!id || (!loading && !auditObjectGroupsMap[id])) {
      navigate(routes.auditObjects.groupsList);
      return;
    }
  }, [id, loading, auditObjectGroupsMap]);

  return (
    <>
      <PageHeader
        title={
          <Space align="center">
            <CloseModalButton
              type="button"
              onClick={() => {
                navigate(routes.auditObjects.groupsList);
              }}
            >
              <ArrowLeft />
            </CloseModalButton>

            {
              <Title>
                {loading ? (
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ borderRadius: 5 }}
                  />
                ) : id ? (
                  auditObjectGroupsMap[id]?.name
                ) : (
                  '-'
                )}
              </Title>
            }
          </Space>
        }
        extra={extra}
        footer={
          <PageTabbedSwitcher<AuditObjectGroupPage>
            pages={[
              {
                onClick() {
                  if (id) {
                    navigate(
                      routes.auditObjects.groupDetails.replace(':id', id)
                    );
                  }
                },
                name: formatMessage({ id: 'Details' }),
                id: AuditObjectGroupPage.Details,
              },
              {
                onClick() {
                  if (id) {
                    navigate(
                      routes.auditObjects.groupAssignments.replace(':id', id)
                    );
                  }
                },
                name: formatMessage({ id: 'Assignments' }),
                id: AuditObjectGroupPage.Assignments,
              },
              ...(canManageIssueTypes
                ? [
                    {
                      onClick() {
                        if (id) {
                          navigate(
                            routes.auditObjects.groupQRCodes.replace(':id', id)
                          );
                        }
                      },
                      name: formatMessage({ id: 'QrCodes' }),
                      id: AuditObjectGroupPage.QRCodes,
                    },
                  ]
                : []),
            ]}
            activePage={page}
          />
        }
      />
      {children}
    </>
  );
};

export default AuditObjectGroupContentWrapper;
