export enum TrendsType {
  Audit,
  Item,
  Section,
}

export enum ItemFlag {
  Warning,
  NeedsTraining,
  Urgent,
  NeedsAssistance,
  FollowUp,
  CorrectedOnSite,
  GreatJob,
}

export enum AuditDueDateStatus {
  All,
  DueSoon,
  PastDue,
  OnTime,
  Upcoming,
}

export enum AuditStartDateStatus {
  All,
  Upcoming,
  Ready,
}

export enum PendingAuditsStatusSelectOption {
  ReadyToStart,
  Upcoming,
  InProgress,
}

export enum AssignmentType {
  all,
  unassigned,
  me,
}

export enum AuditStatusReports {
  Pending,
  Completed,
  Expired,
  ReadyToStart,
  InProgress,
}

export enum SignatureBy {
  Auditor,
  Auditee,
}

export enum AuditStatus {
  Pending,
  Completed,
  Reopened,
  InProgress,
  Expired,
}

export enum PerformAuditErrorCode {
  alreadyCompleted = 'audit/already-completed',
  alreadyExpired = 'audit/already-expired',
  alreadyDeleted = 'audit/already-deleted',
  notAvailable = 'audit/not-available',
  itemsNotSynced = 'audit/items-not-synced',
  missingFiles = 'audit/missing-files',
  completionInProgress = 'audit/completion-in-progress',
  auditNotAvailable = 'audit/no-access-or-not-found',
  switchCompanyToAccess = 'audit/switch-company-to-access',
  itemsNotValid = 'audit/audit-items-not-valid',
  auditorSignatureRequired = 'audit/auditor-signature-required',
  auditeeSignatureRequired = 'audit/auditee-signature-required',
}

export enum MyAuditsGroup {
  Past,
  Today,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
  NextWeek,
}
