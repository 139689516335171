import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const Email = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M3.5 7L10.986 11.4035C11.6119 11.7717 12.3881 11.7717 13.014 11.4035L20.5 7"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 8C3 5.79086 4.79086 4 7 4H17C19.2091 4 21 5.79086 21 8V16C21 18.2091 19.2091 20 17 20H7C4.79086 20 3 18.2091 3 16V8Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default Email;
