import React from 'react';
import Space from 'antd/es/space';
import { useNavigate } from 'react-router-dom';

import {
  ResultContainer,
  ResultIcon,
  ResultText,
  ResultTitle,
  ResultButtons,
} from './styled';
import { routes } from '@src/config/routes';

import Button from '../../../shared/ant/Button';

interface IProps {
  title: string;
  text: string;
  signInBtnText?: string;
  icon: React.ReactNode;
}

const SetNewPasswordResult: React.FC<React.PropsWithChildren<IProps>> = ({
  title,
  text,
  signInBtnText,
  icon,
}) => {
  const navigate = useNavigate();

  return (
    <ResultContainer>
      <ResultIcon>{icon}</ResultIcon>
      <ResultTitle>{title}</ResultTitle>
      <ResultText>{text}</ResultText>
      {!!signInBtnText && (
        <ResultButtons>
          <Space>
            <Button type="primary" onClick={() => navigate(routes.signin)}>
              {signInBtnText}
            </Button>
          </Space>
        </ResultButtons>
      )}
    </ResultContainer>
  );
};

export default SetNewPasswordResult;
