import React from 'react';

import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { useSetIssuesPage } from '@application/Issues/hooks/useSetIssuesPage';

import Issues from '@src/presentation/Issues/Issues';

interface IProps {}

const MyIssues: React.FC<React.PropsWithChildren<IProps>> = () => {
  useSetIssuesPage(IssuesPage.My);

  return <Issues page={IssuesPage.My} />;
};

export default MyIssues;
