import { createReducer, Reducer } from '@reduxjs/toolkit';

import { ISettingsState } from '@types';
import { settingsActions as actions } from './settings.actions';
import { notificationsAdapter } from '@store/entityAdapters';
import { NotificationsTab } from '@repo/shared/enums';

export const settingsReducer: Reducer<ISettingsState> =
  createReducer<ISettingsState>(
    {
      notifications: {
        loading: false,
        data: notificationsAdapter.getInitialState(),
        error: null,
        tab: NotificationsTab.Audit,
      },
      integrations: {
        showConfirmRegenerateModal: false,
        loading: false,
        apiKey: null,
        webhook: null,
        error: null,
      },
    },
    (builder) =>
      builder
        .addCase(actions.getNotifications.pending, (state) => {
          state.notifications.loading = true;
          state.notifications.error = null;
        })
        .addCase(actions.getNotifications.fulfilled, (state, { payload }) => {
          state.notifications.loading = false;
          notificationsAdapter.setAll(state.notifications.data, payload);
        })
        .addCase(actions.getNotifications.rejected, (state, { payload }) => {
          state.notifications.loading = false;
          state.notifications.error = payload || null;
        })

        .addCase(
          actions.updateNotification.pending,
          (
            state,
            {
              meta: {
                arg: { isEnabled, notification },
              },
            }
          ) => {
            notificationsAdapter.updateOne(state.notifications.data, {
              id: notification,
              changes: {
                isEnabled,
              },
            });
          }
        )
        .addCase(actions.updateNotification.rejected, (state, { payload }) => {
          if (payload) {
            state.notifications.error = payload.error || null;

            notificationsAdapter.updateOne(state.notifications.data, {
              id: payload.notification,
              changes: {
                isEnabled: !notificationsAdapter
                  .getSelectors()
                  .selectById(state.notifications.data, payload.notification)
                  ?.isEnabled,
              },
            });
          }
        })
        .addCase(actions.setNotificationsTab, (state, { payload }) => {
          state.notifications.tab = payload;
        })
        .addCase(actions.integrations.getIntegrations.pending, (state) => {
          state.integrations.loading = true;
        })
        .addCase(
          actions.integrations.getIntegrations.fulfilled,
          (state, { payload: { apiKey, webhook } }) => {
            state.integrations.loading = false;
            state.integrations.apiKey = apiKey;
            state.integrations.webhook = webhook;
          }
        )
        .addCase(
          actions.integrations.getIntegrations.rejected,
          (state, { payload }) => {
            state.integrations.loading = false;
            state.integrations.error = payload || null;
          }
        )
        .addCase(actions.integrations.generateApiKey.pending, (state) => {
          state.integrations.apiKey = null;
        })
        .addCase(
          actions.integrations.generateApiKey.fulfilled,
          (state, { payload }) => {
            state.integrations.apiKey = payload;
          }
        )
        .addCase(actions.integrations.deleteApiKey.fulfilled, (state) => {
          state.integrations.apiKey = null;
        })
        .addCase(
          actions.integrations.createWebhook.fulfilled,
          (state, { payload }) => {
            state.integrations.webhook = payload;
          }
        )
        .addCase(actions.integrations.deleteWebhook.fulfilled, (state) => {
          state.integrations.webhook = null;
        })
        .addCase(
          actions.integrations.toggleConfirmRegenerateModal,
          (state, { payload }) => {
            state.integrations.showConfirmRegenerateModal = payload;
          }
        )
  );
