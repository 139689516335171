import React from 'react';
import { useIntl } from 'react-intl';

import { More, ExclamationMarkPlaceholder, EmptyPlaceholder } from './styled';
import { IConcise } from '@repo/shared/types';

interface IProps {
  items: IConcise[];
  showExclamationPlaceholder?: boolean;
  emptyPlaceholder?: string;
}

const ConciseListStr: React.FC<React.PropsWithChildren<IProps>> = ({
  items,
  showExclamationPlaceholder,
  emptyPlaceholder,
}) => {
  const { formatMessage } = useIntl();
  let more = 0;

  if (items.length === 0) {
    const placeholder = emptyPlaceholder || formatMessage({ id: 'Unassigned' });

    if (showExclamationPlaceholder) {
      return (
        <ExclamationMarkPlaceholder>{placeholder}</ExclamationMarkPlaceholder>
      );
    }

    return <EmptyPlaceholder>{placeholder}</EmptyPlaceholder>;
  }

  return (
    <>
      {items.reduce((acc, item: IConcise, i) => {
        let a = acc;

        if (i <= 1) {
          a += item.name;

          if (i === 0 && i !== items.length - 1) {
            a += ', ';
          }
        } else if (i > 1) {
          more += 1;
        }

        return a;
      }, '')}
      {more > 0 && <More> +{more}</More>}
    </>
  );
};

export default ConciseListStr;
