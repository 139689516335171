import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@src/core/frameworks/redux';
import {
  conciseSchedulesAdapter,
  schedulesAdapter,
} from '@application/Schedules/store/entityAdapters';
import { SchedulesState } from '@application/Schedules/models/SchedulesState';

const getSchedulesState = (state: IRootState): SchedulesState =>
  state.schedules;

const getAddEditModalState = createSelector(
  getSchedulesState,
  ({ addEditScheduleModal }) => addEditScheduleModal
);

const getSchedules = createSelector(
  getSchedulesState,
  ({ table: { loading, error, meta, data } }) => ({
    loading,
    error,
    meta,
    data: schedulesAdapter.getSelectors().selectAll(data),
  })
);

const getConciseSchedules = createSelector(
  getSchedulesState,
  ({ concise }) => ({
    ...concise,
    data: conciseSchedulesAdapter.getSelectors().selectAll(concise.data),
  })
);

const getScheduleDetails = createSelector(
  getSchedulesState,
  ({ details }) => details
);

const getSchedulesDictionary = createSelector(
  getSchedulesState,
  ({ table: { data } }) => schedulesAdapter.getSelectors().selectEntities(data)
);

const getFilters = createSelector(
  getSchedulesState,
  ({ table: { filters } }) => filters
);

const isFiltersModalOpened = createSelector(
  getSchedulesState,
  ({ showFiltersModal }) => showFiltersModal
);

const getNumberOfSelectedFilters = createSelector(
  getSchedulesState,
  ({ table: { filters } }) => {
    let selected = 0;

    if (
      filters.auditObjectIds.length > 0 ||
      filters.auditObjectGroupIds.length > 0
    ) {
      selected += 1;
    }

    if (filters.auditRepeatPattern !== null) {
      selected += 1;
    }

    return selected;
  }
);

const getConfirmDeleteModalState = createSelector(
  getSchedulesState,
  ({ confirmDeleteModal }) => confirmDeleteModal
);

export const schedulesSelectors = {
  getSchedules,
  getSchedulesDictionary,
  getScheduleDetails,
  getFilters,
  isFiltersModalOpened,
  getNumberOfSelectedFilters,
  getAddEditModalState,
  getConfirmDeleteModalState,
  getConciseSchedules,
};
