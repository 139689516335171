import styled from 'styled-components';
import AntPagination from 'antd/es/pagination';

import { colors } from '@repo/shared/config';

export const StyledPagination = styled(AntPagination)`
  .ant-pagination-item-link {
    border: 0;
  }

  .ant-pagination-item {
    border: 0;
  }

  .ant-pagination-item-active {
    a {
      color: #000000;
      font-weight: 600;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    a {
      line-height: 29px;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      color: ${colors.gray8};
    }
  }
`;
