import { IRootState } from '@src/core/frameworks/redux';
import { issuesQrCodesEntityAdapter } from '@application/Issues/store/entityAdapters';
import { createSelector, EntityState } from '@reduxjs/toolkit';
import { IPagingMeta } from '@repo/shared/types';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { IssueTypeQrCode } from '@domain/IssueTypes/models/IssueTypeQrCode';
import { IssueTypeQrCodesFilters } from '@application/IssueTypes/models/IssueTypeQrCodesFilters';
import { IssueTypeQrCodeModalState } from '@application/IssueTypes/models/IssueTypeQrCodeModalState';

const getIssueQrCodes = createSelector(
  (
    state: IRootState
  ): {
    loading: boolean;
    data: EntityState<IssueTypeQrCode, string>;
    meta: IPagingMeta | null;
    error: string | null;
  } => state.issueTypeQrCodes.list,
  ({ data, meta, loading, error }) => ({
    data: issuesQrCodesEntityAdapter.getSelectors().selectAll(data),
    meta,
    loading,
    error,
  })
);

const getIssueQrCodesDictionary = (state: IRootState) =>
  issuesQrCodesEntityAdapter
    .getSelectors()
    .selectEntities(state.issueTypeQrCodes.list.data);

const getConfirmDeleteModalState = (
  state: IRootState
): DeleteModalState<IssueTypeQrCode> =>
  state.issueTypeQrCodes.confirmDeleteModal;

const getFilters = (state: IRootState): IssueTypeQrCodesFilters =>
  state.issueTypeQrCodes.filters;

const getSelectedFiltersQty = createSelector(getFilters, (filters) => {
  let selected = 0;

  if (filters.allowAnyAuditObject) {
    selected += 1;
  }

  if (filters.auditObjectsIds.length > 0) {
    selected += 1;
  }

  if (filters.issueTypeIds.length > 0) {
    selected += 1;
  }

  return selected;
});

const isFiltersModalOpened = (state: IRootState): boolean =>
  state.issueTypeQrCodes.showFiltersModal;

const getAddEditModalState = (
  state: IRootState
): {
  show: false;
  issueTypeQrCodeId: null;
} => state.issueTypeQrCodes.addEditModal;

const getQrCodeModalState = (
  state: IRootState
): IssueTypeQrCodeModalState | null => state.issueTypeQrCodes.qrCodeModal;

export const issueTypeQrCodesSelectors = {
  getIssueQrCodes,
  getIssueQrCodesDictionary,
  getConfirmDeleteModalState,
  getFilters,
  getSelectedFiltersQty,
  isFiltersModalOpened,
  getAddEditModalState,
  getQrCodeModalState,
};
