import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import styled from 'styled-components';

import { usersActions, usersSelectors } from '@store';
import { notification, validations } from '@utils';
import { useAppDispatch } from '@hooks';
import { colors } from '@repo/shared/config';

import Form from '@components/shared/ant/Form';
import Modal from '../../shared/ant/Modal/Modal';
import { InputPassword } from '@components/shared/ant/Input';

const Content = styled.div`
  padding-top: 5px;
`;

const SetUserPasswordModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const usersMap = useSelector(usersSelectors.list.getMap);
  const userId = useSelector(usersSelectors.list.getSetUserPasswordModalUserId);
  const userName =
    userId !== null && usersMap[userId] !== undefined
      ? usersMap[userId]?.name
      : '';

  const closeModal = useCallback(
    () => dispatch(usersActions.list.toggleSetUserPasswordModal(null)),
    [dispatch]
  );

  useEffect(() => {
    if (userId !== null) {
      form?.resetFields();
    }
  }, [userId]);

  return (
    <Modal
      title={formatMessage({ id: 'SetUserPassword' })}
      open={userId !== null}
      onOk={async () => {
        if (userId === null) {
          return;
        }

        try {
          await form?.validateFields();

          setLoading(true);

          const resultAction = await dispatch(
            usersActions.list.setUserPassword({
              userId,
              password: form.getFieldValue('password'),
            })
          );

          if (usersActions.list.setUserPassword.fulfilled.match(resultAction)) {
            notification.success({
              message: formatMessage({
                id: 'PasswordHasBeenSuccessfullyUpdated',
              }),
            });

            closeModal();
          } else {
            const passwordChangeNotAllowed =
              resultAction.payload === 'user/change-password-not-allowed';

            if (passwordChangeNotAllowed) {
              notification.error({
                message: formatMessage({
                  id: 'user/change-password-not-allowed',
                }),
              });
            } else {
              notification.error({
                message: formatMessage({ id: 'ErrorWhileUpdatingPassword' }),
              });
            }
          }

          setLoading(false);
        } catch (_) {
          /* empty */
        }
      }}
      okText={formatMessage({ id: 'Change' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => closeModal()}
      closeIcon={<X />}
    >
      <Content>
        <Form form={form} layout="vertical" initialValues={{ password: '' }}>
          <Form.Item
            name="password"
            label={formatMessage({ id: 'NewPassword' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'PleaseEnterNewUsernamePassword' },
                  { userName }
                ),
              },
              {
                pattern: validations.password,
                message: formatMessage({
                  id: 'PasswordShouldContainMinimumEightCharacters',
                }),
              },
            ]}
          >
            <InputPassword
              disabled={loading}
              placeholder={formatMessage({ id: 'Password' })}
            />
          </Form.Item>
        </Form>
      </Content>
    </Modal>
  );
};

export default SetUserPasswordModal;
