import React from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@hooks';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';

import InternalAuditReport from '@presentation/Audits/InternalAuditReport/InternalAuditReport';

interface IProps {}

const InternalAuditReportModal: React.FC<
  React.PropsWithChildren<IProps>
> = () => {
  const dispatch = useAppDispatch();

  const modalState = useSelector(auditsSelectors.getAuditReportModalAuditState);

  return (
    <InternalAuditReport
      opened={modalState.opened}
      auditId={modalState.opened ? modalState.audit.id : undefined}
      onClose={() => {
        dispatch(auditsActions.toggleAuditReportModal({ opened: false }));
      }}
    />
  );
};

export default InternalAuditReportModal;
