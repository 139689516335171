import { Filter, X } from 'react-feather';

import { useHover } from '@hooks';
import {
  Container,
  ShowModalButton,
  SelectedFilters,
  ResetFilters,
  Info,
} from './styled';

interface IProps {
  showModal: () => void;
  resetFilters: () => void;
  selectedFilters: number;
  showZero?: boolean;
}

function FiltersButton({
  showModal,
  resetFilters,
  selectedFilters,
  showZero,
}: IProps) {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const showQty = showZero || selectedFilters > 0;

  return (
    <Container ref={hoverRef}>
      <ShowModalButton
        type="button"
        onClick={() => showModal()}
        showQty={showQty}
      >
        <Filter />
      </ShowModalButton>
      {showQty && (
        <Info>
          {isHovered && selectedFilters > 0 ? (
            <ResetFilters type="button" onClick={() => resetFilters()}>
              <X />
            </ResetFilters>
          ) : (
            <SelectedFilters>{selectedFilters}</SelectedFilters>
          )}
        </Info>
      )}
    </Container>
  );
}

export default FiltersButton;
