import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const CurrentBadge = styled.div`
  margin-left: 7px;
  padding: 4px 8px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  color: ${colors.gray8};
  background: ${colors.gray4};
`;
