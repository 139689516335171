import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'antd/es/modal';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@hooks';
import { useWindowSize } from '@repo/shared/hooks';
import {
  Header,
  Title,
  Count,
  Container,
  Content,
  LoaderContainer,
  Flag,
} from './styled';
import { itemAnalysisReportSelectors, itemAnalysisReportActions } from '@store';
import { ItemFlag } from '@repo/shared/enums';

import AnimatedFlag from '@repo/shared/components/Flags/AnimatedFlag/AnimatedFlag';
import DetailsModalAudit from './DetailsModalAudit/DetailsModalAudit';
import Scrollbar from '@components/shared/Scrollbar/Scrollbar';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';

interface IProps {}

const DetailsModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const type = useSelector(itemAnalysisReportSelectors.detailsModal.getType);
  const loading = useSelector(
    itemAnalysisReportSelectors.detailsModal.isLoading
  );
  const filters = useSelector(
    itemAnalysisReportSelectors.detailsModal.getFilters
  );
  const error = useSelector(itemAnalysisReportSelectors.detailsModal.getError);
  const { title, count } = useSelector(
    itemAnalysisReportSelectors.detailsModal.getHeader
  );
  const audits = useSelector(
    itemAnalysisReportSelectors.detailsModal.getAudits
  );
  const hasMoreData = useSelector(
    itemAnalysisReportSelectors.detailsModal.hasMoreData
  );
  const visible = type !== null;
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(itemAnalysisReportActions.detailsModal.getData());
      setHasScroll(false);
    }
  }, [visible]);

  const { height: windowHeight } = useWindowSize();

  const modalHeight = useMemo<number>(() => {
    if (!windowHeight || windowHeight > 1000) {
      return 740;
    }

    return windowHeight - 170 > 440 ? windowHeight - 170 : 440;
  }, [windowHeight]);

  return (
    <Modal
      open={visible}
      title={
        <Header hasScroll={hasScroll}>
          <Title>{title}</Title>
          {Object.values(ItemFlag).includes(filters?.flag) && (
            <Flag>
              <AnimatedFlag
                expanded
                flag={filters.flag}
                failedInRowCount={count}
              />
            </Flag>
          )}
          {count !== null && <Count>{count}</Count>}
        </Header>
      }
      width={740}
      className="modal-no-padding"
      footer={null}
      styles={{
        body: {
          marginBottom: 0,
          marginTop: -8,
          height: modalHeight,
        },
      }}
      style={{
        top: 50,
      }}
      destroyOnClose
      wrapProps={{
        style: {
          overflow: modalHeight > 440 ? 'hidden' : 'auto',
        },
      }}
      closeIcon={<X />}
      onCancel={() => {
        dispatch(itemAnalysisReportActions.detailsModal.hide());
      }}
      closable
      maskClosable
    >
      <Container style={{ height: modalHeight }}>
        {loading && audits.length === 0 ? (
          <Content style={{ marginTop: 50, paddingRight: 20 }}>
            <Skeleton active />
          </Content>
        ) : (
          <>
            {audits.length === 0 ? (
              <>
                {error ? (
                  <Content style={{ marginTop: 100 }}>
                    <EmptyTable text={error} />
                  </Content>
                ) : (
                  <Content style={{ marginTop: 100 }}>
                    <EmptyTable text={formatMessage({ id: 'NothingFound' })} />
                  </Content>
                )}
              </>
            ) : (
              <Scrollbar
                onScroll={(scrollTop) => {
                  if (scrollTop && !hasScroll) {
                    setHasScroll(true);
                  }

                  if (!scrollTop && hasScroll) {
                    setHasScroll(false);
                  }
                }}
                onScrollStop={({
                  clientHeight,
                  scrollTop,
                  contentScrollHeight,
                }) => {
                  if (
                    !loading &&
                    hasMoreData &&
                    clientHeight + scrollTop > contentScrollHeight - 200
                  ) {
                    dispatch(itemAnalysisReportActions.detailsModal.getData());
                  }
                }}
              >
                <Content>
                  {audits.map((audit) => (
                    <DetailsModalAudit key={audit.id} audit={audit} />
                  ))}

                  <LoaderContainer>
                    {hasMoreData && loading && <Spin spinning />}
                  </LoaderContainer>
                </Content>
              </Scrollbar>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};

export default DetailsModal;
