import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const Info = styled.div`
  line-height: 1.4;
`;

export const UserName = styled.div`
  font-size: 14px;
  color: ${colors.white};
`;

export const Company = styled.div`
  font-size: 12px;
  color: ${colors.gray6};
`;

export const DropDownMenuTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    color: ${colors.white};
  }
`;
