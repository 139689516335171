import styled from 'styled-components';

import LibraryCheckbox from '@ui/components/shared/ant/Checkbox';
import { colors } from '@repo/shared/config';

export const Header = styled.div`
  font-size: 14px;
`;

export const Desc = styled.div`
  font-size: 12px;
  color: ${colors.gray8};
  line-height: 1.5;
  margin-top: 2px;
`;

export const DeleteSampleDataCheckbox = styled(LibraryCheckbox)`
  &.ant-checkbox-wrapper {
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;

    .ant-checkbox + span {
      margin-top: 18px;
      padding-left: 0;
    }
  }
`;
