import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  accountSelectors,
  auditObjectsActions,
  auditObjectsSelectors,
} from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission, useAppDispatch } from '@hooks';
import { e2eTestElements } from '@config';

import PageHeader from '../../../../shared/ant/PageHeader';
import SearchInput from '../../../../shared/SeachInput/SearchInput';
import Button from '@components/shared/ant/Button';

const AuditObjectsGroupsHeader: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const canManageAuditObjects = usePermission(Permission.CanManageAuditObjects);
  const auditObjectName = useSelector(accountSelectors.getObjectName);
  const { isLocationsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const filters = useSelector(auditObjectsSelectors.groups.getFilters);

  return (
    <PageHeader
      title={`${auditObjectName.plural} ${formatMessage({ id: 'Groups' })}`}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(
                auditObjectsActions.groups.getData({
                  search,
                  pageNumber: 1,
                })
              )
            }
          />
          {!isLocationsReadonly && canManageAuditObjects && (
            <Button
              type="primary"
              onClick={() => {
                dispatch(
                  auditObjectsActions.groups.toggleAddEditModal({
                    visible: true,
                  })
                );
              }}
              data-e2e={e2eTestElements.objects.createObjectGroup}
            >
              {formatMessage(
                {
                  id: 'NewAuditObjectGroup',
                },
                { auditObjectName: auditObjectName.single }
              )}
            </Button>
          )}
        </>
      }
    />
  );
};

export default AuditObjectsGroupsHeader;
