import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks';
import { auditObjectsActions } from '@store';

import AuditObjectsHeader from './AuditObjectsHeader/AuditObjectsHeader';
import AuditObjectsTable from './AuditObjectsTable/AuditObjectsTable';
import AuditObjectsActionsModal from './AuditObjectsActionsModal';
import AddEditAuditObjectModal from './AddEditAuditObjectModal/AddEditAuditObjectModal';
import DeleteAuditObjectModal from './DeleteAuditObjectModal';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import AuditObjectsFiltersModal from '@components/auditObjects/list/AuditObjectsFiltersModal/AuditObjectsFiltersModal';

const AuditObjects: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(auditObjectsActions.objects.getData());

    return () => {
      dispatch(auditObjectsActions.objects.hideAddEditModal());
    };
  }, []);

  return (
    <>
      <SampleDataAlert />
      <AuditObjectsHeader />
      <AuditObjectsTable />
      <AddEditAuditObjectModal />
      <AuditObjectsActionsModal />
      <DeleteAuditObjectModal />
      <AuditObjectsFiltersModal />
    </>
  );
};

export default AuditObjects;
