import { AuditTemplateSuggestion } from '@domain/AuditTemplates/models/AuditTemplateSuggestion';
import { AuditTemplateDetails } from '@repo/shared/types';
import { getPristineAuditTemplateItem } from '@application/AuditTemplateBuilder/utils/getPristineAuditTemplateItem';
import { AnswerType, AuditTemplateType, ItemType } from '@repo/shared/enums';
import { getAnswerTypeData } from '@utils';
import { getAuditTemplateBase } from '@application/AuditTemplateBuilder/utils/getAuditTemplateBase';
import { IAnswerData, IScore, ITemplateItem } from '@repo/shared/types';

export function createTemplateFromSuggestion({
  templateType,
  answerTypes,
  suggestion,
  scoreSystem,
}: {
  templateType: AuditTemplateType;
  answerTypes: { answerType: AnswerType; data: IAnswerData }[];
  suggestion: AuditTemplateSuggestion;
  scoreSystem: IScore;
}): AuditTemplateDetails {
  const root = getPristineAuditTemplateItem({
    parentId: null,
    itemType: ItemType.Root,
    answerType: null,
    text: 'Root',
    index: 0,
  });

  const items = [root];

  for (let i = 0; i < suggestion.data.length; i++) {
    const sectionSuggestion = suggestion.data[i];

    const section = getPristineAuditTemplateItem({
      parentId: root.id,
      itemType: ItemType.Section,
      answerType: null,
      text: sectionSuggestion.sectionName,
      index: 0,
    });

    root.childrenIds.push(section.id);
    items.push(section);

    for (let j = 0; j < sectionSuggestion.items.length; j++) {
      const answerType =
        templateType === AuditTemplateType.Audit
          ? AnswerType.PassFailButtons
          : AnswerType.Checklist;

      const item = getPristineAuditTemplateItem({
        parentId: section.id,
        itemType: ItemType.Item,
        answerType,
        data: getAnswerTypeData(answerType, answerTypes),
        text: sectionSuggestion.items[j],
        index: i,
      });

      section.childrenIds.push(item.id);
      items.push(item);
    }
  }

  return {
    ...getAuditTemplateBase({
      templateType,
      templateName: suggestion.templateName,
      scoreSystem,
    }),
    data: items.reduce<Record<string, ITemplateItem>>((acc, item) => {
      const a = acc;
      a[item.id] = item;
      return a;
    }, {}),
  };
}
