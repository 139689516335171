import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import { AlertTriangle } from 'react-feather';

export const Content = styled.div`
  text-align: center;
`;

export const Icon = styled(AlertTriangle)`
  display: block;
  width: 36px;
  height: 36px;
  color: ${colors.red};
  margin: 0 auto 15px;
`;

export const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 12px;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 25px;

  a {
    color: ${colors.blue2};
    text-decoration: none;
  }
`;
