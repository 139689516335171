import { Harmonizer } from 'color-harmony';

import { colors, tagColors } from '@repo/shared/config';

const { green, orange, red, wingstop, yellow } = colors;
const baseColors = Object.values({
  ...tagColors,
  green,
  orange,
  red,
  wingstop,
  yellow,
});
const harmonizer = new Harmonizer();

export class ChartLineColorGenerator {
  private static _instance: ChartLineColorGenerator =
    new ChartLineColorGenerator();

  private _baseColorIndex = 0;
  private _colorIndex = 0;

  constructor() {
    if (ChartLineColorGenerator._instance) {
      throw new Error(
        'Error: Instantiation failed: Use ChartLineColorGenerator.use() instead of new.'
      );
    }

    ChartLineColorGenerator._instance = this;
  }

  public static use(): ChartLineColorGenerator {
    return ChartLineColorGenerator._instance;
  }

  public getNextColor(): string {
    const baseColor = baseColors[this._baseColorIndex];
    const harmony = harmonizer.harmonize(baseColor, 'analogous');
    const color = harmony[this._colorIndex];

    if (this._baseColorIndex < baseColors.length - 1) {
      this._baseColorIndex++;
    } else {
      this._colorIndex++;
      this._baseColorIndex = 0;
    }

    return color;
  }

  public reset(): void {
    this._baseColorIndex = 0;
    this._colorIndex = 0;
  }
}
