import React from 'react';

import { BillingPage } from '@repo/shared/enums';
import { Container, Content } from './styled';

import BillingHeader from './BillingHeader/BillingHeader';

interface IProps {
  page: BillingPage;
  children: React.ReactNode;
}

const BillingLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  page,
  children,
}) => {
  return (
    <Container>
      <BillingHeader page={page} />
      <Content>{children}</Content>
    </Container>
  );
};

export default BillingLayout;
