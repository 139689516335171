import React from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, usePermission } from '@hooks';
import { Issue, IssueStatus } from '@domain/Issues/models/Issue';
import { issuesActions } from '@application/Issues/store';
import { Permission } from '@repo/shared/enums';

import TableRowActionsMenu from '@components/shared/TableRowActionsMenu/TableRowActionsMenu';

interface IProps {
  issue: Issue;
}

const IssuesTableRowMenu: React.FC<React.PropsWithChildren<IProps>> = ({
  issue,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [canChangeIssueStatus, canDeleteIssues, canEditIssues] = usePermission([
    Permission.CanChangeIssueStatus,
    Permission.CanDeleteIssues,
    Permission.CanEditIssues,
  ]);

  const menuItems = [
    {
      name: formatMessage({ id: 'Details' }),
      onClick: () => {
        dispatch(issuesActions.toggleIssueDetailsModal(issue.id));
      },
    },
  ];

  if (issue.status === IssueStatus.Closed && canChangeIssueStatus) {
    menuItems.push({
      name: formatMessage({ id: 'Reopen' }),
      onClick: () => {
        dispatch(issuesActions.toggleReopenIssuesModal([issue.id]));
      },
    });
  }

  if (issue.status === IssueStatus.Opened && canChangeIssueStatus) {
    menuItems.push({
      name: formatMessage({ id: 'Close' }),
      onClick: () => {
        dispatch(issuesActions.toggleCloseIssuesModal([issue.id]));
      },
    });
  }

  if (issue.status === IssueStatus.Opened && canEditIssues) {
    menuItems.push({
      name: formatMessage({ id: 'AssignUsers' }),
      onClick: () => {
        dispatch(
          issuesActions.toggleAssignUsersToIssuesModal({
            issuesIds: [issue.id],
            auditObjectId: issue.auditObject.id,
          })
        );
      },
    });
  }

  if (canDeleteIssues) {
    menuItems.push({
      name: formatMessage({ id: 'Delete' }),
      onClick: () => {
        dispatch(
          issuesActions.toggleConfirmDeleteIssuesModal({
            multiple: false,
            data: issue,
          })
        );
      },
    });
  }

  return <TableRowActionsMenu menuItems={menuItems} />;
};

export default IssuesTableRowMenu;
