import React from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { AuditsLink } from './styled';
import {
  dashboardSelectors,
  generalSelectors,
  itemAnalysisReportActions,
} from '@store';
import { removeUndefinedAndNullProps } from '@utils';
import { getQueryString } from '@repo/shared/utils';
import { useAppDispatch, usePermission } from '@hooks';
import {
  AuditDueDateStatus,
  AuditStatusReports,
  DetailsModalType,
  Permission,
} from '@repo/shared/enums';
import { routes, e2eTestElements } from '@config';

import Total from './Total/Total';

const Totals: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const { data, loading } = useSelector(
    dashboardSelectors.getTotals,
    shallowEqual
  );
  const { templateIds, ...filters } = useSelector(
    generalSelectors.getSharedFilters
  );
  const [canDoAudits, canAssignAudits, canManageAudits, canScheduleAudits] =
    usePermission([
      Permission.CanDoAudits,
      Permission.CanAssignAudits,
      Permission.CanManageAudits,
      Permission.CanScheduleAudits,
    ]);
  const auditsStr = formatMessage({ id: 'Audits' }).toLowerCase();
  const canViewAuditsPages =
    canDoAudits || canAssignAudits || canManageAudits || canScheduleAudits;

  return (
    <Row align="middle" gutter={[14, 14]}>
      <Col span="12">
        <Total
          value={data?.upcoming}
          label={formatMessage({ id: 'UpcomingAudits' })}
          loading={loading}
          onClick={
            data?.upcoming
              ? () => {
                  dispatch(
                    itemAnalysisReportActions.detailsModal.show({
                      title: formatMessage({ id: 'UpcomingAudits' }),
                      count: data?.upcoming,
                      type: DetailsModalType.Audits,
                      filters: {
                        auditStatus: AuditStatusReports.Pending,
                        dueDateStatus: AuditDueDateStatus.Upcoming,
                      },
                    })
                  );
                }
              : undefined
          }
          e2eDataAttr={e2eTestElements.dashboard.totals.upcomingAudits}
        />
      </Col>
      <Col span="12">
        <Total
          value={data?.readyToStart}
          label={formatMessage({ id: 'ReadyToStart' })}
          loading={loading}
          onClick={
            data?.readyToStart
              ? () => {
                  dispatch(
                    itemAnalysisReportActions.detailsModal.show({
                      title: `${formatMessage({
                        id: 'ReadyToStart',
                      })} ${auditsStr}`,
                      count: data?.readyToStart,
                      type: DetailsModalType.Audits,
                      filters: {
                        auditStatus: AuditStatusReports.ReadyToStart,
                      },
                    })
                  );
                }
              : undefined
          }
          e2eDataAttr={e2eTestElements.dashboard.totals.readyToStart}
        />
      </Col>
      <Col span="12">
        <Total
          value={data?.dueIn7Days}
          label={formatMessage({ id: 'DueIn7Days' })}
          loading={loading}
          onClick={
            data?.dueIn7Days
              ? () => {
                  dispatch(
                    itemAnalysisReportActions.detailsModal.show({
                      title: `${formatMessage({
                        id: 'DueIn7Days',
                      })} ${auditsStr}`,
                      count: data?.dueIn7Days,
                      type: DetailsModalType.Audits,
                      filters: {
                        auditStatus: AuditStatusReports.Pending,
                        dueDateStatus: AuditDueDateStatus.DueSoon,
                      },
                    })
                  );
                }
              : undefined
          }
          e2eDataAttr={e2eTestElements.dashboard.totals.dueSoon}
        />
      </Col>
      <Col span="12">
        <Total
          value={data?.pastDue}
          label={formatMessage({ id: 'PastDue' })}
          loading={loading}
          onClick={
            data?.pastDue
              ? () => {
                  dispatch(
                    itemAnalysisReportActions.detailsModal.show({
                      title: `${formatMessage({ id: 'PastDue' })} ${auditsStr}`,
                      count: data?.pastDue,
                      type: DetailsModalType.Audits,
                      filters: {
                        auditStatus: AuditStatusReports.Pending,
                        dueDateStatus: AuditDueDateStatus.PastDue,
                      },
                    })
                  );
                }
              : undefined
          }
          e2eDataAttr={e2eTestElements.dashboard.totals.pastDue}
        />
      </Col>
      <Col span="12">
        <Total
          value={data?.inProgress}
          label={formatMessage({ id: 'InProgress' })}
          loading={loading}
          onClick={
            data?.inProgress
              ? () => {
                  dispatch(
                    itemAnalysisReportActions.detailsModal.show({
                      title: `${formatMessage({
                        id: 'InProgress',
                      })} ${auditsStr}`,
                      count: data?.inProgress,
                      type: DetailsModalType.Audits,
                      filters: {
                        auditStatus: AuditStatusReports.InProgress,
                      },
                    })
                  );
                }
              : undefined
          }
          e2eDataAttr={e2eTestElements.dashboard.totals.inProgress}
        />
      </Col>
      <Col span="12">
        {canViewAuditsPages && (
          <AuditsLink
            to={`${
              canDoAudits ? routes.pendingAudits : routes.completedAudits
            }?${getQueryString(
              removeUndefinedAndNullProps({
                ...filters,
                templateId: templateIds[0],
              })
            )}`}
            data-e2e={e2eTestElements.dashboard.totals.allAudits}
          >
            {formatMessage({
              id: canDoAudits ? 'AllPendingAudits' : 'AllCompletedAudits',
            }).toLowerCase()}{' '}
            ›
          </AuditsLink>
        )}
      </Col>
    </Row>
  );
};

export default Totals;
