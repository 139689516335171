import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ColumnType } from 'antd/es/table';

import { date, getColumnSortProperties } from '@utils';
import { accountSelectors } from '@store';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useAppDispatch, usePermission } from '@hooks';
import { e2eTestElements } from '@config';
import { config } from '@repo/shared/config';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { Issue } from '@domain/Issues/models/Issue';
import { Permission } from '@repo/shared/enums';
import { IssueAnswer } from './styled';
import { constructIssueName } from '@application/Issues/utils/constructIssueName';

import Table from '@components/shared/ant/Table/Table';
import EmptyTable from '@components/shared/ant/EmptyTable/EmptyTable';
import SampleBadgedName from '@components/shared/SampleBadgedName';
import PriorityLabel from '@components/shared/PriorityLabel/PriorityLabel';
import ZonedDateTime from '@components/shared/ZonedDateTime';
import IssuesTableRowMenu from '@src/presentation/Issues/IssuesTable/IssuesTableRowMenu';
import ConciseListStr from '@components/shared/ConciseListStr/ConciseListStr';
import CompleteDateTableCell from '@components/shared/DateTableCellWithDueLabel/CompleteDateTableCell';
import DueDateTableCell from '@components/shared/DateTableCellWithDueLabel/DueDateTableCell';

interface IProps {
  page: IssuesPage;
}

const IssuesTable: React.FC<React.PropsWithChildren<IProps>> = ({ page }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, meta, data, error } = useSelector(issuesSelectors.getIssues);
  const filters = useSelector(issuesSelectors.getFilters);
  const auditObjectName = useSelector(accountSelectors.getObjectName);

  const [canEditIssues, canDeleteIssues] = usePermission([
    Permission.CanEditIssues,
    Permission.CanDeleteIssues,
  ]);

  return (
    <Table<Issue>
      onPageChange={(update) => {
        dispatch(issuesActions.getIssues(update));
      }}
      onUnmount={() => {
        dispatch(issuesActions.toggleCreateIssueModal(false));
        dispatch(issuesActions.resetListData());
      }}
      canSelectRow={canDeleteIssues}
      onSelectRow={() => {
        dispatch(issuesActions.toggleFiltersModal(false));
      }}
      e2eDataAttr={e2eTestElements.issues.issues.table}
      loading={loading}
      meta={meta}
      error={error}
      dataSource={data.map((issue) => ({
        key: issue.id,
        ...issue,
      }))}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          render: (_, issue) => (
            <>
              <SampleBadgedName
                text={constructIssueName(issue)}
                isSample={issue.isSample}
              />
              {!!issue.firstQuestion && (
                <IssueAnswer>
                  {issue.firstQuestion}
                  {issue.firstQuestionAnswer
                    ? ` - ${issue.firstQuestionAnswer}`
                    : ''}
                </IssueAnswer>
              )}
            </>
          ),
          className: 'cell-text-link',
        },
        {
          title: auditObjectName.single,
          dataIndex: 'auditObjectName',
          key: 'auditObjectName',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'auditObjectName'
          ),
          render(_, issue) {
            return issue.auditObject.name;
          },
        },
        {
          title: formatMessage({ id: 'Type' }),
          dataIndex: 'type',
          key: 'type',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'type'
          ),
          render(_, issue) {
            return issue.type.name;
          },
        },
        {
          title: formatMessage({ id: 'Priority' }),
          dataIndex: 'priority',
          key: 'priority',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'priority'
          ),
          render(_, issue) {
            return <PriorityLabel priority={issue.priority} />;
          },
        },
        {
          title: formatMessage({ id: 'AssignedTo' }),
          dataIndex: 'assignedTo',
          key: 'assignedTo',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'assignedTo'
          ),
          render(_, issue) {
            return (
              <ConciseListStr
                items={issue.assignedUsers}
                showExclamationPlaceholder
              />
            );
          },
        },
        {
          title: formatMessage({ id: 'DateReported' }),
          dataIndex: 'createDate',
          key: 'createDate',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'createDate'
          ),
          render(_, issue) {
            return <ZonedDateTime dateTime={issue.createDate} />;
          },
        },
        ...(page === IssuesPage.Pending
          ? [
              {
                title: formatMessage({ id: 'DueDate' }),
                dataIndex: 'dueDate',
                key: 'dueDate',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'dueDate'
                ),
                render(_, issue) {
                  return (
                    <DueDateTableCell
                      dueDate={issue.dueDate.localTime}
                      timeZoneAbbreviation={issue.dueDate.timeZoneAbbreviation}
                      dueInDays={issue.dueInDays}
                    />
                  );
                },
              } as ColumnType<Issue>,
            ]
          : []),
        ...(page === IssuesPage.Archive
          ? [
              {
                title: formatMessage({ id: 'ClosedDate' }),
                dataIndex: 'closedDate',
                key: 'closedDate',
                ...getColumnSortProperties(
                  filters.orderByDirection,
                  filters.orderBy === 'closedDate'
                ),
                render(_, issue) {
                  if (!issue.closedDate) {
                    return null;
                  }

                  return (
                    <CompleteDateTableCell
                      completeDate={issue.closedDate.localTime}
                      timeZoneAbbreviation={
                        issue.closedDate.timeZoneAbbreviation
                      }
                      showLabel={date(issue.closedDate.localTime).isAfter(
                        issue.dueDate.localTime,
                        'day'
                      )}
                      labelText={formatMessage({ id: 'Late' })}
                    />
                  );
                },
              } as ColumnType<Issue>,
            ]
          : []),
        {
          title: formatMessage({ id: 'Action' }),
          key: 'action',
          align: 'center',
          width: 70,
          render: (_, issue) => <IssuesTableRowMenu issue={issue} />,
        } as ColumnType<Issue>,
      ]}
      onRow={(issue) => ({
        onClick: (e) => {
          e.stopPropagation();
          e.preventDefault();

          dispatch(issuesActions.toggleFiltersModal(false));
          dispatch(issuesActions.toggleIssueDetailsModal(issue.id));
        },
      })}
      locale={{
        emptyText: (
          <EmptyTable
            text={
              filters.search !== ''
                ? formatMessage(
                    { id: 'NoIssuesSearchResults' },
                    { searchStr: filters.search, linebreak: <br /> }
                  )
                : formatMessage({ id: 'NoIssues' }, { linebreak: <br /> })
            }
            button={
              page === IssuesPage.Pending && canEditIssues
                ? {
                    text: `+ ${formatMessage({ id: 'CreateIssue' })}`,
                    type: 'link',
                    onClick: () =>
                      dispatch(issuesActions.toggleCreateIssueModal(true)),
                  }
                : undefined
            }
            howItWorksUrl={config.urls.issuesSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(issuesActions.getIssues({ orderBy, orderByDirection }))
      }
    />
  );
};

export default IssuesTable;
