import React from 'react';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/es/select';

import { DayOfWeek } from '@repo/shared/enums';
import { enumToSelectOptions } from '@utils';

import Select from '@components/shared/ant/Select/Select';

const dayLangIds: Record<DayOfWeek, string> = {
  [DayOfWeek.Monday]: 'Monday',
  [DayOfWeek.Tuesday]: 'Tuesday',
  [DayOfWeek.Wednesday]: 'Wednesday',
  [DayOfWeek.Thursday]: 'Thursday',
  [DayOfWeek.Friday]: 'Friday',
  [DayOfWeek.Saturday]: 'Saturday',
  [DayOfWeek.Sunday]: 'Sunday',
};

interface IProps extends SelectProps {
  value?: number;
  onChange?: (value: DayOfWeek) => void;
  disabled?: boolean;
}

const options = enumToSelectOptions<DayOfWeek>(DayOfWeek);

const DaysOfWeekSelect: React.FC<React.PropsWithChildren<IProps>> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Select
      {...props}
      options={options.map(({ value }) => ({
        value,
        label: formatMessage({ id: dayLangIds[value] }),
      }))}
    />
  );
};

export default DaysOfWeekSelect;
