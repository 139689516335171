import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  IExposedFormRef,
  useExposedForm,
} from '@application/Issues/hooks/useExposedForm';
import {
  IssueTypeContactFieldType,
  IssueTypeContactInformationField,
} from '@domain/IssueTypes/models/IssueType';
import { issueTypesSelectors } from '@application/IssueTypes/store';
import { ContributorGuidance, Header } from './styled';
import { IssueContactInformationField } from '@domain/Issues/models/Issue';
import { contactFieldTypesLangIds } from '@src/presentation/IssueTypes/IssueType/IssueTypeDetails/IssueTypeDetailsContactFormFields';
import { disallowHTML } from '@utils';
import { Input } from '@components/shared/ant/Input';

import Form from '@components/shared/ant/Form';

interface IProps {}

interface FormValues {
  contactInformationFields: IssueContactInformationField[];
  contactInformationInfo: string;
}

const contactInformationPlaceholders = {
  [IssueTypeContactFieldType.Name]: 'YourName',
  [IssueTypeContactFieldType.Phone]: 'YourPhone',
  [IssueTypeContactFieldType.Email]: 'YourEmail',
};

const IssueContactInformationForm = forwardRef<
  IExposedFormRef<FormValues>,
  React.PropsWithChildren<IProps>
>((_, ref) => {
  const { formatMessage } = useIntl();

  const { loading, data } = useSelector(
    issueTypesSelectors.getIssueTypeDetails
  );

  const form = useExposedForm<FormValues>(ref);

  useEffect(() => {
    if (!loading && data) {
      form.setFieldsValue({
        contactInformationFields: (data.contactInformationFields || []).map(
          (field) => ({
            ...field,
            value: '',
          })
        ),
      });
    }
  }, [loading, JSON.stringify(data)]);

  if (!data || (data.contactInformationFields || []).length === 0) {
    return null;
  }

  return (
    <>
      <Header>{formatMessage({ id: 'ContactInformation' })}</Header>
      {!!data.contactInformationInfo && (
        <ContributorGuidance>{data.contactInformationInfo}</ContributorGuidance>
      )}
      <Form<FormValues>
        form={form}
        layout="vertical"
        initialValues={{
          contactInformationFields: [],
        }}
      >
        <Form.List name="contactInformationFields">
          {(fields) => (
            <>
              {fields.map((field) => {
                const contactInformationField: IssueTypeContactInformationField =
                  form.getFieldValue(['contactInformationFields', field.name]);

                const rules = [
                  {
                    max: 300,
                    message: formatMessage(
                      {
                        id: 'NameMustNotExceedXCharacters',
                      },
                      { count: 300 }
                    ),
                  },
                  disallowHTML(),
                ];

                if (contactInformationField.required) {
                  rules.push({
                    required: true,
                    message: formatMessage({ id: 'RequiredField' }),
                  });
                }

                switch (contactInformationField.type) {
                  case IssueTypeContactFieldType.Email:
                    rules.push({
                      type: 'email',
                      message: formatMessage({ id: 'PleaseEnterValidEmail' }),
                    });
                    break;
                }

                return (
                  <Form.Item
                    key={field.key}
                    name={[field.name, 'value']}
                    label={formatMessage({
                      id: contactFieldTypesLangIds[
                        contactInformationField.type
                      ],
                    })}
                    rules={rules}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: contactInformationPlaceholders[
                          contactInformationField.type
                        ],
                      })}
                    />
                  </Form.Item>
                );
              })}
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
});

export default IssueContactInformationForm;
