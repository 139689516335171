import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { itemAnalysisReportSelectors } from '@store';
import {
  CriticalLabel,
  Name,
  FlagsContainer,
  PreviewsContainer,
} from './styled';
import { DetailsModalType, FileSourceType } from '@repo/shared/enums';
import { DetailsModalItem as IDetailsModalItem } from '@domain/common/DetailsModalItem';

import ContentBlock from '../ContentBlock/ContentBlock';
import Response from './Response/Response';
import DetailsModalAction from './DetailsModalAction/DetailsModalAction';
import Flags from '@repo/shared/components/Flags/Flags';
import Previews from '@components/shared/Previews/Previews';
import TagList from '@components/shared/TagList/TagList';

interface IProps {
  item: IDetailsModalItem;
}

const DetailsModalItem: React.FC<React.PropsWithChildren<IProps>> = ({
  item,
}) => {
  const { formatMessage } = useIntl();

  const type = useSelector(itemAnalysisReportSelectors.detailsModal.getType);

  return (
    <>
      <ContentBlock
        content={
          <>
            <Name>
              <span>
                {item.groupIndex !== null ? `${item.groupIndex + 1}.` : ''}
                {item.index + 1}. {item.text}
              </span>
              {item.isCritical && (
                <CriticalLabel>
                  {' '}
                  {formatMessage({ id: 'Critical' })}
                </CriticalLabel>
              )}
            </Name>
            {type === DetailsModalType.Items && (
              <>
                {item.flags.length > 0 && (
                  <FlagsContainer>
                    <Flags flags={item.flags} flagsInRow={item.flagsInRow} />
                  </FlagsContainer>
                )}
                {item.files.length > 0 && (
                  <PreviewsContainer>
                    <Previews
                      files={item.files}
                      fileSourceType={FileSourceType.AuditItemFile}
                      containerStyle={{
                        margin: '8px 0',
                      }}
                    />
                  </PreviewsContainer>
                )}
              </>
            )}
          </>
        }
      />
      {type === DetailsModalType.Items && (
        <>
          {item.tags?.length > 0 && (
            <ContentBlock
              label={formatMessage({ id: 'Tags' })}
              content={<TagList tags={item.tags} />}
            />
          )}
          <ContentBlock
            label={formatMessage({ id: 'Response' })}
            content={<Response item={item} />}
          />
          {!!item.note && (
            <ContentBlock
              label={formatMessage({ id: 'Note' })}
              content={item.note}
            />
          )}
          {item.actions.map((action) => (
            <ContentBlock
              key={action.id}
              label={formatMessage({ id: 'Action' })}
              content={<DetailsModalAction action={action} />}
            />
          ))}
        </>
      )}
    </>
  );
};

export default DetailsModalItem;
