import React from 'react';

import { IAccount } from '@repo/shared/types';
import { Container } from './styled';

import ImageContainer from '@repo/shared/components/Previews/ImageContainer';
import AccountIcon from './AccountIcon';

interface IProps {
  account: IAccount;
}

const AccountIconContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  account,
}) => {
  if (account.company.iconId !== null) {
    return (
      <Container>
        <ImageContainer
          spinSize="small"
          fileId={account.company.iconId}
          companyId={account.company.id}
          blurBackgroundOnLoad={false}
          skipStatusCheck
        >
          {({ imageUrl, onLoadImage }) => {
            return (
              <AccountIcon
                imageUrl={imageUrl}
                onLoad={onLoadImage}
                account={account}
              />
            );
          }}
        </ImageContainer>
      </Container>
    );
  }

  return <AccountIcon account={account} />;
};

export default AccountIconContainer;
