import styled from 'styled-components';
import Button from 'antd/es/button';

export const Action = styled((props) => <Button {...props} type="text" />)`
  display: block;
  padding: 0;
  margin-bottom: 5px;
  font-size: 14px;

  &:hover,
  &:focus {
    background: none;
  }
`;
