import React, { useEffect, useState } from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';

import Select from '../../../../../../../shared/ant/Select/Select';

const { Option } = AntSelect;

interface IProps extends SelectProps<any> {
  country?: string;
}

const StateSelect: React.FC<React.PropsWithChildren<IProps>> = ({
  country,
  disabled,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{ code: string; name: string }[]>([]);

  useEffect(() => {
    async function changeCountry() {
      if (country) {
        setLoading(true);

        try {
          const divisions = await import(`./divisions/${country}.json`);

          setOptions(divisions.default);
        } catch (e) {
          console.error(e);
        }

        setLoading(false);
      }
    }

    changeCountry();
  }, [country]);

  return (
    <Select {...props} disabled={disabled || loading || !country}>
      {options.map(({ name, code }) => (
        <Option key={code} value={code}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

export default StateSelect;
