import React from 'react';
import { useIntl } from 'react-intl';

import { Container, ContactUsLink } from './styled';
import { config } from '@repo/shared/config';

const HaveQuestions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      {formatMessage({ id: 'HaveQuestions?' })}
      <ContactUsLink
        href={config.urls.support}
        target="_blank"
        rel="noreferrer noopener"
      >
        {formatMessage({ id: 'Support24/7' })}
      </ContactUsLink>
    </Container>
  );
};

export default HaveQuestions;
