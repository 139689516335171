import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;

  & > * {
    &:first-child {
      flex-basis: 60%;
    }

    &:last-child {
      flex-basis: 40%;
    }
  }
`;

export const Title = styled.h2`
  margin-bottom: 16px;
`;
