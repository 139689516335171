import React from 'react';
import { useSelector } from 'react-redux';
import AntModal from 'antd/es/modal';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { Content, Desc, LogoutBtn, StopIcon, Title, Accounts } from './styled';
import { accountActions, accountSelectors } from '@store';
import { routes } from '@config';
import { config } from '@repo/shared/config';
import { usePermission, useAppDispatch } from '@hooks';
import { Permission } from '@repo/shared/enums';

import HaveQuestions from '../HaveQuestions/HaveQuestions';
import ModalAccountsList from '@ui/components/shared/ModalAccountsList/ModalAccountsList';

const PayWall: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();

  const { data: accounts } = useSelector(accountSelectors.getAccounts);
  const canManageBilling = usePermission(Permission.CanManageBilling);
  const { show, isTrial } = useSelector(accountSelectors.getPayWallState);

  return (
    <AntModal
      open={
        canManageBilling &&
        (location.pathname === routes.subscription ||
          location.pathname === routes.payment ||
          location.pathname === routes.invoices)
          ? false
          : show
      }
      width={460}
      footer={null}
      closable={false}
      styles={{
        body: {
          marginBottom: 0,
        },
      }}
    >
      <Content>
        <StopIcon />
        <Title>
          {isTrial
            ? formatMessage(
                { id: 'TheXDayFreeTrialHas' },
                { count: config.freeTrialInDays }
              )
            : formatMessage({ id: 'YourSubscriptionHas' })}{' '}
          <span>{formatMessage({ id: 'Expired' })}</span>
        </Title>
        <Desc>
          {isTrial
            ? formatMessage({ id: 'PayWallDescriptionTrial' })
            : formatMessage({ id: 'PayWallDescriptionExpired' })}{' '}
          {formatMessage({
            id: 'PleaseContactYourAccountAdministratorForMoreInformation',
          })}
          {canManageBilling && (
            <>
              {' '}
              {formatMessage({ id: 'or' })}{' '}
              <Link to={routes.payment}>
                {formatMessage({ id: 'PurchaseSubscriptionHere' })}
              </Link>
            </>
          )}
          .
        </Desc>
        <HaveQuestions />
        <Accounts>
          <ModalAccountsList
            accounts={accounts}
            onClick={({ companyId }) => {
              dispatch(accountActions.changeAccount({ companyId }));
            }}
            currentAccountDisabled
          />
        </Accounts>
        <LogoutBtn
          type="default"
          onClick={() => dispatch(accountActions.signOut())}
        >
          {formatMessage({ id: 'LogOut' })}
        </LogoutBtn>
      </Content>
    </AntModal>
  );
};

export default PayWall;
