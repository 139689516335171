import React, { useCallback, forwardRef } from 'react';
import throttle from 'lodash/throttle';
import useResizeObserver from '@react-hook/resize-observer';

interface IProps {
  measure(): void;
}

const TourPopoverElement = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<IProps>
>(({ measure, children }, ref) => {
  const throttledMeasure = useCallback(
    throttle(() => {
      measure();
    }, 100),
    []
  );

  useResizeObserver(document.body, () => {
    throttledMeasure();
  });

  return <div ref={ref}>{children}</div>;
});

export default TourPopoverElement;
