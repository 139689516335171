import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 500px;
`;

export const ButtonContainer = styled.div`
  border: 1px solid ${colors.gray5};
  border-radius: 5px;

  button {
    width: 330px;
  }
`;
