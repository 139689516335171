import styled from 'styled-components';
import { Check } from 'react-feather';

import { colors } from '@repo/shared/config';

export const Checked = styled(Check)`
  color: ${colors.green};
  width: 18px;
  height: 18px;
`;
