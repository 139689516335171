import React from 'react';

interface IProps {}

const ActionIcon: React.FC<React.PropsWithChildren<IProps>> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8332 6.99999C12.8332 10.2217 10.2215 12.8333 6.99984 12.8333C3.77818 12.8333 1.1665 10.2217 1.1665 6.99999C1.1665 3.77833 3.77818 1.16666 6.99984 1.16666C10.2215 1.16666 12.8332 3.77833 12.8332 6.99999Z"
        stroke="#92929D"
        strokeLinecap="round"
      />
      <path
        d="M9.33317 5.83334L6.49259 8.75001L4.6665 6.87489"
        stroke="#92929D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActionIcon;
