import styled from 'styled-components';

import lines from './lines.svg';
import { colors, BREAKPOINTS } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: ${colors.blue6};
  min-height: 100vh;
  min-width: ${({ minWidth }: { minWidth?: number }) =>
    minWidth ? `${minWidth}px` : 'auto'};

  &:before,
  &:after {
    display: none;
    position: absolute;
    content: '';
    width: 267px;
    height: 232px;
    background-image: url(${lines});
    background-size: cover;
  }

  &:before {
    bottom: 0;
    left: 0;
  }

  &:after {
    top: 0;
    right: 0;
    transform: rotate(-180deg);
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    &:before,
    &:after {
      display: block;
    }
  }
`;

export const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  z-index: 1;
  padding: 35px 0 60px;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    padding: 85px 0;
  }
`;
