import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { useAppDispatch } from '@hooks';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { IssueEventType } from '@domain/Issues/models/IssueEvent';

import AddEditComment from '@src/presentation/Actions/ActionDetails/AddEditComment/AddEditComment';

interface IProps {}

const AddEditIssueComment: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  const issue = useSelector(issuesSelectors.getDetailsIssue);

  return (
    <AddEditComment
      onSend={async (newComment) => {
        if (!issue) {
          return false;
        }

        const resultAction = await dispatch(
          issuesActions.createIssueCommentEvent({
            issueId: issue.id,
            event: {
              id: uuid(),
              createdBy: newComment.createdBy,
              createDate: {
                localTime: newComment.createdAt,
                timeZoneAbbreviation: issue.timeZoneAbbreviation,
              },
              type: IssueEventType.Comment,
              data: newComment,
            },
          })
        );

        return issuesActions.createIssueCommentEvent.fulfilled.match(
          resultAction
        );
      }}
      ianaTimeZone={issue?.ianaTimeZone}
    />
  );
};

export default AddEditIssueComment;
