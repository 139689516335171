import styled from 'styled-components';

import rightArrow from './right-arrow.svg';
import leftPattern from './left-pattern.svg';
import rightPattern from './right-pattern.svg';

import { colors } from '@repo/shared/config';
import { hexToRGB } from '@utils';

export const Container = styled.div`
  width: 1100px;
  background:
    url(${leftPattern}) no-repeat left top,
    url(${rightPattern}) no-repeat right top;
`;

export const Close = styled.div`
  width: 50px;
  height: 50px;

  svg {
    width: 14px;
    height: 14px;
    color: ${colors.gray8};
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 556px;
  padding-top: 60px;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
`;

export const StartSampleAuditBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  color: ${colors.white};
  background: ${colors.blue2};
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;

  &:after {
    display: block;
    content: '';
    width: 22px;
    height: 22px;
    margin-left: 15px;
    background:
      url(${rightArrow}) no-repeat center center,
      ${hexToRGB(colors.white, 0.2)};
    background-size:
      55% 55%,
      100% 100%;
    border-radius: 100%;
  }
`;

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: 28px;
`;

export const SubTitle = styled.div`
  font-size: 15px;
  color: ${colors.gray8};
`;

export const Text = styled.div`
  font-size: 14px;
  margin-bottom: 19px;
  color: ${colors.gray8};
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -7px 28px;

  & > * {
    width: 50%;
    margin: 0 7px;
  }
`;

export const Footer = styled.div`
  text-align: center;
  color: ${colors.gray7};
  font-size: 12px;
  margin-bottom: 40px;
`;
