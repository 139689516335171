import React from 'react';
import AntSelect, { SelectProps } from 'antd/es/select';
import { useIntl } from 'react-intl';

import Select from '../ant/Select/Select';
import { filterOptionsByChildren } from '@utils';
import { IOption } from '@repo/shared/types';

const { Option } = AntSelect;

interface IProps extends Omit<SelectProps<any>, 'options'> {
  extraOptions?: IOption[];
  options: IOption[];
  loading: boolean;
  e2eDataAttr?: string;
}

const EntitySelect: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef(({ extraOptions, options, loading, ...props }, _) => {
    const { formatMessage } = useIntl();

    const { disabled, placeholder, mode } = props;
    let value = props.value;

    if (loading) {
      value = mode === 'multiple' ? [] : null;
    }

    return (
      <Select
        {...props}
        disabled={loading || disabled}
        placeholder={
          loading ? `${formatMessage({ id: 'loading' })}...` : placeholder
        }
        value={value}
        loading={loading}
        showSearch
        optionFilterProp="children"
        filterOption={filterOptionsByChildren}
      >
        {(extraOptions || []).map((option: any, i) => (
          <Option value={option.id} key={`${option.id}-${i}`}>
            {option.name}
          </Option>
        ))}
        {(options || []).map((entity: any) => (
          <Option value={entity.id} key={entity.id}>
            {entity.name}
          </Option>
        ))}
      </Select>
    );
  });

export default EntitySelect;
