import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { accountSelectors, accountActions } from '@store';
import { useOpacityTransition, useAppDispatch } from '@hooks';
import { browserHistory } from '@src/browserHistory';

import StartTourModal from './modals/StartTourModal';
import EndTourModal from './modals/EndTourModal';
import TourOverlay from './TourOverlay/TourOverlay';

const SelfGuidedTour: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();

  const { step: tourStep } = useSelector(accountSelectors.getSelfGuidedTour);
  const transition = useOpacityTransition(tourStep !== null);
  const [locationKeys, setLocationKeys] = useState<string[]>([]);

  useEffect(() => {
    return browserHistory.listen((location: any) => {
      if (tourStep === null) {
        return;
      }

      if (browserHistory.action === 'PUSH') {
        setLocationKeys([location.pathname]);
      }

      if (browserHistory.action === 'POP') {
        if (locationKeys[1] === location.pathname) {
          // forward
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          // back
          setLocationKeys((keys) => [location.pathname, ...keys]);

          dispatch(accountActions.selfGuidedTour.stop());
        }
      }
    });
  }, [tourStep]);

  return (
    <>
      {transition.map(({ item, props, key }) => {
        if (item) {
          return ReactDOM.createPortal(
            <TourOverlay key={key} style={{ ...props }} />,
            document.body
          );
        }

        return null;
      })}
      <StartTourModal />
      <EndTourModal />
    </>
  );
};

export default SelfGuidedTour;
