import styled from 'styled-components';

import { colors } from '@repo/shared/config';
import stopIcon from './stop.svg';

import LibraryButton from '../../../shared/ant/Button';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 5px 10px;
`;

export const StopIcon = styled.i`
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 0 0 30px 0;
  background: url(${stopIcon}) no-repeat center center;
`;

export const Title = styled.h3`
  margin-bottom: 15px;

  span {
    color: ${colors.red};
  }
`;

export const Desc = styled.p`
  font-size: 14px;
  margin-bottom: 15px;
`;

export const LogoutBtn = styled(LibraryButton)`
  margin-top: 25px;
`;

export const Accounts = styled.div`
  padding: 9px 0;
  margin: 30px 15px 0;
  width: 100%;
`;
