import styled from 'styled-components';

interface ContainerProps {
  color: string;
  bgColor: string;
}

export const Container = styled.div`
  padding: 6px 12px;
  font-size: 10px;
  line-height: 1;
  border-radius: 50px;
  white-space: nowrap;

  ${({ color, bgColor }: ContainerProps) => `
     color: ${color};
     background: ${bgColor}
  `}
`;
