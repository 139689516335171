import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '../shared/ActionsModal/styled';
import { Modal } from '@repo/shared/enums';
import {
  rolesSelectors,
  generalActions,
  generalSelectors,
  rolesActions,
} from '@store';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../shared/ActionsModal/ActionsModal';

const RolesActionsModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const roles = useSelector(rolesSelectors.getRolesLookup);

  return (
    <ActionsModal>
      {({ isGroupActions, closeModal }) => (
        <>
          {!isGroupActions && (
            <Action
              onClick={() => {
                dispatch(
                  rolesActions.showAddEditRoleModal(
                    roles[selectedTableRowKeys[0]]
                  )
                );

                closeModal();
              }}
            >
              {formatMessage({ id: 'Edit' })}
            </Action>
          )}
          {(selectedTableRowKeys.length > 1 ||
            (selectedTableRowKeys.length === 1 &&
              !roles[selectedTableRowKeys[0]]?.isDefault)) && (
            <Action
              onClick={() =>
                dispatch(
                  generalActions.showModal({ name: Modal.ConfirmDelete })
                )
              }
            >
              {formatMessage({ id: 'Delete' })}
              {selectedTableRowKeys.length > 1
                ? ` (${selectedTableRowKeys.length})`
                : ''}
            </Action>
          )}
        </>
      )}
    </ActionsModal>
  );
};

export default RolesActionsModal;
