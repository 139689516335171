import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { TagColorCircle } from '../../shared/ant/Tag/styled';
import { getColumnSortProperties } from '@utils';
import { ITag } from '@repo/shared/types';
import { usePermission, useAppDispatch } from '@hooks';
import {
  accountSelectors,
  generalActions,
  tagsActions,
  tagsSelectors,
} from '@store';
import { Permission, RoleType, Modal } from '@repo/shared/enums';

import Table from '../../shared/ant/Table/Table';
import EmptyTable from '../../shared/ant/EmptyTable/EmptyTable';
import TagsActionsMenu from './TagsActionsMenu';
import SampleBadgedName from '@components/shared/SampleBadgedName';

const TagsTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { loading, error, tags, meta } = useSelector(tagsSelectors.getTags);
  const filters = useSelector(tagsSelectors.getFilters);
  const canManageTags = usePermission(Permission.CanManageTags);
  const currentUser = useSelector(accountSelectors.getCurrentUser);
  const isCurrentUserAdmin = currentUser?.role.roleType === RoleType.Admin;

  return (
    <Table
      loading={loading}
      meta={meta}
      error={error}
      canSelectRow={canManageTags}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'nameCol',
          key: 'nameCol',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          render: ({ name, isSample }) => (
            <SampleBadgedName text={name} isSample={isSample} />
          ),
        },
        {
          title: formatMessage({ id: 'Color' }),
          dataIndex: 'color',
          key: 'color',
          render: (color) => <TagColorCircle size="lg" color={color} />,
        },
        {
          title: formatMessage({ id: 'Comment' }),
          dataIndex: 'description',
          key: 'description',
        },
        ...(canManageTags
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (tag: ITag) => <TagsActionsMenu tag={tag} />,
              },
            ]
          : []),
      ]}
      dataSource={
        tags
          ? tags.map((tag: ITag) => {
              return {
                key: tag.id,
                nameCol: {
                  name: tag.name,
                  isSample: tag.isSample,
                },
                ...tag,
              };
            })
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage(
                  {
                    id:
                      filters.search !== '' ? 'NoTagsSearchResults' : 'NoTags',
                  },
                  { searchStr: filters.search }
                )}
                {isCurrentUserAdmin && canManageTags && (
                  <>
                    <br />
                    {formatMessage({ id: 'YouCanAddTagRightNow' })}
                  </>
                )}
              </>
            }
            button={
              isCurrentUserAdmin && canManageTags
                ? {
                    text: `+ ${formatMessage({ id: 'CreateNewTag' })}`,
                    type: 'link',
                    onClick: () =>
                      dispatch(
                        generalActions.showModal({ name: Modal.AddEdit })
                      ),
                  }
                : null
            }
          />
        ),
      }}
      onPageChange={(update) => dispatch(tagsActions.getTags(update))}
      onSort={(orderBy, orderByDirection) =>
        dispatch(tagsActions.getTags({ orderBy, orderByDirection }))
      }
    />
  );
};

export default TagsTable;
