import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  .ant-checkbox {
    margin-right: 4px;
  }

  .ant-checkbox-disabled + span {
    color: inherit;
  }
`;

export const DisabledContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 18px;
  color: ${colors.gray8};
  height: 22px;

  svg {
    width: 18px;
    height: 18px;
    color: ${colors.gray8};
    margin-left: -1px;
    margin-right: 10px;
  }
`;

export const SpinContainer = styled.div`
  margin-left: 3px;
`;
