import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { getColumnSortProperties } from '@utils';
import { IUserGroup } from '@repo/shared/types';
import { usersSelectors, usersActions, accountSelectors } from '@store';
import { Permission } from '@repo/shared/enums';
import { usePermission } from '@hooks/account';
import { e2eTestElements, routes } from '@config';
import { config, colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';

import Table from '../../../../shared/ant/Table/Table';
import EmptyTable from '../../../../shared/ant/EmptyTable/EmptyTable';
import UserGroupsActionsMenu from '@components/users/groups/list/UserGroupsTable/UserGroupsActionsMenu';

const UserGroupsTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { loading, error, data, meta } = useSelector(
    usersSelectors.groups.getData
  );
  const filters = useSelector(usersSelectors.groups.getFilters);
  const canManageUsers = usePermission(Permission.CanManageUsers);
  const { isUsersGroupsReadonly } = useSelector(
    accountSelectors.getDataImportSettings
  );
  const canEdit = !isUsersGroupsReadonly && canManageUsers;

  return (
    <Table
      onPageChange={(update) => dispatch(usersActions.groups.getData(update))}
      onSelectRow={() => {
        dispatch(
          usersActions.groups.toggleAddEditGroupModal({ visible: false })
        );
      }}
      loading={loading}
      meta={meta}
      canSelectRow={canEdit}
      onUnmount={() => {
        dispatch(
          usersActions.groups.toggleAddEditGroupModal({ visible: false })
        );
      }}
      onRow={(userGroup) => {
        return {
          onClick: () => {
            navigate(routes.users.groupDetails.replace(':id', userGroup.id));
          },
        };
      }}
      error={error}
      e2eDataAttr={e2eTestElements.users.groupsTable}
      columns={[
        {
          title: formatMessage({ id: 'Name' }),
          dataIndex: 'name',
          key: 'name',
          ...getColumnSortProperties(
            filters.orderByDirection,
            filters.orderBy === 'name'
          ),
          render: (_, group: IUserGroup) => (
            <div>
              <span style={{ color: colors.blue2 }}>{group.name}</span>
              {!!group.usersCount && (
                <span
                  style={{ color: colors.gray7 }}
                >{` (${group.usersCount})`}</span>
              )}
            </div>
          ),
        },
        ...(canEdit
          ? [
              {
                title: formatMessage({ id: 'Action' }),
                key: 'action',
                align: 'center' as 'center',
                width: 70,
                render: (group: IUserGroup) => (
                  <UserGroupsActionsMenu group={group} />
                ),
              },
            ]
          : []),
      ]}
      dataSource={
        data
          ? data.map((group: IUserGroup) => ({
              key: group.id,
              ...group,
            }))
          : []
      }
      locale={{
        emptyText: (
          <EmptyTable
            text={
              <>
                {formatMessage(
                  {
                    id:
                      filters.search !== ''
                        ? 'NoUsersGroupsSearchResults'
                        : 'NoUsersGroups',
                  },
                  {
                    searchStr: filters.search,
                  }
                )}
                {canEdit && (
                  <>
                    <br />
                    {formatMessage({ id: 'YouCanAddUseGroupRightNow' })}
                  </>
                )}
              </>
            }
            button={
              canEdit
                ? {
                    text: `+ ${formatMessage({
                      id: 'CreateNewUserGroup',
                    })}`,
                    type: 'link',
                    onClick: () => {
                      dispatch(
                        usersActions.groups.toggleAddEditGroupModal({
                          visible: true,
                        })
                      );
                    },
                  }
                : null
            }
            howItWorksUrl={config.urls.userGroupsSupport}
          />
        ),
      }}
      onSort={(orderBy, orderByDirection) =>
        dispatch(
          usersActions.groups.getData({
            orderBy,
            orderByDirection,
          })
        )
      }
    />
  );
};

export default UserGroupsTable;
