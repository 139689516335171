import { forwardRef, SVGAttributes } from 'react';
import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const AuditObjectGroup = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <path
          d="M9.28843 16.2222C8.76376 16.7228 8.1041 17 7.44444 17C6.78489 17 6.12533 16.7227 5.60072 16.2222C4.02128 14.7155 2 11.9729 2 8.91497C2 2.84007 8.46064 1.44456 11.429 4.72833M9.25926 8.44444C9.25926 9.44674 8.44674 10.2593 7.44444 10.2593C6.44215 10.2593 5.62963 9.44674 5.62963 8.44444C5.62963 7.44215 6.44215 6.62963 7.44444 6.62963C8.44674 6.62963 9.25926 7.44215 9.25926 8.44444ZM18.3693 11.4444C18.3693 12.4467 17.5567 13.2593 16.5544 13.2593C15.5522 13.2593 14.7396 12.4467 14.7396 11.4444C14.7396 10.4422 15.5522 9.62963 16.5544 9.62963C17.5567 9.62963 18.3693 10.4422 18.3693 11.4444ZM21.9989 11.915C21.9989 14.9728 19.9778 17.7155 18.3984 19.2222C17.8738 19.7228 17.2141 20 16.5544 20C15.8949 20 15.2353 19.7227 14.7107 19.2222C13.1313 17.7155 11.11 14.9729 11.11 11.915C11.11 4.02832 21.9989 4.02837 21.9989 11.915Z"
          stroke={color}
          strokeLinecap="round"
        />
      </svg>
    );
  }
);

export default AuditObjectGroup;
