import { Action } from '@domain/Actions/models/Action';
import { UpdateActionDto } from '@infrastructure/Actions/models/UpdateActionDto';

export function transformActionDetailsToEditActionDto({
  assignedUsers,
  tags,
  dueDateInformation,
  createdAtInformation,
  completedAtInformation,
  expiredAtInformation,
  ...actionDetails
}: Action): UpdateActionDto {
  return {
    ...actionDetails,
    dueDate: dueDateInformation?.localTime || null,
    createdAt: createdAtInformation?.localTime || null,
    completedAt: completedAtInformation?.localTime || null,
    expiredAt: expiredAtInformation?.localTime || null,
    assignedUsersIds: assignedUsers.map(({ id }) => id),
    tagsIds: tags.map(({ id }) => id),
  };
}
