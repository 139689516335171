import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Check, X } from 'react-feather';
import Spin from 'antd/es/spin';
import Popover from 'antd/es/popover';

import { Container, DisabledContainer, SpinContainer } from './styled';
import { settingsActions } from '@store';
import { INotification } from '@repo/shared/types';
import { notification as uiNotification } from '@utils/notifications';
import { useAppDispatch } from '@hooks';

import Checkbox from '../../../../shared/ant/Checkbox';

interface IProps {
  notification: INotification;
  langId: string;
}

const NotificationRow: React.FC<React.PropsWithChildren<IProps>> = ({
  notification,
  langId,
}) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [processing, setProcessing] = useState(false);

  if (notification.isEditable) {
    return (
      <Container>
        <Checkbox
          checked={notification.isEnabled}
          disabled={processing}
          onChange={async (e) => {
            setProcessing(true);

            const resultAction = await dispatch(
              settingsActions.updateNotification({
                notification: notification.notificationSettingType,
                isEnabled: e.target.checked,
              })
            );

            if (
              settingsActions.updateNotification.fulfilled.match(resultAction)
            ) {
              uiNotification.success({
                message: formatMessage({
                  id: 'NotificationSettingsHaveBeenSuccessfullyUpdated',
                }),
              });
            } else {
              uiNotification.error({
                message: formatMessage({
                  id: 'ErrorWhileUpdatingNotificationSettings',
                }),
              });
            }

            setProcessing(false);
          }}
        >
          {formatMessage({
            id: langId,
          })}
        </Checkbox>
        <SpinContainer>
          <Spin spinning={processing} size="small" />
        </SpinContainer>
      </Container>
    );
  }

  return (
    <Popover
      content={formatMessage({
        id: 'ThisNotificationIsCrucialAndCanNotBeModified',
      })}
    >
      <DisabledContainer>
        {notification.isEnabled ? <Check /> : <X />}
        <div>
          {formatMessage({
            id: langId,
          })}
        </div>
      </DisabledContainer>
    </Popover>
  );
};

export default NotificationRow;
