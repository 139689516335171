import { AuditTemplateDetails } from '@repo/shared/types';
import { UpdateAuditTemplateDto } from '@infrastructure/AuditTemplateBuilder/models/UpdateAuditTemplateDto';
import { transformTemplateItemsToDto } from '@infrastructure/AuditTemplateBuilder/transformers/transformTempalteItemsToDto';

export function transformTemplateToUpdateTemplateDto({
  data,
  scoreSystem,
  ...template
}: AuditTemplateDetails): UpdateAuditTemplateDto {
  return {
    ...template,
    data: transformTemplateItemsToDto(data),
    scoreSystemId: scoreSystem.id,
  };
}
