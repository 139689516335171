import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';

import { accountActions, accountSelectors } from '@store';
import { colors } from '@repo/shared/config';
import { notification } from '@utils';
import { useAppDispatch } from '@hooks';

import LibraryModal from '../../shared/ant/Modal/Modal';

const DeleteAccountModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const { show, company } = useSelector(
    accountSelectors.getConfirmDeleteAccountModalState
  );

  function closeModal() {
    dispatch(accountActions.toggleConfirmDeleteAccountModal({ show: false }));
  }

  return (
    <LibraryModal
      title={formatMessage({ id: 'DeleteAccount' })}
      open={show}
      onOk={async () => {
        setLoading(true);

        const resultAction = await dispatch(
          accountActions.deleteCompany(company?.id)
        );

        if (accountActions.deleteCompany.fulfilled.match(resultAction)) {
          dispatch(accountActions.getAccounts());
          closeModal();
        } else {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileDeletingAccount' }),
            description: resultAction.payload,
          });
        }

        setLoading(false);
      }}
      okText={formatMessage({ id: 'Delete' })}
      okButtonBackground={colors.gray10}
      confirmLoading={loading}
      onCancel={() => closeModal()}
      closeIcon={<X />}
    >
      {formatMessage({ id: 'YouAreAboutToRemoveAccountFromSystem' }).replace(
        '%replaceValue%',
        company?.name || ''
      )}
    </LibraryModal>
  );
};

export default DeleteAccountModal;
