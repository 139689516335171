import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Title } from './styled';
import { NotificationsGroupType } from '@repo/shared/enums';
import { settingsSelectors } from '@store';

import NotificationRow from '@components/settings/Notifications/NotificationsGroup/NotificationRow/NotificationRow';

interface IProps {
  group: NotificationsGroupType;
  title: string;
}

const NotificationsGroup: React.FC<React.PropsWithChildren<IProps>> = ({
  group,
  title,
}) => {
  const notifications = useSelector(
    settingsSelectors.getNotificationsByGroup(group)
  );

  if (notifications.length === 0) {
    return null;
  }

  return (
    <Container>
      <Title>{title}:</Title>
      {notifications.map(({ notification, langId }, i) => {
        const notificationEl = (
          <NotificationRow
            key={notification.notificationSettingType}
            notification={notification}
            langId={langId}
          />
        );

        if (!notification.isEditable && i < notifications.length - 1) {
          return (
            <React.Fragment key={notification.notificationSettingType}>
              {notificationEl}
              <br />
            </React.Fragment>
          );
        }

        return notificationEl;
      })}
    </Container>
  );
};

export default NotificationsGroup;
