import React, { useMemo } from 'react';
import { Plus } from 'react-feather';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { generalActions, tagsActions, tagsSelectors } from '@store';
import { usePermission, useAppDispatch } from '@hooks';
import { Permission, TagType, Modal } from '@repo/shared/enums';
import { routes } from '@config';

import SearchInput from '../../shared/SeachInput/SearchInput';
import Button from '../../shared/ant/Button';
import PageHeader from '../../shared/ant/PageHeader';
import PageTabbedSwitcher from '@components/shared/PageTabbedSwitcher/PageTabbedSwitcher';

interface IProps {
  tagType: TagType;
}

const TagsHeader: React.FC<React.PropsWithChildren<IProps>> = ({ tagType }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const canManageTags = usePermission(Permission.CanManageTags);

  const filters = useSelector(tagsSelectors.getFilters);

  const tagTabs = useMemo(() => {
    return [
      {
        id: TagType.Audit,
        name: formatMessage({ id: 'Audits' }),
        onClick: () => {
          navigate(routes.tagsAudits);
        },
      },
      {
        id: TagType.Item,
        name: formatMessage({ id: 'Items' }),
        onClick: () => {
          navigate(routes.tagsItems);
        },
      },
      {
        id: TagType.Action,
        name: formatMessage({ id: 'CorrectiveActions' }),
        onClick: () => {
          navigate(routes.tagsActions);
        },
      },
    ];
  }, []);

  return (
    <PageHeader
      title={formatMessage({ id: 'Tags' })}
      extra={
        <>
          <SearchInput
            value={filters.search}
            onEnter={(search: string) =>
              dispatch(tagsActions.getTags({ search, pageNumber: 1 }))
            }
          />
          {canManageTags && (
            <Button
              type="primary"
              icon={<Plus />}
              onClick={() =>
                dispatch(generalActions.showModal({ name: Modal.AddEdit }))
              }
            >
              {formatMessage({ id: 'AddNewTag' })}
            </Button>
          )}
        </>
      }
      footer={
        <PageTabbedSwitcher<TagType> activePage={tagType} pages={tagTabs} />
      }
    />
  );
};

export default TagsHeader;
