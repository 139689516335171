import React, { useEffect } from 'react';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import { shallowEqual, useSelector } from 'react-redux';

import { accountSelectors, dashboardActions, generalSelectors } from '@store';
import { useAppDispatch, usePermission } from '@hooks';
import { Permission } from '@repo/shared/enums';

import Header from './Header/Header';
import Totals from './Totals/Totals';
import AuditsCompletion from './AuditsCompletion/AuditsCompletion';
import UnresolvedActions from './UnresolvedActions/UnresolvedActions';
import AuditsList from './AuditsList/AuditsList';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';
import FeedbackModal from '@components/shared/FeedbackModal/FeedbackModal';
import DetailsModal from '@components/reports/itemAnalysis/DetailsModal/DetailsModal';

const Dashboard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { loading: templatesLoading, data: templates } = useSelector(
    generalSelectors.getConciseTemplates,
    shallowEqual
  );
  const canDoAudits = usePermission(Permission.CanDoAudits);
  const company = useSelector(accountSelectors.getCompany);
  const filters = useSelector(generalSelectors.getSharedFilters);
  const isAppLoading = useSelector(accountSelectors.isFullScreenLoaderVisible);

  useEffect(() => {
    if (templatesLoading) {
      dispatch(dashboardActions.setDashboardLoadingStatus(true));

      if (filters.templateIds.length > 0) {
        dispatch(
          dashboardActions.getDashboard({
            templateIds: [],
          })
        );
      }
    } else if (
      templates.length > 0 &&
      filters.templateIds.length === 0 &&
      company?.defaultTemplateId
    ) {
      dispatch(
        dashboardActions.getDashboard({
          templateIds: [company.defaultTemplateId],
        })
      );
    } else if (
      (templates.length === 0 && filters.templateIds.length === 0) ||
      !company?.defaultTemplateId
    ) {
      dispatch(dashboardActions.setDashboardLoadingStatus(false));
    }
  }, [
    templatesLoading,
    templates,
    JSON.stringify(filters.templateIds),
    isAppLoading,
  ]);

  useEffect(() => {
    if (filters.templateIds.length > 0) {
      dispatch(dashboardActions.getDashboard());
    }
  }, []);

  let colSpan = 12;
  let unresolvedActionsWidth = 550;

  if (canDoAudits) {
    colSpan = 8;
    unresolvedActionsWidth = 350;
  }

  return (
    <>
      <SampleDataAlert />
      <Header />
      <Row align="top" gutter={[14, 14]}>
        {canDoAudits && (
          <Col span={colSpan}>
            <Totals />
          </Col>
        )}
        <Col span={colSpan}>
          <AuditsCompletion />
        </Col>
        <Col span={colSpan}>
          <UnresolvedActions width={unresolvedActionsWidth} />
        </Col>
      </Row>
      {canDoAudits && <AuditsList />}
      <FeedbackModal />
      <DetailsModal />
    </>
  );
};

export default Dashboard;
