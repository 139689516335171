export enum HttpMethods {
  get = 'GET',
  post = 'POST',
  delete = 'DELETE',
  put = 'PUT',
}

export enum ExportType {
  Pdf,
  Xls,
  Csv,
}

export enum ApiType {
  Internal,
  InternalFiles,
  PublicAuditReport,
  PublicAuditReportFiles,
}

export enum Priority {
  Low,
  Medium,
  High,
}

export enum ConciseDataType {
  User,
  UserGroup,
  AuditObject,
  AuditObjectGroup,
  Template,
}

export enum Modal {
  AddEdit = 'addEdit',
  ConfirmDelete = 'confirmDelete',
  SuspendActivate = 'suspendActivate',
  SelectUser = 'selectUser',
  View = 'view',
  Information = 'information',
  ConfirmExpire = 'confirmExpire',
}

export enum AssigneesModalContext {
  Audit,
  Action,
  Issue,
}
