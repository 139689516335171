import styled from 'styled-components';

import { colors } from '@repo/shared/config';

export const Container = styled.div`
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  background: ${colors.gray1};
  width: 560px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const Extra = styled.div`
  display: flex;
  align-items: center;
`;

export const EventDate = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: ${colors.gray8};
`;

export const Body = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.gray10};

  span {
    font-size: 14px;
    line-height: 1;
  }

  & > * {
    &:first-child {
      display: block;
      margin-right: 8px;
    }
  }
`;
