import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Action } from '../../../../shared/ActionsModal/styled';
import { generalSelectors, auditObjectsActions } from '@store';
import { useAppDispatch } from '@hooks';

import ActionsModal from '../../../../shared/ActionsModal/ActionsModal';

const AuditObjectsActionsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );

  return (
    <ActionsModal>
      {() => (
        <Action
          onClick={() =>
            dispatch(
              auditObjectsActions.groupAssignments.toggleConfirmDeleteModal(
                selectedTableRowKeys
              )
            )
          }
        >
          {formatMessage({ id: 'Delete' })}
          {selectedTableRowKeys.length > 1
            ? ` (${selectedTableRowKeys.length})`
            : ''}
        </Action>
      )}
    </ActionsModal>
  );
};

export default AuditObjectsActionsModal;
