import { IRootState } from '../../../frameworks/redux';
import { createSelector } from '@reduxjs/toolkit';

import { colors } from '@repo/shared/config';
import {
  prepareComparablesForAPI,
  round,
  selectActionsAnalytics,
} from '@utils';
import {
  AuditStatusReports,
  ComparableChart,
  CompareType,
  DetailsModalType,
} from '@repo/shared/enums';
import { generalSelectors, itemAnalysisReportActions } from '@store';
import {
  IAuditsCompletionChartItem,
  IComparable,
  ISectionPerformanceChartItem,
} from '@repo/shared/types';
import { intl } from '@repo/shared/components/IntlGlobalProvider';

export const summaryReportSelectors = {
  getTotals: (state: IRootState) => state.reports.summary.totals,
  getAuditCompliance: createSelector(
    (state: IRootState) => state.reports.summary.auditCompliance,
    ({ data, loading }) => {
      return {
        loading,
        data: Array(5)
          .fill(null)
          .map((_, i) => {
            const sectorSize = 20;
            const score = data?.score || 0;
            const scoreColor = data?.scoreColor || colors.gray5;

            return {
              count: sectorSize,
              color: score > sectorSize * i ? scoreColor : colors.gray5,
            };
          }),
        score: data?.score ? round(data.score, 0) : 0,
        previousScore: data?.previousScore ? round(data.previousScore, 0) : 0,
      };
    }
  ),
  getScoreBreakdown: createSelector(
    [(state: IRootState) => state.reports.summary.scoreBreakdown],
    ({ data, loading }) => {
      return {
        loading,
        data:
          data?.map(
            ({
              scoreMin,
              scoreMax,
              label,
              ...item
            }: IAuditsCompletionChartItem) => ({
              ...item,
              label: `${label} (${scoreMin}%-${scoreMax}%)`,
              onClickAction: itemAnalysisReportActions.detailsModal.show({
                title: label || intl.formatMessage({ id: 'ScoreSystem' }),
                count: item.count,
                type: DetailsModalType.Audits,
                filters: {
                  scoreElementId: item.id,
                  auditStatus: AuditStatusReports.Completed,
                },
              }),
            })
          ) || [],
      };
    }
  ),
  getSectionPerformance: createSelector(
    (state: IRootState) => state.reports.summary.sectionPerformance,
    ({ data, loading, comparables, page, nonZeroDataChartComparablesIds }) => {
      return {
        comparables,
        nonZeroDataChartComparablesIds,
        sections: (data || [])
          .slice(page * 10, page * 10 + 10)
          .map((section: ISectionPerformanceChartItem) => ({
            ...section,
            shortName:
              section.name && section.name?.length > 13
                ? `${section.name.substring(0, 13)}...`
                : section.name,
            onClickAction: itemAnalysisReportActions.detailsModal.show({
              title: section.name || intl.formatMessage({ id: 'ScoreSystem' }),
              count: section.count,
              type: DetailsModalType.Audits,
              filters: {
                sectionId: section.templateItemId,
                auditStatus: AuditStatusReports.Completed,
              },
            }),
          })),
        loading,
      };
    }
  ),
  getSectionPerformanceFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) =>
        state.reports.summary.sectionPerformance.comparables,
    ],
    (filters, comparables) => ({
      ...filters,
      compare: prepareComparablesForAPI(comparables),
    })
  ),
  getSectionsPerformancePagination: createSelector(
    [
      (state: IRootState) => state.reports.summary.sectionPerformance.page,
      (state: IRootState) =>
        state.reports.summary.sectionPerformance.data.length,
    ],
    (page, totalCount) => ({ page, totalCount })
  ),
  getActionsAnalytics: createSelector(
    [
      (state: IRootState) => state.reports.summary.actionsAnalytics,
      generalSelectors.getSharedFilters,
    ],
    selectActionsAnalytics
  ),
  getAuditPerformance: createSelector(
    (state: IRootState) => state.reports.summary.auditPerformance,
    ({ data, loading, comparables }) => {
      return {
        comparables: Object.values<IComparable>(comparables),
        items: data || [],
        loading,
      };
    }
  ),
  getAuditPerformanceFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) => state.reports.summary.auditPerformance.comparables,
    ],
    (filters, comparables) => ({
      ...filters,
      compare: prepareComparablesForAPI(comparables),
    })
  ),
  getAuditCompletion: createSelector(
    (state: IRootState) => state.reports.summary.auditCompletion,
    ({ data, loading, comparables }) => {
      return {
        comparables: Object.values<IComparable>(comparables),
        items: data || [],
        loading,
      };
    }
  ),
  getAuditCompletionFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) => state.reports.summary.auditCompletion.comparables,
    ],
    (filters, comparables) => ({
      ...filters,
      compare: prepareComparablesForAPI(comparables),
    })
  ),
  getAuditDayOfWeekBreakdown: (state: IRootState) =>
    state.reports.summary.auditDayOfWeekBreakdown,
  getAuditCompletionTime: (state: IRootState) =>
    state.reports.summary.auditCompletionTime,
  getFailedCriticalItemsFilters: createSelector(
    [
      generalSelectors.getSharedFilters,
      (state: IRootState) =>
        state.reports.itemAnalysis.failedCriticalItems.filters,
    ],
    (filters, failedCriticalItemsFilters) => {
      return {
        ...filters,
        ...failedCriticalItemsFilters,
      };
    }
  ),
  compareModal: {
    getChart: (state: IRootState) => state.reports.summary.compareModal.chart,
    getActiveTab: (state: IRootState) =>
      state.reports.summary.compareModal.activeTab,
    getChartComparables: createSelector(
      [
        (state: IRootState) => state.reports.summary.compareModal.chart,
        (state: IRootState) =>
          state.reports.summary.sectionPerformance.comparables,
        (state: IRootState) =>
          state.reports.summary.auditPerformance.comparables,
        (state: IRootState) =>
          state.reports.summary.auditCompletion.comparables,
      ],
      (
        chart,
        sectionPerformanceComparable,
        auditPerformanceComparables,
        auditCompletionComparables
      ) => {
        const comparables = {
          [ComparableChart.SectionPerformance]: sectionPerformanceComparable,
          [ComparableChart.AuditPerformance]: auditPerformanceComparables,
          [ComparableChart.AuditCompletion]: auditCompletionComparables,
        };

        return chart !== null ? comparables[chart as ComparableChart] : {};
      }
    ),
    getActiveTabDataList: createSelector(
      [
        (state: IRootState) => state.reports.summary.compareModal.activeTab,
        generalSelectors.getConciseUsers,
        generalSelectors.getConciseUserGroups,
        generalSelectors.getConciseAccessibleAuditObjects,
        generalSelectors.getConciseAuditObjectGroups,
      ],
      (activeTab, users, userGroups, auditObjects, auditObjectGroups) => {
        if (activeTab === CompareType.User && !users.loading) {
          return users.data;
        }

        if (activeTab === CompareType.UserGroups && !userGroups.loading) {
          return userGroups.data;
        }

        if (activeTab === CompareType.AuditObject && !auditObjects.loading) {
          return auditObjects.data;
        }

        if (
          activeTab === CompareType.AuditObjectGroups &&
          !auditObjectGroups.loading
        ) {
          return auditObjectGroups.data;
        }

        return [];
      }
    ),
  },
  getShareViaEmailModalVisibility: (state: IRootState) =>
    state.reports.summary.showShareViaEmailModal,
};
