import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Minus, Plus } from 'react-feather';
import { v4 as uuid } from 'uuid';

import { IScore } from '@repo/shared/types';
import { scoresActions } from '@store';
import {
  ActionBtn,
  Buttons,
  Label,
  LabeledInput,
  PercentInput,
  ScoreOption,
  SubHeader,
} from './styled';
import { colors } from '@repo/shared/config';
import { useAppDispatch } from '@hooks';
import { notification, validations } from '@utils';
import { delay } from '@repo/shared/utils';
import { ScoreSystemType } from '@repo/shared/enums';

import Form from '../../shared/ant/Form';
import { Input } from '../../shared/ant/Input';
import Button from '../../shared/ant/Button';
import ColorPalette from './ColorPalette/ColorPalette';
import LabeledSwitch from '@components/shared/LabeledSwitch/LabeledSwitch';

interface IProps {
  data?: IScore;
  closeModal: () => void;
  opened: boolean;
}

const createNewLabel = () => ({
  id: uuid(),
  min: '',
  max: '',
  label: '',
  color: colors.gray7,
});

const AddEditScoreModalForm: React.FC<React.PropsWithChildren<IProps>> = ({
  data: score,
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const isEditMode = !!score;
  const { formatMessage } = useIntl();

  const validateLevelIntervals = useCallback(
    ({ getFieldValue }: { getFieldValue: any }) => ({
      validator() {
        const labels = getFieldValue('labels');

        if (!Array.isArray(labels) || labels.length === 0) {
          return Promise.reject(
            formatMessage({
              id: 'AtLeastOneScoreLevelIsRequired',
            })
          );
        }

        const sortedLabels = [...labels].sort(
          (a, b) => parseInt(a.min, 10) - parseInt(b.min, 10)
        );

        const setIntervalErrors = (error: string | null) =>
          form.setFields(
            labels.reduce((acc: any, _: any, i: number) => {
              return [
                ...acc,
                {
                  name: ['labels', i, 'min'],
                  errors: error ? [error] : [],
                },
                {
                  name: ['labels', i, 'max'],
                  errors: error ? [error] : [],
                },
              ];
            }, [])
          );

        for (let i = 0; i < sortedLabels.length; i++) {
          const label = sortedLabels[i];
          const nextLabel = sortedLabels[i + 1];

          if (!label) {
            return Promise.resolve();
          }

          const labelMin = parseInt(label.min, 10);
          const labelMax = parseInt(label.max, 10);

          if (isNaN(labelMin) || isNaN(labelMax)) {
            const error = formatMessage({
              id: 'RequiredField',
            });

            setIntervalErrors(error);
            return Promise.reject(error);
          }

          if (labelMin > labelMax) {
            const error = formatMessage({
              id: 'LevelMinimumCannotBeMoreThanMaximum',
            });

            setIntervalErrors(error);
            return Promise.reject(error);
          }

          if (nextLabel) {
            const nextLabelMin = parseInt(nextLabel.min, 10);
            const nextLabelMax = parseInt(nextLabel.max, 10);

            if (nextLabelMin - labelMax > 0) {
              const error = formatMessage({
                id: 'LevelsMustCoverFullRangeFrom0To100',
              });

              setIntervalErrors(error);
              return Promise.reject(error);
            }

            if (
              (labelMin === nextLabelMin && labelMax === nextLabelMax) ||
              nextLabelMin <= labelMax - 1
            ) {
              const error = formatMessage({ id: 'LevelsMustNotOverlap' });

              setIntervalErrors(error);
              return Promise.reject(error);
            }
          }

          if (
            i === sortedLabels.length - 1 &&
            sortedLabels[sortedLabels.length - 1].max
          ) {
            const firstLabelMin = parseInt(sortedLabels[0].min, 10);
            const lastLabelMax = parseInt(
              sortedLabels[sortedLabels.length - 1].max,
              10
            );

            if (lastLabelMax - firstLabelMin !== 100) {
              const error = formatMessage({
                id: 'LevelsMustCoverFullRangeFrom0To100',
              });

              setIntervalErrors(error);
              return Promise.reject(error);
            }
          }
        }

        setIntervalErrors(null);

        return Promise.resolve();
      },
    }),
    []
  );

  return (
    <Form
      form={form}
      onFinish={async ({
        labels,
        passedThreshold,
        countNAItems,
        ...values
      }: any) => {
        setSaving(true);

        const resultAction = await dispatch(
          scoresActions.updateScore({
            id: score?.id,
            labels: labels.map((label: any) => ({
              ...label,
              min: parseInt(label.min, 10),
              max: parseInt(label.max, 10),
              color: label.color || colors.gray7,
            })),
            passedThreshold: parseInt(passedThreshold, 10),
            scoreSystemType: countNAItems
              ? ScoreSystemType.CountPointsForNAItems
              : ScoreSystemType.Default,
            ...values,
          })
        );

        if (scoresActions.updateScore.fulfilled.match(resultAction)) {
          closeModal();
          dispatch(scoresActions.getScores());

          notification.success({
            message: isEditMode
              ? formatMessage({ id: 'YouHaveSuccessfullyEditedScore' })
              : formatMessage({ id: 'YouHaveSuccessfullyCreatedScore' }),
          });
        } else {
          notification.error({
            message: isEditMode
              ? formatMessage({ id: 'ErrorWhileEditingScore' })
              : formatMessage({ id: 'ErrorWhileCreatingScore' }),
            description: resultAction.payload as string,
          });
        }

        await delay(200);

        setSaving(false);
      }}
      name="add-edit-score-form"
      layout="vertical"
      initialValues={{
        name: score?.name || '',
        description: score?.description || '',
        passedThreshold: score?.passedThreshold || '',
        labels: score?.labels || [createNewLabel()],
        countNAItems:
          score?.scoreSystemType === ScoreSystemType.CountPointsForNAItems,
      }}
    >
      <Form.Item
        name="name"
        label={formatMessage({ id: 'NameOfScoringSystem' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'PleaseEnterScoreLabel' }),
          },
        ]}
      >
        <Input
          disabled={saving}
          placeholder={formatMessage({ id: 'EnterName' })}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label={formatMessage({ id: 'DescriptionOptional' })}
      >
        <Input.TextArea disabled={saving} rows={3} />
      </Form.Item>
      <SubHeader>{formatMessage({ id: 'Options' })}</SubHeader>
      <Form.List name="labels">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, i) => (
              <ScoreOption key={field.key}>
                <div>
                  <LabeledInput>
                    <Label>{formatMessage({ id: 'IfScoreIsFrom' })}</Label>
                    <PercentInput>
                      <Form.Item
                        {...field}
                        name={[field.name, 'min']}
                        fieldKey={[field.key, 'min']}
                        className="hide-text-error-message"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            pattern: validations.from0to100,
                          },
                          validateLevelIntervals,
                        ]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>
                    </PercentInput>
                    <PercentInput>
                      <Form.Item
                        {...field}
                        name={[field.name, 'max']}
                        fieldKey={[field.key, 'max']}
                        className="hide-text-error-message"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            pattern: validations.from0to100,
                          },
                          validateLevelIntervals,
                        ]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>
                    </PercentInput>
                  </LabeledInput>
                  <LabeledInput>
                    <Label>{formatMessage({ id: 'ThenLabelItAs' })}</Label>
                    <ColorPalette form={form} index={i} field={field}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'label']}
                        fieldKey={[field.key, 'label']}
                        className="hide-text-error-message"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>
                    </ColorPalette>
                  </LabeledInput>
                </div>
                <ActionBtn
                  type="button"
                  disabled={fields.length <= 1}
                  onClick={() => {
                    remove(field.name);
                    form.validateFields();
                  }}
                >
                  <Minus />
                </ActionBtn>
                <ActionBtn
                  type="button"
                  onClick={() => add(createNewLabel(), i + 1)}
                >
                  <Plus />
                </ActionBtn>
              </ScoreOption>
            ))}
          </>
        )}
      </Form.List>
      <SubHeader>{formatMessage({ id: 'MarkAsFailed' })}</SubHeader>
      <LabeledInput>
        <Label>{formatMessage({ id: 'IfScoreBelow' })}</Label>
        <PercentInput>
          <Form.Item
            name="passedThreshold"
            className="hide-text-error-message"
            rules={[
              {
                required: true,
              },
              {
                pattern: validations.from0to100,
              },
            ]}
          >
            <Input disabled={saving} />
          </Form.Item>
        </PercentInput>
      </LabeledInput>
      <SubHeader>{formatMessage({ id: 'NAItems' })}</SubHeader>
      <Form.Item name="countNAItems">
        <LabeledSwitch
          disabled={isEditMode || saving}
          title={formatMessage({ id: 'CountNAItemsPoints' })}
        />
      </Form.Item>
      <Buttons>
        <Button type="primary" htmlType="submit" loading={saving}>
          {isEditMode
            ? formatMessage({ id: 'Save' })
            : formatMessage({ id: 'AddNewScore' })}
        </Button>
        <Button
          type="default"
          onClick={() => {
            form.resetFields();
            setTimeout(() => {
              closeModal();
            }, 50);
          }}
        >
          {formatMessage({ id: 'Cancel' })}
        </Button>
      </Buttons>
    </Form>
  );
};

export default AddEditScoreModalForm;
