export enum OverviewType {
  All,
  My,
}

export enum DashboardDateRangeType {
  PriorWeek,
  Prior3Months,
  Prior6Months,
  Prior9Months,
  Prior12Months,
}

export enum ShowUnresolvedActionsBy {
  ByStatus,
  ByPriority,
}
