import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from '@hooks';
import { issueTypesActions } from '@application/IssueTypes/store';

interface IProps {}

const IssueTypeRoot: React.FC<React.PropsWithChildren<IProps>> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(issueTypesActions.resetIssueType());
    };
  }, []);

  return <Outlet />;
};

export default IssueTypeRoot;
