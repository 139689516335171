import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Spin from 'antd/es/spin';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Container, Desc, Logo, RemoveLogoBtn, Title, Upload } from './styled';
import { accountActions, accountSelectors, generalActions } from '@store';
import { dateUTC, notification } from '@utils';
import { useAppDispatch } from '@hooks';
import { allowedLogoMimeTypes, config } from '@repo/shared/config';
import { Logger } from '@repo/shared/services';
import { unwrapResult } from '@reduxjs/toolkit';
import { InternalApiService } from '@repo/shared/api';
import { FileSourceType, MimeType } from '@repo/shared/enums';

const apiService = InternalApiService.getInstance();

const UploadLogo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const currentAccount = useSelector(accountSelectors.getCurrentAccount);
  const existingLogoId = currentAccount?.company?.logoId;

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState<string | undefined>();

  const getAndSetLogoUrl = useCallback(
    async (logoId: string): Promise<boolean> => {
      setLoading(true);

      try {
        const blob = await apiService.getFile<Blob>({
          id: logoId,
          responseType: 'blob',
        });

        setLogo(URL.createObjectURL(blob));

        setLoading(false);

        return true;
      } catch (e) {
        console.error(e);

        setLoading(false);

        return false;
      }
    },
    []
  );

  useEffect(() => {
    if (existingLogoId) {
      getAndSetLogoUrl(existingLogoId);
    }
  }, [existingLogoId]);

  if (!currentAccount) {
    return null;
  }

  return (
    <Spin spinning={loading}>
      <Container>
        {logo ? (
          <>
            {!loading && (
              <RemoveLogoBtn
                type="button"
                onClick={async () => {
                  if (currentAccount.company.logoId === null) {
                    Logger.captureException(
                      new Error('icon deletion: iconId is null in the account')
                    );

                    return;
                  }

                  setLoading(true);

                  const resultAction = await dispatch(
                    generalActions.deleteFile('identity/logo')
                  );

                  if (generalActions.deleteFile.fulfilled.match(resultAction)) {
                    dispatch(
                      accountActions.updateAccount({
                        accountId: currentAccount.id,
                        localOnly: true,
                        update: {
                          company: {
                            ...currentAccount?.company,
                            logoId: null,
                          },
                        },
                      })
                    );

                    dispatch(
                      accountActions.updateCompany({
                        local: true,
                        update: {
                          reportLogoId: null,
                          accentColor: null,
                        },
                      })
                    );

                    setLogo(undefined);
                  } else {
                    notification.error({
                      message: formatMessage({ id: 'ErrorWhileDeletingImage' }),
                    });
                  }

                  setLoading(false);
                }}
              >
                <X />
              </RemoveLogoBtn>
            )}
            <Logo
              src={logo}
              onLoad={() => {
                setLoading(false);
              }}
            />
          </>
        ) : (
          <Upload
            name="logo"
            beforeUpload={async (file: File) => {
              if (!allowedLogoMimeTypes.includes(file.type as MimeType)) {
                notification.error({
                  message: formatMessage({
                    id: 'ThereIsProblemUploadingYourImage',
                  }),
                  description: formatMessage({
                    id: 'TrySavingItInDifferentFormatAndUploadAgain',
                  }),
                });

                return 'done';
              }

              setLoading(true);

              const logoId = uuid();

              const resultUploadAction = await dispatch(
                generalActions.uploadFile({
                  file: {
                    id: `identity/logo/${logoId}`,
                    file,
                    name: 'company-logo',
                    contentType: file.type,
                    updatedAt: dateUTC().format(config.apiDateFormat),
                  },
                  source: FileSourceType.CompanyFile,
                })
              );

              let hasError = false;

              if (
                generalActions.uploadFile.fulfilled.match(resultUploadAction)
              ) {
                const success = await getAndSetLogoUrl(logoId);
                hasError = !success;
              } else {
                hasError = true;
              }

              if (hasError) {
                notification.error({
                  message: formatMessage({ id: 'ErrorWhileUploadingImage' }),
                });
              } else {
                dispatch(
                  accountActions.updateAccount({
                    accountId: currentAccount.id,
                    localOnly: true,
                    update: {
                      company: {
                        ...currentAccount?.company,
                        logoId,
                      },
                    },
                  })
                );

                const { response } = unwrapResult(resultUploadAction);

                if (response?.accentColor) {
                  dispatch(
                    accountActions.updateCompany({
                      local: true,
                      update: {
                        reportLogoId: logoId,
                        accentColor: response.accentColor,
                      },
                    })
                  );
                }
              }

              setLoading(false);

              return false;
            }}
          >
            {!loading && (
              <>
                <Title>
                  {formatMessage({ id: 'DropAnImageOr' })}{' '}
                  <span>{formatMessage({ id: 'UploadFile' })}</span>
                </Title>
                <Desc>{formatMessage({ id: 'ImageFormatsAllowed' })}</Desc>
              </>
            )}
          </Upload>
        )}
      </Container>
    </Spin>
  );
};

export default UploadLogo;
