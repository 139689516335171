import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ActionsPage } from '@application/Actions/enums/ActionsPage';
import { useAppDispatch } from '@hooks';
import { actionsSelectors } from '@application/Actions/store/actionsSelectors';
import { actionsActions } from '@application/Actions/store/actionsActions';

import Actions from './Actions';

const MyActions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useAppDispatch();
  const page = useSelector(actionsSelectors.getPage);

  useEffect(() => {
    dispatch(actionsActions.setPage(ActionsPage.My));
  }, []);

  if (page === null) {
    return null;
  }

  return <Actions page={page} />;
};

export default MyActions;
