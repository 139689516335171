import styled from 'styled-components';
import AntTag from 'antd/es/tag';

import { colors } from '@repo/shared/config';

export const StyledTag = styled(AntTag)`
  font-size: 12px;
  border: 0;
  line-height: 1.3;
  padding: 4px 8px;
  border-radius: 5px;

  svg {
    position: relative;
    top: -1px;
    width: 14px !important;
    height: 14px !important;
    vertical-align: middle;
  }

  ${({ tagcolor }: { tagcolor?: string }) =>
    tagcolor &&
    `
    color: ${colors.white}; background-color: ${tagcolor};  
    svg { color: ${colors.white}!important };
  `}
`;

interface TagColorCircleProps {
  color: string;
  size: 'sm' | 'lg';
}

export const TagColorCircle = styled.span`
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  margin-left: 6px;
  margin-top: -1px;
  background: ${(props: TagColorCircleProps) => props.color};

  ${(props: TagColorCircleProps) =>
    props.size === 'sm'
      ? `width: 6px; height: 6px;`
      : `width: 16px; height: 16px;`}
`;
