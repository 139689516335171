import React from 'react';

import ScoreHeader from './ScoresHeader/ScoresHeader';
import ScoresTable from './ScoresTable/ScoresTable';
import ScoresActionsModal from './ScoresActionsModal';
import DeleteScoresModal from './DeleteScoresModal';
import AddEditScoreModal from './AddEditScoreModal/AddEditScoreModal';
import SampleDataAlert from '@components/shared/SampleDataAlert/SampleDataAlert';

const Scores: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <SampleDataAlert />
      <ScoreHeader />
      <ScoresTable />
      <ScoresActionsModal />
      <DeleteScoresModal />
      <AddEditScoreModal />
    </>
  );
};

export default Scores;
