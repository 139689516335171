import styled from 'styled-components';

export const Label = styled.div`
  position: relative;
  padding-left: 19px;
  margin-bottom: 4px;

  &:before {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background: ${({ labelColor }: { labelColor: string }) => labelColor};
  }
`;
