import { forwardRef, SVGAttributes } from 'react';

import { colors } from '@repo/shared/config';

interface IProps extends SVGAttributes<SVGElement> {
  size?: string | number;
  title?: string;
  color?: string;
}

const CsvIcon = forwardRef<SVGSVGElement, IProps>(
  ({ size = 24, title, color = colors.blue2, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {title && <title>{title}</title>}
        <rect
          x="4"
          y="2"
          width="20"
          height="24"
          rx="4"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.71054 23C8.86863 23 8.2064 22.7413 7.72384 22.224C7.24128 21.7067 7 20.9653 7 20C7 19.0507 7.23358 18.3147 7.70074 17.792C8.16789 17.264 8.81986 17 9.65664 17C10.0571 17 10.4061 17.0293 10.7039 17.088C10.8271 17.1093 10.9272 17.1733 11.0042 17.28C11.0812 17.3867 11.1197 17.5067 11.1197 17.64V17.896C11.1197 18.008 11.0761 18.096 10.9888 18.16C10.9015 18.224 10.8066 18.2427 10.7039 18.216C10.4113 18.136 10.1135 18.096 9.81065 18.096C9.34862 18.096 8.9867 18.2587 8.72489 18.584C8.46821 18.9093 8.33987 19.3813 8.33987 20C8.33987 20.608 8.47848 21.08 8.75569 21.416C9.03291 21.752 9.40253 21.92 9.86455 21.92C10.1726 21.92 10.4729 21.88 10.7655 21.8C10.8682 21.7733 10.9631 21.792 11.0504 21.856C11.1428 21.92 11.189 22.008 11.189 22.12V22.36C11.189 22.4933 11.148 22.6133 11.0658 22.72C10.9888 22.8267 10.8887 22.8907 10.7655 22.912C10.4626 22.9707 10.111 23 9.71054 23Z"
          fill={color}
        />
        <path
          d="M13.8897 20.584C13.2429 20.3707 12.7757 20.1067 12.4882 19.792C12.2007 19.472 12.057 19.0827 12.057 18.624C12.057 18.1227 12.2238 17.728 12.5575 17.44C12.8963 17.1467 13.3789 17 14.0052 17C14.4775 17 14.9061 17.056 15.2912 17.168C15.4144 17.2053 15.5119 17.2827 15.5838 17.4C15.6608 17.512 15.6993 17.6373 15.6993 17.776V18.008C15.6993 18.1147 15.6557 18.1973 15.5684 18.256C15.4811 18.3147 15.3887 18.3253 15.2912 18.288C14.8548 18.128 14.4518 18.048 14.0822 18.048C13.6099 18.048 13.3738 18.24 13.3738 18.624C13.3738 18.9493 13.5791 19.1813 13.9898 19.32C14.6982 19.544 15.1885 19.8053 15.4606 20.104C15.7378 20.4027 15.8764 20.7947 15.8764 21.28C15.8764 22.4267 15.1808 23 13.7896 23C13.3481 23 12.9477 22.928 12.5883 22.784C12.4651 22.736 12.3676 22.6533 12.2957 22.536C12.229 22.4133 12.1956 22.28 12.1956 22.136V21.88C12.1956 21.784 12.2367 21.712 12.3188 21.664C12.4009 21.6107 12.4831 21.6053 12.5652 21.648C12.94 21.84 13.3224 21.936 13.7126 21.936C13.9847 21.936 14.19 21.8827 14.3286 21.776C14.4724 21.664 14.5442 21.5067 14.5442 21.304C14.5442 21.128 14.4955 20.984 14.3979 20.872C14.3004 20.76 14.131 20.664 13.8897 20.584Z"
          fill={color}
        />
        <path
          d="M17.9778 22.488L16.4223 17.512C16.3863 17.4053 16.3992 17.3067 16.4608 17.216C16.5275 17.1253 16.6148 17.08 16.7226 17.08H17.2385C17.3669 17.08 17.4824 17.1227 17.585 17.208C17.6877 17.288 17.7545 17.392 17.7853 17.52L18.794 21.608C18.794 21.6133 18.7966 21.616 18.8017 21.616C18.8068 21.616 18.8094 21.6133 18.8094 21.608L19.849 17.52C19.8798 17.392 19.9465 17.288 20.0492 17.208C20.1518 17.1227 20.2674 17.08 20.3957 17.08H20.8808C20.9886 17.08 21.0733 17.1253 21.1349 17.216C21.2017 17.3067 21.2171 17.4053 21.1811 17.512L19.6257 22.488C19.5897 22.616 19.5178 22.72 19.41 22.8C19.3074 22.88 19.1893 22.92 19.0558 22.92H18.5476C18.4193 22.92 18.3012 22.88 18.1934 22.8C18.0907 22.72 18.0188 22.616 17.9778 22.488Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default CsvIcon;
