import { forwardRef, SVGAttributes } from 'react';

interface IProps extends SVGAttributes<SVGElement> {
  color: string;
}

const SubscriptionImage = forwardRef<SVGSVGElement, IProps>(
  ({ color, ...rest }, _) => {
    return (
      <svg
        width="200"
        height="162"
        viewBox="0 0 200 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M31.7604 0L18.5356 25.7917L7.75103 46.8239L0 61.94L96.0408 111.174L127.801 49.2343L31.7604 0Z"
          fill="#F3F3F3"
        />
        <path
          d="M176.211 16.7778H28.968V90.6409H176.211V16.7778Z"
          fill="#64646F"
        />
        <path
          opacity="0.3"
          d="M158.306 43.3184H49.6271V43.5688H158.306V43.3184Z"
          fill={color}
        />
        <path
          opacity="0.3"
          d="M158.431 49.5781H49.7523V49.8285H158.431V49.5781Z"
          fill={color}
        />
        <path
          opacity="0.3"
          d="M158.431 62.0972H49.7523V62.3476H158.431V62.0972Z"
          fill={color}
        />
        <path
          opacity="0.3"
          d="M158.431 67.6055H49.7523V67.8559H158.431V67.6055Z"
          fill={color}
        />
        <path
          opacity="0.3"
          d="M158.431 55.8376H49.7523V56.088H158.431V55.8376Z"
          fill={color}
        />
        <path
          d="M141.5 162C167.181 162 188 159.985 188 157.5C188 155.015 167.181 153 141.5 153C115.819 153 95 155.015 95 157.5C95 159.985 115.819 162 141.5 162Z"
          fill="#F3F3F3"
        />
        <path
          d="M52.7674 67.787L52.4219 67.4244L68.5988 52.0076L82.8588 62.2638L94.3906 49.4802L99.4471 53.7778L113.202 45.5257L128.481 55.7936L152.461 42.1555L152.708 42.5907L128.459 56.3823L113.187 46.1188L99.3966 54.392L94.4366 50.1765L82.9304 62.932L68.6434 52.6568L52.7674 67.787Z"
          fill={color}
        />
        <path
          d="M99.353 149.19C100.53 153.54 104.561 156.234 104.561 156.234C104.561 156.234 106.684 151.875 105.507 147.525C104.33 143.175 100.299 140.481 100.299 140.481C100.299 140.481 98.176 144.84 99.353 149.19Z"
          fill="#44444A"
        />
        <path
          d="M101.082 148.255C104.312 151.398 104.706 156.231 104.706 156.231C104.706 156.231 99.8647 155.966 96.6352 152.823C93.4057 149.679 93.0112 144.847 93.0112 144.847C93.0112 144.847 97.8528 145.111 101.082 148.255Z"
          fill={color}
        />
        <path
          d="M191.611 21.7862C196.244 21.7862 200 18.0308 200 13.3983C200 8.76586 196.244 5.0105 191.611 5.0105C186.978 5.0105 183.222 8.76586 183.222 13.3983C183.222 18.0308 186.978 21.7862 191.611 21.7862Z"
          fill={color}
        />
        <path
          d="M176.086 23.2878C179.336 23.2878 181.97 20.6534 181.97 17.4038C181.97 14.1541 179.336 11.5198 176.086 11.5198C172.836 11.5198 170.201 14.1541 170.201 17.4038C170.201 20.6534 172.836 23.2878 176.086 23.2878Z"
          fill={color}
        />
        <path
          d="M168.824 128.45C170.552 128.45 171.954 127.048 171.954 125.319C171.954 123.591 170.552 122.189 168.824 122.189C167.095 122.189 165.693 123.591 165.693 125.319C165.693 127.048 167.095 128.45 168.824 128.45Z"
          fill={color}
        />
        <path
          d="M115.486 143.973C116.8 143.973 117.865 142.908 117.865 141.594C117.865 140.28 116.8 139.215 115.486 139.215C114.172 139.215 113.107 140.28 113.107 141.594C113.107 142.908 114.172 143.973 115.486 143.973Z"
          fill={color}
        />
        <path
          d="M107.472 113.176C109.201 113.176 110.603 111.775 110.603 110.046C110.603 108.317 109.201 106.916 107.472 106.916C105.744 106.916 104.342 108.317 104.342 110.046C104.342 111.775 105.744 113.176 107.472 113.176Z"
          fill={color}
        />
        <path
          d="M152.296 46.6995C154.094 46.6995 155.552 45.242 155.552 43.4441C155.552 41.6462 154.094 40.1887 152.296 40.1887C150.498 40.1887 149.041 41.6462 149.041 43.4441C149.041 45.242 150.498 46.6995 152.296 46.6995Z"
          fill={color}
        />
        <path
          d="M131.145 102.4L128.385 102.707C128.385 102.707 127.158 108.226 128.385 115.584C129.611 122.942 131.145 130.3 131.145 130.3C131.145 130.3 132.371 154.215 132.984 154.521C133.598 154.828 139.117 156.667 139.424 154.828C139.73 152.988 139.73 110.985 139.73 110.985L144.023 131.22C144.023 131.22 144.636 156.667 145.863 156.667C147.089 156.667 151.076 156.361 151.076 154.215C151.076 152.068 151.996 123.862 151.996 123.862C151.996 123.862 151.689 103.013 150.462 102.4C149.236 101.787 131.145 102.4 131.145 102.4Z"
          fill="#44444A"
        />
        <path
          d="M133.598 153.602C133.598 153.602 130.838 153.295 130.225 153.602C129.612 153.908 128.692 156.055 130.225 157.281C131.758 158.508 135.744 160.347 136.971 160.347C137.821 160.31 138.655 160.101 139.424 159.734L138.81 154.521L133.598 153.602Z"
          fill="#44444A"
        />
        <path
          d="M145.863 155.135L146.17 159.428C146.17 159.428 150.156 160.041 150.462 159.428C150.769 158.814 153.835 157.281 154.142 156.975C154.449 156.668 154.755 153.295 152.915 152.988C151.076 152.682 150.462 153.602 150.462 153.602L145.863 155.135Z"
          fill="#44444A"
        />
        <path
          d="M120.106 76.6462C120.106 76.6462 112.747 83.6979 113.667 87.377C114.587 91.0562 121.946 101.787 121.946 101.787C121.946 101.787 128.998 105.466 129.918 101.787C130.838 98.1078 125.012 99.0276 125.012 99.0276L120.413 87.9902L125.625 80.632L120.106 76.6462Z"
          fill="#FFB8B8"
        />
        <path
          d="M152.989 65.9181C152.989 65.9181 159.692 61.6076 159.537 58.6462C159.382 55.6849 155.341 46.3475 155.341 46.3475C155.341 46.3475 152.179 39.7751 150.922 42.4611C149.665 45.1471 152.574 48.0063 152.574 48.0063L154.452 57.1658L149.349 62.0329L152.989 65.9181Z"
          fill="#FFB8B8"
        />
        <path
          d="M136.357 59.7837C139.406 59.7837 141.877 57.3129 141.877 54.265C141.877 51.2171 139.406 48.7463 136.357 48.7463C133.309 48.7463 130.838 51.2171 130.838 54.265C130.838 57.3129 133.309 59.7837 136.357 59.7837Z"
          fill="#FFB8B8"
        />
        <path
          d="M133.598 57.6377L134.518 63.4637L141.263 62.2371C141.263 62.2371 140.957 56.4111 140.957 55.7979C140.957 55.1846 133.598 57.6377 133.598 57.6377Z"
          fill="#FFB8B8"
        />
        <path
          d="M133.291 63.4628C133.291 63.4628 140.957 61.6233 141.877 62.2365C142.797 62.8497 147.396 63.4628 147.396 63.4628L152.609 71.4343L151.076 81.5519C151.076 81.5519 150.769 97.8014 151.382 100.561C151.995 103.32 155.368 103.627 148.316 104.24C141.263 104.853 140.037 103.933 135.131 105.16C130.225 106.386 126.238 104.546 127.465 103.32C128.692 102.094 130.838 82.4717 128.385 80.6321C125.932 78.7926 125.012 69.2881 125.012 69.2881C125.012 69.2881 133.291 64.9958 133.291 63.4628Z"
          fill={color}
        />
        <path
          d="M146.476 64.3826L147.396 63.4629L149.236 61.6233C149.236 61.6233 155.215 64.2293 154.909 65.1491C154.602 66.0689 152.609 71.4343 152.609 71.4343L149.849 72.3541L146.476 64.3826Z"
          fill={color}
        />
        <path
          d="M128.692 69.9013L125.012 69.2881C125.012 69.2881 122.252 72.354 121.332 73.5804C120.413 74.8068 118.573 77.8727 118.573 77.8727L125.012 82.4716L129.918 77.2595L128.692 69.9013Z"
          fill={color}
        />
        <path
          d="M138.284 47.5789L138.227 47.1368C137.852 47.1347 137.479 47.1933 137.122 47.3104C137.086 47.0438 137.109 46.7724 137.191 46.5159C136.828 46.609 136.483 46.7591 136.168 46.9604L135.945 46.1536L132.88 47.6641C131.779 48.2063 130.619 48.8022 129.99 49.8552C129.289 51.0292 129.411 52.5379 129.891 53.8186C130.37 55.0993 131.166 56.2327 131.805 57.4419C132.047 57.9619 132.339 58.4577 132.675 58.9225C133.832 60.3647 136.084 60.6561 137.716 59.7857C138.336 59.4866 138.831 58.9774 139.112 58.3485C139.231 57.9583 139.324 57.5607 139.391 57.1583C139.593 56.3342 139.827 54.6329 140.497 54.1116C140.791 53.8821 141.376 54.6427 141.62 54.3602C142.247 53.6342 142.115 52.5145 141.726 51.6376C141.352 50.757 140.915 49.9045 140.418 49.0868C140.207 48.7581 138.277 47.5259 138.284 47.5789Z"
          fill="#44444A"
        />
      </svg>
    );
  }
);

export default SubscriptionImage;
