import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import AntSelect from 'antd/es/select';
import { FormInstance } from 'antd/es/form';

import { accountSelectors } from '@store';
import { InputRow } from './styled';
import countries from './countries.json';

import Form from '@components/shared/ant/Form';
import { Input } from '@components/shared/ant/Input';
import Select from '@components/shared/ant/Select/Select';
import StateSelect from './StateSelect/StateSelect';

const { Option } = AntSelect;

interface IProps {
  processing: boolean;
  form: FormInstance;
}

const CompanyInfoFormFields: React.FC<React.PropsWithChildren<IProps>> = ({
  processing,
  form,
}) => {
  const { formatMessage } = useIntl();
  const [country, setCountry] = useState<string | undefined>(undefined);
  const currentUser = useSelector(accountSelectors.getCurrentUser);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Form.Item
        name="organization"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
        ]}
        label={formatMessage({ id: 'CompanyName' })}
      >
        <Input
          placeholder={formatMessage({ id: 'CompanyName' })}
          disabled={processing}
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
          {
            type: 'email',
            message: formatMessage({ id: 'PleaseEnterValidEmail' }),
          },
        ]}
        label={formatMessage({ id: 'BillingEmailAddress' })}
      >
        <Input
          placeholder={formatMessage({ id: 'Email' })}
          disabled={processing}
        />
      </Form.Item>
      <InputRow>
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
          label={formatMessage({ id: 'Country' })}
        >
          <Select
            placeholder={formatMessage({ id: 'Country' })}
            onChange={(value) => {
              form.setFieldValue('state', null);
              setCountry(value);
            }}
            disabled={processing}
          >
            {countries.map((option) => (
              <Option key={option.code} value={option.code}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="state"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
          label={formatMessage({ id: 'StateRegion' })}
        >
          <StateSelect
            placeholder={formatMessage({ id: 'StateRegion' })}
            disabled={processing}
            country={country}
          />
        </Form.Item>
      </InputRow>
      <InputRow>
        <Form.Item
          name="city"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
          label={formatMessage({ id: 'City' })}
        >
          <Input
            placeholder={formatMessage({ id: 'City' })}
            disabled={processing}
          />
        </Form.Item>
        <Form.Item
          name="zip"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'RequiredField' }),
            },
          ]}
          label={formatMessage({ id: 'PostalCode' })}
        >
          <Input
            placeholder={formatMessage({ id: 'PostalCode' })}
            disabled={processing}
          />
        </Form.Item>
      </InputRow>
      <Form.Item
        name="address"
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'RequiredField' }),
          },
        ]}
        label={formatMessage({ id: 'StreetAddress' })}
      >
        <Input
          placeholder={formatMessage({ id: 'StreetAddress' })}
          disabled={processing}
        />
      </Form.Item>
    </>
  );
};

export default CompanyInfoFormFields;
