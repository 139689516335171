import React from 'react';
import Dropdown from 'antd/es/dropdown';

import { IDropdownMenuItem } from '@repo/shared/types';

interface IProps {
  menuItems: IDropdownMenuItem[];
  children: React.ReactNode;
  trigger?: ('click' | 'hover')[];
  popupContainer?: HTMLElement;
  e2eDataAttr?: string;
  overlayStyle?: React.CSSProperties;
  disabled?: boolean;
}

const DropDownMenu: React.FC<React.PropsWithChildren<IProps>> =
  React.forwardRef(
    (
      {
        menuItems,
        children,
        trigger,
        popupContainer,
        e2eDataAttr,
        overlayStyle,
        disabled,
      },
      _
    ) => {
      return (
        <Dropdown
          disabled={disabled}
          getPopupContainer={(trigger) =>
            popupContainer || trigger.parentElement || document.body
          }
          rootClassName="common-dropdown"
          overlayStyle={overlayStyle}
          data-e2e={e2eDataAttr}
          trigger={trigger || ['hover']}
          menu={{
            items: menuItems.map(
              ({ disabled, onClick, e2eDataAttr, name }, i) => ({
                key: i,
                disabled,
                style: {
                  padding: '0 10px',
                  height: '21px',
                  whitespace: 'nowrap',
                  fontSize: '12px',
                  textAlign: 'left',
                },
                'data-e2e': e2eDataAttr,
                onClick: ({ domEvent }) => {
                  domEvent.stopPropagation();
                  onClick();
                },
                label: name,
              })
            ),
          }}
        >
          {children}
        </Dropdown>
      );
    }
  );

export default DropDownMenu;
